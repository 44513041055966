import styled from "styled-components";
export const ViewChecklistDiv = styled.div`
  .view_checlHeight_setting h3 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .subtitlesSpan {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .dateStyle {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .userPage_a {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .userPageSpan {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
`;
export const CardMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: calc(100vh - 305px);
  div{
      span.checklist_over, .clickDesktop, .tapMobile{
      font-family: "Open Sans", sans-serif;
      font-size: 14px !important;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    }
  }
`;

export const CardBodyConatiner = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px 15px;
`;

export const TopHeaderDetailsDiv = styled.div`
  width: 100%;
`;
export const TopTittleName = styled.div`
  font-family: Kanit;
  font-size: 26px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TopDetails = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 8px;
`;

export const DateStyleDiv = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  text-align: left;
  padding-top: 2px;
`;
