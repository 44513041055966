/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA LANDING PAGE LAYOUT WITH HEADER AND FOOTER
 */

import React from "react";

import Header from "../view/Landing/Header/Header.component";
import Footer from "../view/Landing/Footer/Footer.component";

class SieraLandingLayout extends React.Component {
  render() {
    return (
      <>
        <Header />
        {this.props.children}
        <Footer />
      </>
    );
  }
}

export default SieraLandingLayout;
