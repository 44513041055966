import styled from "styled-components";

export const GeneralDropdownContainer = styled.div`
  display: block;
  margin: 10px 0px;
  overflow: hidden;
  @media (max-width: 1100px) {
    padding: 0px 10px;
  }
`;

export const LeftLabel = styled.div`
  float: left;
  width: 60%;
`;

export const RightInput = styled.div`
  float: right;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: end;
  label.switch {
    margin: 0px !important;
  }
`;

export const SpanTop = styled.div`
  text-align: left;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const SpanBottom = styled.div`
  text-align: left;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

export const SwitchLabel = styled.span`
  margin-right: 10px;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;