import styled from "styled-components";

export const OperatorCertificateWrapper = styled.div`
  /*margin-bottom: 20px;*/
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 5% 25% 25% 25% auto;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
    position: relative;
    display: ${({ isHeader }) => (isHeader ? "none" : "block")};
    border: ${({ isHeader }) => (isHeader ? "0px" : "1px solid #CCCCCC")};
    margin: ${({ isHeader }) => (isHeader ? "0px" : "10px 0px")};
    padding: 10px 0px;
    .classNameReg {
      font-size: 20px;
    }
    .widthMobile {
      width: 78%;
    }
    .firstCol {
      display: none;
    }
    .editButtonMobile {
      float: right;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  text-transform: ${({ isHeader }) => (isHeader ? "uppercase" : "")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ display }) => (display ? display : "flex")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: left;
  line-height: 30px;
  @media (max-width: 1100px) {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
    line-height: 10px;
    justify-content: left !important;
  }
  label {
    margin: 0 !important;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 632px)" : "calc(100vh - 605px)")};
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    height: auto;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(236, 236, 236, 0);
    border-radius: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888e94;
    border-width: 10px 3px 10px 3px;
    border-radius: 12px;
    border: 3px solid rgb(255, 255, 255);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #636161;
  }
`;

export const ImageWrapper = styled.img`
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: ${({ isHeader }) => (isHeader ? "pointer" : "initial")};
`;

export const AddCertificateDiv = styled.div`
  float: left;
`;

export const AddCertificateButton = styled.button`
  float: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: Kanit;
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 20px;
  display: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-position: center;
  -webkit-transition: background 0.8s;
  transition: background 0.8s;
  svg {
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-top: 3px;
    height: 16px;
    width: 16px;
    float: left;
    background-size: contain;
    box-sizing: initial;
    fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"}
      radial-gradient(
        circle,
        transparent 1%,
        ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} 1%
      )
      center/15000%;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    background-size: 100%;
    -webkit-transition: background 0s;
    transition: background 0s;
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  &:disabled > svg {
    fill: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const CertificateEditDeleteButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: block;
  margin-left: 9px;
  svg {
    fill: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    }
  }
  &:hover {
    > svg {
      fill: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      > .cls-1 {
        fill: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      }
    }
  }
  &:disabled {
    fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
  }
  &:disabled > svg {
    fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  &:disabled > svg > .cls-1 {
    fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 580px)" : "calc(100vh - 560px)")};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-size: 15px !important;
  font-family: "Open Sans";
  width: 78%;
  margin: auto;
  @media (max-width: 1100px) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const InformationTooltip = styled.div`
  display: block;
  span {
    display: block;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    font-family: "Open Sans";
  }
  span.headingPopover {
    font-family: Kanit;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-size: 16px;
    margin-bottom: 6px;
  }
  span.colorCertificate {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const ImageWrapperInfo = styled.img`
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const OperatorCertificateConfirmation = styled.div`
  text-align: center;
  p {
    text-align: center;
    display: block;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-size: 16px !important;
    font-family: "Open Sans";
    width: 90%;
    margin: 20px auto 20px;
  }
  button {
    float: initial;
    width: 170px;
  }
`;

export const OperatorButtonCancel = styled.button`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: Kanit;
  cursor: pointer;
  display: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-variant: normal;
  border-radius: 4px;
  padding: 7px 20px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border-image: initial;
  background-position: center center;
  transition: background 0.8s ease 0s;
  &:active {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    background-size: 100%;
    transition: background 0s ease 0s;
    outline: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background: radial-gradient(circle, transparent 1%, ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} 1%)
      center center / 15000% ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  &:focus {
    outline: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} solid 1px;
  }
  @media (max-width: 1100px) {
    float: left;
    margin-top: 0px;
  }
`;

export const OperatorSaveCancel = styled.div`
  float: right;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
export const OperatorSaveButton = styled.button`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: Kanit;
  cursor: pointer;
  display: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  color: white;
  font-variant: normal;
  border-radius: 4px;
  padding: 7px 20px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border-image: initial;
  background-position: center center;
  transition: background 0.8s ease 0s;

  svg {
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-top: 3px;
    height: 16px;
    width: 16px;
    float: left;
    background-size: contain;
    box-sizing: initial;
    fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  }

  &:focus {
    outline: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} solid 1px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    background: radial-gradient(
        circle,
        transparent 1%,
        ${({ theme }) => theme.colors.greenHover || "#0D8908"} 1%
      )
      center center / 15000% ${({ theme }) => theme.colors.greenHover || "#0D8908"};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    background-size: 100%;
    transition: background 0s ease 0s;
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  &:disabled > svg {
    fill: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media (max-width: 1100px) {
    float: right;
  }
`;

export const MobileView = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    margin-right: 4px;
  }
`;
export const AddEditModalDiv = styled.div`
  margin: 0px 15px;
  @media (max-width: 1100px) {
    margin: 0px 0px;
  }
`;
