import React from "react";
import SearchBox from "../../../../../../components/SearchBox/Searchbox.component";
import { Trans, useTranslation } from "react-i18next";

const SearchBoxComponent = ({ searchText, setSearchText, getUserTypeBySearchText, placeholder }) => {
  const { t } = useTranslation();
  return (
    <SearchBox
      label={t("Your User Types")}
      placeholder={t("Search a user type")}
      searchText={searchText}
      setSearchText={setSearchText}
      onChange={(e) => getUserTypeBySearchText(e.target.value)}
    />
  );
};

export default SearchBoxComponent;
