import { modalDropdownTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
//import { CacheRefs } from "../../utils/cache/CacheRefs";
import { CacheTypes } from "../../utils/cache/CacheTypes";

//Get Modal Dropdown Worksite List
export const getDropdownWorksiteList = (accountId) => {
  return async (dispatch) => {
    let url = `/dropdown/sites?accid=${accountId}`;
    const cache = CacheManager.getInstance();

    if (cache) {
      const cacheResult1 = await cache.get(url, CacheTypes.FBC);
      if (cacheResult1) {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_SUCCESS,
          data: cacheResult1,
        });
      }
    }

    dispatch({
      type: modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_LOADING,
    });

    apiCall(url)
      .then((resp) => {
        const responseData = resp?.data?.data?.data ? resp?.data?.data?.data : [];
        if (cache) {
          cache.set(url, responseData, null, CacheTypes.FBC);
        }

        dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_SUCCESS,
          data: responseData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_FAILED,
        });
      });
  };
};

// ASSET
export const getDropdownAssetList = (accountId, worksiteId, assetTypeId) => {
  return async (dispatch) => {
    const url = `/dropdown/assets?accid=${accountId}&siteid=${worksiteId ? worksiteId : "all"}&astpid=${
      assetTypeId ? assetTypeId : "all"
    }`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url, CacheTypes.FBC);
      if (cacheResult1) {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_SUCCESS,
          data: cacheResult1,
        });
      }
    }

    dispatch({
      type: modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_LOADING,
    });

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data?.data?.length > 0) {
            cache.set(url, resp?.data?.data?.data, null, CacheTypes.FBC);
          }
        }
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_SUCCESS,
          data: resp?.data?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_FAILED,
        });
      });
  };
};

export const getUsers = (accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey) => {
  return async (dispatch) => {
    let url = `/user/list/${dropdownUserAccessLevel || "all"}/${accountId || "all"}/${siteId || "all"}/${
      pageNo || "all"
    }/${pageSize || "all"}/${encodeURIComponent(searchKey ? searchKey : "all")}`;

    const cache = CacheManager.getInstance();

    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_SUCCESS,
          data: cacheResult1,
        });
      }
    }

    dispatch({
      type: modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_LOADING,
    });

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data?.length > 0) {
            cache.set(url, resp.data.data);
          }
        }
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_SUCCESS,
          data: resp?.data?.data,
        });
      })
      .catch((err) => {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_FAILED,
        });
      });
  };
};

export const getAllSuperAdmins = () => {
  return async (dispatch) => {
    const cache = CacheManager.getInstance();
    const url = "/user/superadmins/dd";

    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_SUCCESS,
          data: cacheResult1,
        });
      }
    }

    dispatch({
      type: modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_LOADING,
    });

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data?.length > 0) {
            cache.set(url, resp?.data?.data);
          }
        }
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_SUCCESS,
          data: resp?.data?.data,
        });
      })
      .catch((err) => {
        return dispatch({
          type: modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_FAILED,
        });
      });
  };
};
