import styled from "styled-components";

const alignStyle = {
  left: {
    textalign: "left",
  },
  middle: {
    textalign: "center",
  },
  right: {
    textalign: "right",
  },
};

export const TabWrapper = styled.div`
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  font-family: Kanit, sans-serif;
  .nav {
    border-radius: 0px;
    border-bottom: ${(props) => props.varientStyle[props.variant].borderBottomNav};
    background-color: ${(props) => props.varientStyle[props.variant].bgColorHover};
    display: ${(props) => props.varientStyle[props.variant].displayTab};
    width: ${(props) => props.varientStyle[props.variant].widthNav};
    float: ${(props) => props.varientStyle[props.variant].floatNav};
  }
  .nav a {
    text-align: ${(props) => alignStyle[props.align].textalign};
    padding: ${(props) => props.varientStyle[props.variant].padddingAch};
    margin-right: ${(props) => props.varientStyle[props.variant].marginRig};
  }
  .tab-content {
    width: ${(props) => props.varientStyle[props.variant].widthTab};
    float: ${(props) => props.varientStyle[props.variant].floatTab};
    padding: ${(props) => props.varientStyle[props.variant].paddingTab};
  }
  .nav .nav-link {
    /*margin-bottom: -1px;*/
    background-color: ${(props) => props.varientStyle[props.variant].bgColorHover};
    border: 0px solid transparent;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }
  .nav-link {
    margin-bottom: 0px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    /* font-family: "Open Sans", sans-serif; */
    font-size: 16px;
    flex: ${(props) => props.varientStyle[props.variant].flex};
    text-align: ${(props) => alignStyle[props.align].textalign};
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: ${(props) => props.varientStyle[props.variant].linebar};
      height: 60%;
      right: 0;
      background: ${(props) => props.varientStyle[props.variant].bgColorHoverN};
      top: 50%;
      transform: translate(0, -50%);
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:nth-child(1) {
      border-left: 1px solid ${(props) => props.varientStyle[props.variant].bgColorHover};
      border-top-left-radius: 4px;
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRad};
    }
    &:last-child {
      border-right: 1px solid ${(props) => props.varientStyle[props.variant].bgColorHover};
      border-top-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRadBotLeft};
    }
  }
  .nav-link:hover {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${(props) => props.varientStyle[props.variant].bgColorHoverN};
    &:before {
      display: none;
    }
    &:nth-child(1) {
      border-left: 1px solid ${(props) => props.varientStyle[props.variant].bgColorHoverN};
      border-top-left-radius: 4px;
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRad};
    }
    &:last-child {
      border-right: 1px solid ${(props) => props.varientStyle[props.variant].bgColorHoverN};
      border-top-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRadBotLeft};
    }
  }
  .nav-link.active {
    color: ${(props) => props.varientStyle[props.variant].colorText};
    background-color: ${(props) => props.varientStyle[props.variant].backgroundColor};
    border-bottom: 2px solid ${(props) => props.varientStyle[props.variant].borderBotoomCo};
    font-weight: ${(props) => props.varientStyle[props.variant].fontWeight};
    &:before {
      display: none;
    }
    &:nth-child(1) {
      border-left: 1px solid ${(props) => props.varientStyle[props.variant].backgroundColor};
      border-top-left-radius: 4px;
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRad};
    }
    &:last-child {
      border-right: 1px solid ${(props) => props.varientStyle[props.variant].backgroundColor};
      border-top-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRadBotLeft};
    }
  }
  .nav-link.active:hover {
    color: ${(props) => props.varientStyle[props.variant].colorText};
    background-color: ${(props) => props.varientStyle[props.variant].backgroundColor};
    &:before {
      display: none;
    }
    &:nth-child(1) {
      border-left: 1px solid ${(props) => props.varientStyle[props.variant].backgroundColor};
      border-top-left-radius: 4px;
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRad};
    }
    &:last-child {
      border-right: 1px solid ${(props) => props.varientStyle[props.variant].backgroundColor};
      border-top-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-right-radius: ${(props) => props.varientStyle[props.variant].borderRad};
      border-bottom-left-radius: ${(props) => props.varientStyle[props.variant].borderRadBotLeft};
    }
  }
  .nav-tabs .nav-link.disabled {
    color: ${(props) => props.varientStyle[props.variant].bgColorHoverN};
  }
`;

export const DropdownDiv = styled.div`
  margin-top: 10px;
`;
