import styled from "styled-components";

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;

export const ModalPopupWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .addWorkNav {
    background-image: linear-gradient(
      30deg,
      ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
      ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
    );
  }
  .addWorkNav span {v
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }

  input.form-control:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    border: 2px solid;
  }
  input.form-control[disabled]:hover {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
`;
export const DeleteIconStyled = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 20px !important;
  margin-top: 20px;
`;

export const ShiftDurationMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  width: 100%;
  text-align: center;
  & p {
    margin: auto;
  }
`;
export const ShiftDurationWrapper = styled.div`
  width: 95%;
  margin: 20px auto 20px auto;
`;

export const ShiftDurationSettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const ShiftDurationHours = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  & label {
    width: 100%;
  }
  & input {
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    opacity: 1;
    margin-top: 3px;
    float: left;
    height: 33px;
    margin-left: 5px;
    padding: 5px;
    width: 145px;
  }
`;

export const ShiftDurButton = styled.div`
  width: 50%;
  float: left;
  display: flex;
  margin-bottom: 10px;
`;
export const ShiftDurationH = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  opacity: 1;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  height: 30px;
  line-height: 30px;
  float: left;
  margin-top: 24px;
  padding: 0px 6px;
`;

