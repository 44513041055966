import moment from "moment";

export const initialState = {
  //For Filter
  inspectionReportFilter: {
    accountId: "all",
    assetId: "all",
    filterByType: "all",
    assetTypeId: "all",
    inspectorId: "all",
    pageNo: 1,
    pageSize: 10,
    startDate: moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    status: "all",
    worksiteId: "all",
  },
  incidentReportFilter: {
    accountId: "all",
    worksiteId: "all",
    assetId: "all",
    filterByType: "all",
    assetTypeId: "all",
    inspectorId: "all",
    incidentType: "all",
    impactSeverity: "all",
    obstacleType: "all",
    pageNo: 1,
    pageSize: 10,
    startDate: moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  },
  unauthorizedAccessFilter: {
    accountId: "all",
    worksiteId: "all",
    assetId: "all",
    filterByType: "all",
    assetTypeId: "all",
    inspectorId: "all",
    pageNo: 1,
    pageSize: 10,
    startDate: moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    impactSeverity: "all",
  },
};
