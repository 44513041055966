import styled from "styled-components";

export const SelectedItemsWrapper = styled.div`
  border: 1px solid #cecece;
  border-top: 0px;
  margin-top: -3px;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 170px;
  overflow-y: auto;
`;

export const MainWrapper = styled.div`
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.gray1 || "#323232"};
    font-family: "Open Sans", sans-serif;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background:  ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .cam-position__placeholder {
    color: ${({ theme }) => theme.colors.gray3 || "#676767"}
  }
  .cam-position__control {
    border-radius: 0px;
  }
`;

export const SelectedItem = styled.div`
  display: flex;
  border-radius: 15px;
  padding: 4px 12px;
  margin-right: 5px;
  height: 30px;
  margin: 5px;
  background: ${({ theme }) => theme.colors.gray6 || "#F2F2F2"};
  font-family: "Open Sans", sans-serif;
  color:  ${({ theme }) => theme.colors.gray1 || "#323232"};
`;

export const SelectedItemText = styled.div`
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveItem = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray4 || "#9A9A9A"};
`;

export const SelectItemWrapper = styled.div`
  width: 94%;
  float: right;
`;

export const ClearAllButtonWrapper = styled.div`
  float: left;
  display: block;
  width: 100%;
`;

export const ClearAllbuttom = styled.span`
  display: inline-block;
  padding: 5px 10px 3px 10px;
  color: #1070e0;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InnerButtonWrapper = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  button.cancel-button-style {
    border-radius: 0.2rem;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.gray4 || "#9A9A9A"};
    font-family: Kanit;
    font-style: normal;
    font-size: 16px;
    height: 40px;
    width: 110px;
    box-shadow: none;
    text-transform: uppercase;
    margin-right: 8px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray6 || "#F2F2F2"} !important;
    }
  }
  button.save-button-style {
    border-radius: 0.2rem;
    border: none;
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
    font-family: Kanit;
    font-style: normal;
    font-size: 16px;
    height: 40px;
    width: 110px;
    box-shadow: none;
    text-transform: uppercase;
  }
`;

export const Label = styled.label`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grayL4 || "#9A9A9A" : theme.colors.grayL1 || "#323232")}!important;
`;

export const FooterNote = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  display: flex;
`;

export const AlertIcon = styled.i`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
  margin-right: 10px;
`;
