import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tab from "react-bootstrap/Tab";
import PropTypes from "prop-types";

const SiearTab = (props) => {
  const { children } = props;
  return <Tab {...props}>{children}</Tab>;
};

SiearTab.propTypes = {};

export default SiearTab;
