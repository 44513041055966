import React from "react";
import ReactDom from "react-dom";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import {
  ContainerDiv,
  HeadingDiv,
  ContainerData,
  LeftArrowIcon,
  HeadingLeft,
  HeadingViewRight,
} from "./DivContainer.component.styles";

const DivContainer = ({ heading, to, onClick, children, showViewAll }) => {
  return (
    <ContainerDiv>
      <HeadingDiv>
        <HeadingLeft>
          <Trans>{heading}</Trans>
        </HeadingLeft>
        {showViewAll && (
          <HeadingViewRight>
            <NavLink to={to} onClick={onClick}>
              <Trans>View All</Trans>
              <LeftArrowIcon className="icon icon-arrow-right"></LeftArrowIcon>
            </NavLink>
          </HeadingViewRight>
        )}
      </HeadingDiv>

      <ContainerData>{children}</ContainerData>
    </ContainerDiv>
  );
};

DivContainer.propTypes = {
  showViewAll: PropTypes.bool,
};

DivContainer.defaultProps = {
  showViewAll: true,
};

export default DivContainer;
