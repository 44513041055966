import {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  TO_RESET_MEDIA_STATE,
  TO_STORE_SELECTED_ASSET,
} from "./types";
import config from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
const baseUrl =  config.baseUrl
//To get all asset types
const getAssetTypeLoading = () => ({
  type: GET_ASSET_TYPE_LOADING,
});

const getAssetTypeSuccess = (data) => ({
  type: GET_ASSET_TYPE_SUCCESS,
  data,
});

const getAssetTypeError = (err) => ({
  type: GET_ASSET_TYPE_ERROR,
  err,
});

//To get all assets details
const getAllAssetLoading = () => ({
  type: GET_ALL_ASSET_LOADING,
});

const getAllAssetSuccess = (data) => ({
  type: GET_ALL_ASSET_SUCCESS,
  data,
});

const getAllAssetError = (err) => ({
  type: GET_ALL_ASSET_ERROR,
  err,
});

//to get asset checklists by checklist ID
const getAssetCheckListByAssetIdLoading = () => ({
  type: GET_ASSET_CHECK_LIST_LOADING,
});

const getAssetCheckListByAssetIdSuccess = (data) => ({
  type: GET_ASSET_CHECK_LIST_SUCCESS,
  data,
});

const getAssetCheckListByAssetIdError = (err) => ({
  type: GET_ASSET_CHECK_LIST_ERROR,
  err,
});

//To upload multiple media
const toUploadMediaLoading = () => ({
  type: UPLOAD_MEDIA_LOADING,
});

const toUploadMediaSuccess = (data) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  data,
});

const toUploadMediaError = (err) => ({
  type: UPLOAD_MEDIA_ERROR,
  err,
});

//To submit the answers for each question
const postAnswersLoading = () => ({
  type: POST_ANSWER_LOADING,
});

const postAnswersSuccess = (data) => ({
  type: POST_ANSWER_SUCCESS,
  data,
});

const postAnswersError = (err) => ({
  type: POST_ANSWER_ERROR,
  err,
});

//To check hour meter reading
// export const validateMeterReading = (assetId, meterReading) => {
//   return async(dispatch, getState) => {
//     dispatch(getAssetTypeLoading());
//     let token =
//       getState() &&
//       getState().user &&
//       getState().user.loginDetails &&
//       getState().user.loginDetails.token;
//     let headers = {
//       "Content-Type": "application/json",
//       "auth-token": token,
//     };

//     axios
//       .get(`${baseUrl}/checklist/validateMeterReading/${assetId}/${meterReading}`, {
//         headers: headers,
//       })
//       .then((resp) => {
//         console.log(resp);
//         dispatch(getAssetTypeSuccess(resp.data));
//       })
//       .catch((err) => {
//         dispatch(getAssetTypeError(err));
//         console.log(err);
//       });
//   };
// };

//To get all asset types
export const getAssetTypes = () => {
  return async (dispatch, getState) => {
    dispatch(getAssetTypeLoading());
    apiCall(`/assettype/getallassettypes/all/0/0`)
      .then((resp) => {
        console.log(resp);
        dispatch(getAssetTypeSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAssetTypeError(err));
        console.log(err);
      });
  };
};

//To get all assets details
export const getAllAsset = () => {
  return async (dispatch, getState) => {
    dispatch(getAllAssetLoading());
    const siteID = getState()?.user?.loginDetails?.userData?.authorizedSites?.[0];

    apiCall(`/asset/getallassets/${siteID ? siteID : 0}`)
      .then((resp) => {
        console.log(resp);
        dispatch(getAllAssetSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getAllAssetError(err));
        console.log(err);
      });
  };
};

//to get asset checklists by checklist ID
export const getAssetCheckListByAssetId = (accountId, assetId) => {
  return async (dispatch, getState) => {
    dispatch(getAssetCheckListByAssetIdLoading());

    apiCall(`/asset/listcheklist/${accountId}/${assetId}`)
      .then((resp) => {
        console.log(resp);
        dispatch(getAssetCheckListByAssetIdSuccess(resp?.data?.data?.[0]?.assetChecklists));
      })
      .catch((err) => {
        dispatch(getAssetCheckListByAssetIdError(err));
        console.log(err);
      });
  };
};

//To upload multiple media
export const toUploadMedia = (data) => {
  return async (dispatch, getState) => {
    dispatch(toUploadMediaLoading());
    const formData = new FormData();
    data.map((file) => formData.append("media", file));

    apiCall(`/checklist/uploadMedia`, { method: "POST", data: formData })
      .then((resp) => {
        console.log(resp);
        dispatch(toUploadMediaSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(toUploadMediaError(err));
        console.log(err);
      });
  };
};

//To submit the answers for each question
export const postAnswers = (data) => {
  return async (dispatch, getState) => {
    dispatch(postAnswersLoading());
    apiCall(`/checklist/checklistResponse`, { method: "POST", data: data })
      .then((resp) => {
        console.log(resp);
        dispatch(postAnswersSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(postAnswersError(err));
        console.log(err);
      });
  };
};

export const toStoreSelectedAsset = (data) => ({
  type: TO_STORE_SELECTED_ASSET,
  data,
});

//to Reset All State
export const toResetAllAssetState = () => ({
  type: TO_RESET_ALL_ASSET_STATE,
});

export const toResetMediaState = () => ({
  type: TO_RESET_MEDIA_STATE,
});
