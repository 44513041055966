import { userManagementTypes } from "./userManagement.types";

// Add User List Utility for addding user to the existing user list
import { addUserToList } from "./userManagement.util";

const INTIAL_STATE = {
  //1. User list from enterprise account
  users: [],
  //2. Selected user overview from enterprise account
  userOverview: null,
  Loading: false,
  Success: false,
  Error: false,
};

export const userManagementReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    // Case 1: Selecting all User from the User List
    case userManagementTypes.LIST_USERS:
      return {
        ...state,
        users: action.payload,
      };

    // Case 2: Add User to the User List
    case userManagementTypes.ADD_USER:
      return {
        ...state,
        users: addUserToList(state.users, action.payload),
      };

    // Case 3: Select Default User Overview from the User List
    case userManagementTypes.DEFAULT_USER_OVERVIEW:
      return {
        ...state,
        userOverview: state.users.find((user, index) => index === 0),
      };

    // Case 4: Selected User Overview
    case userManagementTypes.USER_OVERVIEW:
      return {
        ...state,
        userOverview: state.users.find((user) => user._id === action.payload),
      };

    // Case 5: Update User
    case userManagementTypes.UPDATE_USER:
      let userUpdated = [...state.users];
      userUpdated.forEach((user, index) => {
        if (user._id == action.payload._id) {
          for (var key in action.payload) {
            if (key != "_id") {
              userUpdated[index][key] = action.payload[key];
            }
          }
        }
      });
      return {
        ...state,
        users: userUpdated,
      };

    // To edit username
    case userManagementTypes.EDIT_USERNAME_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case userManagementTypes.EDIT_USERNAME_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        users: state.users.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              fullName: action.data.value,
            };
          }
          return item;
        }),
      };

    case userManagementTypes.EDIT_USERNAME_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    case userManagementTypes.RESET_USERNAME_STATE:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: false,
      };

    case userManagementTypes.SET_SELECTED_NEW_ACCOUNT_OWNER: {
      return {
        ...state,
        newAdminUserData: action.userData,
      };
    }

    // Returning default state
    default:
      return state;
  }
};
