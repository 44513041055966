import { billingTypes } from "./types";
import { initialState } from "./initialState";

export const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    // For Billing Plans
    case billingTypes.GET_ALL_PLANS_LOADING:
      return {
        ...state,
        PlansLoading: true,
        PlansSuccess: false,
        PlansError: false,
      };

    case billingTypes.GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        PlansLoading: false,
        PlansSuccess: true,
        PlansError: false,
        Plans: action.data.data,
      };

    case billingTypes.GET_ALL_PLANS_ERROR:
      return {
        ...state,
        PlansLoading: false,
        PlansSuccess: false,
        PlansError: true,
      };

    // For Creating Customer
    case billingTypes.POST_CREATE_CUSTOMER_LOADING:
      return {
        ...state,
        CustomerLoading: true,
        CustomerSuccess: false,
        CustomerError: false,
      };

    case billingTypes.POST_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        CustomerLoading: false,
        CustomerSuccess: true,
        CustomerError: false,
        CustomerDetails: action.data,
      };

    case billingTypes.POST_CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        CustomerLoading: false,
        CustomerSuccess: false,
        CustomerError: true,
      };

    // For Creating Customer
    case billingTypes.UPDATE_BASIC_PLAN_LOADING:
      return {
        ...state,
        BasicPlanLoading: true,
        BasicPlanSuccess: false,
        BasicPlanError: false,
      };

    case billingTypes.UPDATE_BASIC_PLAN_SUCCESS:
      return {
        ...state,
        BasicPlanLoading: false,
        BasicPlanSuccess: true,
        BasicPlanError: false,
        CustomerDetails: action.data,
      };

    case billingTypes.UPDATE_BASIC_PLAN_ERROR:
      return {
        ...state,
        BasicPlanLoading: false,
        BasicPlanSuccess: false,
        BasicPlanError: true,
      };

    // For getting invoice
    case billingTypes.GET_INVOICES_LOADING:
      return {
        ...state,
        InvoicesLoading: true,
        InvoicesSuccess: false,
        InvoicesError: false,
      };

    case billingTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        InvoicesLoading: false,
        InvoicesSuccess: true,
        InvoicesError: false,
        Invoices: action.data,
      };

    case billingTypes.GET_INVOICES_ERROR:
      return {
        ...state,
        InvoicesLoading: false,
        InvoicesSuccess: false,
        InvoicesError: true,
      };

    //To store selected plan
    case billingTypes.STORE_SELECTED_PLAN:
      return {
        ...state,
        SelectedPlan: action.data,
      };

    //To reset state
    case billingTypes.RESET_STATE:
      return {
        ...state,
        CustomerLoading: false,
        CustomerSuccess: false,
        CustomerError: false,
        BasicPlanLoading: false,
        BasicPlanSuccess: false,
        BasicPlanError: false,
      };

    case billingTypes.GET_BILLING_INFO:
      return {
        ...state,
        BillingInfo: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
