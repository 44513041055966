import { modalDropdownTypes } from "./types";
import { initialState } from "./initialState";

const modalDropdownFiltersReducers = (state = initialState, action) => {
  switch (action.type) {
    case modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_LOADING:
      return {
        ...state,
        getWorksiteListLoading: true,
        getWorksiteListFailed: false,
      };
    case modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_SUCCESS:
      return {
        ...state,
        worksiteList: action.data,
        getWorksiteListSuccess: true,
        getWorksiteListLoading: false,
        getWorksiteListFailed: false,
      };
    case modalDropdownTypes.GET_MODAL_DROPDOWN_WORKSITE_LIST_FAILED:
      return {
        ...state,
        getWorksiteListSuccess: false,
        getWorksiteListLoading: false,
        getWorksiteListFailed: true,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_LOADING:
      return {
        ...state,
        getAssetListLoading: true,
        getAssetListFailed: false,
      };
    case modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_SUCCESS:
      return {
        ...state,
        assetList: action.data,
        getAssetListSuccess: true,
        getAssetListLoading: false,
        getAssetListFailed: false,
      };
    case modalDropdownTypes.GET_MODAL_DROPDOWN_ASSET_LIST_FAILED:
      return {
        ...state,
        getAssetListSuccess: false,
        getAssetListLoading: false,
        getAssetListFailed: true,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_LOADING:
      return {
        ...state,
        getUserListLoading: true,
        getUserListFailed: false,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.data,
        getUserListSuccess: true,
        getUserListLoading: false,
        getUserListFailed: false,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_USER_LIST_FAILED:
      return {
        ...state,
        getUserListSuccess: false,
        getUserListLoading: false,
        getUserListFailed: true,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_LOADING:
      return {
        ...state,
        getSuperAdminListLoading: true,
        getSuperAdminListFailed: false,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        superAdminList: action.data,
        getSuperAdminListSuccess: true,
        getSuperAdminListLoading: false,
        getSuperAdminListFailed: false,
      };

    case modalDropdownTypes.GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_FAILED:
      return {
        ...state,
        getSuperAdminListSuccess: false,
        getSuperAdminListLoading: false,
        getSuperAdminListFailed: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default modalDropdownFiltersReducers;
