import React, { useEffect } from "react";
import { Trans } from "react-i18next";

import { UserTypeOverviewWrapper, HeaderTitle, BodyDetails } from "./styles/Modal.styles";
import UserTypeDetailsTable from "../common/UserTypeDetailsTable.component";
import { getCustomFields } from "../const/usertypeConstants";

const DetailsStep = (props) => {
  const { addUserTypeModalData, setAddUserTypeModalData } = props;
  const { accessLevel, customFields } = addUserTypeModalData;

  useEffect(() => {
    setAddUserTypeModalData({
      ...addUserTypeModalData,
      customFields: getCustomFields(accessLevel),
    });
  }, [accessLevel]);

  const userTypeDetailsTableCB = (customFields) => {
    setAddUserTypeModalData({
      ...addUserTypeModalData,
      customFields: customFields,
    });
  };
  return (
    <UserTypeOverviewWrapper>
      <HeaderTitle>
        <Trans>User Type Details</Trans>
      </HeaderTitle>
      <BodyDetails>
        <UserTypeDetailsTable
          customFields={customFields}
          userTypeDetailsTableCB={userTypeDetailsTableCB}
          type="step"
        />
      </BodyDetails>
    </UserTypeOverviewWrapper>
  );
};

export default DetailsStep;
