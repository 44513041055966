import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
  AssetPermissionWrapper,
  Row,
  Column,
  RowsContainer,
  AllowDisallowWrapper,
  AllowDisallow,
  MobileView,
  DesktopView,
} from "./AssetPermissionTab.styles";
import UserEditComponent from "./../UserEdit.component";
import SwitchComponent from "../../../../../components/Switchbox/SwitchComponent";

const AssetPermissionTab = (props) => {
  const {
    handleUpdateUser,
    isSuperAdmin,
    userDetails,
    setUserDetails,
    editForm,
    allAssetTypesWithCount,
    activeTab,
  } = props;

  const [unauthorizedAssetTypes, setUnauthorizedAssetTypes] = useState(
    userDetails.unauthorizedAssetTypes ? userDetails.unauthorizedAssetTypes : []
  );
  const [allAssetTypeIds, setAllAssetTypeIds] = useState(
    allAssetTypesWithCount.map((assetType) => assetType._id)
  );

  useEffect(() => {
    setAllAssetTypeIds(allAssetTypesWithCount.map((assetType) => assetType._id));
  }, [allAssetTypesWithCount]);

  useEffect(() => {
    setUnauthorizedAssetTypes(userDetails.unauthorizedAssetTypes ? userDetails.unauthorizedAssetTypes : []);
  }, [userDetails]);

  const toggleAllow = (id) => {
    const unauthorizedAssetTypesSet = new Set(unauthorizedAssetTypes);
    if (unauthorizedAssetTypesSet.has(id)) {
      unauthorizedAssetTypesSet.delete(id);
    } else {
      unauthorizedAssetTypesSet.add(id);
    }
    setUserDetails({
      ...userDetails,
      unauthorizedAssetTypes: Array.from(unauthorizedAssetTypesSet),
    });
  };

  const toggleAll = (allowAll) => {
    if (editForm) {
      return;
    }
    if (allowAll) {
      setUserDetails({
        ...userDetails,
        unauthorizedAssetTypes: [],
      });
    } else {
      setUserDetails({
        ...userDetails,
        unauthorizedAssetTypes: allAssetTypeIds,
      });
    }
  };

  return (
    <>
      <AssetPermissionWrapper>
        <Row isHeader={true}>
          <Column isHeader={true} align={"left"}>
            Your Asset Types
          </Column>
          <Column isHeader={true} align={"center"} className="mobileHide">
            Number of Assets
          </Column>
          <Column
            isHeader={true}
            align={"right"}
            style={{ textAlign: "right", flexDirection: "column", display: "block" }}
          >
            <span className="mobileHideSpan">
              Access Permission <br />
            </span>

            <AllowDisallowWrapper>
              <AllowDisallow
                onClick={() => toggleAll(true)}
                isDisabled={
                  editForm ||
                  !userDetails.unauthorizedAssetTypes ||
                  userDetails?.unauthorizedAssetTypes?.length === 0
                }
              >
                <DesktopView>Allow All</DesktopView> <MobileView>All</MobileView>
              </AllowDisallow>
              |
              <AllowDisallow
                onClick={() => toggleAll(false)}
                isDisabled={
                  editForm || userDetails?.unauthorizedAssetTypes?.length === allAssetTypeIds.length
                }
              >
                <DesktopView>Disallow All </DesktopView> <MobileView>None</MobileView>
              </AllowDisallow>
            </AllowDisallowWrapper>
          </Column>
        </Row>
        <RowsContainer isSuperAdmin={isSuperAdmin}>
          {allAssetTypesWithCount.map((data) => {
            return (
              <Row key={data._id}>
                <Column align={"left"} className="mobileFontSize">
                  {data.assetTypeName}
                </Column>
                <Column align={"center"}>
                  <MobileView>
                    <Trans>Number of Assets : {"  "}</Trans>
                  </MobileView>

                  {data.assetsCount}
                </Column>
                <Column align={"right"} className="editSwitchMobile">
                  <SwitchComponent
                    checked={!unauthorizedAssetTypes.includes(data._id)}
                    onChange={() => toggleAllow(data._id)}
                    disabled={editForm}
                  />
                </Column>
              </Row>
            );
          })}
        </RowsContainer>
      </AssetPermissionWrapper>

      <UserEditComponent
        handleUpdateUser={handleUpdateUser}
        activeTab={activeTab}
        userDetails={userDetails}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  allAssetTypesWithCount: state.assetType.allAssetTypesWithCount || [],
  isSuperAdmin: state.user.isSuperAdmin,
});

export default connect(mapStateToProps)(AssetPermissionTab);
