import { modalTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalTypes.ADD_SITE_MODAL:
      return {
        ...state,
        addSiteModal: action.data,
      };

    case modalTypes.ADD_ASSET_MODAL:
      return {
        ...state,
        addAssetModal: action.data,
      };

    case modalTypes.ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: action.data,
        isUpdate: false,
      };

    case modalTypes.ADD_ASSET_TYPE_MODAL:
      return {
        ...state,
        addAssetTypeModal: action.data,
      };

    case modalTypes.ADD_USER_TYPE_MODAL:
      return {
        ...state,
        addUserTypeModal: action.data,
      };

    case modalTypes.UPDATE_USER_MODAL:
      return {
        ...state,
        addUserModal: true,
        userDetails: action.data,
        isUpdate: true,
      };

    case modalTypes.SET_ADD_ASSET_MODAL_REDIRECT:
      return {
        ...state,
        addAssetModalRedirect: action.data,
        isUpdate: false,
      };

    case modalTypes.MODAL_WAITING_FOR_OPEN:
      return {
        ...state,
        modalWaitingForOpen: action.data,
      };

    case modalTypes.SET_EDIT_ALERT_MODAL:
      return {
        ...state,
        editAlertModal: action.data,
      };

    case modalTypes.ADD_CHECKLIST_MODAL:
      return {
        ...state,
        addChecklistModal: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
