import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as AlertIcon } from "../../../assets/Alert_Icon.svg";

function AssetTypeChecklistDeleteAllModal({ showModal, setShowModal, handleYes }) {
  return (
    <>
      <Modal
        size="md"
        className="custom-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        {/* modal body starts */}

        <Modal.Body className="text-center modalPopup">
          <Row style={{ justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
            <AlertIcon style={{ width: 60, marginRight: 10 }} fill="#d00" />
          </Row>
          <Row className="mb-4 mt-4 ">
            <Col>
              <span
                className="mb-2 text-open-sans text-center modal-font-color"
                style={{ fontSize: "1rem", margin: "auto", width: "96%", display: "block" }}
              >
                <Trans>
                  Removing all the checklists on your assets will remove them from the list of
                  inspection-ready assets
                </Trans>
                .<br></br> <Trans>Are you sure you want to continue</Trans>?
              </span>
            </Col>
          </Row>

          {/* Modal body ends */}
          <div className="justify-content-center">
            <div className="s3modalbuttonyes">
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                className="btn btn-primary mb-2 buttons ripple"
              >
                <Trans>NO</Trans>
              </button>
            </div>

            <div className="s3modalbuttonno ">
              <button onClick={() => handleYes()} className="btn btn-lg mb-3 buttons ripple">
                <Trans>YES</Trans>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssetTypeChecklistDeleteAllModal;
