import React, { useState, useEffect } from "react";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import SiearTabs from "../../../components/TabComponent/SieraTabs.Component";
import SiearTab from "../../../components/TabComponent/SieraTab.Component";

import {
  EnterpriseTitle,
  EnterpriseSubTitle,
  EnterpriseSubTitle2,
  LoginPageStyled,
  TabWrapperDiv,
} from "./LoginRequestAccessTabs.styles";
import RequestAccess from "../RequestAccess/RequestAccess.component";
import Register from "../RegistrationForm/RegistrationForm.component";
import Login from "../Login/Login.component";
import config from "../../../config";
const baseUrl = config.baseUrl; //Base Url
const s3BucketLogo = config.s3BucketLogo; //Logo s3 path

// Login Request Access Tab Component
export default function LoginRequestAccessTabs({ props, value, header, handleChange, setHeader }) {
  const { t } = useTranslation();

  const [logo, setLogo] = useState("");
  const [accountId, setAccountId] = useState({ accountId: "" });

  //let pathArray = props.location.pathname.split("/");
  // let companyUrl = !pathArray[2] && pathArray[1];
  let companyUrl = false;

  useEffect(() => {
    if (companyUrl) {
      axios
        .get(`${baseUrl}/account/byEnterpriseUrl/${companyUrl}`)
        .then((response) => {
          if (response.data.success) {
            setLogo(response.data.data.logo);
            setAccountId(response.data.data._id);
          }
        })
        .catch((error) => {
          console.log("Something wrong");
          console.log(error);
        });
    }
  }, []);

  return (
    <LoginPageStyled>
      {/*Enterprise Logo*/}
      {!companyUrl ? (
        <div className="p-2 bg-transparent"></div>
      ) : (
        <img
          className="mt-4"
          src={`${s3BucketLogo}` + logo}
          style={{ height: "50px", margin: "0px" }}
          alt="Logo"
        />
      )}

      {/*Login Title*/}
      <EnterpriseTitle>
        <Trans>
          {header.status === "login"
            ? t("Login to SIERA.AI Dashboard")
            : companyUrl
              ? t("Request Enterprise Access")
              : t("Create Your Free Account Today!")}
        </Trans>
      </EnterpriseTitle>

      {/*Login Sub Titles*/}
      {header.status === "login" ? (
        <EnterpriseSubTitle2>
          <Trans>Please enter your credentials to login</Trans>
          <br></br>
          <br></br>
          <br></br>
        </EnterpriseSubTitle2>
      ) : companyUrl ? (
        <>
          <EnterpriseSubTitle>
            <Trans>Welcome to SIERA Dashboard. Please enter your full name</Trans>,
          </EnterpriseSubTitle>
          <EnterpriseSubTitle2>
            <Trans>email ID and request description for enterprise access</Trans>
          </EnterpriseSubTitle2>
        </>
      ) : (
        <>
          <EnterpriseSubTitle>
            <Trans>
              Get 30-days FREE Pro trial. No credit card required.<br></br> Cancel anytime.
            </Trans>
          </EnterpriseSubTitle>
        </>
      )}
      <TabWrapperDiv>
        <SiearTabs defaultActiveKey="Login" variant="horizontalNew" align="left">
          <SiearTab eventKey={"Login"} title={t("Login")}>
            <Login history={props.history} companyUrl={companyUrl} />
          </SiearTab>
          <SiearTab eventKey={"Register"} title={t("Register")}>
            {companyUrl ? <RequestAccess accountId={accountId} /> : <Register />}
          </SiearTab>
        </SiearTabs>
      </TabWrapperDiv>
    </LoginPageStyled>
  );
}
