/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { login } from "../../../../redux/user/user.action";
import DropdownFilter from "../../../../components/DropdownFilter.component";
import SettingsSection from "../../../../components/SettingsSection";
import {
  getNotificationSettingsByAccount,
  getNotificationSettingsBySite,
} from "../../../../redux/notification/action";
import useAlert from "../../../../utils/useAlert";
import { isEqual } from "../../../../utils/validations/utilities";
import alertConstant from "../../../../constant/messages/toaster.json";
import { apiCall } from "../../../../utils/apiCall";
import ActionButtons from "../../common/ActionButtons";
import {
  SettingWithButtonWrapper,
  SettingWrapper,
  ButtonWrapper,
} from "./NotificationSetting.component.styles";
import {
  getDropdownWorksiteList,
  setDropdownAccountId,
  setDropdownWorksiteId,
} from "../../../../redux/dropdownFilters/action";
import ActionsBar from "./ActionsBar";

const NotificationSettings = ({
  loginDetails,
  notificationSetting,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  isSiteAdmin,
  dropdownAccountList,
  dropdownWorksiteList,
  getNotificationSettingsByAccount,
  getNotificationSettingsBySite,
  setDropdownAccountId,
  dropdownAccountId,
}) => {
  const [accountSummaryAlertSettings, setAccountSummaryAlertSettings] = useState(null);
  const [workSiteSummaryAlertSettings, setWorkSiteSummaryAlertSettings] = useState(null);
  const [inspectionAlertSettings, setInspectionAlertSettings] = useState(null);
  const [nearMissAlertSettings, setNearMissAlertSettings] = useState(null);
  const [operatorCertificateExpiryAlertSettings, setOperatorCertificateExpiryAlertSettings] = useState({});
  const [impactAlertSettings, setImpactAlertSettings] = useState(null);
  const [unAuthorizedAccessAlertSettings, setUnAuthorizedAccessAlertSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownWorksiteId, setDropdownWorksiteId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [worksiteId, setWorksiteId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);

  useEffect(() => {
    const acId =
      isAccountAdmin || isAccountOwner || isSiteAdmin
        ? loginDetails && loginDetails.accountId
        : dropdownAccountId;
    setCurrentAccountId(acId);
  }, [dropdownAccountId, loginDetails, isAccountAdmin, isAccountOwner, isSiteAdmin]);

  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const getNotificationSettings = () => {
    if (currentAccountId && currentAccountId !== "all") {
      getNotificationSettingsByAccount(currentAccountId);
    }
    if (dropdownWorksiteId && dropdownWorksiteId !== "all") {
      getNotificationSettingsBySite(dropdownWorksiteId);
    }
  };

  useEffect(() => {
    getDropdownWorksiteList();
  }, [dropdownAccountId]);

  useEffect(() => {
    setWorksiteId(dropdownWorksiteList?.[0]?._id);
    setDropdownWorksiteId(dropdownWorksiteList?.[0]?._id);
  }, [dropdownWorksiteList]);

  useEffect(() => {
    getNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountId, dropdownWorksiteId, loginDetails]);

  useEffect(() => {
    setDropdownAccountId(dropdownAccountList?.[0]?._id);

    setAccountId(dropdownAccountList?.[0]?._id);
  }, [dropdownAccountList]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setDropdownAccountId(loginDetails?.accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  const setDefaultNotificationSettingsAccount = () => {
    setAccountSummaryAlertSettings(notificationSetting?.accountSummaryAlertSettings);
  };

  const setDefaultNotificationSettingsWorksite = () => {
    setInspectionAlertSettings(notificationSetting?.inspectionAlertSettings);
    setImpactAlertSettings(notificationSetting?.impactAlertSettings);
    setUnAuthorizedAccessAlertSettings(notificationSetting?.unAuthorizedAccessAlertSettings);
    setWorkSiteSummaryAlertSettings(notificationSetting?.workSiteSummaryAlertSettings);
    setNearMissAlertSettings(notificationSetting?.nearMissAlertSettings);
    setOperatorCertificateExpiryAlertSettings(notificationSetting?.operatorCertificateExpiryAlertSettings);
  };

  const hideButtonsForWorksiteSettings =
    isEqual(notificationSetting?.inspectionAlertSettings, inspectionAlertSettings) &&
    isEqual(notificationSetting?.impactAlertSettings, impactAlertSettings) &&
    isEqual(notificationSetting?.unAuthorizedAccessAlertSettings, unAuthorizedAccessAlertSettings) &&
    isEqual(notificationSetting?.workSiteSummaryAlertSettings, workSiteSummaryAlertSettings) &&
    isEqual(notificationSetting?.nearMissAlertSettings, nearMissAlertSettings) &&
    isEqual(
      notificationSetting?.operatorCertificateExpiryAlertSettings,
      operatorCertificateExpiryAlertSettings
    );

  const hideButtonsForAccountSettings = isEqual(
    notificationSetting?.accountSummaryAlertSettings,
    accountSummaryAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettingsAccount();
      setDefaultNotificationSettingsWorksite();
    }
  }, [notificationSetting]);

  const handleInspectionAlertSettingsChange = (field, value) => {
    setInspectionAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const handleImpactAlertSettingsChange = (field, value) => {
    setImpactAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const handleUnauthorizedAccessChange = (field, value) => {
    setUnAuthorizedAccessAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const handleAccountSummaryAlertChange = (field, value) => {
    setAccountSummaryAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };
  const handleWorkSiteSummaryAlertSettingsChange = (field, value) => {
    setWorkSiteSummaryAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const handleNearMissAlertChange = (field, value) => {
    setNearMissAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const handleOperatorCertificateExpiryAlertSettingsChange = (field, value) => {
    if (field === "headerSwitch") {
      setOperatorCertificateExpiryAlertSettings((prevAlert) => ({
        ...prevAlert,
        beforeMonth: value,
        beforeWeek: value,
        beforeDay: value,
      }));
    } else {
      setOperatorCertificateExpiryAlertSettings((prevAlert) => ({
        ...prevAlert,
        [field]: value,
      }));
    }
  };

  const onSubmitAccount = () => {
    setIsLoading(true);
    const data = {
      accountSummaryAlertSettings: accountSummaryAlertSettings,
    };

    apiCall(`/account/summaryalertsettings/${currentAccountId}`, { method: "PUT", data: data })
      .then(() => {
        showAlert("success", alertConstant.email_alert_save_successfull, 2500);
        getNotificationSettings();
        setIsLoading(false);
      })
      .catch((err) => {
        showAlert("error", alertConstant.server_error, 1500);
        setIsLoading(false);
      });
  };

  const onSubmitWorksiteSettings = () => {
    setIsLoading(true);
    let selectedWorksites = [];
    const worksiteId = isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownWorksiteId;

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(worksiteId)) {
        selectedWorksites = [...selectedWorksites, worksiteId];
      }
    } else {
      selectedWorksites = [worksiteId];
    }

    const data = {
      accountId,
      sites: selectedWorksites,
      settings: {
        inspectionAlertSettings,
        impactAlertSettings,
        nearMissAlertSettings,
        unAuthorizedAccessAlertSettings,
        workSiteSummaryAlertSettings,
        operatorCertificateExpiryAlertSettings,
      },
    };

    apiCall("/alertsetting/bulkupdate/notificationsettings", { method: "PUT", data: data })
      .then(() => {
        showAlert("success", alertConstant.email_alert_save_successfull, 2500);
        getNotificationSettings();
        setApplyToAllObj({});
        setCheckApplyToAll(false);
        setIsLoading(false);
      })
      .catch((err) => {
        showAlert("error", alertConstant.server_error, 1500);
        setIsLoading(false);
      });
  };

  const displaySettings = {
    accountSummaryRecipient: {
      title: "accountSummaryRecipient",
      description: "accountSummaryRecipientDescription",
      tableHeader: ["Emailer Recipients", "Email"],
      onChange: handleAccountSummaryAlertChange,
      customEmails: accountSummaryAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!accountSummaryAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
        },
        {
          label: "Account Admin",
          value: !!accountSummaryAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
        },
        {
          label: "Account Viewer",
          value: !!accountSummaryAlertSettings?.toAccountviwer,
          field: "toAccountviwer",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },

    accountSummaryAlert: {
      title: "accountSummaryAlert",
      description: "accountSummaryAlertDescription",
      tableHeader: ["Duration", "Alert"],
      onChange: handleAccountSummaryAlertChange,
      tableData: [
        {
          label: "Weekly",
          value: !!accountSummaryAlertSettings?.weekly,
          field: "weekly",
        },
        {
          label: "Monthly",
          value: !!accountSummaryAlertSettings?.monthly,
          field: "monthly",
        },
      ],
    },
    inspectionAlertSettings: {
      title: "InspectionAlerts",
      description: "InspectionAlertsDescription",
      tableHeader: ["Inspection Result", "Alert"],
      onChange: handleInspectionAlertSettingsChange,
      tableData: [
        {
          label: "Pass",
          value: !!inspectionAlertSettings?.pass,
          field: "pass",
        },
        {
          label: "Fail",
          value: !!inspectionAlertSettings?.fail,
          field: "fail",
        },
      ],
    },
    impactAlerts: {
      title: "ImpactAlerts",
      description: "ImpactAlertsDescription",
      tableHeader: ["Impact Severity", "Alert"],
      onChange: handleImpactAlertSettingsChange,
      tableData: [
        {
          label: "Low",
          value: !!impactAlertSettings?.low,
          field: "low",
        },
        {
          label: "Medium",
          value: !!impactAlertSettings?.medium,
          field: "medium",
        },
        {
          label: "High",
          value: !!impactAlertSettings?.high,
          field: "high",
        },
      ],
    },
    pedestrianProximity: {
      title: "pedestrianProximity",
      description: "pedestrianProximityDescription",
      tableHeader: ["Near-Miss to", "Alert"],
      onChange: handleNearMissAlertChange,
      tableData: [
        {
          label: "Pedestrian",
          value: !!nearMissAlertSettings?.pedestrian,
          field: "pedestrian",
        },
        {
          label: "Object",
          value: !!nearMissAlertSettings?.object,
          field: "object",
        },
      ],
    },
    workSiteSummaryAlertSettings: {
      title: "worksiteSummaryAlert",
      description: "worksiteSummaryAlertDescription",
      tableHeader: ["Duration", "Alert"],
      onChange: handleWorkSiteSummaryAlertSettingsChange,
      tableData: [
        {
          label: "Weekly",
          value: !!workSiteSummaryAlertSettings?.weekly,
          field: "weekly",
        },
        {
          label: "Monthly",
          value: !!workSiteSummaryAlertSettings?.monthly,
          field: "monthly",
        },
      ],
    },
    operatorCertificateExpiryAlertSettings: {
      title: "operatorCertificateExpirationAlert",
      description: "operatorCertificateExpirationAlertDesc",
      tableHeader: ["", ""],
      onChange: handleOperatorCertificateExpiryAlertSettingsChange,
      isBodyHidable: true,
      summarySwitch: {
        label: "Alert for Expiration",
        value: !!(
          operatorCertificateExpiryAlertSettings?.beforeMonth ||
          operatorCertificateExpiryAlertSettings?.beforeWeek ||
          operatorCertificateExpiryAlertSettings?.beforeDay
        ),
        field: "headerSwitch",
        headerSwitch: true,
      },
      tableData: [
        {
          label: "Send alert 30 days before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeMonth,
          field: "beforeMonth",
        },
        {
          label: "Send alert 7 days before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeWeek,
          field: "beforeWeek",
        },
        {
          label: "Send alert 1 day before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeDay,
          field: "beforeDay",
        },
      ],
    },
    inspectionAlertSettingssRecipient: {
      title: "InspectionAlertsRecipient",
      description: "alertRecipientDescription",
      tableHeader: ["User Accesss Level", "Alert"],
      onChange: handleInspectionAlertSettingsChange,
      customEmails: inspectionAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!inspectionAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
        },
        {
          label: "Account Admin",
          value: !!inspectionAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
        },
        {
          label: "Worksite Manager",
          value: !!inspectionAlertSettings?.toWorkSiteManager,
          field: "toWorkSiteManager",
        },
        {
          label: "Worksite Administrators",
          value: !!inspectionAlertSettings?.toWorkSiteAdmin,
          field: "toWorkSiteAdmin",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
    impactAlertSettingssRecipient: {
      title: "IncidentAlertsRecipient",
      description: "incidentRecipientDescription",
      tableHeader: ["User Accesss Level", "Alert"],
      onChange: handleImpactAlertSettingsChange,
      customEmails: impactAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!impactAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
        },
        {
          label: "Account Admin",
          value: !!impactAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
        },
        {
          label: "Worksite Manager",
          value: !!impactAlertSettings?.toWorkSiteManager,
          field: "toWorkSiteManager",
        },
        {
          label: "Worksite Administrators",
          value: !!impactAlertSettings?.toWorkSiteAdmin,
          field: "toWorkSiteAdmin",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
    unAuthorizedAccessAlertSettingsRecipient: {
      title: "UnAuthorizedAccessRecipient",
      description: "unauthorizedRecipientDescription",
      tableHeader: ["User Accesss Level", "Alert"],
      onChange: handleUnauthorizedAccessChange,
      customEmails: unAuthorizedAccessAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!unAuthorizedAccessAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
        },
        {
          label: "Account Admin",
          value: !!unAuthorizedAccessAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
        },
        {
          label: "Worksite Manager",
          value: !!unAuthorizedAccessAlertSettings?.toWorkSiteManager,
          field: "toWorkSiteManager",
        },
        {
          label: "Worksite Administrators",
          value: !!unAuthorizedAccessAlertSettings?.toWorkSiteAdmin,
          field: "toWorkSiteAdmin",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
    worksiteSummaryRecipient: {
      title: "worksiteSummaryRecipient",
      description: "accountSummaryRecipientDescription",
      tableHeader: ["User Accesss Level", "Alert"],
      onChange: handleWorkSiteSummaryAlertSettingsChange,
      customEmails: workSiteSummaryAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!workSiteSummaryAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
          showOverlay: false,
        },
        {
          label: "Account Admin",
          value: !!workSiteSummaryAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
          showOverlay: false,
        },
        {
          label: "Account Viewer",
          value: !!workSiteSummaryAlertSettings?.toAccountviwer,
          field: "toAccountviwer",
          showOverlay: false,
        },
        {
          label: "Worksite Manager",
          value: !!workSiteSummaryAlertSettings?.toWorkSiteManager,
          field: "toWorkSiteManager",
          showOverlay: true,
          overlayMessage:
            "When this option is checked, the worksite managers will receive the alerts for their worksite only.",
        },
        {
          label: "Worksite Administrators",
          value: !!workSiteSummaryAlertSettings?.toWorkSiteAdmin,
          field: "toWorkSiteAdmin",
          showOverlay: true,
          overlayMessage:
            "When this option is checked, the worksite admins will receive the alerts for their worksite only.",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
    operatorCertificateExpirationRecipient: {
      title: "operatorCertificateExpirationRecipient",
      description: "operatorCertificateExpirationRecipientDesc",
      tableHeader: ["User Accesss Level", "Email"],
      onChange: handleOperatorCertificateExpiryAlertSettingsChange,
      customEmails: operatorCertificateExpiryAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!operatorCertificateExpiryAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
        },
        {
          label: "Account Administrators",
          value: !!operatorCertificateExpiryAlertSettings?.toAccountAdmin,
          field: "toAccountAdmin",
        },
        {
          label: "Worksite Manager",
          value: !!operatorCertificateExpiryAlertSettings?.toWorkSiteManager,
          field: "toWorkSiteManager",
        },
        {
          label: "Worksite Administrators",
          value: !!operatorCertificateExpiryAlertSettings?.toWorkSiteAdmin,
          field: "toWorkSiteAdmin",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
  };

  return (
    <div className="justify-content-center profile_height_m">
      <div>
        <div className="flex-display">
          <div className="account_select_threshold">
            {isSuperAdmin && (
              <DropdownFilter
                filter={{
                  type: "Account",
                  name: "companyName",
                  key: "companyName",
                  data: dropdownAccountList,
                }}
                label={t("filter")}
                useAsDropdown={true}
                value={accountId}
                handleFilter={(value) => {
                  setDropdownAccountId(value);
                  setAccountId(value);
                }}
              />
            )}
          </div>
        </div>

        <Row>
          <SettingWithButtonWrapper>
            <ButtonWrapper>
              <ActionButtons
                showAppyToAll={false}
                updatableChanges={!hideButtonsForAccountSettings}
                handleUpdate={onSubmitAccount}
                handleCancel={setDefaultNotificationSettingsAccount}
                isLoading={isLoading}
              />
            </ButtonWrapper>
            <SettingWrapper>
              <Col md={5}>
                <SettingsSection {...displaySettings.accountSummaryAlert} />
              </Col>
              <Col md={7}>
                <SettingsSection {...displaySettings.accountSummaryRecipient} />
              </Col>
            </SettingWrapper>
          </SettingWithButtonWrapper>
        </Row>

        <Row>
          <SettingWithButtonWrapper>
            <ActionsBar
              dropdownWorksiteList={dropdownWorksiteList}
              setDropdownWorksiteId={setDropdownWorksiteId}
              setWorksiteId={setWorksiteId}
              showAppyToAll={false}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleUpdate={onSubmitWorksiteSettings}
              handleCancel={setDefaultNotificationSettingsWorksite}
              isLoading={isLoading}
              worksiteId={worksiteId}
              showWorksiteSelection={isSuperAdmin || isAccountAdmin || isAccountOwner}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
            />

            <SettingWrapper>
              <Col md={5}>
                <SettingsSection {...displaySettings.inspectionAlertSettings} />
                <SettingsSection {...displaySettings.impactAlerts} />
                <SettingsSection {...displaySettings.pedestrianProximity} />
                <SettingsSection {...displaySettings.workSiteSummaryAlertSettings} />
                <SettingsSection {...displaySettings.operatorCertificateExpiryAlertSettings} />
              </Col>

              <Col md={7}>
                <SettingsSection {...displaySettings.inspectionAlertSettingssRecipient} />
                <SettingsSection {...displaySettings.impactAlertSettingssRecipient} />
                <SettingsSection {...displaySettings.unAuthorizedAccessAlertSettingsRecipient} />
                <SettingsSection {...displaySettings.worksiteSummaryRecipient} />
                <SettingsSection {...displaySettings.operatorCertificateExpirationRecipient} />
              </Col>
            </SettingWrapper>
          </SettingWithButtonWrapper>
        </Row>
        <br />
        <br />
        <Row></Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    accountList: state.accounts.Accounts,
    isSuperAdmin: state.user.isSuperAdmin,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    dropdownAccountList: state.dropdownFilters.accountList,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters.accountId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (assetTypeId) => dispatch(setDropdownWorksiteId(assetTypeId)),
  setLogin: (loginDetail) => dispatch(login(loginDetail)),
  getNotificationSettingsByAccount: (id) => dispatch(getNotificationSettingsByAccount(id)),
  getNotificationSettingsBySite: (id) => dispatch(getNotificationSettingsBySite(id)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
