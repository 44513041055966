export default {
  WORKSITE: 0,
  ASSET: 1,
  USERS: 2,
  PENDING_USERS: 3,
  ASSET_TYPE: 4,
  ACCOUNT: 5,
  CHECKLIST: 6,
  CHECKLIST_TEMPLATE: 7,
  USER_TYPE: 8,
};
