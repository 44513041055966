import React, { useState, useEffect, useRef } from "react";
import Button from "../../../components/Button/Button.component";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginDetails, selectUserDetails } from "../../../redux/user/user.selectors";
import { updateUser, login } from "../../../redux/user/user.action";
import alertConstant from "../../../constant/messages/toaster.json";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";

import units from "../../../constant/units";
import {
  MFAWrapper,
  SwitchWrapper,
  OptionsWrapper,
  SignUpModalDiv,
  HeadingContainer,
  CompanyLogoText,
  CompanyLogoDiv,
  CompanyImage,
  ModalBodyHeight,
  Break,
} from "./SignupForm.component.styles";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import RadioButton from "../../../components/RadioButton/Radio.component";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import DropdownComponent from "../../../components/DropdownComponent/DropdownFilterWrapper";
import InputStartAdorment from "./InputStartAdorment";
import CompanyDrawerIcon from "../../../assets/enterprise_logo/default-logo.png";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const timeZone = require("../../../constant/backendTimezones");

const labelStyle = {
  position: "relative",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
  left: "3px",
};

function SignupForm(props) {
  let { loginDetails, login, setSuccessMsg } = props;
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(CompanyDrawerIcon);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const [accountData, setAccountData] = useState({
    companyName: "",
    enterpriseUrl: "",
    unitType: "Imperial",
    companyDescription: "",
    address: "",
    mfaData: {},
  });

  const companyNameInputRef = useRef(null);
  useEffect(() => {
    companyNameInputRef.current && companyNameInputRef.current.focus();
  }, []);

  useEffect(() => {
    setAccountData({
      ...accountData,
      mfaData: {
        ...loginDetails?.mfaData,
      },
    });
  }, [loginDetails]);

  const [crop, setCrop] = useState({ aspect: 1 });
  const [modalVisible, setModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const imageRef = useRef(null);

  const handleImageClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
          setModalVisible(true);
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const onCropComplete = (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      createCroppedImage(crop);
    } else if (imageRef.current) {
      const fullCrop = {
        x: 0,
        y: 0,
        width: imageRef.current.width,
        height: imageRef.current.height,
        unit: "px",
      };
      createCroppedImage(fullCrop);
    }
  };

  const createCroppedImage = (crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      setCroppedImage(blob);
    });
  };

  const handleSaveCroppedImage = () => {
    if (croppedImage) {
      setPreviewImage(URL.createObjectURL(croppedImage));
    }
    setModalVisible(false);
    setImage(croppedImage);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setImageUrl(null);
    setCroppedImage(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("logo", image);
    bodyFormData.append("accountData", JSON.stringify(accountData));
    if (validate()) {
      await apiCall(`/account/${loginDetails.accountId}`, {
        method: "PUT",
        data: bodyFormData,
        type: "file",
      })
        .then(function (response) {
          if (response.data.success) {
            //handle success
            loginDetails.formPending = false;
            loginDetails.logo = response.data.data.logo;
            loginDetails.companyName = response.data.data.companyName;
            loginDetails.enterpriseUrl = response.data.data.enterpriseUrl;
            loginDetails.unitType = response.data.data.unitType;
            loginDetails.mfaData = response.data.data.mfaData;
            login({ ...loginDetails });
            setSuccessMsg(alertConstant.account_profile_update);
            props.onHide(false);
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  };

  // Enterprise URL Check
  const handleEnterpriseUrl = (e) => {
    const fullUrl = e.target.value.trim();
    const actualUrl = fullUrl.substring(fullUrl.indexOf("/") + 1);
    if (accountData.enterpriseUrl === actualUrl) {
      return;
    }
    setAccountData({ ...accountData, enterpriseUrl: actualUrl });

    if (actualUrl) {
      apiCall(`/account/urlAvailabilty/${loginDetails && loginDetails.accountId}/${actualUrl}`)
        .then((response) => {
          if (response.data.success === true) {
            setErrors({ ...errors, enterpriseUrl: "Available" });
          } else if (response.data.success === false) {
            setErrors({ ...errors, enterpriseUrl: "NotAvailable" });
          }
        })
        .catch((error) => {
          setErrors({ ...errors, enterpriseUrl: "NotAvailable" });
        });
    } else {
      setErrors({ ...errors, enterpriseUrl: "" });
    }
  };

  const validate = () => {
    let isValid = true;

    if (!accountData.enterpriseUrl) {
      isValid = true;
    }

    if (!accountData.companyName) {
      isValid = false;

      setErrors((prevState) => ({
        ...prevState,
        companyName: t(validationConstant.company_required),
      }));
    }

    if (errors.enterpriseUrl === "NotAvailable") {
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (accountData.enterpriseUrl) {
      setErrors((prevState) => ({
        ...prevState,
        enterpriseUrl: "",
      }));
    }
    if (accountData.companyName) {
      setErrors((prevState) => ({
        ...prevState,
        companyName: "",
      }));
    }
  }, [accountData]);

  return (
    <SignUpModalDiv>
      <Container>
        <HeadingContainer>
          <Trans>Account Details</Trans>
        </HeadingContainer>

        <form onSubmit={handleSubmit}>
          <Row className="m-2">
            <Col md={6}>
              <InputFieldWrapper
                inputRef={companyNameInputRef}
                id="outlined-basic"
                label={t("Company Name")}
                name="companyName"
                minLength="1"
                maxLength="128"
                value={accountData.companyName}
                placeholder={t("Please enter your Company Name")}
                type="text"
                onChange={(e) => setAccountData({ ...accountData, companyName: e.target.value })}
                required
                isError={errors ? errors.companyName : ""}
                errorMessage={errors ? errors.companyName : ""}
              />

              <InputStartAdorment
                id="enterpriseUrl"
                label={t("Enterprise URL")}
                fullWidth
                required={false}
                name="enterpriseUrl"
                onChange={handleEnterpriseUrl}
                value={accountData.enterpriseUrl}
                startAdornment="manage.siera.ai/#/"
                maxLength="128"
                errors={errors.enterpriseUrl}
                helperText="Create your own URL to access SIERA Dashboard"
                errorMessage={t("Please enter enterprise url")}
              />
              <Break />
              <DropdownComponent
                key={"unitType"}
                id={"unitType"}
                label={t("Unit Type")}
                fullWidth
                required={true}
                name={"unitType"}
                onChange={(value) => setAccountData({ ...accountData, unitType: value })}
                value={accountData.unitType ? accountData.unitType : "Imperial"}
                options={units}
              />
              <Break />
              <DropdownComponent
                key={"timeZone"}
                id={"timeZone"}
                label={t("Timezone")}
                fullWidth
                required={true}
                name={"timeZone"}
                onChange={(value) => setAccountData({ ...accountData, timeZone: value })}
                value={accountData.timeZone || timeZone[0]._id}
                options={timeZone}
              />

              <MFAWrapper>
                <SwitchWrapper>
                  Multi-Factor Authentication{" "}
                  <SwitchComponent
                    onChange={(e) =>
                      setAccountData({
                        ...accountData,
                        mfaData: { ...accountData?.mfaData, status: e.target.checked },
                      })
                    }
                    checked={accountData?.mfaData?.status}
                    name="status"
                  />
                </SwitchWrapper>
                {accountData?.mfaData?.status && (
                  <OptionsWrapper>
                    <RadioButton
                      onChange={(e) =>
                        setAccountData({
                          ...accountData,
                          mfaData: { ...accountData?.mfaData, isMandatory: e.target.checked },
                        })
                      }
                      checked={accountData?.mfaData?.isMandatory}
                      label="Required"
                      name="isMandatory"
                      size="small"
                      id="required"
                    />
                    <RadioButton
                      onChange={(e) =>
                        setAccountData({
                          ...accountData,
                          mfaData: { ...accountData?.mfaData, isMandatory: !e.target.checked },
                        })
                      }
                      checked={!accountData?.mfaData?.isMandatory}
                      label="Optional"
                      name="isMandatory"
                      size="small"
                      id="optional"
                    />
                  </OptionsWrapper>
                )}
              </MFAWrapper>
            </Col>
            <Col md={6}>
              {/* Logo*/}

              <CompanyLogoText>
                <Trans>Company Logo</Trans>
              </CompanyLogoText>
              <CompanyLogoDiv onClick={handleImageClick}>
                <CompanyImage src={previewImage} alt="Selected Logo" />
              </CompanyLogoDiv>

              <ModalComponent
                size="lg"
                centered
                backdrop="static"
                title={t("Crop Image")}
                handleClose={handleCloseModal}
                show={modalVisible}
                onHide={handleCloseModal}
              >
                <ModalBodyHeight>
                  <Modal.Body>
                    {imageUrl && (
                      <ReactCrop
                        src={imageUrl}
                        crop={crop}
                        onImageLoaded={(img) => (imageRef.current = img)}
                        onComplete={onCropComplete}
                        onChange={(newCrop) => setCrop(newCrop)}
                      />
                    )}
                  </Modal.Body>
                </ModalBodyHeight>
                <Modal.Footer>
                  <Button label="Close" onClick={handleCloseModal} />
                  <Button label="Save" onClick={handleSaveCroppedImage} />
                </Modal.Footer>
              </ModalComponent>
            </Col>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            <Col></Col>
            <Col className="text-right">
              <Button label={t("Submit")} showIcon iconClass="save" buttonType="save" />
            </Col>
          </Row>
        </form>
      </Container>
    </SignUpModalDiv>
  );
}

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
  userDetails: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUser(user)),
  login: (userDetails) => dispatch(login(userDetails)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupForm));
