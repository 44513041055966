export const initialState = {
  //For Checklist Lists
  checklistLoading: false,
  checklistSuccess: false,
  checklistError: false,
  checklists: [],

  //For checklist template
  checklistTemplatesLoading: false,
  checklistTemplatesSuccess: false,
  checklistTemplatesError: false,
  templateTotalCount: 0,
  checklistTemplates: [],

  // For Checklist Create
  createChecklistLoading: false,
  createChecklistSuccess: false,
  createChecklistError: false,

  // For Checklist Update
  updateChecklistLoading: false,
  updateChecklistSuccess: false,
  updateChecklistError: false,

  // For Section Update
  addUpdateSectionLoading: false,
  addUpdateSectionSuccess: false,
  addUpdateSectionError: false,

  // For Question Update
  addUpdateQuestionLoading: false,
  addUpdateQuestionSuccess: false,
  addUpdateQuestionError: false,

  // For Section Remove
  removeSectionLoading: false,
  removeSectionSuccess: false,
  removeSectionError: false,

  // For Question Remove
  removeQuestionLoading: false,
  removeQuestionSuccess: false,
  removeQuestionError: false,

  // Checklist Overview
  checklistOverview: null,
  checklistOverviewLoading: true,

  // Response Type
  responseTypeLoading: false,
  responseTypeSuccess: false,
  responseTypeList: [],

  // Edit template
  editTemplate: {},
};
