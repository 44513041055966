/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA DASHBOARD PAGE USER MANAGMENT TYPES
 */

export const checklistManagementTypes = {
  // 1. TYPE: CHECKLIST LIST
  LIST_CHECKLISTS: "LIST_CHECKLISTS",

  // 2. TYPE: ADD CHECKLIST
  ADD_CHECKLIST: "ADD_CHECKLIST",

  // 3. TYPE: DEFAULT CHECKLIST OVERVIEW
  DEFAULT_CHECKLIST_OVERVIEW: "DEFAULT_CHECKLIST_OVERVIEW",

  // 4. TYPE: SELECTED CHECKLIST OVERVIEW
  CHECKLIST_OVERVIEW: "CHECKLIST_OVERVIEW",

  // 5. TYPE: UPDATE CHECKLIST AFTER CHECKLIST UPDATE
  UPDATE_CHECKLIST: "UPDATE_CHECKLIST",
};
