export const BILLING_PLANS = [
  {
    id: 0,
    planId: "price_1I9EwoJF2YbPc3KaLiUt3Gob",
    title: "Basic",
    amount: 0,
    time: "FOREVER",
    details: "No credit card required",
    perks: [
      {
        id: 0,
        title: "Perform up to 10 inspections per month",
      },
      {
        id: 2,
        title: "View only current month's inspections",
      },
    ],
  },
  {
    id: 1,
    yearly: {
      planId: "price_1I9EwYJF2YbPc3Ka2qdnIyrF",
      flag: "MOST POPULAR",
      time: "PER MONTH",
      amount: "6.40",
      subTitle: "Annual",
      savings: "Save 20%",
      details: "per asset, billed annualy",
    },
    monthly: {
      planId: "plan_pro",
      flag: "",
      time: "PER MONTH",
      amount: "8.00",
      subTitle: "Monthly",
      savings: "",
      details: "per asset, billed monthly",
    },
    title: "Pro",
    perks: [
      {
        id: 0,
        title: "Perform unlimited inspections",
      },
      {
        id: 2,
        title: "Keep track of inspection history",
      },
      {
        id: 3,
        title: "Download CSV reports",
      },
    ],
  },
  {
    id: 3,
    planId: "",
    flag: "",
    title: "Enterprise",
    details: "tailored solutions for you",
    subTitle: "Working with a large number of assets?",
    description: "Connect with us to find the best solutions tailored to fit the needs of your organization",
  },
];

export const PLAN_IDs = ["price_1I9EwoJF2YbPc3KaLiUt3Gob", "price_1I9EwYJF2YbPc3Ka2qdnIyrF", "plan_pro"];

export const PLAN_TO_ID = {
  Basic: "price_1I9EwoJF2YbPc3KaLiUt3Gob",
  Pro: "price_1I9EwYJF2YbPc3Ka2qdnIyrF",
  Enterprise: "plan_pro",
};
