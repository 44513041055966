import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import DataTable from "../../../../../../components/DataTableComponent/DataTable.component";
import {
  tableHeader,
  rowPerPageDropdown,
  mobileCheckboxAccordion,
  mobileCheckboxDateTime,
  accordionFields,
} from "./constants";

import { categoryValueToLabelMap, eventValueToLabelMapping } from "../../constants";

import { StyledSpan, LoaderOverlay } from "./LoginRecordsDataTable.styles";
import { getTimezoneShortCode } from "../../../../../../utils/timezoneConvert";
import SieraLoader from "../../../../../../components/SieraLoader/SieraLoader.component";
import Checkbox from "../../../../../../components/Checkbox/Checkbox.component";

const DataTableWrapper = ({
  isSuperAdmin,
  isSiteAdmin,
  handlePageLength,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  rowHeight,
  data,
  totalCount,
  handleSelectAll,
  isAllSelected,
  handleCheckboxClick,
  downloadReportSet,
  currentTimeZone,
  isLoadingRecords,
}) => {
  const { t } = useTranslation();

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })
    .filter((column) => column.show)
    .map((data) => {
      if (data.name === "Account") {
        return {
          name: data.name,
          selector: (row) => row?.accountId?.companyName || "NA",
        };
      }
      if (data.name === "Worksite") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.siteId?.name || "NA";
          },
        };
      }

      if (data.name === "Asset") {
        return {
          name: data.name,
          selector: (row) => row?.assetId?.assetName || "NA",
        };
      }

      if (data.name === "User") {
        return {
          name: data.name,
          selector: (row) => row?.userId?.fullName || "NA",
        };
      }

      if (data.name === "Date/Time") {
        return {
          name: data.name,
          selector: (row) =>
            row?.createdAt
              ? `${moment(row?.createdAt)
                  .utcOffset(currentTimeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm A")} ${getTimezoneShortCode(currentTimeZone)}`
              : "-",
        };
      }

      if (data.name === "Event Category") {
        return {
          name: data.name,
          selector: (row) => (row?.cat ? categoryValueToLabelMap[row?.cat] : "NA"),
        };
      }

      if (data.name === "Event") {
        return {
          name: data.name,
          selector: (row) => (row?.event ? eventValueToLabelMapping[row?.event] : "NA"),
        };
      }

      return {
        name: data.name,
        selector: (row) => row?.data?.fieldName || "NA",
      };
    });

  // This column accordion only
  const columnAccordion = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "User Type") {
        return {
          name: data.name,
          selector: (row) => row?.userId?.userTypeId?.name || "NA",
        };
      }

      if (data.name === "Unlock Method") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.event === "unlock" ? capitalizeFirstLetter(row?.data?.unLockMethod || "NA") : "N-A";
          },
        };
      }

      if (data.name === "Connection Type") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.event === "i_disconnected" || row?.event === "i_connected"
              ? capitalizeFirstLetter(row?.data?.connectionType || "NA")
              : "N-A";
          },
        };
      }

      if (data.name === "Platform") {
        return {
          name: data.name,
          selector: (row) => row?.platform || "NA",
        };
      }

      if (data.name === "Login Method") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.event === "config_update" || row?.event === "admin_menu_login"
              ? capitalizeFirstLetter(row?.data?.loginMethod || "NA")
              : "N-A";
          },
        };
      }

      if (data.name === "Lock Reason") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.event === "lock" ? capitalizeFirstLetter(row?.data?.lockReason || "NA") : "N-A";
          },
        };
      }

      return {
        name: data.name,
        selector: (row) => row?.data?.fieldName || "NA",
      };
    });

  columns.unshift(...columnsWithCheckboxes);
  if (!Array.isArray(data)) {
    return <StyledSpan height={rowHeight}>No Troubleshoot logs available</StyledSpan>;
  }

  return (
    <>
      {isLoadingRecords && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}
      <DataTable
        title=""
        columns={columns}
        data={data}
        pagination
        responsive
        dense
        rowsPerPageOptions={[10, 25, 50, 100]}
        //  loading={false}
        rowHeight={"400"}
        scrollable={true}
        fixedHeader
        noDataMessage="No Troubleshoot logs available"
        pageSize={pageSize}
        pageNo={pageNo}
        handlePageLength={handlePageLength}
        paginationOptions={rowPerPageDropdown}
        previousPage={previousPage}
        nextPage={nextPage}
        accordion={true}
        columnAccordian={columnAccordion}
        accordianColumns={accordionFields}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordian={mobileCheckboxAccordion}
        totalPageReport={totalCount}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DataTableWrapper);
