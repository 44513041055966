import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import Error404 from "../view/Misc/Error404.component";
import Error500 from "../view/Misc/Error500.component";

const ErrorRoutes = () => (
  <>
    <Switch>
      <Route exact path="/app/error/500" component={Error500}></Route>
      <Route exact path="/app/error/404" component={Error404}></Route>
    </Switch>
  </>
);

export default ErrorRoutes;
