import Skeleton from "react-loading-skeleton";

function LoaderSkeletonList({ showLoader }) {
  return (
    <>
      {showLoader &&
        ["", "", "", "", "", "", "", "", "", "", ""].map(() => (
          <tr className="tableTr" style={{ cursor: "pointer" }}>
            <td>
              <Skeleton count={1} />
            </td>
          </tr>
        ))}
    </>
  );
}

export default LoaderSkeletonList;
