import styled from "styled-components";
export const ManagementRightBottomWrapper = styled.div`
  margin-top: 1vh;
  height: calc(100vh - 318px);
  border: 1px solid rgb(242, 242, 242);
  border-radius: 4px;
  overflow: hidden;
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    height: 100%;
  }
  .title-style {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }

  @media screen and (max-width: 1100px) {
    height: auto;
    margin-bottom: 50px;
  }
`;

export const AccountWrapper = styled.div``;
