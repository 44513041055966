import { configTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import impactThresholdDefaultSetting from "../../constant/impactThresholdDefaultSettings.json";

// To set the impact threshold success
export const getImpactThresholdSuccess = (data) => ({
  type: configTypes.GET_IMPACT_THRESHOLD_PLAN_SUCCESS,
  data: data,
});

// To set the impact threshold loading
export const getImpactThresholdLoading = () => ({
  type: configTypes.GET_IMPACT_THRESHOLD_PLAN_LOADING,
});

//To get the impact threshold
export const getImpactThreshold = (assetId) => {
  return async (dispatch, getState) => {
    dispatch(getImpactThresholdLoading());
    apiCall(`/systemSettings/impactThresholdSettings/${assetId}`, {
      method: "GET",
    })
      .then((response) => {
        dispatch(
          getImpactThresholdSuccess(
            response?.data?.data?.impactThresholdSettings || impactThresholdDefaultSetting
          )
        );
      })
      .catch((err) => {});
  };
};

// To update the impact threshold success
export const updateImpactThresholdSuccess = (data) => ({
  type: configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_SUCCESS,
  data: data,
});

// To update the impact threshold loading
export const updateImpactThresholdLoading = () => ({
  type: configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_LOADING,
});

// To update the impact threshold error
export const updateImpactThresholdError = () => ({
  type: configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_ERROR,
});

//implementation part of copy of previous code -DBM
export const resetImpactThresholdByAssetId = (assetId, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(updateImpactThresholdLoading());
    callbackAfterUpdate(false);

    apiCall(`/systemSettings/impactThresholdSettings/${assetId}`, {
      method: "PATCH",
    })
      .then((response) => {
        callbackAfterUpdate(true);
        dispatch(updateImpactThresholdSuccess(response?.data?.data));
      })
      .catch((err) => {
        dispatch(updateImpactThresholdError());
      });
  };
};

//implementation part of copy of previous code -DBM
export const updateImpactThresholdByAssetId = (assetId, data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(updateImpactThresholdLoading());
    callbackAfterUpdate?.(false);

    apiCall(`/systemSettings/impactThresholdSettings/${assetId}`, {
      method: "PUT",
      data: { impactThresholdSettings: data },
    })
      .then((response) => {
        callbackAfterUpdate?.(true);
        dispatch(updateImpactThresholdSuccess(response?.data?.data?.impactThresholdSettings));
      })
      .catch((err) => {
        dispatch(updateImpactThresholdError());
      });
  };
};
