import { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Popover, OverlayTrigger, Modal } from "react-bootstrap";
import { setEditForm } from "../../../../../redux/forms/action";
import {
  OperatorCertificateWrapper,
  Row,
  Column,
  RowsContainer,
  ImageWrapper,
  CertificateEditDeleteButton,
  NoData,
  InformationTooltip,
  ImageWrapperInfo,
  OperatorCertificateConfirmation,
  OperatorButtonCancel,
  AddCertificateButton,
  MobileView,
} from "./OperatorCertificatesTab.styles";

import {
  CertificateExpired,
  CertificateExpiring30Days,
  CertificateExpiringTomorrow,
  CertificateInformationPNG,
  CertificateOk,
  DeleteIcon,
} from "../../../../../assets/certificates";
import { ReactComponent as EditIcon } from "../../../../../assets/AddIcon/edit.svg";

import UserEditComponent from "../UserEdit.component";
import { vehicleClassesByValue } from "../../operatorVerification/constants";
import AddEditModal from "./AddEditModal.component";

const OperatorCertificatesTab = (props) => {
  const { userDetails, setUserDetails, isSuperAdmin, editForm, handleUpdateUser, activeTab } = props;
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState({});
  const { certificates } = userDetails;
  const { t } = useTranslation();
  const [allCertificates, setAllCertificates] = useState(certificates);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [certificateIdToDelete, setCertificateIdToDelete] = useState(null);
  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false);
  const getDeleteConfirmation = (id) => {
    setShowConfirmationModal(true);
    setCertificateIdToDelete(id);
  };

  const deleteCertificateHandler = () => {
    const certificates = allCertificates.filter((certificate) => certificate._id !== certificateIdToDelete);
    setAllCertificates(certificates);
    setUserDetails({
      ...userDetails,
      certificates,
    });

    setShowConfirmationModal(false);
    setCertificateIdToDelete(null);
  };

  const cancelHandler = () => {
    setShowConfirmationModal(false);
    setCertificateIdToDelete(null);
  };

  useEffect(() => {
    setAllCertificates(certificates);
    if (isReadyToUpdate) {
      setIsReadyToUpdate(false);
      handleUpdateUser();
    }
  }, [certificates]);

  useEffect(() => {
    if (editForm) {
      setAllCertificates(certificates);
    }
  }, [editForm]);

  const editCertificateHandler = (certificate) => {
    setCurrentCertificate(certificate);
    setIsEdit(true);
    setShowAddEditModal(true);
  };

  const handleAddCertificate = () => {
    setCurrentCertificate({});
    setIsEdit(false);
    setShowAddEditModal(true);
  };

  const toggleModal = () => {
    setShowAddEditModal(false);
  };

  const getCertificateLabel = (classArr) =>
    classArr.map((certClass) => vehicleClassesByValue[certClass].label).join(", ");

  const getCertificateValidityIcon = (date) => {
    const dateCount = moment(date).diff(moment(), "days");
    if (dateCount < 0) {
      return CertificateExpired;
    } else if (dateCount < 7) {
      return CertificateExpiringTomorrow;
    } else if (dateCount < 30) {
      return CertificateExpiring30Days;
    } else {
      return CertificateOk;
    }
  };

  const getCertificateValidityIconText = (date) => {
    const dateCount = moment(date).diff(moment(), "days");
    if (dateCount < 0) {
      return <span>Certificate Expired</span>;
    } else if (dateCount < 7) {
      return <span>Certificate Expiring in 7 days</span>;
    } else if (dateCount < 30) {
      return <span>Certificate Expiring in 30 days</span>;
    } else {
      return <span>Certificate Valid</span>;
    }
  };

  const updateBackend = () => {
    setShowAddEditModal(false);
    if (isEdit) {
      const tempCertificates = certificates.map((certificate) => {
        if (certificate._id === currentCertificate._id) {
          return currentCertificate;
        } else {
          return certificate;
        }
      });

      setUserDetails({
        ...userDetails,
        certificates: tempCertificates,
      });
    } else {
      setUserDetails({
        ...userDetails,
        certificates: [...userDetails.certificates, currentCertificate],
      });
      //Below is the workaround as userDetails is not in store. Move that to store when time permits
      setIsReadyToUpdate(true);
    }
  };

  return (
    <OperatorCertificateWrapper className="operatorEditButtonResponsive">
      {allCertificates.length > 0 ? (
        <>
          <Row isHeader={true}>
            <Column isHeader={true} align={"left"}>
              <OverlayTrigger
                placement="right"
                trigger="click"
                rootClose={true}
                overlay={
                  <Popover>
                    <Popover.Title as="h3">
                      <InformationTooltip>
                        <span className="headingPopover">
                          <Trans>Certificate Status</Trans>
                        </span>
                        <span>
                          <ImageWrapperInfo src={CertificateOk} alt={"Certificate Valid"} />
                          <Trans>Certificate Valid</Trans>
                        </span>
                        <span>
                          <ImageWrapperInfo src={CertificateExpiring30Days} alt={"Certificate Expiring"} />
                          <Trans>Certificate Expiring in 30 days</Trans>
                        </span>
                        <span>
                          <ImageWrapperInfo src={CertificateExpiringTomorrow} alt={"Certificate Expiring"} />
                          <Trans>Certificate Expiring in 7 days</Trans>
                        </span>
                        <span>
                          <ImageWrapperInfo src={CertificateExpired} alt={"Certificate Expired"} />
                          <Trans>Certificate Expired</Trans>
                        </span>
                      </InformationTooltip>
                    </Popover.Title>
                  </Popover>
                }
              >
                <ImageWrapper
                  isHeader={true}
                  src={CertificateInformationPNG}
                  alt={"CertificateInformation"}
                />
              </OverlayTrigger>
            </Column>
            <Column isHeader={true} align={"left"}>
              <Trans>User Certificates</Trans>
            </Column>
            <Column isHeader={true} align={"center"}>
              <Trans>Issue Date</Trans>
            </Column>
            <Column isHeader={true} align={"center"}>
              <Trans>Valid Till</Trans>
            </Column>
            <Column
              isHeader={true}
              align={"right"}
              style={{ textAlign: "right", flexDirection: "column", display: "block" }}
            >
              <Trans>Actions</Trans>
            </Column>
          </Row>
          <RowsContainer isSuperAdmin={isSuperAdmin}>
            {allCertificates.map((certificate, i) => (
              <Row key={i}>
                <Column align={"left"} className="firstCol">
                  <OverlayTrigger
                    placement="right"
                    trigger="hover"
                    overlay={
                      <Popover>
                        <Popover.Title as="h3">
                          <InformationTooltip>
                            <span className="colorCertificate">
                              <Trans>{getCertificateValidityIconText(certificate.expiryDate)}</Trans>
                            </span>
                          </InformationTooltip>
                        </Popover.Title>
                      </Popover>
                    }
                  >
                    <ImageWrapper
                      src={getCertificateValidityIcon(certificate.expiryDate)}
                      alt={"CertificateExpired"}
                    />
                  </OverlayTrigger>
                </Column>
                <Column align={"left"} display={"block"} className="widthMobile">
                  <span
                    className="classNameReg"
                    title={getCertificateLabel(certificate.certifiedVehicleClasses)}
                  >
                    {getCertificateLabel(certificate.certifiedVehicleClasses)}
                  </span>
                </Column>
                <Column align={"center"}>
                  <MobileView className="spaceCss">
                    <Trans>Issue Date: </Trans>
                  </MobileView>
                  {moment(certificate.issueDate).format("MM/DD/YYYY")}
                </Column>
                <Column align={"center"}>
                  <MobileView className="spaceCss">
                    <Trans>Valid Till:</Trans>
                  </MobileView>
                  {moment(certificate.expiryDate).format("MM/DD/YYYY")}
                </Column>
                <Column align={"right"} className="editButtonMobile">
                  <CertificateEditDeleteButton
                    disabled={editForm}
                    onClick={() => editCertificateHandler(certificate)}
                  >
                    <EditIcon />
                  </CertificateEditDeleteButton>
                  <CertificateEditDeleteButton
                    disabled={editForm}
                    onClick={() => getDeleteConfirmation(certificate._id)}
                  >
                    <DeleteIcon />
                  </CertificateEditDeleteButton>
                </Column>
              </Row>
            ))}
          </RowsContainer>
        </>
      ) : (
        <NoData isSuperAdmin={isSuperAdmin}>
          <Trans>No Valid Certificate present</Trans>
        </NoData>
      )}
      <UserEditComponent
        handleUpdateUser={handleUpdateUser}
        addCertificate={true}
        handleAddCertificate={handleAddCertificate}
        activeTab={activeTab}
      />
      <AddEditModal
        {...{
          show: showAddEditModal,
          toggleModal: toggleModal,
          headerLabel: isEdit ? t("Edit Certificate") : t("Add Certificate"),
          certificate: currentCertificate,
          setCertificate: setCurrentCertificate,
          saveHandler: updateBackend,
        }}
      />

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={showConfirmationModal}
      >
        <Modal.Body>
          <OperatorCertificateConfirmation>
            <p>
              Once you delete this document it will be lost forever. Are you sure you want to delete this
              certificate?
            </p>
            <AddCertificateButton onClick={deleteCertificateHandler}>
              <Trans>Confirm</Trans>
            </AddCertificateButton>
            <br />
            <OperatorButtonCancel onClick={cancelHandler}>CANCEL</OperatorButtonCancel>
          </OperatorCertificateConfirmation>
        </Modal.Body>
      </Modal>
    </OperatorCertificateWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setEditForm: (data) => dispatch(setEditForm(data)),
});

const mapStateToProps = (state) => ({
  editForm: state.forms.editForm,
  isSuperAdmin: state.user.isSuperAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorCertificatesTab);
