export const Style = (theme) => ({
  snackBarContent: {
    backgroundColor: "#11A70B",
    color: theme.palette.common.white,
  },
  failed: {
    backgroundColor: "#dc004e",
    color: theme.palette.common.white,
  },
  success: {
    backgroundColor: "#11A70B",
    color: theme.palette.common.white,
  },
});
