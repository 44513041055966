import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import AccountsActionBar from "../../Account/AccountsActionBar.component";
import ManagementPanelStyles from "./ManagementPanel.module.css";
import AccountCard from "../../Account/AccountCard.component";
import AccountList from "../../Account/AccountList.component";
import AccountManagement from "../../Account/AccountManagement.component";
//import SearchBox from "../../../../components/SearchBox.component";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import AccountOverview from "../../Account/AccountOverview.component";
import { ReactComponent as rightIcon } from "../../../../assets/rightIconwhite.svg";
import {
  AccountPanelStyled,
  MobileView,
  UserTypeDetailHeading,
  ActionBarWrapper,
  RightIconImage,
  ActionBarButtons,
  DesktopView,
  ButtonsContainer,
  SearchFilter,
  SearchBoxWrapper,
  FilterWrapper,
  CloseBackDiv,
  CloseIconDiv,
  FilterBackDiv,
  FilterOnclickDes,
  FilterDesWrapper,
  FilterIconDiv,
} from "./accountPanel.styles";

function AccountPanel({ updateActiveNavItem, companyName, email }) {
  const [SearchText, setSearchText] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showAccountDetail, setShowAccountDetail] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("accounts");
  }, []);

  return (
    <AccountPanelStyled>
      {/* Account Action Bar */}
      <DesktopView>
        {/* <ButtonsContainer>
          <AccountsActionBar />
        </ButtonsContainer> */}
        <AccountManagement />
      </DesktopView>
      <MobileView>
        <ActionBarWrapper>
          <ActionBarButtons>
            <AccountsActionBar />
          </ActionBarButtons>
        </ActionBarWrapper>

        {!showAccountDetail ? <AccountCard /> : ""}
        {!showAccountDetail ? (
          <>
            <SearchFilter>
              <SearchBoxWrapper>
                <SearchBox
                  searchText={SearchText}
                  setSearchText={setSearchText}
                  placeholder={t("Search an account")}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </SearchBoxWrapper>
            </SearchFilter>
          </>
        ) : (
          ""
        )}
        {!showAccountDetail ? (
          <div
            onClick={() => setShowAccountDetail(true)}
            // className={`${ManagementPanelStyles.mobileListingSection}`}
          >
            <AccountList
              setImageLoaded={() => setImageLoaded(false)}
              searchText={SearchText}
              setSearchText={setSearchText}
              style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            />
          </div>
        ) : (
          <>
            <UserTypeDetailHeading>
              <span onClick={() => setShowAccountDetail(false)}>Your Accounts</span>
              <RightIconImage className="icon icon-arrow-right"></RightIconImage>
              {` ${companyName || email || ""}`}
            </UserTypeDetailHeading>
            <AccountOverview imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
          </>
        )}
      </MobileView>
    </AccountPanelStyled>
  );
}

const mapStateToProps = (state) => ({
  companyName: state.accounts.AccountOverview?.companyName,
  email: state.accounts.AccountOverview?.email,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountPanel));
