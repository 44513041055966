import {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  TO_RESET_MEDIA_STATE,
  TO_STORE_SELECTED_ASSET,
} from "./types";
import initialState from "./initialState";
import { uniqBy } from "lodash";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_TYPE_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case GET_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        AssetTypes: action.data,
      };

    case GET_ASSET_TYPE_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    case GET_ALL_ASSET_LOADING:
      return {
        ...state,
        AssetLoading: true,
        AssetSuccess: false,
        AssetError: false,
      };

    case GET_ALL_ASSET_SUCCESS:
      console.log(action.data);
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: true,
        AssetError: false,
        Assets: action.data.data.filter((asset) => asset.assetChecklists.length > 0 && asset.AssetStatus),
      };

    case GET_ALL_ASSET_ERROR:
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: false,
        AssetError: true,
      };

    case GET_ASSET_CHECK_LIST_LOADING:
      return {
        ...state,
        AssetCheckListLoading: true,
        AssetCheckListSuccess: false,
        AssetCheckListError: false,
      };

    case GET_ASSET_CHECK_LIST_SUCCESS:
      return {
        ...state,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: true,
        AssetCheckListError: false,
        AssetCheckList: action.data,
      };

    case GET_ASSET_CHECK_LIST_ERROR:
      return {
        ...state,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: false,
        AssetCheckListError: true,
      };

    case UPLOAD_MEDIA_LOADING:
      return {
        ...state,
        MediaLoading: true,
        MediaSuccess: false,
        MediaError: false,
      };

    case UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: true,
        MediaError: false,
        Media: action.data.data,
      };

    case UPLOAD_MEDIA_ERROR:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: false,
        MediaError: true,
      };

    case POST_ANSWER_LOADING:
      return {
        ...state,
        AnswerLoading: true,
        AnswerSuccess: false,
        AnswerError: false,
      };

    case POST_ANSWER_SUCCESS:
      return {
        ...state,
        AnswerLoading: false,
        AnswerSuccess: true,
        AnswerError: false,
        Result: action.data.message,
      };

    case POST_ANSWER_ERROR:
      return {
        ...state,
        AnswerLoading: false,
        AnswerSuccess: false,
        AnswerError: true,
      };

    case TO_RESET_MEDIA_STATE:
      return {
        ...state,
        MediaLoading: false,
        MediaSuccess: false,
        MediaError: false,
        Media: null,
      };

    case TO_RESET_ALL_ASSET_STATE:
      return {
        ...state,
        AnswerLoading: false,
        AnswerSuccess: false,
        AnswerError: false,
        Result: null,
        AssetCheckListLoading: false,
        AssetCheckListSuccess: false,
        AssetCheckListError: false,
        AssetCheckList: [],
      };

    case TO_STORE_SELECTED_ASSET:
      return {
        ...state,
        SelectedAsset: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
