export const initialState = {
  uploadImageLoading: false,
  uploadImageError: false,
  uploadImageSuccess: false,
  deleteImageLoading: false,
  deleteImageError: false,
  deleteImageSuccess: false,
  getInspectionLoading: true,
  getInspectionError: false,
  getInspectionSuccess: false,
  checklist: [],
  postInspectionRepsonseLoading: false,
  postInspectionRepsonseError: false,
  postInspectionRepsonseSuccess: false,
  selectedAsset: "",
};
