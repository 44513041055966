import {default as localForage} from "localforage"
export class LForageCache {
  prefix = ""; // not necessary
  storage = null;
  constructor() {
    this.storage = localForage.createInstance({
      driver: [
        localForage.INDEXEDDB,
        localForage.LOCALSTORAGE,
        // localForage.WEBSQL,
      ],
      name: "sie_fbc", // These fields
      version: 1.0, // are totally optional
    });
  }
  get = async function (key, withPrefix = true) {
    if (!this.storage) {
      return null;
    }

    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    let _value = null;
    try {
      _value = await this.storage.getItem(_key);
      return JSON.parse(_value);
    } catch (e) {
      return _value;
    }
  };
  set = async function (key, value, withPrefix = true, shouldAwait = false) {
    if (!this.storage) {
      console.log("OOPS Local Forage Not found ...cache will not work ..");
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    let _value;
    try {
      _value = JSON.stringify(value);
    } catch (e) {
      _value = value;
    }
    try {
      if (shouldAwait) {
        await this.storage.setItem(_key, _value);
      } else {
        this.storage.setItem(_key, _value);
      }
    } catch (err) {
      console.log(err);
      if (err instanceof DOMException) {
        console.log("LOCAL Forage FULL ....!!");
        this.purge();
        try {
          this.storage.setItem(_key, _value);
        } catch (err) {}
      }
    }
  };
  //ttl in millis
  setWithExpiry = async function setWithExpiry(key, value, ttl, withPrefix = true, shouldAwait = false) {
    if (!this.storage) {
      console.log("OOPS Local Forage Not found ...cache will not work ..");
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    let _value;
    try {
      _value = JSON.stringify(item);
    } catch (e) {
      _value = item;
    }
    try {
      if (shouldAwait) {
        await this.storage.setItem(_key, _value);
      } else {
        this.storage.setItem(_key, _value);
      }
    } catch (err) {
      console.log(err);
      if (err instanceof DOMException) {
        console.log("LOCAL Forage FULL ....!!");
        this.purge();
        try {
          this.storage.setItem(key, JSON.stringify(item));
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  getWithExpiry = async function getWithExpiry(key) {
    if (!this.storage) {
      return null;
    }
    const itemStr = await this.storage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      this.storage.removeItem(key);
      return null;
    }
    console.log("came from cache");
    return item.value;
  };

  remove = async function (key, withPrefix = false) {
    if (!this.storage) {
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    return await this.storage.removeItem(_key);
  };
  async removeWhere(testFn) {
    // Get a copy of the keys array - it won't be modified when we remove items from storage
    const keys = await this.storage.keys();
    console.log(keys);
    for (const key of keys) {
      const value = await this.get(key);
      if (testFn(key, value) === true) {
        await this.remove(key, false);
      }
    }
    // return new Promise(function (resolve,reject){
    //   if (!this.storage) {
    //     resolve(null);
    //   }
    //   return this.storage.iterate(async  function(value, key, iterationNumber) {
    //       // Resulting key/value pair -- this callback
    //       // will be executed for every item in the
    //       // database.
    //       if (testFn(key, value) === true) {
    //           await this.remove(key)
    //       }
    //   }).then(function() {
    //       // console.log('Iteration has completed');
    //       resolve(true)
    //   }).catch(function(err) {
    //       // This code runs if there were any errors
    //       console.log(err);
    //       reject(err)
    //   });
    // })
  }
  keys = async function () {
    if (!this.storage) {
      return [];
    }
    let ret = [];

    ret = await this.storage.keys();
    return ret;

    //below was trying using the iterator way had some issue with the async await callback..!!
    // this.storage.iterate(function(value, key, iterationNumber) {
    //   // Resulting key/value pair -- this callback
    //   // will be executed for every item in the
    //   // database.
    //   ret.push(key);
    // }).then(function() {
    //     // console.log('Iteration has completed');
    // }).catch(function(err) {
    //     // This code runs if there were any errors
    //     console.log(err);
    // });
  };

  has = function (key) {
    if (!this.storage) {
      return false;
    }
    return this.getWithExpiry(key);
  };
  purge = function () {
    if (!this.storage) {
      return false;
    }
    this.storage.clear();
  };
}
