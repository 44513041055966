export const tableHeader = [
  { name: "Account", space: 0, feildName: "accountName" },
  { name: "Worksite", space: 0, feildName: "worksite" },
  { name: "Asset Type", space: 0, feildName: "assetType" },
  { name: "Incident By", space: 0, feildName: "assetId" },
  { name: "Asset Name", space: 0, feildName: "assetName" },
  { name: "Date, Time", space: 0, feildName: "createdAt" },
  // { name: "Impact (g)", space: 1, feildName: "impactG" },
  { name: "Incident Type", space: 0, feildName: "impactSeverity" },
  { name: "Actions", space: 1, feildName: "inspectionCountMonthly" },
];

export const mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, feildName: "siteName" },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 1,
    feildName: "fullName",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    feildName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    feildName: "impactRecordedAt",
  },
];

export const tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    feildName: "companyName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, feildName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    feildName: "AssetTypeName",
  },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 0,
    feildName: "fullName",
  },

  {
    name: "Asset Name",
    keyName: "assetImpacted",
    space: 0,
    feildName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    feildName: "impactRecordedAt",
  },
  {
    name: "Impact Severity",
    keyName: "impactSeverity",
    isStatus: true,
    space: 0,
    feildName: "impactSeverity",
    feildName2: "incidentObject",
  },
];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
  { name: "User", _id: "inspectedBy" },
  { name: "Incident Type", _id: "incidentType" },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectedBy: "User",
  incidentType: "Incident Type",
};
