import { mobileViewTypes } from "./types";
import { initialState } from "./initialState";

export const mobileViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case mobileViewTypes.SET_MANAGEMENT_ACTION_BAR_STATUS:
      return {
        ...state,
        showManagementActionBar: action.data,
      };

    case mobileViewTypes.SET_SHOW_FILTERS_STATUS:
      return {
        ...state,
        showFilters: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
