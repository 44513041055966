export const initialState = {
  //For Billing Plans
  PlansLoading: false,
  PlansSuccess: false,
  PlansError: false,
  Plans: [],

  //For Customer creation
  CustomerLoading: false,
  CustomerSuccess: false,
  CustomerError: false,
  CustomerDetails: null,

  //For basic plan update
  BasicPlanLoading: false,
  BasicPlanSuccess: false,
  BasicPlanError: false,

  //For getting invoices
  InvoicesLoading: false,
  InvoicesSuccess: false,
  InvoicesError: false,
  Invoices: [],

  // Billing info
  BillingInfo: [],

  //For storing selected plan
  SelectedPlan: null,
};
