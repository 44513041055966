import React, { useEffect, useState } from "react";
import { ReactComponent as SCZForkLiftTopView } from "../../../../../../assets/CameraSettings/Forklift_Topview.svg";
import "../../CameraSetting.css";
import {
  DepthContainer,
  Container,
  Wrapper,
  Row,
  Col,
  InputContainer,
  Input,
  Label,
  DepthImage,
  StyledButton,
  SpanContainer,
} from "./SCZCameraDepthView.styles";
import Button from "../../../../../../components/Button/Button.component";
import { Zone1, Zone2, Zone3, Zone4 } from "../../../../../../assets/CameraSettings/zones";
import { zoneMap, zoneMaxValues, zoneDefaultValues, currentToPreviousZoneMap } from "../constants";

const zoneIdMap = { 1: Zone1, 2: Zone2, 3: Zone3, 4: Zone4 };

const SCZCameraDepthView = (props) => {
  const { forkliftClass, inputValue, setInputValue, obstacle, unitType, s3CameraForm, camIndex } = props;

  const [selectedZone, setSelectedZone] = useState(4);
  const [zoneImage, setZoneImage] = useState(Zone1);
  const [zones, setZones] = useState(zoneDefaultValues);

  useEffect(() => {
    setZoneImage(zoneIdMap[selectedZone]);
  }, [selectedZone]);

  useEffect(() => {
    const currentZone = s3CameraForm[camIndex]?.[obstacle]?.zones;
    setZones(currentZone);
  }, [camIndex, obstacle, s3CameraForm]);

  const handleZoneValueChange = (value, target) => {
    value = Math.min(value, zoneMaxValues[target]);
    setZones((prevZones) => ({ ...prevZones, [target]: value }));

    setInputValue({
      ...inputValue,
      [obstacle]: { ...inputValue[obstacle], zones: { ...zones, [target]: value } },
    });
  };

  const cleanupValue = (value, target) => {
    if (value < 150 && target === "one") {
      value = 150;
    }
    setZones((prevZones) => ({ ...prevZones, [target]: value }));
    setInputValue({
      ...inputValue,
      [obstacle]: { ...inputValue[obstacle], zones: { ...zones, [target]: value } },
    });
  };

  const getButton = (label, zoneNum, colorCode) => {
    return (
      <StyledButton isSelected={selectedZone === zoneNum} colorCode={colorCode}>
        <Button
          label={label}
          buttonType="secondary"
          onClick={() => {
            setSelectedZone(zoneNum);
          }}
        />
      </StyledButton>
    );
  };

  const inputChangeHandler = (e) => {
    e.preventDefault();
    const input = e.target.value;
    if (/[^0-9]/.test(input)) {
      e.target.value = input.replace(/[^0-9]/g, "");
    }
  };

  const sanitizeData = (event, zone) => {
    const previousZone = currentToPreviousZoneMap[zone];
    const previousZoneValue = zones[previousZone];
    if (event.target.value < previousZoneValue) {
      event.target.value = previousZoneValue;
      setZones((prevZones) => ({ ...prevZones, [zone]: previousZoneValue }));
    }

    if (zone === "one" && zones.two < event.target.value) {
      event.target.value = zones.two;
      setZones((prevZones) => ({ ...prevZones, one: zones.two }));
      setInputValue({
        ...inputValue,
        [obstacle]: { ...inputValue[obstacle], zones: { ...zones, one: zones.two } },
      });
    }
  };

  const getInputWithContainer = (isDisabled, id, label, value, zoneNum, zone) => {
    return (
      <>
        <InputContainer disabled={isDisabled}>
          <Label for={id}>{label}</Label>
          <Input
            id={id}
            type="text"
            maxLength={5}
            disabled={isDisabled}
            value={value}
            onFocus={() => {
              setSelectedZone(zoneNum);
            }}
            onChange={(event) => {
              handleZoneValueChange(event.target.value, zone);
            }}
            onBlur={(event) => {
              if (zoneNum === 1 && zone === "one") {
                cleanupValue(event.target.value, "one");
              }
              sanitizeData(event, zone);
            }}
            onInput={inputChangeHandler}
          />
        </InputContainer>
        <SpanContainer disabled={isDisabled}>
          {unitType?.toLowerCase() === "metric" ? "mm" : "ft"}
        </SpanContainer>
      </>
    );
  };
  return (
    <DepthContainer className="camera_width_depth_style speedControl">
      <div className="width-text" />
      <SCZForkLiftTopView
        style={{ width: "150px", height: "100px" }}
        className={`forklift-${forkliftClass?.toLowerCase()}`}
        alt="Forklift Top View"
      />

      <DepthImage src={zoneImage} height="200" alt="Zone" useMap="#depthImageMap" />

      <map name="depthImageMap">
        {zoneMap.map((obj) => {
          return (
            <area
              style={{ cursor: "pointer" }}
              shape="poly"
              coords={obj.coords}
              alt={"Zone " + obj.zone}
              onClick={() => {
                setSelectedZone(obj.zone);
              }}
            />
          );
        })}
      </map>

      {/* below code to cache the image with 0 width and 0 height to to avoid scenario where it renders slow */}
      <img src={Zone1} style={{ width: 0, height: 0 }} alt="" />
      <img src={Zone2} style={{ width: 0, height: 0 }} alt="" />
      <img src={Zone3} style={{ width: 0, height: 0 }} alt="" />
      <img src={Zone4} style={{ width: 0, height: 0 }} alt="" />

      <div className="dot-line-1" />
      <div className="dot-line-2" />
      <div className="dot-line-3" />
      <div className="dot-line-4" />
      <div className="dot-line-7" />

      <div className="dot-line-1" style={{ top: "349px" }} />

      <Container>
        <Wrapper>
          <Row>
            <Col>{getButton("Zone 4", 4, "#11A70B")}</Col>
            <Col align="end">{getInputWithContainer(true, "zone4start", "Zone Depth Start", zones.four)}</Col>
            <Col align="center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Col>
            <Col align="center" className="addFurther">
              and further
            </Col>
          </Row>
          <Row>
            <Col>{getButton("Zone 3", 3, "#DDCC00")}</Col>
            <Col align="end">
              {getInputWithContainer(true, "zone3start", "Zone Depth Start", zones.three)}
            </Col>
            <Col align="center">&nbsp;to&nbsp;</Col>
            <Col align="end">
              {getInputWithContainer(false, "zone3end", "Zone Depth End", zones.four, 3, "four")}
            </Col>
          </Row>
          <Row style={{ marginTop: "13px" }}>
            <Col>{getButton("Zone 2", 2, "#DD9000")}</Col>
            <Col align="end">{getInputWithContainer(true, "zone2start", "Zone Depth Start", zones.two)}</Col>
            <Col align="center">&nbsp;to&nbsp;</Col>
            <Col align="end">
              {getInputWithContainer(false, "zone2end", "Zone Depth End", zones.three, 2, "three")}
            </Col>
          </Row>
          <Row>
            <Col>{getButton("Zone 1", 1, "#DD0000")}</Col>
            <Col align="end">
              {getInputWithContainer(false, "zone1start", "Zone Depth Start", zones.one, 1, "one")}
            </Col>
            <Col align="center">&nbsp;to&nbsp;</Col>
            <Col align="end">
              {getInputWithContainer(false, "zone1end", "Zone Depth End", zones.two, 1, "two")}
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </DepthContainer>
  );
};

export default SCZCameraDepthView;
