import { analyticsTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";
import { CacheTypes } from "../../utils/cache/CacheTypes";

export const selectChart = (data) => ({
  type: analyticsTypes.SELECT_CHART,
  data: data,
});

export const showLoader = (data = true) => ({
  type: analyticsTypes.SHOW_LOADER,
  data: data,
});

export const showError = (data = true) => ({
  type: analyticsTypes.SHOW_ERROR,
  data: data,
});

export const getUserChartsListLoading = (data = true) => ({
  type: analyticsTypes.GET_USER_CHARTS_LIST_LOADING,
  data: data,
});

export const getUserChartsListSuccess = (data) => ({
  type: analyticsTypes.GET_USER_CHARTS_LIST_SUCCESS,
  data: data,
});

export const getUserChartsListError = (err) => ({
  type: analyticsTypes.GET_USER_CHARTS_LIST_ERROR,
  data: err,
});

export const getUserChartsList = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader());
    dispatch(getUserChartsListLoading());
    const state = getState();
    const userId = state.user.loginDetails.userData._id;
    const url = `/userCharts?userId=${userId}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getUserChartsListSuccess(cacheResult1));
        return;
      }
    }
    try {
      const response = await apiCall(url);
      if (cache) {
        if (response?.data?.data) {
          cache.set(url, response.data.data, CacheRefs.getTtlShort());
        }
      }
      dispatch(getUserChartsListSuccess(response.data.data));
    } catch (err) {
      console.log(err);
      dispatch(getUserChartsListError(err));
    } finally {
      dispatch(getUserChartsListLoading(false));
    }
  };
};

export const getAllChartsListLoading = (data = true) => ({
  type: analyticsTypes.GET_ALL_CHARTS_LIST_LOADING,
  data: data,
});

export const getAllChartsListSuccess = (data) => ({
  type: analyticsTypes.GET_ALL_CHARTS_LIST_SUCCESS,
  data: data,
});

export const getAllChartsListError = (err) => ({
  type: analyticsTypes.GET_ALL_CHARTS_LIST_ERROR,
  data: err,
});

export const getAllChartsList = () => {
  return async (dispatch, getState) => {
    // console.log("userCharts", "came here tooo");
    const state = await getState();
    const userId = state.user.loginDetails.userData._id;
    dispatch(showLoader());
    dispatch(getAllChartsListLoading());
    const url = `/userCharts/allCharts?userId=${userId}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getAllChartsListSuccess(cacheResult1));
        return;
      }
    }
    try {
      const response = await apiCall(url);
      if (cache) {
        if (response?.data?.data) {
          cache.set(url, response.data.data, CacheRefs.getTtlShort());
        }
      }
      dispatch(getAllChartsListSuccess(response.data.data));
    } catch (err) {
      console.log(err);
      dispatch(getAllChartsListError(err));
    } finally {
      dispatch(getAllChartsListLoading(false));
    }
  };
};

export const saveUserChartsListLoading = (data = true) => ({
  type: analyticsTypes.SAVE_USER_CHARTS_LIST_LOADING,
  data: data,
});

export const saveUserChartsListSuccess = (data) => ({
  type: analyticsTypes.SAVE_USER_CHARTS_LIST_SUCCESS,
  data: data,
});

export const saveUserChartsListError = (err) => ({
  type: analyticsTypes.SAVE_USER_CHARTS_LIST_ERROR,
  data: err,
});

export const saveUserChartsList = (listOFChartsWithOrder) => {
  return async (dispatch, getState) => {
    const state = await getState();
    const userId = state.user.loginDetails.userData._id;
    dispatch(showLoader());
    dispatch(saveUserChartsListLoading());
    const url = `/userCharts`;
    const cache = CacheManager.getInstance();
    // if (cache) {
    //   const cacheResult1 = await cache.get(url);
    //   if (cacheResult1) {
    //     dispatch(saveUserChartsListSuccess(cacheResult1));
    //     return;
    //   }
    // }
    console.log("saving user charts ::", listOFChartsWithOrder);
    try {
      const response = await apiCall(url, {
        method: "POST",
        data: { userId: userId, charts: listOFChartsWithOrder },
      }); //TODO : chaneg to post ...!!!
      if (cache) {
        if (response?.data?.data) {
          cache.set(url, response.data.data, CacheRefs.getTtlShort());
        }
      }
      dispatch(saveUserChartsListSuccess(response.data.data));
    } catch (err) {
      console.log(err);
      dispatch(saveUserChartsListError(err));
    } finally {
      dispatch(saveUserChartsListLoading(false));
    }
  };
};

export const getJwtTokenForChartsLoading = (data = true) => ({
  type: analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_LOADING,
  data: data,
});

export const getJwtTokenForChartsSuccess = (data) => ({
  type: analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_SUCCESS,
  data: data,
});

export const getJwtTokenForChartsError = (err) => ({
  type: analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_ERROR,
  data: err,
});

export const getJwtTokenForCharts = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader());
    dispatch(getJwtTokenForChartsLoading());
    const url = `/common/mongoChartsToken/123`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getJwtTokenForChartsSuccess(cacheResult1));
        return;
      }
    }
    try {
      const response = await apiCall(url);
      if (cache) {
        if (response?.data?.data) {
          cache.set(url, response.data.data, CacheRefs.getTtlTenMins());
        }
      }
      dispatch(getJwtTokenForChartsSuccess(response.data.data));
    } catch (err) {
      console.log(err);
      dispatch(getJwtTokenForChartsError(err));
    } finally {
      dispatch(getJwtTokenForChartsLoading(false));
    }
  };
};
