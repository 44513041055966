import SimpleInput from "../../../../components/InputFieldComponent/InputField.component";
import { DetailsMainWrapper, DetailsHeading } from "./DeviceDetailsInfo.styles";
import { Row, Col } from "react-bootstrap";
import { deviceInfo } from "../constants";
import { Trans } from "react-i18next";
import moment from "moment";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";

const DeviceInfo = (props) => {
  const { selectedAsset, currentTimeZone } = props;
  const { lastDeviceInformation, siteId } = selectedAsset;

  const valueFormatter = (fieldType, key) => {
    const timeZone = currentTimeZone || siteId?.timeZone;
    switch (fieldType) {
      case "lastSeen":
        return lastDeviceInformation?.[key]
          ? moment(lastDeviceInformation?.[key]).startOf("minute").fromNow()
          : "NA";

      case "date":
        return lastDeviceInformation?.[key]
          ? moment(lastDeviceInformation?.[key])
              .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
              .format("MM/DD/YYYY, hh:mm A") +
              " " +
              getTimezoneShortCode(timeZone)
          : "NA";

      case "deviceSetupBy":
        return lastDeviceInformation?.deviceSetupBy?.fullName || "NA";
      case "percentage":
        return lastDeviceInformation?.[key] ? lastDeviceInformation?.[key] + "%" : "NA";

      default:
        return lastDeviceInformation?.[key] || "NA";
    }
  };

  return (
    <>
      <DetailsMainWrapper>
        <DetailsHeading>
          <Trans>Device Information</Trans>
        </DetailsHeading>
        <Row>
          {deviceInfo.map((field, index) => {
            const value = valueFormatter(field.type, field.key);
            const lastSeenHoursBack =
              field.type === "lastSeen"
                ? lastDeviceInformation?.[field.key]
                  ? moment().diff(moment(lastDeviceInformation?.[field.key]), "hours")
                  : null
                : null;
            return (
              <Col md={3} key={index} className="mb-3">
                <SimpleInput
                  label={field.label}
                  value={value}
                  readOnly={true}
                  iconNameRight={lastDeviceInformation?.[field.key] && field.iconName}
                  iconColor={lastSeenHoursBack && lastSeenHoursBack > 48 && "red"}
                  showIconRight
                  size="medium"
                />
              </Col>
            );
          })}
        </Row>
        <hr className="mb-0 mt-0" />
      </DetailsMainWrapper>
    </>
  );
};

export default DeviceInfo;
