import React from "react";
import { useTranslation } from "react-i18next";
import { ParaText } from "./UserReactivation.styles";

const UserReactivation = ({ StatusData }) => {
  const { t } = useTranslation();
  const type = !StatusData.isAccountActive
    ? "Account"
    : !StatusData.isUserTypeActive
    ? "User Type"
    : !StatusData.isWorksiteActive
    ? "Worksite"
    : "";
  const userReactivationErrorMessage = t("USER_REACTIVATION_ERROR_MESSAGE", { type });

  return <ParaText>{userReactivationErrorMessage}</ParaText>;
};

export default UserReactivation;
