import styled from "styled-components";
import { CSVHoverIcon } from "../../../../assets";
import { CSVActiveIcon } from "../../../../assets";
import { CSVDisabledIcon } from "../../../../assets";
export const CSVButtonWrapper = styled.div`
  /*  flex: 1;
  position: relative;

  & button {
    width: 42px;
    height: 42px;
    cursor: pointer;
    float: right;
    outline: 1px solid #008000;
    border: 2px solid #008000;
    background: url(${CSVActiveIcon});
    padding: 5px;
    border-radius: 5px;
    border-radius: 5px;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background: url(${CSVHoverIcon}) #008000;
      background-size: 73%;
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid #008000;
      outline: 1px solid #008000;
    }
    &:disabled {
      background: url(${CSVDisabledIcon}) #ffffff;
      background-size: 73%;
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid #f2f2f2;
      outline: 1px solid #f2f2f2;
      pointer-events: none;
    }
  }
  */
  flex: 1;
  position: relative;
  float: right;
  margin-top: 18px;
  margin-right: 17px;
  & button {
    float: right;
  }
`;

export const CustomDropdown = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 5px #888;
  border-radius: 4px;
  z-index: 2;
  right: 0;
  top: 42px;
  width: max-content;
`;

export const ClickWrapper = styled.div`
  cursor: pointer;
  & a {
    color: #323232;
    font-family: "Open Sans", sans-serif;
    font-size: 15px !important;
    text-align: left;
    border-bottom: 1px solid #cccc;
    padding: 5px 15px;
    float: left;
    width: 100%;
    cursor: pointer;
  }
`;

export const CSVSEND = styled.div`
  color: #323232;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  text-align: left;
  margin-top: 8px;
  cursor: pointer;
`;

export const OptionText = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? "#9e9e9e" : "rgb(50, 50, 50)")};
  font-family: "Open Sans", sans-serif;
  text-align: left;
  border-bottom: 1px solid rgba(204, 204, 204, 0.8);
  padding: 5px 15px;
  float: left;
  width: 100%;
  font-size: 15px !important;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;
