import { Dialog, Transition } from "@headlessui/react";
// Button Component
import Button from "../../../../components/Button/Button.component";
import { Trans, useTranslation } from "react-i18next";

export default function MessagePopup({ data, onClick }) {
  const { t } = useTranslation();
  return (
    <>
      <div className=" text-center">
        <div className="notification-style inline-block w-full  p-6 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl  max-w-[97%] md:max-w-[70%] lg:max-w-[70%]">
          <div className="notification-content-div">
            <div className="flex items-center justify-center">
              <Dialog.Title
                as="h3"
                className="p-2 text-2xl font-sans text-black-text leading-6 flex justify-center rounded-md text-gray-900"
              >
                {data?.message}
              </Dialog.Title>
            </div>
            <br />
            <div className="flex items-center justify-center">
              {/*  <button
                type="button"
                onClick={onClick}
                className="text-white bg-primary-background  pt-3 pb-3 rounded-md font-kanit w-full flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                CONTINUE
              </button>*/}
              <Button showIcon={false} label={t("CONTINUE")} onClick={onClick} size="large" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
