import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { setIntroFlag } from "../../../redux/user/user.action";
import {
  TutorialsMenu,
  TutorialHeader,
  MenuItem,
  TutorialWrapper,
  SieraAiLogo,
  PlayButton,
} from "./Header.styles";
import { Trans } from "react-i18next";

import ButtonIcon from "../../../components/IconButtonComponent/IconButton.component";

const TutorialMenu = ({ setIntroFlag }) => {
  const [showIntroMenu, setShowIntroMenu] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);

  const introIconClickHandler = () => {
    setShowIntroMenu(!showIntroMenu);
  };

  const showIntro = () => {
    setIntroFlag(false);
    setShowIntroMenu(false);
    setClickedOutside(false);
  };

  useEffect(() => {
    setShowIntroMenu(false);
    setClickedOutside(false);
  }, [clickedOutside]);

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <TutorialWrapper ref={myRef}>
      {/* <IconWrapper>
        <TutorialsIcon className="icon icon-tutorials" onClick={introIconClickHandler} />
      </IconWrapper>
      <SpanText>
        <Trans>Tutorials</Trans>
      </SpanText> */}

      <ButtonIcon icon="icon-tutorials" label="Tutorials" onClick={introIconClickHandler} />

      <TutorialsMenu showIntroMenu={showIntroMenu}>
        <TutorialHeader>
          <Trans>Explore SIERA.AI</Trans>
          <span>
            <Trans>Explore all that you can do in your SIERA.AI account</Trans>
          </span>
        </TutorialHeader>
        <MenuItem onClick={showIntro}>
          <SieraAiLogo></SieraAiLogo>
          <Trans>Navigation Tools</Trans>
          <PlayButton onClick={showIntro}>
            <i className="icon icon-arrow-right"></i>
          </PlayButton>
        </MenuItem>
      </TutorialsMenu>
    </TutorialWrapper>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setIntroFlag: (data) => dispatch(setIntroFlag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialMenu);
