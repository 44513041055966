import styled from "styled-components";

export const UserTypeManagementDiv = styled.div``;
export const UserTypeMainContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const ButtonsContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  text-align: left;
  margin: 10px 0px;

  button {
    margin-right: 10px;
  }
`;
export const UerTypeMainContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const UserTypeListing = styled.div`
  width: 340px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
`;

export const UserTypeDataList = styled.div`
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  div {
  }
`;

export const DesktopView = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;
export const SearchBoxWrapper = styled.div`
  flex: 1;
`;

export const SearchFilter = styled.div`
  display: flex;
`;
export const FilterWrapper = styled.div`
  flex: 0;
`;

export const CloseBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const FilterBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const FilterIconDiv = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CloseIconDiv = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const FilterOnclickDes = styled.div``;
export const FilterDesWrapper = styled.div``;

export const UserMobileListWrapper = styled.div`
  .tableHeight {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    height: auto;
  }
`;

export const UserTypeDetailHeading = styled.div`
  text-align: left;
  float: left;
  display: flex;
  width: 100%;
  background-color: #0d5fbe;
  color: #fff;
  line-height: 45px;
  padding-left: 9px;
  font-size: 16px !important;
  font-weight: 500;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;

  span {
    font-weight: 500;
  }

  img {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const RightIconImage = styled.div`
  /* font-size: 20px !important; */
  display: inline;
  line-height: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;


export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;