import styled from "styled-components";

// Copyright Text Stylings
export const CopyrightText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #323232;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: "Kanit";
  align-content: "center";
`;
