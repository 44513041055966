import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Custom Components and utilities
import CustomStepper from "../../../../../components/StepperComponent/Stepper.Component";
import Button from "../../../../../components/Button/Button.component";
import ModalPopup from "../../../../../components/ModalPopupComponent/ModalPopup.component";
import OverviewStep from "../common/OverviewStep.component";
import PermissionsMatrix from "../common/PermissionsMatrix.component";
import DetailsStep from "../common/DetailsStep.component";
import { addUserType, resetUserTypeFlags } from "../../../../../redux/userType/action";
import { allSteps, addUserTypeModalDefaultData, accessLevelMapping } from "../const/usertypeConstants";
import { ModalBodyWrapper } from "../common/styles/Modal.styles";
import SnackBar from "../../../../Dashboard/SnackBar";

function AddUserTypeModal(props) {
  const {
    addUserType,
    addUserTypeSuccess,
    addUserTypeError,
    toggleModalClose,
    isSuperAdmin,
    resetUserTypeFlags,
  } = props;
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [addUserTypeModalData, setAddUserTypeModalData] = useState(addUserTypeModalDefaultData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedValues(accessLevelMapping?.[addUserTypeModalData?.accessLevel] || addUserTypeModalDefaultData);
  }, [addUserTypeModalData.accessLevel]);

  const getAddUserTypeModalStep = (step, setActiveStep) => {
    switch (step) {
      case 0:
        return (
          <OverviewStep
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
          />
        );
      case 1:
        return (
          <PermissionsMatrix
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            accessLevel={addUserTypeModalData?.accessLevel}
          />
        );
      case 2:
        return (
          <DetailsStep
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
          />
        );
      default:
        return <OverviewStep />;
    }
  };

  const resetModalData = () => {
    setActiveStep(0);
    setAddUserTypeModalData(addUserTypeModalDefaultData);
  };

  const isDataValidForTheActiveStep = () => {
    const { accessLevel, name, accountId } = addUserTypeModalData;
    switch (activeStep) {
      case 0: {
        return isSuperAdmin
          ? accessLevel === "ALL_ACCOUNTS"
            ? !accessLevel || !name.trim()
            : !accessLevel || !name.trim() || !accountId
          : !accessLevel || !name.trim();
      }
      case 2: {
        return false;
      }
      default:
        return false;
    }
  };

  const handleNext = () => {
    isDataValidForTheActiveStep(activeStep);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleModalCloseCustom = () => {
    resetModalData();
    toggleModalClose();
  };

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    addUserType(addUserTypeModalData);
  };

  useEffect(() => {
    if (addUserTypeSuccess) {
      setIsLoading(false);
      handleModalCloseCustom();
    } else if (addUserTypeError) {
      setIsLoading(false);
    }
  }, [addUserTypeSuccess, addUserTypeError]);

  const handleCloseSnackBar = () => {
    resetUserTypeFlags();
  };

  return (
    <>
      <ModalPopup
        {...props}
        size="lg"
        centered
        backdrop="static"
        title={t("Add User Type")}
        handleClose={() => handleModalCloseCustom()}
      >
        <CustomStepper className="mt-2 mb-4" allSteps={allSteps} activeStep={activeStep} />

        <ModalBodyWrapper>
          {getAddUserTypeModalStep(activeStep, setActiveStep)}

          <div style={{ display: "flex", float: "right" }}>
            <>
              {activeStep > 0 && activeStep < allSteps.length && (
                <Button
                  label={t("Back")}
                  onClick={() => {
                    handlePrevious();
                  }}
                  showIcon
                  iconClass="arrow-left"
                  buttonType="ghost"
                />
              )}
              {activeStep < allSteps.length - 1 && (
                <Button
                  label={t("Next")}
                  onClick={handleNext}
                  style={{ marginLeft: "10px" }}
                  disabled={isDataValidForTheActiveStep()}
                  iconClass="arrow-right"
                  showIcon
                  iconPosition="after"
                />
              )}
              {activeStep === allSteps.length - 1 && (
                <Button
                  label={
                    <>
                      <>
                        {isLoading ? (
                          <div className="spinner-border spinner-border-sm" role="status"></div>
                        ) : (
                          t("Submit")
                        )}
                      </>
                    </>
                  }
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                  iconClass="save"
                  showIcon
                  buttonType="save"
                />
              )}
            </>
          </div>
        </ModalBodyWrapper>
      </ModalPopup>
      {/*   <SnackBar
        isSuccess={addUserTypeSuccess}
        isFailed={addUserTypeError}
        label={
          addUserTypeSuccess
            ? "User Type Added Successfully"
            : addUserTypeError
            ? "Error occurred while adding User Type. Please try again"
            : ""
        }
        handleClose={handleCloseSnackBar}
      />*/}
    </>
  );
}

const mapStateToProps = (state) => ({
  addUserTypeSuccess: state.userType.addUserTypeSuccess,
  addUserTypeError: state.userType.addUserTypeError,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  addUserType: (data) => dispatch(addUserType(data)),
  resetUserTypeFlags: () => dispatch(resetUserTypeFlags()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserTypeModal));
