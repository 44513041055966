import React from "react";
import { Navbar } from "react-bootstrap";
import { CopyrightText } from "./Footer.styles";
import { Trans } from "react-i18next";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Navbar className="footerNav" collapseOnSelect expand="lg">
      {/* Copyright Text on the Footer */}
      <CopyrightText>
        <Trans i18nKey="FOOTER_COPYRIGHT_TEXT">
          Proudly built in Austin, TX. Copyright {{ year }} Stocked Robotics, Inc. dba SIERA.AI
        </Trans>
      </CopyrightText>
    </Navbar>
  );
};

export default Footer;
