import { worksiteTypes } from "./types";
import config from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
import { getAllCount } from "../counts/action";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";
const baseUrl =  config.baseUrl
//To get all worksites
const getWorksitesLoading = () => ({
  type: worksiteTypes.GET_ALL_WORKSITES_LOADING,
});

const getWorksitesSuccess = (data, isResetState, pageNo) => ({
  type: worksiteTypes.GET_ALL_WORKSITES_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getWorksitesError = (err) => ({
  type: worksiteTypes.GET_ALL_WORKSITES_ERROR,
  err,
});

export const getWorksites = (paginationData, isResetState, skipCache) => {
  return async (dispatch, getState) => {
    dispatch(getWorksitesLoading());
    const state = getState();
    let accountId = state?.user?.loginDetails?.accountId;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    if (isSuperAdmin) {
      accountId = state?.dropdownFilters?.accountId;
    }
    const { pageNo, pageSize, searchKey } = paginationData;
    const url = `/site/${accountId}/${pageNo}/${pageSize}/${
      searchKey ? encodeURIComponent(searchKey) : "all"
    }`;
    const cache = CacheManager.getInstance();
    if (cache && !skipCache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getWorksitesSuccess(cacheResult1, isResetState, pageNo));

        return;
      }
    }

    apiCall(url)
      .then((resp) => {
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        if (cache) {
          if (resp?.data?.data?.length > 0) {
            cache.set(url, resp.data);
          }
        }
        dispatch(getWorksitesSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getWorksitesError(err));
        console.log(err);
      });
  };
};

// get worksite detail by Id
const getWorksiteDetailLoading = () => ({
  type: worksiteTypes.GET_WORKSITE_DETAIL_LOADING,
});

const getWorksiteDetailSuccess = (data) => ({
  type: worksiteTypes.GET_WORKSITE_DETAIL_SUCCESS,
  data,
});

const getWorksiteDetailError = (err) => ({
  type: worksiteTypes.GET_WORKSITE_DETAIL_ERROR,
  err,
});

export const getWorksiteDetailById = (worksiteId) => {
  return async (dispatch, getState) => {
    dispatch(getWorksiteDetailLoading());
    const url = `/site/${worksiteId}`;

    apiCall(url)
      .then((resp) => {
        console.log(resp);
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        dispatch(getWorksiteDetailSuccess(resp.data?.data));
      })
      .catch((err) => {
        dispatch(getWorksiteDetailError(err));
        console.log(err);
      });
  };
};

//To create new worksite
const postWorksiteLoading = () => ({
  type: worksiteTypes.POST_NEW_WORKSITE_LOADING,
});

const postWorksiteSuccess = (data) => ({
  type: worksiteTypes.POST_NEW_WORKSITE_SUCCESS,
  data,
});

const postWorksiteError = (err) => ({
  type: worksiteTypes.POST_NEW_WORKSITE_ERROR,
  err,
});

export const postWorksite = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      dispatch(postWorksiteLoading());

      const resp = await apiCall(`/site/create/`, { method: "POST", data: data });
      // eslint-disable-next-line no-unused-expressions
      cb?.();
      dispatch(getWorksites({ pageNo: 1, pageSize: 20 }));
      dispatch(getAllCount());
      if (resp.data.success) {
        await CacheRefs.removeCacheForSites();
        dispatch(
          postWorksiteSuccess({
            message: resp.data.message,
            data: resp.data.data,
          })
        );
      } else {
        dispatch(postWorksiteError({ message: resp.data.message }));
      }
    } catch (err) {
      dispatch(postWorksiteError(err));
      console.log(err);
    }
  };
};

//To update the worksite status
const worksiteStatusLoading = () => ({
  type: worksiteTypes.WORKSITE_STATUS_UPDATE_LOADING,
});

const worksiteStatusSuccess = (data) => ({
  type: worksiteTypes.WORKSITE_STATUS_UPDATE_SUCCESS,
  data,
});

const worksiteStatusError = (err) => ({
  type: worksiteTypes.WORKSITE_STATUS_UPDATE_ERROR,
  err,
});

export const worksiteStatusUpdate = (id, status) => {
  return async (dispatch, getState) => {
    dispatch(worksiteStatusLoading());
    const data = {
      status: status,
    };
    try {
      const resp = await apiCall(`/site/status/${id}`, { method: "PUT", data: data });
      if (resp.data.success) {
        await CacheRefs.removeCacheForSites();
        dispatch(worksiteStatusSuccess({ id, status, message: resp.data.message }));
      } else {
        dispatch(worksiteStatusSuccess({ id, status: !status, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(worksiteStatusError(err));
      console.log(err);
    }
  };
};

//To get worksite detail
const worksiteDetailsLoading = () => ({
  type: worksiteTypes.WORKSITE_DETAILS_UPDATE_LOADING,
});

const worksiteDetailsSuccess = (data) => ({
  type: worksiteTypes.WORKSITE_DETAILS_UPDATE_SUCCESS,
  data,
});

const worksiteDetailsError = (err) => ({
  type: worksiteTypes.WORKSITE_DETAILS_UPDATE_ERROR,
  err,
});

export const worksiteDetailsUpdate = (id, details, setEditOverview) => {
  return async (dispatch, getState) => {
    dispatch(worksiteDetailsLoading());
    try {
      const resp = await apiCall(`/site/${id}`, { method: "PUT", data: details });
      if (resp.data.success) {
        setEditOverview(true);
        await CacheRefs.removeCacheForSites();
        dispatch(
          worksiteDetailsSuccess({
            id: id,
            message: resp.data.message,
            details,
          })
        );
        dispatch(getWorksiteDetailSuccess(resp.data?.data));
        //dispatch(selectedWorksite(id));
      } else {
        dispatch(worksiteDetailsError({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(worksiteDetailsError(err));
      console.log(err);
    }
  };
};

//To update the worksite name
const worksiteNameLoading = () => ({
  type: worksiteTypes.WORKSITE_NAME_UPDATE_LOADING,
});

const worksiteNameSuccess = (data) => {
  return {
    type: worksiteTypes.WORKSITE_NAME_UPDATE_SUCCESS,
    data,
  };
};

const worksiteNameError = (err) => {
  return {
    type: worksiteTypes.WORKSITE_NAME_UPDATE_ERROR,
    err,
  };
};

export const worksiteNameUpdate = (id, name) => {
  return async (dispatch, getState) => {
    dispatch(worksiteNameLoading());
    const data = {
      name: name,
    };
    try {
      const resp = await apiCall(`/site/${id}`, { method: "PUT", data: data });
      if (resp.data.success) {
        await CacheRefs.removeCacheForSites();
        dispatch(
          worksiteNameSuccess({
            id: id,
            message: resp.data.message,
            name,
          })
        );
      } else {
        dispatch(worksiteNameError({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(worksiteNameError(err));
      console.log(err);
    }
  };
};

// To get selected worksite
export const selectedWorksite = (data) => ({
  type: worksiteTypes.SELECTED_WORKSITE,
  data,
});

//To reset the state
export const resetWorksitesState = () => ({
  type: worksiteTypes.RESET_WORKSITES_STATE,
});

//below is to avoid the intermittent issue on the Worksite successfully Added modal
export const resetWorksitesSuccessFlag = () => ({
  type: worksiteTypes.RESET_WORKSITES_SUCCESS_FLAG,
});

export const preDeactivatedWorksiteLoading = () => ({
  type: worksiteTypes.PRE_DEACTIVATED_WORKSITE_LOADING,
});

export const preDeactivatedWorksiteSuccess = (data) => ({
  type: worksiteTypes.PRE_DEACTIVATED_WORKSITE_SUCCESS,
  data,
});

export const preDeactivatedWorksiteError = (err) => ({
  type: worksiteTypes.PRE_DEACTIVATED_WORKSITE_ERROR,
  err,
});

export const preDeactivatedWorksite = (siteId, accountId) => {
  return async (dispatch) => {
    dispatch(preDeactivatedWorksiteLoading());
    try {
      const response = await apiCall(`/site/predeactivation?siteId=${siteId}&accountId=${accountId}`);
      if (response.data.data.users.length == 0 && response.data.data.assets.length == 0) {
        dispatch(deactivateWorksite(siteId, accountId));
      } else {
        dispatch(preDeactivatedWorksiteSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(preDeactivatedWorksiteError(err));
    }
  };
};

export const deactivatedWorksiteLoading = () => ({
  type: worksiteTypes.DEACTIVATE_WORKSITE_LOADING,
});

export const deactivatedWorksiteSuccess = (data) => ({
  type: worksiteTypes.DEACTIVATE_WORKSITE_SUCCESS,
  data,
});

export const deactivatedWorksiteError = (err) => ({
  type: worksiteTypes.DEACTIVATE_WORKSITE_ERROR,
  err,
});

export const deactivateWorksite = (siteId, accountId) => {
  return async (dispatch) => {
    dispatch(deactivatedWorksiteLoading());

    try {
      const response = await apiCall(`/site/deactivate?siteId=${siteId}&accountId=${accountId}`, {
        method: "PUT",
      });
      dispatch(deactivatedWorksiteSuccess({ id: siteId, message: response.data.success }));
    } catch (err) {
      dispatch(deactivatedWorksiteError(err));
    }
  };
};
