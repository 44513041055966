import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import image1 from "../../../assets/MarketingCarousel/Carousel_1.jpg";
import image2 from "../../../assets/MarketingCarousel/Carousel_2.jpg";
import image3 from "../../../assets/MarketingCarousel/Carousel_3.jpg";
import Button from "../../../components/Button/Button.component";

// Marketing Carousel Component
export const MarketingCarousel = ({ setHeader, handleChange }) => {
  const onHandleRegister = (e) => {
    setHeader({ status: "register" });
    handleChange(e, 1);
  };

  const { t } = useTranslation();

  return (
    <>
      <Carousel className="marKeTingCar">
        {/*First Slide Carousel Item*/}
        <Carousel.Item className="carousel_itemMar">
          <img className="d-block w-100" src={image1} alt="First slide" />
          <Carousel.Caption className="carousel-buttton">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
              }}
              style={{ border: "none", borderRadius: "20px" }}
              label={t("Request a Demo")}
              buttonType="primary"
            />
          </Carousel.Caption>
        </Carousel.Item>

        {/*Second Slide Carousel Item*/}
        <Carousel.Item className="carousel_itemMar">
          <img className="d-block w-100" src={image2} alt="Third slide" />
          <Carousel.Caption className="carousel-buttton-a">
            <Button
              style={{ border: "none", borderRadius: "20px", backgroundColor: "#F4AE33", color: "#323232" }}
              onClick={onHandleRegister}
              label={t("Start Inspecting for FREE")}
              buttonType="primary"
            />
          </Carousel.Caption>
        </Carousel.Item>

        {/*Third Slide Carousel Item*/}
        <Carousel.Item className="carousel_itemMar">
          <img height="100%" className="d-block w-100" src={image3} alt="Third slide" />

          <Carousel.Caption className="carousel-buttton-b">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
              }}
              style={{ border: "none", borderRadius: "20px", backgroundColor: "#F4AE33", color: "#323232" }}
              label={t("Start Inspecting for FREE")}
              buttonType="primary"
            />
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};
