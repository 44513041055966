/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA LANDING PAGE USER ACTION CREATORS
 */

import { userTypes } from "./user.types";
import config from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
import { CacheRefs } from "../../utils/cache/CacheRefs";
const baseUrl = config.baseUrl;
//  1. LANDING PAGE SIGNUP ACTION CREATOR: FOR SIERA REGISTRATION FORM
export const landingPageSignup = (userDetails) => ({
  type: userTypes.SIGN_UP,
  payload: userDetails,
});

//  2. LANDING PAGE LOGIN ACTION CREATOR: FOR SIERA LOGIN FORM
export const login = (userDetails) => ({
  type: userTypes.LOGIN,
  payload: userDetails,
});

export const resetLoginStatus = () => ({
  type: userTypes.RESET_LOGIN_LOGIN,
});

//  3. DASHBOARD PAGE UPDATE USER ACTION CREATOR: FOR SIERA DASHBOARD UPDATE ACCOUNT MODAL
export const updateUser = (userDetails) => ({
  type: userTypes.UPDATE_ACCOUNT,
  payload: userDetails,
});

// 4. LOGOUT
export const logout = (data) => ({
  type: userTypes.LOGOUT,
  payload: data,
});

//5. Update plan
export const updatePlan = (id) => ({
  type: userTypes.UPDATE_PLAN,
  id,
});

//6. Update stripe id
export const updateStripeID = (id) => ({
  type: userTypes.UPDATE_STRIPE,
  id,
});

export const refreshTokenReset = (data) => ({
  type: userTypes.REFRESH_TOKEN,
  data,
});

export const refreshTokenLoading = (data) => ({
  type: userTypes.REFRESH_TOKEN_LOADING,
  data,
});

export const refreshToken = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state?.user?.loginDetails?.token;
    const refreshTokenn = state?.user?.loginDetails?.refreshToken;
    const headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };
    if (state?.user?.refreshTokenLoading) {
      return;
    }
    dispatch(refreshTokenLoading(true));

    axios
      .post(
        `${baseUrl}/user/refreshToken`,
        {
          refreshToken: refreshTokenn,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        dispatch(refreshTokenReset(response?.data?.token));
      })
      .catch((err) => {
        // dispatch(getLastHourMeterListError(err));
        console.log("Error occurred while refreshing token", err);
      })
      .finally(() => {
        dispatch(refreshTokenLoading(false));
      });
  };
};

export const updateLoginUserDetail = (userDetails) => ({
  type: userTypes.UPDATE_LOGIN_USER,
  payload: userDetails,
});

export const setIntroFlag = (data) => ({
  type: userTypes.SET_IS_ONBOARDED_FLAG,
  data: data,
});

export const setIsOnboardedFlag = (value) => {
  return async (dispatch, getState) => {
    const userId = getState()?.user?.loginDetails?.userData?._id;
    if (!userId) {
      return;
    }
    const userData = new FormData();
    const data = {
      isOnboarded: true,
    };

    userData.append("userData", JSON.stringify(data));
    try {
      await apiCall(`/user/${userId}`, { method: "PUT", data: userData });
      await CacheRefs.removeCacheForUsers();
      dispatch(setIntroFlag(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateAccountOwnerSuccess = (currAccountOwnerId) => ({
  type: userTypes.UPDATE_ACCOUNT_OWNER_SUCCESS,
  data: currAccountOwnerId,
});

export const updateAccountOwnerError = () => ({
  type: userTypes.UPDATE_ACCOUNT_OWNER_ERROR,
});

export const updateAccountOwnerLoading = () => ({
  type: userTypes.UPDATE_ACCOUNT_OWNER_LOADING,
});

export const updateAccountOwnerResetFlags = () => ({
  type: userTypes.UPDATE_ACCOUNT_OWNER_RESET,
});

export const updateAccountOwner = (currAccountOwnerId, newAccountOwnerId) => {
  return async (dispatch, getState) => {
    dispatch(updateAccountOwnerLoading());
    try {
      const response = await apiCall(`/account/changeAccountOwner`, {
        method: "PUT",
        data: { currAccountOwnerId, newAccountOwnerId },
      });
      await CacheRefs.removeCacheForUsers();
      if (response.data.success) {
        dispatch(updateAccountOwnerSuccess(currAccountOwnerId));
      } else {
        dispatch(updateAccountOwnerError());
      }
    } catch (err) {
      console.log(err);
      dispatch(updateAccountOwnerError());
    }
  };
};

export const updateCurrentUser = (data) => ({
  type: userTypes.CURRENT_USER_DETAILS_UPDATED,
  data,
});
