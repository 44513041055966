/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA CHECKLIST MANAGEMENT ACTION CREATORS
 */

import { checklistManagementTypes } from "./checklist.types";

// 1. Adding Checklist
// export const addUser = checklistDetail => ({
//     type: checklistManagementTypes.ADD_CHECKLIST,
//     payload: checklistDetail
// })

// 2. Selecting all checklist
export const checklistList = (checklist) => ({
  type: checklistManagementTypes.LIST_CHECKLISTS,
  payload: checklist,
});

// 3. Selected checklist overview
export const checklistOverview = (checklistId) => ({
  type: checklistManagementTypes.CHECKLIST_OVERVIEW,
  payload: checklistId,
});

// 4. Selected default checklist overview from the user list
export const defaultChecklistOverview = () => ({
  type: checklistManagementTypes.DEFAULT_CHECKLIST_OVERVIEW,
});

// 5. Update the user list
// export const updateUser =user => ({
//     type:checklistManagementTypes.UPDATE_USER,
//     payload:user
// })
