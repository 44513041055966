import React, { useState } from "react";
import { Card } from "react-bootstrap";
import FlipCard from "../../../components/FlipCard/FlipCard";
import AccountProfile from "./AccountProfile";
import "./AccountUser.css";
import styled from "styled-components";
const AccountDivStyled = styled.div`
  .profile-div {
    padding-bottom: 0px;
  }
  .front,
  .back,
  .skew-div-style {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .content {
    transform-style: flat !important;
  }
`;

export default function Account() {
  const [face, setFace] = useState("front");
  return (
    <AccountDivStyled>
      <div className="profile-div">
        <div className="left-profile-div">
          <div>
            <div>
              <div>
                <FlipCard front={() => <AccountProfile setFace={setFace} />} currentFace={face} />
              </div>
            </div>
          </div>
        </div>
        <div className="skew-div-style"></div>
      </div>
    </AccountDivStyled>
  );
}
