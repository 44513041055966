export const getCameraRawObject = (data, position, isFeet) => {
  return {
    sensor: data.number,
    position: data.name,
    object: {
      left: position ? position.object?.pos2 : isFeet ? 4.92 : 1500,
      right: position ? position.object?.pos3 : isFeet ? 4.92 : 1500,
      top: position ? position.object?.pos4 : isFeet ? 9.18 : 2800,
      middle: position ? position.object?.pos6 : isFeet ? 7.21 : 2200,
      bottom: position ? position.object?.pos5 : isFeet ? 1.64 : 500,
      depth: position ? position.object?.pos1 : isFeet ? 4.92 : 1500,
    },
    pedestrian: {
      left: position ? position.pedestrian?.pos2 : isFeet ? 4.92 : 1500,
      right: position ? position.pedestrian?.pos3 : isFeet ? 4.92 : 1500,
      top: position ? position.pedestrian?.pos4 : isFeet ? 9.18 : 2800,
      middle: position ? position.pedestrian?.pos6 : isFeet ? 7.21 : 2200,
      bottom: position ? position.pedestrian?.pos5 : isFeet ? 1.64 : 500,
      depth: position ? position.pedestrian?.pos1 : isFeet ? 4.92 : 1500,
    },
    sczPedestrian: {
      left: position ? position.sczPedestrian?.pos2 : isFeet ? 4.92 : 1500,
      right: position ? position.sczPedestrian?.pos3 : isFeet ? 4.92 : 1500,
      top: position ? position.sczPedestrian?.pos4 : isFeet ? 9.18 : 2800,
      middle: position ? position.sczPedestrian?.pos6 : isFeet ? 7.21 : 2200,
      bottom: position ? position.sczPedestrian?.pos5 : isFeet ? 1.64 : 500,
      zones: {
        one: position ? position.sczPedestrian?.zones?.one : isFeet ? 4.92 : 1500,
        two: position ? position.sczPedestrian?.zones?.two : isFeet ? 4.92 : 1500,
        three: position ? position.sczPedestrian?.zones?.three : isFeet ? 4.92 : 1500,
        four: position ? position.sczPedestrian?.zones?.four : isFeet ? 4.92 : 1500,
      },
    },
    sczObject: {
      left: position ? position.sczObject?.pos2 : isFeet ? 4.92 : 1500,
      right: position ? position.sczObject?.pos3 : isFeet ? 4.92 : 1500,
      top: position ? position.sczObject?.pos4 : isFeet ? 9.18 : 2800,
      middle: position ? position.sczObject?.pos6 : isFeet ? 7.21 : 2200,
      bottom: position ? position.sczObject?.pos5 : isFeet ? 1.64 : 500,
      zones: {
        one: position ? position.sczObject?.zones?.one : isFeet ? 4.92 : 1500,
        two: position ? position.sczObject?.zones?.two : isFeet ? 4.92 : 1500,
        three: position ? position.sczObject?.zones?.three : isFeet ? 4.92 : 1500,
        four: position ? position.sczObject?.zones?.four : isFeet ? 4.92 : 1500,
      },
    },
  };
};

export const getCameraNearMissDetectionObject = (data, nearMissData, isFeet) => {
  return {
    sensor: data.number,
    position: data.name,
    object: {
      left: nearMissData?.object?.left || (isFeet ? 4.92 : 1500),
      right: nearMissData?.object?.right || (isFeet ? 4.92 : 1500),
      top: nearMissData?.object?.top || (isFeet ? 9.18 : 2800),
      middle: nearMissData?.object?.middle || (isFeet ? 7.21 : 2200),
      bottom: nearMissData?.object?.bottom || (isFeet ? 1.64 : 500),
      depth: nearMissData?.object?.depth || (isFeet ? 4.92 : 1500),
    },
    pedestrian: {
      left: nearMissData?.pedestrian?.left || (isFeet ? 4.92 : 1500),
      right: nearMissData?.pedestrian?.right || (isFeet ? 4.92 : 1500),
      top: nearMissData?.pedestrian?.top || (isFeet ? 9.18 : 2800),
      middle: nearMissData?.pedestrian?.middle || (isFeet ? 7.21 : 2200),
      bottom: nearMissData?.pedestrian?.bottom || (isFeet ? 1.64 : 500),
      depth: nearMissData?.pedestrian?.depth || (isFeet ? 4.92 : 1500),
    },
    sczObject: {
      left: nearMissData?.sczObject?.left || (isFeet ? 4.92 : 1500),
      right: nearMissData?.sczObject?.right || (isFeet ? 4.92 : 1500),
      top: nearMissData?.sczObject?.top || (isFeet ? 9.18 : 2800),
      middle: nearMissData?.sczObject?.middle || (isFeet ? 7.21 : 2200),
      bottom: nearMissData?.sczObject?.bottom || (isFeet ? 1.64 : 500),
      zones: {
        one: nearMissData ? nearMissData.sczObject?.zones?.one : isFeet ? 4.92 : 1500,
        two: nearMissData ? nearMissData.sczObject?.zones?.two : isFeet ? 4.92 : 1500,
        three: nearMissData ? nearMissData.sczObject?.zones?.three : isFeet ? 4.92 : 1500,
        four: nearMissData ? nearMissData.sczObject?.zones?.four : isFeet ? 4.92 : 1500,
      },
    },
    sczPedestrian: {
      left: nearMissData?.sczPedestrian?.left || (isFeet ? 4.92 : 1500),
      right: nearMissData?.sczPedestrian?.right || (isFeet ? 4.92 : 1500),
      top: nearMissData?.sczPedestrian?.top || (isFeet ? 9.18 : 2800),
      middle: nearMissData?.sczPedestrian?.middle || (isFeet ? 7.21 : 2200),
      bottom: nearMissData?.sczPedestrian?.bottom || (isFeet ? 1.64 : 500),
      zones: {
        one: nearMissData ? nearMissData.sczPedestrian?.zones?.one : isFeet ? 4.92 : 1500,
        two: nearMissData ? nearMissData.sczPedestrian?.zones?.two : isFeet ? 4.92 : 1500,
        three: nearMissData ? nearMissData.sczPedestrian?.zones?.three : isFeet ? 4.92 : 1500,
        four: nearMissData ? nearMissData.sczPedestrian?.zones?.four : isFeet ? 4.92 : 1500,
      },
    },
  };
};

export const sliderLabel = {
  left: "Left Boundary",
  right: "Right Boundary",
  depth: "Depth",
  top: "Upper Boundary",
  bottom: "Lower Boundary",
  middle: "Camera Height",
};
