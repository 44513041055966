import React from "react";
import DropdownWrapper from "../../../components/DropdownComponent/DropdownWrapper";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { setEditAlertModal } from "../../../redux/modals/action";

const WorksiteDropdownFilter = ({
  isSuperAdmin,
  dropdownAccountList,
  dropdownAccountId,
  setDropdownAccountId,
  editForm,
  setEditAlertModal
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {isSuperAdmin && (
        <DropdownWrapper
          filter={{
            type: "Account",
            name: "companyName",
            key: "companyName",
            data: dropdownAccountList,
          }}
          value={dropdownAccountId}
          label={t("filter")}
          handleFilter={(value) => (editForm ? setDropdownAccountId(value) : setEditAlertModal(true))}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
  editForm: state.forms.editForm,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteDropdownFilter);
