import styled from "styled-components";

export const MobileResponsive = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    float: left;
    font-weight: bold;
    margin-right: 20px;
  }
`;

export const AssetChecklistTableWrapper = styled.div`
  @media (max-width: 1100px) {
    .forResponsive {
      display: none;
    }
  }
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;
  @media (max-width: 1100px) {
    display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "5px")};
    padding-bottom: ${({ isHeader }) => (isHeader ? "0px" : "5px")};
    border-bottom: ${({ isHeader, theme }) =>
      isHeader ? "none" : `0px solid ${theme.colors.grayL5 || "#CCCCCC"}`};
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }

  // Responsive styling
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    float: none;
    margin-right: 0;
    padding: 7px;

    &:nth-child(odd) {
      text-align: right;
    }

    &:nth-child(even) {
      text-align: right;
    }
  }

  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  margin-bottom: 10px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  /* height: calc(100vh - 610px); */
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 612px)" : "calc(100vh - 593px)")};
  @media (max-width: 1100px) {
    margin-bottom: 50px;
    height: auto;
  }
`;

export const NoSectionContainer = styled.div`
  /*  height: calc(100vh - 570px); */
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 570px)" : "calc(100vh - 550px)")};
  display: inline-block;
  align-content: center;
  width: 100%;
  @media (max-width: 1100px) {
    height: auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;
export const SpanDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  text-align: center;
  font-family: "Open Sans";
  font-size: 13px !important;
  line-height: 20px;
`;

export const DeleteIconStyled = styled.div`
  font-size: 15px !important;
  color: ${({ isDisabled, theme }) => {
    return isDisabled ? "#CCCCCC" : theme.colors.grayL1 || "#323232";
  }};
  cursor: ${({ isDisabled }) => {
    return isDisabled ? "not-allowed" : "pointer";
  }};
  :hover {
    color: ${({ isDisabled, theme }) => (isDisabled ? "#CCCCCC" : theme.colors.themePrimary || "#0D5FBE")};
  }
`;

export const AddChecklistDiv = styled.div`
  width: 95%;
  margin: auto;
  text-align: left;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
  float: left;
  width: 100%;
  text-align: left;
`;

export const ContainerRow = styled.div`
  padding: 25px;
  margin: auto;
  overflow: hidden;
`;

export const ButtonWrapperCol = styled.div`
  flex: 1;
  float: left;
  display: inline-flex;

  .col {
    padding-right: 0px;
  }
`;

export const ButtonWrapperRow = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    ${ButtonWrapperCol} {
      &:last-child {
        flex: 2;
      }
    }
  }
`;
