import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

// Styled components
const HiddenInput = styled.input`
  display: none;
`;

const OverlayImage = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 0.3s ease;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  text-align: center;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  overflow: hidden;

  &:hover ${OverlayImage} {
    height: 50%;
  }
`;

const Image = styled.img`
  display: block;
  height: 95px;
  object-fit: cover;
  width: 95px;
  border-radius: 50%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  background-color: #f0f0f0;
  i {
    font-size: 100px;
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

const SpinnerContainer = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PreviewImageComp = React.memo(({ imageLoaded, setImageLoaded, defaultSrc, PreviewImage }) => {
  return (
    <>
      {PreviewImage ? (
        <span>
          <Image
            alt="img"
            className={`${imageLoaded ? "d-block" : "d-none"}`}
            src={PreviewImage}
            key={PreviewImage}
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && (
            <SpinnerContainer>
              <Spinner animation="border" role="status" />
            </SpinnerContainer>
          )}
        </span>
      ) : (
        <IconContainer>
          <i className="icon icon-user-photo" />
        </IconContainer>
      )}
    </>
  );
});

const ImageUpload = React.memo(({ id, src, updateImage, imageLoading, defaultSrc }) => {
  const [PreviewImage, setPreviewImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const handleImage = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    updateImage(e.target.files[0], id);
  };

  useEffect(() => {
    setPreviewImage(src);
    setImageLoaded(false);
  }, [src]);

  return (
    <>
      <HiddenInput accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />

      <ImageContainer>
        <PreviewImageComp
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
          defaultSrc={defaultSrc}
          PreviewImage={PreviewImage}
        />
        <OverlayImage htmlFor="contained-button-file">
          {PreviewImage ? t("Change Photo") : t("Add Photo")}
        </OverlayImage>
        {imageLoading && (
          <SpinnerContainer>
            <Spinner animation="border" role="status" size="sm" />
          </SpinnerContainer>
        )}
      </ImageContainer>
    </>
  );
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  success: state.asset.Success,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
