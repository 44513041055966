const GET_ASSET_TYPE_LOADING = "GET_ASSET_TYPE_LOADING";
const GET_ASSET_TYPE_SUCCESS = "GET_ASSET_TYPE_SUCCESS";
const GET_ASSET_TYPE_ERROR = "GET_ASSET_TYPE_ERROR";
const GET_ALL_ASSET_LOADING = "GET_ALL_ASSET_LOADING";
const GET_ALL_ASSET_SUCCESS = "GET_ALL_ASSET_SUCCESS";
const GET_ALL_ASSET_ERROR = "GET_ALL_ASSET_ERROR";
const TO_RESET_ALL_ASSET_STATE = "TO_RESET_ALL_ASSET_STATE";
const RESET_A_ASSET_STATE = "RESET_A_ASSET_STATE";
const GET_ASSET_CHECK_LIST_LOADING = "GET_ASSET_CHECK_LIST_LOADING";
const GET_ASSET_CHECK_LIST_SUCCESS = "GET_ASSET_CHECK_LIST_SUCCESS";
const GET_ASSET_CHECK_LIST_ERROR = "GET_ASSET_CHECK_LIST_ERROR";
const UPLOAD_MEDIA_LOADING = "UPLOAD_MEDIA_LOADING";
const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";
const POST_ANSWER_LOADING = "POST_ANSWER_LOADING";
const POST_ANSWER_SUCCESS = "POST_ANSWER_SUCCESS";
const POST_ANSWER_ERROR = "POST_ANSWER_ERROR";
const TO_RESET_MEDIA_STATE = "TO_RESET_MEDIA_STATE";
const TO_STORE_SELECTED_ASSET = "TO_STORE_SELECTED_ASSET";

export {
  GET_ASSET_TYPE_LOADING,
  GET_ASSET_TYPE_SUCCESS,
  GET_ASSET_TYPE_ERROR,
  GET_ALL_ASSET_LOADING,
  GET_ALL_ASSET_SUCCESS,
  GET_ALL_ASSET_ERROR,
  TO_RESET_ALL_ASSET_STATE,
  RESET_A_ASSET_STATE,
  GET_ASSET_CHECK_LIST_LOADING,
  GET_ASSET_CHECK_LIST_SUCCESS,
  GET_ASSET_CHECK_LIST_ERROR,
  UPLOAD_MEDIA_LOADING,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  POST_ANSWER_LOADING,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  TO_RESET_MEDIA_STATE,
  TO_STORE_SELECTED_ASSET,
};
