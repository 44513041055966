import styled, { css } from "styled-components";

export const SlideContainer = styled.div`
  position: relative;
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  float: left;


  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }

  .slide-content {
    overflow: hidden;
    height: 0;
  }

  .slide-container.open .slide-content {
    animation: slideDown 0.5s ease;
  }

  .slide-container:not(.open) .slide-content {
    animation: slideUp 0.5s ease;
  }

  ${(props) =>
    props.open &&
    css`
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border-radius: 4px;
    width: fit-content;
    `}
`;
export const Icon = styled.div`
  margin-right: 5px;
  display: inline-flex;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const ToggleButton = styled.button`
  background-color:  ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  :hover {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border-radius: 4px;
  }
  ${(props) =>
    props.open &&
    css`
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border-radius: 4px;
    `}
`;

export const DivContent = styled.div`
  padding: 5px 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
`;
export const SlideContent = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;

  ${(props) =>
    props.open &&
    css`
      max-height: 1000px;
    `}
`;
