//TODO - When time permits, review and optimize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import UsersCard from "./UsersCard.component";
import UserOverview from "./UserOverview.component";
import SnackBar from "../SnackBar";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "../../../redux/userManagement/userManagement.action";
import { useTranslation } from "react-i18next";
import { getDropdownWorksiteList, getDropdownAccountList } from "../../../redux/dropdownFilters/action";
import { apiCall } from "../../../utils/apiCall";
import UserFiltersComponent from "./UserFilters.component";

import { resetUsersState } from "../../../redux/users/action";
import UserReactivation from "./UserReactivation.component";

const Users = ({
  updateUser,
  getDropdownWorksiteList,
  getDropdownAccountList,
  imageLoaded,
  setImageLoaded,
  statusData,
  statusFailed,
  resetUsersState,
}) => {
  const [ShowStatus, setShowStatus] = useState(false);
  const [CurrentStatus, setCurrentStatus] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const { t } = useTranslation();

  const updateUserStatus = async (e) => {
    const id = e.target.id;
    const status = e.target.checked;
    const responseUser = await apiCall(`/user/status/${id}`, { method: "PUT", data: { status } });

    if (responseUser.data.success === true) {
      updateUser({ _id: id, status });
      setCurrentStatus(status);
      setShowStatus(true);
    }
  };

  const handleCloseSnackBar = () => {
    setShowStatus(false);
  };

  const handleMessage = (ShowStatus) => {
    if (ShowStatus) {
      if (CurrentStatus) {
        return t("The user is now active");
      } else {
        return t("The user has been deactivated");
      }
    }
  };

  useEffect(() => {
    getDropdownWorksiteList();
    getDropdownAccountList();
  }, []);

  useEffect(() => {
    if (statusFailed) {
      setShowAlertModal(true);
    }
  }, [statusFailed]);

  const handleModalClose = () => {
    setShowAlertModal(false);
    resetUsersState();
  };

  return (
    <>
      <div>
        <Row>
          <UserFiltersComponent />
          <Col md={3}>
            <UsersCard />
          </Col>
        </Row>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <UserOverview
          imageLoaded={imageLoaded}
          setImageLoaded={(data) => setImageLoaded(data)}
          updateUserStatus={updateUserStatus}
        />
      </div>
      <SnackBar isSuccess={ShowStatus} label={handleMessage(ShowStatus)} handleClose={handleCloseSnackBar} />

      <ModalComponent
        show={showAlertModal}
        variant="AlertModal"
        handleClose={handleModalClose}
        iconClass="alertsettings"
        message="Cannot Activate User"
        paragraph={<UserReactivation StatusData={statusData} />}
        button1Text="Continue"
        button2Text=""
        onClickA={handleModalClose}
        typeA="primary"
        typeB="ghost"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAssetTypeId: state.dropdownFilters.assetTypeId,
  statusData: state.users.StatusData,
  statusFailed: state.users.StatusFailed,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userDetail) => dispatch(updateUser(userDetail)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  resetUsersState: () => dispatch(resetUsersState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
