import styled from "styled-components";

// Password Recovery Title Styling
export const RegisterTitle = styled.div`
  font-size: 40px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 50px;
  padding-bottom: 12px;
  font-family: "Kanit";
`;
// Password Recovery Sub Title Styling
export const RegisterSubTitle = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  padding-top: 3px;
  font-family: "Open Sans";
`;

// Password Recovery Sub Title2 Styling
export const RegisterSubTitle2 = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  padding-top: 3px;
  padding-bottom: 20px;
  font-family: "Open Sans";
`;
