import styled from "styled-components";

export const ChecklistTabBarMenu = styled.div`
  width: 100%;
  .card-header-tabs {
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    border-bottom: 0px;
  }
  /*header.MuiAppBar-root {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .tabPanels .MuiAppBar-root {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .tabpanelSize .MuiTab-textColorPrimary.Mui-selected {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .tabpanelSize .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .MuiTab-textColorPrimary {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .checklistTabPanel .card {
    margin-top: 2px;
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .checklistTabPanel .card .card-header {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .checklistTabPanel .table thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .checklistYour_h {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }*/
`;

export const ChecklistTabSection = styled.div``;

export const SectionnameChecklist = styled.div`
  background: 0% 0% no-repeat padding-box padding-box ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};

  border-radius: 4px 4px 0px 0px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  text-align: left;
  padding: 6px 15px;
  font-size: 18px !important;
  margin-top: 2px;
  .headerColor {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
`;

export const SectionChecklistTableWrapper = styled.div``;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 15% 15% 15% 15% 15%;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;
  @media (max-width: 1100px) {
    display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 1100px) {
    display: ${({ isHeader }) => (isHeader ? "none;" : "block;")};
    width: ${({ widthResp }) => (widthResp ? widthResp : "75%")};
    float: ${({ floatResp }) => (floatResp ? floatResp : "left")};
    margin-right: ${({ RmarginResp }) => (RmarginResp ? RmarginResp : "5%")};
    padding: 7px;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 581px);
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;
