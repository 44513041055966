// Libraries
import React, { useState, useEffect } from "react";

// React bootstrap
import { Container, Row, Col, Navbar, Accordion, Card, Form } from "react-bootstrap";

// Custom Styles
import Styles from "./AddUserModalStyles.module.css";

// For multiple language support
import { Trans, useTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { addUser } from "../../../../../redux/userManagement/userManagement.action";
import { setDropdownAccountId } from "../../../../../redux/dropdownFilters/action";
import { postUser } from "../../../../../redux/users/action";
import { userList } from "../../../../../redux/userManagement/userManagement.action";

// Custom Components and utilities
import Input from "../../../../../components/Input";
import useAlert from "../../../../../utils/useAlert";
import accessLevelListConstant from "../../../../../constant/accessLevel";
import permissions from "../../../../../constant/permission";

// Images and Icons
import { ReactComponent as Information } from "../../../../../assets/Information_Icon.svg";

function UserOverviewForm({
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  setAccountIdForAdmin,
  setDropdownAccountId,
  userModalData,
  setuserModalData,
  dropdownAccountList,
  dropdownWorksiteList,
}) {
  // For multiple language suppor
  const { t } = useTranslation();

  // For accordian to show
  const [isAccordianVisible, setisAccordianVisible] = useState(false);

  const [isValidationError, setValidationError] = useState(false);

  const [accessLevelList, setAccessLevelList] = useState(accessLevelListConstant);

  useEffect(() => {
    // setRole(loginDetails ? loginDetails.permission.permissionName : "");
    // setAccountIdForAdmin(loginDetails && loginDetails.accountId);
    if (
      (loginDetails ? loginDetails?.permission?.permissionName : "") == permissions.SITE_ADMIN ||
      (loginDetails ? loginDetails?.permission?.permissionName : "") == permissions.SITE_MANAGER
    ) {
      setAccessLevelList([
        { name: "Worksite Admin", value: "site_admin" },
        { name: "Operator", value: "operator" },
      ]);
    }
  }, [loginDetails]);

  const handleAccountFilterChange = (value) => {
    setDropdownAccountId(value);
    setuserModalData({ ...userModalData, accountId: value });
  };

  const handleAccessLevelChange = (value) => {
    // setAccessLevel(value);
    setuserModalData({
      ...userModalData,
      permissions: value,
      authorizedSites: null,
    });
  };

  const handleUserName = (value) => {
    setuserModalData({
      ...userModalData,
      fullName: value,
    });
  };

  const handleWorksiteChange = (value) => {
    setuserModalData({
      ...userModalData,
      authorizedSites: value,
    });
  };

  return (
    <>
      <div className={Styles.useroverviewContainer}>
        <div className={Styles.useroverviewHeading}>
          <h5>
            <Trans>User Overview</Trans>
          </h5>
        </div>

        <Row>
          {/* Super Admin Dropdown */}
          {isSuperAdmin && (
            <Col md={6} className="asterisk_form accountLabelWorksite">
              <Form.Group className="inputBoxDiv  hide-detail">
                <Form.Label>
                  <Trans>Account</Trans>
                </Form.Label>
                <Input
                  options={dropdownAccountList?.map((data) => ({
                    _id: data._id,
                    label: data.companyName,
                  }))}
                  type="select"
                  as="select"
                  label={t("Account")}
                  isValidationError={isValidationError}
                  value={userModalData.accountId || ""}
                  onChange={(value) => {
                    handleAccountFilterChange(value);
                  }}
                  required={true}
                />
              </Form.Group>
            </Col>
          )}

          {/* User Access Level */}
          <Col md={6} className="asterisk_form accountLabelWorksite">
            <Form.Group className="inputBoxDiv  hide-detail">
              <Form.Label>
                <Trans>Access Level</Trans>
              </Form.Label>
              <Input
                label="Access Level"
                type="select"
                options={accessLevelList?.map((data) => ({
                  _id: data.value,
                  label: data.name,
                }))}
                as="select"
                className="selectBox"
                required={true}
                isValidationError={isValidationError}
                value={userModalData?.permissions || ""}
                onChange={(value) => handleAccessLevelChange(value)}
              />
              <p className="asterisk_user">*</p>
            </Form.Group>
          </Col>

          {/* If the user access level is Operator */}
          {[permissions.SITE_ADMIN, permissions.OPERATOR].includes(userModalData?.permissions) &&
            !isSiteAdmin && (
              <Col md={6} className="asterisk_form accountLabelWorksite">
                <Form.Group className="inputBoxDiv  hide-detail">
                  <Form.Label>
                    <Trans>Worksite</Trans>
                  </Form.Label>
                  {/* Worksite*/}
                  <Input
                    label="Worksite"
                    defaultValue={
                      dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList[0]._id
                    }
                    required
                    value={userModalData?.authorizedSites || ""}
                    type="select"
                    isValidationError={isValidationError}
                    className="selectBox"
                    onChange={(value) => handleWorksiteChange(value)}
                    options={dropdownWorksiteList?.map?.((data) => ({
                      _id: data._id,
                      label: data.name,
                    }))}
                    feedback="Please select worksite"
                  />
                  <p className="asterisk_user">*</p>
                </Form.Group>
              </Col>
            )}

          {/* First Name and Last Name Input */}
          <Col md={6} className="asterisk_form">
            <Input
              label="User Name"
              autofocus="true"
              id="outlined-basic"
              placeholder={t("First and Last Name")}
              variant="standard"
              type="text"
              name="fullName"
              autocomplete="new-password"
              value={userModalData.fullName || ""}
              onChange={(e) => handleUserName(e.target.value)}
              required
              feedback={"Name is required"}
            />
            <p className="asterisk_user">*</p>
          </Col>
        </Row>

        <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Col md={8} className="instruction-bottom" style={{ padding: "0px" }}>
            <Accordion className="text-open-sans accordion_bulk">
              <Card style={{ border: "0px" }}>
                <Card.Header
                  className={isAccordianVisible ? "bulk_information_header" : "bulk_information_header_white"}
                >
                  <Accordion.Toggle
                    onClick={() => setisAccordianVisible(!isAccordianVisible)}
                    tabIndex="0"
                    eventKey="0"
                    className="info_button"
                  >
                    <img src={Information} />
                    <Trans>ACCESS LEVELS</Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="bulk_information">
                    <div className="line_vertical_a" style={{ height: "102px" }}></div>
                    <div className="addUserInfo">
                      <Trans>Use the classification below to determine the access level of your users</Trans>.
                      <span className="mt-2">
                        <strong>
                          - <Trans>Account Admin</Trans>:
                        </strong>{" "}
                        <Trans>For admins of entire account</Trans>; <Trans>all worksites</Trans>
                      </span>
                      <span>
                        <strong>
                          - <Trans>Account Viewer</Trans>:
                        </strong>{" "}
                        <Trans>For users with access to all worksites (data only)</Trans>
                      </span>
                      <span>
                        <strong>
                          - <Trans>Worksite Admin</Trans>:
                        </strong>{" "}
                        <Trans>For admins of individual worksites only</Trans>
                      </span>
                      <span>
                        <strong>
                          - <Trans>Operator</Trans>:
                        </strong>
                        <Trans>For employees in individual worksites</Trans>
                      </span>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (id) => dispatch(setDropdownAccountId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOverviewForm);
