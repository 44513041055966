import { assetManagementTypes } from "./asset.types";
import { addAssetToList } from "./asset.util";

const INTIAL_STATE = {
  //1. asset list
  asset: [],
  //2. Selected asset overview
  assetOverview: null,
  //To edit asset
  Loading: false,
  Success: false,
  Error: false,
  AssetImageLoading: false,
  AssetImageSuccess: false,
  AssetImageError: false,
  AssetImage: "",
  assetOverviewLoading: false,
};

export const assetManagementReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    // Case 1: Selecting asset from the asset List
    case assetManagementTypes.LIST_ASSET:
      return {
        ...state,
        asset: action.payload,
      };

    //Case 2: To update asset status
    case assetManagementTypes.UPDATE_ASSET_STATUS:
      return {
        ...state,
        asset: state.asset.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              AssetStatus: action.data.AssetStatus,
            };
          }
          return item;
        }),
      };

    // Case 2: Select Default Asset Overview from the Asset List
    case assetManagementTypes.DEFAULT_ASSET_OVERVIEW:
      return {
        ...state,
        assetOverview: state.asset.find((asset, index) => index === 0),
      };

    // Case 3: Selected Asset Overview
    case assetManagementTypes.ASSET_OVERVIEW:
      return {
        ...state,
        assetOverview: state.asset.find((asset) => asset._id === action.payload),
        assetOverviewLoading: false,
      };
    case assetManagementTypes.ASSET_OVERVIEW_LOADING:
      return {
        ...state,
        assetOverviewLoading: true,
      };
    // Case 4: Add Asset to the Asset List
    case assetManagementTypes.ADD_ASSET:
      return {
        ...state,
        asset: addAssetToList(state.asset, action.payload),
      };

    // To edit asset
    case assetManagementTypes.EDIT_ASSET_LOADING:
      return {
        ...state,
        Loading: true,
        Success: false,
        Error: false,
      };

    case assetManagementTypes.EDIT_ASSET_SUCCESS:
      return {
        ...state,
        Loading: false,
        Success: true,
        Error: false,
        asset: state.asset.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              assetName: action.data.data.assetName,
            };
          }
          return item;
        }),
      };

    case assetManagementTypes.EDIT_ASSET_ERROR:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: true,
      };

    // To add or edit asset image
    // case assetManagementTypes.ADD_ASSET_IMAGE_LOADING:
    //   return {
    //     ...state,
    //     AssetImageLoading: true,
    //     AssetImageSuccess: false,
    //     AssetImageError: false,
    //   };

    // case assetManagementTypes.ADD_ASSET_IMAGE_SUCCESS:
    //   console.log(action.data.data.data+'========'+action.data.id,'===============',state)
    //   return {
    //     ...state,
    //     AssetImageLoading: false,
    //     AssetImageSuccess: true,
    //     AssetImageError: false,
    //     AssetImage: action.data.data.data,
    //     Assets: state.Assets.map((item) => {
    //       if (item._id === action.data.id) {
    //         return {
    //           ...item,
    //           assetImage: action.data.data.data,
    //         };
    //       }
    //       return item;
    //     }),
    //   };

    // case assetManagementTypes.ADD_ASSET_IMAGE_ERROR:
    //   return {
    //     ...state,
    //     AssetImageLoading: false,
    //     AssetImageSuccess: false,
    //     AssetImageError: true,
    //   };

    case assetManagementTypes.RESET_STATE:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: false,
        AssetImageLoading: false,
        AssetImageSuccess: false,
        AssetImageError: false,
      };

    // Returning default state
    default:
      return state;
  }
};
