import styled from "styled-components";
export const ModalHeader = styled.div`
  display: flex;
  margin: 15px;
`;
export const ModalTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 24px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  line-height: 32px;
`;
export const TitleSpan = styled.div`
  flex: 1;
`;
export const ButtonDiv = styled.div``;
export const ModalSpan = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
`;
