import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import WorksiteList from "./WorksiteList.component";
import Worksite from "./Worksite.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import SearchBox from "../../../components/SearchBox/Searchbox.component";
import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";
import styled from "styled-components";

const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorksiteManagement = ({ setEditAlertModal, editForm, editFormSecond, WorksiteLoading }) => {
  const [SearchText, setSearchText] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("all");
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (WorksiteLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!WorksiteLoading) {
      setShowLoader(false);
    }
  }, [WorksiteLoading, hasShownOnce]);
  return (
    <>
      {showLoader && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}
      <Row className="mt-2">
        <Col lg={3}>
          <SearchBox
            label={t("Your Worksites")}
            placeholder={t("Search a Worksite")}
            searchText={SearchText}
            onChange={(e) => setSearchText(e.target.value)}
            setSearchText={(data) =>
              editForm && editFormSecond ? setSearchText(data) : setEditAlertModal(true)
            }
          />

          <WorksiteList
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            searchText={SearchText}
            filterAccountId={filterAccountId}
            setShowWorksiteDetail={() => {}}
          />
        </Col>

        <Col lg={9}>
          <Worksite
            setSearchText={setSearchText}
            searchText={SearchText}
            filterAccountId={filterAccountId}
            setFilterAccountId={setFilterAccountId}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  WorksiteLoading: state.worksites.WorksiteLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteManagement);
