export const vehicleClasses = [
  {
    label: "Class 1",
    value: "CLASS-I",
  },
  {
    label: "Class 2",
    value: "CLASS-II",
  },
  {
    label: "Class 3",
    value: "CLASS-III",
  },
  {
    label: "Class 4",
    value: "CLASS-IV",
  },
  {
    label: "Class 5",
    value: "CLASS-V",
  },
  {
    label: "Class 6",
    value: "CLASS-VI",
  },
  {
    label: "Class 7",
    value: "CLASS-VII",
  },
];

export const vehicleClassesByValue = {
  "CLASS-I": {
    label: "Class 1",
    value: "CLASS-I",
  },
  "CLASS-II": {
    label: "Class 2",
    value: "CLASS-II",
  },
  "CLASS-III": {
    label: "Class 3",
    value: "CLASS-III",
  },
  "CLASS-IV": {
    label: "Class 4",
    value: "CLASS-IV",
  },
  "CLASS-V": {
    label: "Class 5",
    value: "CLASS-V",
  },
  "CLASS-VI": {
    label: "Class 6",
    value: "CLASS-VI",
  },
  "CLASS-VII": {
    label: "Class 7",
    value: "CLASS-VII",
  },
};
