import { useEffect, useState } from "react";
import styled from "styled-components";

const MessageWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 35px;
  background: #d6901d;
  border-radius: 3px;
  font-size: 14px !important;
  transform: translateX(-50%);
  padding: 10px 20px;
  color: white;
  font-weight: 300 !important;
  font-family: "Open Sans";
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 99999;
`;

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean" ? navigator.onLine : true;

const OfflineModal = () => {
  const [isOnline, setIsOnline] = useState(getOnLineStatus());

  const setOnline = () => setIsOnline(true);
  const setOffline = () => setIsOnline(false);

  useEffect(() => {
    console.debug("isOnline", isOnline);
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);
    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Backdrop>
          <MessageWrapper>You're offline. Please check your internet connection.</MessageWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default OfflineModal;
