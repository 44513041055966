import styled from "styled-components";
export const CarouselWrapperDiv = styled.div`
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .count-card {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    span {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  .borderLeftStyle {
    border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const DashboardContainer = styled.div`
  position: relative;

  
  thead.table-thead-style {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .head_style_link a {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  thead.theadStyleCss {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    border: 0px solid;
  }
  .tablethheight div div {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .AccordReport_Tog .m-4 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .table {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .AccordReport_Tog .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .cls-1 {
    fill: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  @media screen and (max-width: 1100px) {
    .accorbuutt button.FilterButton {
      padding: 8px 16px;
      border-radius: 4px;
    }
  }
`;

export const CarouselDivCont = styled.div`
  .react-multi-carousel-list {
    .react-multiple-carousel__arrow {
      display: none;
    }
    @media screen and (max-width: 1100px) {
      margin-bottom: 10px;
      .react-multiple-carousel__arrow {
        display: block;
      }
    }
  }
  ul {
    li {
      &:nth-child(4) {
        &:after {
          width: 0px;
        }

        @media screen and (max-width: 1100px) {
          &:after {
            width: 1px;
          }
        }
      }
    }
  }
`;
export const CarouselDivContto = styled.div`
  ul {
    li {
      &:after {
        width: 0px;
      }
    }
  }
`;
export const LeftArrowIcon = styled.div`
  font-size: 10px !important;
  float: right;
  position: relative;
  top: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
`;

export const FailedInspectionDiv = styled.div`
  .noReportsDiv {
    font-size: 14px !important;
  }
  .rowHeight {
    height: auto !important;
    max-height: calc(-565px + 100vh);
  }
`;

export const NoReportsDiv = styled.div``;

export const GraphContainer = styled.div`
  padding-top: 8px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
  .apexcharts-toolbar{
    z-index: 1 !important;
  }
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
