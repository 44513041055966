import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "../Button/Button.component";
import { MarketinSlider, ImgWrapper } from "./MarketingSlider.component.style";

// Marketing Carousel Component
const MarketingCarousel = ({ items, setHeader, handleChange }) => {
  const { t } = useTranslation();

  const onHandleRegister = (e) => {
    setHeader({ status: "register" });
    handleChange(e, 1);
  };

  return (
    <MarketinSlider>
      <Carousel>
        {items.map((item, index) => (
          <Carousel.Item key={index}>
            <ImgWrapper src={item.src} alt={`Slide ${index + 1}`} />
            <Carousel.Caption>
              <Button
                onClick={item.onClick === "register" ? onHandleRegister : item.onClick}
                className={item.buttonType === "primary" ? "primary-button" : "secondary-button"}
                label={item.label}
              />
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </MarketinSlider>
  );
};

MarketingCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
      buttonType: PropTypes.oneOf(["primary", "secondary"]).isRequired,
    })
  ).isRequired,
  setHeader: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default MarketingCarousel;
