import { LRUMap } from "lru_map";
export class CustomRSMSLruCache {
  prefix = ""; // not necessary
  maxCount = 100;
  constructor(maxCountArg) {
    if (maxCountArg) {
      this.maxCount = maxCountArg;
    }
    this.storage = new LRUMap(this.maxCount);
  }
  storage = null;
  get = function (key, withPrefix = true) {
    if (!this.storage) {
      return null;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    let _value = this.storage.get(_key);
    try {
      return JSON.parse(_value);
    } catch (e) {
      return _value;
    }
  };
  set = function (key, value, withPrefix = true) {
    if (!this.storage) {
      console.log("OOPS Local Storage Not found ...cache will not work ..");
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    let _value;
    try {
      _value = JSON.stringify(value);
    } catch (e) {
      _value = value;
    }
    this.storage.set(_key, _value);
  };
  //ttl in millis
  setWithExpiry = function setWithExpiry(key, value, ttl, withPrefix = true) {
    if (!this.storage) {
      console.log("OOPS Local Storage Not found ...cache will not work ..");
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    let _value;
    try {
      _value = JSON.stringify(item);
    } catch (e) {
      _value = item;
    }
    this.storage.set(_key, _value);
  };
  getWithExpiry = function getWithExpiry(key) {
    if (!this.storage) {
      return null;
    }
    const itemStr = this.storage.get(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      this.storage.delete(key);
      return null;
    }
    console.log("came from cache");
    return item.value;
  };

  remove = function (key, withPrefix = false) {
    if (!this.storage) {
      return;
    }
    let _key = key;
    if (withPrefix) {
      _key = this.prefix + key;
    }
    this.storage.delete(_key);
  };

  removeWhere(testFn) {
    // Get a copy of the keys array - it won't be modified when we remove items from storage
    if (!this.storage) {
      return;
    }
    let keysToRemove = [];
    this.storage.forEach(function (value, key) {
      // console.log(`${key}: ${value}`);
      if (testFn(key, value) === true) {
        // console.log(`${key}: ${value}`);
        // this.remove(key, false);//directly deleting breaks the for loop as soon as u remove one .. we need to remove all entires so commenting this out
        keysToRemove.push(key);
      }
    });
    for (let i = 0; i < keysToRemove.length; i++) {
      const keyToDelete = keysToRemove[i];
      this.remove(keyToDelete, false);
    }

    console.log(this.storage);
    // for (let [key, value] of Object.entries(this.storage)) {

    // }
  }
  keys = function () {
    if (!this.storage) {
      return [];
    }
    let ret = [];
    this.storage.forEach(function (key, value) {
      // console.log(`${key}: ${value}`);
      ret.push(key);
    });
    return ret;
  };
  has = function (key) {
    if (!this.storage) {
      return false;
    }
    return this.getWithExpiry(key);
  };
  purge = function () {
    return this.storage.clear();
  };
}
