import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Input from "../../../../../components/Input";
import { setDropdownAccountId } from "../../../../../redux/dropdownFilters/action";
import FileUploadWithDragAndDrop from "../../../../../components/FileUploadWithDragAndDrop";
import { ReactComponent as Information } from "../../../../../assets/Information_Icon.svg";

import { uploadBulkAssets } from "../../../../../redux/bulkUpload/action";
import SlideButton from "../../../../../components/InfoDivComponent/InfoDiv.component";
// Button Component
import Button from "../../../../../components/Button/Button.component";
import DropdownFilterWrapper from "../../../../../components/DropdownComponent/DropdownFilterWrapper";
import {
  RowWrapper,
  ButtonWrapper,
  AccessLevelsText,
  AccessLevelDescription,
  AssetBulkUploadForm,
} from "./AssetBulkUploadUploadFiles.component.styles";

function AssetBulkUploadUploadFiles(props) {
  let {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    dropdownAccountList,
    dropdownWorksiteList,
    dropdownAssetTypeList,
    dropdownFilterProductList,
    setDropdownAccountId,
    uploadBulkAssets,
    assetBulkUploadFileLoading,
  } = props;

  const [accountId, setAccountId] = useState("");
  const [isValidationError, setValidationError] = useState([]);
  const [worksite, setWorksite] = useState(loginDetails?.userData?.authorizedSites?.[0] || "");
  const [assetType, setAssetType] = useState("");
  const [productType, setProductType] = useState("");
  const [csvFile, setcsvFile] = useState(null);
  const [isAccordianVisible, setisAccordianVisible] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isCSVFileError, setIsCSVFileError] = useState(false);

  const { t } = useTranslation();

  const handleAccountFilterChange = (value) => {
    setAccountId(value);
    setDropdownAccountId(value);
  };

  const handleBulkUploadAsset = () => {
    setIsSubmitClicked(true);
    console.log(isValidationError?.length);
    if (isValidationError?.length != 0 || !csvFile) {
      return;
    }
    console.log("Upload");

    let data = new FormData();
    data.append("templatefile", csvFile);
    data.append(
      "templateData",
      JSON.stringify({
        accountId: accountId || loginDetails?.accountId,
        siteId: isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : worksite,
        assetTypeId: assetType,
        productType: productType,
      })
    );

    uploadBulkAssets(data);
  };

  useEffect(() => {
    if (isSubmitClicked) {
      let errors = [];
      if (isSuperAdmin && !accountId) {
        errors.push("Account");
      }
      if (!isSiteAdmin && !worksite) {
        errors.push("Worksite");
      }
      if (!assetType) {
        errors.push("Asset Type");
      }
      if (!productType) {
        errors.push("SIERA.AI Product");
      }
      if (!csvFile) {
        setIsCSVFileError(true);
      }
      setValidationError(errors);
    }
  }, [accountId, worksite, assetType, productType, isSubmitClicked]);

  useEffect(() => {
    setWorksite("");
    setAssetType("");
    setProductType("");
  }, [accountId]);

  useEffect(() => {
    if (csvFile) {
      setIsCSVFileError(false);
    }
  }, [csvFile]);

  const ComponentDataUploadFiles = () => {
    return (
      <AccessLevelsText>
        <ul class="dashed">
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>
                  Please select the correct worksite,asset type and the SIERA.AI Product before submitting the
                  file to avoid errors in the asset's information
                </Trans>
                .
              </strong>
            </AccessLevelDescription>
          </li>
        </ul>
      </AccessLevelsText>
    );
  };

  return (
    <AssetBulkUploadForm>
      <Row className="mt-2">
        {isSuperAdmin && (
          <Col md={6}>
            <DropdownFilterWrapper
              label={t("Account")}
              isValidationError={isValidationError}
              feedback={t("Please select an account")}
              value={accountId}
              onChange={(value) => handleAccountFilterChange(value)}
              options={dropdownAccountList?.map((option) => ({
                _id: option._id,
                label: option.companyName,
              }))}
              // inputRef = {accountRef}
            />
          </Col>
        )}

        {!isSiteAdmin && (
          <Col md={6}>
            <DropdownFilterWrapper
              label={t("Worksite")}
              required={true}
              isValidationError={isValidationError}
              displayEmpty
              value={worksite}
              onChange={(value) => setWorksite(value)}
              options={dropdownWorksiteList?.map((option) => ({
                _id: option._id,
                label: option.name,
              }))}
              feedback={t("Please select a Worksite")}
            />
          </Col>
        )}
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <DropdownFilterWrapper
            required={true}
            label={t("Asset Type")}
            isValidationError={isValidationError}
            value={assetType}
            displayEmpty
            onChange={(value) => setAssetType(value)}
            options={dropdownAssetTypeList?.map((option) => ({
              _id: option._id,
              label: option.AssetTypeName,
            }))}
            feedback={t("Please select an asset type")}
            // inputRef={inputRef}
          />
        </Col>

        <Col md={6}>
          <DropdownFilterWrapper
            label={t("SIERA.AI Product")}
            options={dropdownFilterProductList?.map((option) => ({
              _id: option._id,
              label: option.name,
            }))}
            required={true}
            isValidationError={isValidationError}
            value={productType}
            onChange={(value) => {
              setProductType(value);
            }}
            feedback={t("Please select a Product")}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <FileUploadWithDragAndDrop
            label={t("Asset Bulk Upload CSV Files")}
            setcsvFile={setcsvFile}
            csvFile={csvFile}
            isCSVFileError={isCSVFileError}
          />
        </Col>
      </Row>

      <Row>
        <RowWrapper>
          <SlideButton
            buttonName={t("IMPORTANT INSTRUCTIONS")}
            divContent={<ComponentDataUploadFiles />}
            iconName="information-tooltip"
            defaultOpen={false}
          />
        </RowWrapper>
        <ButtonWrapper>
          {assetBulkUploadFileLoading ? (
            <Button
              label={<div className="spinner-border" title="Submit" role="status"></div>}
              showIcon={false}
              buttonType="save"
            />
          ) : (
            <Button
              label={t("Submit")}
              onClick={() => {
                handleBulkUploadAsset();
              }}
              showIcon
              iconClass="save"
              buttonType="save"
            />
          )}
        </ButtonWrapper>
      </Row>
    </AssetBulkUploadForm>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownFilterProductList: state.dropdownFilters.productList,
  assetBulkUploadFileLoading: state.bulkUpload.assetBulkUploadFileUploadLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  uploadBulkAssets: (data) => dispatch(uploadBulkAssets(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUploadUploadFiles));
