export const checklistManagementTypes = {
  //For Checklist List
  GET_CHECKLIST_LOADING: "GET_CHECKLIST_LOADING",
  GET_CHECKLIST_SUCCESS: "GET_CHECKLIST_SUCCESS",
  GET_CHECKLIST_ERROR: "GET_CHECKLIST_ERROR",

  //For checklist templates list
  GET_CHECKLIST_TEMPLATES_LOADING: "GET_CHECKLIST_TEMPLATES_LOADING",
  GET_CHECKLIST_TEMPLATES_SUCCESS: "GET_CHECKLIST_TEMPLATES_SUCCESS",
  GET_CHECKLIST_TEMPLATES_ERROR: "GET_CHECKLIST_TEMPLATES_ERROR",
  CHECKLIST_TEMPLATE_OVERVIEW_SUCCESS: "CHECKLIST_TEMPLATE_OVERVIEW_SUCCESS",

  //For Checklist Create
  CREATE_CHECKLIST_LOADING: "CREATE_CHECKLIST_LOADING",
  RESEST_CHECKLIST_SUCCESS: "RESEST_CHECKLIST_SUCCESS",
  CREATE_CHECKLIST_SUCCESS: "CREATE_CHECKLIST_SUCCESS",
  CREATE_CHECKLIST_ERROR: "CREATE_CHECKLIST_ERROR",
  RESET_CHECKLISTS_SUCCESS_FLAG: "RESET_CHECKLISTS_SUCCESS_FLAG",

  //For Updating Checklist
  UPDATE_CHECKLIST_LOADING: "UPDATE_CHECKLIST_LOADING",
  UPDATE_CHECKLIST_SUCCESS: "UPDATE_CHECKLIST_SUCCESS",
  UPDATE_CHECKLIST_ERROR: "UPDATE_CHECKLIST_ERROR",

  //For Add or Updating Section
  ADD_UPDATE_SECTION_LOADING: "ADD_UPDATE_SECTION_LOADING",
  ADD_UPDATE_SECTION_SUCCESS: "ADD_UPDATE_SECTION_SUCCESS",
  ADD_UPDATE_SECTION_ERROR: "ADD_UPDATE_SECTION_ERROR",

  //For Add or Updating Question
  ADD_UPDATE_QUESTION_LOADING: "ADD_UPDATE_QUESTION_LOADING",
  ADD_UPDATE_QUESTION_SUCCESS: "ADD_UPDATE_QUESTION_SUCCESS",
  ADD_UPDATE_QUESTION_ERROR: "ADD_UPDATE_QUESTION_ERROR",

  //Checklist Overview
  CHECKLIST_OVERVIEW_LOADING: "CHECKLIST_OVERVIEW_LOADING",
  CHECKLIST_OVERVIEW_SUCCESS: "CHECKLIST_OVERVIEW_SUCCESS",

  // Response Type
  GET_RESPONSE_TYPE_LOADING: "GET_RESPONSE_TYPE_LOADING",
  GET_RESPONSE_TYPE_SUCCESS: "GET_RESPONSE_TYPE_SUCCESS",

  // Edit template
  UPDATE_EDIT_TEMPLATE: "UPDATE_EDIT_TEMPLATE",
};
