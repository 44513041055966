import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SnackBar from "../../SnackBar/SnackBar";
import { getInspectionPlan } from "../../../../redux/config/action";
import permission from "../../../../constant/permission";
import { Trans, useTranslation } from "react-i18next";
import planConstant from "../../../../constant/planConstant.json";
import alertConstant from "../../../../constant/messages/toaster.json";
import validationConstant from "../../../../constant/messages/validation.json";
import InspectionModule from "../Inspection/Inspection";

const { inspectionPlan } = planConstant;
const StartInspection = ({
  assetCheckList,
  selectedCheckList,
  loginDetails,
  inspectionPlanDetail,
  setShowInspection,
}) => {
  const [alertFailed, setAlertFailed] = useState(false);
  const { t } = useTranslation();

  const handleCloseSnackBar = () => {
    setAlertFailed(false);
  };

  return (
    <div>
      <InspectionModule setShowInspection={setShowInspection} />
      <SnackBar isFailed={alertFailed} label={alertFailed} handleClose={handleCloseSnackBar} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  inspectionPlanDetail: state.config.inspectionPlan,
});

const mapDispatchToProps = (dispatch) => ({
  getInspectionPlan: (accountId) => dispatch(getInspectionPlan(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartInspection));
