import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Trans, useTranslation } from "react-i18next";
import "react-pro-sidebar/dist/css/styles.css";
import { DesktopMenuAccordian, ResponsiveMenuDropdown } from "./SystemSetting.component.styles";
import CustomDropdown from "../../Analytics/Analytics/Charts/ChartsList/DropdownWrapperComp";

const MenuBar = ({ history, permissions, isAccountOwner, isSuperAdmin }) => {
  const { t } = useTranslation();

  const accountSettings = {
    id: "accountSettings",
    label: t("Account Settings"),
    route: "account-settings",
    subMenuRoutes: [t("account-profile"), t("password-policy"), t("mfa-authentication")],
    subMenu: [
      {
        id: "accountProfile",
        label: "Account Profile",
        route: "account-profile",
      },
      {
        id: "passwordPolicy",
        label: "Password Policy",
        route: "password-policy",
      },
      {
        id: "mfaAuthentication",
        label: "Multi-Factor Authentication",
        route: "mfa-authentication",
      },
    ],
  };

  const superAdminAccountSettings = {
    id: "accountSettings",
    label: t("Account Settings"),
    route: "account-settings",
    subMenuRoutes: [t("account-profile"), t("password-policy"), t("mfa-authentication")],
    subMenu: [
      {
        id: "passwordPolicy",
        label: "Password Policy",
        route: "password-policy",
      },
    ],
  };

  const userSettings = {
    id: "userSettings",
    label: t("User Settings"),
    route: "user-settings",
    subMenuRoutes: ["user-profile", "password", "pin"],

    subMenu: [
      {
        id: "user-profile",
        label: "User Profile",
        route: "user-profile",
      },
      {
        id: "password",
        label: "Change Password",
        route: "password",
      },
      {
        id: "pin",
        label: "Change Pin",
        route: "pin",
      },
    ],
  };

  const menuItems = isAccountOwner
    ? [accountSettings, userSettings]
    : isSuperAdmin
      ? [superAdminAccountSettings, userSettings]
      : [userSettings];
  const [selectedRoute, setSelectedRoute] = useState(
    isAccountOwner || isSuperAdmin ? "account-profile" : "user-profile"
  );
  const [expandedPanel, setExpandedPanel] = useState("");

  const userManagementObj = permissions[0].permissions.find(
    (permission) => permission.name === "USER_MANAGEMENT"
  );

  useEffect(() => {
    const route = history?.location?.pathname?.replace("/app/general-setting/", "");
    setSelectedRoute(route);

    menuItems.forEach((menuItem) => {
      if (menuItem.subMenuRoutes?.includes(route)) {
        setExpandedPanel(menuItem.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.pathname]);

  const settingsRoutes = {
    accountSettings: ["mfa-authentication", "password-policy", "account-profile"],
    userSettings: ["user-profile", "password", "pin"],
  };

  const clickHandler = (route) => {
    history.push(route);
    setSelectedRoute(route);
  };
  const onOpenChangeHandler = (id) => {
    setExpandedPanel(id);
  };

  const options = menuItems.map((menuItem) => {
    if (menuItem.subMenu) {
      const groupOptions = menuItem.subMenu.map((subMenu) => ({
        value: subMenu.route,
        label: subMenu.label,
      }));
      return {
        label: menuItem.label,
        options: groupOptions,
      };
    } else {
      return {
        label: menuItem.label,
        options: [{ value: menuItem.route, label: menuItem.label }],
      };
    }
  });

  const getMenuItem = (subMenu) => {
    return (
      <>
        {subMenu.id === "accountSummaryAlertSettings" && !(isAccountOwner || isSuperAdmin) ? null : (
          <MenuItem
            onClick={() => clickHandler(subMenu.route)}
            active={subMenu.route === selectedRoute}
            title={subMenu.label}
          >
            <Trans>{subMenu.label}</Trans>
          </MenuItem>
        )}
      </>
    );
  };

  return (
    <ProSidebar>
      <ResponsiveMenuDropdown>
        <CustomDropdown
          options={options.flat()}
          value={options?.label}
          isSearchable={true}
          isLoading={false}
          groupedOptions={true}
          handleFilter={(selectedData) => clickHandler(selectedData.value)}
        />
      </ResponsiveMenuDropdown>
      <DesktopMenuAccordian>
        <Menu>
          {menuItems.map((menuItem, index) => {
            return (
              <>
                {menuItem.subMenu ? (
                  <SubMenu
                    title={menuItem.label}
                    onOpenChange={() => onOpenChangeHandler(menuItem.id)}
                    open={expandedPanel === menuItem.id}
                  >
                    {menuItem.subMenu.map((subMenu, subMenuIndex) => {
                      return (
                        <Fragment key={subMenuIndex}>
                          {subMenu.id === "operatorCertificationExpirationAlertSettings" ||
                          subMenu.id === "WorksiteSummaryAlertSettings" ||
                          subMenu.id === "accountSummaryAlertSettings"
                            ? userManagementObj?.action?.view
                              ? getMenuItem(subMenu)
                              : null
                            : getMenuItem(subMenu)}
                        </Fragment>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <MenuItem
                    onClick={() => clickHandler(menuItem.route)}
                    active={
                      menuItem.route === selectedRoute ||
                      (menuItem.route === "account-settings" &&
                        settingsRoutes.accountSettings.includes(selectedRoute))
                    }
                    title={menuItem.label}
                  >
                    <Trans>{menuItem.label}</Trans>
                  </MenuItem>
                )}
              </>
            );
          })}
        </Menu>
      </DesktopMenuAccordian>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
