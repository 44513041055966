/**When time permits, rewrite this file. Change naming conventions, cleanup the flow */

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import axios from "axios";

import { Row, Col, Form } from "react-bootstrap";

import { Trans, useTranslation } from "react-i18next";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
// Button Component
import Button from "../../../components/Button/Button.component";

import UploadMessageComponent from "../../../components/BulkUploadComponents/UploadMessage.component";
import FileUploaderComponent from "../../../components/BulkUploadComponents/FileUploadCSV.component";

import { getUserBulkUploadTemplates, getUsers } from "../../../redux/users/action";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";
import config from "../../../config";
import SiearTab from "../../../components/TabComponent/SieraTab.Component";
import {
  RowDiv,
  Column,
  RowsContainer,
  MobileView,
  AccessLevelsText,
  AccessLevelHeading,
  AccessLevelDescription,
  LoaderDiv,
  RowWrapper,
  ButtonWrapper,
  DownloadStyled,
  UserBulkUploadWrapper,
} from "./UserBulkUploadForm.component.styles";
import SlideButton from "../../../components/InfoDivComponent/InfoDiv.component";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";

//const s3BaseUrl = config.s3BaseUrl;
//const baseUrl = config.baseUrl; //Base Url
const csvuploadErrorLogS3 = config.s3BucketUserBulkUploadErrorLog; //URL for user bulk upload csv

function UserBulkUploadForm({
  dropdownWorksiteList,
  loginDetails,
  getUserBulkUploadTemplates,
  UserBulkUploadTemplates,
  UserBulkUploadLoading,
  handleCancelModal,
  getUsers,
  dropdownAccountList,
  setDropdownAccountId,
  isSuperAdmin,
  isSiteAdmin,
  userTypeDetailList,
}) {
  const { t } = useTranslation();
  const [isDownloadTemplateActive, setIsDownloadTemplateActive] = useState(true);
  const [dragText, setDragText] = useState(t("Please upload .CSV file"));
  const [csvFile, setCSVFile] = useState(null);
  const [worksite, setWorksite] = useState(loginDetails?.userData?.authorizedSites?.[0] || "");
  const [userTypeId, setUserTypeId] = useState("");
  const [csvFileUploadSuccess, setCSVFileUploadSuccess] = useState(false);
  const [isAPIRequestFailed, setIsAPIRequestFailed] = useState(false);
  const [isCSVParsedSuccess, setIsCSVParsedSuccess] = useState(true);
  const [userAddedCount, setUserAddedCount] = useState(0);
  const [userFailedCount, setUserFailedCount] = useState(0);
  const [userTotalCount, setUserTotalCount] = useState(0);
  const [csvFileUploadingStatus, setCSVFileUploadingStatus] = useState(false);
  const [validated, setValidated] = useState(false);
  const [accountIdLocal, setAccountIdLocal] = useState(loginDetails.accountId);
  const [accountNoLocal, setAccountNoLocal] = useState(loginDetails.accountNo);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLogCSV, setErrorLogCSV] = useState("");
  const [noFileCsvErrorMsg, setNoFileCsvErrorMsg] = useState("");
  const [isValidationError, setValidationError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [userTypeList, setUserTypeList] = useState([]);

  /* const [isAccordianVisible, setisAccordianVisible] = useState(false); */

  const csvFileUploadInputRef = useRef(null);

  useEffect(() => {
    console.log(csvFile);
  }, [csvFile]);

  useEffect(() => {
    UserBulkUploadTemplates?.length == 0 && getUserBulkUploadTemplates();
    setUserTypeList(
      userTypeDetailList
        ?.filter((user) => user?.accessLevel == "INDIVIDUAL_EMPLOYEE")
        ?.map((user) => {
          return {
            _id: user?._id,
            label: user?.name,
          };
        })
    );
  }, []);

  useEffect(() => {
    setUserTypeList(
      userTypeDetailList
        ?.filter((user) => user?.accessLevel == "INDIVIDUAL_EMPLOYEE")
        ?.map((user) => {
          return {
            _id: user?._id,
            label: user?.name,
          };
        })
    );
  }, [userTypeDetailList]);

  const validateUserData = (formData) => {
    let accountId = formData.accountIdLocal;
    let userTypeID = formData.userTypeId;
    let siteId = formData.worksite;
    let errors = [];
    if (!userTypeID) {
      errors.push(t("User Type"));
    }
    if (!accountId && isSuperAdmin) {
      errors.push(t("Account"));
    }
    if (!siteId && !isSiteAdmin) {
      errors.push(t("Worksite"));
    }

    return errors;
  };

  useEffect(() => {
    if (!isSubmitClicked) {
      return;
    }
    const isError = validateUserData({ accountIdLocal, userTypeId, worksite });
    if (isError[0]) {
      setValidationError(isError);
    } else {
      setValidationError(false);
    }
  }, [accountIdLocal, userTypeId, worksite, isSubmitClicked]);

  const handleUploadFormSubmit = (e) => {
    const isError = validateUserData({ accountIdLocal, userTypeId, worksite });
    if (isValidationError || isError.length > 0) {
      e.preventDefault();
      return;
    }
    e.preventDefault();

    let data = new FormData();
    data.append("templatefile", csvFile);
    data.append(
      "templateData",
      JSON.stringify({
        accountId: accountIdLocal || loginDetails?.accountId,
        siteId: isSiteAdmin ? loginDetails.userData.authorizedSites[0] : worksite,
        accountNo: accountNoLocal,
        userTypeId: userTypeId,
      })
    );
    setIsAPIRequestFailed(false);
    if ((accountIdLocal || loginDetails?.accountId) && accountNoLocal && csvFile && !isValidationError) {
      setCSVFileUploadingStatus(true);
      apiCall(`/bulkupload/uploadOperator/`, { method: "POST", data: data, type: "file" })
        .then((resp) => {
          setCSVFileUploadingStatus(false);
          setIsCSVParsedSuccess(resp?.data["success"]);
          setCSVFileUploadSuccess(true);
          if (!resp?.data?.success) {
            setErrorMessage(resp?.data?.message || "");
            //setIsAPIRequestFailed(true);
            setErrorLogCSV(`${csvuploadErrorLogS3}${resp?.data?.ErrorLog}`);
          } else {
            setUserAddedCount(resp?.data["Inserted Records"] || 0);
            setUserFailedCount(resp?.data["Failed Records"] || 0);
            setUserTotalCount(resp?.data["Total Records"] || 0);
            setErrorLogCSV(`${csvuploadErrorLogS3}${resp?.data?.ErrorLog}`);
            if (resp?.data["Inserted Records"] > 0)
              getUsers({ pageNo: 1, pageSize: 15, searchKey: "" }, true);

            setIsAPIRequestFailed(false);
          }
          setValidationError(false);
          setIsSubmitClicked(false);
        })
        .catch((err) => {
          setIsAPIRequestFailed(true);
          setCSVFileUploadingStatus(false);
          console.log(err);
          setValidationError(false);
          setIsSubmitClicked(false);
        });
    } else {
      setValidated(true);
      if (!csvFile) setNoFileCsvErrorMsg(validationConstant.invalid_csv_file);
      // console.log(`AccountID : ${accountIdLocal}`,`Account No : ${accountNoLocal}` , `Worksite : ${worksite} `, csvFile)
    }
  };

  const handleReuploadButton = () => {
    setIsAPIRequestFailed(false);
    setCSVFileUploadSuccess(false);
    setWorksite(loginDetails?.userData?.authorizedSites[0] || "");
    setUserTypeId("");
    setAccountIdLocal("");
    setValidated(false);
    setCSVFile(null);
    setErrorMessage("");
  };

  const handleContinueButton = () => {
    handleCancelModal();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop your file here, or browse"));
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          // uploadedFile.push(file)
          const str = file?.name;
          const compareStr = str.slice(str.length - 4, str.length);
          if (compareStr == ".csv") {
            setCSVFile(file);
            setNoFileCsvErrorMsg("");
          } else {
            setCSVFile(null);
            setNoFileCsvErrorMsg(validationConstant.invalid_csv_file);
          }
          // setCSVFile(file)
          // setUploadedFile(uploadedFile)
          console.log("... file[" + i + "].name = " + file.name);
          // console.log(uploadedFile[0], '=====');
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log("... file[" + i + "].name = " + e.dataTransfer.files[i].name);
      }
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop Here..."));
  };

  const handleAccountFilterChange = (value) => {
    getAccountDetail(value);
    setAccountIdLocal(value);
    setDropdownAccountId(value);
  };

  const handleCSVFileUpload = (e) => {
    console.log(e?.target?.files[0]?.name);
    const str = e?.target?.files[0]?.name;
    const compareStr = str.slice(str.length - 4, str.length);
    if (compareStr == ".csv") {
      setCSVFile(e.target.files[0]);
      setNoFileCsvErrorMsg("");
    } else {
      setCSVFile(null);
      setNoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  };

  const getAccountDetail = (accountId) => {
    // axios.get(`${baseUrl}/account/${accountIdLocal}`)
    apiCall(`/account/${accountId}`)
      .then((resp) => {
        setAccountNoLocal(resp?.data?.data?.accountNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabSelect = (eventKey) => {
    if (eventKey === "downloadTemplate") {
      setIsDownloadTemplateActive(true);
    } else {
      setIsDownloadTemplateActive(false);
      setCSVFile(null);
    }
  };

  const ComponentDataDownloadFiles = () => {
    return (
      <AccessLevelsText>
        <AccessLevelHeading></AccessLevelHeading>
        <ul class="dashed">
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>
                  Please ensure that you download the correct template to enter the details of your users
                </Trans>
              </strong>
            </AccessLevelDescription>
          </li>
        </ul>
      </AccessLevelsText>
    );
  };

  const ComponentDataUploadFiles = () => {
    return (
      <AccessLevelsText>
        <AccessLevelHeading></AccessLevelHeading>
        <ul class="dashed">
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>
                  Please select the correct access level and the worksite before submitting the files to avoid
                  any errors in the user’s information
                </Trans>
                .
              </strong>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>In case of Operators, the EmployeeID will become their PIN for login</Trans>.
              </strong>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>
                  If the Password/PIN field is left blank, a system generated PIN/Password will be created for
                  that user
                </Trans>
                .
              </strong>
            </AccessLevelDescription>
          </li>
          <li>
            <AccessLevelDescription>
              <strong>
                <Trans>Phone number is an optional field</Trans>.
              </strong>
            </AccessLevelDescription>
          </li>
        </ul>
      </AccessLevelsText>
    );
  };

  return (
    <UserBulkUploadWrapper>
      {csvFileUploadSuccess || isAPIRequestFailed ? (
        ""
      ) : (
        <SieraTabs
          defaultActiveKey={isDownloadTemplateActive ? "downloadTemplate" : "uploadFiles"}
          onSelect={handleTabSelect}
          align="middle"
        >
          <SiearTab title={t("Download Template")} eventKey="downloadTemplate"></SiearTab>
          <SiearTab title={t("Upload Files")} eventKey="uploadFiles"></SiearTab>
        </SieraTabs>
      )}
      {isDownloadTemplateActive ? (
        <>
          <RowDiv isHeader={true}>
            <Column isHeader={true} align={"left"}>
              <Trans>File Name</Trans>
            </Column>
            <Column isHeader={true} align={"left"}>
              <Trans>Description</Trans>
            </Column>
            <Column isHeader={true} align={"center"}>
              <Trans>Action</Trans>
            </Column>
          </RowDiv>
          <RowsContainer>
            {UserBulkUploadLoading ? (
              <LoaderDiv>
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </LoaderDiv>
            ) : (
              <>
                {UserBulkUploadTemplates?.map((userBulkUploadData, i) => (
                  <RowDiv key={i}>
                    <Column align={"left"}>
                      <MobileView>
                        <Trans>File Name</Trans>
                      </MobileView>

                      {userBulkUploadData?.name}
                    </Column>
                    <Column align={"left"}>
                      <MobileView>
                        <Trans>Description</Trans>
                      </MobileView>

                      {userBulkUploadData?.description || "NA"}
                    </Column>

                    <Column align={"center"}>
                      <MobileView>
                        <Trans>Action</Trans>
                      </MobileView>

                      <a
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        href={userBulkUploadData?.link}
                        download="newfilename.pdf"
                      >
                        <DownloadStyled className="icon icon-download" />
                      </a>
                    </Column>
                  </RowDiv>
                ))}
              </>
            )}
          </RowsContainer>

          <SlideButton
            buttonName={t("IMPORTANT INSTRUCTIONS")}
            divContent={<ComponentDataDownloadFiles />}
            iconName="information-tooltip"
            defaultOpen={false}
          />
        </>
      ) : (
        <>
          {isAPIRequestFailed ? (
            <>
              <UploadMessageComponent
                title="Upload Failed"
                iconClassName="icon-cancel"
                uploadMessage="The file was not uploaded"
                additionalMessageError={["An internal error occurred", "Please try uploading again"]}
                handleReuploadButton={handleReuploadButton}
                handleContinueButton={handleContinueButton}
                ButtonLabelReupload="Try Again"
                ButtonLabelContinue="Continue"
                failedItemsDownloadLink={errorLogCSV}
              />
            </>
          ) : csvFileUploadSuccess ? (
            <>
              {userTotalCount == userAddedCount && isCSVParsedSuccess ? (
                <>
                  <UploadMessageComponent
                    title="Users Added"
                    iconClassName="icon-select"
                    uploadMessage="The file was uploaded successfully"
                    additionalMessageSuccess={[
                      "Items successfully added: ",
                      <span className="greenSuccess">{`${userAddedCount}`}</span>,
                    ]}
                    handleContinueButton={handleContinueButton}
                    ButtonLabelContinue="Continue"
                    instructionModal={[
                      "An email has also been sent to your account with the details of users added",
                    ]}
                    failedItemsDownloadLink={errorLogCSV}
                  />
                </>
              ) : (
                ""
              )}

              {userTotalCount != userAddedCount && userAddedCount != 0 && isCSVParsedSuccess ? (
                <>
                  <UploadMessageComponent
                    title="Users Added"
                    iconClassName="icon-exclamation-mark"
                    uploadMessage="The file was uploaded with some errors"
                    additionalMessageSuccess={[
                      "Items successfully added: ",
                      <span className="greenSuccess">{`${userAddedCount}`}</span>,
                    ]}
                    additionalMessageError={[
                      "Items failed to add: ",
                      <span className="redFailed">{`${userFailedCount}`}</span>,
                    ]}
                    failedItemsDownloadLink={errorLogCSV}
                    handleReuploadButton={handleReuploadButton}
                    handleContinueButton={handleContinueButton}
                    ButtonLabelContinue="Continue"
                    ButtonLabelReupload="Re Upload"
                    userFailedCount
                    instructionModal={[
                      "The reasons for errors are listed in the CSV file of failed items",
                      "An email has also been sent to your account with the details of the users added",
                      "Please remove the error column from the 'failed items' CSV file to avoid errors",
                    ]}
                  />
                </>
              ) : (
                ""
              )}

              {userTotalCount == userFailedCount && userAddedCount == 0 && isCSVParsedSuccess ? (
                <>
                  <UploadMessageComponent
                    title="Unable to Add Users"
                    iconClassName="icon-cancel"
                    uploadMessage="The users were not added from the file"
                    additionalMessageError={[
                      "Uploaded data has errors in it",
                      "Please check for errors and upload again",
                    ]}
                    handleReuploadButton={handleReuploadButton}
                    handleContinueButton={handleContinueButton}
                    ButtonLabelReupload="RE-UPLOAD"
                    ButtonLabelContinue="Continue"
                    userFailedCount
                    instructionModal={[
                      "The reasons for errors are listed in the CSV file of failed items",
                      "Please remove the error column from the 'failed items' CSV file to avoid data mismatch errors",
                    ]}
                    failedItemsDownloadLink={errorLogCSV}
                  />
                </>
              ) : (
                ""
              )}

              {!isCSVParsedSuccess && errorMessage == "Column mismatch" ? (
                <>
                  <UploadMessageComponent
                    title="Unable to Add Users"
                    iconClassName="icon-cancel"
                    uploadMessage="The users were not added from the file"
                    additionalMessageError={[
                      "Data mismatch in columns",
                      "Please remove extra data columns and upload again.",
                    ]}
                    handleReuploadButton={handleReuploadButton}
                    handleContinueButton={handleContinueButton}
                    ButtonLabelReupload="RE-UPLOAD"
                    ButtonLabelContinue="Continue"
                    instructionModal={[
                      "Please remove the error column from CSV file to avoid data mismatch errors.",
                    ]}
                    failedItemsDownloadLink={errorLogCSV}
                  />
                </>
              ) : (
                ""
              )}

              {!isCSVParsedSuccess && errorMessage == "No Records" ? (
                <>
                  <UploadMessageComponent
                    title="Unable to Add Users"
                    iconClassName="icon-cancel"
                    uploadMessage="The users were not added from the file"
                    additionalMessageError={["The uploaded file is empty"]}
                    handleReuploadButton={handleReuploadButton}
                    handleContinueButton={handleContinueButton}
                    ButtonLabelReupload="RE-UPLOAD"
                    ButtonLabelContinue="Continue"
                    instructionModal={[
                      "Please enter the information of your employees in this file and upload again",
                    ]}
                    failedItemsDownloadLink={errorLogCSV}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Form noValidate validated={validated} onSubmit={(e) => handleUploadFormSubmit(e)}>
              {isSuperAdmin ? (
                <Row>
                  <Col md={6}>
                    <DropdownFilterWrapper
                      options={dropdownAccountList?.map((data) => ({
                        _id: data._id,
                        label: data.companyName,
                      }))}
                      label={t("Account")}
                      value={accountIdLocal}
                      isValidationError={isValidationError}
                      onChange={(value) => handleAccountFilterChange(value)}
                    />
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row className="mt-2">
                <Col md={6}>
                  <DropdownFilterWrapper
                    options={userTypeList}
                    label={t("User Type")}
                    required={true}
                    value={userTypeId}
                    isValidationError={isValidationError}
                    onChange={(value) => {
                      setUserTypeId(value);
                    }}
                    feedback={t("This field is required")}

                    // inputRef={inputRef}
                  />
                </Col>
                {!isSiteAdmin && (
                  <Col md={6}>
                    <DropdownFilterWrapper
                      label={t("Worksite")}
                      defaultValue={
                        dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList[0]._id
                      }
                      required
                      value={worksite}
                      isValidationError={isValidationError}
                      onChange={(value) => setWorksite(value)}
                      options={dropdownWorksiteList?.map?.((data) => ({
                        _id: data._id,
                        label: data.name,
                      }))}
                      feedback={t("Please select worksite")}
                    />
                  </Col>
                )}
              </Row>
              <Row style={{ marginTop: "10px", marginBottom: "15px" }}>
                <Col>
                  <FileUploaderComponent
                    handleDrop={handleDrop}
                    handleDrag={handleDrag}
                    handlecsvFileUpload={handleCSVFileUpload}
                    csvFileUploadInputRef={csvFileUploadInputRef}
                    noFileCsvErrorMsg={noFileCsvErrorMsg}
                    dragText={dragText}
                    csvFile={csvFile}
                    setcsvFile={setCSVFile}
                    setnoFileCsvErrorMsg={setNoFileCsvErrorMsg}
                  />
                </Col>
              </Row>

              <Row>
                <RowWrapper>
                  <SlideButton
                    buttonName={t("IMPORTANT INSTRUCTIONS")}
                    divContent={<ComponentDataUploadFiles />}
                    iconName="information-tooltip"
                    defaultOpen={false}
                  />
                </RowWrapper>
                <ButtonWrapper>
                  {csvFileUploadingStatus ? (
                    <Button
                      label={<div className="spinner-border" title="Submit" role="status"></div>}
                      showIcon={false}
                      buttonType="save"
                    />
                  ) : (
                    <Button
                      label={t("Submit")}
                      onClick={() => setIsSubmitClicked(true)}
                      showIcon
                      iconClass="save"
                      buttonType="save"
                      disabled={isValidationError}
                    />
                  )}
                </ButtonWrapper>
              </Row>
            </Form>
          )}
        </>
      )}
    </UserBulkUploadWrapper>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  UserBulkUploadTemplates: state.users.UserBulkUploadTemplates,
  UserBulkUploadLoading: state.users.UserBulkUploadLoading,
  dropdownAccountList: state.dropdownFilters.accountList,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  userTypeDetailList: state.userType.userTypeDetailList,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBulkUploadTemplates: (accessType) => dispatch(getUserBulkUploadTemplates(accessType)),
  getUsers: (paginationData, isResetState) => dispatch(getUsers(paginationData, isResetState)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBulkUploadForm));
