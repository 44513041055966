import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";

export default function PlanAlertModal(props) {
  const { modalShow, history, setModalShow } = props;

  return (
    <Modal
      size="lg"
      className="text-style custom-modal mainModelPop"
      show={modalShow}
      onHide={props.handleModalClose}
    >
      {/* modal body starts */}
      <Modal.Body className="text-center modalPopup">
        <Row className="mb-4">
          <Col>
            <span className="h3 checklist_addh4 text-primary mb-2">
              <Trans>Please upgrade your plan in order to export ledger</Trans>
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <button onClick={() => history.push("/app/billing")} className="btn btn-primary btn-lg mb-2">
            <Trans>UPGRADE NOW</Trans>
          </button>
        </Row>
        <Row className="justify-content-center">
          <button onClick={() => setModalShow(false)} className="btn btn-lg mb-3">
            <Trans>LATER</Trans>
          </button>
        </Row>
      </Modal.Body>
      {/* Modal body ends */}
    </Modal>
  );
}
