import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ViewUserTypeOverview from "./ViewUserTypeOverview.component";
import { UserTypeDataDetails } from "./ViewUserTypes.component.styles";
import { getUserTypeList } from "../../../../../redux/userType/action";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import {
  toggleUserTypeStatus,
  resetUserTypeFlags,
  preDeactivatedUserType,
  deactivateUserType,
  getUserTypeDetailById,
} from "../../../../../redux/userType/action";

import ModalComponent from "../../../../../components/ModalPopupComponent/ModalPopup.component";
import UsersDeactivation from "./UserDeactivatedList.component";
const ViewUserTypes = (props) => {
  const {
    userTypeOverview,
    toggleUserTypeStatus,
    resetUserTypeFlags,
    selectedAccessLevel,
    preDeactivatedUserType,
    DeactivatedUsersList,
    deactivateUserType,
    DeactivateUserTypeSuccess,
  } = props;

  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    if (DeactivatedUsersList.length > 0) {
      setShowAlertModal(true);
    }
  }, [DeactivatedUsersList]);

  const handleModalClose = () => {
    setShowAlertModal(false);
    resetUserTypeFlags();
  };

  const { isActive, _id } = userTypeOverview || {};
  const handleConfirmDeactivation = () => {
    deactivateUserType(_id);
    //   const updatedStatus = { isActive: !isActive };
    //    toggleUserTypeStatus(_id, updatedStatus);
    handleModalClose();
  };
  return (
    <UserTypeDataDetails>
      <ViewUserTypeOverview selectedAccessLevel={selectedAccessLevel} />

      <ModalComponent
        show={showAlertModal}
        variant="AlertModal"
        handleClose={handleModalClose}
        iconClass="alertsettings"
        message="Confirm Deactivation"
        paragraph={
          <UsersDeactivation
            isActive={isActive}
            _id={_id}
            DeactivatedUsersList={DeactivatedUsersList}
            preDeactivatedUserType={preDeactivatedUserType}
          />
        }
        button1Text="Cancel"
        button2Text="Confirm"
        onClickA={handleModalClose}
        onClickB={handleConfirmDeactivation}
        typeA="primary"
        typeB="ghost"
      />
    </UserTypeDataDetails>
  );
};

const mapStateToProps = (state) => ({
  userTypeOverview: state.userType.UserTypeDetail,
  UserTypeLoading: state.userType.UserTypeDetailLoading,
  toggleUserTypeStatusSuccess: state.userType.toggleUserTypeStatusSuccess,
  toggleUserTypeStatusError: state.userType.toggleUserTypeStatusError,
  accountId: state.dropdownFilters.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
  DeactivatedUsersList: state.userType.DeactivatedUsersList,
  DeactivateUserTypeSuccess: state.userType.DeactivateUserTypeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  toggleUserTypeStatus: (userId, data) => dispatch(toggleUserTypeStatus(userId, data)),
  resetUserTypeFlags: () => dispatch(resetUserTypeFlags()),
  getUserTypeList: (hideLoading) => dispatch(getUserTypeList(hideLoading)),
  preDeactivatedUserType: (userTypeId) => dispatch(preDeactivatedUserType(userTypeId)),
  deactivateUserType: (userTypeId) => dispatch(deactivateUserType(userTypeId)),
  getUserTypeDetailById: (id) => dispatch(getUserTypeDetailById(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewUserTypes));
