import React from "react";
import AppContext from "../AppContext";

const useAlert = () => {
  const { dispatch } = React.useContext(AppContext);
  const showAlert = (type, message, duration = 2000) => {
    hideAlert();
    dispatch({
      alert: {
        type,
        message,
        duration,
      },
    });
  };
  const hideAlert = () => {
    dispatch({
      alert: null,
    });
  };
  return { showAlert, hideAlert };
};

export default useAlert;
