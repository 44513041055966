import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputSlider from "../../../../../../components/InputSlider/InputSlider";

const SCZSlider = (props) => {
  const {
    inputValue,
    setInputValue,
    value,
    setValue,
    type,
    min,
    max,
    isDepth,
    obstacle,
    setTabKey,
    unitType,
    untouchedValueinMM,
    isSystemSettingsEditable,
    isSliderDisabled,
  } = props;
  const [activeInput, setActiveInput] = useState("");
  const [isFeet, setIsFeet] = useState(true);

  const untouchedValue = isFeet ? parseFloat(untouchedValueinMM / 305).toFixed(1) : untouchedValueinMM;

  useEffect(() => {
    if (unitType?.toLowerCase() === "metric") {
      setIsFeet(false);
    }
  }, [unitType]);

  const changeCameraHeight = (e) => {
    let newInputValue = { ...inputValue };
    newInputValue[obstacle] = {
      ...inputValue?.[obstacle],
      [e.target.name]: e.target.value,
      pos4: isFeet ? 13.12 : 4000,
      pos5: isFeet ? 1.64 : 500,
    };
    setInputValue({ ...newInputValue });
  };

  const changeInput = (e) => {
    setActiveInput("");
    let newValue = e.target.value;
    if (newValue <= min) {
      if (e.target.name === "pos6") {
        e.target.value = untouchedValue;
      }
      newValue = untouchedValue;
    }
    if (!newValue) {
      newValue = untouchedValue;
    }
    if (parseFloat(newValue) > Math.round(max, -1)) {
      newValue = max;
      e.target.value = max;
    }
    newValue = (newValue - min) / ((max - min) / 50);
    if (isNaN(newValue)) {
      return;
    }
    setValue({ ...value, [obstacle]: { ...value?.[obstacle], [e.target.name]: newValue } });
    let newInputValue = { ...inputValue };
    newInputValue[obstacle] = {
      ...inputValue?.[obstacle],
      [e.target.name]: isFeet ? e.target.value : parseInt(e.target.value),
    };
    setInputValue({ ...newInputValue });
  };

  const onBlurSliderInput = (e) => {
    setActiveInput("");
    let actualInputValue = e.target.value;
    let newValue = actualInputValue;
    if (newValue <= min) {
      actualInputValue = untouchedValue;
      newValue = untouchedValue;
    }
    if (!newValue) {
      newValue = untouchedValue;
    }
    if (newValue > max) {
      newValue = max;
      actualInputValue = max;
    }
    newValue = (newValue - min) / ((max - min) / 50);
    if (isNaN(newValue)) {
      return;
    }

    setValue({ ...value, [obstacle]: { ...value?.[obstacle], [e.target.name]: newValue } });
    let newInputValue = { ...inputValue };
    newInputValue[obstacle] = { ...inputValue?.[obstacle], [e.target.name]: actualInputValue };
    setInputValue({ ...newInputValue });
  };

  const changeSliderInput = (e) => {
    let newValue = e.target.value;

    if (!newValue) {
      newValue = 0;
    }
    if (newValue > 50) {
      newValue = max;
    }
    newValue = newValue * ((max - min) / 50) + min;
    if (isNaN(newValue)) {
      return;
    }
    setValue({ ...value, [obstacle]: { ...value?.[obstacle], [e.target.name]: e.target.value } });
    let newInputValue = { ...inputValue };
    newInputValue[obstacle] = { ...inputValue?.[obstacle], [e.target.name]: newValue };
    setInputValue({ ...newInputValue });
  };

  const roundOff = (value) => {
    return Math.round(value * 10) / 10;
  };

  if (type === "pos1") {
    return (
      <div className="postion-1">
        <InputSlider
          isFeet={isFeet}
          onClick={() => setTabKey(2)}
          isDepth={isDepth}
          name={type}
          label="Depth"
          id="scz-rs-input-1"
          value={roundOff(inputValue?.[obstacle]?.pos1)}
          type="number"
          max={50}
          classType="rs-label-input-1"
          onInput={changeInput}
          onBlur={onBlurSliderInput}
          isDisabled={!isSystemSettingsEditable}
        />
        <input
          name={type}
          id="scz-rs-range-line-1"
          className="rs-range horizontal"
          type="range"
          value={value?.[obstacle]?.[type]}
          onInput={changeSliderInput}
          min="0"
          max="50"
          onMouseDown={() => setTabKey(2)}
          disabled={!isSystemSettingsEditable}
        />
        <span id="scz-rs-bullet-1" className={`rs-label ${isDepth ? "rs-line-1" : "rs-line-1-disabled"}`} />
      </div>
    );
  } else if (type === "pos2") {
    return (
      <div className="postion-2">
        <span id="scz-rs-bullet-2" className={`rs-label ${isDepth ? "rs-line-2-disabled" : "rs-line-2"}`} />
        <input
          name={type}
          id="scz-rs-range-line-2"
          className="rs-range vertical-1"
          type="range"
          value={value?.[obstacle]?.[type]}
          onInput={changeSliderInput}
          min="0"
          max="50"
          onMouseDown={() => setTabKey(1)}
          disabled={!isSystemSettingsEditable}
        />
        <InputSlider
          isFeet={isFeet}
          onClick={() => setTabKey(1)}
          isDepth={isDepth}
          name={type}
          label="Left Boundary"
          id="scz-rs-input-2"
          classType="rs-label-input-2"
          value={roundOff(inputValue?.[obstacle]?.pos2)}
          type="number"
          max={50}
          onInput={changeInput}
          onBlur={onBlurSliderInput}
          isDisabled={!isSystemSettingsEditable}
        />
      </div>
    );
  } else if (type === "pos3") {
    return (
      <div className="postion-3">
        <InputSlider
          isFeet={isFeet}
          onClick={() => setTabKey(1)}
          isDepth={isDepth}
          name={type}
          label="Right Boundary"
          id="scz-rs-input-3"
          classType="rs-label-input-3"
          value={roundOff(inputValue?.[obstacle]?.pos3)}
          type="number"
          max={50}
          onInput={changeInput}
          onBlur={onBlurSliderInput}
          isDisabled={!isSystemSettingsEditable}
        />
        <input
          name={type}
          id="scz-rs-range-line-3"
          className="rs-range vertical-2"
          type="range"
          value={value?.[obstacle] && value?.[obstacle]?.[type]}
          onInput={changeSliderInput}
          min="0"
          max="50"
          onMouseDown={() => setTabKey(1)}
          disabled={!isSystemSettingsEditable}
        />
        <span id="scz-rs-bullet-3" className={`rs-label ${isDepth ? "rs-line-3-disabled" : "rs-line-3"}`} />
      </div>
    );
  } else if (type === "pos4") {
    return (
      <div className="postion-4" style={{ zIndex: activeInput === "pos4" ? 1000 : 5 }}>
        <span id="scz-rs-bullet-4" className={`rs-label ${isDepth ? "rs-line-4-disabled" : "rs-line-4"}`} />
        <input
          name={type}
          id="scz-rs-range-line-4"
          className="rs-range vertical-3"
          type="range"
          value={value?.[obstacle] && value?.[obstacle]?.[type]}
          onInput={changeSliderInput}
          min="0"
          max="50"
          onMouseDown={() => setActiveInput(type)}
          disabled={!isSystemSettingsEditable}
        />
        <InputSlider
          isFeet={isFeet}
          isDepth={isDepth}
          name={type}
          label="Upper Boundary"
          id="scz-rs-input-4"
          classType="rs-label-input-4"
          value={roundOff(inputValue?.[obstacle]?.pos4)}
          type="number"
          max={50}
          onClick={() => setActiveInput(type)}
          onInput={changeInput}
          onBlur={(e) => {
            setActiveInput("");
            onBlurSliderInput(e);
          }}
          isDisabled={!isSystemSettingsEditable}
        />
      </div>
    );
  } else if (type === "pos5") {
    return (
      <div className="postion-5" style={{ zIndex: activeInput === "pos5" ? 1000 : 5 }}>
        <span id="scz-rs-bullet-5" className={`rs-label ${isDepth ? "rs-line-5-disabled" : "rs-line-5"}`} />
        <input
          onMouseDown={() => setActiveInput(type)}
          name={type}
          id="scz-rs-range-line-5"
          className="rs-range vertical-4"
          type="range"
          value={value?.[obstacle] && value?.[obstacle]?.[type]}
          onInput={changeSliderInput}
          min="0"
          max="50"
          disabled={!isSystemSettingsEditable}
        />
        <InputSlider
          isFeet={isFeet}
          isDepth={isDepth}
          name={type}
          label="Lower Boundary"
          id="scz-rs-input-5"
          classType="rs-label-input-5"
          value={roundOff(inputValue?.[obstacle]?.pos5)}
          type="number"
          max={50}
          onClick={() => setActiveInput(type)}
          onInput={changeInput}
          onBlur={(e) => {
            setActiveInput("");
            onBlurSliderInput(e);
          }}
          isDisabled={!isSystemSettingsEditable}
        />
      </div>
    );
  } else if (type === "pos6") {
    return (
      <div className="postion-6" style={{ zIndex: activeInput === "pos6" ? 1000 : 11 }}>
        {/* <span id='rs-bullet-6' className={`rs-label ${isDepth ? 'rs-line-6-disabled' : 'rs-line-6'}`} />
            <input
                disabled={isDepth}
                name={type}
                id='rs-range-line-6'
                className='rs-range vertical-5'
                type="range"
                value={value?.[obstacle] && value?.[obstacle]?.[type]}
                onInput={changeSliderInput}
                min="0"
                max="50"
            /> */}
        <InputSlider
          isFeet={isFeet}
          isDepth={isDepth}
          name={type}
          label="Camera Height"
          id="scz-rs-input-6"
          classType="rs-label-input-6"
          value={roundOff(inputValue?.[obstacle]?.pos6)}
          type="number"
          max={50}
          onClick={() => setActiveInput(type)}
          onInput={changeCameraHeight}
          onBlur={changeInput}
          isDisabled={!isSystemSettingsEditable || isSliderDisabled}
        />
      </div>
    );
  } else if (type === "pos7") {
    return (
      <div className="postion-7">
        <InputSlider
          isFeet={isFeet}
          onClick={() => setTabKey(2)}
          isDepth={isDepth}
          name={type}
          label="Depth"
          id="scz-rs-input-7"
          value={roundOff(inputValue?.[obstacle]?.pos1)}
          type="number"
          max={50}
          classType="rs-label-input-7"
          onInput={changeInput}
          onBlur={onBlurSliderInput}
          isDisabled={!isSystemSettingsEditable}
        />
        <input
          name={type}
          id="scz-rs-range-line-7"
          className="rs-range horizontal-2"
          type="range"
          value={value?.[obstacle]?.pos1}
          onInput={changeSliderInput}
          min="0"
          max="50"
          onMouseDown={() => setTabKey(2)}
          disabled={!isSystemSettingsEditable}
        />
        <span id="scz-rs-bullet-7" className={`rs-label ${isDepth ? "rs-line-7" : "rs-line-5-disabled"}`} />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SCZSlider);
