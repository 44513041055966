import { useEffect } from "react";
import { Fragment, useState } from "react";
import MultiplePhotoUpload from "../Input/FileUpload.component";

export default function MeterReading({
  data,
  currentIndex,
  setChecklistResponseForm,
  handleSubmitAnswer,
  showSelection,
  uploadImage,
  deleteImage,
  deleteImageLoading,
  uploadImageLoading,
}) {
  const [answerText, setAnswerText] = useState("");
  const [currentResponse, setCurrentResponse] = useState({});
  const [numericValidation, setNumericValidation] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCurrentResponse(data[currentIndex]);
    setAnswerText(data[currentIndex]?.response);
    setShowError(false);
  }, [currentIndex]);

  const onClickSubmit = () => {
    setShowError(true);
    if (!answerText) {
      setNumericValidation("Numeric response is required");
      return;
    }
    if (!/^\d+$/.test(answerText)) {
      setNumericValidation("Please enter valid number");
      return;
    }
    data[currentIndex] = {
      ...currentResponse,
      response: answerText,
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  useEffect(() => {
    if (!answerText) {
      setNumericValidation("Numeric response is required");
      return;
    }
    if (!/^\d+$/.test(answerText)) {
      setNumericValidation("Please enter valid number");
      return;
    }
    setNumericValidation("");
  }, [answerText]);

  const onClickNA = () => {
    data[currentIndex] = {
      ...currentResponse,
      response: "NA",
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  return (
    <>
      <div className="justify-center flex-wrap w-full  block md:block lg:flex ">
        <div className="flex-1 grid  border-[#F2F2F2] border">
          <div className="heightmax">
            <p className="w-full font-sans p-1  text-xl rounded text-black-text flex justify-left">
              <span className="text-md md:text-xl lg:text-xl "> {currentResponse?.question}</span>
            </p>
          </div>
        </div>

        <div className="flex-1 items-center justify-center relative">
          <div className="w-3/4 m-auto text-left">
            <label className="text-sm font-sans text-color-blackDark">Numeric Response</label>
            <textarea
              id="about"
              name="about"
              type="number"
              rows={4}
              className="focus:border-blue-500 p-2 text-xrlem font-sans block w-full  text-xl border-b text-black-text border-gray-300"
              placeholder="Enter your response here"
              value={answerText ? (answerText == "NA" ? "" : answerText) : ""}
              onChange={(e) => setAnswerText(e.target.value)}
            />
            {showError && numericValidation && (
              <div className="text-color-red flex justify-left font-sans text-md">{numericValidation}</div>
            )}
          </div>

          <div className="flex justify-centr flex-wrap mt-4">
            <div className="w-full flex-1  justify-center">
              <button
                type="button"
                onClick={onClickSubmit}
                className={`${
                  showSelection && answerText === currentResponse?.response && answerText !== "NA"
                    ? "button-green-selected"
                    : "button-green"
                } font-kanit text-xrlem border-none`}
              >
                SUBMIT
              </button>
            </div>
            <div className="w-full flex-1  justify-center self-center">
              <button
                type="button"
                onClick={onClickNA}
                className={`${
                  showSelection && answerText === "NA" ? "button-gray-selected" : "button-gray"
                } font-kanit text-xrlem border-none`}
              >
                NA
              </button>
            </div>
          </div>

          <div className="flex-1">
            <MultiplePhotoUpload
              deleteImageLoading={deleteImageLoading}
              uploadImageLoading={uploadImageLoading}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              currentResponse={currentResponse}
              setCurrentResponse={setCurrentResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
}
