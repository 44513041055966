export const tableHeader = [
  { name: "Status", space: 1, fieldName: "assetStatus" },
  { name: "Asset Name", space: 2, fieldName: "assetName" },
  { name: "Account", space: 2, fieldName: "accountName" },
  { name: "Worksite", space: 2, fieldName: "worksiteName" },
  { name: "SIERA.AI Product", space: 2, fieldName: "productType" },
  { name: "Last Activity At", space: 2, fieldName: "lastActivityAt" },
];

export const tableRowData = [
  { name: "Asset Status", space: 1, fieldName: "assetStatus" },
  { name: "Asset Name", space: 2, fieldName: "assetName" },
  { name: "Account", space: 2, fieldName: "accountName" },
  { name: "Worksite Name", space: 2, fieldName: "worksiteName" },
  { name: "SIERA.AI Product", space: 2, fieldName: "productType" },
  { name: "Last Activity At", space: 3, fieldName: "lastActivityAt", isDate: true },
];

export const tableRowDetailData = [
  { label: "Added On", fieldName: "addOn", isDate: true },
  { label: "Last Activity At", fieldName: "lastActivityAt", isDate: true },
  { label: "Last Inspection Report At", fieldName: "lastInspectionAt", isDate: true },
  { label: "Last Impact Report At", fieldName: "lastImpactReportAt", isDate: true },
  { label: "Last Near-Miss Pedestrian Report", fieldName: "lastNearMissPedestrianReportAt", isDate: true },
  { label: "Last Near-Miss Object Report", fieldName: "lastNearMissObjectReportAt", isDate: true },
  { label: "Last Unauthorized Access Report At", fieldName: "lastUnauthorizedAccessReportAt", isDate: true },
  { label: "Current Hour Meter Reading", fieldName: "currentHourMeterReading", isDate: false },
  { label: "Accumulated Hour Meter Reading", fieldName: "accumulatedHourMeterReading", isDate: false },
  { label: "Last Updated On", fieldName: "lastUpdatedOn", isDate: true },
  { label: "S2 Version", fieldName: "s2Version", isDate: false },
  { label: "S3 Version", fieldName: "s3Version", isDate: false },
];
