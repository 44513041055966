import { assetTypeManagementTypes } from "./assetType.types";

const INTIAL_STATE = {
  //1. assetType list
  assetType: [],
  //2. Selected assetType overview
  assetTypeOverview: null,
  allAssetTypesWithCount: [],
};

export const assetTypeManagementReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    // Case 1: Selecting asset type from the asset type List
    case assetTypeManagementTypes.LIST_ASSET_TYPE:
      return {
        ...state,
        assetType: action.payload,
      };

    // Case 2: Select Default Asset Type Overview from the Asset Type List
    case assetTypeManagementTypes.DEFAULT_ASSET_TYPE_OVERVIEW:
      return {
        ...state,
        assetTypeOverview: state.assetType.find((assetType, index) => index === 0),
      };

    // Case 3: Selected Asset Type Overview
    case assetTypeManagementTypes.ASSET_TYPE_OVERVIEW:
      return {
        ...state,
        assetTypeOverview: state.assetType.find((assetType) => assetType._id === action.payload),
      };

    case assetTypeManagementTypes.GET_ASSET_TYPE_WITH_COUNT_BY_ACCOUNT_SUCCESS:
      const newData = action.payload.isNewAccount
        ? {
            allAssetTypesWithCountForNewAccount: action.payload.data,
          }
        : {
            allAssetTypesWithCount: action.payload.data,
          };

      return {
        ...state,
        ...newData,
      };

    case assetTypeManagementTypes.CREATE_ASSET_TYPE_SUCCESS: {
      return {
        ...state,
        addAssetTypeSuccessFlag: true,
        newlyAddedAssetType: action.payload,
      };
    }

    case assetTypeManagementTypes.CREATE_ASSET_TYPE_FAILURE: {
      return {
        ...state,
        newlyAddedAssetType: null,
        assetTypeCreationFailed: true,
        addAssetTypeSuccessFlag: false,
      };
    }

    case assetTypeManagementTypes.RESET_CREATE_ASSET_TYPE_FLAGS:
      return {
        ...state,
        newlyAddedAssetType: null,
        addAssetTypeSuccessFlag: false,
        assetTypeCreationFailed: false,
      };

    // Returning default state
    default:
      return state;
  }
};
