const deviceInfoTypes = {
  GET_DEVICE_INFORMATION: "GET_DEVICE_INFORMATION",
  GET_DEVICE_INFORMATION_ERROR: "GET_DEVICE_INFORMATION_ERROR",
  GET_DEVICE_INFORMATION_SUCCESS: "GET_DEVICE_INFORMATION_SUCCESS",

  SET_SELECTED_ACCOUNT_ID: "SET_SELECTED_ACCOUNT_ID",
  SET_SELECTED_WORKSITE_ID: "SET_SELECTED_WORKSITE_ID",
  SET_SELECTED_ASSET_TYPE_ID: "SET_SELECTED_ASSET_TYPE_ID",
  SET_SELECTED_SIERA_PRODUCT_ID: "SET_SELECTED_SIERA_PRODUCT_ID",

  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  SET_PAGE_NO: "SET_PAGE_NO",

  SET_DATA_LOADING: "SET_DATA_LOADING",
  RESET_DATA_LOADING: "RESET_DATA_LOADING",
  RESET_DEVICE_DATA: "RESET_DEVICE_DATA",
  RESET_DEVICE_DATA_TO_INITIAL_STATE: "RESET_DEVICE_DATA_TO_INITIAL_STATE",
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",

  SET_GPS_DATA_LOADING: "SET_GPS_DATA_LOADING",
  RESET_GPS_DATA_LOADING: "RESET_GPS_DATA_LOADING",
  GET_GPS_DATA_SUCCESS: "GET_GPS_DATA_SUCCESS",
  SET_GPS_LOCATION_DATES: "SET_GPS_LOCATION_DATES",
};

export default deviceInfoTypes;
