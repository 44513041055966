import { assetStatusTypes } from "./types";
import { initialState } from "./initialState";

export const assetStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case assetStatusTypes.GET_ASSET_STATUS_LOADING:
      return {
        ...state,
        assetStatusLoading: true,
        assetStatusSuccess: false,
        assetStatusError: false,
      };

    case assetStatusTypes.GET_ASSET_STATUS_SUCCESS:
      return {
        ...state,
        assetStatusLoading: false,
        assetStatusSuccess: true,
        assetStatusError: false,
        assetStatusList: action.data,
      };

    case assetStatusTypes.GET_ASSET_STATUS_ERROR:
      return {
        ...state,
        assetStatusLoading: false,
        assetStatusSuccess: false,
        assetStatusError: true,
        assetStatusList: [],
      };

    case assetStatusTypes.SET_ASSET_STATUS_COUNT:
      return {
        ...state,
        assetStatusCount: action.data,
      };

    default:
      return state;
  }
};
