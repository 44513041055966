import moment from "moment";
export const getAllDurations = () => {
  const allDurationsArr = [
    { _id: 1, label: "Today", order: 1 },
    { _id: 2, label: "Yesterday", order: 2 },
    { _id: 3, label: "Last 3 Days", order: 3 },
    { _id: 4, label: "Last 7 Days", order: 4 },
    { _id: 5, label: "Last 15 Days", order: 5 },
    { _id: 6, label: "Last 30 Days", order: 6 },
    { _id: 7, label: "Last 2 Months", order: 7 },
    { _id: 8, label: "Last 3 Months", order: 8 },
    { _id: 9, label: "Last 6 Months", order: 9 },
    { _id: 10, label: "Last 12 Months", order: 10 },
    { _id: 11, label: "This Week", order: 11 },
    { _id: 12, label: "This Month", order: 12 },
    { _id: 13, label: "This Year", order: 13 },
    { _id: 14, label: "All-Time", order: 14 },
    // { _id: 6, label: "Custom" },
  ];
  allDurationsArr.sort((a, b) => (a.order > b.order ? 1 : -1));
  return allDurationsArr;
};
export const getStartEndDateFromDuration = (durationSelected) => {
  if (!durationSelected || !durationSelected._id) {
    return;
  }
  let dateInfoObj = {
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    startTime: moment(Date.now()).subtract({ minute: 15 }).format("HH:mm"),
    endTime: moment(Date.now()).format("HH:mm"),
  };
  //below is currently not being used , we use the redux duration value to
  if (durationSelected._id == 1) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 2) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 3) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(2, "day").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 4) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 5) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(14, "day").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 6) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 7) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 8) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 9) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 10) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 11) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().startOf("week").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 12) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 13) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment().startOf("year").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  } else if (durationSelected._id == 14) {
    dateInfoObj = {
      ...dateInfoObj,
      startDate: moment(0).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: "00:00",
      endTime: "23:59",
    };
  }

  let startDate = new Date(`${dateInfoObj.startDate}T${dateInfoObj.startTime}`);
  let endDate = new Date(`${dateInfoObj.endDate}T${dateInfoObj.endTime}`);

  return {
    startDate: startDate,
    endDate: endDate,
  };
};
