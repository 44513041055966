import { configTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case configTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data,
      };

    case configTypes.UPDATE_ACCOUNT_POPUP:
      return {
        ...state,
        updateAccountPopUp: action.data,
      };

    case configTypes.SET_INPSECTION_PLAN:
      return {
        ...state,
        inspectionPlan: action.data,
      };

    case configTypes.SET_INPSECTION_PLAN_ERROR:
      return {
        ...state,
        inspectionPlanError: "Error in fetching plan",
      };

    case configTypes.GET_IMPACT_THRESHOLD_PLAN_SUCCESS:
      return {
        ...state,
        getImpactThresholdLoading: false,
        getImpactThresholdSuccess: true,
        updateImpactThresholdLoading: false,
        impactThresholdData: action.data,
      };

    case configTypes.GET_IMPACT_THRESHOLD_PLAN_LOADING:
      return {
        ...state,
        getImpactThresholdLoading: true,
        getImpactThresholdSuccess: false,
        updateImpactThresholdLoading: false,
      };

    case configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_LOADING:
      return {
        ...state,
        updateImpactThresholdLoading: true,
        updateImpactThresholdSuccess: false,
        updateImpactThresholdError: false,
      };

    case configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_SUCCESS:
      return {
        ...state,
        updateImpactThresholdLoading: false,
        updateImpactThresholdSuccess: true,
        updateImpactThresholdError: false,
        impactThresholdData: action.data,
      };

    case configTypes.UPDATE_IMPACT_THRESHOLD_PLAN_ERROR:
      return {
        ...state,
        updateImpactThresholdLoading: false,
        updateImpactThresholdSuccess: false,
        updateImpactThresholdError: true,
      };

    case configTypes.SET_EMAIL_ALERT_SETTING_LOADING:
      return {
        ...state,
        emailAlertSettingLoading: true,
        emailAlertSettingSuccess: false,
        emailAlertSettingError: false,
      };

    case configTypes.SET_EMAIL_ALERT_SETTING_SUCCESS:
      return {
        ...state,
        emailAlertSettingLoading: false,
        emailAlertSettingSuccess: true,
        emailAlertSettingError: false,
        emailAlertSetting: action.data,
      };

    case configTypes.SET_EMAIL_ALERT_SETTING_ERROR:
      return {
        ...state,
        emailAlertSettingLoading: false,
        emailAlertSettingSuccess: false,
        emailAlertSettingError: true,
      };

    case configTypes.SET_EMAIL_ALERT_RECIPIENTS_LOADING:
      return {
        ...state,
        emailAlertRecipientsLoading: true,
        emailAlertRecipientsSuccess: false,
        emailAlertRecipientsError: false,
      };

    case configTypes.SET_EMAIL_ALERT_RECIPIENTS_SUCCESS:
      return {
        ...state,
        emailAlertRecipientsLoading: false,
        emailAlertRecipientsSuccess: true,
        emailAlertRecipientsError: false,
        emailAlertRecipients: action.data,
      };

    case configTypes.SET_EMAIL_ALERT_RECIPIENTS_ERROR:
      return {
        ...state,
        emailAlertRecipientsLoading: false,
        emailAlertRecipientsSuccess: false,
        emailAlertRecipientsError: true,
      };

    case configTypes.GET_TIME_TO_COLLISION_LOADING:
      return {
        ...state,
        getTimeToCollisionLoading: true,
        getTimeToCollisionSuccess: false,
        getTimeToCollisionError: false,
        updateTimeToCollisionSuccess: false,
      };

    case configTypes.GET_TIME_TO_COLLISION_SUCCESS:
      return {
        ...state,
        getTimeToCollisionLoading: false,
        getTimeToCollisionSuccess: true,
        getTimeToCollisionError: false,
        updateTimeToCollisionSuccess: false,
        timeToCollision: action.data,
      };

    case configTypes.GET_TIME_TO_COLLISION_ERROR:
      return {
        ...state,
        getTimeToCollisionLoading: false,
        getTimeToCollisionSuccess: false,
        getTimeToCollisionError: true,
        updateTimeToCollisionSuccess: false,
      };

    case configTypes.UPDATE_TIME_TO_COLLISION_LOADING:
      return {
        ...state,
        updateVehicleAlertSettingSuccess: false,
        updateTimeToCollisionLoading: true,
        updateTimeToCollisionSuccess: false,
        updateTimeToCollisionError: false,
      };

    case configTypes.UPDATE_TIME_TO_COLLISION_SUCCESS:
      return {
        ...state,
        updateTimeToCollisionLoading: false,
        updateTimeToCollisionSuccess: true,
        updateTimeToCollisionError: false,
        timeToCollision: action.data,
      };

    case configTypes.UPDATE_TIME_TO_COLLISION_ERROR:
      return {
        ...state,
        updateTimeToCollisionLoading: false,
        updateTimeToCollisionSuccess: false,
        updateTimeToCollisionError: true,
      };

    case configTypes.UPDATE_TIME_TO_COLLISION_RESET_STATE:
      return {
        ...state,
        updateTimeToCollisionLoading: false,
        updateTimeToCollisionSuccess: false,
        updateTimeToCollisionError: false,
      };

    case configTypes.GET_VEHICLE_ALERT_SETTING_LOADING:
      return {
        ...state,
        getVehicleAlertSettingLoading: true,
        getVehicleAlertSettingSuccess: false,
        getVehicleAlertSettingError: false,
        updateVehicleAlertSettingSuccess: false,
      };

    case configTypes.GET_VEHICLE_ALERT_SETTING_SUCCESS:
      return {
        ...state,
        getVehicleAlertSettingLoading: false,
        getVehicleAlertSettingSuccess: true,
        getVehicleAlertSettingError: false,
        updateVehicleAlertSettingSuccess: false,
        vehicleAlertSetting: action.data,
      };

    case configTypes.GET_VEHICLE_ALERT_SETTING_ERROR:
      return {
        ...state,
        getVehicleAlertSettingLoading: false,
        getVehicleAlertSettingSuccess: false,
        getVehicleAlertSettingError: false,
        updateVehicleAlertSettingSuccess: false,
      };

    case configTypes.UPDATE_VEHICLE_ALERT_SETTING_LOADING:
      return {
        ...state,
        updateTimeToCollisionSuccess: false,
        updateVehicleAlertSettingLoading: true,
        updateVehicleAlertSettingSuccess: false,
        updateVehicleAlertSettingError: false,
      };

    case configTypes.UPDATE_VEHICLE_ALERT_SETTING_SUCCESS:
      return {
        ...state,
        updateVehicleAlertSettingLoading: false,
        updateVehicleAlertSettingSuccess: true,
        updateVehicleAlertSettingError: false,
        vehicleAlertSetting: action.data,
      };

    case configTypes.UPDATE_VEHICLE_ALERT_SETTING_ERROR:
      return {
        ...state,
        updateVehicleAlertSettingLoading: false,
        updateVehicleAlertSettingSuccess: false,
        updateVehicleAlertSettingError: false,
      };

    case configTypes.RESET_VEHICLE_ALERT_SETTING:
      return {
        ...state,
        updateVehicleAlertSettingLoading: false,
        updateVehicleAlertSettingSuccess: false,
        updateVehicleAlertSettingError: false,
      };

    case configTypes.GET_WORKSITE_REPORT_SETTING_LOADING:
      return {
        ...state,
        getWorksiteReportSettingsLoading: true,
        getWorksiteReportSettingsSuccess: false,
        getWorksiteReportSettingsError: false,
        updateWorksiteReportSettingsSuccess: false,
      };

    case configTypes.GET_WORKSITE_REPORT_SETTING_SUCCESS:
      return {
        ...state,
        getWorksiteReportSettingsLoading: false,
        getWorksiteReportSettingsSuccess: true,
        getWorksiteReportSettingsError: false,
        updateWorksiteReportSettingsSuccess: false,
        worksiteReportSettings: action.data.reportSettings,
      };

    case configTypes.GET_WORKSITE_REPORT_SETTING_ERROR:
      return {
        ...state,
        getWorksiteReportSettingsLoading: false,
        getWorksiteReportSettingsSuccess: false,
        getWorksiteReportSettingsError: false,
        updateWorksiteReportSettingsSuccess: false,
      };

    case configTypes.UPDATE_WORKSITE_REPORT_SETTING_LOADING:
      return {
        ...state,
        updateWorksiteReportSettingLoading: true,
        updateWorksiteReportSettingSuccess: false,
        updateWorksiteReportSettingError: false,
      };

    case configTypes.UPDATE_WORKSITE_REPORT_SETTING_SUCCESS:
      return {
        ...state,
        updateWorksiteReportSettingLoading: false,
        updateWorksiteReportSettingSuccess: true,
        updateWorksiteReportSettingError: false,
        worksiteReportSettings: action.data,
      };

    case configTypes.UPDATE_WORKSITE_REPORT_SETTING_ERROR:
      return {
        ...state,
        updateWorksiteReportSettingLoading: false,
        updateWorksiteReportSettingSuccess: false,
        updateWorksiteReportSettingError: false,
      };

    case configTypes.RESET_WORKSITE_REPORT_SETTING:
      return {
        ...state,
        updateWorksiteReportSettingLoading: false,
        updateWorksiteReportSettingSuccess: false,
        updateWorksiteReportSettingError: false,
      };

    case configTypes.UPDATE_S3_CAMERA_SETTING_LOADING:
      return {
        ...state,
        updateS3CameraSettingLoading: true,
        updateS3CameraSettingSuccess: false,
        updateS3CameraSettingError: false,
      };

    case configTypes.UPDATE_S3_CAMERA_SETTING_SUCCESS:
      return {
        ...state,
        updateS3CameraSettingLoading: false,
        updateS3CameraSettingSuccess: true,
        updateS3CameraSettingError: false,
        s3CameraSetting: action.data,
        getS3CameraSettingLoading: false,
        resetS3CameraSettingLoading: false,
      };

    case configTypes.UPDATE_S3_CAMERA_SETTING_ERROR:
      return {
        ...state,
        updateS3CameraSettingLoading: false,
        updateS3CameraSettingSuccess: false,
        updateS3CameraSettingError: true,
        getS3CameraSettingLoading: false,
      };
    case configTypes.RESET_S3_CAMERA_SETTING_LOADING:
      return {
        ...state,
        resetS3CameraSettingLoading: true,
        resetS3CameraSettingSuccess: false,
        resetS3CameraSettingError: false,
      };

    case configTypes.RESET_S3_CAMERA_SETTING_SUCCESS:
      return {
        ...state,
        resetS3CameraSettingLoading: false,
        resetS3CameraSettingSuccess: true,
        resetS3CameraSettingError: false,
        getS3CameraSettingLoading: false,
      };

    case configTypes.RESET_S3_CAMERA_SETTING_ERROR:
      return {
        ...state,
        resetS3CameraSettingLoading: false,
        resetS3CameraSettingSuccess: false,
        resetS3CameraSettingError: false,
        updateS3CameraSettingError: false,
      };
    case configTypes.GET_S3_CAMERA_SETTING_LOADING:
      return {
        ...state,
        getS3CameraSettingLoading: true,
        getS3CameraSettingSuccess: false,
        getS3CameraSettingError: false,
      };

    case configTypes.GET_S3_CAMERA_SETTING_SUCCESS:
      return {
        ...state,
        getS3CameraSettingLoading: false,
        getS3CameraSettingSuccess: true,
        getS3CameraSettingError: false,
        s3CameraSetting: action?.data,
      };

    case configTypes.GET_S3_CAMERA_SETTING_ERROR:
      return {
        ...state,
        getS3CameraSettingLoading: false,
        getS3CameraSettingSuccess: false,
        getS3CameraSettingError: false,
        s3CameraSetting: null,
      };

    case configTypes.DOWNLOAD_FILE_LOADING:
      return {
        ...state,
        fileDownloadLoading: true,
      };

    case configTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileDownloadLoading: false,
      };

    case configTypes.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        fileDownloadLoading: false,
      };
    case configTypes.GET_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS:
      return {
        ...state,
        getWorksiteAutoLogoutSettingsLoading: false,
        getWorksiteAutoLogoutSettingsSuccess: false,
        getWorksiteAutoLogoutSettingsError: false,
        updateWorksiteAutoLogoutSettingsSuccess: false,
        worksiteAutoLogoutSettings: action.data,
        updateWorksiteAutoLogoutSettingLoading: false,
      };
    case configTypes.GET_WORKSITE_AUTO_LOGOUT_SETTING_LOADING:
      return {
        ...state,
        getWorksiteAutoLogoutSettingsLoading: true,
        getWorksiteAutoLogoutSettingsSuccess: false,
        getWorksiteAutoLogoutSettingsError: false,
        updateWorksiteAutoLogoutSettingsSuccess: false,
      };

    case configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_LOADING:
      return {
        ...state,
        updateWorksiteAutoLogoutSettingLoading: true,
        updateWorksiteAutoLogoutSettingSuccess: false,
        updateWorksiteAutoLogoutSettingError: false,
      };

    case configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS:
      return {
        ...state,
        updateWorksiteAutoLogoutSettingLoading: false,
        updateWorksiteAutoLogoutSettingSuccess: true,
        updateWorksiteAutoLogoutSettingError: false,
        worksiteAutoLogoutSettings: action.data,
      };

    case configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_ERROR:
      return {
        ...state,
        updateWorksiteAutoLogoutSettingLoading: false,
        updateWorksiteAutoLogoutSettingSuccess: false,
        updateWorksiteAutoLogoutSettingError: false,
      };
    case configTypes.RESET_WORKSITE_AUTO_LOGOUT_SETTING:
      return {
        ...state,
        updateWorksiteAutoLogoutSettingLoading: false,
        updateWorksiteAutoLogoutSettingSuccess: false,
        updateWorksiteAutoLogoutSettingError: false,
      };

    case configTypes.GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS:
      return {
        ...state,
        accessControlSettings: action.data,
      };

    case configTypes.ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_LOADING:
      return {
        ...state,
        accessControlSettingsLoading: true,
      };

    case configTypes.UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS:
      return {
        ...state,
        accessControlSettingsLoading: false,
        accessControlSettings: action.data,
      };

    case configTypes.UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR:
      return {
        ...state,
        accessControlSettingsLoading: false,
      };
    // Returning default state

    case configTypes.BULK_SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        bulkSettingsUpdateSuccess: true,
        ...action.data,
      };

    case configTypes.BULK_UPDATE_CAMERA_SETTINGS_ERROR:
      return {
        bulkSettingsUpdateSuccess: false,
        bulkSettingsUpdateError: true,
      };

    case configTypes.BULK_UPDATE_CAMERA_SETTINGS_SUCCESS:
      return {
        ...state,
        updateS3CameraSettingLoading: false,
        updateS3CameraSettingSuccess: false,
        updateS3CameraSettingError: false,
        getS3CameraSettingLoading: false,
        resetS3CameraSettingLoading: false,
        bulkUpdateS3CameraSettingSuccess: true,
        s3CameraSettingUpdateStatusData: action.data,
      };

    case configTypes.BULK_UPDATE_CAMERA_SETTINGS_RESET_FLAGS:
      return {
        ...state,
        updateS3CameraSettingLoading: false,
        updateS3CameraSettingSuccess: false,
        updateS3CameraSettingError: false,
        getS3CameraSettingLoading: false,
        resetS3CameraSettingLoading: false,
        bulkUpdateS3CameraSettingSuccess: false,
        notificationBulkUpdateSuccess: false,
        s3CameraSettingUpdateStatusData: null,
      };

    default:
      return state;
  }
};
