import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  AssetPermissionWrapper,
  Header,
  Row,
  Column,
  RowsContainer,
  AllowDisallowWrapper,
  AllowDisallow,
} from "./AssetPermissionForm.styles";
import SwitchComponent from "../../../../components/Switchbox/SwitchComponent";

const AssetPermissionForm = (props) => {
  const { formData, setFormData, handleNext, allAssetTypesWithCount } = props;
  const [unauthorizedAssetTypes, setUnauthorizedAssetTypes] = useState([]);
  const [allAssetTypeIds, setAllAssetTypeIds] = useState(
    allAssetTypesWithCount.map((assetType) => assetType._id)
  );

  useEffect(() => {
    setUnauthorizedAssetTypes(formData.unauthorizedAssetTypes || []);
  }, [formData]);

  useEffect(() => {
    setAllAssetTypeIds(allAssetTypesWithCount.map((assetType) => assetType._id));
  }, [allAssetTypesWithCount]);

  const toggleAllow = (id) => {
    const unauthorizedAssetTypesSet = new Set(unauthorizedAssetTypes);
    if (unauthorizedAssetTypesSet.has(id)) {
      unauthorizedAssetTypesSet.delete(id);
    } else {
      unauthorizedAssetTypesSet.add(id);
    }
    setFormData({
      ...formData,
      unauthorizedAssetTypes: Array.from(unauthorizedAssetTypesSet),
    });
  };

  const toggleAll = (val) => {
    if (val) {
      setFormData({
        ...formData,
        unauthorizedAssetTypes: [],
      });
    } else {
      setFormData({
        ...formData,
        unauthorizedAssetTypes: allAssetTypeIds,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext(event);
  };

  return (
    <Form id="userAddForm1" onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
      <AssetPermissionWrapper>
        <Header>Asset Permission</Header>
        <>
          <Row isHeader={true}>
            <Column isHeader={true} align={"left"}>
              Your Asset Type
            </Column>
            <Column isHeader={true} align={"center"}>
              Number of Assets
            </Column>
            <Column
              isHeader={true}
              align={"right"}
              style={{ textAlign: "right", flexDirection: "column", display: "block" }}
            >
              Access Permission
              <AllowDisallowWrapper>
                <AllowDisallow
                  onClick={() => formData?.unauthorizedAssetTypes?.length !== 0 && toggleAll(true)}
                  disabled={
                    !formData.unauthorizedAssetTypes || formData?.unauthorizedAssetTypes?.length === 0
                  }
                >
                  Allow All
                </AllowDisallow>
                |
                <AllowDisallow
                  onClick={() =>
                    formData?.unauthorizedAssetTypes?.length !== allAssetTypeIds.length && toggleAll(false)
                  }
                  disabled={formData?.unauthorizedAssetTypes?.length === allAssetTypeIds.length}
                >
                  Disallow All
                </AllowDisallow>
              </AllowDisallowWrapper>
            </Column>
          </Row>
          <RowsContainer>
            {allAssetTypesWithCount &&
              allAssetTypesWithCount.map((data, index) => {
                return (
                  <Row key={data.assetType}>
                    <Column align={"left"}>{data.assetTypeName}</Column>
                    <Column align={"center"}>{data.assetsCount}</Column>
                    <Column align={"right"}>
                      <SwitchComponent
                        checked={!unauthorizedAssetTypes.includes(data._id)}
                        onChange={() => toggleAllow(data._id)}
                      />
                    </Column>
                  </Row>
                );
              })}
          </RowsContainer>
        </>
      </AssetPermissionWrapper>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  allAssetTypesWithCount: state.assetType.allAssetTypesWithCountForNewAccount || [],
});

export default connect(mapStateToProps)(AssetPermissionForm);
