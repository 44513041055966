import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonWrapper, ActionBar, DropdownWrapper } from "./NotificationSetting.component.styles";
import DropdownFilter from "../../../../components/DropdownFilter.component";
import ActionButtons from "../../common/ActionButtons";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";

const defaultSettingsOptions = [
  {
    label: "All Worksites",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "worksites",
  },
  {
    label: "Selected Worksites",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "name",
    radioGroup: "worksites",
    dropdown: {
      placeholder: "Select a Worksite",
    },
  },
  {
    label: "All Asset Types",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assetTypes",
  },
  {
    label: "Selected Asset Types",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "AssetTypeName",
    radioGroup: "assetTypes",
    dropdown: {
      placeholder: "Select an Asset Type",
    },
  },
  {
    label: "All Assets",
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assets",
  },
  {
    label: "Selected Assets",
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "assetName",
    radioGroup: "assets",
    dropdown: {
      placeholder: "Select an Asset",
    },
  },
];

const ActionsBar = (props) => {
  const {
    dropdownWorksiteList,
    setDropdownWorksiteId,
    setWorksiteId,
    updatableChanges,
    handleCancel,
    isLoading,
    worksiteId,
    handleUpdate,
    showWorksiteSelection,
    applyToAllObj,
    setApplyToAllObj,
    checkApplyToAll,
    setCheckApplyToAll,
    handleReset,
    showAppyToAll,
    settingsOptions,
    clearSelection,
    setClearSelection,
  } = props;
  const { t } = useTranslation();

  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [applyAllSelectedTypes, setApplyAllSelectedTypes] = useState([]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = (selectedOptions, selectedItems) => {
    let validData = true;
    Object.keys(selectedOptions).forEach((key) => {
      if (
        Object.keys(selectedOptions[key]).length > 0 &&
        !(selectedOptions[key].selected || selectedOptions[key].all)
      ) {
        validData = false;
      }
    });

    setCheckApplyToAll(validData);
    toggleApplyToAllModal();
  };

  const cancelHandler = () => {
    setApplyToAllObj({});
    handleCancel();
    setCheckApplyToAll(false);
  };

  return (
    <ActionBar>
      <DropdownWrapper>
        {showWorksiteSelection && (
          <DropdownFilter
            filter={{
              type: "Worksite",
              placeholder: "Worksite",
              name: "worksiteName",
              key: "name",
              data: dropdownWorksiteList,
            }}
            defaultValue={dropdownWorksiteList[0]}
            useAsDropdown={true}
            label={t("filter")}
            value={worksiteId}
            handleFilter={(value) => {
              setDropdownWorksiteId(value);
              setWorksiteId(value);
            }}
          />
        )}
      </DropdownWrapper>

      <ButtonWrapper>
        <ActionButtons
          showAppyToAll={showAppyToAll}
          updatableChanges={updatableChanges}
          handleUpdate={handleUpdate}
          handleCancel={cancelHandler}
          isLoading={isLoading}
          handleApplyToAll={toggleApplyToAllModal}
          applyToAll={checkApplyToAll || applyAllSelectedTypes.length > 0}
          showResetButton={true}
          handleReset={handleReset}
        />

        <ApplyToAllModal
          showModal={showApplyToAllModal}
          setShowModal={toggleApplyToAllModal}
          applyChanges={saveHandler}
          applyAllSelectedAssetTypes={applyAllSelectedTypes}
          setApplyAllSelectedAssetTypes={setApplyAllSelectedTypes}
          setCheckApplyToAll={setCheckApplyToAll}
          settingsOptions={settingsOptions}
          modalHeader={"Notification Settings"}
          applyToAllObj={applyToAllObj}
          setApplyToAllObj={setApplyToAllObj}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      </ButtonWrapper>
    </ActionBar>
  );
};

ActionsBar.defaultProps = {
  settingsOptions: defaultSettingsOptions,
  setDropdownWorksiteId: () => {},
};

export default ActionsBar;
