/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Store with root Reducer
 */

import { createStore, applyMiddleware } from "redux";

import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./root-reducer";
import { persistStore } from "redux-persist";

// Middlewares that the store is expecting from redux is generally an array
const middlewares = [thunk, logger];

// Consuming logger middleware only during development phase
if (import.meta.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

// Applying Root Reducer and Middlewares to the store
const store = createStore(rootReducer, applyMiddleware(...middlewares));

// Peristor for Session Storage
const persistor = persistStore(store);

export { store, persistor };
