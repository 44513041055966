export const configTypes = {
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  UPDATE_ACCOUNT_POPUP: "UPDATE_ACCOUNT_POPUP",
  SET_INPSECTION_PLAN: "SET_INPSECTION_PLAN",
  SET_INPSECTION_PLAN_ERROR: "SET_INPSECTION_PLAN_ERROR",
  GET_IMPACT_THRESHOLD_PLAN_LOADING: "GET_IMPACT_THRESHOLD_PLAN_LOADING",
  GET_IMPACT_THRESHOLD_PLAN_SUCCESS: "GET_IMPACT_THRESHOLD_PLAN_SUCCESS",
  UPDATE_IMPACT_THRESHOLD_PLAN_LOADING: "UPDATE_IMPACT_THRESHOLD_PLAN_LOADING",
  UPDATE_IMPACT_THRESHOLD_PLAN_SUCCESS: "UPDATE_IMPACT_THRESHOLD_PLAN_SUCCESS",
  UPDATE_IMPACT_THRESHOLD_PLAN_ERROR: "UPDATE_IMPACT_THRESHOLD_PLAN_ERROR",
  GET_EMAIL_ALERT_SETTING: "GET_EMAIL_ALERT_SETTING",
  SET_EMAIL_ALERT_SETTING_LOADING: "SET_EMAIL_ALERT_SETTING_LOADING",
  SET_EMAIL_ALERT_SETTING_SUCCESS: "SET_EMAIL_ALERT_SETTING_SUCCESS",
  SET_EMAIL_ALERT_SETTING_ERROR: "SET_EMAIL_ALERT_SETTING_ERROR",
  GET_EMAIL_ALERT_RECIPIENTS: "GET_EMAIL_ALERT_RECIPIENTS",
  SET_EMAIL_ALERT_RECIPIENTS_LOADING: "SET_EMAIL_ALERT_RECIPIENTS_LOADING",
  SET_EMAIL_ALERT_RECIPIENTS_SUCCESS: "SET_EMAIL_ALERT_RECIPIENTS_SUCCESS",
  SET_EMAIL_ALERT_RECIPIENTS_ERROR: "SET_EMAIL_ALERT_RECIPIENTS_ERROR",
  GET_TIME_TO_COLLISION_LOADING: "GET_TIME_TO_COLLISION_LOADING",
  GET_TIME_TO_COLLISION_SUCCESS: "GET_TIME_TO_COLLISION_SUCCESS",
  GET_TIME_TO_COLLISION_ERROR: "GET_TIME_TO_COLLISION_ERROR",
  UPDATE_TIME_TO_COLLISION_LOADING: "UPDATE_TIME_TO_COLLISION_LOADING",
  UPDATE_TIME_TO_COLLISION_SUCCESS: "UPDATE_TIME_TO_COLLISION_SUCCESS",
  UPDATE_TIME_TO_COLLISION_ERROR: "UPDATE_TIME_TO_COLLISION_ERROR",
  GET_VEHICLE_ALERT_SETTING_LOADING: "GET_VEHICLE_ALERT_SETTING_LOADING",
  GET_VEHICLE_ALERT_SETTING_SUCCESS: "GET_VEHICLE_ALERT_SETTING_SUCCESS",
  GET_VEHICLE_ALERT_SETTING_ERROR: "GET_VEHICLE_ALERT_SETTING_ERROR",
  UPDATE_VEHICLE_ALERT_SETTING_LOADING: "UPDATE_VEHICLE_ALERT_SETTING_LOADING",
  UPDATE_VEHICLE_ALERT_SETTING_SUCCESS: "UPDATE_VEHICLE_ALERT_SETTING_SUCCESS",
  UPDATE_VEHICLE_ALERT_SETTING_ERROR: "UPDATE_VEHICLE_ALERT_SETTING_ERROR",
  RESET_VEHICLE_ALERT_SETTING: "RESET_VEHICLE_ALERT_SETTING",
  UPDATE_TIME_TO_COLLISION_RESET_STATE: "UPDATE_TIME_TO_COLLISION_RESET_STATE",
  GET_WORKSITE_REPORT_SETTING_LOADING: "GET_WORKSITE_REPORT_SETTING_LOADING",
  GET_WORKSITE_REPORT_SETTING_SUCCESS: "GET_WORKSITE_REPORT_SETTING_SUCCESS",
  GET_WORKSITE_REPORT_SETTING_ERROR: "GET_WORKSITE_REPORT_SETTING_ERROR",
  UPDATE_WORKSITE_REPORT_SETTING_LOADING: "UPDATE_WORKSITE_REPORT_SETTING_LOADING",
  UPDATE_WORKSITE_REPORT_SETTING_SUCCESS: "UPDATE_WORKSITE_REPORT_SETTING_SUCCESS",
  UPDATE_WORKSITE_REPORT_SETTING_ERROR: "UPDATE_WORKSITE_REPORT_SETTING_ERROR",
  RESET_WORKSITE_REPORT_SETTING: "RESET_WORKSITE_REPORT_SETTING",
  DOWNLOAD_FILE_LOADING: "DOWNLOAD_FILE_LOADING",
  DOWNLOAD_FILE_SUCCESS: "DOWNLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_ERROR: "DOWNLOAD_FILE_ERROR",
  UPDATE_S3_CAMERA_SETTING_LOADING: "UPDATE_S3_CAMERA_SETTING_LOADING",
  UPDATE_S3_CAMERA_SETTING_SUCCESS: "UPDATE_S3_CAMERA_SETTING_SUCCESS",
  UPDATE_S3_CAMERA_SETTING_ERROR: "UPDATE_S3_CAMERA_SETTING_ERROR",
  RESET_S3_CAMERA_SETTING_LOADING: "RESET_S3_CAMERA_SETTING_LOADING",
  RESET_S3_CAMERA_SETTING_SUCCESS: "RESET_S3_CAMERA_SETTING_SUCCESS",
  RESET_S3_CAMERA_SETTING_ERROR: "RESET_S3_CAMERA_SETTING_ERROR",
  GET_S3_CAMERA_SETTING_LOADING: "GET_S3_CAMERA_SETTING_LOADING",
  GET_S3_CAMERA_SETTING_SUCCESS: "GET_S3_CAMERA_SETTING_SUCCESS",
  GET_S3_CAMERA_SETTING_ERROR: "GET_S3_CAMERA_SETTING_ERROR",

  GET_WORKSITE_AUTO_LOGOUT_SETTING_LOADING: "GET_WORKSITE_AUTO_LOGOUT_SETTING_LOADING",
  GET_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS: "GET_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS",
  GET_WORKSITE_AUTO_LOGOUT_SETTING_ERROR: "GET_WORKSITE_AUTO_LOGOUT_SETTING_ERROR",
  UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_LOADING: "UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_LOADING",
  UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS: "UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS",
  UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_ERROR: "UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_ERROR",
  RESET_WORKSITE_AUTO_LOGOUT_SETTING: "RESET_WORKSITE_AUTO_LOGOUT_SETTING",

  SET_INTRO_FLAG: "SET_INTRO_FLAG",
  GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS: "GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS",
  GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR: "GET_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR",
  UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR: "UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_ERROR",
  UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS: "UPDATE_ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_SUCCESS",
  ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_LOADING: "ACCESS_CONTROL_SETTINGS_BY_ASSET_ID_LOADING",
  BULK_SETTINGS_UPDATE_SUCCESS: "BULK_SETTINGS_UPDATE_SUCCESS",

  BULK_UPDATE_CAMERA_SETTINGS_ERROR: "BULK_UPDATE_CAMERA_SETTINGS_ERROR",
  BULK_UPDATE_CAMERA_SETTINGS_SUCCESS: "BULK_UPDATE_CAMERA_SETTINGS_SUCCESS",
  BULK_UPDATE_CAMERA_SETTINGS_RESET_FLAGS: "BULK_UPDATE_CAMERA_SETTINGS_RESET_FLAGS",

  BULK_UPDATE_OPERATOR_CERTIFICATE_LOADING: "BULK_UPDATE_OPERATOR_CERTIFICATE_LOADING",
  BULK_UPDATE_OPERATOR_CERTIFICATE_SUCCESS: "BULK_UPDATE_OPERATOR_CERTIFICATE_SUCCESS",
  BULK_UPDATE_OPERATOR_CERTIFICATE_ERROR: "BULK_UPDATE_OPERATOR_CERTIFICATE_ERROR",

  BULK_UPDATE_WORKSITE_ALERT_SETTINGS_LOADING: "BULK_UPDATE_WORKSITE_ALERT_SETTINGS_LOADING",
  BULK_UPDATE_WORKSITE_ALERT_SETTINGS_SUCCESS: "BULK_UPDATE_WORKSITE_ALERT_SETTINGS_SUCCESS",
  BULK_UPDATE_WORKSITE_ALERT_SETTINGS_ERROR: "BULK_UPDATE_WORKSITE_ALERT_SETTINGS_ERROR",
};
