import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import "./CameraSetting.css";
import {
  S3SettingUpdateModal,
  HeadingDivStyle,
  ContainerDivStyle,
  ContainerHead,
  S3SettingTable,
  RowDiv,
  Column,
  RowsContainer,
  ButtonContainer,
} from "./S3SettingsUpdateResult.styles";
import Button from "../../../../components/Button/Button.component";
import { connect } from "react-redux";

const S3SettingsUpdateResult = ({
  s3CameraSettingUpdateStatusData,
  bulkUpdateS3CameraSettingSuccess,
  onContinue,
  assetList,
}) => {
  const { t } = useTranslation();
  const getAssetDetails = (assetid) => {
    const asset = assetList?.find((asset) => {
      return asset._id === assetid;
    });
    return asset;
  };

  return (
    <Modal
      size="lg"
      className="custom-modal discard-modal"
      show={bulkUpdateS3CameraSettingSuccess}
      onHide={() => onContinue()}
      backdrop="static"
    >
      <S3SettingUpdateModal>
        <Modal.Body>
          <HeadingDivStyle>S3 Settings | Update Summary</HeadingDivStyle>
          <ContainerDivStyle>
            <ContainerHead>
              Updated({s3CameraSettingUpdateStatusData?.updatedAssets?.length || 0})
            </ContainerHead>
            <S3SettingTable>
              <RowDiv isHeader={true}>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Asset</Trans>
                </Column>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Asset Type</Trans>
                </Column>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Worksite</Trans>
                </Column>
              </RowDiv>

              <RowsContainer>
                {s3CameraSettingUpdateStatusData?.updatedAssets?.map((asset) => {
                  //const asset = getAssetDetails(data);
                  return asset ? (
                    <RowDiv>
                      <Column align={"left"} textleft={"left"}>
                        {asset.assetName}
                      </Column>
                      <Column align={"left"} textleft={"left"}>
                        {asset.assetTypeName}
                      </Column>
                      <Column align={"left"} textleft={"left"}>
                        {asset.worksiteName}
                      </Column>
                    </RowDiv>
                  ) : null;
                })}
              </RowsContainer>
            </S3SettingTable>
          </ContainerDivStyle>
          <ContainerDivStyle>
            <ContainerHead>
              Not Updated({s3CameraSettingUpdateStatusData?.notUpdatedAssets?.length || 0})
            </ContainerHead>
            <S3SettingTable>
              <RowDiv isHeader={true}>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Asset</Trans>
                </Column>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Asset Type</Trans>
                </Column>
                <Column textleft={"left"} isHeader={true}>
                  <Trans>Worksite</Trans>
                </Column>
              </RowDiv>
              <RowsContainer>
                {s3CameraSettingUpdateStatusData?.notUpdatedAssets?.map((asset) => {
                  //const asset = getAssetDetails(data);
                  return asset ? (
                    <RowDiv>
                      <Column align={"left"} textleft={"left"}>
                        {asset.assetName}
                      </Column>
                      <Column align={"left"} textleft={"left"}>
                        {asset.assetTypeName}
                      </Column>
                      <Column align={"left"} textleft={"left"}>
                        {asset.worksiteName}
                      </Column>
                    </RowDiv>
                  ) : null;
                })}
              </RowsContainer>
            </S3SettingTable>
            <ButtonContainer>
              <Button
                style={{ width: "160px" }}
                label={t("Continue")}
                onClick={() => onContinue()}
                showIcon={false}
                buttonType="primary"
              />
            </ButtonContainer>
          </ContainerDivStyle>
        </Modal.Body>
      </S3SettingUpdateModal>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  assetList: state.assets.allAssetsList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(S3SettingsUpdateResult);
