import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { apiCall } from "../../../utils/apiCall";
import { updateUser } from "../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import useAlert from "../../../utils/useAlert";
import toasterConstant from "../../../constant/messages/toaster.json";
import {
  MFAWrapper,
  SwitchWrapper,
  OptionsWrapper,
  SettingsWrapper,
  SpanTop,
  MfaAuthDivContainer,
  MfaAuthContainer,
  MfaAuthDiv,
  SpanBottom,
  Divider,
  LabelContainer,
  LeftLabel,
  RightInput,
  DropWrapper,
  ButtonWrapper,
  ButtonDiv,
  DivScroller,
  SwitchOption,
  SwitchWrapperDiv,
  LoaderOverlay,
} from "./MFAAuth.styles";
import Button from "../../../components/Button/Button.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import RadioButton from "../../../components/RadioButton/Radio.component";
import SettingsHeader from "../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import SimpleDropdownFilter from "../../../components/SimpleDropdownFilter.component";
import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";

const { account_profile_update, account_update_error } = toasterConstant;

function MFAAuth(props) {
  const { account, updateUser, updateActiveNavItem } = props;
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [form, setForm] = useState({
    companyName: "",
    ownerName: "",
    address: "",
    enterpriseUrl: "",
    timeZone: "",
    unitType: "",
    logo: "",
  });

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (account) {
      setForm({
        companyName: account?.companyName,
        ownerName: account?.userData?.fullName,
        address: account?.address,
        enterpriseUrl: account?.enterpriseUrl,
        timeZone: account?.timeZone,
        ...account,
      });
    }
  }, [account, editMode]);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = new FormData();
    formData.append(
      "accountData",
      JSON.stringify({
        companyName: form.companyName,
        ownerName: form.ownerName,
        address: form.address,
        enterpriseUrl: form.enterpriseUrl,
        timeZone: form.timeZone,
        unitType: form.unitType,
        mfaData: form.mfaData,
      })
    );
    if (selectedFile) {
      formData.append("logo", selectedFile);
    }
    apiCall(`/account/${account.accountId}`, {
      method: "PUT",
      data: formData,
      type: "file",
    })
      .then((data) => {
        localStorage.setItem("unitType", data?.data?.data?.unitType);
        if (data?.data) {
          updateUser({
            ...data?.data?.data,
          });
        }
        showAlert("success", t(account_profile_update));
        setIsLoading(false);
        setEditMode(false);
      })
      .catch((err) => {
        showAlert("error", t(account_update_error));
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { id, name, value, checked } = e.target;
    if (name === "status" || name === "isMandatory") {
      const value = name === "status" ? checked : id === "required";
      setForm((prevState) => ({
        ...prevState,
        mfaData: {
          ...(prevState?.mfaData
            ? prevState.mfaData
            : {
                status: false,
                type: "EMAIL",
                isMandatory: false,
              }),
          [name]: !!value,
        },
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const [isLoadingLoader, setIsLoadingLoader] = useState(false);

  useEffect(() => {
    setIsLoadingLoader(true);
    apiCall(`/account/${account.accountId}`)
      .then((resp) => {
        //console.log(resp);
        updateUser({
          ...resp.data.data,
        });
      })
      .catch((err) => {
        // dispatch(getUsersError(err));
        console.log(err);
      })
      .finally(() => {
        setIsLoadingLoader(false);
      });

    updateActiveNavItem("accountProfile");
  }, [account.accountId, updateActiveNavItem, updateUser]);

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("MFA_AUTH_HEADER")} />
      <MfaAuthDivContainer>
        <Form validated={true} onSubmit={(e) => e.preventDefault()} className="no-flex">
          <MfaAuthContainer>
            <DivScroller>
              {isLoadingLoader ? (
                <LoaderOverlay>
                  <SieraLoader OnlyLoaderIcon size="small" />
                </LoaderOverlay>
              ) : (
                <MfaAuthDiv>
                  <MFAWrapper>
                    <SwitchOption>
                      <SwitchWrapper>
                        <LabelContainer>
                          <LeftLabel>
                            <SpanTop>
                              <Trans>Multi-Factor Authentication</Trans>
                            </SpanTop>
                            <SpanBottom>
                              <Trans>
                                Enable this option to improve the security of your SIERA.AI dashboard
                              </Trans>
                            </SpanBottom>
                          </LeftLabel>
                        </LabelContainer>
                        <RightInput>
                          <SwitchComponent
                            onChange={handleInputChange}
                            checked={form?.mfaData?.status}
                            disabled={!editMode}
                            name="status"
                          />
                        </RightInput>
                      </SwitchWrapper>
                      {form?.mfaData?.status && (
                        <OptionsWrapper>
                          <RadioButton
                            onClick={handleInputChange}
                            checked={form?.mfaData?.isMandatory}
                            label={t("Required")}
                            name="isMandatory"
                            size="small"
                            id="required"
                            disabled={!editMode}
                          />
                          <RadioButton
                            onClick={handleInputChange}
                            checked={!form?.mfaData?.isMandatory}
                            label={t("Optional")}
                            name="isMandatory"
                            size="small"
                            id="optional"
                            disabled={!editMode}
                          />
                        </OptionsWrapper>
                      )}
                    </SwitchOption>
                    {form?.mfaData?.status && (
                      <>
                        <Divider />
                        <SwitchOption>
                          <SwitchWrapperDiv>
                            <LabelContainer>
                              <LeftLabel>
                                <SpanTop>
                                  <Trans>Default MFA Method</Trans>
                                </SpanTop>
                                <SpanBottom>
                                  <Trans>Choose how you want to implement MFA</Trans>
                                </SpanBottom>
                              </LeftLabel>
                            </LabelContainer>
                            <DropWrapper>
                              <SimpleDropdownFilter
                                useAsDropdown={true}
                                options={[
                                  {
                                    _id: 1,
                                    label: t("Email"),
                                  },
                                ]}
                                disabled={true}
                              />
                            </DropWrapper>
                          </SwitchWrapperDiv>
                        </SwitchOption>
                      </>
                    )}
                  </MFAWrapper>
                </MfaAuthDiv>
              )}
            </DivScroller>
            {!editMode ? (
              <ButtonWrapper>
                <ButtonDiv>
                  <Button
                    label={t("Edit")}
                    iconClass="edit"
                    onClick={() => setEditMode(true)}
                    showIcon
                    buttonType="secondary"
                  />
                </ButtonDiv>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <ButtonDiv style={{ marginRight: "15px" }}>
                  <Button
                    label={t("Cancel")}
                    onClick={() => {
                      setSelectedFile(null);
                      setEditMode(false);
                    }}
                    showIcon={false}
                    buttonType="ghost"
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <Button
                    label={t("Save")}
                    iconClass="save"
                    disabled={isLoading}
                    onClick={submit}
                    showIcon
                    buttonType="save"
                  />
                </ButtonDiv>
              </ButtonWrapper>
            )}
          </MfaAuthContainer>
        </Form>
      </MfaAuthDivContainer>
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state?.user?.loginDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MFAAuth);
