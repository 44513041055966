import React, { useState } from "react";
import FlipCard from "../../../components/FlipCard/FlipCard";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import "./AccountUser.css";
import { ProfileDivStyled } from "./UserProfile.styles";

export default function UserProfile() {
  const [face, setFace] = useState("front");
  return (
    <ProfileDivStyled>
      <FlipCard
        front={() => <EditProfile setFace={setFace} />}
        back={() => <ChangePassword face={face} setFace={setFace} />}
        currentFace={face}
      />
    </ProfileDivStyled>
  );
}
