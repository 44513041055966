export const initialState = {
  //For user bulk upload

  // For asset bulk upload
  assetBulkUploadLoading: false,
  assetBulkUploadSuccess: false,
  assetBulkUploadError: false,
  assetBulkUploadFileUploadLoading: false,
  assetBulkUploadFileUploadSuccess: false,
  assetBulkUploadFileUploadError: false,
  assetBulkUploadFileUploadStatus: "",
  assetTypeTemplateList: [],
};
