import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { RegisterTitle, RegisterSubTitle, RegisterSubTitle2 } from "./PasswordRecoverSuccess.styles";
import { ReactComponent as RightCircledIcon } from "../../../assets/rightCircledIcon/Group 3.svg";
import Button from "../../../components/Button/Button.component";
import SnackBar from "../../Dashboard/SnackBar/SnackBar";

const PasswordRecoverSuccess = ({ history }) => {
  const { t } = useTranslation();
  const [showSnackbarMsg, setShowSnackbarMsg] = useState(true);
  const handleCloseSnackBar = () => {
    setShowSnackbarMsg(false);
  };

  return (
    <div className="forgot-pass forgot-pass-height">
      <RegisterTitle>
        <Trans>Password Recovery</Trans>
      </RegisterTitle>

      <RegisterSubTitle>
        <Trans>Awesome! Check your email</Trans>
      </RegisterSubTitle>

      <RegisterSubTitle2>
        <Trans>Follow the instructions to reset your password</Trans>.
      </RegisterSubTitle2>
      <Container>
        <form>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ paddingBottom: "10px" }}>
              {/* Success Tick Icon */}
              <RightCircledIcon style={{ width: "60px", height: "60px" }} />
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              {/* Back To Login Button redirecting to Home Page*/}

              <Button
                onClick={() => history.push("/")}
                label={t("Back To Login")}
                buttonType="primary"
                size="large"
                showIcon={false}
              />
            </Col>
            <Col md={3}></Col>
          </Row>
        </form>
      </Container>
      <SnackBar
        isSuccess={showSnackbarMsg}
        label={t("Password Recovery Information Sent")}
        handleClose={handleCloseSnackBar}
      />
    </div>
  );
};

export default withRouter(PasswordRecoverSuccess);
