import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import * as htmlToImage from "html-to-image";

import Button from "../../components/Button/Button.component";

import {
  ModalTitle,
  SuccessIconWrapper,
  ModalContentWrapper,
  PrintMessageWrapper,
  QrWrapper,
  IconSuccess,
  FullWidthDiv,
} from "./SuccessMessageModal.component.styles";
import QrCode from "../qrCode/QrCode.component";

const SuccessMessageModal = (props) => {
  const { show, handleCloseModal, handleAddAnother, propertyName, showQRCode, qrCodeDetails } = props;
  const { t } = useTranslation();
  const downloadQr = (e) => {
    const element = document.getElementById("qrCodeIdWrapper");
    htmlToImage.toSvg(element).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `${qrCodeDetails.label}.svg`;
      link.href = dataUrl;
      link.click();
    });
  };

  const printQr = () => {
    htmlToImage.toSvg(document.getElementById("qrCodeIdWrapper")).then((dataUrl) => {
      let printDiv = document.createElement("div");
      let printQrCode = document.createElement("img");
      printQrCode.src = dataUrl;
      printQrCode.style.display = "inline-block";
      printQrCode.style.height = "auto";
      printQrCode.style.padding = "10px";

      // printQrCode.style.textAlign= "center"
      printDiv.appendChild(printQrCode);
      let printWindow = window.open();
      const content = `<html><head><style> p{margin-top: "5px" !important;margin-bottom: "5px" !important; } </style></head><body>
                ${printDiv.innerHTML}                 
                <script>setTimeout(() => {
                    try {
                        // Print for Safari browser
                        document.execCommand('print', false, null)
                      } catch {
                        window.print()
                      }
                }, 100) </script></body></html>`;
      try {
        printWindow.document.write(content);
      } catch {
        alert("Please check pop-up permission and try again");
      }
    });
  };

  const translatedPropertyName = t(propertyName, { defaultValue: propertyName });
  const labelName = t("add another", { propertyName: translatedPropertyName });

  return (
    <Modal
      show={show}
      centered
      size="lg"
      onHide={handleCloseModal}
      backdrop="static"
      dialogClassName="success-modal-dialog"
      backdropClassName="success-modal-backdrop"
    >
      <ModalContentWrapper>
        <ModalTitle>
          <Trans i18nKey="propertyAdded" values={{ propertyName: translatedPropertyName }}>
            {translatedPropertyName}
          </Trans>{" "}
          <Trans>Added</Trans>
        </ModalTitle>
        <SuccessIconWrapper className="rightIcon mt-4">
          <IconSuccess className="icon icon-select" />
        </SuccessIconWrapper>
        <FullWidthDiv></FullWidthDiv>
        {showQRCode && (
          <QrWrapper>
            <QrCode isUser={propertyName === "User"} size={96} qrCodeDetails={qrCodeDetails} />

            <PrintMessageWrapper>
              {propertyName === "User"
                ? t("Please print and paste this QR code on the user's badge or uniform")
                : t("Please print and paste this QR code on your asset")}
            </PrintMessageWrapper>
          </QrWrapper>
        )}

        <Button
          style={{ marginBottom: "10px", width: "220px" }}
          label={t("Continue")}
          onClick={handleCloseModal}
          showIcon={false}
          buttonType="primary"
        />

        <br />

        {showQRCode && (
          <>
            <Button
              style={{ marginRight: "10px", width: "220px" }}
              iconClass="download"
              label={t("Download")}
              onClick={downloadQr}
              showIcon
              buttonType="secondary"
            />

            <Button
              style={{ marginBottom: "10px", width: "220px" }}
              iconClass="print"
              label={t("Print")}
              onClick={printQr}
              showIcon
              buttonType="secondary"
            />

            <br />
          </>
        )}

        <Button
          style={{ width: "220px" }}
          label={labelName}
          onClick={handleAddAnother}
          showIcon={false}
          buttonType="ghost"
        />
      </ModalContentWrapper>
    </Modal>
  );
};

SuccessMessageModal.propTypes = {
  show: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  propertyName: PropTypes.string,
  handleAddAnother: PropTypes.func,
};

export default SuccessMessageModal;
