import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

export const AnalyticsContainer = styled.div``;
export const HeadingAnalytics = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  min-height: 38px;
  height: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 15px;
  color:  ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-family: "Open Sans", sans-serif;
  text-align: left;
  font-size: 16px !important;
  position: relative;

  .icon.icon-update {
    transition: transform 0.5s;
  }
`;

export const IconContainer = styled.div`
  position: relative;
  right: 0px;
  float: right;
  top: -8px;
`;

export const TittleDiv = styled.div`
  float: left;
  width: 96%;
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateAnimationCSS = css`
  ${rotateAnimation} 2s linear forwards;
`;

export const RefreshButton = styled.div`
  cursor: pointer;
  position: absolute;
  font-size: 16px !important;
  top: 0;
  left: 0;
  line-height: 15px;
  animation: ${({ rotating }) => (rotating ? rotateAnimationCSS : 'none')};
  @media screen and (max-width: 800px) {
    left: -10px;
  }
`;
