import styled from "styled-components";
export const CarouselWrapperDiv = styled.div`
  width: 95%;
  margin: auto;
  .icon {
    font-size: 22px !important;
  }
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .count-card {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    span {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  .borderLeftStyle {
    border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;
export const InspectionPerformedIcon = styled.div`
  font-size: 21px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 6px;
    right: 7px;
    position: relative;
  }
`;
export const InspectionPassedIcon = styled.div`
  font-size: 21px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 6px;
    right: 7px;
    position: relative;
  }
`;
export const InspectionFailedIcon = styled.div`
  font-size: 21px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 6px;
    right: 7px;
    position: relative;
  }
`;
