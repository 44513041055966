export const initialState = {
  //For Language
  getJwtTokenLoading: false,
  getJwtToken: {
    success: null,
    error: null,
  },
  showLoader: false,
  showError: false,
  getUserChartsListLoading: false,
  userChartsList: {
    loaded: false,
    success: [],
    error: null,
  },
  getAllChartsListLoading: false,
  getAllChartsList: {
    success: [],
    error: null,
  },
  saveUserChartsListLoading: false,
  saveUserChartsList: {
    payload: [],
    success: false,
    error: null,
  },
  userId: null,
  selectedChart: null,
};
