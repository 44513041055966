//TODO - Clean up, Refactor,  Optimize this file when time permits and remove this comment afterwards
//Separate styles, constants, sub-components
//Move the actions to action file

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import AssetOverviewForm from "./AssetOverviewForm.component";
import AssetDetailsForm from "./AssetDetailsForm.component";
import AssetMedia from "./AssetMedia.component";
import Loader from "../Loader";

import { assetAdded } from "../../../redux/assets/action";
import Button from "../../../components/Button/Button.component";
import { addAsset } from "../../../redux/asset/asset.action";
import {
  getDropdownAccountList,
  getDropdownAssetTypeList,
  getDropdownProductList,
  getDropdownWorksiteList,
} from "../../../redux/dropdownFilters/action";
import { setModalWaitingToOpen } from "../../../redux/modals/action";
import { apiCall } from "../../../utils/apiCall";
import { AddAssetStepperDiv, IconSuccess } from "./AddAssetStepper.component.styles";
import CustomStepper from "../../../components/StepperComponent/Stepper.Component";

function getSteps() {
  return ["Asset Overview", "Asset Details", "Asset Media"];
}

function getStepContent(step, props, validated) {
  const {
    formData,
    setFormData,
    handleNext,
    PreviewImage,
    setPreviewImage,
    isValidationError,
    isSubmitClicked,
  } = props;
  switch (step) {
    case 0:
      return (
        <AssetOverviewForm
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
          PreviewImage={PreviewImage}
          setPreviewImage={setPreviewImage}
          isValidationError={isValidationError}
          isSubmitClicked={isSubmitClicked}
        />
      );
    case 1:
      return (
        <AssetDetailsForm
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
        />
      );
    default:
      return <AssetMedia setFormData={setFormData} formData={formData} />;
  }
}

function AddAssetStepper({
  assetTypeList,
  loginDetails,
  addAsset,
  toogleModalClose,
  assetImageLoading,
  assetImage,
  setIsSuccess,
  setIsError,
  assetAdded,
  workSiteList,
  isSuperAdmin,
  onHide,
  getDropdownAccountList,
  getDropdownAssetTypeList,
  getDropdownWorksiteList,
  getDropdownProductList,
  isSiteAdmin,
  setModalWaitingToOpen,
  setCreatedAsset,
}) {
  //const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [formData, setFormData] = useState({ assetAttributes: [] });
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [PreviewImage, setPreviewImage] = useState("");
  const [isValidationError, setValidationError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  //const { showAlert } = useAlert();

  const handleNext = (event) => {
    let form = event.target;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (isValidationError) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { t } = useTranslation();

  const handleBack = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isLoading) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const validateAssetData = (formData) => {
    let assetTypeId = formData.assetType;
    let accountId = formData.accountIdForSuperAdmin;
    let siteId = formData.siteId;
    let productType = formData.productType;
    let errors = [];
    if (!assetTypeId) {
      errors.push("Asset Type");
    }
    if (!accountId && isSuperAdmin) {
      errors.push("Account");
    }
    if (!siteId && !isSiteAdmin) {
      errors.push("Worksite");
    }
    if (!productType) {
      errors.push("SIERA.AI Product");
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formData)
    if (!isSubmitClicked) {
      return;
    }
    let isError = validateAssetData(formData);
    if (isError[0]) {
      setValidationError(isError);
    } else {
      setValidationError(false);
    }
  }, [formData, isSubmitClicked]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setModalWaitingToOpen(false);
    if (isValidationError) {
      setIsLoading(false);
      return;
    }
    let selectedAssetTypeData = [];
    let assetAttributes = [];
    let assetTypeId = formData.assetType;
    await apiCall(`/assettype/getassettype/${assetTypeId}`).then((response) => {
      // eslint-disable-next-line no-unused-expressions
      onHide?.();
      if (response.data.success === true) {
        //showAlert("success", t(validationConstant.asset_added), 1500);
        selectedAssetTypeData = response.data.data[0];
      }
    });

    selectedAssetTypeData.AssetTypeAttributes.map((data) => {
      assetAttributes.push({
        AssetAttributeName: data.AssetAttributeName,
        AssetAttributeRequired: data.AssetAttributeRequired,
        AssetAttributeValue: formData[data.AssetAttributeName],
      });
    });

    let data = {
      assetName: formData.assetName,
      assetDescription: formData.assetDescription,
      assetUId:
        assetAttributes[0].AssetAttributeValue +
        "_" +
        assetAttributes[1].AssetAttributeValue +
        "_" +
        assetAttributes[2].AssetAttributeValue,
      assetMedia: formData.assetMedia,
      assetAttributes,
      assetTypeId: selectedAssetTypeData._id,
      accountId: loginDetails.accountId,
      siteId: formData.siteId,
      assetImage: assetImage,
      s3enabled: formData.s3enabled,
      productType: formData.productType,
    };

    if (formData.accountIdForSuperAdmin || isSuperAdmin) {
      data.accountId = formData.accountIdForSuperAdmin;
    }

    if (isSiteAdmin) {
      data.siteId = loginDetails.userData.authorizedSites[0];
    }
    await apiCall(`/asset/create`, { method: "POST", data: data })
      .then((response) => {
        if (response.data.success === true) {
          // alert("added");
          addAsset(response.data.data);
          setFormData({ assetAttributes: [] });
          toogleModalClose();
          assetAdded(response.data.data);
          setIsSuccess(true);

          setCreatedAsset(response.data.data);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error occurred--", error);
        setIsError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      assetType: assetTypeList && assetTypeList[0] && assetTypeList[0]._id,
      siteId: workSiteList && workSiteList[0] && workSiteList[0] && workSiteList[0]._id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypeList, workSiteList]);

  useEffect(() => {
    setRole(loginDetails ? loginDetails?.permission?.permissionName : "");
  }, [loginDetails]);

  useEffect(() => {
    getDropdownAccountList();
    getDropdownAssetTypeList();
    getDropdownWorksiteList();
    getDropdownProductList();
  }, []);

  return (
    <AddAssetStepperDiv>
      {assetImageLoading && <Loader />}
      <CustomStepper
        allSteps={[
          { iconClass: "icon icon-blue-asset-overview", heading: t("Asset Overview") },
          { iconClass: "icon icon-blue-asset-details", heading: t("Asset Details") },
          { iconClass: "icon icon-asset-media", heading: t("Asset Media") },
        ]}
        activeStep={activeStep}
      />

      <div>
        {activeStep === steps.length ? (
          <div className="text-center">
            <IconSuccess className="icon icon-select" />
            <div>{t("You have successfully added an asset")}</div>
          </div>
        ) : (
          <div className="button-dissabled-asset">
            <div>
              {getStepContent(
                activeStep,
                {
                  formData,
                  setFormData,
                  handleNext,
                  PreviewImage,
                  setPreviewImage,
                  isValidationError,
                  isSubmitClicked,
                },
                validated
              )}
            </div>
            <div className={`text-right float-right d-flex mr-3 mb-3`}>
              {activeStep !== 0 && (
                <Button
                  label={t("Back")}
                  disabled={activeStep === 0 || isLoading}
                  onClick={handleBack}
                  showIcon
                  iconClass="arrow-left"
                  buttonType="ghost"
                />
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  label={
                    <span>
                      {isLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                      ) : (
                        t("Submit")
                      )}
                    </span>
                  }
                  value="submit"
                  form="assetAddForm1"
                  onClick={(e) => {
                    if (!isLoading) {
                      handleSubmit(e);
                      setIsLoading(true);
                    }
                  }}
                  showIcon
                  style={{ marginLeft: "10px" }}
                  iconClass="save"
                  buttonType="save"
                />
              ) : (
                <Button
                  form="assetAddForm1"
                  label={t("Next")}
                  onClick={() => setIsSubmitClicked(true)}
                  iconClass="arrow-right"
                  showIcon
                  iconPosition="after"
                  buttonType="primary"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </AddAssetStepperDiv>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetTypeList: state.assets.AssetTypes,
  assetImageLoading: state.asset.AssetImageLoading,
  assetImage: state.assets.AssetImage,
  workSiteList: state.worksites.Worksites,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  addAsset: (assetDetail) => dispatch(addAsset(assetDetail)),
  assetAdded: (data) => dispatch(assetAdded(data)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownProductList: () => dispatch(getDropdownProductList()),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAssetStepper));
