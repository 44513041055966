import styled from "styled-components";

export const ButtonPanel = styled.div`
  justify-content: end;
  display: flex;
`;

export const TabContainerMobile = styled.div`
  @media (max-width: 1100px) {
    padding: 5px 0px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
`;
