import { settingsStateTypes } from "./settings.types";
import { initialState } from "./initialState";

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingsStateTypes.SET_CURRENT_TIMEZONE:
      return {
        ...state,
        currentTimeZone: action.payload,
      };

    default:
      return state;
  }
};

export default settingsReducer;
