import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../components/Button/Button.component";

import { setAddSiteModal } from "../../../redux/modals/action";

function WorksiteActionBar({ showActionBar, setAddSiteModal, setWorksiteBulkUploadShow }) {
  const { t } = useTranslation();
  return (
    <>
      {!showActionBar ? (
        ""
      ) : (
        <>
          <>
            {/* <Button
              className="buttonAdd ripple"
              onClick={() => setAddSiteModal(true)}
              variant="outline-primary"
            >
              <AddIcon className="buttonIcon" />
              <Trans>ADD WORKSITE</Trans>
            </Button> */}

            <Button
              iconClass="add"
              label={t("Add Worksite")}
              onClick={() => setAddSiteModal(true)}
              showIcon
              buttonType="secondary"
            />
          </>
          <>
            <Button
              iconClass="bulk-upload"
              label={t("Bulk Upload")}
              onClick={() => setWorksiteBulkUploadShow(true)}
              showIcon
              buttonType="secondary"
              disabled
            />

            {/* <Button
              className="buttonAdd buttonDisabled ripple"
              onClick={() => setWorksiteBulkUploadShow(true)}
              variant="outline-primary"
              style={{
                color: "#ccc",
                borderColor: "#ccc",
              }}
              disabled
            >
              <BulkUpload style={{ fill: "#CCC" }} /> <Trans>BULK UPLOAD</Trans>
            </Button> */}
          </>
          {/* <Col  md={{ offset: 5 }}><SearchAWorksite /></Col> */}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteActionBar));
