import { mobileViewTypes } from "./types";

export const setManagementActionBar = (data) => ({
  type: mobileViewTypes.SET_MANAGEMENT_ACTION_BAR_STATUS,
  data: data,
});

export const setShowFilters = (data) => ({
  type: mobileViewTypes.SET_SHOW_FILTERS_STATUS,
  data: data,
});
