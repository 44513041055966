//TODO cleanup this file when time permits and remove this comment

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import SignupForm from "./SignupForm.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import SnackBar from "../SnackBar/SnackBar";
import { connect } from "react-redux";
import { updateAccountList } from "../../../redux/account/account.action";
import { updateCount } from "../../../redux/count/count.action";
import { updateMenu } from "../../../redux/sideMenu/menu.action";
import { getWorksites } from "../../../redux/worksites/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { userList, defaultUserOverview } from "../../../redux/userManagement/userManagement.action";
import { resetLoginStatus } from "../../../redux/user/user.action";
import { checklistList } from "../../../redux/checklist/checklist.action";
import { getAllChecklist } from "../../../redux/checklists/action";
import { setUpdateAccountPopup } from "../../../redux/config/action";
import { setAddAssetModal, setAddUserModal, setModalWaitingToOpen } from "../../../redux/modals/action";
import managementModule from "../../../constant/managementModule";

// Panel Components
import AccountPanel from "./panels/accountPanel.component";
import WorksitePanel from "./panels/worksite/worksitePanel.component";
import AssetPanel from "./panels/assetPanel.component";
import UserPanel from "./panels/userPanel.component";
import { Trans, useTranslation } from "react-i18next";
import EditAlertModal from "../Modals/EditAlertModal";
import { apiCall } from "../../../utils/apiCall";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    boxShadow: "unset",
    borderBottom: "1px solid #ccc",
    fontSize: "20px",
  },
  labelWrapped: {
    fontSize: "0.875rem",
  },
}));

function CustomizedTabs(props) {
  let {
    loginDetails,
    menuList,
    updateMenu,
    updateCount,
    loginSuccess,
    resetLoginStatus,
    setUpdateAccountPopup,
    isSuperAdmin,
    modalWaitingToOpen,
    setModalWaitingToOpen,
    setAddAssetModal,
    setAddUserModal,
    worksiteLoading,
    editForm,
    setEditAlertModal,
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [module, setModule] = useState([0, 1, 2]);
  const [successMsg, setSuccessMsg] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue == value) {
      return;
    }
    if (editForm) {
      updateMenu({ section: "management", subSection: newValue });
    } else {
      setEditAlertModal(true);
    }
  };

  const handleCloseSnackBar = () => {
    setSuccessMsg(false);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      setModalSignupShow(false);
      return;
    }
    if (loginDetails.formPending && loginSuccess) {
      setModalSignupShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails, isSuperAdmin]);

  useEffect(() => {
    menuList && setValue(menuList.subSection);
  }, [menuList]);

  useEffect(() => {
    if (worksiteLoading) {
      return;
    }
    if (modalWaitingToOpen && modalWaitingToOpen === "Asset") {
      updateMenu({ section: "management", subSection: isSuperAdmin ? 2 : 1 });
      setAddAssetModal(true);
    } else if (modalWaitingToOpen && modalWaitingToOpen === "User") {
      updateMenu({ section: "management", subSection: isSuperAdmin ? 3 : 2 });
      setAddUserModal(true);
    }
    setModalWaitingToOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksiteLoading]);

  const loadCount = async (accountId) => {
    const response = await apiCall(`/account/getCount`);
    if (response.data.success === true) {
      updateCount(response.data.data);
    }
  };

  //TODO clean up this
  const roleFilter = (role) => {
    /* if (role === permission.SITE_ADMIN || role === permission.SITE_MANAGER) {
      setModule([2, 3]);
      //setValue(1)
    } else if (role === permission.SUPER_ADMIN) { */
    setModule([0, 1, 2, 3]);
    /* } else if (role === permission.ACCOUNT_ADMIN || role === permission.ACCOUNT_OWNER) {
      setModule([1, 2, 3]);
    } */
  };

  useEffect(() => {
    roleFilter(loginDetails ? loginDetails?.permission?.permissionName : "");
    loadCount(loginDetails ? loginDetails.accountId : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  const { t } = useTranslation();
  const hideSignUp = () => {
    setModalSignupShow(false);
    setUpdateAccountPopup(false);
    resetLoginStatus();
  };

  return (
    <>
      <BreadCrumb />
      <EditAlertModal />
      <div className={classes.root}>
        <div className={classes.demo1}>
          {/* <div classes="tabPanels main-panel-tab">*/}
          {/* <AppBar position="static" color="default" className={classes.appBar}> */}
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="ant example"
            className="tabpanelSize"
          >
            {module.map((data, i) => (
              <Tab className="buttontabpanels " index={i} label={t(managementModule[data].label)} />
            ))}
          </Tabs>
          <select onChange={(e) => handleChange(e, Number(e.target.value))} className="mobileTabPanelSize">
            {module.map((data, i) => (
              <option value={Number(data)}>{t(managementModule[data].label)}</option>
            ))}
          </select>
          {/* </AppBar> */}
          {module.map((data, i) =>
            data === 0 ? (
              <TabPanel value={value} index={i} className="management-panel">
                <AccountPanel />
              </TabPanel>
            ) : data === 1 ? (
              <TabPanel value={value} index={i} className="management-panel">
                <WorksitePanel />
              </TabPanel>
            ) : data === 2 ? (
              <TabPanel value={value} index={i} className="management-panel">
                <AssetPanel />
              </TabPanel>
            ) : (
              <TabPanel value={value} index={i} className="management-panel">
                <UserPanel />
              </TabPanel>
            )
          )}
        </div>
        <SignupModal
          show={modalSignupShow}
          onHide={hideSignUp}
          successMsg={successMsg}
          setSuccessMsg={setSuccessMsg}
        />
        <SnackBar isSuccess={successMsg} label={t(successMsg)} handleClose={handleCloseSnackBar} />
      </div>
    </>
  );
}

function SignupModal(props) {
  const classes = useStyles();
  const { successMsg, setSuccessMsg } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ paddingTop: "60px", paddingRight: "80px" }}
      backdrop={true}
      fade={true}
    >
      <Modal.Header closeButton>
        <Modal.Title className={classes.addAccountText} id="contained-modal-title-vcenter">
          <Trans>Welcome to SIERA Dashboard</Trans>
          <div
            style={{
              fontFamily: "Open Sans",
              fontSize: "14px",
              color: "#919191",
            }}
          >
            {" "}
            <Trans>We are almost there! Please tell us a bit more about your organization</Trans>.
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SignupForm successMsg={successMsg} setSuccessMsg={setSuccessMsg} onHide={props.onHide} />
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.account.accountList,
  menuList: state.sideMenu.menuState,
  loginSuccess: state.user.loginSuccess,
  updateAccountPopUp: state.config.updateAccountPopUp,
  isSuperAdmin: state.user.isSuperAdmin,
  modalWaitingToOpen: state.modals.modalWaitingForOpen,
  worksiteLoading: state.worksites.AddWorksiteLoading,
  worksiteAddSuccess: state.worksites.AddWorksiteSuccess,
  addSiteModal: state.modals.addSiteModal,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  userList: (users) => dispatch(userList(users)),
  defaultUserOverview: () => dispatch(defaultUserOverview()),
  updateAccountList: (accountList) => dispatch(updateAccountList(accountList)),
  getWorksites: () => dispatch(getWorksites()),
  updateCount: (countDetail) => dispatch(updateCount(countDetail)),
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  setChecklistList: (checklist) => dispatch(checklistList(checklist)),
  resetLoginStatus: () => dispatch(resetLoginStatus()),
  getAllChecklist: (accountId) => dispatch(getAllChecklist(accountId)),
  setUpdateAccountPopup: (status) => dispatch(setUpdateAccountPopup(status)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizedTabs));
