import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { HeaderWrapper, FilterWrapper } from "./Header.component.styles";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import { getDropdownAccountList } from "../../../../../redux/dropdownFilters/action";
import { getDropdownWorksiteList, getUsers } from "../../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../../utils/apiCall";
import {
  setSettingsDropdownAccountId,
  getDropdownAssetList,
} from "../../../../../redux/dropdownFilters/dropdownActions";
import DateRangePicker from "../../../../../components/DatePicker.component";
import Button from "../../../../../components/Button/Button.component";
import ExportToCSV from "../ExportToCSV.component";

const Header = (props) => {
  const {
    getDropdownWorksiteList,
    isSuperAdmin,
    dropdownAccountList,
    worksiteList,
    setLockUnlockRecords,
    pageSize,
    pageNo,
    getWorksiteListLoading,
    getDropdownAccountList,
    loginDetails,
    setIsLoading,
    isAccountOwner,
    isSiteAdmin,
    setSettingsDropdownAccountId,
    isAccountAdmin,
    getDropdownAssetList,
    dropdownAssetList,
    assetListLoading,
    accountListLoading,
    setPageNo,
    userList,
    getUsers,
    getUserListLoading,
    downloadReportSet,
    recordSet,
    currentTimeZone,
  } = props;
  const { t } = useTranslation();

  const [accountListWithAll, setAccountListWithAll] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({ value: "", label: "All" });

  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);

  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [userListWithAll, setUserListWithAll] = useState([]);

  const [selectedWorksite, setSelectedWorksite] = useState({ value: "", label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: "", label: "All" });
  const [selectedUser, setSelectedUser] = useState({ value: "", label: "All" });
  const [eventCategory, setEventCategory] = useState({ value: "", label: "All" });
  const [event, setEvent] = useState({ value: "", label: "All" });

  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList.map((option) => ({
        value: option._id,
        label: option.companyName,
      }));
      optionListTemp.unshift({ value: "", label: "All" });
      setAccountListWithAll(optionListTemp);
    } else {
      setAccountListWithAll([{ value: "", label: "All" }]);
    }
  }, [dropdownAccountList]);

  useEffect(() => {
    if (userList?.length > 0 && userList?.[0]?._id !== -1) {
      const optionListTemp = userList.map((option) => ({
        label: option.fullName,
        value: option._id,
      }));
      // as suggested by Shubham, the id for unauthorized user is 6046645f21ae542b2a42a8d3 in all the envs
      optionListTemp.unshift({ value: "6046645f21ae542b2a42a8d3", label: "Unauthorized" });
      optionListTemp.unshift({ value: "", label: "All" });
      setUserListWithAll(optionListTemp);
    } else {
      setUserListWithAll([{ value: "", label: "All" }]);
    }
  }, [userList]);

  const accountId = isSuperAdmin ? selectedAccount?.value : loginDetails?.accountId;

  const getTroubleshootingLogs = async () => {
    setIsLoading(true);
    const params = `${accountId && accountId !== "all" ? "&accountId=" + accountId : ""}${selectedWorksite.value ? "&siteId=" + selectedWorksite.value : ""}${selectedAsset.value ? "&assetId=" + selectedAsset.value : ""}${eventCategory.value ? "&cat=" + eventCategory.value : ""}${event.value ? "&event=" + event.value : ""}${selectedUser.value ? "&userId=" + selectedUser.value : ""}${startDate ? "&startDate=" + startDate.toISOString() : ""}${endDate ? "&endDate=" + endDate.toISOString() : ""}`;

    try {
      const resp = await apiCall(`/activityLogs/?pageSize=${pageSize}&pageNo=${pageNo}${params}`, {
        method: "GET",
      });
      if (resp.data.success) {
        setLockUnlockRecords(resp?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching login records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTroubleshootingLogs();
  }, [pageNo, pageSize]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  useEffect(() => {
    if (selectedAccount) {
      setSelectedWorksite({ value: "", label: "All" });
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount.value, "", "", "all", "all", "");
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: "", label: "All" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: "", label: "All" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (dropdownAssetList?.length > 0 && dropdownAssetList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAssetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "", label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: "", label: "All" }]);
    }
  }, [dropdownAssetList]);

  useEffect(() => {
    setSelectedAsset({ value: "", label: "All" });
    getDropdownAssetList(selectedAccount?.value || "all", selectedWorksite?.value || "all", "all");
  }, [selectedWorksite, selectedAccount]);

  const applyChange = () => {
    setPageNo(1);
    getTroubleshootingLogs();
  };

  const catToEventMapping = {
    admin_menu_access: [
      { value: "", label: t("All") },
      { value: "admin_menu_login", label: t("Admin Menu Login") },
    ],
    config_update: [
      { value: "", label: t("All") },
      { value: "config_update", label: t("Config Update") },
    ],
    fe_auth: [
      { value: "", label: t("All") },
      { value: "fe_login", label: t("fe_login") },
    ],
    internet_activity: [
      { value: "", label: t("All") },
      { value: "i_connected", label: t("Connected") },
      { value: "i_disconnected", label: t("Disconnected") },
    ],
    lock_unlock: [
      { value: "", label: t("All") },
      { value: "lock", label: t("Lock") },
      { value: "unlock", label: t("Unlock") },
    ],
  };

  const eventValueToLabelMapping = {
    admin_menu_login: t("Admin Menu Login"),
    config_update: t("Config Update"),
    fe_login: t("fe_login"),
    i_connected: t("Connected"),
    i_disconnected: t("Disconnected"),
    lock: t("Lock"),
    unlock: t("Unlock"),
  };

  return (
    <HeaderWrapper>
      <FilterWrapper>
        <Row>
          <Col md={3}>
            <DropdownComponent
              label={t("Account")}
              placeholder={"Select an Account"}
              options={accountListWithAll}
              value={selectedAccount}
              handleFilter={(selectedData) => {
                setSelectedAccount(selectedData);
              }}
              size="medium"
              isLoading={accountListLoading}
            />
          </Col>

          <Col md={3}>
            <DropdownComponent
              label={t("Worksite")}
              placeholder={t("Select a worksite")}
              options={worksitesListWithAll}
              value={selectedWorksite}
              handleFilter={(option) => {
                setSelectedWorksite(option);
              }}
              errorMessage={t("This field is required")}
              size="medium"
              isLoading={getWorksiteListLoading}
            />
          </Col>

          {/* Date Range Picker */}
          <Col lg={3}>
            <DropdownComponent
              label={t("Asset")}
              placeholder={t("Select an Asset")}
              options={assetListWithAll}
              value={selectedAsset}
              handleFilter={(asset) => {
                setSelectedAsset(asset);
              }}
              size="medium"
              isLoading={assetListLoading}
            />
          </Col>
          <Col lg={3}>
            <DropdownComponent
              label={t("User")}
              placeholder={t("Select a User")}
              options={userListWithAll}
              value={selectedUser}
              handleFilter={setSelectedUser}
              size="medium"
              isLoading={getUserListLoading}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <DropdownComponent
              label={t("Event Category")}
              placeholder={t("Select an Event Category")}
              options={[
                { value: "", label: t("All") },
                { value: "admin_menu_access", label: t("Admin Menu Access") },
                { value: "config_update", label: t("Config Update") },
                { value: "fe_auth", label: t("fe_auth") },
                { value: "internet_activity", label: t("Internet Activity") },
                { value: "lock_unlock", label: t("Lock/Unlock") },
              ]}
              value={eventCategory}
              handleFilter={(data) => {
                setEventCategory(data);
                setEvent({ value: "", label: t("All") });
              }}
              size="medium"
            />
          </Col>
          <Col md={3}>
            <DropdownComponent
              label={t("Event")}
              placeholder={t("Select an Event")}
              options={catToEventMapping[eventCategory.value] || []}
              value={event}
              handleFilter={setEvent}
              size="medium"
            />
          </Col>
          <Col md={6}>
            <DateRangePicker
              setDate={onDateChange}
              startDate={startDate}
              endDate={endDate}
              minDate={moment().subtract(730, "days").toDate()}
            />
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "right", marginTop: "10px" }}>
            <Button
              label={t("Apply Filter")}
              size="medium"
              onClick={applyChange}
              showIcon={false}
              buttonType="primary"
            />
            <ExportToCSV
              downloadReportSet={downloadReportSet}
              reportList={recordSet}
              currentTimeZone={currentTimeZone}
            />
          </Col>
        </Row>
      </FilterWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,

  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  accountListLoading: state.dropdownFilters.accountListLoading,
  isSuperAdmin: state.user.isSuperAdmin,
  loginDetails: state.user.loginDetails,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  assetListLoading: state.dropdownFilters?.settings?.assetListLoading,
  dropdownAssetList: state.dropdownFilters?.settings?.assetList,

  userList: state.modalDropdownFilters.userList,
  getUserListLoading: state.modalDropdownFilters.getUserListLoading,
  dropdownAssetTypeList: state.dropdownFilters?.settings?.assetTypeList,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAssetList: (selectedAccountId, selectedWorksite, assetTypeId) =>
    dispatch(getDropdownAssetList(selectedAccountId, selectedWorksite, assetTypeId)),
  getUsers: (accountId, siteId) => dispatch(getUsers(accountId, siteId)),
});

Header.defaultProps = {
  showFilters: true,
  handleAssetIdFilter: () => {},
  handleAssetTypeIdFilter: () => {},
  handleWorksiteFilter: () => {},
  showWorksiteFilter: true,
  showAssetTypeFilter: true,
  showAssetFilter: true,
  showUserTypeFilter: false,
  showUserFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
