import { bulkUploadTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheRefs } from "../../utils/cache/CacheRefs";

import { getAllAsset } from "../assets/action";

export const resetBulkUploadAssetState = () => ({
  type: bulkUploadTypes.RESET_UPLOAD_ASSET_BULK_UPLOAD_FILE_STATE,
});

const getAssetBulkUploadLoading = () => ({
  type: bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_LOADING,
});

const getAssetBulkUploadSuccess = (data) => ({
  type: bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_SUCCESS,
  data,
});

const getAssetBulkUploadError = (err) => ({
  type: bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_ERROR,
  err,
});

export const getAssetBulkUploadTemplates = (accountId) => {
  return async (dispatch, getState) => {
    dispatch(getAssetBulkUploadLoading());
    let state = getState();
    // let accountId =
    //     state &&
    //     state.user &&
    //     state.user.loginDetails &&
    //     state.user.loginDetails.accountId;

    // let isSuperAdmin = state && state.user && state.user.isSuperAdmin;

    // if (isSuperAdmin) {
    //     accountId =
    //         state && state.dropdownFilters && state.dropdownFilters.accountId;
    // }

    let action = `/assettype/getAssetTemplate/${accountId}`;

    apiCall(action)
      .then((response) => {
        dispatch(getAssetBulkUploadSuccess(response?.data?.data));
      })
      .catch((error) => {
        dispatch(getAssetBulkUploadError(error));
      });
  };
};

const postAssetBulkUploadLoading = () => ({
  type: bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_LOADING,
});

const postAssetBulkUploadSuccess = (data) => ({
  type: bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_SUCCESS,
  data,
});

const postAssetBulkUploadError = (err) => ({
  type: bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_ERROR,
  err,
});

export const uploadBulkAssets = (data) => {
  return async (dispatch, getState) => {
    // let state = getState();

    dispatch(postAssetBulkUploadLoading());

    try {
      const resp = await apiCall(`/bulkupload/uploadAssets`, { method: "POST", data: data, type:"file" });
      dispatch(getAllAsset({ pageNo: 1, pageSize: 15, searchKey: "all" }, true, true));
      await CacheRefs.removeCacheForAssets();
      await CacheRefs.removeCacheForAssetTypes();
      dispatch(postAssetBulkUploadSuccess(resp?.data));
    } catch (err) {
      dispatch(postAssetBulkUploadError(err));
    }
  };
};
