export const initialState = {
  //For Asset List
  AssetLoading: false,
  AssetSuccess: false,
  AssetError: false,
  Assets: [],

  // For asset detail
  assetDetailSuccess: false,
  assetDetailLoading: false,
  assetDetailError: false,

  //For Asset Types
  AssetTypeLoading: false,
  AssetTypeSuccess: false,
  AssetTypeError: false,
  AssetTypes: [],

  //For Asset Type Overview
  AssetTypeOverview: null,

  //For Asset Overview
  AssetOverview: null,

  //For Inspection Asset
  InpectionAsset: null,

  //For Adding New Asset
  AddAssetLoading: false,
  AddAssetSuccess: false,
  AddAssetError: false,

  //For Asset Status
  AssetStatusLoading: false,
  AssetStatusSuccess: false,
  AssetStatusError: false,
  AssetStatus: false,
  AssetStatusMessage: "",
  assetStatusFailed:false,
  assetStatusData:{},

  //For Asset Image Update
  AssetImageLoading: false,
  AssetImageSuccess: false,
  AssetImageError: false,
  AssetImage: "",

  //For Generate CSV
  GenerateCSVLoading: false,
  GenerateCSVSuccess: false,
  GenerateCSVError: false,
  GeneratingCSVData: [],

  //For Asset Name Edit
  EditAssetNameLoading: false,
  EditAssetNameSuccess: false,
  EditAssetNameError: false,

  //For Asset Checklist
  AssetCheckListLoading: false,
  AssetCheckListSuccess: false,
  AssetCheckListError: false,
  AssetCheckList: [],

  //For Inspection Upload Media
  InspectionMediaLoading: false,
  InspectionMediaSuccess: false,
  InspectionMediaError: false,
  InspectionMedia: null,

  //For Answer Submit
  AnswerLoading: false,
  AnswerSuccess: false,
  AnswerError: false,
  Result: null,

  //Asset Type update checklist mapping
  UpdateAssetTypeChecklistLoading: false,
  UpdateAssetTypeChecklistSuccess: false,
  UpdateAssetTypeChecklistError: false,
};
