/** Tons of bad and unused code are removed but still there are many. Please fix when time permits */

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import SnackBar from "../../SnackBar";
import { connect } from "react-redux";
import {
  getImpactThreshold,
  updateImpactThresholdByAssetId,
  resetImpactThresholdByAssetId,
} from "../../../../redux/config/impactThreshold.actions";
import { Trans, useTranslation } from "react-i18next";
import validationConstant from "../../../../constant/messages/validation.json";
import ActionButtons from "../../common/ActionButtons";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";
import SettingsHeader from "../common/SettingsHeader.component";
import {
  SettingsWrapper,
  SettingsContainer,
  SettingsFooter,
  SettingsBody,
  SettingTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  SettingDotIconLow,
  SettingDotIconMed,
  SettingDotIconHigh,
  SettingContent,
  InputGroupWrapper,
  Gtext,
  RowDivError,
  ErrorInputNumbers,
  BorderLineDiv,
  SettingsAlertMessege,
  WarningMessageDiv,
  AlertSettingIcon,
  AndAboveText,
  WarningMessageText,
  NoAssetWrapper,
  LoaderOverlay,
} from "../Settings.Styles";

import ImpactThresholdInfo from "./ImpactThresholdInfo.component";
import { bulkSettingsUpdate, resetNotificationSettingsFlags } from "../../../../redux/config/action";
import { settingsOptions } from "./constants";
import InputComponent from "../../../../components/InputFieldComponent/InputFieldWrapper.component";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

const MIN_G_VALUE = 1;
const G_STEP = 0.01;

const ImpactThresholdSettings = ({
  getImpactThreshold,
  impactThresholdData,
  updateImpactThresholdSuccess,
  getImpactThresholdLoading,
  dropdownAccountId,
  resetImpactThresholdByAssetId,
  updateImpactThresholdByAssetId,
  bulkSettingsUpdate,
  notificationBulkUpdateSuccess,
  isSystemSettingsEditable,
  isSuperAdmin,
  resetNotificationSettingsFlags,
}) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [impactThreshold, setImpactThreshold] = useState({
    lowMin: 3,
    lowMax: 4,
    mediumMin: 4,
    mediumMax: 6,
    highMin: 6,
    highMax: 10,
    accountId: "",
  });
  const { t } = useTranslation();
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [updatableChanges, setUpdatableChanges] = useState(false);
  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [applyAllSelectedAssetTypes, setApplyAllSelectedAssetTypes] = useState([]);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [clearSelection, setClearSelection] = useState(false);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getImpactThreshold(selectedAssetId);
      setApplyToAllObj({});
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = () => {
    toggleApplyToAllModal();
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.assetTypes?.all ||
        (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0)) &&
      (applyToAllObj.assets?.all ||
        (applyToAllObj.assets?.selected && applyToAllObj.assets?.selectedItems?.length > 0))
    );
  };

  const handleUpdate = () => {
    setIsUpdateLoading(true);
    if (isApplyToAllObjValid()) {
      const siteIds = applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const assetTypeIds = applyToAllObj?.assetTypes?.selectedItems?.map((assetType) => assetType.value);
      const assetIds = applyToAllObj?.assets?.selectedItems?.map((asset) => asset.value);

      const updatableSettings = {
        sites: siteIds,
        assetTypes: assetTypeIds,
        assets: assetIds,
        settings: { impactThresholdSettings: impactThreshold },
      };

      bulkSettingsUpdate(updatableSettings, setIsUpdateLoading);
    }
    updateImpactThresholdByAssetId(selectedAssetId, impactThreshold, setIsUpdateLoading);
  };

  /*   useEffect(() => {
    if (isUpdateLoading) setApplyToAllObj({});
  }, [isUpdateLoading]); */

  const handleCancel = () => {
    setClearSelection(true);
    setImpactThreshold(impactThresholdData);
    setApplyToAllObj({});
  };

  const updateThreshold = (v, severity, blurred) => {
    let val = v;
    if (blurred) {
      val = Number(parseFloat(Math.abs(v)).toFixed(2));
    }

    switch (severity) {
      case "LOW_MIN":
        setImpactThreshold({
          ...impactThreshold,
          lowMin: val,
        });
        break;
      case "LOW_MAX":
        setImpactThreshold({
          ...impactThreshold,
          lowMax: val,
          mediumMin: val,
        });
        break;
      case "MEDIUM_MIN":
        setImpactThreshold({
          ...impactThreshold,
          mediumMin: val,
        });
        break;
      case "MEDIUM_MAX":
        setImpactThreshold({
          ...impactThreshold,
          mediumMax: val,
          highMin: val,
          highMax: val,
        });
        break;
      case "HIGH":
        setImpactThreshold({
          ...impactThreshold,
          highMin: val,
          highMax: val,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedAssetId) {
      getImpactThreshold(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    if (impactThresholdData) {
      // const { lowMin, lowMax, mediumMin, mediumMax, highMin, highMax } = impactThresholdData;
      setImpactThreshold({
        ...impactThresholdData,
      });
    }
  }, [impactThresholdData]);

  useEffect(() => {
    if (impactThresholdData) {
      const { lowMin, lowMax, mediumMin, mediumMax, highMin, highMax } = impactThresholdData;
      if (
        lowMin !== impactThreshold.lowMin ||
        lowMax !== impactThreshold.lowMax ||
        mediumMin !== impactThreshold.mediumMin ||
        mediumMax !== impactThreshold.mediumMax ||
        highMin !== impactThreshold.highMin ||
        highMax !== impactThreshold.highMax ||
        isApplyToAllObjValid()
      ) {
        setIsSaveVisible(true);
      } else {
        setIsSaveVisible(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactThreshold, applyToAllObj]);

  useEffect(() => {
    if (updateImpactThresholdSuccess) {
      setIsSaveVisible(false);
    }
  }, [updateImpactThresholdSuccess]);

  const handleCloseSnackBar = () => {
    console.log("handleCloseSnackBar---called", isUpdateLoading);
    setIsUpdateLoading(false);
    resetNotificationSettingsFlags();
  };

  const handleMessage = (IsSuccess) => {
    return t(validationConstant.threshold_update_success);
  };

  useEffect(() => {
    const isUpdatable =
      (selectedAssetId &&
        impactThreshold.lowMin < impactThreshold.lowMax &&
        impactThreshold.lowMax === impactThreshold.mediumMin &&
        impactThreshold.mediumMin < impactThreshold.mediumMax &&
        impactThreshold.mediumMax === impactThreshold.highMin) ||
      isApplyToAllObjValid();
    setUpdatableChanges(isUpdatable && isSaveVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactThreshold, isSaveVisible, selectedAssetId, applyToAllObj]);

  const resetImpactThreshold = () => {
    resetImpactThresholdByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const handleAssetIdFilter = (assetId) => {
    setSelectedAssetId(assetId);
  };

  const blurHandler = (thresholdType, severity) => {
    updateThreshold(
      impactThreshold[thresholdType] < MIN_G_VALUE ? MIN_G_VALUE : impactThreshold[thresholdType],
      severity,
      true
    );
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("IMPACT_THRESHOLD_SETTINGS_HEADER_LABEL")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {isSuperAdmin && !dropdownAccountId ? (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : selectedAssetId ? (
        <>
          <SettingsContainer>
            {getImpactThresholdLoading ? (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            ) : (
              <SettingsBody>
                <SettingTableDescription>
                  <RowDiv isHeader={true}>
                    <Column isHeader={true}>
                      <Trans>Impact Severity</Trans>
                    </Column>
                    <Column isHeader={true}>
                      <Trans>Lower Range</Trans>
                    </Column>
                    <Column isHeader={true}></Column>
                    <Column isHeader={true}>
                      <Trans>Upper Range</Trans>
                    </Column>
                  </RowDiv>
                  <RowsContainer>
                    <RowDiv>
                      <Column>
                        <SettingContent>
                          <SettingDotIconLow className="icon icon-dot"></SettingDotIconLow>
                          <Trans>Low</Trans>
                        </SettingContent>
                      </Column>
                      <Column>
                        <InputGroupWrapper>
                          {!getImpactThresholdLoading ? (
                            <InputComponent
                              value={impactThreshold.lowMin}
                              disabled={!selectedAssetId || !isSystemSettingsEditable}
                              fullWidth
                              type="number"
                              size="small"
                              step={G_STEP}
                              min={MIN_G_VALUE}
                              placeholder={t("lower range")}
                              onChange={(e) => updateThreshold(e.target.value, "LOW_MIN")}
                              isError={impactThreshold.lowMin >= impactThreshold.lowMax}
                              onBlur={() => blurHandler("lowMin", "LOW_MIN")}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          )}
                          <Gtext> g</Gtext>
                        </InputGroupWrapper>
                      </Column>
                      <Column></Column>
                      <Column>
                        <InputGroupWrapper>
                          {!getImpactThresholdLoading ? (
                            <InputComponent
                              value={impactThreshold.lowMax}
                              disabled={!selectedAssetId || !isSystemSettingsEditable}
                              fullWidth
                              type="number"
                              step={G_STEP}
                              min={MIN_G_VALUE}
                              placeholder={t("upper range")}
                              onChange={(e) => updateThreshold(e.target.value, "LOW_MAX")}
                              onBlur={() => blurHandler("lowMax", "LOW_MAX")}
                              isError={
                                impactThreshold.lowMin > impactThreshold.lowMax ||
                                impactThreshold.mediumMin < impactThreshold.lowMax ||
                                impactThreshold.lowMax !== impactThreshold.mediumMin
                              }
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          )}
                          <Gtext> g</Gtext>
                        </InputGroupWrapper>
                      </Column>
                    </RowDiv>
                    <RowDivError>
                      {impactThreshold.lowMin >= impactThreshold.lowMax && (
                        <ErrorInputNumbers>
                          <Trans>Lower range should be lesser than the upper range</Trans>
                        </ErrorInputNumbers>
                      )}
                      {impactThreshold.lowMax !== impactThreshold.mediumMin && (
                        <ErrorInputNumbers>
                          <Trans>Upper range should be equal to MEDIUM IMPACT lower range</Trans>
                        </ErrorInputNumbers>
                      )}
                      {!isNaN(impactThreshold.lowMin) && impactThreshold.lowMin < 0 && (
                        <ErrorInputNumbers>
                          <Trans>Lower range should be at least 0</Trans>
                        </ErrorInputNumbers>
                      )}
                    </RowDivError>
                    <BorderLineDiv></BorderLineDiv>
                    <RowDiv>
                      <Column>
                        <SettingContent>
                          <SettingDotIconMed className="icon icon-dot"></SettingDotIconMed>
                          <Trans>Medium</Trans>
                        </SettingContent>
                      </Column>
                      <Column>
                        <InputGroupWrapper>
                          {!getImpactThresholdLoading ? (
                            <InputComponent
                              value={impactThreshold.mediumMin}
                              disabled={true}
                              fullWidth
                              type="number"
                              step={G_STEP}
                              min={MIN_G_VALUE}
                              placeholder={t("lower range")}
                              onChange={(e) => updateThreshold(e.target.value, "MEDIUM_MIN")}
                              onBlur={() => blurHandler("mediumMin", "MEDIUM_MIN")}
                              isError={
                                impactThreshold.mediumMin >= impactThreshold.mediumMax ||
                                impactThreshold.mediumMin !== impactThreshold.lowMax
                              }
                              // value={impactThreshold?.lowMax}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          )}
                          <Gtext> g</Gtext>
                        </InputGroupWrapper>
                      </Column>
                      <Column></Column>
                      <Column>
                        <InputGroupWrapper>
                          {!getImpactThresholdLoading ? (
                            <InputComponent
                              value={impactThreshold.mediumMax}
                              disabled={!selectedAssetId || !isSystemSettingsEditable}
                              fullWidth
                              type="number"
                              step={G_STEP}
                              min={MIN_G_VALUE}
                              placeholder={t("upper range")}
                              onChange={(e) => updateThreshold(e.target.value, "MEDIUM_MAX")}
                              onBlur={() => blurHandler("mediumMax", "MEDIUM_MAX")}
                              isError={
                                impactThreshold.mediumMin > impactThreshold.mediumMax ||
                                impactThreshold.mediumMax !== impactThreshold.highMin
                              }
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          )}
                          <Gtext> g</Gtext>
                        </InputGroupWrapper>
                      </Column>
                    </RowDiv>

                    <RowDivError>
                      {(impactThreshold.mediumMin >= impactThreshold.mediumMax && (
                        <ErrorInputNumbers>
                          <Trans>Lower range should be lesser than the upper range</Trans>
                        </ErrorInputNumbers>
                      )) ||
                        (impactThreshold.mediumMin !== impactThreshold.lowMax && (
                          <ErrorInputNumbers>
                            <Trans>Lower range should be equal to LOW IMPACT upper range</Trans>
                          </ErrorInputNumbers>
                        )) ||
                        (impactThreshold.mediumMax !== impactThreshold.highMin && (
                          <ErrorInputNumbers>
                            <Trans>Upper range should be equal to high impact severity</Trans>
                          </ErrorInputNumbers>
                        ))}
                    </RowDivError>
                    <BorderLineDiv></BorderLineDiv>
                    <RowDiv>
                      <Column>
                        <SettingContent>
                          <SettingDotIconHigh className="icon icon-dot"></SettingDotIconHigh>
                          <Trans>High</Trans>
                        </SettingContent>
                      </Column>
                      <Column>
                        <InputGroupWrapper>
                          {!getImpactThresholdLoading ? (
                            <InputComponent
                              value={impactThreshold.highMin}
                              disabled={true}
                              fullWidth
                              type="number"
                              step={G_STEP}
                              min={MIN_G_VALUE}
                              placeholder={t("lower range")}
                              onChange={(e) => updateThreshold(e.target.value, "HIGH")}
                              onBlur={() => blurHandler("highMin", "HIGH")}
                              isError={impactThreshold.mediumMax !== impactThreshold.highMin}
                              // value={impactThreshold?.mediumMax}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          )}
                          <Gtext> g</Gtext>
                        </InputGroupWrapper>
                      </Column>
                      <Column></Column>
                      <Column>
                        <AndAboveText>
                          <Trans>AND ABOVE</Trans>
                        </AndAboveText>
                      </Column>
                    </RowDiv>

                    <RowDivError>
                      {impactThreshold.mediumMax !== impactThreshold.highMin && (
                        <ErrorInputNumbers>
                          <Trans>Value should be equal to medium upper range</Trans>
                        </ErrorInputNumbers>
                      )}
                    </RowDivError>
                    {/*  <BorderLineDiv></BorderLineDiv>*/}
                  </RowsContainer>
                </SettingTableDescription>
                <SettingsAlertMessege>
                  <WarningMessageDiv>
                    {impactThreshold.lowMin < 3 && (
                      <>
                        <AlertSettingIcon className="icon icon-alertsettings"></AlertSettingIcon>

                        <WarningMessageText>
                          <Trans>Warning</Trans>:
                          <Trans>Lowering threshold value below 3g may trigger excessive alerts</Trans>
                        </WarningMessageText>
                      </>
                    )}
                  </WarningMessageDiv>
                </SettingsAlertMessege>

                <ImpactThresholdInfo />
              </SettingsBody>
            )}
          </SettingsContainer>
          <SettingsFooter>
            <ActionButtons
              showAppyToAll={true}
              handleApplyToAll={toggleApplyToAllModal}
              applyToAll={isApplyToAllObjValid()}
              updatableChanges={updatableChanges}
              handleUpdate={handleUpdate}
              handleCancel={handleCancel}
              isLoading={isUpdateLoading}
              showResetButton={true}
              handleReset={resetImpactThreshold}
            />
          </SettingsFooter>
          <ApplyToAllModal
            showModal={showApplyToAllModal}
            setShowModal={toggleApplyToAllModal}
            applyChanges={saveHandler}
            applyAllSelectedAssetTypes={applyAllSelectedAssetTypes}
            setApplyAllSelectedAssetTypes={setApplyAllSelectedAssetTypes}
            setCheckApplyToAll={() => {}}
            settingsOptions={settingsOptions}
            modalHeader={"Impact Threshold Settings"}
            applyToAllObj={applyToAllObj}
            setApplyToAllObj={setApplyToAllObj}
            clearSelection={clearSelection}
            setClearSelection={setClearSelection}
          />
        </>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>NO_ASSET_FOUND</Trans>
          </p>
          <p>
            <Trans>EMPTY_ASSET</Trans>
          </p>
        </NoAssetWrapper>
      )}

      <SnackBar
        isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
        label={handleMessage()}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  getImpactThresholdLoading: state.config.getImpactThresholdLoading,
  updateImpactThresholdSuccess: state.config.updateImpactThresholdSuccess,
  impactThresholdData: state.config.impactThresholdData,
  notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  getImpactThreshold: (accountId) => dispatch(getImpactThreshold(accountId)),
  resetImpactThresholdByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetImpactThresholdByAssetId(assetId, setIsUpdateLoading)),
  updateImpactThresholdByAssetId: (assetId, updateData, setIsUpdateLoading) =>
    dispatch(updateImpactThresholdByAssetId(assetId, updateData, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setIsUpdateLoading) => dispatch(bulkSettingsUpdate(data, setIsUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpactThresholdSettings);
