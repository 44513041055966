import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import InputField from "../../../components/InputFieldComponent/InputField.component";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";

const VerifyPassword = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    showPasswordModal,
    setShowPasswordModal,
    invalidPasswordError,
    setInvalidPasswordError,
  } = props;

  const [userAlert, setUserAlert] = useState(null);
  const [userPassword, setUserPassword] = useState("");

  useEffect(() => {
    if (!showPasswordModal) {
      setUserAlert(null);
      setUserPassword("");
    }
  }, [showPasswordModal]);

  const EmailChangeBody = () => {
    return (
      <>
        <span>Please enter your password to confirm the email change</span>
        <InputField
          required
          label="Password"
          name={"password"}
          type="password"
          placeholder={t("Enter your password")}
          value={userPassword}
          onChange={(e) => {
            setUserAlert(null);
            setInvalidPasswordError(false);
            setUserPassword(e.target.value);
          }}
          isError={invalidPasswordError || !!userAlert}
          errorMessage={userAlert ? userAlert : t("Incorrect password. Please try again.")}
          autoFocus
          autocomplete="one-time-code"
        />
      </>
    );
  };

  return (
    <ModalComponent
      show={showPasswordModal}
      variant="TransactionalModal"
      handleClose={() => {}}
      message="Confirm Email Change"
      paragraph={<EmailChangeBody />}
      button1Text="Confirm"
      button2Text="Cancel"
      onClickA={(e) => {
        if (userPassword?.trim()) {
          handleSubmit(userPassword);
        } else {
          setUserAlert(t("Please enter a valid password."));
        }
      }}
      onClickB={() => {
        setShowPasswordModal(false);
        setUserPassword("");
        setInvalidPasswordError(false);
      }}
      typeA="primary"
      typeB="ghost"
    />
  );
};

export default VerifyPassword;
