import styled from "styled-components";
export const ManagementPanelWrapper = styled.div`
  .select__control {
    width: 100% !important;
  }
 
    .text-right {
      padding-right: 0px;
    }
  
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};

  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .input-group-text:disabled:hover {
    background-color: none;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  inpit.enterpriseUrlStyle:focus {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  .input-group input:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    border: 2px solid;
  }
  .enterpriseUrlStyle[disabled]:hover {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .enterpriseUrlStyle:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  .tabpanelSize .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

export const DivLogoCompany = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  height: 100px;
  img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
`;

export const NoLogoMessage = styled.span`
  font-size: 16px;
  color: #999;
`;

export const AccountPageWrapper = styled.div`
  display: block;
  /* grid-template-columns: 33% 33% 33%;*/
  height: calc(100vh - 515px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    height: auto;
    overflow: hidden;
  }
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;
export const UserCol = styled.div`
  margin: 4px 0px;
  float: left;
  width: 48%;

  .companyName label {
    font-size: 12px;
  }

  margin-right: 16px;
  .astr {
    display: none;
  }

  @media (max-width: 1100px) {
    width: 100%;
    margin: 5px 0px;
  }
`;

export const CompanyLogoImg = styled.img`
  display: ${({ loaded }) => (loaded ? "block" : "none")};
  width: 200px;
`;

export const FullWidth = styled.div`
  width: 100%;
  @media (max-width: 1100px) {
    display: block;

    .timeZone {
      margin-bottom: -10px;
      margin-top: -10px;
    }
    .accountLogo {
      margin-top: 10px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: right;
  clear: both;
  width: 100%;
  /* margin-top: -10px; */
  @media (max-width: 1100px) {
    justify-content: left;
    width: 93%;
    margin: -8px 10px auto;
  }
`;

export const UrlText = styled.div`
  float: left;
  width: 100%;
  margin-top: 2px;
`;

export const AvailableText = styled.span`
  color: green;
  margin-left: 5px;
`;

export const NotAvailableText = styled.span`
  color: red;
  margin-left: 5px;
`;

export const ContainerImage = styled.div`
  margin-bottom: 16px;
  display: flex;
  margin-top: 10px;
  @media (max-width: 1100px) {
    display: block;
    overflow: hidden;
    padding: 0px 10px;
  }
`;

export const ChangeImage = styled.div`
  height: 50px;
  color: #fff;
  line-height: 40px;
  width: 100%;
  padding: 5px;
  position: absolute;
  bottom: 0;
  text-align: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    font-family: "Open Sans", sans-serif;
    font-size: 18px !important;
  }
`;
export const LabelContainer = styled.div`
  flex: 1;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
export const CompanyLogo = styled.div`
  margin: 5px 0px;
  width: 40%;
  border-radius: 4px;
  height: 120px;
  float: left;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  &:hover ${ChangeImage} {
    visibility: visible;
  }

  ${ChangeImage} input[type="file"] {
    visibility: hidden;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const AvatarImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.icon-svg {
    width: 100%;
    height: 100%;
  }
`;

export const LabelDiv = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: 12px !important;
  float: left;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
`;
