import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import ChecklistOverviewTab from "./ChecklistOverviewTab.component";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import DropdownFilter from "../../../components/DropdownFilter.component";
import { Trans, useTranslation } from "react-i18next";

import {
  ViewChecklistDiv,
  CardMainContainer,
  CardBodyConatiner,
  TopHeaderDetailsDiv,
  TopTittleName,
  TopDetails,
  DateStyleDiv,
} from "./ChecklistOverview.component.styles";

const ChecklistOverview = ({
  checklistOverview,
  dropdownAccountList,
  isSuperAdmin,
  setDropdownAccountId,
  accountListById,
  currentTimeZone,
}) => {
  const { t } = useTranslation();

  const timeZone = currentTimeZone || checklistOverview?.createdAt || "-06:00 Central America";

  return (
    <ViewChecklistDiv>
      {isSuperAdmin && (
        <Row>
          <Col md={4}>
            <DropdownFilter
              filter={{
                type: "Account",
                key: "companyName",
                data: dropdownAccountList,
              }}
              label={t("filter")}
              handleFilter={(value) => setDropdownAccountId(value)}
            />
          </Col>
        </Row>
      )}
      <CardMainContainer>
        {checklistOverview ? (
          <CardBodyConatiner>
            <TopHeaderDetailsDiv>
              <TopTittleName>
                <span title={checklistOverview ? checklistOverview?.checklistName : ""}>
                  {checklistOverview ? checklistOverview?.checklistName : ""}
                </span>
              </TopTittleName>
              <TopDetails>
                <span title={checklistOverview ? checklistOverview?.checklistDescription : ""}>
                  {checklistOverview ? checklistOverview?.checklistDescription : ""}
                </span>
              </TopDetails>
              <DateStyleDiv>
                <Trans>Added On</Trans>:
                {moment(checklistOverview?.createdAt)
                  .utcOffset(timeZone?.split?.(" ")[0])
                  .format(" MMMM DD YYYY")}
              </DateStyleDiv>

              {isSuperAdmin && checklistOverview?.accountId && (
                <DateStyleDiv>
                  <Trans>AccountLabel</Trans>
                  <span title={accountListById?.[checklistOverview?.accountId]?.companyName}>
                    {accountListById?.[checklistOverview?.accountId]?.companyName}
                  </span>
                </DateStyleDiv>
              )}
            </TopHeaderDetailsDiv>
            <>
              <ChecklistOverviewTab checklistOverview={checklistOverview} />
            </>
          </CardBodyConatiner>
        ) : (
          <Card.Body>
            <span className="h3 checklist_over">
              {/*  <Trans>Please add checklist to see overview</Trans>*/}
              <Trans>NO_CHECKLISTS_IN_ACCOUNT</Trans>
              <br></br>
              {/* <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>*/}
              <div className="clickDesktop">
                {" "}
                <Trans>CLICK_ADD_CHECKLIST_BUTTON</Trans>
              </div>
              <div className="tapMobile">
                {" "}
                <Trans>CLICK_ADD_CHECKLIST_BUTTON_MOB</Trans>
              </div>
            </span>
          </Card.Body>
        )}
      </CardMainContainer>
    </ViewChecklistDiv>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

const mapStateToProps = (state) => ({
  checklistOverview: state.checklist.checklistOverview,
  accountList: state.accounts.Accounts,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  accountListById: state.dropdownFilters.accountListById,
  currentTimeZone: state.settings.currentTimeZone,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistOverview));
