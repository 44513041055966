const tipMessage = [
  "Remember to secure your harness and seat belt",
  "Notice a problem? Inform your manager immediately",
  'Remember to Press the "End Shift" button when your shift is over',
  "Drive Slow on corners. Fast turning causes dangerous tip-overs",
  "Honk at intersections to warn people around you",
  "Stop for pedestrians and stop signs",
  "Check blind spots for people when reversing",
  "Drive safely in aisles. Do not bump into racks",
];

export default tipMessage;
