import { inspectionTypes } from "./types";
import axios from "axios";
import config  from "../../../src/config";
import { apiCall } from "../../utils/apiCall";
const baseUrl =  config.baseUrl
const uploadImageLoading = (data) => ({
  type: inspectionTypes.UPLOAD_IMAGE_LOADING,
  data,
});

const uploadImageSuccess = (data) => ({
  type: inspectionTypes.UPLOAD_IMAGE_SUCCESS,
  data,
});

const uploadImageError = (data) => ({
  type: inspectionTypes.UPLOAD_IMAGE_ERROR,
  data,
});

const deleteImageLoading = (data) => ({
  type: inspectionTypes.DELETE_IMAGE_LOADING,
  data,
});

const deleteImageSuccess = (data) => ({
  type: inspectionTypes.DELETE_IMAGE_SUCCESS,
  data,
});

const postInspectionResponseLoading = (data) => ({
  type: inspectionTypes.POST_INSPECTION_RESPONSE_LOADING,
  data,
});

const postInspectionResponseSuccess = (data) => ({
  type: inspectionTypes.POST_INSPECTION_RESPONSE_SUCCESS,
  data,
});

const postInspectionResponseError = (data) => ({
  type: inspectionTypes.POST_INSPECTION_RESPONSE_ERROR,
  data,
});

const getChecklistLoading = (data) => ({
  type: inspectionTypes.GET_CHECKLIST_BY_ID_LOADING,
  data,
});

const getChecklistSuccess = (data) => ({
  type: inspectionTypes.GET_CHECKLIST_BY_ID_SUCCESS,
  data,
});

const getChecklistError = (data) => ({
  type: inspectionTypes.GET_CHECKLIST_BY_ID_ERROR,
  data,
});

export const uploadImage = (data, callbackAfterUpload) => {
  return async (dispatch, getState) => {
    dispatch(uploadImageLoading());
    const state = getState();

    let formData = new FormData();
    data.map((file) => formData.append("media", file));
    console.log(formData);
    apiCall(`/checklist/uploadMedia`, { method: "POST", data: formData, type: "file" })
      .then((resp) => {
        let media = [];
        resp?.data?.data?.map((data) => {
          media.push(data.filename);
        });
        callbackAfterUpload(media);
        dispatch(uploadImageSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(uploadImageError(err));
        console.log(err);
      });
  };
};

export const deleteImage = (fileName, callbackAfterDelete) => {
  return async (dispatch, getState) => {
    dispatch(deleteImageLoading());

    apiCall(`/checklist/deleteMedia/${fileName}`, { method: "GET", data: {} })
      .then((res) => {
        console.log(res);
        callbackAfterDelete(fileName);
        dispatch(deleteImageSuccess(res?.data));
      })
      .catch((err) => {
        // dispatch(setLoginByCredentialsError())
      });
  };
};

export const getChecklistById = (checklistId) => {
  return async (dispatch, getState) => {
    let state = getState();
    dispatch(getChecklistLoading());
    apiCall(`/checklist/${checklistId}`, { method: "GET" })
      .then((res) => {
        console.log(res);
        dispatch(getChecklistSuccess(res?.data?.data));
      })
      .catch((err) => {
        dispatch(getChecklistError());
      });
  };
};

export const postInspectionResponse = (data, callbackAfterInspect) => {
  return async (dispatch, getState) => {
    dispatch(postInspectionResponseLoading());
    //console.log(data, "---------- form data");
    apiCall(`/checklist/checklistResponse`, { method: "POST", data: data })
      .then((resp) => {
        console.log(resp);
        callbackAfterInspect(resp?.data?.message.toLowerCase() === "pass");
        dispatch(postInspectionResponseSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(postInspectionResponseError(err));
        console.log(err);
      });
  };
};

export const toStoreSelectedAsset = (data) => ({
  type: inspectionTypes.TO_STORE_SELECTED_ASSET,
  data,
});
