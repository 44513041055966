import { formActionTypes } from "./types";
import config from "../../../src/config";
import axios from "axios";
const baseUrl =  config.baseUrl
//To update edit state
export const setEditForm = (data) => ({
  type: formActionTypes.SET_EDIT_FORM,
  data: data,
});

//To update edit state 2
export const setEditFormSecond = (data) => ({
  type: formActionTypes.SET_EDIT_FORM_SECOND,
  data: data,
});
