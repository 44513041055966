import React from "react";
import { Trans } from "react-i18next";

import {
  WorksiteDeactivationList,
  ParaText,
  TableContainerDivUser,
  TableContainerDivAsset,
  RowsContainer,
  Column,
  RowDiv,
} from "./WorksiteDeactivatedList.styles";

const WorksiteDeactivationModal = ({ preDeactivatedWorksiteList }) => {
  const renderFullNameTable = () => {
    const users = preDeactivatedWorksiteList?.users;

    return (
      <TableContainerDivUser>
        <RowDiv isHeader={true}>
          <Column isHeader={true}>#</Column>
          <Column isHeader={true}>
            <Trans>Full Name</Trans>
          </Column>
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {users && users.length > 0 ? (
            users.map((user, index) => (
              <RowDiv key={user._id}>
                <Column>{index + 1}</Column>
                <Column textleft={"left"}>
                  <Trans>{user.fullName}</Trans>
                </Column>
              </RowDiv>
            ))
          ) : (
            <RowDiv>
              <Column></Column>
              <Column>
                <Trans> No users found</Trans>
              </Column>
            </RowDiv>
          )}
        </RowsContainer>
      </TableContainerDivUser>
    );
  };

  const renderAssetNameTable = () => {
    const assets = preDeactivatedWorksiteList?.assets;

    return (
      <TableContainerDivAsset>
        <RowDiv isHeader={true}>
          <Column isHeader={true}>#</Column>
          <Column isHeader={true}>
            <Trans>Asset Name</Trans>
          </Column>
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {assets && assets.length > 0 ? (
            assets.map((asset, index) => (
              <RowDiv key={asset._id}>
                <Column>{index + 1}</Column>
                <Column>
                  <Trans key={asset._id}>{asset.assetName}</Trans>
                </Column>
              </RowDiv>
            ))
          ) : (
            <RowDiv>
              <Column></Column>
              <Column>
                <Trans> No assets found</Trans>
              </Column>
            </RowDiv>
          )}
        </RowsContainer>
      </TableContainerDivAsset>
    );
  };

  return (
    <WorksiteDeactivationList>
      <ParaText>
        <Trans>
          Deactivating this worksite will remove access for all the users and assets mentioned below from the
          SIERA.AI systems. Are you sure you want to continue?
          {/* {siteId} */}
        </Trans>
      </ParaText>
      <>
        {renderFullNameTable()}
        {renderAssetNameTable()}
      </>
    </WorksiteDeactivationList>
  );
};

export default WorksiteDeactivationModal;
