import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import TabBar from "../../../components/TabBar";
import BreadCrumb from "../../../components/BreadCrumb.component";
import UserProfile from "./UserProfile";
import AccountProfile from "./Account";
import { connect } from "react-redux";

const Settings = ({ isAccountOwner, isSuperAdmin }) => {
  const match = useRouteMatch();

  const userProfileTab = [
    {
      name: "User Profile",
      route: "user-profile",
    },
  ];

  const userAndAccountProfile = [
    {
      name: "Account Profile",
      route: "account-profile",
    },
    {
      name: "User Profile",
      route: "user-profile",
    },
  ];

  return (
    <div className="settings">
      <BreadCrumb />
      <TabBar
        tabs={isAccountOwner && !isSuperAdmin ? userAndAccountProfile : userProfileTab}
        url={match.url}
      />
      <Switch>
        {isAccountOwner && <Route path={`${match.url}/account-profile`} component={AccountProfile} />}
        <Route path={`${match.url}/user-profile`} component={UserProfile} />
        <Redirect to={`${match.url}/${isAccountOwner ? "account-profile" : "user-profile"}`} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAccountOwner: state.user.isAccountOwner,
  isSuperAdmin: state.user.isSuperAdmin,
});

export default connect(mapStateToProps, null)(Settings);
