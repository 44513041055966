import styled from "styled-components";
export const DivContainer = styled.div`
  height: calc(100vh - 114px);
  @media (max-width: 768px) {
    height: auto;
  }
`;
export const BarFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const InspectText = styled.div`
  color: #919191;
  font-size: 20px !important;
`;
export const InspectAssetDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 31vh);
  border: 1px solid #ccc;
  border-radius: 4px;
  p {
    .clickDesktop,
    .tapMobile {
      font-family: "Open Sans", sans-serif;
      font-size: 14px !important;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    }
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  }
`;

/*
export const Styles = (theme) => ({

  title: {
    textAlign: "left",
    fontSize: 24,
    color: "#323232",
    backgroundColor: "#F2F2F2",
    padding: "14px 25px",
  },
  inspectionWrapper: {
    border: "1px solid #C6C6C6",
    height: "calc(100vh - 220px)",
    borderRadius: 8,
    clear: "both",
  },
  inspectText: {
    color: "#919191",
    fontSize: 20,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    padding: "5px 6px",
    borderTop: "1px solid #dee2e6",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  status: {
    width: "14px",
    height: "14px",
    borderRadius: 50,
    backgroundColor: "#8BC34A",
    marginRight: 14,
  },
  name: {
    fontSize: 16,
    color: "#323232",
    flex: 1,
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inspect: {
    color: theme.palette.common.white,
    backgroundColor: "#0D5FBE",
    borderRadius: "4px",
    padding: "6px 10px",
    textDecoration: "none",
    fontFamily: "Open Sans",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  listItems: {
    marginTop: 30,
  },
  formControl: {
    marginTop: 15,
  },
  assetLabel: {
    fontSize: 20,
  },
  menuItem: {
    fontSize: 22,
    textAlign: "left",
  },
  barFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "10px",
  },
  searchField: {
    marginBottom: 10,
    "& div": {
      borderRadius: 50,
    },
    "& input": {
      padding: "10px 12px",
      backgroundColor: "#fff",
    },
  },
  inspectionCover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 31vh)",
    border: "1px solid #CCC",
    borderRadius: 4,
  },
});


*/
