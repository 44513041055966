import React from "react";
import { Row } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AssetActionButtons = ({
  editForm,
  editFormSecond,
  setEditAlertModal,
  setAddAssetModal,
  setWorksiteBulkUploadShow,
  isPlanValidToExport,
  generateCSVData,
  headersData,
  showExportData,
  handleDownloadCSV,
  setModalShow,
  setQrcodeFilterBy,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Row className="m-0 p-0">
      {/* Manage Asset Types Button */}
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_TYPE_MANAGEMENT"} actionName={"view"}>
        <div className="mr-2 pl-0">
          <Button
            iconClass="manage-asset-types"
            label={t("Manage asset types")}
            onClick={() =>
              editForm && editFormSecond ? history.push("/app/management/assettype") : setEditAlertModal(true)
            }
            showIcon
            buttonType="secondary"
          />
        </div>
      </PermissionCheck>

      {/* Add Asset and Bulk Upload Buttons */}
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"add"}>
        <div className="mr-2 pl-0">
          <Button
            iconClass="add"
            label={t("Add Asset")}
            onClick={() => (editForm && editFormSecond ? setAddAssetModal(true) : setEditAlertModal(true))}
            showIcon
            buttonType="secondary"
          />
        </div>

        <div className="mr-2 pl-0">
          <Button
            iconClass="bulk-upload"
            label={t("Bulk Upload")}
            onClick={() =>
              editForm && editFormSecond ? setWorksiteBulkUploadShow(true) : setEditAlertModal(true)
            }
            showIcon
            buttonType="secondary"
          />
        </div>
      </PermissionCheck>

      {/* Export Data Button */}
      <div className="mr-2 pl-0">
        {isPlanValidToExport() ? (
          <CSVLink
            data={generateCSVData ? generateCSVData : []}
            headers={headersData}
            filename={"Asset-Ledger.csv"}
          >
            <Button
              disabled={!showExportData}
              iconClass="download"
              label={t("Export Data")}
              showIcon
              buttonType="secondary"
              onClick={editForm && editFormSecond ? handleDownloadCSV : setEditAlertModal}
            />
          </CSVLink>
        ) : (
          <Button
            iconClass="download"
            label={t("Export Data")}
            onClick={() => (editForm && editFormSecond ? setModalShow(true) : setEditAlertModal(true))}
            showIcon
            buttonType="secondary"
          />
        )}
      </div>

      {/* Print QR Codes Button */}
      <div className="mr-2 pl-0">
        <Button
          iconClass="print"
          label={t("Print Qr Codes")}
          onClick={() => {
            if (editForm) {
              setQrcodeFilterBy("assets");
              history.push("/app/management/qrcode");
            } else {
              setEditAlertModal(true);
            }
          }}
          showIcon
          buttonType="secondary"
        />
      </div>
    </Row>
  );
};

export default AssetActionButtons;
