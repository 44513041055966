//TODO: When time permits optimize this file and remove unused code
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import MultiCardsWorksite from "./MultiCardsWorksite.component";
import WorksiteOverviewTab from "./WorksiteOverviewTab.component";
import toasterConstant from "../../../constant/messages/toaster.json";
import SnackBar from "../SnackBar";
import {
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
  preDeactivatedWorksite,
  deactivateWorksite,
} from "../../../redux/worksites/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import {
  ManagementRightBottomWrapper,
  WorksiteTopHeader,
  DetailsWrapper,
  WorksiteDataDescp,
  WorksiteHeadDetail,
  StatusMobileView,
  StatusDesktopView,
  NoSectionStyle,
} from "./WorksiteOverview.styles.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const { worksite_name_update, worksite_activated_message, worksite_deactivated_message } = toasterConstant;

const WorksiteOverview = ({
  loginDetails,
  selectWorksiteOverview,
  statusSuccess,
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
  updateNameLoading,
  updateNameSuccess,
  filterAccountId,
  isSuperAdmin,
  WorksiteLoading,
  WorksiteDetailLoading,
  editForm,
  setEditAlertModal,
  accountListById,
  permissions,
  preDeactivatedWorksite,
  deactivateWorksite,
  preDeactivatedWorksiteList,
  deactivateWorksiteSuccess,
  currentTimeZone,
}) => {
  const { name, _id, status, createdAt } = selectWorksiteOverview
    ? selectWorksiteOverview
    : {
        name: "",
        createdDate: new Date(),
        accountId: "",
        _id: "",
        s3Enable: false,
        status: true,
      };
  const [statusButton, setStatusButton] = useState(status);
  const [WorkSiteName, setWorkSiteName] = useState(selectWorksiteOverview?.name || "");
  //const [IsEdit, setIsEdit] = useState(false);
  //const [IsSubmitted, setIsSubmitted] = useState(false);
  //const [disableSwitch, setDisableSwitch] = useState(false);
  const changeStatus = (e) => {
    if (editForm) {
      let id = e.target.id;
      let status = e.target.checked;
      // updateSiteStatus(e);

      if (!status) {
        preDeactivatedWorksite(id, selectWorksiteOverview?.accountId?._id);
      } else {
        worksiteStatusUpdate(id, status);
      }
      //setDisableSwitch(true);

      //setStatusButton(!statusButton);
    } else {
      setEditAlertModal(true);
    }
  };

  useEffect(() => {
    if (deactivateWorksiteSuccess) setStatusButton(false);
  }, [deactivateWorksiteSuccess]);

  useEffect(() => {
    if (statusSuccess) setStatusButton(true);
  }, [statusSuccess]);

  const currentPermissionObj = permissions?.[0]?.permissions?.find(
    (permissionObj) => permissionObj.name === "WORKSITE_MANAGEMENT"
  );
  const worksitesEditPermission = currentPermissionObj?.action["edit"];

  useEffect(() => {
    setStatusButton(status);
    setWorkSiteName(name);
  }, [selectWorksiteOverview]);

  // useEffect(() => {
  //   if (updateNameSuccess) {
  //     setIsEdit(false);
  //   }
  //   //reset state on unload of the page
  //   return () => {
  //     resetWorksitesState();
  //   };
  // }, [updateNameSuccess]);

  useEffect(() => {
    if (updateNameSuccess) {
      // Fetch the updated worksite overview data or update the local state
      setTimeout(() => {
        resetWorksitesState(); // Reset the state after the update
      }, 3000);
    }
  }, [updateNameSuccess, resetWorksitesState]);

  useEffect(() => {
    if (selectWorksiteOverview) {
      setStatusButton(status);
    }
  }, [selectWorksiteOverview, status]);

  // const handleCloseSnackBar = () => {
  //   resetWorksitesState();
  // };
  const { t } = useTranslation();
  const handleMessage = () => {
    if (updateNameSuccess) {
      return t(worksite_name_update);
    } else if (statusSuccess && statusButton) {
      return t(worksite_activated_message);
    } else if (statusSuccess && !statusButton) {
      return t(worksite_deactivated_message);
    } else if (deactivateWorksiteSuccess) {
      return t(worksite_deactivated_message);
    }
  };

  const [showSnackBar, setShowSnackBar] = useState(false);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, []);

  const handleCloseSnackBar = () => {
    resetWorksitesState();
    setShowSnackBar(false);
    //setDisableSwitch(false);
  };

  useEffect(() => {
    if (handleMessage()) {
      setShowSnackBar(true);
    }
  }, [updateNameSuccess, statusSuccess, statusButton, deactivateWorksiteSuccess]);

  const handleWorksiteNameChange = (newName) => {
    setWorkSiteName(newName);
  };

  useEffect(() => {
    if (selectWorksiteOverview) {
      setWorkSiteName(selectWorksiteOverview.name); // Set the initial name
    }
  }, [selectWorksiteOverview]);

  const truncateText = (text, limit = 24) =>
    text && text.length > limit ? `${text.substring(0, limit)}...` : text;

  const WorksiteStatusSwitch = ({}) => {
    return (
      <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="deactivate">
        <>
          <p className="m-0  statusStyle">
            <Trans>Status</Trans>
          </p>

          <SwitchComponent id={_id} onChange={changeStatus} checked={statusButton} />
        </>
      </PermissionCheck>
    );
  };

  const timeZone = currentTimeZone || createdAt?.split(" ")?.[0];

  return (
    <ManagementRightBottomWrapper>
      <>
        {(WorksiteLoading || WorksiteDetailLoading) && (
          <Row>
            <Col lg={5}>
              <Skeleton height={40} />
              <Skeleton height={10} count={1} />
            </Col>
            <Col lg={5}>
              <Skeleton height={50} width={200} />
            </Col>
            <Col lg={2}>
              <Skeleton height={60} width={60} />
            </Col>
          </Row>
        )}
        {!WorksiteLoading && !WorksiteDetailLoading && selectWorksiteOverview && (
          <Row>
            <Col lg={5}>
              <WorksiteTopHeader>
                <DetailsWrapper>
                  <WorksiteHeadDetail title={WorkSiteName}>{truncateText(WorkSiteName)}</WorksiteHeadDetail>

                  {!isSuperAdmin && (
                    <WorksiteDataDescp title={loginDetails ? loginDetails.accountNo : ""}>
                      <Trans>Account ID</Trans>: {loginDetails ? loginDetails.accountNo : ""}
                    </WorksiteDataDescp>
                  )}

                  <WorksiteDataDescp>
                    <Trans>Added On</Trans>:
                    {createdAt && moment(createdAt).utcOffset(timeZone).format(" MMMM DD YYYY")}
                  </WorksiteDataDescp>
                  {isSuperAdmin && selectWorksiteOverview?.accountId?._id && (
                    <WorksiteDataDescp>
                      <Trans>AccountLabel</Trans>{" "}
                      {accountListById?.[selectWorksiteOverview?.accountId._id]?.companyName}
                    </WorksiteDataDescp>
                  )}
                </DetailsWrapper>

                <StatusMobileView>
                  <WorksiteStatusSwitch />
                </StatusMobileView>
              </WorksiteTopHeader>
            </Col>

            <Col lg={5}>
              <MultiCardsWorksite data={selectWorksiteOverview} />
            </Col>

            <Col lg={2}>
              <StatusDesktopView>
                <WorksiteStatusSwitch />
              </StatusDesktopView>
            </Col>
          </Row>
        )}

        {selectWorksiteOverview && !WorksiteDetailLoading && (
          <Row>
            <WorksiteOverviewTab
              OverviewDetails={selectWorksiteOverview}
              statusButton={statusButton && worksitesEditPermission}
              filterAccountId={filterAccountId}
              handleWorksiteNameChange={handleWorksiteNameChange}
            />
          </Row>
        )}
      </>
      {!selectWorksiteOverview && !WorksiteLoading && !WorksiteDetailLoading && (
        <NoSectionStyle>
          <p>
            <Trans>NO_WORKSITE_IN_ACCOUNT</Trans>
          </p>
          <div className="clickDesktop">
            <Trans>CLICK_ADD_WORKSITE_BUTTON</Trans>
          </div>
          <div className="tapMobile">
            <Trans>CLICK_ADD_WORKSITE_BUTTON_MOB</Trans>
          </div>
        </NoSectionStyle>
      )}
      {showSnackBar && (
        <SnackBar
          isSuccess={updateNameSuccess || (statusSuccess && statusButton)}
          isFailed={!statusButton || deactivateWorksiteSuccess}
          label={handleMessage()}
          handleClose={() => handleCloseSnackBar()}
        />
      )}
    </ManagementRightBottomWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  selectWorksiteOverview: state.worksites.WorksiteOverview,
  statusMessage: state.worksites.StatusMessage,
  statusSuccess: state.worksites.StatusSuccess,
  statusError: state.worksites.StatusError,
  updateNameLoading: state.worksites.UpdateNameLoading,
  updateNameSuccess: state.worksites.UpdateNameSuccess,
  isSuperAdmin: state.user.isSuperAdmin,
  WorksiteLoading: state.worksites.WorksiteLoading,
  WorksiteDetailLoading: state.worksites.worksiteDetailLoading,
  editForm: state.forms.editForm,
  accountListById: state.dropdownFilters.accountListById,
  permissions: state.user?.loginDetails?.userData?.permissions,
  preDeactivatedWorksiteList: state.worksites.preDeactivatedWorksiteList,
  deactivateWorksiteSuccess: state.worksites.deactivateWorksiteSuccess,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  worksiteNameUpdate: (id, name) => dispatch(worksiteNameUpdate(id, name)),
  worksiteStatusUpdate: (id, status) => dispatch(worksiteStatusUpdate(id, status)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  preDeactivatedWorksite: (siteId, accountId) => dispatch(preDeactivatedWorksite(siteId, accountId)),
  deactivateWorksite: (siteId, accountId) => dispatch(deactivateWorksite(siteId, accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteOverview);
