import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ApplyToAllWrapper,
  StyledInformationIcon,
  RowWrapper,
  ColWrapper,
  ButtonReset,
  ColDiv,
} from "./ActionButtons.styles";
import Checkbox from "../../../components/Checkbox/Checkbox.component";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>
      SELECT_THIS_OPTION_WHEN_YOU_WANT_TO_APPLY_THese_SETTINGS_TO_YOUR_ENTIRE_ACCOUNT_PLEASE_NOTE_THAT_ANY_EXISTING_SETTINGS_WILL_GET_OVERRIDEN_IF_YOU_SELECT_APPLY_TO_ALL
    </Trans>
  </Tooltip>
);

const ActionButtons = (props) => {
  const {
    handleApplyToAll,
    applyToAll,
    updatableChanges,
    handleUpdate,
    handleCancel,
    isLoading,
    showAppyToAll,
    applyToAllLabel,
    handleReset,
    showResetButton,
  } = props;
  const { t } = useTranslation();

  return (
    <PermissionCheck section="DASHBOARD" permissionName={"SYSTEM_SETTINGS"} actionName="edit">
      <RowWrapper>
        <ColWrapper>
          <ButtonReset>
            {showResetButton && (
              <Button
                label={t("RESET TO DEFAULT")}
                onClick={handleReset}
                showIcon={false}
                buttonType="secondary"
              />
            )}
          </ButtonReset>
          {showAppyToAll && (
            <ApplyToAllWrapper>
              <Checkbox
                label={applyToAllLabel || t("Apply to All")}
                onClick={handleApplyToAll}
                checked={!!applyToAll}
              />
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <span>
                  <StyledInformationIcon />
                </span>
              </OverlayTrigger>
            </ApplyToAllWrapper>
          )}
        </ColWrapper>
        <ColDiv>
          <>
            {!isLoading && (
              <Button label={t("Cancel")} onClick={handleCancel} showIcon={false} buttonType="ghost" />
            )}
            {updatableChanges ? (
              <Button
                label={
                  isLoading ? (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  ) : (
                    t("Save")
                  )
                }
                onClick={!isLoading && handleUpdate}
                showIcon
                iconClass="save"
                buttonType="save"
              />
            ) : (
              <Button label={t("Update")} disabled showIcon={false} buttonType="primary" />
            )}
          </>
        </ColDiv>
      </RowWrapper>
    </PermissionCheck>
  );
};

export default ActionButtons;
