import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../../../assets/logo/1.svg";
import { selectLoginDetails } from "../../../redux/user/user.selectors";
import Button from "../../../components/Button/Button.component";
import LanguageSelectorDropdown from "../../Dashboard/LanguageSelectorDropdown/LanguageSelectorDropdown";
import { NavbarStyledCss } from "./Header.styles";

// Header Component
const Header = ({ loginDetails }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const pathArray = history.location.pathname.split("/");
  const isResetPassword =
    history.location.pathname.includes("reset-password") ||
    history.location.pathname.includes("expired-password");

  useEffect(() => {
    if (loginDetails) {
      loginDetails.success && history.push("/app/dashboard");
    }
  }, [loginDetails]);

  return (
    <NavbarStyledCss>
      <Navbar className="stick-topNAV" sticky="top" collapseOnSelect expand="lg">
        {/* Logo */}
        <Navbar.Brand className="justify-content-end" href="#home">
          <Link to="/">
            <Logo style={{ height: "28px" }} />
          </Link>
        </Navbar.Brand>

        {/* Request a Demo */}
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav"> */}
        <Nav className="navBarLang">
          {(!pathArray[2] || isResetPassword) && <LanguageSelectorDropdown />}
          <Nav.Item>
            {(!pathArray[2] || isResetPassword) && (
              <Button
                label={t("REQUEST A DEMO")}
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
                }}
                showIcon={false}
                buttonType="primary"
              />
            )}
          </Nav.Item>
        </Nav>
        {/* </Navbar.Collapse> */}
      </Navbar>
    </NavbarStyledCss>
  );
};

// Subscribing the selectors for displaying data
const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
});

export default connect(mapStateToProps, null)(Header);
