import React from "react";
import DropdownComponent from "../../../../../../components/DropdownComponent/Dropdown.component";

const CustomDropdown = ({ options, ...rest }) => {
  const finalOptions = Array.isArray(options) ? options : [];

  return <DropdownComponent options={finalOptions} {...rest} />;
};

export default CustomDropdown;
