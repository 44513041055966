import styled from "styled-components";

export const UserTypeOverviewWrapper = styled.div`
  margin: auto;
  width: 100%;
`;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} 100%
  );
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  line-height: 45px;
  font-size: 22px !important;
  border-radius: 4px;
  padding: 0px 15px;
  text-align: left;
`;

export const BodyDetails = styled.div`
  width: 100%;
  margin: 0 auto 20px;
`;

export const UserTypePermissionDiv = styled.div``;

export const UserTypeTableDescription = styled.div`
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? 'calc(100vh - 543px)' : 'calc(100vh - 480px)')};
  overflow-y: scroll;
  @media (max-width: 1100px) {
    overflow: hidden auto;
    max-height: calc(100vh - 245px);
    height: auto;
  }
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 50% 12% 12% 12% 14%;
  line-height: 32px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
    height: auto;
    line-height: 24px;

    .headingRow {
      font-size: 16px !important;
      font-weight: 500;
      padding: 8px 14px;
      border-radius: 4px;
    }
    .mobileViewColTop {
      display: block;
      width: 100%;
      font-size: 16px !important;
      font-weight: 500;
      padding: 0px 15px; 
    }
    .mobileViewCol {
      display: flex;
      align-items: center;
      justify-content: normal;
      padding: 0px 15px; 
    }
  }
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  :nth-last-child {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ isHeader }) => (isHeader ? "18px" : "14px")} !important;
  border-top-left-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  border-top-right-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ textleft }) => (textleft ? textleft : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  text-transform: capitalize;

  @media (max-width: 1100px) {
    font-size: ${({ isHeader }) => (isHeader ? "15px" : "14px")} !important;
  }

  :nth-child(5) {
    padding-right: 10px;
  }
`;
export const MobileOnlyHeader = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    flex: 1;
  }
`;

export const DesktopOnlyHeader = styled(RowDiv)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  /*height: calc(100vh - 450px);*/

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 1100px) {
    margin-bottom: 50px;
    display: ${({ isHeaderM }) => (isHeaderM ? "none" : "grid")};
  }
`;
