import React from "react";
import { Row } from "react-bootstrap";
import ChecklistOverview from "./ChecklistOverview.component";
import ChecklistAddModal from "./Modals/ChecklistAddModal.component";

const Checklists = ({ setEditChecklist, editChecklist, filterAccountId, setFilterAccountId }) => {
  return (
    <>
      <div className="m-0 p-0">
        <Row>{/* <Button>Add Checklist</Button> */}</Row>
      </div>
      <div className="m-0 p-0">
        <ChecklistOverview
          editChecklist={editChecklist}
          setEditChecklist={setEditChecklist}
          filterAccountId={filterAccountId}
          setFilterAccountId={setFilterAccountId}
        />
      </div>
      <ChecklistAddModal editChecklist={editChecklist} />
    </>
  );
};

export default Checklists;
