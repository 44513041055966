import { dropdownTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_LOADING:
      return {
        ...state,
        accountListLoading: true,
      };
    case dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.data,
        accountListLoading: false,
      };
    case dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_LOADED:
      return {
        ...state,
        accountListLoading: false,
      };
    case dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_BY_ID:
      return {
        ...state,
        accountListById: action.data,
      };
    case dropdownTypes.GET_DROPDOWN_WORKSITE_LIST:
      return {
        ...state,
        worksiteList: action.data,
      };
    case dropdownTypes.GET_DROPDOWN_ASSET_LIST:
      return {
        ...state,
        assetList: action.data,
      };
    case dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST:
      return {
        ...state,
        assetTypeList: action.data,
      };

    case dropdownTypes.GET_DROPDOWN_USER_LIST:
      return {
        ...state,
        userList: action.data,
      };

    case dropdownTypes.GET_DROPDOWN_PRODUCT_LIST:
      return {
        ...state,
        productList: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_ACCOUNT_ID:
      return {
        ...state,
        worksiteId: "all",
        assetId: "all",
        assetTypeId: "all",
        dropdownUserAccessLevel: "all",
        accountId: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_WORKSITE_ID:
      //alert('dfdsf')
      return {
        ...state,
        worksiteId: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_ASSET_ID:
      return {
        ...state,
        assetId: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_ASSET_TYPE_ID:
      return {
        ...state,
        assetTypeId: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_USER_ACCESS_LEVEL:
      return {
        ...state,
        dropdownUserAccessLevel: action.data,
      };

    case dropdownTypes.SET_QRCODE_FILTER_BY:
      return {
        ...state,
        qrCodeFilterBy: action.data,
      };

    case dropdownTypes.RESET_ALL_DROPDOWN_FILTER:
      return {
        ...state,
        accountId: "all",
        worksiteId: "all",
        assetId: "all",
        assetTypeId: "all",
        dropdownUserAccessLevel: "all",
        qrCodeFilterBy: "assets",
      };
    case dropdownTypes.SET_DROPDOWN_USER_TYPE_ID:
      return {
        ...state,
        dropdownUserTypeId: action.data,
      };

    case dropdownTypes.SET_DROPDOWN_USER_ID:
      return {
        ...state,
        dropdownUserId: action.data,
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_ACCOUNT_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          accountId: action.data,
        },
      };

    case dropdownTypes.GET_DROPDOWN_WORKSITE_LIST_FOR_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          worksiteList: action.data,
        },
      };

    case dropdownTypes.RESET_DROPDOWN_WORKSITE_LIST:
      return {
        ...state,
        settings: {
          ...state.settings,
          worksiteList: [],
        },
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_WORKSITE_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          worksiteId: action.data,
        },
      };

    case dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetTypeList: action.data,
          assetTypeListLoading: false,
        },
      };

    case dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_LOADING:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetTypeListLoading: true,
        },
      };

    case dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetTypeListLoading: false,
        },
      };

    case dropdownTypes.RESET_DROPDOWN_ASSET_TYPE_LIST:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetTypeList: [],
        },
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_ASSET_TYPE_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetTypeId: action.data,
        },
      };

    case dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetList: action.data,
          assetListLoading: false,
        },
      };
    case dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_LOADING:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetListLoading: true,
        },
      };
    case dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetListLoading: false,
        },
      };
    case dropdownTypes.RESET_DROPDOWN_ASSET_LIST:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetList: [],
        },
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_ASSET_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          assetId: action.data,
        },
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_USER_TYPE_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          userTypeId: action.data,
        },
      };

    case dropdownTypes.GET_DROPDOWN_USERS_BY_USER_TYPE_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          userListByUserType: action.data,
        },
      };

    case dropdownTypes.SET_SETTINGS_DROPDOWN_USER_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          userId: action.data,
        },
      };
    case dropdownTypes.SET_DURATION:
      return {
        ...state,
        duration: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};
