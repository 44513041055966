import styled from "styled-components";
import { InformationIcon } from "../../../../../assets";

export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const PopoverComponentWrapper = styled.div`
  font-size: 20px;
`;

export const AddShiftDurationButton = styled.div`
  float: left;
  & button {
    border-radius: 0.2rem;
    border: 1px solid #0d5fbe;
    background-color: #ffffff;
    color: #0d5fbe;
    font-family: Kanit;
    font-style: normal;
    font-size: 14px !important;
    height: 38px;
    letter-spacing: 0.5px;
    box-shadow: none;
    &:hover {
      background-color: #0d5fbe;
      color: #fff;
    }
  }
`;

export const BackButtonComppnent = styled.div`
  float: right;

  & button {
    color: #919191 !important;
    width: 120px;
    height: 42px;
    margin-right: 10px;
    font-family: Kanit;
    font-style: normal;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:disabled {
      display: none;
    }
  }
`;

export const SubmitButtonComppnent = styled.div`
  float: right;
  & button {
    text-transform: uppercase;
    font-family: Kanit;
    background-color: #11a70b;
    &:hover {
      background-color: #1e6c1b;
    }
  }
`;

export const NextButtonComppnent = styled.div`
  float: right;
  & button {
    text-transform: uppercase;
    font-family: Kanit;
    background-color: #0d5fbe;
    &:hover {
      background-color: #5387dc;
    }
  }
`;

export const StyledButton = styled.button`
  ${({ disabled }) => disabled && "background-color:#ccc !important; cursor: not-allowed"}
`;
