import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginDetails } from "../../../redux/user/user.selectors";
import { selectAssetTypeOverview } from "../../../redux/assetType/assetType.selector";
import { assetTypeList, defaultAssetTypeOverview } from "../../../redux/assetType/assetType.action";
import { Trans, useTranslation } from "react-i18next";
import {
  AssetTypeDetailsDiv,
  AssetTypeDetailsTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  RadioWrapper,
  MobileViewText,
  ColumnReorder,
  RadioWrapperDiv,
} from "./ViewAssetTypeOverviewTable.component.styles";
import RadioButton from "../../../components/RadioButton/Radio.component";

function RadioYes(props) {
  const { name, handleRadio, id, AssetAttributeRequired, isDisabled } = props;
  const { t } = useTranslation();

  return (
    <RadioWrapperDiv>
      <RadioButton
        name={name}
        onClick={handleRadio}
        checked={AssetAttributeRequired}
        size="small"
        disabled={isDisabled}
        label={t("Yes")}
        id={`${name}_yes`}
        value="Yes"
      />
      <RadioButton
        name={name}
        onClick={handleRadio}
        checked={!AssetAttributeRequired}
        size="small"
        disabled={isDisabled}
        label={t("Optional")}
        id={`${name}_optional`}
        value="optional"
      />
      {/* <FormControlLabel
        value="Yes"
        disabled={isDisabled}
        control={
          <RadioButton name={name} onClick={handleRadio} checked={AssetAttributeRequired} size="small" />
        }
        label={t("Yes")}
      />
      <FormControlLabel
        value="optional"
        disabled={isDisabled}
        control={
          <RadioButton name={name} onClick={handleRadio} checked={!AssetAttributeRequired} size="small" />
        }
        label={t("Optional")}
      /> */}
    </RadioWrapperDiv>
  );
}

function ViewAssetTypeOverviewTable({ selectAssetTypeOverview, isSuperAdmin, typeAttributes }) {
  return (
    <AssetTypeDetailsDiv isSuperAdmin={isSuperAdmin}>
      <AssetTypeDetailsTableDescription>
        <RowDiv isHeader={true}>
          <Column isHeader={true} textleft={"left"}>
            <Trans>Field Name</Trans>
          </Column>
          <Column isHeader={true} textleft={"left"}>
            <Trans>Required</Trans>
          </Column>
          <Column isHeader={true} textleft={"left"}>
            <Trans>Description</Trans>
          </Column>
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {typeAttributes?.map((data, i) => (
            <RowDiv>
              <Column textleft={"left"} className="firstMobileCss">
                <Trans>{data?.AssetAttributeName}</Trans>
              </Column>

              <ColumnReorder textleft={"left"}>
                <MobileViewText>Required</MobileViewText>
                <RadioWrapper>
                  <RadioYes
                    name={i}
                    isDisabled={true}
                    handleRadio={() => {}}
                    AssetAttributeRequired={data?.AssetAttributeRequired}
                  />
                </RadioWrapper>
              </ColumnReorder>
              <ColumnReorder textleft={"left"}>{data?.AssetAttributeDescription}</ColumnReorder>
            </RowDiv>
          ))}
        </RowsContainer>
      </AssetTypeDetailsTableDescription>
    </AssetTypeDetailsDiv>
  );
}

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
  selectAssetTypeOverview: selectAssetTypeOverview,
});

const mapDispatchToProps = (dispatch) => ({
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypeOverviewTable));
