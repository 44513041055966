import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  setAddAssetModal,
  setAddSiteModal,
  setAddUserModal,
  setAddAssetTypeModal,
  setModalWaitingToOpen,
  setAddAssetModalRedirect,
} from "../../redux/modals/action";

import { updateActiveNavItem } from "../../redux/sideMenu/menu.action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import validationConstant from "../../constant/messages/validation.json";
import PermissionCheck from "../../components/PermissionCheck/PermissionCheck";
import { Trans, useTranslation } from "react-i18next";
import DropdownComponent, { components } from "./Dropdown.component";
import { Asterisk, OptionDiv } from "./Dropdown.component.styles";

const isFirstCharacterVowel = (character) => {
  character = character?.toLowerCase?.();
  if (character == "a" || character == "e" || character == "i" || character == "o" || character == "u") {
    return true;
  }
  return false;
};

const DropdownFilterWrapper = ({
  id,
  label,
  startAdornment,
  onChange,
  value,
  isValid,
  isInvalid,
  disabled,
  feedback,
  placeholder,
  type,
  options,
  inputRef,
  fullNameInputRef,
  accountNameInputRef,
  filter,
  isValidationError,
  isDisabled,
  setAddAssetModal,
  setAddSiteModal,
  setAddAssetTypeModal,
  addAssetModal,
  addSiteModal,
  setAddUserModal,
  addUserModal,
  worksiteLoading,
  worksiteLoadingSuccess,
  setModalWaitingToOpen,
  updateActiveNavItem,
  activeNavItem,
  isSuperAdmin,
  isAccountOwner,
  setAddAssetModalRedirect,
  showAddItem = true,
  bordered,
  showLabel,
  sortData,
  required,
  ...props
}) => {
  useEffect(() => {
    if (addAssetModal) {
      setModalWaitingToOpen("Asset");
    } else if (addUserModal) {
      setModalWaitingToOpen("User");
    }
  }, [addAssetModal, addSiteModal, worksiteLoading]);

  const [dropdownOption, setDropdownOption] = useState([]);

  useEffect(() => {
    sortData &&
      options?.length > 0 &&
      options.sort((a, b) => {
        let fa = a?.label?.toLowerCase(),
          fb = b?.label?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    setDropdownOption(options);
  }, [options, sortData]);

  const history = useHistory();
  const { t } = useTranslation();
  const MenuList = (props) => {
    const renderOption = (onClick, icon, text) => (
      <OptionDiv onClick={onClick}>
        {icon && <icon className={`icon ${icon}`} />}
        {text && t(text)}
      </OptionDiv>
    );

    switch (label) {
      case "Asset Type":
        return (
          <components.MenuList {...props}>
            {props.options.length === 0 && <OptionDiv>{t(validationConstant.no_asset_type)}</OptionDiv>}
            <PermissionCheck section="DASHBOARD" permissionName={"ASSET_TYPE_MANAGEMENT"} actionName="add">
              {renderOption(
                () => {
                  setAddAssetTypeModal(true);
                  history.push("/app/management/assettype");
                },
                "icon icon-add-asset-type-op3",
                "AssetTypeAddAssetText"
              )}
            </PermissionCheck>
            {props.children}
          </components.MenuList>
        );
      case "Worksite":
        return (
          <components.MenuList {...props}>
            {props.options.length === 0 && <OptionDiv>{t(validationConstant.no_worksite)}</OptionDiv>}
            <PermissionCheck section="DASHBOARD" permissionName={"WORKSITE_MANAGEMENT"} actionName="add">
              {(isAccountOwner || isSuperAdmin) &&
                renderOption(
                  () => {
                    if (activeNavItem !== "dashboard") updateActiveNavItem("worksites");
                    setAddAssetModal(false);
                    setAddUserModal(false);
                    setAddSiteModal(true);
                  },
                  "icon icon-worksites",
                  "Add Worksite"
                )}
            </PermissionCheck>
            {props.children}
          </components.MenuList>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DropdownComponent
        label={label}
        options={dropdownOption}
        placeholder={
          isFirstCharacterVowel(label?.charAt?.(0))
            ? t(`Select an ${label}`)
            : label === "Worksite Manager"
              ? t(`Choose site manager`)
              : t(`Select a ${label}`)
        }
        isError={isValidationError && isValidationError.includes(label)}
        errorMessage={
          isValidationError && isValidationError.includes(label) && <Trans>This field is required.</Trans>
        }
        isDisabled={disabled}
        onChange={(value) => {
          onChange(value && value._id ? value._id : value);
        }}
        value={dropdownOption && dropdownOption.filter((option) => option._id === value)}
        isSearchable={true}
        readOnly={false}
        components={{
          ...((label === "Asset Type" || label === "Worksite") && showAddItem && { MenuList }),
        }}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => (option ? option["_id"] : "")}
        noOptionsMessage={label === "Asset Type" ? () => "" : () => <Trans>No Options</Trans>}
        required={required}
        {...props}
      />
      {required && <Asterisk>*</Asterisk>}
      {["Account", "SIERA.AI Product", "Worksite", "Asset Type"].includes(label) && (
        <Asterisk className="astr">*</Asterisk>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addAssetModal: state.modals.addAssetModal,
  addSiteModal: state.modals.addSiteModal,
  addUserModal: state.modals.addUserModal,
  worksiteLoading: state.worksites.AddWorksiteLoading,
  worksiteLoadingSuccess: state.worksites.AddWorksiteSuccess,
  activeNavItem: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
});

const mapDispatchToProps = (dispatch) => ({
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddAssetModalRedirect: (data) => dispatch(setAddAssetModalRedirect(data)),
});

DropdownFilterWrapper.defaultProps = {
  sortData: true,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownFilterWrapper));
