import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function AssetTypeChecklistUpdateAllModal({ showModal, setShowModal, handleYes }) {
  return (
    <>
      <Modal
        size="md"
        className="custom-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        {/* modal body starts */}

        <Modal.Body className="text-center modalPopup">
          <Row className="mb-4 mt-4 ">
            <Col>
              <span className="mb-2 text-open-sans text-center modal-font-color">
                <Trans>You are updating the checklist mapping on all the assets under this asset type</Trans>.{" "}
                <Trans>Do you wish to continue</Trans>?
              </span>
            </Col>
          </Row>

          {/* Modal body ends */}
          <Row className="justify-content-center">
            <div className="s3modalbuttonyes mr-2">
              <button
                onClick={() => {
                  handleYes();
                }}
                className="btn btn-primary mb-2 buttons ripple"
              >
                <Trans>YES</Trans>
              </button>
            </div>

            <div className="s3modalbuttonno ">
              <button onClick={() => setShowModal(false)} className="btn btn-lg mb-3 buttons ripple">
                <Trans>NO</Trans>
              </button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssetTypeChecklistUpdateAllModal;
