import styled from "styled-components";

export const UserTypeListingDiv = styled.div`
  .table {
    display: block;
    overflow-x: hidden;
    tbody {
      display: block;
    }
  }
  tr.tableTr {
    display: block;
  }
  tr.tableTr td {
    display: block;
  }
  .tableListSec thead {
    display: none;
  }
  .tableHeight {
    height: calc(100vh - 300px);
  }

  tr.tableTr p {
    width: 80%;
  }
`;

export const ListUserTypeDiv = styled.div`
  clear: both;
  width: 100%;
  float: left;
  border-bottom: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  padding: 10px;
`;

export const DotIcon = styled.i`
  float: left;
  position: relative;
  font-size: 14px !important;
  top: 4px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
`;

export const UserTypeName = styled.div`
  font-family: "Open Sans";
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
`;
