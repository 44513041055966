import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../../../components/Input";
import { getAllDurations } from "./DurationFilter.utils";

function DurationFilter({ timeDuration, setTimeDuration }) {
  const { t } = useTranslation();
  const [dateDropdown, setDateDropdown] = useState(14);
  const allDurations = getAllDurations();

  useEffect(() => {
    try {
      if (dateDropdown) {
        console.log("dateDropdown", dateDropdown, allDurations);
        const selectedDuration = allDurations[dateDropdown - 1];
        if (selectedDuration) {
          setTimeDuration(selectedDuration);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [dateDropdown]);

  const handleClearAllSelection = () => {};
  const changeDateTypeDropdown = (value) => {
    try {
      handleClearAllSelection();
      setDateDropdown(value);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {
        <div className="d-flex flex-row flex-wrap align-items-center custom-date-dropdown">
          <div style={{ width: "156px" }}>
            <Input
              options={allDurations}
              type="select"
              as="select"
              showLabel={true}
              label={t("Duration")}
              isValidationError={false}
              value={dateDropdown}
              onChange={(value) => changeDateTypeDropdown(value)}
              sortData={false}
            />
          </div>
        </div>
      }
    </>
  );
}

export default DurationFilter;
