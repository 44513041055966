import styled, { css, keyframes } from "styled-components";
import { RefreshIcon } from "../../../assets";

export const InspectionReportDiv = styled.div`
  display: block;
  width: 100%;
  float: left;
  position: relative; 
`;

export const ImpactReportDiv = styled.div`
  display: block;
  width: 100%;
  float: left;
  position: relative; 
`;

export const UnauthorizedReportDiv = styled.div`
  display: block;
  width: 100%;
  float: left;
  position: relative; 
`;


export const PDFCSVREFRESHButton = styled.div`
  display: -webkit-inline-box;
  float: right;
  margin-bottom: 5px;
  margin-top: -40px;
  position: relative;
  z-index: 180;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
  @media (max-width: 1100px) {
    margin-top: 10px;
    z-index: 0;
    clear: both;
  }
`;
export const RefreshButton = styled.div`
  /* &::after {
    content: "W";
    display: block;
    font-family: "siera-font-icons";
    font-size: 24px;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }*/
`;

export const RefreshButtonDiv = styled.div`
  cursor: pointer;
  overflow: hidden;
  float: right;

  display: flex;
  align-items: center;
  height: 40px;
  .icon.icon-update {
    transition: transform 0.5s;
  }
`;

export const PDFButtonWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
  position: relative;
  margin-right: 8px;
  button{
    &:hover{
      i{
        width: ;
        height: ;
      }
    }
  }
& button.PDFicon_disabled{
  pointer-events:none;
  border: 0px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background:none;
  .cls-1 {
    fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
}
& button.PDFicon_enabled{
  background:none;
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.PdfColor || "#B30B00"};
    }
    &:hover
  {
    background: ${({ theme }) => theme.colors.PdfColor || "#B30B00"};
     &:hover
    {
      .cls-1 {
        fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      }
    }
  }
}
}
button{

  float:right;

  border-width: 0px !important;
}
 button svg{
  height:36px;

 }

 & div.spinner-border
 {
   margin-left:-30px;
   margin-top: 8px;
 }
  }
`;

export const CSVButtonWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
  position: relative;
  margin-right: 12px;
  margin-top:1px;
  button{
    &:hover{
      i{
        width: ;
        height: ;
      }
    }
  }
& a.CSVicon_disabled{
  pointer-events:none;

 
  .cls-1 {
    fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
}

& a.CSVicon_enabled{

  &:hover
  {
  
     &:hover
    {
      .cls-1 {
        fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      }
    }
  }
  svg
  {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  
  }

}
a{

  float:right;
}
 a svg{
  height:36px;
 }
 button{

  float:right;

  border-width: 0px !important;
}
 button svg{
  height:36px;

 }

 & button.CSVicon_enabled{
  background:none;
  padding: 0px;
  svg
  {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.CsvColor || "#008000"};
    }
    &:hover
    {
      background: ${({ theme }) => theme.colors.PdfColor || "#B30B00"};
       &:hover
      {
        .cls-1 {
          fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        }
      }
    }
  }

}
& div.spinner-border
{
  margin-left:-30px;
  margin-top: 8px;
}

  }
`;

export const TableWrapper = styled.table`
  /* height: calc(100vh - 53vh);*/
  display: block;
`;
export const TableHeader = styled.thead`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  display: block;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border: 0px solid;
  text-align: left;
  border-radius: 4px 4px 0px 0px;
  font-family: Kanit;
  font-weight: 300;
  border-right: 20px solid transparent;
  & tr {
    display: flex;
    & th {
      font-family: Kanit;
      font-weight: 500;
      flex: 1;
      padding: 8px;
      &:nth-child(8) {
        text-align: center;
      }
      &:last-child {
        text-align: center;
      }
    }
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    button.btn {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    & tr {
      background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    }
  }

  @media (min-width: 1500px) {
    height: calc(100vh - 355px);
  }

  @media (min-width: 1800px) {
    height: calc(100vh - 390px);
  }

  overflow: auto;
  display: block;
  & tr {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    display: flex;
    align-items: center;
    @media (max-width: 1100px) {
      display: inline-grid;
      border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      float: left;
      padding: 20px;
      position: relative;
      width: 100%;
      margin: 0px !important;
    }
    & td {
      background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      font-family: "Open Sans", sans-serif;
      font-size: 13px !important;
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      text-align: left;
      flex: 1;
      padding: 8px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        svg {
          .cls-1 {
            fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
          }
        }
      }
      &:nth-child(8) {
        text-align: center;
      }
      &:last-child {
        text-align: center;
      }
      @media (max-width: 1100px) {
        display: block;
        flex: unset;
        width: 80%;
        float: left;
        padding: 0 !important;
        margin: 0;
        font-size: 15px !important;
        &:nth-child(1) {
          & input {
            width: 24px;
            height: 27px;
            display: none;
          }
        }
        &:nth-child(3) {
          font-size: 20px !important;
        }
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: none;
        }
        &:nth-child(8) {
          margin-top: 15px;
          text-align: left;
          width: 65%;
        }
        &:last-child {
          margin-top: 15px;
          float: right;
          width: 35%;
          text-align: right;
          display: block !important;
        }
      }
    }
  }
`;

export const DropdownTable = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    position: absolute;
    margin: auto;
    right: 6%;
    top: 13%;
    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const AlertTableReport = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  line-height: 35px;
  position: relative;
  box-shadow:
    inset 0px 6px 8px -5px ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"},
    inset 0px -4px 3px -5px ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
`;

export const AlertTableReportDiv = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  fon-weight: 400;
  & p {
    display: contents;
  }
`;

export const AlertTextDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: 500;
  font-family: "Open Sans";
  font-size: 13px;
`;

export const AlertTextClear = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-weight: 500;
  font-family: "Open Sans";
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
`;

export const PaginationReport = styled.div`
  display: inline-flex;
  /* margin-top: 20px; */
  text-align: center;
  @media (max-width: 1100px) {
    display: inline-flex;
    margin-top: 20px;
    border-bottom: 0px;
  }
`;

export const ReportPageRow = styled.div``;
export const FormGroupReport = styled.div`
  float: left;
  display: flex;
  margin-right: 30px;
  & label {
    float: left;
    flex: none;
    margin-top: 2px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    font-size: 14px !important;
  }
  & select {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    cursor: pointer;
    margin-top: -4px;
    padding: 0px;
    border: 0px;
  }
`;

export const ReportPageNumber = styled.div`
  float: left;
  margin-right: 20px;
  & p {
    margin-bottom: 0rem;
    margin-top: 2px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;

export const ReportPageArrow = styled.div`
  margin-top: 2px;
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const TableBodyUnauthorized = styled.tbody`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  /* height: calc(100vh - 56vh); */
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    button.btn {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    & tr {
      background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    }
  }
  overflow: auto;
  display: block;

  @media (min-width: 1500px) {
    height: calc(100vh - 355px);
  }

  @media (min-width: 1800px) {
    height: calc(100vh - 390px);
  }

  @media (min-width: 1600px) {
    /*  height: calc(100vh - 47vh); */
  }
  & tr {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    display: flex;
    align-items: center;
    @media (max-width: 1100px) {
      display: inline-grid;
      border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      float: left;
      padding: 20px;
      position: relative;
      width: 100%;
      margin: 0px !important;
    }
    & td {
      font-family: "Open Sans", sans-serif;
      font-size: 13px !important;
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      text-align: left;
      flex: 1;
      padding: 8px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        svg {
          .cls-1 {
            fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
          }
        }
      }
      &:nth-child(8) {
        text-align: center;
      }
      &:last-child {
        text-align: center;
      }
      @media (max-width: 1100px) {
        display: block;
        flex: unset;
        width: 80%;
        float: left;
        padding: 0 !important;
        margin: 0;
        font-size: 15px !important;
        &:nth-child(1) {
          & input {
            width: 24px;
            height: 27px;
            display: none;
          }
        }
        &:nth-child(3) {
          font-size: 20px !important;
        }
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: none;
        }
        &:nth-child(8) {
          margin-top: 15px;
          text-align: left;
          width: 65%;
        }
        &:last-child {
          margin-top: 15px;
          float: right;
          width: 35%;
          text-align: right;
          display: block !important;
        }
      }
    }
  }
`;

export const TRnoreports = styled.tr`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: 1400px) {
    height: calc(100vh - 400px);
  }

  @media (min-width: 1500px) {
    height: calc(100vh - 400px);
  }

  @media (min-width: 1800px) {
    height: calc(100vh - 410px);
  }

  font-family: "Open Sans", sans-serif;
`;

export const LoadingHeight = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: 1500px) {
    height: calc(100vh - 355px);
  }

  @media (min-width: 1800px) {
    height: calc(100vh - 390px);
  }

  font-family: "Open Sans", sans-serif;
  .spinner-border {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;

export const PassFail = styled.div`
  position: relative;

  .register-now {
    opacity: 0;
    transform: translate(-100%, 0);
    transition: all 0.3s cubic-bezier(0.94, 0.06, 0.45, 0.95);
    position: relative;
    display: block;
    top: -1px;
    line-height: 28px;
    left: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .register {
    position: relative;
    display: block;
    top: -14px;
    line-height: 0px;
    width: 100%;
    transition: all 0.3s cubic-bezier(0.94, 0.06, 0.45, 0.95);
  }
  div:hover .register-now {
    opacity: 1;
    transform: translate(0, 0);
  }
  div:hover .register {
    opacity: 0;
    transform: translate(100%, 0);
  }
  button {
    background: none;
    border: 0;
    width: 100%;
  }
`;

export const PassBorderDiv = styled.div`
  border: 1px solid #11a70b;
  border-radius: 4px;
  margin: -8px;
`;
export const FailBorderDiv = styled.div`
  border: 1px solid #dd0000;
  border-radius: 4px;
  margin: -8px;
`;
export const TimedOutBorderDiv = styled.div`
  border: 1px solid #323232;
  border-radius: 4px;
  margin: -8px;
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const rotateAnimationCSS = css`
  ${rotateAnimation} 2s linear forwards;
`;

export const RefreshButtonD = styled.div`
  cursor: pointer;
  position: relative;
  font-size: 26px !important;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  line-height: 15px;
  animation: ${({ rotating }) => (rotating ? rotateAnimationCSS : "none")};
  @media screen and (max-width: 800px) {
  }
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;
