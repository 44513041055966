import React from "react";
import "react-phone-input-2/lib/style.css";
import Input from "react-phone-input-2";

export default function ReactPhoneInput({
  placeholder,
  name,
  onChange,
  value,
  disabled,
  isInvalid,
  ...props
}) {
  const handleChange = (e) => {
    onChange({
      target: {
        value: e,
        name,
      },
    });
  };

  return (
    <Input
      defaultCountry={"Us"}
      fullWidth
      autofocus
      name={name}
      placeholder={""}
      inputExtraProps={{
        name: { name },
        required: true,
        autoFocus: true,
      }}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      isInvalid={isInvalid}
      {...props}
    />
  );
}
