import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { InputGroup } from "react-bootstrap";
import InputField from "../../../../components/InputFieldComponent/InputField.component";
import {
  InputStartAdormentDiv,
  InputDiv,
  LabelDiv,
  RightInput,
  RightInputText,
  StyledInputGroup,
} from "./InputStartAdorment.styles";

const InputStartAdorment = forwardRef(({ label, startAdornment, ...inputProps }, ref) => {
  return (
    <>
      <InputStartAdormentDiv>
        <LabelDiv>{label}</LabelDiv>
        <RightInput>
          {startAdornment && (
            <InputGroup.Prepend>
              <StyledInputGroup id="basic-addon3">{startAdornment}</StyledInputGroup>
            </InputGroup.Prepend>
          )}
          <InputDiv>
            <InputField size="medium" ref={ref} {...inputProps} />
          </InputDiv>
        </RightInput>
      </InputStartAdormentDiv>
    </>
  );
});

InputStartAdorment.propTypes = {};

export default InputStartAdorment;
