import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

import Input from "../../../../components/Input";
import InputNew from "../../../../components/InputFieldComponent/InputField.component";
import MultiSelector from "../../../../components/multiSelector/MultiSelector.component";
import { vehicleClasses } from "./constants";
import CustomDatePicker from "../../../../components/customDatePicker/CustomDatePicker";
import DateRangePicker from "../../../../components/DatePickerComponent/DatePicker.component";
import { InvalidFeedback } from "./OperatorVerificationForm.styles";

const CertificateFormFields = (props) => {
  const {
    issueDate,
    changeHandler,
    errors,
    expiryDate,
    certificateNumber,
    vehicleClassesChangeHandler,
    certifyingOrganization,
    selectedOptions,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Row className="p-4">
        <Col md={12} className="mb-2">
          <DateRangePicker
            dateRangePickerFromTo
            selectedStartDate={issueDate ? moment(issueDate).toDate() : null}
            selectedEndDate={expiryDate ? moment(expiryDate).toDate() : null}
            onChangeStartDate={(date) => changeHandler("issueDate", date)}
            onChangeEndDate={(date) => changeHandler("expiryDate", date)}
            dateFormat="MM/dd/yyyy"
            startLabel={t("Certificate Issue Date")}
            endLabel={t("Certificate Valid Till")}
            placeholderText={t("Select a date")}
          />
          {errors.issueDate && errors.expiryDate && (
            <InvalidFeedback>
              <Trans>Both start and end dates are required</Trans>
            </InvalidFeedback>
          )}
          {errors.issueDate && !errors.expiryDate && (
            <InvalidFeedback>
              <Trans>Start date is required</Trans>
            </InvalidFeedback>
          )}
          {!errors.issueDate && errors.expiryDate && (
            <InvalidFeedback>
              <Trans>End date is required</Trans>
            </InvalidFeedback>
          )}
        </Col>

        {/* <Col md={6}>
          <CustomDatePicker
            name="issueDate"
            selected={issueDate ? moment(issueDate).toDate() : null}
            maxDate={
              expiryDate
                ? moment(expiryDate) < moment()
                  ? moment(expiryDate).toDate()
                  : new Date()
                : new Date()
            }
            onChangeHandler={(date) => changeHandler("issueDate", date)}
            dateFormat="MM/dd/yyyy"
            label={t("Certificate Issue Date")}
            placeholderText={t("Select a date")}
          />
          {errors.issueDate && (
            <InvalidFeedback>
              <Trans>This field is required</Trans>
            </InvalidFeedback>
          )}
        </Col>
        <Col md={6}>
          <CustomDatePicker
            name="expiryDate"
            selected={expiryDate ? moment(expiryDate).toDate() : null}
            minDate={issueDate && moment(issueDate).toDate()}
            onChangeHandler={(date) => changeHandler("expiryDate", date)}
            dateFormat="MM/dd/yyyy"
            label={t("Certificate Valid Till")}
            placeholderText={t("Select a date")}
          />

          {errors.expiryDate && (
            <InvalidFeedback>
              <Trans>This field is required</Trans>
            </InvalidFeedback>
          )}
        </Col> */}
        <Col md={6} className="mb-2">
          <InputNew
            label={t("Certificate Number")}
            variant="standard"
            type="text"
            maxLength={32}
            value={certificateNumber}
            onChange={(e) => changeHandler("certificateNumber", e)}
            errorMessage={t("Please enter alphanumeric characters")}
            isError={errors.certificateNumber}
          />
          {/* {errors.certificateNumber && (
            <InvalidFeedback>
              <Trans>Please enter alphanumeric characters</Trans>
            </InvalidFeedback>
          )} */}
        </Col>
        <Col md={6} className="mb-2">
          <InputNew
            label={t("Certifying Organization")}
            variant="standard"
            type="text"
            maxLength={64}
            value={certifyingOrganization}
            onChange={(e) => changeHandler("certifyingOrganization", e)}
            errorMessage={t("Please enter alphanumeric characters")}
            isError={errors.certifyingOrganization}
          />
          {/* {errors.certifyingOrganization && (
            <InvalidFeedback>
              <Trans>Please enter alphanumeric characters</Trans>
            </InvalidFeedback>
          )} */}
        </Col>
        <Col md={6} className="mb-2">
          <MultiSelector
            label={t("Certified Class of Vehicle")}
            placeholder={t("Please select a class of vehicle")}
            options={vehicleClasses}
            changeHandler={vehicleClassesChangeHandler}
            returnValueArray={true}
            selectedOptions={selectedOptions}
            clearAllText={t("Clear All")}
          />
          {errors.certifiedVehicleClasses && (
            <InvalidFeedback>
              <Trans>Please select at least one class of vehicle</Trans>
            </InvalidFeedback>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CertificateFormFields;
