import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as ProfileImage } from "../../../assets/Profile_Photo.svg";
import { apiCall } from "../../../utils/apiCall";
import config from "../../../config";
import { updateLoginUserDetail } from "../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import Input from "../../../components/Input";
import useAlert from "../../../utils/useAlert";
import toasterConstant from "../../../constant/messages/toaster.json";
import {
  toValidateEmail,
  toValidatePhone,
  toValidateFullName,
} from "../../../utils/validations/checkValidation";
import { getUserDetailById } from "../../../redux/users/action";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import {
  EditProfileContainer,
  TopImageAccountDiv,
  ImageAvatar,
  ChangeImage,
  AccountProfileDetail,
  UserNameDiv,
  InputProfileDiv,
  InputProfileDivCont,
  PhoneErrorDiv,
  ButtonContainerStyle,
  ButtonContainerDiv,
  NameContainerDiv,
  MFAWrapper,
  SwitchWrapper,
  CameraIcon,
} from "./EditProfile.styles";
import InputField from "../../../components/InputFieldComponent/InputField.component";
import Button from "../../../components/Button/Button.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import SimpleDropdownFilter from "../../../components/SimpleDropdownFilter.component";
import { Row, Col } from "react-bootstrap";

const { user_profile_update, user_profile_update_error, email_already_in_use } = toasterConstant;
const s3BucketProfileImg = config.s3BucketProfileImg;
function EditProfile({
  userData,
  updateLoginUserDetail,
  setFace,
  updateActiveNavItem,
  getUserDetailById,
  userId,
  accountMFAData,
  userOverview,
  userType,
}) {
  const inputRef = useRef();
  const [validated, setValidated] = useState(false);
  const fullNameInputRef = useRef(null);
  const [phoneError, setPhoneError] = useState(false);
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const mfaData = userOverview?.mfaData;

  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    imgData: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullNameError: "",
    emailError: "",
    phoneError: "",
  });

  const [editMode, setEditMode] = useState(false);

  const [permission, setPermission] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [mfadataLocal, setMfadataLocal] = useState({});
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    getUserDetailById(userId);
  }, [userId]);

  useEffect(() => {
    setMfadataLocal(mfaData);
  }, [mfaData]);

  useEffect(() => {
    if (userData) {
      setState({
        fullName: userData?.fullName,
        email: userData?.email,
        phone: userData?.phone,
        imgData: userData?.imgData,
        ...userData,
      });
      setPermission(userType?.name);
    }
    if (editMode) {
      fullNameInputRef.current.focus();
    }
  }, [userData, editMode]);

  useEffect(() => {
    let error = toValidatePhone(state?.phone);
    setPhoneError(error);
  }, [state]);

  const presubmitCheck = (e) => {
    e.preventDefault();
    if (userData?.email !== state.email) {
      setShowPasswordModal(true);
    } else {
      handleSubmit(e);
    }
  };

  //create an action/reducer and move it to an action file when time permits
  const handleSubmit = (e, isEmailChanged, password) => {
    e.preventDefault();
    setValidated(true);
    if (phoneError) {
      return;
    }
    if (isFormValid()) {
      //setIsLoading(true);
      let formData = new FormData();

      let { fullName, email, phone } = state;
      if (isEmailChanged) {
        formData.append(
          "userData",
          JSON.stringify({
            fullName,
            email,
            phone,
            password,
            mfaData: {
              status: mfadataLocal?.status,
              type: "EMAIL",
            },
          })
        );
      } else {
        formData.append(
          "userData",
          JSON.stringify({
            fullName,
            email,
            phone,
            mfaData: {
              status: mfadataLocal?.status,
              type: "EMAIL",
            },
          })
        );
      }

      if (selectedFile) {
        formData.append("profileImg", selectedFile);
      }

      const url = isEmailChanged ? `/user/updateprofile/${userData._id}` : `/user/${userData._id}`;

      apiCall(url, {
        method: "PUT",
        data: formData,
        type: "file",
      })
        .then(({ data }) => {
          if (data.success) {
            setValidated(false);
            if (data) {
              let { imgData } = data.data;
              updateLoginUserDetail({ fullName, email, phone, imgData });
            }
            showAlert("success", t(user_profile_update));
            setIsLoading(false);
            setEditMode(false);
            setShowPasswordModal(false);
            setInvalidPasswordError(false);
            setUserPassword("");
          } else {
            if (data.message.includes("Password is invalid")) {
              setInvalidPasswordError(true);
            } else if (data.message.includes("Email not available")) {
              showAlert("error", t(email_already_in_use));
            } else {
              showAlert("error", t(user_profile_update_error));
            }
          }
        })
        .catch((err) => {
          showAlert("error", t(user_profile_update_error));
          setIsLoading(false);
        });
    }
  };

  const isFormValid = (e) => {
    const { fullName, email, phone } = state;
    let isValid = true;
    if (toValidateFullName(state?.fullName)) {
      handleFormError("fullName", "");
      isValid = false;
    }
    if (!email) {
      handleFormError("email", "");
      isValid = false;
    }
    if (toValidateEmail(email)) {
      handleFormError("email", toValidateEmail(email));
      isValid = false;
    }
    return isValid;
  };

  const handleFormError = (fieldName, message) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [`${fieldName}Error`]: message,
    }));
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    updateActiveNavItem("userProfile");
  }, []);

  const [userPassword, setUserPassword] = useState("");
  const [invalidPasswordError, setInvalidPasswordError] = useState(false);
  const [userAlert, setUserAlert] = useState(null);

  const EmailChangeBody = () => {
    return (
      <>
        <span>Please enter your password to confirm the email change</span>
        <InputField
          required
          label="Password"
          name={"password"}
          type="password"
          placeholder={t("Enter your password")}
          value={userPassword}
          onChange={(e) => {
            setUserAlert(null);
            setInvalidPasswordError(false);
            setUserPassword(e.target.value);
          }}
          isError={invalidPasswordError || !!userAlert}
          errorMessage={userAlert ? userAlert : "Incorrect password. Please try again."}
          autoFocus
          autocomplete="one-time-code"
        />
      </>
    );
  };

  return (
    <EditProfileContainer>
      <Form id="changeProfileForm" noValidate className="no-flex">
        <TopImageAccountDiv>
          <Row>
            <Col>Profile Photo</Col>
            <Col>
              <ImageAvatar
                style={{
                  backgroundImage: `url(${
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : state.imgData
                        ? s3BucketProfileImg + "" + state.imgData
                        : ProfileImage
                  })`,
                }}
              >
                {editMode && (
                  <ChangeImage onClick={() => inputRef?.current?.click?.()}>
                    <CameraIcon className="icon icon-camera" />

                    <span>
                      <Trans>Change Image</Trans>
                    </span>
                    <input
                      type="file"
                      ref={inputRef}
                      accept="image/*"
                      onChange={handleImageChange}
                      disabled={!editMode}
                    />
                  </ChangeImage>
                )}
              </ImageAvatar>
            </Col>
          </Row>
          <NameContainerDiv>
            <UserNameDiv>
              <Input
                fullNameInputRef={fullNameInputRef}
                value={state?.fullName}
                disabled
                onChange={(e) => {
                  setState({
                    ...state,
                    fullName: e.target.value,
                  });
                }}
                isError={validated && toValidateFullName(state?.fullName)}
                errorMessage={validated && toValidateFullName(state?.fullName)}
              />
            </UserNameDiv>
            <AccountProfileDetail>
              <Trans>{permission}</Trans>
            </AccountProfileDetail>
          </NameContainerDiv>
        </TopImageAccountDiv>
        <InputProfileDiv>
          <InputProfileDivCont>
            <InputField
              fullNameInputRef={fullNameInputRef}
              label={t("First and Last Name")}
              required
              placeholder={t("First and Last Name")}
              variant="standard"
              type="text"
              id="fullName"
              name="fullName"
              value={state?.fullName}
              disabled={!editMode}
              onChange={(e) => {
                setState({
                  ...state,
                  fullName: e.target.value,
                });
              }}
              isError={validated && toValidateFullName(state?.fullName)}
              errorMessage={validated && toValidateFullName(state?.fullName)}
            />
          </InputProfileDivCont>

          <InputProfileDivCont>
            <InputField
              label="Email"
              required
              className="mb-1"
              id="email"
              placeholder={t("Email")}
              variant="standard"
              type="email"
              name="email"
              value={state?.email}
              disabled={!editMode}
              autocomplete="new-password"
              pattern={
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              }
              onChange={(e) => {
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
              isError={validated && toValidateEmail(state?.email)}
              errorMessage={validated && toValidateEmail(state?.email)}
            />
          </InputProfileDivCont>
          <InputProfileDivCont>
            <InputField
              label="Phone Number"
              required
              className="mb-1"
              id="phone"
              placeholder={t("Phone Number")}
              variant="standard"
              type="phone"
              name="phone"
              value={state?.phone}
              disabled={!editMode}
              pattern={
                /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/
              }
              onChange={(e) => {
                setState({
                  ...state,
                  phone: e.target.value,
                });
              }}
            />

            {accountMFAData?.status && (
              <MFAWrapper>
                <SwitchWrapper>
                  Multi-Factor Authentication{" "}
                  <SwitchComponent
                    onChange={(e) => {
                      setMfadataLocal({
                        ...mfadataLocal,
                        status: e.target.checked,
                      });
                    }}
                    checked={mfadataLocal?.status}
                    disabled={!editMode || accountMFAData?.isMandatory}
                  />
                </SwitchWrapper>
                {mfadataLocal?.status && (
                  <SimpleDropdownFilter
                    useAsDropdown={true}
                    options={[
                      {
                        _id: 1,
                        label: "Email OTP",
                      },
                    ]}
                    disabled={true}
                    label={"MFA Type"}
                  />
                )}
              </MFAWrapper>
            )}
          </InputProfileDivCont>

          <PhoneErrorDiv>{validated && <p className="text-danger">{phoneError}</p>}</PhoneErrorDiv>
        </InputProfileDiv>
        {!editMode ? (
          <ButtonContainerStyle>
            <ButtonContainerDiv>
              <Button
                label={t("Edit")}
                iconClass="edit"
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(true);
                }}
                showIcon
                buttonType="secondary"
              />
            </ButtonContainerDiv>
            <ButtonContainerDiv>
              <Button
                label={t("Change Password")}
                iconClass="unlock"
                onClick={(e) => {
                  e.preventDefault();
                  setFace("back");
                }}
                showIcon
                buttonType="primary"
              />
            </ButtonContainerDiv>
          </ButtonContainerStyle>
        ) : (
          <ButtonContainerStyle>
            <ButtonContainerDiv>
              <Button
                label={t("Save")}
                iconClass="save"
                disabled={isLoading}
                onClick={presubmitCheck}
                showIcon
                buttonType="save"
              />
            </ButtonContainerDiv>
            <ButtonContainerDiv>
              <Button
                label={t("Cancel")}
                onClick={() => {
                  setSelectedFile(null);
                  setEditMode(false);
                }}
                showIcon={false}
                buttonType="ghost"
              />
            </ButtonContainerDiv>
          </ButtonContainerStyle>
        )}
      </Form>
      <ModalComponent
        show={showPasswordModal}
        variant="TransactionalModal"
        handleClose={() => {}}
        message="Confirm Email Change"
        paragraph={<EmailChangeBody />}
        button1Text="Confirm"
        button2Text="Cancel"
        onClickA={(e) => {
          if (userPassword?.trim()) {
            handleSubmit(e, true, userPassword);
          } else {
            setUserAlert("Please enter a valid password.");
          }
        }}
        onClickB={() => {
          setSelectedFile(null);
          setEditMode(false);
          setShowPasswordModal(false);
          setUserPassword("");
          setInvalidPasswordError(false);
        }}
        typeA="primary"
        typeB="ghost"
      />
    </EditProfileContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state?.user?.loginDetails?.userData,
    userType: state?.user?.loginDetails?.userData.userType,
    mfaData: state?.user?.loginDetails?.userData.mfaData,
    userId: state?.user?.loginDetails?.userData?._id,
    accountMFAData: state?.user?.loginDetails?.mfaData,
    userOverview: state?.users?.UserOverview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLoginUserDetail: (data) => dispatch(updateLoginUserDetail(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getUserDetailById: (id) => dispatch(getUserDetailById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
