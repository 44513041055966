export const getSelectedOptions = (applyToAllObj) => {
  const siteIds = applyToAllObj?.worksites?.all
    ? "all"
    : applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
  const assetTypeIds = applyToAllObj?.assetTypes?.all
    ? "all"
    : applyToAllObj?.assetTypes?.selectedItems?.map((assetType) => assetType.value);
  const assetIds = applyToAllObj?.assets?.all
    ? "all"
    : applyToAllObj?.assets?.selectedItems?.map((asset) => asset.value);
  return {
    sites: siteIds,
    assetTypes: assetTypeIds,
    assets: assetIds,
  };
};

export const handleRecipientsChangeUtil = (userType, isChecked, alertSettings, setAlertSettings) => {
  let temp;
  if (isChecked) {
    temp = {
      ...alertSettings,
      userTypes: [...alertSettings?.userTypes, userType._id],
    };
  } else {
    temp = {
      ...alertSettings,
      userTypes: alertSettings?.userTypes?.filter((id) => id !== userType._id),
    };
  }
  setAlertSettings(temp);
};
