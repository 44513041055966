import React, { useEffect, useMemo, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { Trans } from "react-i18next";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

const AppbarStyled = styled.div`
  .tabpanelSize .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .MuiTab-textColorPrimary,
  .tabpanelSize .MuiTab-textColorPrimary.Mui-selected {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;
export default function TabBar({ tabs, url }) {
  const history = useHistory();

  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    const routes = window.location.href.split("/");
    let currentRoute = routes[routes.length - 1];
    setCurrentRoute(currentRoute);
  }, [window.location.href]);

  const value = useMemo(() => {
    let index;
    tabs.forEach((tab, i) => {
      if (tab.route === currentRoute) {
        index = i;
        return;
      }
    });
    return index ? index : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  return (
    <AppbarStyled>
      <AppBar position="static" color="transparent" className="appBar">
        <Tabs
          value={value}
          onChange={() => {}}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          ariaLabel="ant example"
          className="tabpanelSize"
        >
          {tabs.map((data, i) => (
            <Tab
              className="buttontabpanels "
              index={i}
              label={<Trans>{data.name}</Trans>}
              onClick={() => history.push(data.route)}
            />
          ))}
        </Tabs>
      </AppBar>
    </AppbarStyled>
  );
}
