import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import SwitchComponent from "../../../../../components/Switchbox/SwitchComponent";
import {
  Divider,
  GeneralDropdownContainer,
  LeftLabel,
  RightInput,
  SpanBottom,
  SpanTop,
  SwitchLabel,
} from "./GeneralSwitch.styles";

const GeneralSwitch = ({ leftLabel, lineDivider, rightLabel, onSwitchChange, isChecked, ...switchProps }) => {
  const { t } = useTranslation();
  const handleSwitchChange = (e) => {
    if (onSwitchChange) {
      onSwitchChange(e.target.checked);
    }
  };

  return (
    <>
      <GeneralDropdownContainer>
        <LeftLabel>
          <SpanTop>{leftLabel}</SpanTop>
          <SpanBottom>{rightLabel && <span>{rightLabel}</span>}</SpanBottom>
        </LeftLabel>

        <RightInput>
          <SwitchLabel>{isChecked ? t("On") : t("Off")}</SwitchLabel>
          <SwitchComponent size="small" checked={isChecked} onChange={handleSwitchChange} {...switchProps} />
        </RightInput>
      </GeneralDropdownContainer>

      {lineDivider && <Divider></Divider>}
    </>
  );
};

GeneralSwitch.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  lineDivider: PropTypes.bool,
  onSwitchChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default GeneralSwitch;
