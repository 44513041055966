import React, { useState, useEffect } from "react";
import inputSlizerConstant from "./InputSlider.module.css";
import styled from "styled-components";
const InputNumber = styled.div`
  ${({ disabled }) =>
    disabled &&
    `
    border: 1px solid rgb(204, 204, 204);
    label, input{
      color: rgb(204, 204, 204);
    }
  `}
`;

const { activeNumber, inputNumber, defaultNumber, sizeStyle } = inputSlizerConstant;
const InputSlider = (props) => {
  const { id, name, value, label, classType, onInput, isDepth, onBlur, onClick, isFeet, step, isDisabled } =
    props;
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (isDepth && label !== "Depth") {
      setDisabled(true);
    } else if (!isDepth && label === "Depth") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isDepth, label]);
  return (
    <div id={id} className={activeNumber + " " + classType + " " + (disabled && defaultNumber)}>
      <InputNumber className={inputNumber} disabled={isDisabled}>
        <label>{label}</label>
        <input
          step={step}
          onBlur={onBlur}
          onClick={onClick}
          name={name}
          min={0}
          onInput={onInput}
          type="number"
          value={value === 0 ? "" : value}
          disabled={isDisabled}
          onKeyDown={(e) => {
            if (e.target.value.length > 4) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        />
      </InputNumber>
      <span className={sizeStyle}>{isFeet ? "ft" : "mm"}</span>
    </div>
  );
};

export default InputSlider;
