import { filterType } from "./types";
import { initialState } from "./initialState";
import moment from "moment";
import { isValidateDateObject } from "../../utils/validations/checkValidation";

export const configReducer = (state = initialState, action) => {
  let filterData;
  switch (action.type) {
    case filterType.SET_INSPECTION_REPORT_FILTER:
      let inspectionFilter = action.data;
      filterData = {
        accountId: inspectionFilter.accountId ? inspectionFilter.accountId : "all",
        worksiteId: inspectionFilter.worksiteId ? inspectionFilter.worksiteId : "all",
        assetTypeId: inspectionFilter.assetTypeId ? inspectionFilter.assetTypeId : "all",
        filterByType: inspectionFilter.filterByType ? inspectionFilter.filterByType : "all",
        assetId: inspectionFilter.assetId ? inspectionFilter.assetId : "all",
        inspectorId: inspectionFilter.inspectorId ? inspectionFilter.inspectorId : "all",
        startDate: isValidateDateObject(new Date(inspectionFilter.startDate))
          ? inspectionFilter.startDate
          : moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
        endDate: isValidateDateObject(new Date(inspectionFilter.endDate))
          ? inspectionFilter.endDate
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        pageNo: inspectionFilter.pageNo ? inspectionFilter.pageNo : "all",
        pageSize: inspectionFilter.pageSize ? inspectionFilter.pageSize : "all",
        status: inspectionFilter.status ? inspectionFilter.status : "all",
        siteId: inspectionFilter.siteId ? inspectionFilter.siteId : "all",
        checklistId: inspectionFilter.checklistId || "all",
      };
      return {
        ...state,
        inspectionReportFilter: filterData,
      };

    case filterType.RESET_INSPECTION_REPORT_FILTER:
      return {
        ...state,
        inspectionReportFilter: {
          ...initialState.inspectionReportFilter,
        },
      };

    case filterType.SET_INCIDENT_REPORT_FILTER:
      let incidentFilter = action.data;
      filterData = {
        accountId: incidentFilter.accountId ? incidentFilter.accountId : "all",
        worksiteId: incidentFilter.worksiteId ? incidentFilter.worksiteId : "all",
        assetTypeId: incidentFilter.assetTypeId ? incidentFilter.assetTypeId : "all",
        filterByType: incidentFilter.filterByType ? incidentFilter.filterByType : "all",
        assetId: incidentFilter.assetId ? incidentFilter.assetId : "all",
        inspectorId: incidentFilter.inspectorId ? incidentFilter.inspectorId : "all",
        startDate: isValidateDateObject(new Date(incidentFilter.startDate))
          ? incidentFilter.startDate
          : moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
        endDate: isValidateDateObject(new Date(incidentFilter.endDate))
          ? incidentFilter.endDate
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        pageNo: incidentFilter.pageNo ? incidentFilter.pageNo : "all",
        pageSize: incidentFilter.pageSize ? incidentFilter.pageSize : "all",
        impactSeverity: incidentFilter.impactSeverity ? incidentFilter.impactSeverity : "all",
        obstacleType: incidentFilter.obstacleType ? incidentFilter.obstacleType : "all",
        incidentType: incidentFilter.incidentType ? incidentFilter.incidentType : "all",
      };
      return {
        ...state,
        incidentReportFilter: filterData,
      };

    case filterType.RESET_INCIDENT_REPORT_FILTER:
      return {
        ...state,
        incidentReportFilter: {
          accountId: "all",
          assetId: "all",
          filterByType: "all",
          assetTypeId: "all",
          inspectorId: "all",
          pageNo: 1,
          pageSize: 10,
          startDate: moment()
            .subtract(30, "days")
            .startOf("day")
            .add(1, "second")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          impactSeverity: "all",
          worksiteId: "all",
        },
      };
    case filterType.SET_UNAUTHORIZED_ACCESS_FILTER:
      let unauthorizedAccessFilter = action.data;
      filterData = {
        accountId: unauthorizedAccessFilter.accountId ? unauthorizedAccessFilter.accountId : "all",
        worksiteId: unauthorizedAccessFilter.worksiteId ? unauthorizedAccessFilter.worksiteId : "all",
        assetTypeId: unauthorizedAccessFilter.assetTypeId ? unauthorizedAccessFilter.assetTypeId : "all",
        filterByType: unauthorizedAccessFilter.filterByType ? unauthorizedAccessFilter.filterByType : "all",
        assetId: unauthorizedAccessFilter.assetId ? unauthorizedAccessFilter.assetId : "all",
        inspectorId: unauthorizedAccessFilter.inspectorId ? unauthorizedAccessFilter.inspectorId : "all",
        startDate: isValidateDateObject(new Date(unauthorizedAccessFilter.startDate))
          ? unauthorizedAccessFilter.startDate
          : moment().subtract(30, "days").startOf("day").add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
        endDate: isValidateDateObject(new Date(unauthorizedAccessFilter.endDate))
          ? unauthorizedAccessFilter.endDate
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        pageNo: unauthorizedAccessFilter.pageNo ? unauthorizedAccessFilter.pageNo : "all",
        pageSize: unauthorizedAccessFilter.pageSize ? unauthorizedAccessFilter.pageSize : "all",
        impactSeverity: unauthorizedAccessFilter.impactSeverity
          ? unauthorizedAccessFilter.impactSeverity
          : "all",
      };
      return {
        ...state,
        unauthorizedAccessFilter: filterData,
      };

    case filterType.RESET_UNAUTHORIZED_ACCESS_FILTER:
      return {
        ...state,
        unauthorizedAccessFilter: {
          accountId: "all",
          assetId: "all",
          filterByType: "all",
          assetTypeId: "all",
          inspectorId: "all",
          pageNo: 1,
          pageSize: 10,
          startDate: moment()
            .subtract(30, "days")
            .startOf("day")
            .add(1, "second")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          impactSeverity: "all",
          worksiteId: "all",
        },
      };

    // Returning default state
    default:
      return state;
  }
};
