import React from "react";
import { Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import { DesktopView, MobileView, ActionBarButtons, ActionBarWrapper } from "./AccountManagement.styles";

function AccountsActionBar({ showActionBar }) {
  const { t } = useTranslation();
  return (
    <>
      {/* For desktop */}
      <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="add">
        <DesktopView>
          <div className="text-left mr-2 pl-0">
            <Button
              disabled
              iconClass="add"
              label={t("add account")}
              onClick={() => {}}
              showIcon
              buttonType="secondary"
            />
          </div>
        </DesktopView>

        {/* For Mobile */}
        {!showActionBar ? (
          ""
        ) : (
          <MobileView>
            <ActionBarWrapper>
              <ActionBarButtons>
                <Button
                  disabled
                  iconClass="add"
                  label={t("add account")}
                  onClick={() => {}}
                  showIcon
                  buttonType="secondary"
                />
              </ActionBarButtons>
            </ActionBarWrapper>
          </MobileView>
        )}
      </PermissionCheck>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsActionBar));
