import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ContentContainer, BlankMessageDiv } from "../ChartsOverview.component.styles";
import MongoChartComponent from "./MongoChart/MongoChart.component";
import MongoChartHeatMapComponent from "./MongoChart/MongoChartHeatMap.component";
import { Trans } from "react-i18next";

function ChartsDetails({ chartListData, selectedChart }) {
  // Define a reference for the content container
  let contentContainerRef = useRef(null);

  const isChartListDataPresent = () => {
    return chartListData && chartListData.length > 0;
  };

  const [userCharListsData, setUserCharListsData] = useState([]);

  useEffect(() => {
    if (!isChartListDataPresent()) {
      return;
    }

    const updatedUserCharListsData = [];

    chartListData.forEach((chartCatItem) => {
      chartCatItem.entries.forEach((entry) => {
        updatedUserCharListsData.push(entry);
      });
    });

    setUserCharListsData(updatedUserCharListsData);
    console.log("userData", updatedUserCharListsData);
  }, [chartListData]);

  useEffect(() => {
    console.log("scrollToSection:selectedChart", selectedChart);
    if (selectedChart == null) {
      return;
    }
    let slectedChartIndex = null;
    for (let i = 0; i < userCharListsData?.length; i++) {
      const chartListData = userCharListsData[i];
      if (selectedChart?.mChartId == chartListData.mChartId) {
        slectedChartIndex = i;
        break;
      }
    }
    if (!slectedChartIndex) {
      slectedChartIndex = 0; // patch :: somethings wrong if it comes to this means a chart is selected which is not present in details list..
    }
    if (slectedChartIndex || slectedChartIndex === 0) {
      ///===0 condition is kept other wise 0 is taken as false and so it never scrolls to position 0 becasse this if loops skips it ...!!
      scrollToSection(slectedChartIndex);
    }
    //else do nothing ...!!
  }, [selectedChart]);

  // Function to scroll to a specific section
  const scrollToSection = (index) => {
    // console.log("scrollToSection",index)
    if (contentContainerRef?.current) {
      const sectionElement = contentContainerRef?.current?.children[index];
      // console.log("scrollToSection:",sectionElement)
      if (sectionElement) {
        sectionElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }
  };

  const loadChartBasedOnName = (chartData) => {
    if (chartData?.name.toLowerCase().includes("heatmap")) {
      // console.log("came here")
      return (
        <MongoChartHeatMapComponent
          key={chartData.mChartId}
          chartId={chartData.mChartId}
          title={chartData.name}
        />
      );
    } else {
      return (
        <MongoChartComponent key={chartData.mChartId} chartId={chartData.mChartId} title={chartData.name} />
      );
    }
  };

  return (
    <ContentContainer ref={contentContainerRef}>
      {!isChartListDataPresent() ? (
        <BlankMessageDiv>
          <p>
            <Trans>PLEASE_ADD_CHART_FROM_ADD_BUTTON</Trans>
          </p>
        </BlankMessageDiv>
      ) : (
        userCharListsData?.map((chartData) => loadChartBasedOnName(chartData))
      )}
    </ContentContainer>
  );
}

const mapStateToProps = (state) => ({
  chartListData: state.analyticsPage?.getUserChartsList?.success,
  selectedChart: state.analyticsPage?.selectedChart,
});

const mapDispatchToProps = (dispatch) => ({
  // updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsDetails));
