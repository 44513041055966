export const csvHeadersData = [
  { label: "Date Time Timezone", key: "timeRecordedAtText" },
  { label: "User Logged In?", key: "userLoggedINText" },
  { label: "ID", key: "_id" },
  { label: "AccountID", key: "accountId" },
  { label: "Account Name", key: "accountName" },
  { label: "WorksiteID", key: "siteId._id" },
  { label: "Worksite Name", key: "siteName" },

  { label: "AssetID", key: "assetId" },
  { label: "Asset Name", key: "assetName" },
  { label: "Error Category", key: "errorCategory" },
  { label: "Error Description", key: "errorDescription" },
  { label: "User ID", key: "userId" },
  { label: "User Name", key: "userName" },
  { label: "App Version", key: "appVersion" },

  { label: "S3 Version", key: "s3Version" },
  { label: "Bluetooth Version", key: "bluetoothVersion" },
  { label: "Screen Name", key: "ScreenName" },
  { label: "Camera Position - Disconnected", key: "disconnectedCameras" },
  { label: "Camera Position - Occluded", key: "occludedCameras" },
  { label: "Camera Position - Upside Down", key: "orientedCameras" },
  { label: "__v", key: "__v" },
];

export const errorCodes = [
  {
    label: "All",
    value: null,
  },
  {
    label: "SYSTEM_REBOOT_INITIATED",
    value: "SYSTEM_REBOOT_INITIATED",
  },
  {
    label: "SYSTEM_REBOOT_COMPLETE",
    value: "SYSTEM_REBOOT_COMPLETE",
  },
  {
    label: "DFU_WRITE_ERROR",
    value: "DFU_WRITE_ERROR",
  },
  {
    label: "DFU_TRANSFER_ERROR",
    value: "DFU_TRANSFER_ERROR",
  },
  {
    label: "DFU_PACKET_ERROR",
    value: "DFU_PACKET_ERROR",
  },
  {
    label: "DFU_TRANSFER_COMPLETE",
    value: "DFU_TRANSFER_COMPLETE",
  },
  {
    label: "CONFIG_WRITE_ERROR",
    value: "CONFIG_WRITE_ERROR",
  },
  {
    label: "CONFIG_FORMAT_ERROR",
    value: "CONFIG_FORMAT_ERROR",
  },
  {
    label: "CONFIG_CAMERA_DETECTION_ERROR",
    value: "CONFIG_CAMERA_DETECTION_ERROR",
  },
  {
    label: "CONFIG_TRANSFER_COMPLETE",
    value: "CONFIG_TRANSFER_COMPLETE",
  },
  {
    label: "SYSTEM_STATUS_CAMERA_OUT",
    value: "SYSTEM_STATUS_CAMERA_OUT",
  },
  {
    label: "SYSTEM_STATUS_DISK_SPACE",
    value: "SYSTEM_STATUS_DISK_SPACE",
  },
  {
    label: "SYSTEM_STATUS_ODOM_ESTIMATION_ERROR",
    value: "SYSTEM_STATUS_ODOM_ESTIMATION_ERROR",
  },
  {
    label: "SYSTEM_STATUS_DEBUG_VIEW_ENABLED",
    value: "SYSTEM_STATUS_DEBUG_VIEW_ENABLED",
  },
  {
    label: "SYSTEM_STATUS_USB_HUB_NOT_DETECTED",
    value: "SYSTEM_STATUS_USB_HUB_NOT_DETECTED",
  },
  {
    label: "SYSTEM_STATUS_UNRECOGNISED_CAMERA",
    value: "SYSTEM_STATUS_UNRECOGNISED_CAMERA",
  },
  {
    label: "SYSTEM_STATUS_ODOM_DEVICE_NOT_DETECTED",
    value: "SYSTEM_STATUS_ODOM_DEVICE_NOT_DETECTED",
  },
  {
    label: "SYSTEM_STATUS_SEGMENTATION_MODE_ENABLED",
    value: "SYSTEM_STATUS_SEGMENTATION_MODE_ENABLED",
  },
  {
    label: "TYPE_CAMERA_DISCONNECTED_ERROR",
    value: "TYPE_CAMERA_DISCONNECTED_ERROR",
  },
  {
    label: "TYPE_CAMERA_OCCLUDED_ERROR",
    value: "TYPE_CAMERA_OCCLUDED_ERROR",
  },
  {
    label: "TYPE_CAMERA_ORIENTATION_ERROR",
    value: "TYPE_CAMERA_ORIENTATION_ERROR",
  },
  {
    label: "TYPE_MULTI_CAMERA_ERRORS",
    value: "TYPE_MULTI_CAMERA_ERRORS",
  },
];
