import React, { useEffect, useState } from "react";
import { withStyles, Snackbar, IconButton, Slide } from "@material-ui/core";
import { Style } from "./styles";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useAlert from "../../utils/useAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Alert = ({ classes, isSuccess, isFailed, label, duration }) => {
  const { hideAlert } = useAlert();
  const [colorClass, setColorClass] = useState("");

  useEffect(() => {
    let timeout = setTimeout(
      () => {
        hideAlert();
      },
      duration ? duration : 2000
    );
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setColorClass(classes.success);
    } else if (isFailed) {
      setColorClass(classes.failed);
    }
  }, [isSuccess, isFailed]);

  let open = isSuccess || isFailed;
  const { t } = useTranslation();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      ContentProps={{ className: colorClass }}
      TransitionComponent={Transition}
      autoHideDuration={3000}
      onClose={hideAlert}
      message={t(label)}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={hideAlert}>
            <Close fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default withStyles(Style)(Alert);
