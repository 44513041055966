import React from "react";
import InputField from "./InputField.component";
import { Asterisk } from "./InputField.component.styles";

const InputFieldWrapper = ({
  label,
  value,
  onChange,
  type,
  minLength,
  maxLength,
  placeholder,
  isError,
  errorMessage,
  required,
  autofocus,
  size,
  ...rest
}) => {
  // Add any additional conditions here
  const autofocusProps = autofocus ? { autoFocus: true } : {};

  return (
    <>
      <InputField
        label={label}
        value={value}
        onChange={onChange}
        type={type}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        isError={isError}
        errorMessage={errorMessage}
        required={required}
        {...autofocusProps}
        {...rest}
      />
      {required && <Asterisk>*</Asterisk>}
      {(label === "Worksite" ||
        label === "Asset Name" ||
        label === "Asset Type Name" ||
        label === "First and Last Name") && <Asterisk>*</Asterisk>}
    </>
  );
};

export default InputFieldWrapper;
