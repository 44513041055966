import { useState, useEffect, Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import {
  MainWrapper,
  InnerButtonWrapper,
  ButtonWrapper,
  Label,
  FooterNote,
  AlertIcon,
} from "./ApplyToAllModal.styles";
import Button from "../../../../components/Button/Button.component";
import Dropdown from "./Dropdown.component";
import {
  getUsersByWorksites,
  getAssetTypesByWorksites,
  getAssetsByAssetType,
} from "../../../../redux/users/action";
import { getUserTypeDetailList } from "../../../../redux/userType/action";

const ApplyToAllModal = (props) => {
  const {
    showModal,
    setShowModal,
    applyChanges,
    setCheckApplyToAll,
    settingsOptions,
    modalHeader,
    applyToAllObj,
    setApplyToAllObj,
    getUsersByWorksites,
    getAssetTypesByWorksites,
    getAssetsByAssetType,
    pageType,
    setClearSelection,
    clearSelection,
    isAssetPermission,
    footNote,
    permissions,
    getUserTypeDetailList,
  } = props;
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState({});
  const [checkedOptions, setCheckedOptions] = useState({});
  const [reducedDropdownItem, setReducedDropdownItem] = useState(null);
  const [accountLevelPermissionSelected, setAccountLevelPermissionSelected] = useState(false);

  const userManagementObj = permissions[0].permissions.find(
    (permission) => permission.name === "USER_MANAGEMENT"
  );

  useEffect(() => {
    if (clearSelection) {
      setCheckedOptions({});
      setClearSelection(false);
    }
  }, [clearSelection]);
  useEffect(() => {
    if (reducedDropdownItem === "worksites") {
      setSelectedItems({
        ...selectedItems,
        worksites: selectedItems.worksites,
      });
    }
    setReducedDropdownItem(null);
  }, [reducedDropdownItem]);

  useEffect(() => {
    getUserTypeDetailList();
    let checkedOptionInit = {};
    settingsOptions.map((option) => {
      checkedOptionInit = {
        ...checkedOptionInit,
        [option.radioGroup]: {
          ...checkedOptionInit[option.radioGroup],
          [option.isCheckedKey]: false,
        },
      };
    });
    setCheckedOptions(checkedOptionInit);
  }, []);

  useEffect(() => {
    if (applyToAllObj && Object.keys(applyToAllObj).length === 0) {
      setSelectedItems({});
    }
  }, [applyToAllObj]);

  const isAccountPermissionSelected = () => {
    return !!selectedItems?.userTypes?.selectedItems?.find(
      (item) => item.accessLevel === "ALL_WORKSITES" || item.accessLevel === "MULTIPLE_WORKSITES"
    );
  };

  const isCheckedOptionsValid = () => {
    //For worksite alerts
    if (settingsOptions.length === 2) {
      return (
        checkedOptions.worksites?.all ||
        (checkedOptions.worksites?.selected && selectedItems.worksites?.selectedItems?.length > 0)
      );
    } else if (checkedOptions.userTypes) {
      const isAccountPermission = isAccountPermissionSelected();
      return (
        (isAccountPermission ||
          checkedOptions.userTypes?.all ||
          checkedOptions.worksites?.all ||
          (checkedOptions.worksites?.selected && selectedItems.worksites?.selectedItems?.length > 0)) &&
        (checkedOptions.userTypes?.all ||
          (checkedOptions.userTypes?.selected && selectedItems.userTypes?.selectedItems?.length > 0)) &&
        (checkedOptions.users?.all ||
          (checkedOptions.users?.selected && selectedItems.users?.selectedItems?.length > 0))
      );
    } else {
      return (
        (checkedOptions.worksites?.all ||
          (checkedOptions.worksites?.selected && selectedItems.worksites?.selectedItems?.length > 0)) &&
        (checkedOptions.assetTypes?.all ||
          (checkedOptions.assetTypes?.selected && selectedItems.assetTypes?.selectedItems?.length > 0)) &&
        (checkedOptions.assets?.all ||
          (checkedOptions.assets?.selected && selectedItems.assets?.selectedItems?.length > 0))
      );
    }
  };

  const isAnyOptionSelected = () => {
    let isAnySelected = false;
    settingsOptions.every((option) => {
      if (checkedOptions[option.radioGroup]?.[option.isCheckedKey]) {
        isAnySelected = true;
        return false;
      }
      return true;
    });
    return isAnySelected;
  };

  const isDropdownWithSelectedItems = () => {
    let anyOptionSelected = true;
    settingsOptions.every((option) => {
      if (option.isDropdown && checkedOptions?.[option.radioGroup]?.selected) {
        if (selectedItems?.[option.radioGroup]?.selectedItems?.length > 0) {
          return true;
        } else {
          anyOptionSelected = false;
          return false;
        }
      }
      return true;
    });
    return anyOptionSelected;
  };

  const isDropdownSelected = () => {
    let anyOptionSelected = false;
    settingsOptions.every((option) => {
      if (checkedOptions?.[option.radioGroup]?.selected) {
        anyOptionSelected = true;
        return false;
      } else {
        return true;
      }
    });
    return anyOptionSelected;
  };

  const saveHandler = () => {
    const selectedField = Object.keys(checkedOptions).filter((k) => checkedOptions[k])[0];
    const saveData = isDropdownSelected() ? isDropdownWithSelectedItems() : isAnyOptionSelected();
    if (saveData) {
      setCheckApplyToAll(true);
      applyChanges(checkedOptions, selectedItems);

      let temp = {};
      settingsOptions.forEach(
        (option) =>
          (temp = {
            ...temp,
            [option.radioGroup]: {
              ...checkedOptions?.[option.radioGroup],
              ...selectedItems?.[option.radioGroup],
            },
          })
      );
      setApplyToAllObj(temp);
    } else if (!selectedField) {
      setCheckApplyToAll(null);
      applyChanges(false, []);
    }
  };

  const handleClear = () => {
    setApplyToAllObj({});
    setCheckApplyToAll(null);
    setCheckedOptions({});
  };

  const isApplyToAllObjValid = () => {
    if (applyToAllObj.userTypes) {
      return (
        (applyToAllObj.userTypes?.all ||
          applyToAllObj.worksites?.all ||
          (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
        (applyToAllObj.userTypes?.all ||
          (applyToAllObj.userTypes?.selected && applyToAllObj.userTypes?.selectedItems?.length > 0)) &&
        (applyToAllObj.users?.all ||
          (applyToAllObj.users?.selected && applyToAllObj.users?.selectedItems?.length > 0))
      );
    } else {
      return (
        (applyToAllObj.worksites?.all ||
          (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
        (applyToAllObj.assetTypes?.all ||
          (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0)) &&
        (applyToAllObj.assets?.all ||
          (applyToAllObj.assets?.selected && applyToAllObj.assets?.selectedItems?.length > 0))
      );
    }
  };

  const handleClose = () => {
    if (!isApplyToAllObjValid()) {
      setApplyToAllObj({});
      setCheckedOptions({});
    }
    setShowModal(false);
  };

  const radioChangeHandler = (target, radioGroup) => {
    let updatableOptions = {};
    if (radioGroup === "userTypes") {
      if (target === "all") {
        setSelectedItems({});
        if (userManagementObj?.action?.view) {
          getUsersByWorksites("all", "all", isAssetPermission);
        }
      }
      updatableOptions = {
        userTypes: {},
        users: {},
        worksites: {},
      };
    } else if (radioGroup === "worksites") {
      if (target === "all") {
        if (userManagementObj?.action?.view) {
          if (selectedItems?.userTypes?.selectedItems?.length > 0) {
            const permissions = selectedItems.userTypes.selectedItems.map(
              (selectedItem) => selectedItem.value
            );
            getUsersByWorksites("all", permissions, isAssetPermission);
          } else {
            getUsersByWorksites("all", "all", isAssetPermission);
          }
        }
        //get all worksites
        getAssetTypesByWorksites(["all"]);
      }
      if (checkedOptions.users && checkedOptions.userTypes) {
        updatableOptions = {
          users: {},
        };
        setSelectedItems({
          ...selectedItems,
          worksites: {},
          users: {},
        });
      } else {
        updatableOptions = {
          assetTypes: {},
          assets: {},
        };
        setSelectedItems({});
      }
    }

    if (radioGroup === "assetTypes") {
      if (target === "all" && selectedItems.worksites.selectedItems.length > 0) {
        getAssetsByAssetType({
          siteIds: selectedItems.worksites.selectedItems.map((item) => item.value),
          assetTypeIds: "all",
        });
      }
      if (target === "all" && selectedItems.worksites.selectedItems.length == 0) {
        getAssetsByAssetType({ siteIds: "all", assetTypeIds: "all" });
      }

      updatableOptions = {
        assets: {},
      };
      setSelectedItems({
        ...selectedItems,
        assets: {},
      });
    }

    let checkedOptionTemp = { ...checkedOptions, ...updatableOptions };

    const updatedRadioStatus = !checkedOptionTemp[radioGroup]?.[target];

    for (const key in checkedOptionTemp) {
      if (updatedRadioStatus && key !== target) {
        checkedOptionTemp = {
          ...checkedOptionTemp,
          [radioGroup]: {
            [target]: false,
          },
        };
      }
    }
    if (updatedRadioStatus) {
      checkedOptionTemp = {
        ...checkedOptionTemp,
        [radioGroup]: {
          [target]: true,
        },
      };
    }
    setCheckedOptions(checkedOptionTemp);
  };

  const isRadioDisabled = (radioGroup, index, isDropdown) => {
    if (radioGroup === "userTypes") {
      return !isDropdown && isAssetPermission;
    } else if (radioGroup === "worksites") {
      if ((accountLevelPermissionSelected || checkedOptions?.userTypes?.all) && !isAssetPermission) {
        return true;
      } else if (
        index === 0 ||
        index === 1 ||
        (checkedOptions?.userTypes?.selected && selectedItems?.userTypes?.selectedItems?.length > 0) ||
        checkedOptions?.userTypes?.all
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      radioGroup === "users" &&
      (accountLevelPermissionSelected ||
        checkedOptions?.userTypes?.all ||
        checkedOptions?.worksites?.all ||
        selectedItems?.worksites?.selectedItems?.length > 0)
    ) {
      return false;
    } else if (
      radioGroup === "assetTypes" &&
      (checkedOptions?.worksites?.all || selectedItems?.worksites?.selectedItems?.length > 0)
    ) {
      return false;
    } else if (
      radioGroup === "assets" &&
      (checkedOptions?.assetTypes?.all || selectedItems?.assetTypes?.selectedItems?.length > 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  let optionGroup;
  const getOptionElement = (option, index) => {
    const optionElem = (
      <Fragment key={index}>
        {optionGroup && optionGroup !== option.radioGroup && (
          <hr style={{ clear: "both", marginTop: "1rem", float: "left", width: "100%;" }} />
        )}
        <div>
          <input
            type="radio"
            id={option.radioGroup + "_" + option.id}
            name={option.radioGroup || "radio-group"}
            checked={!!checkedOptions[option.radioGroup]?.[option.isCheckedKey]}
            onClick={(e) => radioChangeHandler(option.isCheckedKey, option.radioGroup)}
            disabled={isRadioDisabled(option.radioGroup, index, option.isDropdown)}
          />
          <Label
            htmlFor={option.radioGroup + "_" + option.id}
            disabled={isRadioDisabled(option.radioGroup, index, option.isDropdown)}
          >
            {option.label}
          </Label>
        </div>
        {option.isDropdown && (
          <div style={{ marginBottom: "1rem", overflow: "hidden" }}>
            <Dropdown
              setReducedDropdownItem={setReducedDropdownItem}
              option={option}
              applyAllSelectedAssetTypes={applyToAllObj?.selectedItems || []}
              checkedOptions={checkedOptions}
              setCheckedOptions={setCheckedOptions}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              pageType={pageType}
              setAccountLevelPermissionSelected={setAccountLevelPermissionSelected}
              isAssetPermission={isAssetPermission}
            />
          </div>
        )}
      </Fragment>
    );
    optionGroup = option.radioGroup;
    return optionElem;
  };

  const translatedHeader = `${t(modalHeader, { defaultValue: modalHeader })} | ${t("applyTo")}`;

  return (
    <div>
      <Modal show={showModal} onHide={handleClose} size="md" centered dialogClassName="custom-modal-class">
        <Modal.Header className="p-3">
          <Modal.Title>{translatedHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MainWrapper>
            {settingsOptions &&
              settingsOptions.map((option, index) => {
                return getOptionElement(option, index);
              })}
          </MainWrapper>
        </Modal.Body>
        <Modal.Footer>
          <ButtonWrapper>
            {isAnyOptionSelected() && (
              <Button
                label={t("Clear Selection")}
                onClick={handleClear}
                showIcon={false}
                buttonType="ghost"
              />
            )}

            <InnerButtonWrapper>
              <Button
                label={t("Cancel")}
                onClick={handleClose}
                showIcon={false}
                buttonType="ghost"
                style={{ marginRight: "10px" }}
              />
              <Button
                label={t("Save")}
                onClick={saveHandler}
                showIcon={false}
                iconClass="save"
                buttonType="save"
                disabled={!isCheckedOptionsValid()}
              />
            </InnerButtonWrapper>
          </ButtonWrapper>
        </Modal.Footer>
        {footNote && (
          <FooterNote>
            <AlertIcon className="icon icon-alert-icon"></AlertIcon>
            {footNote}
          </FooterNote>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersByWorksites: (siteIDs, permissions, isAssetPermission) =>
    dispatch(getUsersByWorksites(siteIDs, permissions, isAssetPermission)),
  getAssetTypesByWorksites: (siteIDs) => dispatch(getAssetTypesByWorksites(siteIDs)),
  getAssetsByAssetType: (data) => dispatch(getAssetsByAssetType(data)),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToAllModal);
