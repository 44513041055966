export const tableHeader = [
  { name: "Account", fieldName: "accountId", show: true },
  { name: "Worksite", fieldName: "siteId", show: true },
  { name: "Asset", fieldName: "assetId", show: true },
  { name: "User", fieldName: "userId", show: true },
  { name: "Date/Time", fieldName: "loginTime", show: true },
  { name: "Asset Status", fieldName: "event", show: true },

  { name: "User Type", fieldName: "userType" },
  { name: "Unlock Method", fieldName: "unLockMethod" },
  { name: "Lock Reason", fieldName: "lockReason" },
];

export const tableRowData = [
  {
    name: "Account ",
    keyName: "accountName",
    space: 0,
    fieldName: "companyName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, fieldName: "siteName" },

  {
    name: "Asset",
    keyName: "assetImpacted",
    space: 0,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
  {
    name: "User Type",
    keyName: "userType",
    space: 0,
    fieldName: "userType",
  },
  { name: "Unlock Method", keyName: "unLockMethod", space: 0, fieldName: "unLockMethod" },
];

//This is for desktop Accordian only

export const accordionFields = ["User Type", "Unlock Method", "Lock Reason"];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

// This below code is for mobile accordian view render
export const mobileCheckboxAccordion = ["User Type", "Unlock Method", "Lock Reason"];

// This below code is for mobile view render

export const mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, fieldName: "siteName" },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 1,
    fieldName: "fullName",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
];
export const mobileCheckboxDateTime = [
  { columnName: "Account", className: "accountStyle" },
  { columnName: "Worksite", className: "worksiteStyle" },
  { columnName: "Asset Name", className: "worksiteStyle" },
  { columnName: "User", className: "worksiteStyle" },
  { columnName: "Date/Time", className: "reportStatusStyle" },
  { columnName: "Asset Status", className: "event" },
  { name: "User Type", fieldName: "userType" },
  { name: "Unlock Method", fieldName: "unLockMethod" },
];
