import { accountTypes } from "./account.types";

const INTIAL_STATE = {
  // Current Account Lists
  accountList: null,
};

export const accountReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    //Case 1. Returning account list as payload
    case accountTypes.LIST_ACCOUNT:
      return {
        ...state,
        accountList: action.payload,
      };

    // Returning default state
    default:
      return state;
  }
};
