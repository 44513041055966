import { menuStateTypes } from "./menu.types";

const INTIAL_STATE = {
  //1. menu state
  menuState: { section: "management", subSection: 0 },
  reportMenuState: { section: "report", subSection: 0 },
};

export const sideMenuReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    // Case 1: Selecting the menu state
    case menuStateTypes.UPDATE_MENU:
      return {
        ...state,
        menuState: action.payload,
      };

    case menuStateTypes.UPDATE_REPORT_TAB:
      return {
        ...state,
        reportMenuState: action.payload,
      };
    case menuStateTypes.UPDATE_ACTIVE_ITEM:
      return {
        ...state,
        activeNavItem: action.payload,
      };
    // Returning default state
    default:
      return state;
  }
};
