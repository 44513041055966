import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import SimpleDropdownFilter from "../../../../components/SimpleDropdownFilter.component";

function TimezoneSelect({ selectedTimezone, setSelectedTimezone }) {
  const [timezones, setTimezones] = useState([]);

  const getTimeZones = () => {
    return timezones;
  };

  useEffect(() => {
    const fetchTimezones = () => {
      const timezones = moment.tz.names().map((name) => ({
        label: name + " (" + moment.tz(name).format("Z") + ")",
        value: name,
      }));
      setTimezones(timezones);
    };
    fetchTimezones();
  }, []);

  return (
    <>
      <SimpleDropdownFilter
        options={timezones}
        type="select"
        as="select"
        showLabel={true}
        label={"Timezone"}
        isValidationError={false}
        value={selectedTimezone.value}
        handleFilter={(value) => {
          console.log(value);
          setSelectedTimezone(value);
        }}
        sortData={false}
      />
    </>
  );
}

export default TimezoneSelect;
