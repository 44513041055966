import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { isEqual } from "lodash";

import {
  getS3CameraSetting,
  updateS3CameraSetting,
  getS3CameraSettingByAssetId,
  updateS3CameraSettingsByAssetId,
  resetS3CameraSettingsByAssetId,
  resetS3CameraSettingError,
} from "../../../../redux/config/action";
import CameraLocation from "./CameraLocation/CameraLocation";
import CameraSetting from "./CameraSettingView/CameraSetting";
import SpeedControlSetting from "./speedControl/SpeedControlSetting";

import CameraPreferences from "./CameraPreferences/CameraPreferences";
import { getCameraRawObject } from "./helper";
import SnackBar from "../../SnackBar";
import toasterConstant from "../../../../constant/messages/toaster.json";
//import { ReactComponent as Information } from "../../../../assets/Information_Icon.svg";
import "./CameraSetting.css";
import { defaultInputValues, cameraPositionObj, camPositionToCamNumber } from "./constants";
import { DropdownWrapper } from "./S3CameraSettings.styles";
import ConfirmationModal from "./ConfirmationModal";
import SimpleDropdownFilter from "../../../../components/SimpleDropdownFilter.component";
import ActionButtons from "../../common/ActionButtons";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";
import {
  SettingsContainer,
  SettingsFooter,
  CameraTabNavContainer,
  LoaderOverlay,
} from "../../Profile/Settings.Styles";
import {
  bulkUpdateCameraSettings,
  resetNotificationSettingsFlags,
  resetBulkUpdateCameraSettingsFlags,
} from "../../../../redux/config/action";

import S3SettingsUpdateResult from "./S3SettingsUpdateResult";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

const { camera_setting_updated } = toasterConstant;

function S3CameraSettings({
  unitType,
  updateS3CameraSetting,
  getS3CameraSetting,
  dropdownAssetTypeId,
  dropdownAssetId,
  updateS3CameraSettingLoading,
  getS3CameraSettingLoading,
  s3CameraSettingData,
  resetS3CameraSettingLoading,
  showCameraSetting,
  dropdownWorksiteId,
  dropdownAccountId,
  userAccountId,
  isSuperAdmin,

  getS3CameraSettingByAssetId,
  updateS3CameraSettingsByAssetId,
  resetS3CameraSettingsByAssetId,
  bulkUpdateCameraSettings,

  resetNotificationSettingsFlags,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
  s3CameraSettingUpdateStatusData,
  updateS3CameraSettingSuccess,
  resetBulkUpdateCameraSettingsFlags,
  bulkUpdateS3CameraSettingSuccess,
  isSystemSettingsEditable,
  updateS3CameraSettingError,
  resetS3CameraSettingError,
}) {
  const { t } = useTranslation();
  const [steps, setSteps] = useState(1);
  const [activeSettingsTab, setActiveSettingsTab] = useState(3);
  const [resetModal, setResetModal] = useState(false);
  const [cameraPosition, setCameraPosition] = useState({
    1: "rear",
    2: "front",
    3: "left",
    4: "right",
  });

  const [s3CameraForm, setS3CameraForm] = useState({});

  //const [speedControlForm, setSpeedControlForm] = useState({});

  const [inputValue, setInputValue] = useState(defaultInputValues);
  const [speedControlInputValue, setSpeedControlInputValue] = useState(defaultInputValues);

  const [alertError, setAlertError] = useState("");
  const [alertSuccess, setAlertSuccess] = useState("");
  const [isFeet, setIsFeet] = useState(true);
  const [s3SettingsUntouchedObject, setS3SettingsUntouchedObject] = useState(s3CameraSettingData);
  const [cameraPositionArray, setCameraPositionArray] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("rear");
  const [switchStatusObject, setSwitchStatusObject] = useState({});

  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [applyAllSelectedAssetTypes, setApplyAllSelectedAssetTypes] = useState([]);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  //const [updatableChanges, setUpdatableChanges] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const settingsOptions = [
    {
      label: t("All Worksites"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: t("Selected Worksites"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
    {
      label: t("All Asset Types"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assetTypes",
    },
    {
      label: t("Selected Asset Types"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "AssetTypeName",
      radioGroup: "assetTypes",
      dropdown: {
        placeholder: "Select an Asset Type",
      },
    },
    {
      label: t("All Assets"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assets",
    },
    {
      label: t("Selected Assets"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "assetName",
      radioGroup: "assets",
      dropdown: {
        placeholder: "Select an Asset",
      },
    },
  ];

  useEffect(() => {
    if (notificationBulkUpdateSuccess || updateS3CameraSettingSuccess || bulkUpdateS3CameraSettingSuccess) {
      getS3CameraSettingByAssetId(dropdownAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);

      setClearSelection(true);
      //resetBulkUpdateCameraSettingsFlags();
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess, updateS3CameraSettingSuccess, bulkUpdateS3CameraSettingSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = (isApplyAll, selectedItems) => {
    setCheckApplyToAll(isApplyAll || selectedItems.length > 0);
    toggleApplyToAllModal();
  };

  const handleCancel = () => {
    //getS3CameraSetting(dropdownAssetId);
    setClearSelection(true);
    getS3CameraSettingByAssetId(dropdownAssetId);
    setApplyToAllObj({});
  };

  useEffect(() => {
    const s3CamPosition = (s3CameraSettingData?.[0]?.position || "rear").toLowerCase();
    setS3SettingsUntouchedObject(s3CameraSettingData);
    setSelectedCamera(s3CamPosition);
  }, [s3CameraSettingData]);

  useEffect(() => {
    if (unitType?.toLowerCase() === "metric") {
      setIsFeet(false);
    }
  }, [unitType]);

  useEffect(() => {
    //getS3CameraSetting(dropdownAssetId);
    getS3CameraSettingByAssetId(dropdownAssetId);
  }, [dropdownAssetId]);

  useEffect(() => {
    if (!s3CameraSettingData) {
      return;
    }
    let newS3CameraForm = {};
    let switchStatusObjectTemp = {};

    s3CameraSettingData?.[0] &&
      s3CameraSettingData?.map((data, i) => {
        let { pedestrian, object } = data.nearMissDetection;
        const { pedestrian: sczPedestrian, object: sczObject } = data.speedControlZones;
        let sczPedestrianTemp = {};
        let sczObjectTemp = {};

        switchStatusObjectTemp[data?.position?.toLowerCase()] = {
          pedestrian: {
            inMotion: !!data?.cameraPreference?.pedestrian?.inMotion,
            inStationary: !!data?.cameraPreference?.pedestrian?.inStationary,
          },
          object: {
            inMotion: !!data?.cameraPreference?.object?.inMotion,
            inStationary: !!data?.cameraPreference?.object?.inStationary,
          },
        };

        if (isFeet) {
          sczPedestrianTemp = {
            left: Math.abs(sczPedestrian?.left / 305),
            right: Math.abs(sczPedestrian?.right / 305),
            top: Math.abs(sczPedestrian?.top / 305),
            middle: Math.abs(sczPedestrian?.middle / 305),
            bottom: Math.abs(sczPedestrian?.bottom / 305),
            zones: {
              one: parseInt(sczPedestrian?.zones?.one / 305),
              two: parseInt(sczPedestrian?.zones?.two / 305),
              three: parseInt(sczPedestrian?.zones?.three / 305),
              four: parseInt(sczPedestrian?.zones?.four / 305),
            },
          };
          sczObjectTemp = {
            left: Math.abs(sczObject?.left / 305),
            right: Math.abs(sczObject?.right / 305),
            top: Math.abs(sczObject?.top / 305),
            middle: Math.abs(sczObject?.middle / 305),
            bottom: Math.abs(sczObject?.bottom / 305),
            zones: {
              one: parseInt(sczObject?.zones?.one / 305),
              two: parseInt(sczObject?.zones?.two / 305),
              three: parseInt(sczObject?.zones?.three / 305),
              four: parseInt(sczObject?.zones?.four / 305),
            },
          };

          pedestrian = {
            left: Math.abs(pedestrian?.left / 305),
            right: Math.abs(pedestrian?.right / 305),
            top: Math.abs(pedestrian?.top / 305),
            middle: Math.abs(pedestrian?.middle / 305),
            bottom: Math.abs(pedestrian?.bottom / 305),
            depth: Math.abs(pedestrian?.depth / 305),
          };
          object = {
            left: Math.abs(object?.left / 305),
            right: Math.abs(object?.right / 305),
            top: Math.abs(object?.top / 305),
            middle: Math.abs(object?.middle / 305),
            bottom: Math.abs(object?.bottom / 305),
            depth: Math.abs(object?.depth / 305),
          };
        } else {
          sczPedestrianTemp = {
            left: Math.abs(sczPedestrian?.left),
            right: Math.abs(sczPedestrian?.right),
            top: Math.abs(sczPedestrian?.top),
            middle: Math.abs(sczPedestrian?.middle),
            bottom: Math.abs(sczPedestrian?.bottom),
            zones: {
              one: parseInt(sczPedestrian?.zones?.one),
              two: parseInt(sczPedestrian?.zones?.two),
              three: parseInt(sczPedestrian?.zones?.three),
              four: parseInt(sczPedestrian?.zones?.four),
            },
          };
          sczObjectTemp = {
            left: Math.abs(sczObject?.left),
            right: Math.abs(sczObject?.right),
            top: Math.abs(sczObject?.top),
            middle: Math.abs(sczObject?.middle),
            bottom: Math.abs(sczObject?.bottom),
            zones: {
              one: parseInt(sczObject?.zones?.one),
              two: parseInt(sczObject?.zones?.two),
              three: parseInt(sczObject?.zones?.three),
              four: parseInt(sczObject?.zones?.four),
            },
          };

          pedestrian = {
            left: Math.abs(pedestrian?.left),
            right: Math.abs(pedestrian?.right),
            top: Math.abs(pedestrian?.top),
            middle: Math.abs(pedestrian?.middle),
            bottom: Math.abs(pedestrian?.bottom),
            depth: Math.abs(pedestrian?.depth),
          };
          object = {
            left: Math.abs(object?.left),
            right: Math.abs(object?.right),
            top: Math.abs(object?.top),
            middle: Math.abs(object?.middle),
            bottom: Math.abs(object?.bottom),
            depth: Math.abs(object?.depth),
          };
        }

        newS3CameraForm[i] = {
          pedestrian: { ...pedestrian },
          object: { ...object },
          sensor: data.sensor,
          position: data?.position,
          cameraId: data?.cameraId,
          sczPedestrian: sczPedestrianTemp,
          sczObject: sczObjectTemp,
        };
      });

    setSwitchStatusObject(switchStatusObjectTemp);
    setS3CameraForm({ ...newS3CameraForm });
  }, [s3CameraSettingData, isFeet]);

  /* Apply to all overlay text */
  /*   const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>Click here to reset to default system values.</Trans>
    </Tooltip>
  ); */

  const noS3SettingData =
    !s3SettingsUntouchedObject || (s3SettingsUntouchedObject && !s3SettingsUntouchedObject?.[0]);

  const doesCamsNeedSetup = s3SettingsUntouchedObject && s3SettingsUntouchedObject.length === 0;

  const enableCameraLocations = () => {
    const cameraObjArr = [];
    s3SettingsUntouchedObject?.forEach((camera) => {
      const cameraPosition = camera?.position?.toLowerCase();
      cameraPositionObj[cameraPosition].isDisabled = false;
      cameraObjArr.push(cameraPositionObj[cameraPosition]);
    });

    return cameraObjArr;
  };

  useEffect(() => {
    if (noS3SettingData || !showCameraSetting) {
      setSteps(1);
    }
  }, [noS3SettingData, showCameraSetting]);

  useEffect(() => {
    const camArr = enableCameraLocations();
    setCameraPositionArray(camArr);
    const cameraPosition = camArr.map((data, i) => ({
      label: data.position.split("-").join(" "),
      value: data?.position?.toLowerCase(),
    }));

    setPositionList([...cameraPosition]);
  }, [s3SettingsUntouchedObject]);

  const handleCameraSelectionChange = (option) => {
    setSelectedCamera(option.value);
  };

  const getSwitchStatus = () => {
    const switchStatusIndex = camPositionToCamNumber[selectedCamera] - 1;
    return switchStatusObject[switchStatusIndex];
  };

  const getCurrentNearMissData = (pedestrian, object) => {
    const multiplier = isFeet ? 305 : 1;
    return {
      pedestrian: {
        left: -parseInt(pedestrian?.left * multiplier),
        right: parseInt(pedestrian?.right * multiplier),
        depth: parseInt(pedestrian?.depth * multiplier),
        top: parseInt(pedestrian?.top * multiplier),
        bottom: parseInt(pedestrian?.bottom * multiplier),
        middle: parseInt(pedestrian?.middle * multiplier),
      },
      object: {
        left: -parseInt(object?.left * multiplier),
        right: parseInt(object?.right * multiplier),
        depth: parseInt(object?.depth * multiplier),
        top: parseInt(object?.top * multiplier),
        bottom: parseInt(object?.bottom * multiplier),
        middle: parseInt(object?.middle * multiplier),
      },
    };
  };

  const getSpeedControlData = (sczPedestrian, sczObject) => {
    const multiplier = isFeet ? 305 : 1;
    return {
      object: {
        left: -parseInt(sczObject?.left * multiplier),
        right: parseInt(sczObject?.right * multiplier),
        top: parseInt(sczObject?.top * multiplier),
        bottom: parseInt(sczObject?.bottom * multiplier),
        middle: parseInt(sczObject?.middle * multiplier),
        zones: {
          one: parseInt(sczObject?.zones?.one * multiplier),
          two: parseInt(sczObject?.zones?.two * multiplier),
          three: parseInt(sczObject?.zones?.three * multiplier),
          four: parseInt(sczObject?.zones?.four * multiplier),
        },
      },
      pedestrian: {
        left: -parseInt(sczPedestrian?.left * multiplier),
        right: parseInt(sczPedestrian?.right * multiplier),
        top: parseInt(sczPedestrian?.top * multiplier),
        bottom: parseInt(sczPedestrian?.bottom * multiplier),
        middle: parseInt(sczPedestrian?.middle * multiplier),
        zones: {
          one: parseInt(sczPedestrian?.zones?.one * multiplier),
          two: parseInt(sczPedestrian?.zones?.two * multiplier),
          three: parseInt(sczPedestrian?.zones?.three * multiplier),
          four: parseInt(sczPedestrian?.zones?.four * multiplier),
        },
      },
    };
  };

  /**Below code is to convert UI data to BE understandable data */
  const getUpdatableSpeedControlData = (sczPedestrian, sczObject) => {
    const multiplier = isFeet ? 305 : 1;
    return {
      object: {
        left: -parseInt(sczObject?.left * multiplier),
        right: parseInt(sczObject?.right * multiplier),
        top: parseInt(sczObject?.top * multiplier),
        bottom: parseInt(sczObject?.bottom * multiplier),
        middle: parseInt(sczObject?.middle * multiplier),
        zones: {
          one: {
            min: parseInt(sczObject?.zones?.one * multiplier),
            max: parseInt(sczObject?.zones?.two * multiplier),
          },
          two: {
            min: parseInt(sczObject?.zones?.two * multiplier),
            max: parseInt(sczObject?.zones?.three * multiplier),
          },
          three: {
            min: parseInt(sczObject?.zones?.three * multiplier),
            max: parseInt(sczObject?.zones?.four * multiplier),
          },
          four: {
            min: parseInt(sczObject?.zones?.four * multiplier),
            max: 10000,
          },
        },
      },
      pedestrian: {
        left: -parseInt(sczPedestrian?.left * multiplier),
        right: parseInt(sczPedestrian?.right * multiplier),
        top: parseInt(sczPedestrian?.top * multiplier),
        bottom: parseInt(sczPedestrian?.bottom * multiplier),
        middle: parseInt(sczPedestrian?.middle * multiplier),
        zones: {
          one: {
            min: parseInt(sczPedestrian?.zones?.one * multiplier),
            max: parseInt(sczPedestrian?.zones?.two * multiplier),
          },
          two: {
            min: parseInt(sczPedestrian?.zones?.two * multiplier),
            max: parseInt(sczPedestrian?.zones?.three * multiplier),
          },
          three: {
            min: parseInt(sczPedestrian?.zones?.three * multiplier),
            max: parseInt(sczPedestrian?.zones?.four * multiplier),
          },
          four: {
            min: parseInt(sczPedestrian?.zones?.four * multiplier),
            max: 10000,
          },
        },
      },
    };
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.assetTypes?.all ||
        (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0))
    );
  };

  useEffect(() => {
    if (updateS3CameraSettingError) {
      setAlertError(true);
    }
  }, [updateS3CameraSettingError]);

  const submitForm = () => {
    setAlertError(false);
    const updatableCameraSettings = [];

    Object.keys(s3CameraForm).map((camPosition, index) => {
      const cameraSettingData = s3CameraForm[camPosition];
      const { object, pedestrian, position, sczPedestrian, sczObject } = cameraSettingData;
      const currentNearMiss = getCurrentNearMissData(pedestrian, object);
      const speedControlData = getUpdatableSpeedControlData(sczPedestrian, sczObject);

      updatableCameraSettings.push({
        cameraPreference: { ...switchStatusObject[position?.toLowerCase()] },
        nearMissDetection: currentNearMiss,
        speedControlZones: speedControlData,
        position: s3SettingsUntouchedObject[index]?.position,
        sensor: s3SettingsUntouchedObject[index]?.sensor,
        cameraId: s3SettingsUntouchedObject[index]?.cameraId,
      });
    });

    let selectedWorksites = [];
    let selectedAssetTypes = [];

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(dropdownWorksiteId)) {
        selectedWorksites = [...selectedWorksites, dropdownWorksiteId];
      }
    } else {
      selectedWorksites = [dropdownWorksiteId];
    }

    if (applyToAllObj.assetTypes?.all) {
      selectedAssetTypes = ["all"];
    } else if (applyToAllObj.assetTypes?.selected) {
      applyToAllObj?.assetTypes?.selectedItems?.forEach((assetType) =>
        selectedAssetTypes.push(assetType.value)
      );
      if (!selectedAssetTypes.includes(dropdownAssetTypeId)) {
        selectedAssetTypes = [...selectedAssetTypes, dropdownAssetTypeId];
      }
    } else {
      selectedAssetTypes = [dropdownAssetTypeId];
    }

    const updatableObj = {
      cameraSettings: updatableCameraSettings,
    };

    if (Object.keys(applyToAllObj).length !== 0) {
      const siteIds = applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const assetTypeIds = applyToAllObj?.assetTypes?.selectedItems?.map((assetType) => assetType.value);
      const assetIds = applyToAllObj?.assets?.selectedItems?.map((asset) => asset.value);

      const updatableSettings = {
        sites: siteIds,
        assetTypes: assetTypeIds,
        assets: assetIds,
        settings: updatableObj,
      };
      bulkUpdateCameraSettings(updatableSettings, setIsUpdateLoading);
    }
    updateS3CameraSettingsByAssetId(dropdownAssetId, updatableObj, () => {
      setAlertSuccess(t(camera_setting_updated));
      setIsUpdateLoading(true);
    });
  };

  const isUpdateAvailable = () => {
    let isSettingsUpdatable = false;
    const applyToAllObjValid = isApplyToAllObjValid();
    Object.keys(s3CameraForm).map((camPosition, index) => {
      const cameraSettingData = s3CameraForm[camPosition];
      const { object, pedestrian, sczObject, sczPedestrian, position } = cameraSettingData;
      const currentNearMiss = getCurrentNearMissData(pedestrian, object);
      const currentSpeedControl = getSpeedControlData(sczPedestrian, sczObject);

      const unTouchedObj = s3SettingsUntouchedObject[index];
      const cameraPreference = switchStatusObject[position?.toLowerCase()];

      if (!isSettingsUpdatable) {
        isSettingsUpdatable =
          !isEqual(unTouchedObj.cameraPreference, cameraPreference) ||
          !isEqual(unTouchedObj.nearMissDetection, currentNearMiss) ||
          !isEqual(unTouchedObj.speedControlZones, currentSpeedControl) ||
          !!applyToAllObjValid;
      }
    });
    return isSettingsUpdatable;
  };

  /*   const resetSetting = () => {
    resetS3CameraSetting(dropdownAssetId, () => {
      getS3CameraSetting(dropdownAssetId);
      setResetModal(false);
    });
  }; */

  const onReset = () => {
    resetS3CameraSettingsByAssetId(dropdownAssetId, () => {
      setResetModal(false);
      getS3CameraSettingByAssetId(dropdownAssetId);
    });
  };

  const handleOK = () => {
    setAlertError(false);
    setAlertSuccess(false);
    setIsUpdateLoading(false);
    setApplyToAllObj({});
    setClearSelection(true);
    resetBulkUpdateCameraSettingsFlags();
  };

  const handleCloseSnackBar = () => {
    setAlertError(false);
    setAlertSuccess(false);
    setIsUpdateLoading(false);
    setApplyToAllObj({});
    setClearSelection(true);
    resetS3CameraSettingError();
    resetNotificationSettingsFlags();
  };

  return (
    <>
      <SettingsContainer>
        {/*  <h4 className="pb-2 text-left RespH4 text-kanit">
          <trans>Camera Settings</trans>
        </h4>*/}

        {showCameraSetting && getS3CameraSettingLoading ? (
          <LoaderOverlay>
            <SieraLoader OnlyLoaderIcon size="small" />
          </LoaderOverlay>
        ) : (
          <CameraTabNavContainer>
            <Tabs
              activeKey={steps}
              transition={false}
              id="noanim-tab-example"
              className="text-lg d-flex relative s3-setting-tab text-open-sans"
              style={{ zIndex: "1" }}
              variant="pills"
              onSelect={(value) => setSteps(value)}
            >
              <Tab eventKey="1" title={t("Camera Setup")}>
                {showCameraSetting ? (
                  getS3CameraSettingLoading ? (
                    <Skeleton className="mt-4 pt-4 mb-4 pb-4" height={70} count={4} />
                  ) : (
                    <CameraLocation
                      s3CameraForm={s3CameraForm}
                      cameraPosition={cameraPosition}
                      setCameraPosition={setCameraPosition}
                      noS3SettingData={noS3SettingData}
                      noS3SettingDataAction={onReset}
                      s3SettingsUntouchedObject={s3SettingsUntouchedObject}
                      cameraPositionArray={cameraPositionArray}
                      positionList={positionList}
                      doesCamsNeedSetup={doesCamsNeedSetup}
                      getS3CameraSettingLoading={getS3CameraSettingLoading}
                    />
                  )
                ) : (
                  <p className="default-text text-open-sans">
                    <Trans>Please add an asset to this worksite, to apply the S3 Settings</Trans>.<br />
                    <Trans>There is no S3 device available in this worksite</Trans>.
                  </p>
                )}
              </Tab>

              <Tab eventKey="2" title={t("Camera Settings")} disabled={noS3SettingData || !showCameraSetting}>
                <br />
                <DropdownWrapper>
                  <SimpleDropdownFilter
                    value={selectedCamera}
                    useAsDropdown={true}
                    filter={{
                      type: "cam-position",
                    }}
                    options={positionList}
                    placeholder={"Please select"}
                    label={t("Camera Position")}
                    handleFilter={handleCameraSelectionChange}
                  />
                </DropdownWrapper>

                <Tabs
                  activeKey={activeSettingsTab}
                  transition={false}
                  id="noanim-tab-example"
                  className="text-lg d-flex relative s3-setting-tab text-open-sans"
                  style={{ marginTop: "20px", zIndex: "1" }}
                  variant="pills"
                  onSelect={(value) => setActiveSettingsTab(value)}
                >
                  <Tab
                    eventKey="3"
                    title={t("Camera Preferences")}
                    disabled={noS3SettingData || !showCameraSetting}
                  >
                    {showCameraSetting ? (
                      getS3CameraSettingLoading ? (
                        <Skeleton className="mt-4 pt-4 mb-4 pb-4" height={70} count={4} />
                      ) : (
                        <CameraPreferences
                          switchStatus={getSwitchStatus()}
                          setSwitchStatus={setSwitchStatusObject}
                          cameraPosition={cameraPosition}
                          selectedCamera={selectedCamera}
                          switchStatusObject={switchStatusObject}
                          isSystemSettingsEditable={isSystemSettingsEditable}
                        />
                      )
                    ) : (
                      <p className="default-text text-open-sans">
                        <Trans>Please add an asset to this worksite, to apply the S3 Settings</Trans>.<br />
                        <Trans>There is no S3 device available in this worksite</Trans>.
                      </p>
                    )}
                  </Tab>
                  <Tab eventKey="4" title={t("Near-Miss Detection")} disabled={noS3SettingData}>
                    {showCameraSetting ? (
                      getS3CameraSettingLoading ? (
                        <Skeleton className="mt-4 pt-4 mb-4 pb-4" height={70} count={4} />
                      ) : (
                        <CameraSetting
                          switchStatus={switchStatusObject}
                          isFeet={isFeet}
                          steps={steps}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          getCameraRawObject={getCameraRawObject}
                          s3CameraForm={s3CameraForm}
                          setS3CameraForm={setS3CameraForm}
                          cameraPosition={cameraPosition}
                          setCameraPosition={setCameraPosition}
                          unitType={unitType}
                          s3SettingsUntouchedObject={s3SettingsUntouchedObject}
                          selectedCamera={selectedCamera}
                        />
                      )
                    ) : (
                      <p className="default-text text-open-sans">
                        <Trans>Please add an asset to this worksite, to apply the S3 Settings</Trans>.<br />
                        <Trans>There is no S3 device available in this worksite</Trans>.
                      </p>
                    )}
                  </Tab>
                  <Tab eventKey="5" title={t("SPEED_CONTROL_ZONES")} disabled={noS3SettingData}>
                    {showCameraSetting ? (
                      getS3CameraSettingLoading ? (
                        <Skeleton className="mt-4 pt-4 mb-4 pb-4" height={70} count={4} />
                      ) : (
                        <SpeedControlSetting
                          switchStatus={switchStatusObject}
                          isFeet={isFeet}
                          steps={steps}
                          inputValue={speedControlInputValue}
                          setInputValue={setSpeedControlInputValue}
                          getCameraRawObject={getCameraRawObject}
                          s3CameraForm={s3CameraForm}
                          setS3CameraForm={setS3CameraForm}
                          cameraPosition={cameraPosition}
                          setCameraPosition={setCameraPosition}
                          unitType={unitType}
                          s3SettingsUntouchedObject={s3SettingsUntouchedObject}
                          selectedCamera={selectedCamera}
                        />
                      )
                    ) : (
                      <p className="default-text text-open-sans">
                        <Trans>Please add an asset to this worksite, to apply the S3 Settings</Trans>.<br />
                        <Trans>There is no S3 device available in this worksite</Trans>.
                      </p>
                    )}
                  </Tab>
                  {/* <Tab eventKey="4" title="Slow-Down Detection">
                        <CameraSettingView
                            isFeet={isFeet}
                            steps={steps}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            getCameraRawObject={getCameraRawObject}
                            s3CameraForm={s3CameraForm}
                            setS3CameraForm={setS3CameraForm}
                            cameraPosition={cameraPosition}
                            setCameraPosition={setCameraPosition}
                            unitType={unitType}
                        />
                    </Tab> */}
                </Tabs>
              </Tab>
            </Tabs>
          </CameraTabNavContainer>
        )}
      </SettingsContainer>

      {showCameraSetting && !noS3SettingData && (
        <SettingsFooter>
          <>
            <ActionButtons
              showAppyToAll={true}
              handleApplyToAll={toggleApplyToAllModal}
              applyToAll={isApplyToAllObjValid()}
              updatableChanges={isUpdateAvailable()}
              handleUpdate={submitForm}
              handleCancel={handleCancel}
              isLoading={updateS3CameraSettingLoading}
              showResetButton={true}
              handleReset={setResetModal}
            />

            <ApplyToAllModal
              showModal={showApplyToAllModal}
              setShowModal={toggleApplyToAllModal}
              applyChanges={saveHandler}
              applyAllSelectedAssetTypes={applyAllSelectedAssetTypes}
              setApplyAllSelectedAssetTypes={setApplyAllSelectedAssetTypes}
              setCheckApplyToAll={setCheckApplyToAll}
              settingsOptions={settingsOptions}
              modalHeader={"S3 Settings"}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
              footNote={t("S3 Apply To All Footer Note")}
            />

            {/*  <ResetToDefaultDiv>
              <span className="text-black text-sm cursor-pointer" onClick={() => setResetModal(true)}>
                <Trans>Reset to default</Trans>
              </span>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <Information className="ml-2 h-4 mr-4 cursor-pointer" style={{ marginTop: "-3px" }} />
              </OverlayTrigger>
            </ResetToDefaultDiv>*/}
          </>
        </SettingsFooter>
      )}

      <ConfirmationModal
        resetS3CameraSettingLoading={resetS3CameraSettingLoading}
        setResetModal={setResetModal}
        resetModal={resetModal}
        resetSetting={onReset}
      />

      <S3SettingsUpdateResult
        bulkUpdateS3CameraSettingSuccess={bulkUpdateS3CameraSettingSuccess}
        s3CameraSettingUpdateStatusData={s3CameraSettingUpdateStatusData}
        onContinue={handleOK}
      />

      <SnackBar
        isFailed={alertError}
        isSuccess={
          (notificationBulkUpdateSuccess || alertSuccess || isUpdateLoading) &&
          !bulkUpdateS3CameraSettingSuccess
        }
        label={
          alertError ? t("Error occurred while updating S3 Settings.") : t("S3 Settings updated successfully")
        }
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  updateS3CameraSettingLoading: state.config.updateS3CameraSettingLoading,
  getS3CameraSettingLoading: state.config.getS3CameraSettingLoading,
  s3CameraSettingData: state.config.s3CameraSetting,
  resetS3CameraSettingLoading: state.config.resetS3CameraSettingLoading,
  unitType: state.user.loginDetails.unitType,
  userAccountId: state.user.loginDetails.accountId,
  notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
  notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  s3CameraSettingUpdateStatusData: state.config.s3CameraSettingUpdateStatusData,
  updateS3CameraSettingSuccess: state.config.updateS3CameraSettingSuccess,
  bulkUpdateS3CameraSettingSuccess: state.config.bulkUpdateS3CameraSettingSuccess,
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
  updateS3CameraSettingError: state.config.updateS3CameraSettingError,
});

const mapDispatchToProps = (dispatch) => ({
  getS3CameraSetting: (dropdownAssetTypeId) => dispatch(getS3CameraSetting(dropdownAssetTypeId)),
  updateS3CameraSetting: (assetId, formData, actionAfterUpdate) =>
    dispatch(updateS3CameraSetting(assetId, formData, actionAfterUpdate)),

  getS3CameraSettingByAssetId: (assetId) => dispatch(getS3CameraSettingByAssetId(assetId)),
  updateS3CameraSettingsByAssetId: (assetId, formData, actionAfterUpdate) =>
    dispatch(updateS3CameraSettingsByAssetId(assetId, formData, actionAfterUpdate)),
  resetS3CameraSettingsByAssetId: (assetId, actionAfterUpdate) =>
    dispatch(resetS3CameraSettingsByAssetId(assetId, actionAfterUpdate)),
  bulkUpdateCameraSettings: (data, setUpdateLoading) =>
    dispatch(bulkUpdateCameraSettings(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
  resetBulkUpdateCameraSettingsFlags: () => dispatch(resetBulkUpdateCameraSettingsFlags()),
  resetS3CameraSettingError: () => dispatch(resetS3CameraSettingError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(S3CameraSettings);
