export const assetManagementTypes = {
  // TYPE: ASSET LIST
  LIST_ASSET: "LIST_ASSET",

  // TYPE: DEFAULT ASSET OVERVIEW
  DEFAULT_ASSET_OVERVIEW: "DEFAULT_ASSET_OVERVIEW",

  // TYPE: SELECTED ASSET OVERVIEW
  ASSET_OVERVIEW: "ASSET_OVERVIEW",
  ASSET_OVERVIEW_LOADING: "ASSET_OVERVIEW_LOADING",

  // TYPE: UPDATE ASSET AFTER ASSET UPDATE
  UPDATE_ASSET: "UPDATE_ASSET",

  // TYPE: UPDATE ASSET LIST AFTER ADD ASSET
  ADD_ASSET: "ADD_ASSET",

  //TYPE: To edit asset name
  EDIT_ASSET_LOADING: "EDIT_ASSET_LOADING",
  EDIT_ASSET_SUCCESS: "EDIT_ASSET_SUCCESS",
  EDIT_ASSET_ERROR: "EDIT_ASSET_ERROR",
  RESET_STATE: "RESET_STATE",

  //TYPE: To update asset status
  UPDATE_ASSET_STATUS: "UPDATE_ASSET_STATUS",

  //TYPE: To add or edit asset image
  ADD_ASSET_IMAGE_LOADING: "ADD_ASSET_IMAGE_LOADING",
  ADD_ASSET_IMAGE_SUCCESS: "ADD_ASSET_IMAGE_SUCCESS",
  ADD_ASSET_IMAGE_ERROR: "ADD_ASSET_IMAGE_ERROR",
};
