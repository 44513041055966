import styled from "styled-components";

export const HeadingComponent = styled.div`
  font-family: "kanit";
  font-size: 1.1rem !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-bottom: 0.2rem;
`;

export const HeadingDetailsComponent = styled.div`
  font-size: 0.8rem !important;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  padding-bottom: 10px;
`;

export const TableHeaderStyle = styled.th`
  font-family: "Kanit";
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-weight: 400;
  padding: 0px 0px 7px 0px !important;
  vertical-align: middle !important;
  width: auto;
  margin-top: 10px;
`;

export const TabletheadStyle = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
`;

export const TableBodyTd = styled.tr`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  float: left;
`;

export const SettingsBody = styled.div`
  width: 94%;
  margin: 10px auto;
  text-align: left;
`;

export const SettingsContainer = styled.div`
  overflow: hidden;
  height: calc(100vh - 380px);
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const SettingsFooter = styled.div`
  overflow: hidden;
  width: 94%;
  margin: 15px auto;
  text-align: left;
  .update-button-s2 {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    display: flex;
    .worksite-names {
      padding-right: 0px;
      flex: 1;
      float: left;
      text-align: left;
      display: block !important;
      max-width: initial;
      padding: 0px !important;
    }
    .col-md-8 {
      padding-right: 0px;
      flex: 1;
    }

    @media (max-width: 430px) {
      .worksite-names button {
        padding: 6px 7px;
      }
      .col-md-8 button {
        padding: 6px 7px;
      }
      .apptoall {
        position: relative;
        right: 0;
        left: 100px;
        width: 230px;
      }
    }
  }
`;

export const SettingTableDescription = styled.div``;

export const ImpactSVG = styled.div`
  font-size: 28px !important;
  float: left;
  position: relative;
  top: 3px;
  margin-right: 10px;
  @media (max-width: 768px) {
    font-size: 22px !important;
  }s
`;
export const NearMissSVG = styled.div`
  font-size: 28px !important;
  float: left;
  position: relative;
  top: 1px;
  margin-right: 10px;
  @media (max-width: 768px) {
    font-size: 22px !important;
  }
`;

export const UnauthoSVG = styled.div`
  font-size: 28px !important;
  float: left;
  position: relative;
  top: 1px;
  margin-right: 10px;
  @media (max-width: 768px) {
    font-size: 22px !important;
  }
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 70% 12% 18%;
  @media (max-width: 768px) {
    /*display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};*/
    grid-template-columns: 56% 5% 39%;
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "8px 0px" : "0px")};
  color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "18px" : "16px")} !important;
  line-height: ${({ isHeader }) => (isHeader ? "25px" : "40px")};
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: left;
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    font-size: ${({ isHeader }) => (isHeader ? "15px" : "14px")} !important;
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  @media (max-width: 1100px) {
    /* margin-bottom: 50px;*/
  }
`;


export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 300px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;