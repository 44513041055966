import {
  InformationIconM,
  InformationTextM,
  InformationMessage,
  ImageTextContainer,
  TextBoxLeft,
  ImageForkliftRight,
  LowTextBox,
  MediumTextBox,
  HighTextBox,
} from "../Settings.Styles";
import { Trans } from "react-i18next";
import Forklift_S2 from "../../../../assets/Forklift-w_SensorLines.jpg";

const ImpactThresholdInfo = () => {
  return (
    <>
      <InformationMessage>
        <InformationIconM className="icon icon-information-tooltip"></InformationIconM>
        <InformationTextM>
          <Trans>RECOMMENDED_THRESHOLD_SETTINGS_LABEL</Trans>
        </InformationTextM>
      </InformationMessage>

      <ImageTextContainer>
        <TextBoxLeft>
          <LowTextBox>
            <Trans>Low</Trans>: <Trans>3g to 4g</Trans>
          </LowTextBox>
          <MediumTextBox>
            <Trans>Medium</Trans>: <Trans>4g to 6g</Trans>
          </MediumTextBox>
          <HighTextBox>
            <Trans>High</Trans>: <Trans>6g and above</Trans>
          </HighTextBox>
        </TextBoxLeft>
        <ImageForkliftRight>
          <img src={Forklift_S2} alt="forklift" />
        </ImageForkliftRight>
      </ImageTextContainer>
    </>
  );
};

export default ImpactThresholdInfo;
