export const baseTheme = {
  name: "base-theme",
  colors: {
    themePrimary: "#0D5FBE",
    themePrimaryDarker: "#004AA0",
    grayL1: "#323232",
    grayL2: "#434343",
    grayL3: "#676767",
    grayL4: "#9A9A9A",
    grayL5: "#CCCCCC",
    grayL6: "#F2F2F2",
    grayL7: "#FFFFFF",
    greenPrimary: "#11A70B",
    greenHover: "#0D8908",
    redPrimary: "#DD0000",
    redHover: "#B20000",
    linkColor: "#1070E0",
    orangePrimary: "#DD9000",
    orangeHover: "#C78200",
    PdfColor: "#B30B00",
    CsvColor: "#008000",
  },
};

export const darkTheme = {
  name: "dark-theme",
  colors: {
    themePrimary: "#DEC500",
    themePrimaryDarker: "#f2df49",
    grayL1: "#FFFFFF",
    grayL2: "#F2F2F2",
    grayL3: "#CCCCCC",
    grayL4: "#9A9A9A",
    grayL5: "#676767",
    grayL6: "#434343",
    grayL7: "#323232",
    greenPrimary: "#11A70B",
    greenHover: "#07C100",
    redPrimary: "#DD0000",
    redHover: "#F50000",
    linkColor: "#1070E0",
    orangePrimary: "#DD9000",
    orangeHover: "#F8A100",
    PdfColor: "#B30B00",
    CsvColor: "#008000",
  },
};
