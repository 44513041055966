import React from "react";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import { useTranslation } from "react-i18next";

const SearchBoxComponent = ({ searchText, setSearchText, getAssetTypeBySearchText, placeholder }) => {
  const { t } = useTranslation();

  return (
    <SearchBox
      label={t("Your Asset Types")}
      placeholder={t("Search an Asset Type")}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

export default SearchBoxComponent;
