import React, { useState, useEffect } from "react";

const CountdownDate = ({ targetDate, dateExpiredCallback }) => {
  // This function calculates the time left until the target date.
  const calculateTimeLeft = (targetDate) => {
    const now = new Date().getTime();
    // Added a buffer of 2 sec to compensate for BE and FE time mismatch.
    const timeLeft = targetDate - now + 10000;
    if (timeLeft < 0) {
      return { total: 0, minutes: 0, seconds: 0 };
    }
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return {
      total: timeLeft,
      minutes,
      seconds,
    };
  };

  // Initialize the state with the calculated time left.
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(targetDate));

  useEffect(() => {
    // Only set up the interval if the target date is in the future.
    if (timeLeft.total > 0) {
      const interval = setInterval(() => {
        const newTimeLeft = calculateTimeLeft(targetDate);
        setTimeLeft(newTimeLeft);

        if (newTimeLeft.total <= 0) {
          clearInterval(interval);
          dateExpiredCallback();
        }
      }, 1000);

      // Cleanup the interval on component unmount or when targetDate changes.
      return () => clearInterval(interval);
    } else {
      dateExpiredCallback();
    }
  }, [targetDate, timeLeft.total, dateExpiredCallback]);

  return (
    <span>
      {timeLeft.minutes} minutes and {timeLeft.seconds} seconds
    </span>
  );
};

export default CountdownDate;
