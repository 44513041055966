import styled from "styled-components";
export const ChecklistDeatilStyled = styled.div`
  /* div.tab-header-active {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .form-control:hover,
  .input-group input:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  input.form-control,
  textarea.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  input.form-control:focus,
  textarea.form-control:focus {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder,
  textarea.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }

  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
   
  }

  .MuiTableRow-root.Mui-selected {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  tr.Mui-selected td .row .text-left {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }

  td.tableTextStyle .row .text-left {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
 

  thead.theadBlueStyle,
  .theadBlueStyle {
    background-color: ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} !important;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  thead.theadGrayStyle,
  .theadGrayStyle {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.checklistBGWhite thead {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  span.spanQuestColor {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .addQuesTextColor {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }*/
`;

export const DeleteIconStyled = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  text-align: right;
  float: right;
`;

export const InformationStyledIcon = styled.div`
  font-size: 16px !important;

  .inforIconBl {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .inforIconWh {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
`;

export const ChecklistHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border-radius: 4px;
  padding: 15px;
  font-size: 18px !important;
  margin: 10px auto;
`;

export const ChecklistContainer = styled.div``;
export const ChecklistRowContainer = styled.div`
  display: flex;
  @media (max-width: 700px) {
    display: block;
  }
`;
export const ChecklistMenuSideBarLeft = styled.div`
  width: 25%;
  padding-right: 2%;
  flex-direction: column;
  @media (max-width: 700px) {
    width: 100%;
    padding-right: 0%;
  }
`;

export const ChecklistDetailDescRight = styled.div`
  width: 100%;
  height: calc(100vh - 320px);
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .borderWhiteCont {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    overflow: auto;
  }
  .borderBlueCont {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    overflow: auto;
  }
`;

export const ChecklistSideBarMenuUL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: calc(100vh - 320px);
  overflow-x: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  li.SidebarUnSelected {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-weight: 300;
    display: flex;
    :hover {
      background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    }
  }
  li.SidebarSelected {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: 400;
    display: flex;
    :hover {
      background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    }
  }
  @media (max-width: 700px) {
    max-height: 100px;
    overflow-x: hidden;
    overflow: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

export const ChecklistSideBarMenuLI = styled.li`
  width: 100%;
  padding: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
  overflow: hidden;
  :hover {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const CheckSectionName = styled.div`
  float: left;
  font-size: 15px !important;
  font-family: "Kanit", sans-serif;
  text-transform: capitalize;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
`;

export const AccordionDivCont = styled.div``;

export const AccordionCardHeaderDiv = styled.div`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;
export const AccorDivTop = styled.div``;
export const AccordDivHead = styled.div`
  display: flex;
  .textColoBl {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .textColoWh {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  @media (max-width: 700px) {
    display: block;
  }
`;
export const AccordHeadName = styled.div`
  flex: 1;
  padding: 1.2% 2%;
  font-family: "Kanit", sans-serif;
  font-size: 17px !important;
`;

export const AccordianDetailDiv = styled.div``;

export const AccordianDetailBody = styled.div`
  padding-bottom: 10px;
`;

export const AccordianDetailBodyDiv = styled.div`
  margin: auto;
  width: 95%;
  display: flex;
  clear: both;
  min-height: 40px;
  @media (max-width: 700px) {
    display: block;
  }
`;
export const SpanDiv = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  float: left;
  display: flex;
  margin-right: 15px;
`;

export const InformationDivIcon = styled.div`
  position: relative;
  top: 3px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OnOffText = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
`;
export const AccordianLeftData = styled.div`
  float: left;
  width: 40%;
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    display: inline-flex;
    width: auto;
  }
`;

export const AccordianRightData = styled.div`
  float: right;
  width: 60%;
  margin: 10px auto;
  @media (max-width: 700px) {
    float: none;
    width: 100%;
  }
`;

export const BorderLineDiv = styled.div`
  clear: both;
  width: 100%;
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const AccordDataQuestText = styled.div`
  float: left;
  width: 58%;
  margin-right: 2%;
  margin-bottom: 4px;
  @media (max-width: 700px) {
    float: none;
    width: 100%;
  }
`;

export const AccordResponseType = styled.div`
  float: left;
  width: 35%;
  @media (max-width: 700px) {
    float: none;
    width: 98%;
  }
`;

export const DeleteIconDiv = styled.div`
  cursor: pointer;
  float: right;
  width: 5%;
  padding-top: 27px;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const DeleteIconDivRespMobile = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: center;
    background: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    border-radius: 4px;
    .icon-delete {
      color: #ffffff !important;
      float: none;
      text-align: center;
      :before {
        top: 3px;
        position: relative;
        margin-right: 7px;
      }
    }
  }
`;

export const ResponseTypeDiv = styled.div`
  width: ;
`;

export const AccordianDetailBodyDivSe = styled.div`
  width: 100%;
  padding: 4px 16px;
  clear: both;
  /* overflow: hidden;  */
  display: flex;
  @media (max-width: 700px) {
    display: block;
  }
`;
export const AccordDataQuestTextSe = styled.div`
  flex: 0.92;
`;

export const OkNotOkDropdown = styled.div`
  flex: 2;
  @media (max-width: 700px) {
    overflow: hidden;
    margin: 10px auto;
    padding-bottom: 10px;
  }
`;
export const SwitchButtonDiv = styled.div`
  float: left;
  position: relative;
  top: 20px;
  left: 25px;
  @media (max-width: 700px) {
    left: -96px;
  }
`;

export const DropdownOkNotOk = styled.div`
  flex: 1;
  float: right;
  width: 54%;
  @media (max-width: 700px) {
    float: left;
    width: 52%;
  }
`;

export const PleaseAddQuestion = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  height: calc(100vh - 535px);
  font-size: 21px !important;
  text-align: center;
  width: 100%;
`;

export const ChecklistCreationDiv = styled.div`
  width: 100%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 20px !important;
  height: calc(100vh - 400px);
  display: grid;
  align-items: center;
`;

export const ChecklistBottomButtons = styled.div`
  width: 98%;
  display: flex;
  clear: both;
  margin: 20px auto;
  @media (max-width: 700px) {
    display: block;
  }
`;

export const AddSectionMobileButton = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    margin: 0px 0px 10px 0px;
  }
`;

export const ChecklistButtonDivLeft = styled.div`
  flex: 1;
  button.addQuestButton {
    margin-left: 65px;
  }
  @media (max-width: 700px) {
    .addSectioButtonDesktop {
      display: none;
    }
    button.addQuestButton {
      margin-left: 0px;
    }
  }
`;

export const ChecklistButtonDivRight = styled.div`
  flex: 1;
  display: contents;
  @media (max-width: 700px) {
    width: 100%;
    display: block;
    overflow: hidden;
    margin: 10px auto;
    button:nth-child(1) {
      float: left;
    }
    button:nth-child(2) {
      float: right;
    }
  }
`;

export const InformationDiv = styled.div`
  float: left;
  position: relative;
  left: 107px;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  @media (max-width: 700px) {
    left: 0px;
  }
`;
export const StyledInformationIcon = styled.div`
  width: 17px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 1px;
`;

export const BorderLineDivSE = styled.div`
  clear: both;
  float: left;
  margin-top: 10px;
  width: 100%;
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const EmptySectionWrapper = styled.div`
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const OverlayTriggerDivPS = styled.div`
  .mytooltipx > .tooltip-inner {
  }
`;
