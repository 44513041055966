export const analyticsTypes = {
  //For count
  GET_USER_CHARTS_LIST: "GET_USER_CHARTS_LIST",
  GET_ALL_CHARTS_LIST: "GET_ALL_CHARTS_LIST",
  SAVE_USER_CHARTS_LIST: "SAVE_USER_CHARTS_LIST",
  GET_JWT_TOKEN_FOR_CHARTS: "GET_JWT_TOKEN_FOR_CHARTS",

  GET_USER_CHARTS_LIST_LOADING: "GET_USER_CHARTS_LIST_LOADING",
  GET_USER_CHARTS_LIST_SUCCESS: "GET_USER_CHARTS_LIST_SUCCESS",
  GET_USER_CHARTS_LIST_ERROR: "GET_USER_CHARTS_LIST_ERROR",

  GET_ALL_CHARTS_LIST_LOADING: "GET_ALL_CHARTS_LIST_LOADING",
  GET_ALL_CHARTS_LIST_SUCCESS: "GET_ALL_CHARTS_LIST_SUCCESS",
  GET_ALL_CHARTS_LIST_ERROR: "GET_ALL_CHARTS_LIST_ERROR",

  SAVE_USER_CHARTS_LIST_LOADING: "SAVE_USER_CHARTS_LIST_LOADING",
  SAVE_USER_CHARTS_LIST_SUCCESS: "SAVE_USER_CHARTS_LIST_SUCCESS",
  SAVE_USER_CHARTS_LIST_ERROR: "SAVE_USER_CHARTS_LIST_ERROR",

  GET_JWT_TOKEN_FOR_CHARTS_LOADING: "GET_JWT_TOKEN_FOR_CHARTS_LOADING",
  GET_JWT_TOKEN_FOR_CHARTS_SUCCESS: "GET_JWT_TOKEN_FOR_CHARTS_SUCCESS",
  GET_JWT_TOKEN_FOR_CHARTS_ERROR: "GET_JWT_TOKEN_FOR_CHARTS_ERROR",

  SHOW_LOADER: "SHOW_LOADER",
  SHOW_ERROR: "SHOW_LOADER",

  SELECT_CHART: "SELECT_CHART",
};
