import React from "react";

import styled from "styled-components";

const DropdownFontStyle = {
  small: {
    fontSize: "16px",
    labelFontSize: "12px",
    boxHeight: "32px",
    errorFontSize: "14px",
    optioFontSize: "14px",
  },
  medium: {
    fontSize: "18px",
    labelFontSize: "12px",
    boxHeight: "38px",
    errorFontSize: "15px",
    optioFontSize: "16px",
  },
  large: {
    fontSize: "20px",
    labelFontSize: "14px",
    boxHeight: "42px",
    errorFontSize: "16px",
    optioFontSize: "18px",
  },
};

export const Label = styled.label`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  line-height: 20px;
  font-size: ${(props) => DropdownFontStyle[props.size].labelFontSize} !important;
  float: left;
  color: ${({ isDisabled, color, theme }) =>
    isDisabled ? theme.colors.grayL5 || "#CCCCCC" : theme.colors.grayL3 || "#676767"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
`;

export const DropdownWrapper = styled.div`
  .select__menu-portal {
    z-index: 12;
  }
  font-family: "Open Sans", sans-serif;
  position: relative;
  &[isDisabled] {
    ${Label} {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    }
  }
  .select__control {
    border: ${({ isError, border, theme }) =>
      isError ? "1px solid " + theme.colors.redPrimary || "#DD0000" : ""};
  }
  .select__value-container--is-multi {
    display: flex;
  }

  [tooltip] {
    position: relative;
  }

  [tooltip]::before,
  [tooltip]::after {
    text-transform: none;
    font-size: 0.9em;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
  }

  [tooltip]::before {
    content: "";
    border: 5px solid transparent;
    z-index: 1001;
  }

  [tooltip]::after {
    content: attr(tooltip);
    font-family: Helvetica, sans-serif;
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 0.3ch;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
    z-index: 1000;
  }

  [tooltip]:hover::before,
  [tooltip]:hover::after {
    display: block;
  }

  [tooltip=""]::before,
  [tooltip=""]::after {
    display: none !important;
  }

  [tooltip]:not([flow])::before,
  [tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
  }

  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
  }

  [tooltip]:not([flow])::before,
  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::before,
  [tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
  }

  [tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
  }
  [tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
  }

  [tooltip][flow^="down"]::before,
  [tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
  }

  [tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-0.5em, -50%);
  }

  [tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-0.5em, -50%);
  }

  [tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #333;
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
  }

  [tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
  }

  @keyframes tooltips-vert {
    to {
      opacity: 0.9;
      transform: translate(-50%, 0);
    }
  }

  @keyframes tooltips-horz {
    to {
      opacity: 0.9;
      transform: translate(0, -50%);
    }
  }

  [tooltip]:not([flow]):hover::before,
  [tooltip]:not([flow]):hover::after,
  [tooltip][flow^="up"]:hover::before,
  [tooltip][flow^="up"]:hover::after,
  [tooltip][flow^="down"]:hover::before,
  [tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }

  [tooltip][flow^="left"]:hover::before,
  [tooltip][flow^="left"]:hover::after,
  [tooltip][flow^="right"]:hover::before,
  [tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
  }
  &[readonly] {
    pointer-events: none;
    position: relative;
    box-sizing: border-box;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-size: ${(props) => DropdownFontStyle[props.size].errorFontSize};
  overflow: hidden;
  clear: both;
`;

export const Asterisk = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  position: absolute;
  right: 15px;
  top: 0px;
`;

export const AddAssetIcon = styled.i`
  font-size: 20px !important;
  margin-right: 5px;
  align-item: center;
`;

export const AddWorksiteIcon = styled.i`
  font-size: 20px !important;
  margin-right: 8px;
  align-item: center;
`;

export const OptionDiv = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 14px !important;
  padding: 5px 10px;
  display: flex;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .icon {
    font-size: 18px !important;
    margin-right: 8px;
    align-item: center;
  }
`;
