import React from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const WrapperDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 4px;
  @media (max-width: 1100px) {
    margin-bottom: 10px;
  }
`;
const AssetIconconDiv = styled.div`
  font-size: 28px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
`;
const MainWraper = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;
const SpanCount = styled.div`
  display: contents;
  font-size: 24px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
`;
const SpanHead = styled.div``;

const AssetsCard = ({ assetTotalCount }) => {
  const { t } = useTranslation();
  return (
    <WrapperDiv>
      <MainWraper>
        <SpanHead> {t("Assets")}</SpanHead>
        <AssetIconconDiv className="icon icon-users">
          <SpanCount> {assetTotalCount || 0}</SpanCount>
        </AssetIconconDiv>
      </MainWraper>
    </WrapperDiv>
  );
};

const mapStateToProps = (state) => ({
  assetTotalCount: state.assets.totalCount,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsCard));
