import styled from "styled-components";

export const Thead = styled.thead`
  display: block;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  font-size: 18px;
  color: #333333;
  border: 0px solid;
  font-weight: bold;
  width: 100%;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 3px 8px #00000029;
  padding-right: 12px;
`;
export const Tbody = styled.tbody`
  display: block;
  overflow-y: auto;
  height: calc(100vh - 310px);
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const Tr = styled.tr`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  height: ${({ height }) => height || "initial"};
  &:after {
    content: " ";
    display: block;
    visibility: hidden;
    clear: both;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;
export const Th = styled.th`
  font-family: Kanit;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  width: 20%;
  float: left;
  line-height: 16px;
`;

export const Td = styled.td`
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: ${({ textColor, theme }) => (textColor ? textColor : theme.colors.grayL1 || "#323232")};
  font-weight: ${({ boldFont }) => (boldFont ? boldFont : "400")};
  cursor: ${({ cursorFont }) => (cursorFont ? cursorFont : "inherit")};
  text-align: left;
  width: ${({ width }) => width || "20%"};
  float: left;
  height: ${({ height }) => height || "40px"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NoDataWrapper = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableResponsive = styled.div`
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
      width: -webkit-fill-available;
    }

    table td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
`;
