import React from "react";
import { Trans } from "react-i18next";
import {
  CarouselWrapperDiv,
  AssetIconconDiv,
  StyledCard,
  StyledCol,
  StyledRow,
  AssetDiv,
} from "./MultiCardsWorksite.styles.component";

const MultiCardsWorksite = ({ data }) => {
  return (
    <StyledCard>
      <CarouselWrapperDiv>
        <StyledRow>
          <StyledCol>
            <Trans>Assets</Trans>
          </StyledCol>

          <AssetDiv>
            <AssetIconconDiv className="icon icon-assets"></AssetIconconDiv>
            <span>{data?.assets?.length || 0}</span>
          </AssetDiv>
        </StyledRow>
      </CarouselWrapperDiv>
    </StyledCard>
  );
};

export default MultiCardsWorksite;
