import styled from "styled-components";

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;

export const UserDetailsFormDiv = styled.div``;

export const ProfilePicUpload = styled.div`
  position: relative;
`;

export const InputUploader = styled.input`
  opacity: 0;
  position: absolute;
  width: 150px;
  height: 150px;
  cursor: pointer;
  text-align: center;
`;

export const HeaderLabel = styled.div`
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  margin-bottom: 5px;
`;

export const HoverText = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 150px; /* Same width as ImageWrapper */
  height: 75px; /* Half of the height of ImageWrapper */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  /* clip-path: ellipse(75px 37.5px at 50% 100%); Clip to create half-circle */
  padding: 5px 10px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  &:hover ${HoverText} {
    opacity: 1;
  }
`;

export const ImagePreview = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
  cursor: pointer;
  transition: border-color 0.3s ease;
  &:hover ${HoverText} {
    opacity: 1;
  }
`;


export const ModalBodyHeight = styled.div`
  height: calc(100vh - 260px);
  align-content: center;
  text-align: center;
  .ReactCrop {
    align-items: center;
    justify-content: center;
  }
  img.ReactCrop__image {
    /*  height: calc(100vh - 260px); */
    height: 100%;
    max-height: calc(100vh - 280px);
  }
`;