import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Autoplay } from "swiper/modules";

import {
  CarouselContainer,
  CarouselWrapper,
  CarouselHeading,
  CarouselIconNumber,
  IconCarousel,
  StyledSwiperSlide,
  ContentWrapper,
} from "./Carousel.component.styles";
import PropTypes from "prop-types";

const CarouselComponent = (props) => {
  const {
    size,
    responsive,
    countData,
    carouselData,
    swipeable,
    autoPlay,
    autoPlaySpeed,
    slidesToSlide,
    infinite,
    iconColor,
    isColored,
    getAllCountLoading,
    ...rest
  } = props;

  // Config for Swiper
  const settings = {
    slidesPerView: responsive.desktop.items,
    spaceBetween: 0,
    loop: infinite,
    autoplay: autoPlay
      ? {
          delay: autoPlaySpeed,
          disableOnInteraction: false,
        }
      : false,
    navigation: true,
    grabCursor: swipeable,
    pagination: { clickable: true }, // Add pagination here if needed
    breakpoints: {
      1024: {
        slidesPerView: responsive.desktop.items,
      },
      600: {
        slidesPerView: responsive.tablet.items,
      },
      320: {
        slidesPerView: responsive.mobile.items,
      },
    },
    ...rest,
  };

  return (
    <CarouselWrapper>
      <Swiper
        {...settings}
        modules={[Navigation, Pagination, Autoplay]} // Pass the imported modules here
      >
        {carouselData?.map((data, index) => (
          <StyledSwiperSlide key={index}>
            <ContentWrapper>
              <CarouselContainer href={data.to} onClick={data.onClick}>
                <CarouselHeading
                  label={data.name}
                  size={size}
                  title={data.name}
                  type={data.type}
                  isColored={isColored}
                >
                  {data.name}
                </CarouselHeading>
                <CarouselIconNumber size={size} type={data.type}>
                  <IconCarousel
                    size={size}
                    className={`icon icon-${data.iconClass}`}
                    iconColor={data.iconColor}
                  ></IconCarousel>
                  {getAllCountLoading ? (
                    <div className="spinner-border count-spin" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    countData?.[data?.feildName]
                  )}
                </CarouselIconNumber>
              </CarouselContainer>
            </ContentWrapper>
          </StyledSwiperSlide>
        ))}
      </Swiper>
    </CarouselWrapper>
  );
};

CarouselComponent.propTypes = {
  /**
   * The size of the carousel elements (small, medium, large).
   * Defines the overall size of the carousel headings and icons.
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /**
   * An object that defines the responsive breakpoints.
   * It specifies the number of slides to display for desktop, tablet, and mobile views.
   */
  responsive: PropTypes.object,

  /**
   * An object containing count data for each item in the carousel.
   * This data is used to display additional numeric information inside each carousel item.
   */
  countData: PropTypes.object,

  /**
   * An array of carousel data objects, where each object contains the name, type, icon class, etc.
   * This data is mapped to create the individual carousel slides.
   */
  carouselData: PropTypes.array,

  /**
   * A boolean flag indicating whether the carousel is swipeable or not.
   * When true, users can swipe to navigate through the carousel items.
   */
  swipeable: PropTypes.bool,

  /**
   * A boolean flag to enable or disable autoplay for the carousel.
   * When true, the carousel will automatically cycle through slides based on the autoplay speed.
   */
  autoPlay: PropTypes.bool,

  /**
   * The speed of autoplay in milliseconds.
   * This defines the delay between automatic transitions when autoplay is enabled.
   */
  autoPlaySpeed: PropTypes.number,

  /**
   * The number of slides to transition with each swipe or navigation click.
   */
  slidesToSlide: PropTypes.number,

  /**
   * A boolean flag indicating whether the carousel should loop infinitely.
   * When true, the carousel will restart from the first slide after reaching the last one.
   */
  infinite: PropTypes.bool,

  /**
   * A boolean flag that controls whether the carousel headings and icons are colored.
   */
  isColored: PropTypes.bool,
};

CarouselComponent.defaultProps = {
  size: "large",
  responsive: {
    desktop: {
      items: 4,
    },
    tablet: {
      items: 2,
    },
    mobile: {
      items: 1,
    },
  },
  countData: {},
  carouselData: [],
  swipeable: true,
  autoPlay: true,
  autoPlaySpeed: 5000,
  slidesToSlide: 1,
  infinite: true,
  isColored: false,
};

export default CarouselComponent;
