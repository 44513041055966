import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileToggleButton = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    display: ${(props) => (props.isVisible ? "block" : "none")};
  }
`;