import styled from "styled-components";

const checkboxSizeStyle = {
  small: {
    wrapWidth: "16px",
    WrapHeight: "16px",
    fontSize: "15px",
    leftHover: "-4px",
    topHover: "-2px",
    widthHover: "26px",
    heightHOver: "26px",
    checkboxTop: "3px",
    checkboxLeft: "1px",
    checkboxWidth: "9px",
    checkboxHeight: "4px",
  },
  medium: {
    wrapWidth: "20px",
    WrapHeight: "20px",
    fontSize: "18px",
    leftHover: "-4.5px",
    topHover: "-2px",
    widthHover: "30px",
    heightHOver: "30px",
    checkboxTop: "3px",
    checkboxLeft: "1px",
    checkboxWidth: "11px",
    checkboxHeight: "6px",
  },
  large: {
    wrapWidth: "24px",
    WrapHeight: "24px",
    fontSize: "20px",
    leftHover: "-4.5px",
    topHover: "-2px",
    widthHover: "34px",
    heightHOver: "34px",
    checkboxTop: "5px",
    checkboxLeft: "2px",
    checkboxWidth: "14px",
    checkboxHeight: "6px",
  },
};

export const CheckboxWrapper = styled.label`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  font-family: "Open Sans", sans-serif;
  z-index: 0;
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${(props) => checkboxSizeStyle[props.size].fontSize};
  line-height: 1.5;
  margin: 0;
  > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    /*width: 40px;
		height: 40px;*/
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    &:checked {
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      + {
        span {
          &::before {
            border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
            background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
            position: initial;
          }
          &::after {
            border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
          }
        }
      }
      &:active {
        + {
          span {
            &::before {
              border-color: transparent;
              background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
            }
          }
        }
      }
      &:disabled {
        + {
          span {
            &::before {
              border-color: transparent;
              background-color: currentColor;
            }
          }
        }
      }
    }

    &:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
      + {
        span {
          &::before {
            border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
          }
        }
      }
    }
    &:disabled {
      opacity: 0;
      + {
        span {
          color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
          cursor: initial;
          &::before {
            border-color: currentColor;
            border: 1px solid;
          }
        }
      }
    }
  }
  > span {
    display: inline-block !important;
    width: 100%;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 1px;
      border-color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      border-radius: 4px;
      width: ${(props) => checkboxSizeStyle[props.size].wrapWidth};
      height: ${(props) => checkboxSizeStyle[props.size].WrapHeight};
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: ${(props) => checkboxSizeStyle[props.size].checkboxTop};
      left: ${(props) => checkboxSizeStyle[props.size].checkboxLeft};
      width: ${(props) => checkboxSizeStyle[props.size].checkboxWidth};
      height: ${(props) => checkboxSizeStyle[props.size].checkboxHeight};
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
      box-sizing: initial;
    }
  }
  :hover {
    > input {
      opacity: 0.5;
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      left: ${(props) => checkboxSizeStyle[props.size].leftHover};
      top: ${(props) => checkboxSizeStyle[props.size].topHover};
      width: ${(props) => checkboxSizeStyle[props.size].widthHover};
      height: ${(props) => checkboxSizeStyle[props.size].heightHOver};
      &:focus {
        opacity: 0.16;
      }
      &:disabled {
        background-color: none;
        transition: none;
        background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        opacity: 1;
      }
    }
    > span {
      &::before {
        position: relative;
        z-index: 4;
        background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        border: 0px;
      }
    }
  }
`;
