import React, { useState, useEffect } from "react";
import {
  WrapperOperatorForklift,
  BodyWrapperOperator,
  HeadingContainer,
} from "../../operatorVerification/OperatorVerificationForm.styles";
import CertificateFormFields from "../../operatorVerification/CertificateFormFields.component";
import { vehicleClassesByValue } from "../../operatorVerification/constants";
import { Trans } from "react-i18next";

const OperatorCertificate = (props) => {
  const { certificate, changeHandler, vehicleClassesChangeHandler, errors } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { issueDate, expiryDate, certifiedVehicleClasses, certificateNumber, certifyingOrganization } =
    certificate;
  const stringCertifiedVehicleClasses = JSON.stringify(certifiedVehicleClasses);
  useEffect(() => {
    if (certifiedVehicleClasses?.length > 0) {
      const selectedOpt = [];

      certifiedVehicleClasses.forEach((vClass) => {
        selectedOpt.push(vehicleClassesByValue[vClass]);
      });
      setSelectedOptions(selectedOpt);
    }
  }, [stringCertifiedVehicleClasses]);

  return (
    <WrapperOperatorForklift>
      <HeadingContainer>
        <Trans>Operator Verification</Trans>
      </HeadingContainer>
      <BodyWrapperOperator>
        <CertificateFormFields
          issueDate={issueDate}
          changeHandler={changeHandler}
          errors={errors}
          expiryDate={expiryDate}
          certificateNumber={certificateNumber}
          vehicleClassesChangeHandler={vehicleClassesChangeHandler}
          certifyingOrganization={certifyingOrganization}
          selectedOptions={selectedOptions}
        />
      </BodyWrapperOperator>
    </WrapperOperatorForklift>
  );
};

export default OperatorCertificate;
