export const initialState = {
  //For Worksite List
  WorksiteLoading: false,
  WorksiteSuccess: false,
  WorksiteError: false,
  totalCount: 0,
  Worksites: [],

  worksiteDetailSuccess: false,
  worksiteDetailLoading: false,
  worksiteDetailError: false,

  userWorksiteSuccess: false,
  userWorksiteLoading: false,
  userWorksiteError: false,

  //For Worksite Overview
  WorksiteOverview: null,

  //For Adding Worksite
  AddWorksiteLoading: false,
  AddWorksiteSuccess: false,
  AddWorksiteError: false,
  WorksiteMessage: "",

  //For Status Update
  StatusLoading: true,
  StatusSuccess: false,
  StatusError: false,
  StatusMessage: "",

  //For Worksite Details Update
  UpdateLoading: false,
  UpdateSuccess: false,
  UpdateError: false,
  UpdateMessage: "",

  //For worksite name update
  UpdateNameLoading: false,
  UpdateNameSuccess: false,
  UpdateNameError: false,
  UpdateNameMessage: "",


      // preDeactivated Users Deactivation
      preDeactivatedWorksiteLoading: false,
      preDeactivatedWorksiteSuccess: false,
      preDeactivatedWorksiteError: false,
      preDeactivatedWorksiteList: [],
    
     // Deactivating Users  by confirm button
      deactivateWorksiteLoading: false,
      deactivateWorksiteSuccess: false,
      deactivateWorksiteError: true,

};
