import styled from "styled-components";

export const FileUploaderWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300;400;500;600;700&display=swap");
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  label.labelContainer {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif;
    line-height: 18px;
    margin-bottom: 0px;
  }

  .BorderDefault {
    border: 2px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    &:hover {
      border: 2px dashed ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  .BorderError {
    border: 2px dashed ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }
`;

export const FileUploaderDiv = styled.div`
  flex: 1;
  position: relative;

  .divMouseHover {
    height: 100%;
    width: 100%;
  }

  span.containerSpan {
    margin-top: 4%;
  }
    
  span.imageUploadCont {
    width: 100%;

    .imageBGColor {
      background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      opacity: 0.5;
      height: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      padding-top: 9%;
      padding-bottom: 9%;
    }
    div {
      align-items: center;
      align-content: center;
      align-self: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      font-size: 60px !important;
      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
  span.imgUploadShow {
    span.containerSpan,
    span.dropSpan {
      display: block;
    }
  }

  .hide {
    display: none;
  }
  .userImageUploadUI {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    padding: 0 !important;
    margin: 0;
    display: flex;
    border: 0;
    &:hover {
      border: 0;
    }
    text-align: center !important;

    span.userImageUpload {
      width: 150px;
      height: 150px;
      align-items: center;
      display: flex;
      text-align: center;
      label {
        width: 100%;
      }
    }
  }

  .addPhotoStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    width: 100%;
    height: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
  }

  img.uploaderImageCss {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .defaultImageCss {
    font-size: 150px !important;
  }

  .pngLogoIcon {
    font-size: 60px !important;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    line-height: 18px;
    margin-bottom: 0px;
  }

  .hoverIcon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &:hover .hoverIcon {
    display: flex;
  }

  .borderBlue {
    border: 2px dashed ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  .borderRed {
    border: 2px dashed ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }
`;

export const FileUploaderCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1.5rem !important;
  text-align: center !important;
  min-height: 210px;
  span.containerSpan {
  }
  span.dropSpan {
    font-family: "Open Sans", sans-serif;
    font-size: 16px !important;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  span.browseColor {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    cursor: pointer;
  }
  span.dragTextFont {
    font-family: "Open Sans", sans-serif;
    font-size: 12px !important;
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  span.failedColor {
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    font-family: "Open Sans", sans-serif;
    font-size: 12px !important;
  }
`;

export const HeaderLabel = styled.div`
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  margin-bottom: 5px;
`;

export const FileUploaderCont = styled.span``;

export const DragIconBGComponent = styled.div`
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  z-index: 999999;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  div {
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-size: 30px !important;
  }
  ~ ${FileUploaderCard} {
    opacity: 0;
  }
`;

export const DeleteIconDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 20px !important;
  line-height: 13px;
`;
export const ErrorSet = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const RowDivFile = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  border-top-left-radius: ${({ isHeader }) => (isHeader ? "4px" : "")};
  border-top-right-radius: ${({ isHeader }) => (isHeader ? "4px" : "")};
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;
  @media (max-width: 1100px) {
    display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const ColumnFile = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "15px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 1100px) {
    display: ${({ isHeader }) => (isHeader ? "none;" : "block;")};
    width: ${({ widthResp }) => (widthResp ? widthResp : "75%")};
    float: ${({ floatResp }) => (floatResp ? floatResp : "left")};
    margin-right: ${({ RmarginResp }) => (RmarginResp ? RmarginResp : "5%")};
    padding: 7px;
  }
`;

export const RowsContainerFile = styled.div`
  max-height: 200px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  height: calc(100vh - 192px);
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const TableContainerDiv = styled.div`
  margin: 10px 15px 10px 15px;
`;
