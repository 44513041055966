import styled from "styled-components";

export const RegistrationFormWrapper = styled.div`
  .password-rules {
    text-align: left;
  }
  .label {
    text-align: left;
    .form-label {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      margin-bottom: 2px;
    }
  }
`;

export const TittleDiv = styled.div`
  font-size: 40px !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 20px;
  font-family: kanit;
`;
export const ErrorMessageDiv = styled.div`
  font-size: 14px !important;
  text-align: left;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-family: Open Sans;
  margin-bottom: 15px;
`;

export const MainWrapperDiv = styled.div`
  width: 100%;
  overflow: hidden;
  .formStyle {
    margin-top: 30px;
  }
  .imageStyle {
    height: 86vh;
    @media (max-width: 1100px) {
      height: 100%;
      width: 100%;
    }
  }
  .registerInputText {
    width: 45ch;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-family: "Open Sans";
  }

  @media (max-width: 1100px) {
    margin: auto;
    width: 95%;
  }
`;

export const PasswordRulesWrapper = styled.div`
  color: ${({ isError }) => (isError ? "#e01c1c" : "inherit")};
  border: ${({ isError }) => (isError ? "1px solid #e01c1c" : "1px solid transparent")};
  padding: 10px;
  border-radius: 4px;
  font-size: 14px !important;
  font-family: Open Sans;
  text-align: "center";
  transition: border 0.5s;
`;

export const FieldLabel = styled.div`
  //opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s;
`;
export const FirstLoginMessage = styled.div`
  font-size: 14px !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-family: Open Sans;
  width: 42%;
  margin: auto;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-size: 16px !important;
  overflow: hidden;
  clear: both;
`;
