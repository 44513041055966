import React from "react";
import { CheckboxWrapper } from "./Checkbox.component.styles";
import PropTypes from "prop-types";

const Checkbox = ({ label, id, name, required, checked, onChange, onClick, size, ...rest }) => (
  <CheckboxWrapper size={size} title="">
    <input
      type="checkbox"
      id={id}
      name={name}
      required={required}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      {...rest}
    />
    <span title={label}>{label}</span>
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  /**
   * Specify whether the checkbox should be disabled, or not
   */
  disabled: PropTypes.bool,
  /**
   * checkbox label
   */
  label: PropTypes.string.isRequired,
  /**
   *  Provide an function to be called when the checkbox element is clicked
   */
  onChange: PropTypes.func,
  /**
   * How large should the checkbox be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

Checkbox.defaultProps = { size: "small" };

export default React.memo(Checkbox);
