import styled from "styled-components";
export const ManagementPanelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .card {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .bg-white {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .assetTableszOver {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .title-style,
  .asset-type-size,
  .asset-type-size span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .userTabBarStyle p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  span.tooltipMUI h2 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .div_user_pin {
    .btn-success {
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} !important;
      border-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} !important;
    }
  }
`;

export const Error = styled.div``;
export const ParagraphDiv = styled.div`
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  margin: 10px auto;
  font-size: 18px !important;
  b {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

export const UserOverviewWrapper = styled.div`
 /* box-shadow: 0px 0px 4px 0px #80808080;*/
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  padding: 15px;
  min-height: calc(100vh - 322px);
  @media (max-width: 1100px) {
    padding: initial;
    min-height: auto;
  }
`;
export const UserOverviewMainDiv = styled.div``;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 14% 30% 16%;
  @media (max-width: 1100px) {
    width: 100%;
    display: block;
  }
`;
export const WrapperDiv = styled.div`
  flex: 1;
  padding: 0px 5px;
  @media (max-width: 1100px) {
    float: right;
    text-align: end;
    margin-right: 10px;
    width: 45%;
  }
`;

export const WrapperDivDetails = styled.div`
  float: left;
  display: flex;
`;
export const FullNameSpan = styled.div`
  font-family: Kanit;
  font-size: 24px !important;
  line-height: 30px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1100px) {
    font-size: 20px !important;
    line-height: 25px;
  }
`;
export const HeadingSpan = styled.div`
  font-family: "Open Sans";
  font-size: 13px !important;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 2px;
  @media (max-width: 1100px) {
  }
`;
export const HeadingDiv = styled.div`
  font-family: "Open Sans";
  font-size: 13px !important;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1100px) {
  }
`;

export const MultiCardDiv = styled.div`
  flex: 2;
  @media (max-width: 1100px) {
    display: none;
  }
`;
export const SwitchWrapper = styled.div`
  text-align: right;
  padding-left: 10px;
  flex: 0;
  label {
    margin: 0px !important;
  }
  @media (max-width: 1100px) {
    display: block;
    clear: both;
    width: 100%;
    margin: 10px 0px !important;
    overflow: hidden;
    text-align: left;
    float: left;
  }
`;

export const ImageAndDescDiv = styled.div`
  flex: 1;
  display: flex;
  @media (max-width: 1100px) {
    width: 50%;
    display: block;
    float: left;
    margin-top: 15px;
  }
`;
export const WrapperDesDiv = styled.div`
  padding: 0px 10px;
  display: grid;
`;
export const ImagWrapperDiv = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const PassWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-color: #28a745;
  border-color: #28a745;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-weight: 400;
  font-size: 12px !important;
  width: max-content;
  padding: 2px 10px;
  float: right;
`;
export const PinWrappper = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-color: #28a745;
  border-color: #28a745;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-weight: 400;
  font-size: 12px !important;
  width: max-content;
  padding: 2px 10px;
  padding: 2px 10px;
  float: right;
  margin-top: 5px;
`;

export const PinPassDiv = styled.div`
  @media (max-width: 1100px) {
    float: left;
    text-align: left;
    ${PinWrappper} {
      margin-top: 0px;
      margin-right: 5px;
    }
  }
`;

export const StatusHead = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;
export const StatusMobileRender = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    label {
      margin: 10px 0px !important;
    }
  }
`;
export const StatusDesktopRender = styled.div`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const SpanText = styled.div`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;
export const IconPassPin = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    font-size: 24px !important;
    padding-top: 5px;
  }
`;

export const TabComponentDiv = styled.div`
  .nav a {
    padding: 7px 0px;
  }
  .nav-tabs {
    margin-top: 10px;
  }
  .nav-link {
    font-size: 14px !important;
  }
  .tab-content {
    padding: 0px 0px 0px 0px;
  }
  @media (max-width: 1100px) {
    clear: both;
    width: 95%;
    margin: auto;
  }
`;
