import axios from "axios";
import config  from "../config";
import { store } from "../redux/store";
import { refreshToken } from "../redux/user/user.action";
import { setErrorCode } from "../redux/errors/action";
const baseUrl =  config.baseUrl
export const apiCall = (endpoint, customConfig) => {
  const config = {
    method: "GET",
    ...customConfig,
  };

  const state = store.getState();
  const authToken = state?.user?.loginDetails?.token;

  let cancelToken;

  if (config?.cancelPreviousRequest) {
    cancelToken = axios.CancelToken.source().token;
  }

  var axiosOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    url: `${baseUrl}${endpoint}`,
    method: config?.method || "get",
  };
  if (config?.type === "file") {
    axiosOptions.headers["Content-Type"] = "multipart/form-data";
  }
  if (authToken) {
    axiosOptions.headers["auth-token"] = `${authToken}`;
    console.log(`Calling Secured Api ${endpoint}`);
  }
  if (config?.data) {
    axiosOptions.data = config?.data;
  }
  if (cancelToken) {
    axiosOptions.cancelToken = cancelToken;
  }

  return new Promise(function (resolve, reject) {
    axios(axiosOptions)
      .then((response) => {
        const status = parseInt(response?.data?.status);
        if (status === 500 || status === 404 || status === 403) {
          store.dispatch(setErrorCode(status));
          resolve(response);
        } else if (status === 401 && !response?.data?.data?.message.includes("Access Denied")) {
          store.dispatch(refreshToken());
        }
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        if (Number(error?.response?.data?.status) === 401) {
          if (
            error?.response?.data?.message?.includes("Expired Token") ||
            error?.response?.message?.includes("Expired Token")
          ) {
            store.dispatch(refreshToken());
          } else {
            store.dispatch(setErrorCode(401));
          }
        } else if (Number(error?.response?.data?.status) === 403) {
          store.dispatch(setErrorCode(403));
        } else if (Number(error?.response?.status) === 500) {
          store.dispatch(setErrorCode(500));
        } else if (Number(error?.response?.status) === 404) {
          store.dispatch(setErrorCode(404));
        }
        reject(error);
      });
  });
};
