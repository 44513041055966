import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import LoginRecords from "./applicationActivityLogs/loginRecords/LoginRecords.component";
import LockUnlock from "./applicationActivityLogs/lockUnlockLogs/LockUnlock.component";
import {
  TabWrapper,
  DropdownWrapper,
  MobileViewDropdown,
  ButtonWrapper,
  PanelView,
} from "./ActivityLogs.component.styles";
import MenuBar from "./MenuBar.component";
import { getDropdownAccountList } from "../../../redux/dropdownFilters/action";
import { setSettingsDropdownAccountId } from "../../../redux/dropdownFilters/dropdownActions";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";

const AccountDropdown = ({
  t,
  accountListWithAll,
  selectedAccount,
  setSelectedAccount,
  accountListLoading,
}) => {
  return (
    <div className="dropdown-wrapper">
      <DropdownComponent
        label={t("Account")}
        placeholder={"Select an Account"}
        options={accountListWithAll}
        value={selectedAccount}
        handleFilter={(selectedData) => {
          setSelectedAccount(selectedData);
        }}
        size="medium"
        isLoading={accountListLoading}
      />
    </div>
  );
};

function ActivityLogs({
  updateActiveNavItem,
  isSiteAdmin,
  accountListLoading,
  dropdownAccountList,
  loginDetails,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  setDropdownAccountId,
  dropdownAccountId,
  setSettingsDropdownAccountId,
  getDropdownAccountList,
  permissions,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({ value: "all", label: "All" });
  const [accountListWithAll, setAccountListWithAll] = useState([]);

  useEffect(() => {
    updateActiveNavItem("activityLogs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (isSuperAdmin && (dropdownAccountId === "all" || !dropdownAccountId)) {
      setSettingsDropdownAccountId(dropdownAccountList?.[0] && dropdownAccountList?.[0]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountList]);

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList.map((option) => ({
        value: option._id,
        label: option.companyName,
      }));
      optionListTemp.unshift({ value: "all", label: "All Accounts" });
      setAccountListWithAll(optionListTemp);
    } else {
      setAccountListWithAll([{ value: "all", label: "All Accounts" }]);
    }
  }, [dropdownAccountList]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="settings">
      <BreadCrumb />

      <Row className="mt-2">
        <Col
          className="sidebar-col"
          md={isSidebarVisible ? 3 : 0}
          style={{
            transition: "all 0.3s ease",
            overflow: isSidebarVisible ? "visible" : "hidden",
            width: isSidebarVisible ? "25%" : "0%",
          }}
        >
          <MobileViewDropdown
            style={{
              transition: "all 0.5s ease",
              width: isSidebarVisible ? "0%" : "100%",
              overflow: "hidden",
            }}
          >
            {isSidebarVisible || (
              <Row>
                <Col md={3}>
                  <TabWrapper className="tabAnimation">
                    {isSuperAdmin && (
                      <DropdownWrapper>
                        <AccountDropdown
                          t={t}
                          accountListWithAll={accountListWithAll}
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          accountListLoading={accountListLoading}
                        />
                      </DropdownWrapper>
                    )}
                  </TabWrapper>
                </Col>
              </Row>
            )}
          </MobileViewDropdown>

          {isSidebarVisible && (
            <TabWrapper>
              {isSuperAdmin && (
                <DropdownWrapper>
                  <AccountDropdown
                    t={t}
                    accountListWithAll={accountListWithAll}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    accountListLoading={accountListLoading}
                  />
                </DropdownWrapper>
              )}
              <MenuBar history={history} />
            </TabWrapper>
          )}
        </Col>
        <Col
          md={isSidebarVisible ? 9 : 12}
          className="content-col"
          style={{
            transition: "all 0.3s ease",
            width: isSidebarVisible ? "75%" : "100%",
            marginBottom: isSidebarVisible ? "initial" : "50px",
          }}
        >
          <ButtonWrapper className="absolute right-4" onClick={toggleSidebar}>
            {isSidebarVisible ? (
              <OverlayTrigger placement="top" overlay={<Tooltip id="expand-tooltip">Expand View</Tooltip>}>
                <i className="icon icon-fullscreen-expand"></i>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="collapse-tooltip">Collapse View</Tooltip>}
              >
                <i className="icon icon-fullscreen-collapse"></i>
              </OverlayTrigger>
            )}
          </ButtonWrapper>

          <Switch>
            <Route
              path={`${match.url}/application-login-records`}
              render={(props) => <LoginRecords {...props} selectedAccount={selectedAccount} />}
            />
            <Route
              path={`${match.url}/lock-unlock-logs`}
              render={(props) => <LockUnlock {...props} selectedAccount={selectedAccount} />}
            />
            <Redirect to={`${match.url}/application-login-records`} />
          </Switch>
        </Col>
      </Row>

      <style jsx>{`
        @media (max-width: 768px) {
          .sidebar-col {
            width: 100% !important;
          }
          .content-col {
            width: 100% !important;
          }
        }
      `}</style>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSiteAdmin: state.user.isSiteAdmin,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  accountListLoading: state.dropdownFilters?.settings?.accountListLoading,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityLogs));
