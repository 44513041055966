export const notificationSettingsTypes = {
  GET_NOTIFICATIONS_SETTINGS_SUCCESS: "GET_NOTIFICATIONS_SETTINGS_SUCCESS",
  GET_ACCOUNT_NOTIFICATIONS_SETTINGS_SUCCESS: "GET_ACCOUNT_NOTIFICATIONS_SETTINGS_SUCCESS",
  NOTIFICATION_LOADING: "NOTIFICATION_LOADING",

  GET_ALERT_SETTINGS_BY_ASSET_ID_SUCCESS: "GET_ALERT_SETTINGS_BY_ASSET_ID_SUCCESS",
  GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR: "GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR",
  RESET_NOTIFICATION_SETTINGS_FLAGS: "RESET_NOTIFICATION_SETTINGS_FLAGS",

  BULK_UPDATE_SUCCESS: "BULK_UPDATE_SUCCESS",
  BULK_UPDATE_LOADING: "BULK_UPDATE_LOADING",

  //getSystemSettingsUserTypesSuccess
  GET_SYSTEM_SETTINGS_USER_TYPE_SUCCESS: "GET_SYSTEM_SETTINGS_USER_TYPE_SUCCESS",
  GET_SYSTEM_SETTINGS_USER_TYPE_ERROR: "GET_SYSTEM_SETTINGS_USER_TYPE_ERROR",
};
