import styled from "styled-components";
// Navigation Menu Items Sub Title Text
export const NavItemSubText = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  color: #323232;
  background-color: #f9f9f9;
`;

export const SidebarMenuDiv = styled.div``;
