import React, { useState, useEffect } from "react";
import { Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import { connect } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { landingPageSignup, login } from "../../../redux/user/user.action";
import validationConstant from "../../../constant/messages/validation.json";
import Button from "../../../components/Button/Button.component";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import InputPasswordPin from "../../../components/InputFieldComponent/InputPasswordPin.component";
import {
  RegisterContainer,
  RegisterDiv,
  ErrorContainer,
  PasswordErrorMessageContainer,
  LineDIV,
} from "./RegistrationForm.component.styles";
import config from "../../../config";

const baseUrl = config.baseUrl; //Base Url

// Registration Form Component
function RegistrationForm({ landingPageSignup, login, history, selectedLang }) {
  const { t } = useTranslation();

  // Setting User State with Email and Password
  const [user, setUser] = useState({
    email: "",
    password: "",
    passwordVerification: "",
  });

  const [showPasswordValidationError, setShowPasswordValidationError] = useState(false);

  useEffect(() => {
    setShowPasswordValidationError(false);
  }, [user.passwordVerification, user.password]);

  // Setting Captch State with intial false
  const [captcha, setCaptcha] = useState({
    isVerified: false,
  });

  // Setting Errors state with email and password intially to be null for displaying errors
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    passwordVerification: "",
  });
  const [IsValidated, setIsValidated] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  // Setting Show Password, Confirm Password Visibility State
  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  //const [IsSubmitted, setIsSubmitted] = useState(false);

  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);

  // Recaptcha Loaded Handler
  const recaptchaLoaded = () => {
    console.log("captcha loaded successfully");
  };

  useEffect(() => {
    verifyCallback();
  }, [selectedLang]);

  // Recaptcha Verify Call back upon clicking captcha
  const verifyCallback = (response) => {
    if (response) {
      setCaptcha({ isVerified: true });
    }
  };

  // Show Passowrd Visiblity
  /*   const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  }; */

  // Show Confirm Passowrd Visiblity
  /*   const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  }; */

  // Setting Email Availabilty Check State
  const [emailCheck, setEmailCheck] = useState([null]);
  const [weakPasswordMessage, setWeakPasswordMessage] = useState(false);

  // Email Validation Handler
  /*   const emailValidation = (e) => {
    e.preventDefault();

    const { email } = e.target.value;
    axios.post(`${baseUrl}/user/emailavailabilty`, { email: email }).then((response) => {
      if (response.data.success === false) {
        setEmailCheck(t(validationConstant.update_email_duplicate_error));
      } else {
        const message = response.data.message;
        setEmailCheck([message]);
      }
    });
  }; */

  // Submitting Form data to Signup API
  const handleSubmit = async (event) => {
    event.preventDefault();
    //setIsSubmitted(true);
    const form = event.currentTarget;
    const isFormValid = validate();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsValidated(true);
      return;
    }
    setIsValidated(false);
    if (isFormValid) {
      if (captcha.isVerified) {
        const { email, password } = user;
        setEmailCheck(false);
        setIsLoading(true);
        try {
          // Email Availability Check
          const response1 = await axios.post(`${baseUrl}/user/emailavailabilty`, { email: email });
          if (response1?.data?.success === false) {
            setEmailCheck(t(validationConstant.update_email_duplicate_error));
            setIsLoading(false);
            return;
          }
          //signup
          const response = await axios.post(`${baseUrl}/account/signup`, user);
          if (!response?.data?.success) {
            ///show error ...!!
            const msg = response?.data?.errors?.errors?.[0]?.msg;
            setWeakPasswordMessage(t(msg ? msg : validationConstant.something_went_wrong));
            setIsLoading(false);
            if (msg === "Password too weak") {
              setShowPasswordValidationError(true);
            }
            return;
          }
          landingPageSignup(user);
          //log user in...!!
          const responseLogin = await axios.post(`${baseUrl}/user/login`, {
            email: email,
            password: password,
          });
          login(responseLogin?.data);
          history.push("/app/dashboard");
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          // setEmailCheck(t(validationConstant.something_went_wrong))
          setEmailCheck(t(err?.message));
        }
      }
    }
  };

  // Validating Form Fields with required, pattern validations

  const validate = () => {
    let isValid = true;
    let passwordVerification = "";
    let password = "";
    let email = "";
    let fullName = "";

    if (!user.passwordVerification || user.password !== user.passwordVerification) {
      isValid = false;
      passwordVerification = t(validationConstant.confirm_password_not_equal);
    }

    if (!user.password) {
      isValid = false;
      password = t(validationConstant.password_required);
    }
    var emailPattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    );

    if (!user.email) {
      isValid = false;
      email = t(validationConstant.email_required);
    } else if (!user.email || !emailPattern.test(user.email)) {
      isValid = false;
      email = t(validationConstant.invalid_email);
    }

    if (!user.fullName) {
      isValid = false;
      fullName = t(validationConstant.name_required);
    }

    setErrors({
      passwordVerification,
      password,
      email,
      fullName,
    });

    return isValid;
  };

  return (
    <RegisterContainer>
      <>
        <Form noValidate validated={IsValidated} onSubmit={handleSubmit}>
          {/*First and Last Name Text Field*/}
          <RegisterDiv>
            <InputFieldWrapper
              autoFocus={true}
              placeholder={t("First and Last Name")}
              minLength="1"
              maxLength="64"
              type="text"
              size="medium"
              onChange={(e) => setUser({ ...user, fullName: e.target.value })}
              value={user.fullName}
              errorMessage={t(validationConstant.name_required)}
            />

            <InputFieldWrapper
              autoFocus={true}
              placeholder={t("Email")}
              minLength="1"
              maxLength="64"
              type="text"
              size="medium"
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
                setEmailCheck("");
              }}
              isError={!!errors.email}
              value={user.email}
              errorMessage={t(validationConstant.invalid_email)}
            />

            {/* Email Address */}
            <InputPasswordPin
              autocomplete="new-password"
              type={values.showPassword ? "text" : "password"}
              size="medium"
              placeholder={t("Password")}
              minLength="1"
              maxLength="64"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              required={true}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
            />

            <InputPasswordPin
              type={values.showConfirmPassword ? "text" : "password"}
              size="medium"
              placeholder={t("Confirm Password")}
              onChange={(e) => setUser({ ...user, passwordVerification: e.target.value })}
              minLength="1"
              maxLength="64"
              required={true}
              isError={isConfirmPasswordFocused && user.password !== user.passwordVerification}
              onFocus={() => setIsConfirmPasswordFocused(true)}
              errorMessage={t(validationConstant.confirm_password_not_equal)}
            />
            <ErrorContainer>{emailCheck ? emailCheck : ""}</ErrorContainer>
            {showPasswordValidationError && (
              <PasswordErrorMessageContainer>
                <ErrorContainer>{weakPasswordMessage}</ErrorContainer>
                <LineDIV>The password must satisfy the following requirements:</LineDIV>
                <LineDIV>- Minimum 8 characters </LineDIV>
                <LineDIV>- One upper case character (A-Z) </LineDIV>
                <LineDIV>- One lower case character (a-z) </LineDIV>
                <LineDIV>- One numerical character (0-9) </LineDIV>
                <LineDIV>- One special character (~!@#$%^&*()-_=+)</LineDIV>
              </PasswordErrorMessageContainer>
            )}
          </RegisterDiv>
          {/* Password */}
          {/* <Form.Group controlId="formBasicPassword">
            <InputGroup>
              <Form.Control
                autocomplete="new-password"
                type={values.showPassword ? "text" : "password"}
                className="registerInputText"
                placeholder={t("Password")}
                minLength="1"
                maxLength="64"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                required={true}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
              />
              <InputGroup.Append>
                <InputGroup.Text id="inputGroupPrepend" onClick={handleClickShowPassword}>
                  {values.showPassword ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid" style={{ textAlign: "left" }}>
                <Trans>Password Rules</Trans>
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group> */}

          {/* Confirm Password */}
          {/* <Form.Group controlId="formBasicConfirmPassword">
            <InputGroup>
              <Form.Control
                type={values.showConfirmPassword ? "text" : "password"}
                className="registerInputText"
                placeholder={t("Confirm Password")}
                onChange={(e) => setUser({ ...user, passwordVerification: e.target.value })}
                minLength="1"
                maxLength="64"
                required={true}
                isInvalid={isConfirmPasswordFocused && user.password !== user.passwordVerification}
                onFocus={() => setIsConfirmPasswordFocused(true)}
              />
              <InputGroup.Append>
                <InputGroup.Text id="inputGroupPrepend" onClick={handleClickShowConfirmPassword}>
                  {values.showConfirmPassword ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Append>
              {user.password && (
                <Form.Control.Feedback type="invalid" style={{ textAlign: "left" }}>
                  {t(validationConstant.confirm_password_not_equal)}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group> */}

          <Row className="d-flex justify-content-center">
            {selectedLang && (
              <Recaptcha
                sitekey="6LcL5t8ZAAAAAIyPUk75YUihXaMIzbXRl5BS2Gwn"
                render="explicit"
                expiredCallback={() => setCaptcha({ isVerified: false })}
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
                hl={selectedLang}
              />
            )}
          </Row>
          <br></br>
          {/*Submit Button*/}
          <Button
            style={{ width: "100%" }}
            buttonType="primary"
            label={
              IsLoading ? (
                <div
                  title={t("Get Started For Free")}
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></div>
              ) : (
                t("Get Started For Free")
              )
            }
            showIcon={false}
          />
        </Form>
      </>
    </RegisterContainer>
  );
}

// Dispatching action to the action creators
const mapDispatchToProps = (dispatch) => ({
  landingPageSignup: (userDetails) => dispatch(landingPageSignup(userDetails)),
  login: (userDetails) => dispatch(login(userDetails)),
});

const mapStateToProps = (state) => ({
  selectedLang: state.config?.selectedLanguage?.value,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationForm));
