import styled from "styled-components";

export const HeaderInfoWrapper = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  padding: 10px 15px 10px 15px;
`;

export const BacktoSearch = styled.div`
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  i {
    font-size: 21px;
    padding: 0;
    margin: 0;
    float: left;
    display: inline-grid;
  }
`;

export const AssetNameHeading = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Kanit;
  font-weight: 500;
  font-size: 24px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderAssetDetails = styled.div`
  float: left;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  display: block;
  width: 100%;
  strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

/* DeviceInfo.jsx Style Component */
export const DetailsMainWrapper = styled.div`
  overflow: hidden;
  padding: 10px 15px 10px 15px;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  label {
    font-size: 12px;
  }
  input {
    font-size: 14px;
  }
  .locationFilter {
    margin-bottom: 10px;
    position: relative;
    z-index: 1001;
  }
  .nav-link::before {
    width: 0px;
  }
  .nav {
    display: block;
    clear: both;
    overflow: hidden;
    width: fit-content;
    a {
      float: left;
      width: 210px;
    }
  }
`;

export const DetailsHeading = styled.div`
  float: left;
  display: block;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: Kanit;
  font-weight: 500;
  font-size: 18px !important;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
`;

export const MapWrapper = styled.div`
  clear: both;
  height: 330px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  .leaflet-container {
    width: 100wh;
    height: 300px;
  }
`;

export const MapPositionDetail = styled.div`
  float: left;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-size: 16px !important;
  text-align: left;
  display: block;
  width: 100%;
`;

export const SpanDetails = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftFontAwsm = styled.div`
  display: block;
`;

export const LocationNotAvail = styled.div`
  clear: both;
  line-height: 300px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  text-align: center;
`;

export const BreakLine = styled.div`
  clear: both;
  width: 100%;
  float: left;
  height: 10px;
`;

export const TabWrapper = styled.div`
  clear: both;
  width: 100%;
  float: left;
  div[variant="horizontal"] {
    .nav {
      float: left;
    }
    .locationFilter {
      margin-top: 10px;
      width: 100%;
    }
    .tab-content {
      clear: both;
    }
  }
`;
