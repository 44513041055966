import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledFlag = styled.div`
  display: inline-block;
  height: ${(props) => props.height || "20px"};
  padding-bottom: ${(props) => props.paddingBottom || "0"};

  svg {
    height: 100%;
    width: auto;
  }
`;

const FlagImage = ({ Component, height, paddingBottom }) => {
  return (
    <StyledFlag height={height} paddingBottom={paddingBottom}>
      <Component />
    </StyledFlag>
  );
};

FlagImage.propTypes = {
  Component: PropTypes.elementType,
  height: PropTypes.string,
  paddingBottom: PropTypes.string,
};

export default FlagImage;
