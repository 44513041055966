import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as FontS } from "../../assets/loaderIcon/S.svg";
import { ReactComponent as FontI } from "../../assets/loaderIcon/I.svg";
import { ReactComponent as FontAI } from "../../assets/loaderIcon/A1.svg";
import { ReactComponent as FontR } from "../../assets/loaderIcon/R.svg";
import { ReactComponent as FontDot } from "../../assets/loaderIcon/Dot.svg";
import {
  LoaderWrapper,
  Line,
  TextWrapper,
  LoaderText,
  LoaderWithText,
  LoaderContainer,
} from "./SieraLoader.component.styles";

const SieraLoader = ({ OnlyLoaderIcon, iconWithLogo, label, showLabelBelowLogo, size }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoaderContainer size={size}>
      {OnlyLoaderIcon && (
        <LoaderWrapper key={key}>
          <Line className="line1" />
          <Line className="line2" />
          <Line className="line3" />
        </LoaderWrapper>
      )}
      {iconWithLogo && (
        <TextWrapper>
          <FontS />
          <FontI />
          <LoaderWrapper key={key}>
            <Line className="line1" />
            <Line className="line2" />
            <Line className="line3" />
          </LoaderWrapper>
          <FontR />
          <FontAI />
          <FontDot />
          <FontAI />
          <FontI />
        </TextWrapper>
      )}
      {showLabelBelowLogo && (
        <LoaderWithText>
          <TextWrapper>
            <FontS />
            <FontI />
            <LoaderWrapper key={key}>
              <Line className="line1" />
              <Line className="line2" />
              <Line className="line3" />
            </LoaderWrapper>
            <FontR />
            <FontAI />
            <FontDot />
            <FontAI />
            <FontI />
          </TextWrapper>
          <LoaderText>{label}...</LoaderText>
        </LoaderWithText>
      )}
    </LoaderContainer>
  );
};

SieraLoader.propTypes = {
  /**
   * Specify whether the loader should display only the loader icon.
   */
  OnlyLoaderIcon: PropTypes.bool,

  /**
   * Specify whether the loader should display the loader icon with the company logo.
   */
  iconWithLogo: PropTypes.bool,

  /**
   * Specify whether the loader should display a label (text) below the logo.
   */
  showLabelBelowLogo: PropTypes.bool,

  /**
   * The label text to display below the loader logo when `showLabelBelowLogo` is true.
   */
  label: PropTypes.string,

  /**
   * The size here define small medium and large.
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

SieraLoader.defaultProps = {
  OnlyLoaderIcon: false,
  iconWithLogo: false,
  label: "Loading",
  showLabelBelowLogo: false,
  size: "large",
};

export default SieraLoader;
