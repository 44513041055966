import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../../../components/Button/Button.component";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { setAddAssetTypeModal } from "../../../../redux/modals/action";

const ActionButtons = ({ updateActiveNavItem, setAddAssetTypeModal }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const openAddAssetTypeModal = () => {
    setAddAssetTypeModal(true);
  };

  return (
    <>
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"view"}>
        <Button
          label={t("Manage Assets")}
          onClick={() => {
            updateActiveNavItem("assets");
            history.push("/app/management");
          }}
          showIcon={false}
          buttonType="secondary"
        />
      </PermissionCheck>
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_TYPE_MANAGEMENT"} actionName={"add"}>
        <Button
          iconClass="add"
          label={t("Add Asset Type")}
          onClick={() => openAddAssetTypeModal(true)}
          showIcon
          buttonType="secondary"
        />
      </PermissionCheck>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
});

export default connect(null, mapDispatchToProps)(ActionButtons);
