import { errorTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

export const setErrorCode = (data) => ({
  type: errorTypes.SET_ERROR_CODE,
  data,
});

const getErrorLogLoading = () => ({
  type: errorTypes.GET_ERROR_LOG_LOADING,
});

const getErrorLogSuccess = (data, isResetState, pageNo) => ({
  type: errorTypes.GET_ERROR_LOG_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getErrorLogError = (err) => ({
  type: errorTypes.GET_ERROR_LOG_ERROR,
  err,
});

export const resetErrorLog = () => ({
  type: errorTypes.RESET_ERROR_LOG,
});

// get users list
export const getErrorLogs = (paginationData, isResetState) => {
  return async (dispatch, getState) => {
    dispatch(getErrorLogLoading());
    let state = getState();

    let { pageNo, pageSize, accountId, siteId, assetId, startDate, endDate, errorDescription } =
      paginationData;
    let isSuperAdmin = state?.user?.isSuperAdmin;

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (state.user.isSiteAdmin) {
      siteId = state?.user?.loginDetails?.userData?.authorizedSites[0];
    }

    if (!accountId) {
      dispatch(getErrorLogError("error"));
      return;
    }

    if (!errorDescription) {
      errorDescription = "all";
    }

    let url = `/common/getS3Logs/${accountId}/${siteId}/${assetId}/${errorDescription}/${startDate}/${endDate}/${pageNo}/${pageSize}`;

    apiCall(url)
      .then((resp) => {
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        dispatch(getErrorLogSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getErrorLogError(err));
      });
  };
};
