export const dropdownTypes = {
  GET_DROPDOWN_ACCOUNT_LIST: "GET_ACCOUNT_LIST",
  GET_DROPDOWN_WORKSITE_LIST: "GET_WORKSITE_LIST",
  GET_DROPDOWN_ACCOUNT_LIST_LOADING: "GET_DROPDOWN_ACCOUNT_LIST_LOADING",
  GET_DROPDOWN_ACCOUNT_LIST_LOADED: "GET_DROPDOWN_ACCOUNT_LIST_LOADED",
  GET_DROPDOWN_ASSET_LIST: "GET_ASSET_LIST",
  GET_DROPDOWN_ASSET_TYPE_LIST: "GET_ASSET_TYPE_LIST",
  SET_DROPDOWN_ACCOUNT_ID: "SET_DROPDOWN_ACCOUNT_ID",
  SET_DROPDOWN_WORKSITE_ID: "SET_DROPDOWN_WORKSITE_ID",
  SET_DROPDOWN_ASSET_ID: "SET_DROPDOWN_ASSET_ID",
  SET_DROPDOWN_ASSET_TYPE_ID: "SET_DROPDOWN_ASSET_TYPE_ID",
  SET_DROPDOWN_USER_ACCESS_LEVEL: "SET_DROPDOWN_USER_ACCESS_LEVEL",
  SET_QRCODE_FILTER_BY: "SET_QRCODE_FILTER_BY",
  SET_DROPDOWN_PRODUCT_TYPE: "SET_DROPDOWN_PRODUCT_TYPE",
  RESET_ALL_DROPDOWN_FILTER: "RESET_ALL_DROPDOWN_FILTER",
  GET_DROPDOWN_USER_LIST: "GET_DROPDOWN_USER_LIST",
  GET_DROPDOWN_PRODUCT_LIST: "GET_DROPDOWN_PRODUCT_LIST",
  GET_DROPDOWN_ACCOUNT_LIST_BY_ID: "GET_ACCOUNT_LIST_BY_ID",
  GET_DROPDOWN_USERS_BY_USER_TYPE_SUCCESS: "GET_DROPDOWN_USERS_BY_USER_TYPE_SUCCESS",
  SET_DROPDOWN_USER_TYPE_ID: "SET_DROPDOWN_USER_TYPE_ID",
  SET_DROPDOWN_USER_ID: "SET_DROPDOWN_USER_ID",

  SET_SETTINGS_DROPDOWN_ACCOUNT_ID: "SET_SETTINGS_DROPDOWN_ACCOUNT_ID",
  SET_SETTINGS_DROPDOWN_WORKSITE_ID: "SET_SETTINGS_DROPDOWN_WORKSITE_ID",
  SET_SETTINGS_DROPDOWN_ASSET_ID: "SET_SETTINGS_DROPDOWN_ASSET_ID",
  SET_SETTINGS_DROPDOWN_ASSET_TYPE_ID: "SET_SETTINGS_DROPDOWN_ASSET_TYPE_ID",
  SET_SETTINGS_DROPDOWN_USER_ACCESS_LEVEL: "SET_SETTINGS_DROPDOWN_USER_ACCESS_LEVEL",
  SET_SETTINGS_DROPDOWN_USER_ID: "SET_SETTINGS_DROPDOWN_USER_ID",
  SET_SETTINGS_DROPDOWN_USER_TYPE_ID: "SET_SETTINGS_DROPDOWN_USER_TYPE_ID",
  RESET_ALL_SETTINGS_DROPDOWN_FILTER: "RESET_ALL_SETTINGS_DROPDOWN_FILTER",

  GET_DROPDOWN_WORKSITE_LIST_FOR_SETTINGS_SUCCESS: "GET_DROPDOWN_WORKSITE_LIST_FOR_SETTINGS_SUCCESS",

  GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_SUCCESS: "GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_SUCCESS",
  GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_LOADING: "GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_LOADING",
  GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_ERROR: "GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_ERROR",

  GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_SUCCESS: "GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_SUCCESS",
  GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_LOADING: "GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_LOADING",
  GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_ERROR: "GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_ERROR",

  GET_DROPDOWN_ACCOUNT_LIST_ERROR: "GET_DROPDOWN_ACCOUNT_LIST_ERROR",

  RESET_DROPDOWN_WORKSITE_LIST: "RESET_DROPDOWN_WORKSITE_LIST",
  RESET_DROPDOWN_ASSET_TYPE_LIST: "RESET_DROPDOWN_ASSET_TYPE_LIST",
  RESET_DROPDOWN_ASSET_LIST: "RESET_DROPDOWN_ASSET_LIST",
  RESET_DROPDOWN_USER_LIST: "RESET_DROPDOWN_USER_LIST",

  SET_DURATION: "SET_DURATION",
  GET_DROPDOWN_USERTYPE_LIST: "GET_DROPDOWN_USERTYPE_LIST",
};
