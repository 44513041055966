import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AccountList from "./AccountList.component";
import Account from "./Account.component";
import AccountActionBar from "./AccountsActionBar.component";
import SearchBox from "../../../components/SearchBox/Searchbox.component";
import { AccountManagementPage, LoaderOverlay } from "./AccountManagement.styles";
import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";

const AccountManagement = ({ AccountLoading }) => {
  const [SearchText, setSearchText] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (AccountLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!AccountLoading) {
      setShowLoader(false);
    }
  }, [AccountLoading, hasShownOnce]);

  return (
    <AccountManagementPage>
      <>
        <AccountActionBar />
      </>

      {showLoader && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}

      <Row className="mt-2">
        <Col md={3}>
          <SearchBox
            searchText={SearchText}
            setSearchText={setSearchText}
            placeholder={t("Search an account")}
            label={t("Your Accounts")}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <AccountList
            setImageLoaded={() => setImageLoaded(false)}
            searchText={SearchText}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
          />
        </Col>
        <Col md={9}>
          <Account imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
        </Col>
      </Row>
    </AccountManagementPage>
  );
};

const mapStateToProps = (state) => ({
  AccountLoading: state.accounts.AccountLoading,
});

export default connect(mapStateToProps)(AccountManagement);
