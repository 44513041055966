export const inspectionTypes = {
  UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_ERROR: "UPLOAD_IMAGE_ERROR",
  DELETE_IMAGE_LOADING: "DELETE_IMAGE_LOADING",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_ERROR: "DELETE_IMAGE_ERROR",
  POST_INSPECTION_RESPONSE_LOADING: "POST_INSPECTION_RESPONSE_LOADING",
  POST_INSPECTION_RESPONSE_SUCCESS: "POST_INSPECTION_RESPONSE_SUCCESS",
  POST_INSPECTION_RESPONSE_ERROR: "POST_INSPECTION_RESPONSE_ERROR",
  GET_CHECKLIST_BY_ID_LOADING: "GET_CHECKLIST_BY_ID_LOADING",
  GET_CHECKLIST_BY_ID_SUCCESS: "GET_CHECKLIST_BY_ID_SUCCESS",
  GET_CHECKLIST_BY_ID_ERROR: "GET_CHECKLIST_BY_ID_ERROR",
  TO_STORE_SELECTED_ASSET: "TO_STORE_SELECTED_ASSET",
};
