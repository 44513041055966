/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA DASHBOARD PAGE USER MANAGMENT TYPES
 */

export const userManagementTypes = {
  // 1. TYPE: USER LIST
  LIST_USERS: "LIST_USERS",

  // 2. TYPE: ADD USER
  ADD_USER: "ADD_USER",

  // 3. TYPE: DEFAULT USER OVERVIEW
  DEFAULT_USER_OVERVIEW: "DEFAULT_USER_OVERVIEW",

  // 4. TYPE: SELECTED USER OVERVIEW
  USER_OVERVIEW: "USER_OVERVIEW",

  // 5. TYPE: UPDATE USER AFTER USER UPDATE
  UPDATE_USER: "UPDATE_USER",

  //To edit username
  EDIT_USERNAME_LOADING: "EDIT_USERNAME_LOADING",
  EDIT_USERNAME_SUCCESS: "EDIT_USERNAME_SUCCESS",
  EDIT_USERNAME_ERROR: "EDIT_USERNAME_ERROR",
  RESET_USERNAME_STATE: "RESET_USERNAME_STATE",

  SET_SELECTED_NEW_ACCOUNT_OWNER: "SET_SELECTED_NEW_ACCOUNT_OWNER",
};
