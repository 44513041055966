/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import SettingsSection from "../../../../components/SettingsSection";
import {
  getUnAuthorizedAccessAlertSettingsByAssetId,
  updateUnAuthorizedAccessAlertSettingsByAssetId,
  resetUnAuthorizedAlertSettingsByAssetId,
} from "../../../../redux/notification/action";
import useAlert from "../../../../utils/useAlert";
import { isEqual } from "../../../../utils/validations/utilities";
import { SettingWrapper } from "./NotificationSetting.component.styles";
import {
  getDropdownWorksiteList,
  setDropdownAccountId,
  setDropdownWorksiteId,
} from "../../../../redux/dropdownFilters/action";
import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter } from "../Settings.Styles";
import NoSettingsDataFound from "../common/NoAssetFound.component";
import { bulkSettingsUpdate, resetNotificationSettingsFlags } from "../../../../redux/config/action";
import { getSelectedOptions, handleRecipientsChangeUtil } from "./common";
import SnackBar from "../../SnackBar";
import Recipients from "./emailerRecipients/Recipients";
import validationConstant from "../../../../constant/messages/validation.json";

const UnauthorizedAlertSetting = ({
  loginDetails,
  notificationSetting,
  isAccountAdmin,
  isAccountOwner,
  isSiteAdmin,
  setDropdownAccountId,
  dropdownAccountId,
  getUnAuthorizedAccessAlertSettingsByAssetId,
  updateUnAuthorizedAccessAlertSettingsByAssetId,
  resetUnAuthorizedAlertSettingsByAssetId,
  dropdownAssetId,
  bulkSettingsUpdate,
  loading,
  resetNotificationSettingsFlags,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
}) => {
  const [unAuthorizedAccessAlertSettings, setUnAuthorizedAccessAlertSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownWorksiteId, setDropdownWorksiteId] = useState(null);
  const [worksiteId, setWorksiteId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getUnAuthorizedAccessAlertSettingsByAssetId(selectedAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    if (selectedAssetId) {
      getUnAuthorizedAccessAlertSettingsByAssetId(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    const acId =
      isAccountAdmin || isAccountOwner || isSiteAdmin
        ? loginDetails && loginDetails.accountId
        : dropdownAccountId;
    setCurrentAccountId(acId);
  }, [dropdownAccountId, loginDetails, isAccountAdmin, isAccountOwner, isSiteAdmin]);

  const { t } = useTranslation();
  const { showAlert } = useAlert();

  useEffect(() => {
    getDropdownWorksiteList();
  }, [dropdownAccountId]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setDropdownAccountId(loginDetails?.accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  const setDefaultNotificationSettingsWorksite = () => {
    setClearSelection(true);
    setUnAuthorizedAccessAlertSettings(notificationSetting?.unAuthorizedAccessAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.unAuthorizedAccessAlertSettings,
    unAuthorizedAccessAlertSettings
  );

  useEffect(() => {
    if (notificationSetting?.unAuthorizedAccessAlertSettings) {
      setDefaultNotificationSettingsWorksite();
    }
  }, [notificationSetting?.unAuthorizedAccessAlertSettings]);

  const handleUnauthorizedAccessChange = (field, value) => {
    setUnAuthorizedAccessAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const displaySettings = {
    unAuthorizedAccessAlertSettings: {
      title: "UnAuthorizedAccessRecipient",
      description: "unauthorizedRecipientDescription",
      tableHeader: false,
      onChange: handleUnauthorizedAccessChange,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Unauthorized Access Alerts",
          value: !!unAuthorizedAccessAlertSettings?.status,
          field: "status",
          likeHeader: true,
        },
      ],
    },
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    setDropdownWorksiteId(worksiteId);
  };

  const onReset = () => {
    resetUnAuthorizedAlertSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const onUpdate = () => {
    if (Object.keys(applyToAllObj).length !== 0) {
      const selectedOptions = getSelectedOptions(applyToAllObj);

      const updatableSettings = {
        ...selectedOptions,
        settings: { notificationSettings: { unAuthorizedAccessAlertSettings } },
      };
      bulkSettingsUpdate(updatableSettings, setIsUpdateLoading);
    }
    updateUnAuthorizedAccessAlertSettingsByAssetId(
      selectedAssetId,
      {
        notificationSettings: {
          unAuthorizedAccessAlertSettings,
        },
      },
      setIsUpdateLoading
    );
  };

  const handleCloseSnackBar = () => {
    setIsUpdateLoading(false);
    setDefaultNotificationSettingsWorksite();
    resetNotificationSettingsFlags();
    setApplyToAllObj({});
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(
      userType,
      isChecked,
      unAuthorizedAccessAlertSettings,
      setUnAuthorizedAccessAlertSettings
    );
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...unAuthorizedAccessAlertSettings,
      customEmails: [...email],
    };
    setUnAuthorizedAccessAlertSettings(temp);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("An alert will be sent when there is an unauthorized access on an asset")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {dropdownAssetId && typeof dropdownAssetId === "boolean" && dropdownAssetId === true ? (
        <NoSettingsDataFound />
      ) : (
        <>
          <SettingsContainer>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.unAuthorizedAccessAlertSettings}
                isLoading={notificationUpdateLoading || loading}
              />

              {selectedAssetId && (
                <Recipients
                  customEmails={unAuthorizedAccessAlertSettings?.customEmails}
                  handleCustomEmailsChange={handleCustomEmailsChange}
                  recipients={unAuthorizedAccessAlertSettings?.userTypes}
                  handleRecipientsChange={handleRecipientsChange}
                />
              )}
            </SettingWrapper>
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              setDropdownWorksiteId={setDropdownWorksiteId}
              setWorksiteId={setWorksiteId}
              showAppyToAll={true}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleCancel={setDefaultNotificationSettingsWorksite}
              isLoading={isLoading}
              worksiteId={worksiteId}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleUpdate={onUpdate}
              handleReset={onReset}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
        </>
      )}
      <SnackBar
        isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
        label={t(validationConstant.notification_settings_updated)}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters.accountId,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    loading: state.notificationSetting.loading,

    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (assetTypeId) => dispatch(setDropdownWorksiteId(assetTypeId)),
  getUnAuthorizedAccessAlertSettingsByAssetId: (id) =>
    dispatch(getUnAuthorizedAccessAlertSettingsByAssetId(id)),
  updateUnAuthorizedAccessAlertSettingsByAssetId: (id, data, setIsUpdateLoading) =>
    dispatch(updateUnAuthorizedAccessAlertSettingsByAssetId(id, data, setIsUpdateLoading)),
  resetUnAuthorizedAlertSettingsByAssetId: (id, setIsUpdateLoading) =>
    dispatch(resetUnAuthorizedAlertSettingsByAssetId(id, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedAlertSetting);
