import { menuStateTypes } from "./menu.types";

// Selecting side tab
export const updateMenu = (menuState) => ({
  type: menuStateTypes.UPDATE_MENU,
  payload: menuState,
});

// Selecting side report tab
export const updateMenuReport = (reportMenuState) => ({
  type: menuStateTypes.UPDATE_REPORT_TAB,
  payload: reportMenuState,
});

export const updateActiveNavItem = (item) => ({
  type: menuStateTypes.UPDATE_ACTIVE_ITEM,
  payload: item,
});
