import { useState, useEffect } from "react";
import MultiplePhotoUpload from "../Input/FileUpload.component";

export default function MyModal({
  data,
  currentIndex,
  setChecklistResponseForm,
  handleSubmitAnswer,
  uploadImage,
  deleteImage,
  deleteImageLoading,
  uploadImageLoading,
}) {
  const [answerInput, setAnswerInput] = useState("");
  const [currentResponse, setCurrentResponse] = useState({});

  useEffect(() => {
    setCurrentResponse(data[currentIndex]);
    setAnswerInput(data[currentIndex]?.response);
  }, [currentIndex]);

  const onClickButton = (e) => {
    data[currentIndex] = {
      ...currentResponse,
      response: e.target.value,
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  return (
    <div className="justify-center flex-wrap w-full  block md:block lg:flex ">
      <div className="flex-1 grid  border-[#F2F2F2] border">
        <div className="heightmax">
          <p className="w-full font-sans p-1 rounded text-black-text flex justify-left ">
            <span className="text-md md:text-xl lg:text-xl "> {currentResponse?.question}</span>
          </p>
        </div>
      </div>
      <div className="flex-1 relative">
        <div>
          <div className="flex justify-centr flex-wrap pt-5">
            <div className="w-full flex-1  justify-center">
              <button
                type="button"
                onClick={onClickButton}
                value="OK"
                className={`${
                  answerInput !== "OK" ? "button-green" : "button-green-selected"
                } font-kanit text-xrlem border-none`}
              >
                OK
              </button>
            </div>
            <div className="w-full flex-1 justify-center">
              <button
                type="button"
                onClick={onClickButton}
                value="NOT OK"
                className={`${
                  answerInput !== "NOT OK" ? "button-red" : "button-red-selected"
                } font-kanit text-xrlem border-none`}
              >
                NOT OK
              </button>
            </div>
          </div>
          <div className="w-full flex  justify-center">
            <button
              type="button"
              onClick={onClickButton}
              value="NA"
              className={`${
                answerInput !== "NA" ? "button-gray" : "button-gray-selected"
              }  mt-4 font-kanit text-xrlem border-none`}
            >
              NA
            </button>
          </div>
        </div>
        <div className="mt-4">
          <MultiplePhotoUpload
            uploadImage={uploadImage}
            deleteImage={deleteImage}
            deleteImageLoading={deleteImageLoading}
            uploadImageLoading={uploadImageLoading}
            currentResponse={currentResponse}
            setCurrentResponse={setCurrentResponse}
          />
        </div>
      </div>
    </div>
  );
}
