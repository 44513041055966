import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DropdownWrapper from "../../../components/DropdownComponent/DropdownWrapper";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownUserAccessLevel,
} from "../../../redux/dropdownFilters/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { getUserTypeList, getUserTypeDetailList } from "./../../../redux/userType/action";

function UserFilters({
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownUserAccessLevel,
  dropdownWorksiteList,
  dropdownAccountList,
  editForm,
  setEditAlertModal,
  dropdownFilterAccountId,
  dropdownFilterWorksiteId,
  dropdownUserAccessLevel,
  userTypeList,
  showWorksiteDropdown,
  isSuperAdmin,
  getUserTypeList,
  getUserTypeDetailList,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (dropdownFilterAccountId && dropdownFilterAccountId !== "all") {
      getUserTypeList();
      getUserTypeDetailList();
    }
  }, [dropdownFilterAccountId]);

  return (
    <>
      {isSuperAdmin && (
        <Col>
          <DropdownWrapper
            filter={{
              type: "Account",
              key: "companyName",
              data: dropdownAccountList,
            }}
            value={dropdownFilterAccountId}
            label={t("filter")}
            handleFilter={(value) => (editForm ? setDropdownAccountId(value) : setEditAlertModal(true))}
          />
        </Col>
      )}

      {showWorksiteDropdown && (
        <Col>
          <DropdownWrapper
            filter={{
              type: t("Worksite"),
              key: "name",
              data: dropdownWorksiteList,
            }}
            value={dropdownFilterWorksiteId}
            handleFilter={(value) => (editForm ? setDropdownWorksiteId(value) : setEditAlertModal(true))}
          />
        </Col>
      )}
      <Col>
        <DropdownWrapper
          filter={{
            type: t("User Type"),
            key: "name",
            data: userTypeList,
          }}
          value={dropdownUserAccessLevel}
          handleFilter={(value) => (editForm ? setDropdownUserAccessLevel(value) : setEditAlertModal(true))}
          disabled={isSuperAdmin && (!dropdownFilterAccountId || dropdownFilterAccountId === "all")}
        />
      </Col>
      {!showWorksiteDropdown && <Col md={4}></Col>}
    </>
  );
}

const mapStateToProps = (state) => ({
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  editForm: state.forms.editForm,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  dropdownUserAccessLevel: state.dropdownFilters.dropdownUserAccessLevel,
  userTypeList: state.userType.UserTypeList,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownUserAccessLevel: (accessLevel) => dispatch(setDropdownUserAccessLevel(accessLevel)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  getUserTypeList: () => dispatch(getUserTypeList()),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserFilters));
