import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  MenuDivStyle,
  MainMenuDiv,
  SubmenuUl,
  SubmenuUlLi,
  IconUpSide,
  IconDownSide,
  ManinMenuUl,
  BlankMessageDiv,
  MenuListStylediv,
  ActiveSubmenuStyle,
  SearchTextHeighlight,
  TextStyle,
} from "../ChartsOverview.component.styles";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { selectChart } from "../../../../../../redux/analyticsPage/action";
import LoaderSkeletonList from "../LoaderSkeletonList/LoaderSkeletonList.component";
import CustomDropdown from "./DropdownWrapperComp";

// Custom component to render search results with highlighted text
function SearchHighlighter({ text, searchInput }) {
  const { t } = useTranslation();
  const searchIndex = text.toLowerCase().indexOf(searchInput.toLowerCase());
  if (searchIndex !== -1) {
    return (
      <TextStyle title={text}>
        {text.substring(0, searchIndex)}
        <SearchTextHeighlight>
          <Trans>{text.substring(searchIndex, searchIndex + searchInput.length)}</Trans>
        </SearchTextHeighlight>
        <Trans>{text.substring(searchIndex + searchInput.length)}</Trans>
      </TextStyle>
    );
  } else {
    return text;
  }
}

function ChartsList({ searchInput, chartListData, selectChart, getUserChartsListLoading }) {
  const { t } = useTranslation();
  const subMenuRef = useRef(null);
  const [selectedCats, setSelectedCats] = useState([]);
  // const [selectedChart, setSelectedChart] = useState("Total Inspections: Number");
  const [openMainMenus, setOpenMainMenus] = useState({ impact: false, inspection: true, nearmiss: false });
  // const [filteredChartList, setFilteredChartlist] = useState([]);
  const [filteredSubmenus, setFilteredSubmenus] = useState({});
  const [activeSubmenu, setActiveSubmenu] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [isChartListDataPresent, setIsChartListDataPresent] = useState(false);

  const checkChartListDataPresent = () => {
    const result = filteredSubmenus?.length > 0;

    setIsChartListDataPresent(result);
    console.log("isChartListDataPresent", isChartListDataPresent);
    return result;
  };
  useEffect(() => {
    setShowLoader(getUserChartsListLoading);
  }, [getUserChartsListLoading]);

  let cumulativeIndex = 0;

  const submenuIndices = chartListData?.reduce((acc, mainMenu) => {
    acc[mainMenu.cat] = cumulativeIndex;
    cumulativeIndex += mainMenu.entries.length;
    return acc;
  }, {});

  const handleSubMenuClick = (cat, subMenu, subIndex) => {
    subMenu.index = submenuIndices[cat] + subIndex;
    selectChart(subMenu);
    // scrollToSection(submenuIndices[cat] + subIndex);
    setActiveSubmenu((prevActiveSubmenu) => {
      return {
        ...prevActiveSubmenu,
        [cat]: subIndex,
      };
    });
  };
  const handleMainMenuClick = (mainMenuId) => {
    setOpenMainMenus((prevOpenMenus) => {
      const updatedOpenMenus = {};
      for (const id in prevOpenMenus) {
        updatedOpenMenus[id] = id === mainMenuId ? !prevOpenMenus[id] : false;
      }
      return updatedOpenMenus;
    });

    // Reset the active submenu when a main menu is clicked
    setActiveSubmenu((prevActiveSubmenu) => {
      return {
        ...prevActiveSubmenu,
        [mainMenuId]: null,
      };
    });
  };
  useEffect(() => {
    // Automatically open main menus if searchable content is inside
    const updatedOpenMainMenus = {};
    chartListData?.forEach((mainMenu) => {
      const hasMatchingSubmenu = mainMenu.entries.some(
        (submenu) => submenu && submenu.name && submenu.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      updatedOpenMainMenus[mainMenu.cat] = hasMatchingSubmenu;
    });
    setOpenMainMenus(updatedOpenMainMenus);
    // filterChartList()
    console.log("updatedOpenMainMenus", updatedOpenMainMenus);
    filterChartListData();
  }, [searchInput, chartListData]);

  const filterChartListData = () => {
    const newfilteredSubmenus = chartListData?.reduce((acc, mainMenu) => {
      const filteredSubMenu = mainMenu?.entries?.filter(
        (submenu) => submenu && submenu.name && submenu.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (filteredSubMenu.length > 0) {
        acc[mainMenu.cat] = { cat: mainMenu.cat, catLabel: mainMenu.catLabel, entries: filteredSubMenu };
      }
      return acc;
    }, {});
    if (newfilteredSubmenus) {
      setFilteredSubmenus(newfilteredSubmenus);
      const updatedIsChartListDataPresent = Object.keys(newfilteredSubmenus)?.length > 0 ? true : false;
      setIsChartListDataPresent(updatedIsChartListDataPresent);
      console.log("isChartListDataPresent", newfilteredSubmenus);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSelectChange = (selectedOption) => {
    const selectedChartName = selectedOption.value;

    // Find the selected submenu from filteredSubmenus
    let selectedSubMenu = null;
    Object.keys(filteredSubmenus).forEach((cat) => {
      const foundSubMenu = filteredSubmenus[cat]?.entries.find(
        (submenu) => submenu.name === selectedChartName
      );
      if (foundSubMenu) {
        selectedSubMenu = foundSubMenu;
      }
    });

    // Handle the selected submenu similarly to handleSubMenuClick
    if (selectedSubMenu) {
      const catKey = Object.keys(filteredSubmenus).find((cat) =>
        filteredSubmenus[cat]?.entries.includes(selectedSubMenu)
      );
      const subIndex = filteredSubmenus[catKey]?.entries.findIndex((submenu) => submenu === selectedSubMenu);

      if (catKey !== undefined && subIndex !== -1) {
        handleSubMenuClick(catKey, selectedSubMenu, subIndex);
      }
    }
  };

  const options = Object.keys(filteredSubmenus).map((cat) => {
    const groupOptions = filteredSubmenus[cat]?.entries.map((submenu) => ({
      value: submenu.name,
      label: submenu.name,
    }));
    return {
      label: filteredSubmenus[cat]?.catLabel,
      options: groupOptions,
    };
  });

  return (
    <>
      {windowWidth <= 800 ? (
        <CustomDropdown
          label="Charts"
          options={options}
          onChange={handleSelectChange}
          isSearchable={true}
          isLoading={false}
          groupedOptions={true}
        />
      ) : (
        <MenuListStylediv>
          <LoaderSkeletonList showLoader={showLoader} />
          {!showLoader && (
            <ManinMenuUl>
              {!isChartListDataPresent ? (
                <BlankMessageDiv>
                  <p>
                    <Trans>ADD_CHART_FROM_ADD_BUTTON</Trans>
                  </p>
                </BlankMessageDiv>
              ) : (
                Object.keys(filteredSubmenus).map((cat) => (
                  <li key={cat}>
                    <MainMenuDiv
                      className={`${openMainMenus[cat] ? "activeMain" : ""}`}
                      onClick={() => handleMainMenuClick(cat)}
                    >
                      <span>
                        <Trans>{filteredSubmenus[cat]?.catLabel}</Trans>
                      </span>
                      {openMainMenus[cat] ? (
                        <IconUpSide className="icon icon-arrow-up" />
                      ) : (
                        <IconDownSide className="icon icon-arrow-down" />
                      )}
                    </MainMenuDiv>
                    <SubmenuUl style={{ display: openMainMenus[cat] ? "block" : "none" }} ref={subMenuRef}>
                      {filteredSubmenus[cat].entries.map((submenu, subIndex) => (
                        <SubmenuUlLi
                          key={submenu.mChartId}
                          className={activeSubmenu[cat] === subIndex ? "active" : ""}
                        >
                          <ActiveSubmenuStyle
                            onClick={() => {
                              handleSubMenuClick(cat, submenu, subIndex);
                            }}
                          >
                            <SearchHighlighter text={submenu.name} searchInput={searchInput} />
                          </ActiveSubmenuStyle>
                        </SubmenuUlLi>
                      ))}
                    </SubmenuUl>
                  </li>
                ))
              )}
            </ManinMenuUl>
          )}
        </MenuListStylediv>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  chartListData: state.analyticsPage?.getUserChartsList?.success,
  getUserChartsListLoading: state.analyticsPage?.getUserChartsListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  // updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  selectChart: (chartData) => dispatch(selectChart(chartData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsList));
