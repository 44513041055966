import React, { useState, useEffect, useRef } from "react";
import { HourMeterWrapper, ErrorText, StyledInput } from "./HourMeterInput.component.styles";
import PropTypes from "prop-types";

function NumberInput({ disabled, handleInputChange, errorMessage, pointerEvents, isError, ...rest }) {
  const [value, setValue] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [fontSize, setFontSize] = useState(40);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9.]/g, "");

    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      inputValue = inputValue.replace(/\./g, "");
    }

    const parts = inputValue.split(".");
    if (parts[0].length > 6) {
      parts[0] = parts[0].slice(0, 5);
    }
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    inputValue = parts.join(".");

    if (inputValue.startsWith("0")) {
      inputValue = inputValue.slice(1);
    }

    if (isFocused) {
      setValue(inputValue);
    } else {
      setValue(` ${inputValue} `);
    }
    handleInputChange(inputValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    if (isFocused) {
      inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }
  }, [isFocused]);

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      const textWidth = inputRef.current.scrollWidth;
      if (fontSize === 49 || value.length < 6) {
        if (textWidth > 250) {
          setFontSize(30);
        } else {
          setFontSize(49);
        }
      }
    }
  }, [value]);

  return (
    <HourMeterWrapper>
      <StyledInput
        type="text"
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        ref={inputRef}
        isFocused={isFocused}
        fontSize={fontSize}
        pointerEvents={pointerEvents}
        {...rest}
      />
      {isError && <ErrorText>{errorMessage}</ErrorText>}
    </HourMeterWrapper>
  );
}

NumberInput.propTypes = {
  /**
   * Specify whether the Hour Meter Input should be disabled, or not
   */
  disabled: PropTypes.bool,
  /**
   * Specify the text for Hour Meter Input
   */
  isError: PropTypes.bool,
  /**
   * This pointerEvents is for dispay the value
   */
  pointerEvents: PropTypes.bool,
};

NumberInput.defaultProps = {
  handleInputChange: () => {},
  pointerEvents: false,
};

export default React.memo(NumberInput);
