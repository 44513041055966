import React from "react";
import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//bootstrap imports
import { Row } from "react-bootstrap";

//redux
import { setAddUserTypeModal } from "../../../../redux/modals/action";

// custom css
import ManagementPanelStyles from "../../../../view/Dashboard/Management/panels/ManagementPanel.module.css";
import ActionButtons from "../userTypes/view/UserTypeSection/ActionButtons.component";
import styled from "styled-components";
const ActionBarWrapper = styled.div`
  position: relative;
`;
const ActionBarButtons = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  display: block;
  button {
    border: 0px;
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    color: black;
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Open Sans";
    i {
      display: none;
    }
  }
`;

function UserTypeActionBar({ showActionBar }) {
  return (
    <>
      {!showActionBar ? (
        ""
      ) : (
        <ActionBarWrapper>
          <ActionBarButtons>
            <ActionButtons />
          </ActionBarButtons>
        </ActionBarWrapper>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({
  setAddUserTypeModal: (data) => dispatch(setAddUserTypeModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTypeActionBar));
