export const initialState = {
  getInspectionReportListLoading: false,
  getInspectionReportListSuccess: false,
  getInspectionReportListError: false,
  inspectionReportList: [],
  inspectionReportTotalCount: 0,
  getZipFileReportLoading: false,
  getZipFileReportSuccess: false,
  getZipFileReportFailed: false,
  getImpactReportListLoading: false,
  getImpactReportListSuccess: false,
  getImpactReportListError: false,
  impactReportList: [],
  getUnauthorizedUseReportLoading: false,
  getUnauthorizedUseReportSuccess: false,
  unauthorizedUseReport: [],
  unauthorizedUseCount: 0,
  getUnauthorizedReportLoading: false,
  getUnauthorizedReportSuccess: false,
  getUnauthorizedReportError: false,
  totalUnauthorizedReportCount: 0,
  unauthorizedReportList: [],
  impactReportTotalCount: 0,
  getZipFileImpactReportLoading: false,
  getZipFileImpactReportSuccess: false,
  assetStatusList: [],
  getLastHourMeterListLoading: false,
  getLastHourMeterListSuccess: false,

  // Inspection Report Export All CSV
  exportAllInspectionCSVLoading: false,
  exportAllInspectionCSVSuccess: false,
  exportAllInspectionCSVError: false,

  // Impact Report Export All CSV
  exportAllImpactCSVLoading: false,
  exportAllImpactCSVSuccess: false,
  exportAllImpactCSVError: false,
};
