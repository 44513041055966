import React from "react";
import { useHistory } from "react-router-dom";
import tipMessage from "../../../../constant/messageTips";
import { ReactComponent as HrLine } from "../../../../assets/hr-line.svg";
import { ReactComponent as InformationTipIcon } from "../../../../assets/Information_Tooltip.svg";
// Button Component
import Button from "../../../../components/Button/Button.component";
import { Trans, useTranslation } from "react-i18next";

function InspectionResult({
  uploadImage,
  deleteImage,
  postInspectionResponse,
  inspectionPass,
  userFullName,
  setChecklistResponseForm,
  logoutUser,
  continueClick,
}) {
  let history = useHistory();
  const assetName = "Test Asset";
  const { t } = useTranslation();
  return (
    <div className="h-[80vh]">
      <div className="flex items-center justify-center">
        {inspectionPass ? (
          <span className="text-4xl font-normal font-kanit text-[#11A70B]">Inspection Pass!</span>
        ) : (
          <span className="text-4xl font-normal font-kanit text-[#DD0000]">Inspection Fail!</span>
        )}
      </div>
      <br />
      <div className="flex items-center justify-center text-center">
        {inspectionPass ? (
          <span className="text-md font-sans font-normal text-[#323232] ellipseStyle" title={userFullName}>
            {" "}
            Have a good day <b>{userFullName}</b>!
          </span>
        ) : (
          <span className="text-md font-sans font-normal text-[#323232] w-[100%] md:w-[43%] lg:w-[33%]">
            Please don't use this asset. Report to your worksite manager for further instructions
          </span>
        )}
      </div>

      <br />
      {inspectionPass && (
        <div className="flex shadow-[2px_2px_10px_rgba(0,0,0,0.5)] m-auto border-red-400 w-11/12 rounded-lg">
          <div className="bg-[#F2F2F2] w-10">
            <InformationTipIcon className="p-2" />
          </div>
          <div className="p-2">
            <div className="text-left text-[#0D5FBE] text-md font-normal font-kanit">Tip</div>
            <div className="text-left text-[#323232] text-sm  font-normal font-sans">
              {tipMessage[Math.floor(Math.random() * 8) + 0]}
            </div>
          </div>
        </div>
      )}
      <HrLine className="flex w-full mt-3 mb-4" />
      <br />
      <div className="flex items-center justify-center">
        <div className="text-[#323232] font-kanit text-2xl font-normal">
          {localStorage.getItem("assetName")}
        </div>
      </div>
      <div className="flex items-center justify-center">
       {/* <button
          onClick={() => continueClick()}
          className="mt-5 font-kanit text-white bg-secondary-background pl-8 pr-8 pt-3 pb-3 bg-[#0D5FBE] w-52 rounded font-normal"
        >
          CONTINUE
        </button> */}
        <Button showIcon={false} label={t("CONTINUE")} onClick={() => continueClick()} size="large" />
      </div>
      <br />
      {/* <div className='flex items-center justify-center'>
                <button onClick={() => { logoutUser(); history.push('/'); }} className='font-kanit text-[#0D5FBE] pl-8 pr-8 pt-3 pb-3 border border-[#0D5FBE] w-52 rounded font-normal'>LOGOUT</button>
            </div> */}
    </div>
  );
}

export default InspectionResult;
