export const modalTypes = {
  //For Modal Change
  ADD_SITE_MODAL: "ADD_SITE_MODAL",
  ADD_ASSET_MODAL: "ADD_ASSET_MODAL",
  ADD_USER_MODAL: "ADD-USER_MODAL",
  ADD_ASSET_TYPE_MODAL: "ADD_ASSET_TYPE_MODAL",
  ADD_USER_TYPE_MODAL: "ADD_USER_TYPE_MODAL",
  UPDATE_USER_MODAL: "UPDATE_USER_MODAL",
  MODAL_WAITING_FOR_OPEN: "MODAL_WAITING_FOR_OPEN",
  SET_ADD_ASSET_MODAL_REDIRECT: "SET_ADD_ASSET_MODAL_REDIRECT",
  SET_EDIT_ALERT_MODAL: "SET_EDIT_ALERT_MODAL",
  ADD_CHECKLIST_MODAL: "ADD_CHECKLIST_MODAL",
};
