import requiredFieldConstant from "./requiredFields.json";
import validationConstant from "../../constant/messages/validation.json";

const { requiredFields } = requiredFieldConstant;
//To validate data
export const isValid = (data, extraFeild) => {
  let errorFeildMsg = {};
  for (var key in data) {
    let feildToValidate = requiredFields[key];
    if (!data[key] && feildToValidate) {
      errorFeildMsg[key] = feildToValidate.errorMsg;
    }
  }
  for (var key in extraFeild) {
    let feildToValidate = requiredFields[key];
    if (!extraFeild[key] && feildToValidate) {
      errorFeildMsg[key] = feildToValidate.errorMsg;
    }
  }
  return errorFeildMsg;
};

// To validation account dropdown
export const toValidateAccountDropdown = (accountId) => {
  if (!accountId) {
    return validationConstant.account_required;
  }
};

// To validate section name
export const toValidateChecklistSectionName = (text) => {
  if (!text) {
    return validationConstant.section_name_required;
  }
  //Below code is commented for SIER-4845 - This is a temporary solution till the infra to show error is in place
  /*  let pattern = /^[a-zA-Z0-9!&'-\/.\s]*$/;
  if (!pattern.test(text)) {
    return validationConstant.invalid_input;
  } */
};

// To validate section name
export const toValidateChecklistQuestionName = (text) => {
  if (!text) {
    return validationConstant.question_name_required;
  }
  // let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  // if(!pattern.test(text)){
  //    return validationConstant.invalid_input;
  // }
};

// TO validate required feild
export const toValidateAssetAtrribute = (data) => {
  if (!data || !data?.trim()) {
    return validationConstant.asset_attribute_required;
  }
  // let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  // if(!pattern.test(data)){
  //    return validationConstant.invalid_input;
  // }
};

// To validate checklist name
export const toValidateChecklistName = (checklistName) => {
  if (!checklistName) {
    return validationConstant.checklist_name_required;
  }
  /*  let pattern = /^[a-zA-Z0-9!&'-\/.\s]*$/;
  if (!pattern.test(checklistName)) {
    return validationConstant.invalid_input;
  } */
};

// To validate user name
export const toValidateUserName = (name) => {
  if (!name) {
    return validationConstant.user_name_required;
  }
  const textToCheck = name?.trim();
  if (!textToCheck) {
    return validationConstant.user_name_required;
  }
  const pattern = /^[^!@#$%^&*()=+\\|\]}[{;:",<>?/~`]+$/g;
  if (!pattern.test(textToCheck)) {
    return validationConstant.invalid_input;
  }
};

// To validate asset name
export const toValidateAssetName = (assetName) => {
  if (!assetName) {
    return validationConstant.asset_name_required;
  }
  let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  if (!pattern.test(assetName)) {
    return validationConstant.invalid_input;
  }
};

// To validation asset type name
export const toValidateAssetTypeName = (name) => {
  if (!name) {
    return validationConstant.asset_type_required;
  }
  let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  if (!pattern.test(name)) {
    return validationConstant.invalid_input;
  }
};

// To validation worksite name
export const toValidateWorksiteName = (name) => {
  if (!name) {
    return validationConstant.worksite_name_required;
  }
  let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  if (!pattern.test(name)) {
    return validationConstant.invalid_input;
  }
};

export const toValidateEmail = (email) => {
  let pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return validationConstant.email_required;
  } else if (!pattern.test(email)) {
    return validationConstant.invalid_email;
  }
};

export const toValidatePhone = (phone, isMandatory) => {
  let pattern =
    /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/;
  if (!phone && isMandatory) {
    return validationConstant.field_required;
  } else if (!!phone && !pattern.test(phone)) {
    return validationConstant.invalid_phone;
  }
};

export const toValidateFullName = (fullName) => {
  // let pattern = /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/;
  if (!fullName) {
    return validationConstant.name_required;
  }
  let pattern = /^([a-zA-Z0-9!&'.-]+\s?)*$/g;
  if (!pattern.test(fullName)) {
    return validationConstant.invalid_input;
  }
};

//To validate password
export const toValidatePassword = (password, customRegex) => {
  const passwordPattern = customRegex
    ? customRegex
    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()\-_=+]).{8,64}$/;
  if (!password) {
    return validationConstant.password_required;
  } else if (!passwordPattern.test(password)) {
    return validationConstant.password_validation;
  } else {
    return false;
  }
};

//To validate confirm password
export const toValidateConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword) {
    return validationConstant.confirm_password_required;
  } else if (password !== confirmPassword) {
    return validationConstant.confirm_password_not_equal;
  } else {
    return false;
  }
};

//To validate pin
export const toValidatePin = (pin) => {
  let validation = /^[a-zA-Z0-9]{1,50}$/;
  if (!pin) {
    return validationConstant.pin_required;
  } else if (pin.length < 4) {
    return validationConstant.min_pin_length;
  } else if (!validation.test(pin)) {
    return validationConstant.pin_validation;
  } else {
    return false;
  }
};

export const isValidateDateObject = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

// Validation for alphanumeric string
export const isInvalidUrl = (str) => {
  let pattern = /^([a-zA-Z0-9])*$/g;
  if (!pattern.test(str)) {
    return true;
  }
  return false;
};
