import styled from "styled-components";
export const CarouselWrapperDiv = styled.div`
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;
export const FormControlStyle = styled.div`
  input.formControlStyle {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:hover,
  .input-group input:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .form-control:focus,
  .input-group input:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

export const WorksiteIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;
export const AssetIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;
export const UserIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;

export const AccountTabTabDiv = styled.div`
  clear: both;
  margin: 10px auto;
  float: left;
  width: 100%;
  .select__control {
    @media (max-width: 1100px) {
      margin-bottom: 20px;
      width: 94%;
    }
  }
`;
export const NoSectionStyle = styled.div`
  display: block;
  height: calc(100vh - 280px);
  align-content: center;
  clear: both;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  p {
    margin-bottom: 0px;
  }
  .clickDesktop {
    display: block;
    @media (max-width: 1100px) {
      display: none;
    }
  }
  .tapMobile {
    display: none;
    @media (max-width: 1100px) {
      display: block;
    }
  }
`;

export const AccountPageStyled = styled.div`
  clear: both;
  width: 100%;
  float: left;
  padding: 5px 15px;

  @media (max-width: 1100px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border-radius: 4px;
    padding: 5px 10px;
  }
`;

export const AccountTypeTopHeader = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    padding: 5px 10px;
    border-radius: 4px;
  }
`;

export const DetailsWrapper = styled.div`
  flex: 1 1 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const AccountTypeDataDescp = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL2 || "#434343"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
  }
`;

export const AccountTypeHeadDetail = styled.div`
  font-family: Kanit;
  font-size: 26px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
