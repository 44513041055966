import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import {
  UserDeactivationList,
  ParaText,
  TableContainerDiv,
  RowsContainer,
  Column,
  RowDiv,
  NameSite,
  CommaStyle,
} from "./UserDeactivatedList.styles";

const UsersDeactivation = ({ isActive, _id, preDeactivatedUserType, DeactivatedUsersList }) => {
  useEffect(() => {
    if (isActive && _id) {
      preDeactivatedUserType(_id);
    }
  }, [isActive, _id]);

  return (
    <UserDeactivationList>
      <ParaText>
        <Trans>USER_TYPE_DEACTIVATION_MSG</Trans>
      </ParaText>

      <TableContainerDiv>
        <RowDiv isHeader={true}>
          <Column isHeader={true}>#</Column>
          <Column isHeader={true}>
            <Trans>First & Last Name</Trans>
          </Column>
          <Column isHeader={true}>
            <Trans>Worksite</Trans>
          </Column>
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {DeactivatedUsersList.length === 0 ? (
            <RowDiv>
              <Column>-</Column>
              <Column>No user found</Column>
              <Column>No worksite found</Column>
            </RowDiv>
          ) : (
            DeactivatedUsersList.map((userType, index) => (
              <RowDiv key={userType._id}>
                <Column>{index + 1}</Column>
                <Column textleft={"left"}>
                  {userType.fullName ? <Trans>{userType.fullName}</Trans> : "No user found"}
                </Column>

                <Column textleft={"left"}>
                  {userType.authorizedSites.length > 0 ? (
                    <>
                      {userType.authorizedSites.slice(0, 2).map((site, index) => (
                        <>
                          <NameSite
                            key={site._id}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: userType.authorizedSites.length === 1 ? "200px" : "40px",
                              width: "100%",
                            }}
                          >
                            {site.name}
                          </NameSite>

                          <CommaStyle>
                            {userType.authorizedSites.length > 1 && index === 0 && ", "}
                          </CommaStyle>
                        </>
                      ))}
                      {userType.authorizedSites.length > 2 && (
                        <NameSite
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          title={userType.authorizedSites.map((site) => site.name).join(", ")}
                        >
                          +{userType.authorizedSites.length - 2} more
                        </NameSite>
                      )}
                    </>
                  ) : (
                    "No worksite found"
                  )}
                </Column>
              </RowDiv>
            ))
          )}
        </RowsContainer>
      </TableContainerDiv>
    </UserDeactivationList>
  );
};

export default UsersDeactivation;
