import styled from "styled-components";

export const UserTypePageContainer = styled.div`
  clear: both;
  width: 100%;
  /* margin-top: 5px; */
  float: left;
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 4px;
  padding: 5px 15px;
  @media (max-width: 1100px) {
    padding: 5px 2px;
    border: 0px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  span.selectAccount {
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    color: #676767;
    font-weight: 500 !important;
    line-height: 1.2;
  }
`;

export const UserTypeTopHeader = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    padding: 5px 10px;
    border-radius: 4px;
  }
`;

export const UserTypeHeadDetail = styled.div`
  font-family: Kanit;
  font-size: 26px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserTypeDataDescp = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL2 || "#434343"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
  }
`;
export const UserTypeTabDiv = styled.div`
  clear: both;
  margin: 10px auto;
  float: left;
  width: 100%;
  .select__control {
    @media (max-width: 1100px) {
      margin-bottom: 20px;
      width: 94%;
    }
  }
`;

export const ActionWrapper = styled.div`
  flex: 1 1 10%;
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    @media (max-width: 1100px) {
      display: none;
    }
  }
`;
export const DetailsWrapper = styled.div`
  flex: 1 1 90%;
`;
