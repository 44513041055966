import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import {
  AccountProfileContainer,
  InputProfileDiv,
  InputProfileDivCont,
  InputWrapper,
  SettingsWrapper,
  DivScroller,
  ButtonDiv,
  ButtonWrapper,
  HeadingLabel,
  Divider,
  SpecialChar,
  BlueCol,
  WeakPasswordWarning,
  HeaderWrapper,
  DropdownWrapper,
  NoAssetWrapper,
  LoaderOverlay,
} from "./PasswordPolicy.component.Styles";
import Button from "../../../../components/Button/Button.component";
import SettingsHeader from "../../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import GeneralSwitch from "../../Profile/generalSetting/GeneralComponent/GeneralSwitch.component";
import GeneralInputField from "../../Profile/generalSetting/GeneralComponent/GeneralInput.component";
import {
  getPasswordPolicy,
  updatePasswordPolicy,
  resetUpdatePasswordPolicyFlags,
} from "../../../../redux/accounts/action";

import SnackBar from "../../../Dashboard/SnackBar";
import Input from "../../../../components/Input";
import { setSettingsDropdownAccountId } from "../../../../redux/dropdownFilters/dropdownActions";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

function PasswordPolicy(props) {
  const {
    passwordPolicy,
    getPasswordPolicy,
    updatePasswordPolicy,
    passwordPolicyUpdating,
    passwordPolicyUpdateSuccess,
    passwordPolicyUpdateFailed,
    isSuperAdmin,
    resetUpdatePasswordPolicyFlags,
    policyUpdateErrorMessage,
    dropdownAccountId,
    dropdownAccountList,
    setSettingsDropdownAccountId,
    passwordPolicyLoading,
  } = props;
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const passwordExpirationRef = useRef(null);

  const [form, setForm] = useState({
    passwordExpiration: {
      status: false,
      duration: 365,
    },
    passwordReuse: {
      status: false,
      count: 3,
    },
    passwordLockoutDashboard: {
      status: false,
      count: 5,
    },
    passwordLockoutAndroid: {
      status: false,
      count: 5,
    },
    minLength: 8,
    upperCaseRequired: true,
    lowerCaseRequired: true,
    numberRequired: true,
    specialCharacterRequired: true,
    changeOwnPasswords: true,
    changeOthersPasswords: true,
    changePasswordAfterFirstLogin: false,
  });

  useEffect(() => {
    if (passwordPolicy) {
      setForm(passwordPolicy);
    }
  }, [passwordPolicy]);

  const changeHandler = (key, data, childKey) => {
    setForm((prev) => {
      if (childKey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [childKey]: data,
          },
        };
      } else {
        return {
          ...prev,
          [key]: data,
        };
      }
    });
  };

  useEffect(() => {
    if ((isSuperAdmin && dropdownAccountId) || !isSuperAdmin) {
      getPasswordPolicy(dropdownAccountId);
    }
  }, [dropdownAccountId]);

  const handleSave = () => {
    delete form._id;
    updatePasswordPolicy(form);
  };

  useEffect(() => {
    if (passwordPolicyUpdateSuccess) {
      setEditMode(false);
    }
  }, [passwordPolicyUpdateSuccess]);

  // Reset the flags on unmount
  useEffect(() => {
    return () => {
      resetUpdatePasswordPolicyFlags();
    };
  }, []);

  const handleCloseSnackBar = () => {
    if (!passwordPolicyUpdateFailed) {
      setEditMode(false);
    }
    resetUpdatePasswordPolicyFlags();
  };

  const handleMessage = (success, failed) => {
    if (success) {
      return t("Password Policy Updated Successfully");
    } else if (failed) {
      return t("Password Policy Update Failed." + policyUpdateErrorMessage);
    }
  };

  const LabelSpecial = () => {
    return (
      <SpecialChar>
        <Trans>Special Character</Trans>
        <BlueCol> (!@#$%^&*()_+-=[]{}|`)</BlueCol>
      </SpecialChar>
    );
  };

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("PASSWORD_POLICY_HEADER")} />
      <HeaderWrapper>
        <>
          <Col md={4} className="mb-2">
            {isSuperAdmin && (
              <DropdownWrapper>
                <label
                  htmlFor="Account"
                  style={{
                    fontSize: "12px",
                    color: "rgb(154, 154, 154)",
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "1px",
                    textAlign: "left",
                  }}
                >
                  {t("Account Name")}
                </label>
                <Input
                  options={dropdownAccountList?.map((data) => ({
                    _id: data._id,
                    label: data.companyName,
                  }))}
                  isValidationError={false}
                  type="select"
                  as="select"
                  label="Account"
                  value={dropdownAccountId}
                  onChange={(value) => {
                    setSettingsDropdownAccountId(value);
                  }}
                />
              </DropdownWrapper>
            )}
          </Col>
        </>
      </HeaderWrapper>

      <>
        {isSuperAdmin && !dropdownAccountId ? (
          <NoAssetWrapper>
            <p>
              <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
            </p>
          </NoAssetWrapper>
        ) : (
          <Form validated={true} onSubmit={(e) => e.preventDefault()}>
            <AccountProfileContainer>
              <DivScroller isSuperAdmin={isSuperAdmin}>
                {passwordPolicyLoading ? (
                  <LoaderOverlay>
                    <SieraLoader OnlyLoaderIcon size="small" />
                  </LoaderOverlay>
                ) : (
                  <InputProfileDiv>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralInputField
                          lineDivider={true}
                          leftLabel={t("Password Minimum Length")}
                          rightLabel={t("Enforce a minimum length of characters")}
                          bottomTextLabel={t("Needs to be between 8 and 128 characters")}
                          type="number"
                          value={form.minLength}
                          onChange={(e) => changeHandler("minLength", e.target.value)}
                          disabled={!editMode}
                          onBlur={(e) => {
                            const value = Math.max(8, Math.min(128, Number(e.target.value)));
                            changeHandler("minLength", value);
                          }}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    <HeadingLabel>
                      <Trans>Character Requirements</Trans>
                    </HeadingLabel>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          leftLabel={t("Lowercase Character")}
                          rightLabel=""
                          isChecked={!!form.lowerCaseRequired}
                          disabled={!editMode}
                          onSwitchChange={(checked) => changeHandler("lowerCaseRequired", checked)}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          leftLabel={t("Uppercase Character")}
                          rightLabel=""
                          disabled={!editMode}
                          isChecked={!!form.upperCaseRequired}
                          onSwitchChange={(checked) => changeHandler("upperCaseRequired", checked)}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          leftLabel={t("Number")}
                          rightLabel=""
                          disabled={!editMode}
                          isChecked={!!form.numberRequired}
                          onSwitchChange={(checked) => changeHandler("numberRequired", checked)}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          // leftLabel="Special Character (!@#$%^&*()_+-=[]{}|`)"
                          leftLabel={<LabelSpecial />}
                          rightLabel=""
                          disabled={!editMode}
                          isChecked={!!form.specialCharacterRequired}
                          onSwitchChange={(checked) => changeHandler("specialCharacterRequired", checked)}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    {!form.specialCharacterRequired &&
                      !form.numberRequired &&
                      !form.upperCaseRequired &&
                      !form.lowerCaseRequired && (
                        <WeakPasswordWarning
                          class="text-danger"
                          style={{ textAlign: "left", paddingTop: "10px" }}
                        >
                          {t("WEAK_PASSWORD_WARNING")}
                        </WeakPasswordWarning>
                      )}
                    <HeadingLabel>
                      <Trans>Other Requirements</Trans>
                    </HeadingLabel>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          leftLabel={t("Password Expiration")}
                          rightLabel={t(
                            "Enable the option to make the user passwords expire after a fixed time"
                          )}
                          disabled={!editMode}
                          onSwitchChange={(checked) =>
                            changeHandler("passwordExpiration", {
                              ...form.passwordExpiration,
                              status: checked,
                            })
                          }
                          isChecked={!!form.passwordExpiration?.status}
                        />

                        {form.passwordExpiration?.status && (
                          <InputWrapper>
                            <GeneralInputField
                              className="labelNP"
                              leftLabel=""
                              rightLabel=""
                              placeholder={""}
                              bottomTextLabel={t("Needs to be between 1 and 1825 days")}
                              rightInputText={t("day(s)")}
                              type="number"
                              disabled={!editMode}
                              ref={passwordExpirationRef}
                              value={form.passwordExpiration?.duration}
                              onChange={(e) =>
                                changeHandler("passwordExpiration", e.target.value, "duration")
                              }
                              onBlur={(e) => {
                                const value = Math.max(1, Math.min(1825, Number(e.target.value)));
                                changeHandler("passwordExpiration", value, "duration");
                              }}
                            />
                          </InputWrapper>
                        )}
                      </InputWrapper>
                    </InputProfileDivCont>
                    <br></br>
                    <Divider />
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          leftLabel={t("Change Password on 1st Login")}
                          rightLabel={t(
                            "Enable the option to mandate the users to change their password on first login"
                          )}
                          disabled={!editMode}
                          isChecked={!!form.changePasswordAfterFirstLogin}
                          onSwitchChange={(checked) =>
                            changeHandler("changePasswordAfterFirstLogin", checked)
                          }
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    {/*               <InputProfileDivCont>
                <InputWrapper>
                  <GeneralSwitch
                    lineDivider={true}
                    leftLabel={t("Change own passwords")}
                    rightLabel={t("Enable the option to allow the users to change their own passwords")}
                    disabled={!editMode}
                    isChecked={!!form.changeOwnPasswords}
                    onSwitchChange={(checked) => changeHandler("changeOwnPasswords", checked)}
                  />
                </InputWrapper>
              </InputProfileDivCont> */}
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          lineDivider={true}
                          leftLabel={t("Change Other's Passwords")}
                          rightLabel={t(
                            "Allow higher access level users to change passwords of lower access level users"
                          )}
                          disabled={!editMode}
                          isChecked={!!form.changeOthersPasswords}
                          onSwitchChange={(checked) => changeHandler("changeOthersPasswords", checked)}
                        />
                      </InputWrapper>
                    </InputProfileDivCont>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          // lineDivider={true}
                          leftLabel={t("Password Reuse")}
                          rightLabel={t(
                            "Enable this option to prevent users from using a previously used password"
                          )}
                          disabled={!editMode}
                          isChecked={!!form.passwordReuse?.status}
                          onSwitchChange={(checked) =>
                            changeHandler("passwordReuse", {
                              ...form.passwordReuse,
                              status: checked,
                            })
                          }
                        />

                        {form.passwordReuse?.status && (
                          <InputWrapper>
                            <GeneralInputField
                              leftLabel=""
                              rightLabel=""
                              placeholder={t("")}
                              bottomTextLabel={t("Needs to be between 1 and 10")}
                              leftInputText={t("Remember")}
                              rightInputText={t("password(s)")}
                              type="number"
                              disabled={!editMode}
                              value={form.passwordReuse?.count}
                              onChange={(e) => changeHandler("passwordReuse", e.target.value, "count")}
                              onBlur={(e) => {
                                const value = Math.max(1, Math.min(10, Number(e.target.value)));
                                changeHandler("passwordReuse", value, "count");
                              }}
                            />
                          </InputWrapper>
                        )}
                      </InputWrapper>
                      <br></br>
                      <Divider />
                    </InputProfileDivCont>
                    <InputProfileDivCont>
                      <InputWrapper>
                        <GeneralSwitch
                          leftLabel={t("Password Lockout (Website)")}
                          rightLabel={t(
                            "Define the number of wrong attempts a user can make at the time of login"
                          )}
                          disabled={!editMode}
                          onSwitchChange={(checked) =>
                            changeHandler("passwordLockoutDashboard", {
                              ...form.passwordLockoutDashboard,
                              status: checked,
                            })
                          }
                          isChecked={!!form.passwordLockoutDashboard?.status}
                        />

                        {form.passwordLockoutDashboard?.status && (
                          <InputWrapper>
                            <GeneralInputField
                              leftLabel=""
                              rightLabel=""
                              placeholder={t("")}
                              bottomTextLabel={t("Needs to be between 3 and 10")}
                              leftInputText={t("After")}
                              rightInputText={t("Time(s)")}
                              type="number"
                              disabled={!editMode}
                              value={form.passwordLockoutDashboard?.count}
                              onChange={(e) =>
                                changeHandler("passwordLockoutDashboard", e.target.value, "count")
                              }
                              onBlur={(e) => {
                                const value = Math.max(3, Math.min(10, Number(e.target.value)));
                                changeHandler("passwordLockoutDashboard", value, "count");
                              }}
                            />
                          </InputWrapper>
                        )}
                      </InputWrapper>

                      <InputWrapper>
                        <GeneralSwitch
                          leftLabel={t("Password Lockout (Application)")}
                          rightLabel={t(
                            "Define the number of wrong attempts a user can make at the time of login"
                          )}
                          disabled={!editMode}
                          isChecked={!!form.passwordLockoutAndroid?.status}
                          onSwitchChange={(checked) =>
                            changeHandler("passwordLockoutAndroid", {
                              ...form.passwordLockoutAndroid,
                              status: checked,
                            })
                          }
                        />

                        {form.passwordLockoutAndroid?.status && (
                          <InputWrapper>
                            <GeneralInputField
                              leftLabel=""
                              rightLabel=""
                              placeholder={t("")}
                              bottomTextLabel={t("Needs to be between 5 and 10")}
                              leftInputText={t("After")}
                              rightInputText={t("Time(s)")}
                              type="number"
                              disabled={!editMode}
                              value={form.passwordLockoutAndroid?.count}
                              onChange={(e) =>
                                changeHandler("passwordLockoutAndroid", e.target.value, "count")
                              }
                              onBlur={(e) => {
                                const value = Math.max(5, Math.min(10, Number(e.target.value)));
                                changeHandler("passwordLockoutAndroid", value, "count");
                              }}
                            />
                          </InputWrapper>
                        )}
                      </InputWrapper>

                      <br></br>
                      <Divider />
                    </InputProfileDivCont>
                  </InputProfileDiv>
                )}
              </DivScroller>

              {!editMode ? (
                <ButtonWrapper>
                  <ButtonDiv>
                    <Button
                      label={t("Edit")}
                      iconClass="edit"
                      onClick={() => setEditMode(true)}
                      showIcon
                      buttonType="secondary"
                      disabled={passwordPolicyUpdating || passwordPolicyUpdateSuccess}
                    />
                  </ButtonDiv>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <ButtonDiv style={{ marginRight: "15px" }}>
                    <Button
                      label={t("Cancel")}
                      onClick={() => {
                        getPasswordPolicy();
                        setEditMode(false);
                      }}
                      showIcon={false}
                      buttonType="ghost"
                    />
                  </ButtonDiv>
                  <ButtonDiv>
                    <Button
                      label={t("Save")}
                      iconClass="save"
                      onClick={handleSave}
                      disabled={passwordPolicyUpdating}
                      showIcon
                      buttonType="save"
                    />
                  </ButtonDiv>
                </ButtonWrapper>
              )}
            </AccountProfileContainer>
          </Form>
        )}
      </>

      <SnackBar
        isSuccess={passwordPolicyUpdateSuccess}
        isFailed={passwordPolicyUpdateFailed}
        label={handleMessage(passwordPolicyUpdateSuccess, passwordPolicyUpdateFailed)}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state?.user?.loginDetails,
    passwordPolicy: state?.accounts?.passwordPolicy,
    passwordPolicyUpdating: state?.accounts?.passwordPolicyUpdating,
    passwordPolicyUpdateSuccess: state?.accounts?.passwordPolicyUpdateSuccess,
    passwordPolicyUpdateFailed: state?.accounts?.passwordPolicyUpdateFailed,
    isSuperAdmin: state?.user?.isSuperAdmin,
    policyUpdateErrorMessage: state?.accounts?.policyUpdateErrorMessage,
    dropdownAccountId: state?.dropdownFilters?.settings?.accountId,
    dropdownAccountList: state.dropdownFilters?.accountList,
    passwordPolicyLoading: state?.accounts?.passwordPolicyLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getPasswordPolicy: (dropdownAccountId) => dispatch(getPasswordPolicy(dropdownAccountId)),
  updatePasswordPolicy: (data) => dispatch(updatePasswordPolicy(data)),
  resetUpdatePasswordPolicyFlags: () => dispatch(resetUpdatePasswordPolicyFlags()),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPolicy);
