import styled from "styled-components";

export const SettingNotification = styled.div``;

export const SettingTableDescription = styled.div``;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 80% 18%;
  margin: ${({ isHeader }) => (isHeader ? "auto auto -12px auto" : "10px auto")};
  @media (max-width: 1100px) {
    grid-template-columns: 70% 30%;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "15px")};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "8px 0px" : "0px")};
  color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "18px" : "16px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  /*white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ even }) => (even ? "left" : "right")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  vertical-align: middle;
  label {
    margin: 0 !important;
  }
`;

export const RowsContainer = styled.div`
  max-height: calc(100vh - 557px);
  overflow: auto;
  margin-top: 10px;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    height: auto;
    max-height: initial;
  }
`;

export const SettingContent = styled.div`
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  @media (max-width: 768px) {
    font-size: 15px !important;
  }
`;

export const SpanHeadStyle = styled.div`
  font-size: 18px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
`;

export const SpanDescStyle = styled.div`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  @media (max-width: 768px) {
    font-size: 13px !important;
  }
`;

export const CameraPositionDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  overflow: hidden;
  display: block;
  margin-top: 15px;
  border-radius: 4px;
  padding: 5px;
`;

export const OnOffText = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin: 0rem 5px 0 0;
  font-family: "Open Sans", sans-serif;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
