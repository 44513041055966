import React, { useState, useEffect } from "react";

const Countdown = (props) => {
  const { targetTimeInMinutes, timerExpiredCallBack, startTimer, setStaartTimer } = props;
  const initialTime = targetTimeInMinutes * 60;
  const [secondsLeft, setSecondsLeft] = useState(initialTime);

  useEffect(() => {
    if (startTimer) {
      setSecondsLeft(initialTime);
      setStaartTimer(false);
    }
  }, [startTimer]);

  useEffect(() => {
    if (secondsLeft > 0) {
      const interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      timerExpiredCallBack();
    }
  }, [secondsLeft]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    return `${minutes} minutes ${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return <span>{formatTime(secondsLeft)}</span>;
};

export default Countdown;
