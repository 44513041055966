const allSteps = {
  dashboard: {
    title: "Dashboard",
    element: ".dashboard",
    intro: "Click here to visit the home screen. You can view the top highlights of your account.",
    position: "right",
    tooltipClass: "introTooltipClass",
    highlightClass: "introHighlightClass",
  },
  analyticsPage: {
    title: "Analytics",
    element: ".analyticsPage",
    intro: "View your account's data converted into insightful visual charts.",
    position: "right",
    tooltipClass: "introTooltipClass",
    highlightClass: "introHighlightClass",
  },
  reports: {
    title: "Reports",
    element: ".reports",
    intro: "View all the inspection, impact, near-miss and unauthorized access reports here.",
    position: "right",
    tooltipClass: "introTooltipClass",
    highlightClass: "introHighlightClass",
  },
  inspectAssets: {
    title: "Inspect Asset",
    element: ".inspectAsset",
    intro: "You can choose to perform an inspection from the dashboard itself.",
    position: "right",
  },
  management: {
    title: "Management",
    element: ".introManagement",
    intro: "Add and manage your worksites, asset types, assets and users from this section.",
    position: "right",
  },

  checklistSettings: {
    title: "Checklist Settings",
    element: ".introChecklistSettings",
    intro: "Create new checklists from scratch or from standard templates or edit your existing checklists.",
    position: "right",
  },
  introAssetDiagnostics: {
    title: "Asset Diagnostics",
    element: ".introAssetDiagnostics",
    intro: "Here you can find logs and other detailed trails of your asset's operation and activity.",
    position: "right",
    tooltipClass: "introTooltipClass",
    highlightClass: "introHighlightClass",
  },
  settings: {
    title: "Settings",
    element: ".introSettings",
    intro: "Manage your SIERA.AI account, device, and security settings.",
    position: "right",
  },
  helpCenter: {
    title: "Help Center",
    element: ".introHelpCenter",
    intro: "Click here to get help and information for specific issues.",
    position: "right",
  },
  logout: {
    title: "Logout",
    element: ".introLogout",
    intro: "Click here to logout and end your session.",
    position: "right",
  },
};

const introObjArray = {
  default: [
    "dashboard",
    "analyticsPage",
    "reports",
    "inspectAssets",
    "management",
    "checklistSettings",
    "introAssetDiagnostics",
    "settings",
    "helpCenter",
    "logout",
  ],
  superAdmin: [
    "dashboard",
    "analyticsPage",
    "reports",
    "management",
    "checklistSettings",
    "introAssetDiagnostics",
    "settings",
    "helpCenter",
    "logout",
  ],
  accountViewer: ["dashboard", "reports", "settings", "helpCenter", "logout"],
};

export const getIntroSteps = ({ isSuperAdmin, isAccountViewer }) => {
  if (isSuperAdmin) {
    const introArr = [];
    introObjArray.superAdmin.forEach((element) => {
      introArr.push(allSteps[element]);
    });
    return introArr;
  } else if (isAccountViewer) {
    const introArr = [];
    introObjArray.accountViewer.forEach((element) => {
      introArr.push(allSteps[element]);
    });
    return introArr;
  } else {
    const introArr = [];
    introObjArray.default.forEach((element) => {
      introArr.push(allSteps[element]);
    });
    return introArr;
  }
};
