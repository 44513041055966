import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import "../CameraSetting.css";
import NoDataComponent from "../../../../../components/noData/NoDataComponent";
import { noDataObj } from "../constants";
import {
  CameraLocationWrapper,
  AllCameraPanel,
  CameraDropdownWrapper,
  SerialNumber,
  CamSetupInfoMessage,
  PositionLabel,
  StyledInput,
  CamSetupGif,
} from "./CameraLocation.styles";
import VehicleWithCamMapping from "./VehicleWithCamMapping";
import CamGif from "../../../../../../src/assets/gif/Camera_Setup.gif";

function CameraLocation(props) {
  const {
    cameraPosition,
    noS3SettingData,
    noS3SettingDataAction,
    s3SettingsUntouchedObject,
    cameraPositionArray,
    doesCamsNeedSetup,
  } = props;
  const [assignedPosition, setAssignedPosition] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let postions = [];
    for (var key in cameraPosition) {
      postions.push(cameraPosition[key]);
    }
    setAssignedPosition([...postions]);
  }, [cameraPosition]);

  const cameraSetupData =
    s3SettingsUntouchedObject && s3SettingsUntouchedObject.length > 0 ? s3SettingsUntouchedObject : [];

  const CamSetupHelp = () => {
    return (
      <CamSetupGif>
        <img src={CamGif} alt="Camera setup intro" />
      </CamSetupGif>
    );
  };

  return (
    <>
      {noS3SettingData && !doesCamsNeedSetup ? (
        <NoDataComponent
          noDataObj={noDataObj}
          noS3SettingDataAction={noS3SettingDataAction}
          actionButtonTransKey={"S3 No Data Action Button Text"}
          onLoadShowModal={true}
        />
      ) : (
        <>
          <CameraLocationWrapper>
            <AllCameraPanel>
              {cameraSetupData.map((data, i) => (
                <CameraDropdownWrapper>
                  <PositionLabel>{t(`Camera ${i + 1} Position`)}</PositionLabel>
                  <StyledInput type="text" value={s3SettingsUntouchedObject?.[i]?.position} disabled />
                  {!doesCamsNeedSetup && <SerialNumber>S/N: {data.cameraId}</SerialNumber>}
                </CameraDropdownWrapper>
              ))}

              {s3SettingsUntouchedObject?.[0]?.position !== "rear" &&
                s3SettingsUntouchedObject?.[0]?.position !== "Rear" &&
                !doesCamsNeedSetup && (
                  <CamSetupInfoMessage isWarning={true}>
                    <h2>
                      <Trans>Camera Mismatch Information warning</Trans>
                    </h2>
                    <h3>
                      <strong>
                        <Trans>Need help</Trans>?
                      </strong>
                      <Trans>Please contact us at</Trans>
                      <a href="mailto:support@siera.ai">
                        <trans>support@siera.ai</trans>
                      </a>
                      <trans> or call us at </trans>
                      <a href="tel:+15128170702">
                        <trans> +1 (512) 817 0702</trans>
                      </a>
                    </h3>
                  </CamSetupInfoMessage>
                )}

              {doesCamsNeedSetup && (
                <CamSetupInfoMessage>
                  <h2>
                    <Trans>Please setup the cameras using the touch screen interface of this device</Trans>.
                  </h2>
                  <h3>
                    <strong>
                      <Trans>Need help</Trans>?
                    </strong>
                    <Trans>Please contact us at </Trans>
                    <a href="mailto:support@siera.ai">
                      <Trans>support@siera.ai</Trans>
                    </a>
                    <Trans> or call us at </Trans>
                    <a href="tel:+15128170702">
                      <Trans> +1 (512) 817 0702</Trans>
                    </a>
                  </h3>
                </CamSetupInfoMessage>
              )}
            </AllCameraPanel>
            {doesCamsNeedSetup ? (
              <CamSetupHelp />
            ) : (
              <VehicleWithCamMapping
                assignedPosition={assignedPosition}
                cameraPositionArray={cameraPositionArray}
                doesCamsNeedSetup={doesCamsNeedSetup}
              />
            )}
          </CameraLocationWrapper>
        </>
      )}
    </>
  );
}

export default CameraLocation;
