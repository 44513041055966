export const userTypes = {
  //For Users List
  GET_ALL_USERS_LOADING: "GET_ALL_USERS_LOADING",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_ERROR: "GET_ALL_USERS_ERROR",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",

  // For Getting Single User By id
  GET_USER_DETAIL_LOADING: "GET_USER_DETAIL_LOADING",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_ERROR: "GET_USER_DETAIL_ERROR",

  //For adding new user
  POST_NEW_USER_LOADING: "POST_NEW_USER_LOADING",
  POST_NEW_USER_SUCCESS: "POST_NEW_USER_SUCCESS",
  POST_NEW_USER_ERROR: "POST_NEW_USER_ERROR",
  RESET_ADD_USER_STATE: "RESET_ADD_USER_STATE",
  RESET_USERS_SUCCESS_FLAG: "RESET_USERS_SUCCESS_FLAG",
  UPDATE_USER_DETAIL: "UPDATE_USER_DETAIL",

  //For updating user status
  USER_STATUS_UPDATE_LOADING: "USER_STATUS_UPDATE_LOADING",
  USER_STATUS_UPDATE_SUCCESS: "USER_STATUS_UPDATE_SUCCESS",
  USER_STATUS_UPDATE_ERROR: "USER_STATUS_UPDATE_ERROR",
  USER_STATUS_UPDATE_FAILED: "USER_STATUS_UPDATE_FAILED",

  //For updating worksite details
  WORKSITE_DETAILS_UPDATE_LOADING: "WORKSITE_DETAILS_UPDATE_LOADING",
  WORKSITE_DETAILS_UPDATE_SUCCESS: "WORKSITE_DETAILS_UPDATE_SUCCESS",
  WORKSITE_DETAILS_UPDATE_ERROR: "WORKSITE_DETAILS_UPDATE_ERROR",

  //For updating worksite name
  WORKSITE_NAME_UPDATE_LOADING: "WORKSITE_NAME_UPDATE_LOADING",
  WORKSITE_NAME_UPDATE_SUCCESS: "WORKSITE_NAME_UPDATE_SUCCESS",
  WORKSITE_NAME_UPDATE_ERROR: "WORKSITE_NAME_UPDATE_ERROR",

  //For selected user
  SELECTED_USER: "SELECTED_USER",

  //For Resetting Users state
  RESET_USERS_STATE: "RESET_USERS_STATE",

  //For user bulk upload
  USER_BULK_UPLOAD_TEMPLATE_LOADING: "USER_BULK_UPLOAD_TEMPLATE_LOADING",
  USER_BULK_UPLOAD_TEMPLATE_SUCCESS: "USER_BULK_UPLOAD_TEMPLATE_SUCCESS",
  USER_BULK_UPLOAD_TEMPLATE_ERROR: "USER_BULK_UPLOAD_TEMPLATE_ERROR",

  //TODO-Move these out of this action when time permits
  GET_USERS_BY_SITES_SUCCESS: "GET_USERS_BY_SITES_SUCCESS",
  GET_USERS_BY_SITES_ERROR: "GET_USERS_BY_SITES_ERROR",
  GET_USERS_BY_SITES_LOADING: "GET_USERS_BY_SITES_LOADING",

  GET_ASSET_TYPES_BY_SITES_SUCCESS: "GET_ASSET_TYPES_BY_SITES_SUCCESS",
  GET_ASSET_TYPES_BY_SITES_ERROR: "GET_ASSET_TYPES_BY_SITES_ERROR",
  GET_ASSET_TYPES_BY_SITES_LOADING: "GET_ASSET_TYPES_BY_SITES_LOADING",

  GET_ASSETS_BY_ASSET_TYPES_SUCCESS: "GET_ASSETS_BY_ASSET_TYPES_SUCCESS",
  GET_ASSETS_BY_ASSET_TYPES_ERROR: "GET_ASSETS_BY_ASSET_TYPES_ERROR",
  GET_ASSETS_BY_ASSET_TYPES_LOADING: "GET_ASSETS_BY_ASSET_TYPES_LOADING",

  GET_EXPORT_DATA_LOADING: "GET_EXPORT_DATA_LOADING",
  GET_EXPORT_DATA_SUCCESS: "GET_EXPORT_DATA_SUCCESS",
  GET_EXPORT_DATA_ERROR: "GET_EXPORT_DATA_ERROR",

  GET_ALL_WORKSITES_USERS_SUCCESS: "GET_ALL_WORKSITES_USERS_SUCCESS",
  GET_ALL_WORKSITES_USERS_ERROR: "GET_ALL_WORKSITES_USERS_ERROR",
  GET_ALL_WORKSITES_USERS_LOADING: "GET_ALL_WORKSITES_USERS_LOADING",
};
