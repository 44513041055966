import { useEffect } from "react";
import OTPInput from "otp-input-react";
import { Fragment, useState } from "react";
import MultiplePhotoUpload from "../Input/FileUpload.component";
import { apiCall } from "../../../../../utils/apiCall";

export default function MeterReading({
  data,
  currentIndex,
  setChecklistResponseForm,
  handleSubmitAnswer,
  showSelection,
  uploadImage,
  deleteImage,
  deleteImageLoading,
  uploadImageLoading,
  assetId,
}) {
  const [meterReading, setMeterReading] = useState("");
  const [currentResponse, setCurrentResponse] = useState({});
  const [meterReadingValidation, setMeterReadingValidation] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCurrentResponse(data[currentIndex]);
    setMeterReading(data[currentIndex]?.response);
    setShowError(false);
  }, [currentIndex]);

  const isMeterReadingValid = async (assetId, meterReading) => {
    return await apiCall(`/checklist/validateMeterReading/${assetId}/${meterReading}`);
  };

  const onClickSubmit = () => {
    setShowError(true);
    let isValid = false;
    if (!meterReading) {
      setMeterReadingValidation("Meter reading is required");
      return;
    }
    if (!/^\d+$/.test(meterReading)) {
      setMeterReadingValidation("Please enter valid number");
      return;
    }
    let response = isMeterReadingValid(assetId, meterReading);
    response.then((res) => {
      console.log(res.data, "----");
      isValid = res.data.success;
      if (!isValid) {
        setMeterReadingValidation("Meter reading should be greater than previous one");
        return;
      }
      data[currentIndex] = {
        ...currentResponse,
        response: meterReading,
      };
      handleSubmitAnswer();
    });
  };

  useEffect(() => {
    if (!meterReading) {
      setMeterReadingValidation("Meter reading is required");
      return;
    }
    if (!/^\d+$/.test(meterReading)) {
      setMeterReadingValidation("Please enter valid number");
      return;
    }
    setMeterReadingValidation("");
  }, [meterReading]);

  const onClickNA = () => {
    data[currentIndex] = {
      ...currentResponse,
      response: "NA",
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  return (
    <>
      <div className="justify-center flex-wrap w-full  block md:block lg:flex ">
        <div className="flex-1 grid  border-[#F2F2F2] border">
          <div className="heightmax">
            <p className="w-full font-sans p-1  text-xl rounded text-black-text flex justify-left">
              <span className="text-md md:text-xl lg:text-xl "> {currentResponse?.question}</span>
            </p>
          </div>
        </div>

        <div className="flex-1 items-center justify-center  pt-2 relative">
          <div className="justify-center my-5 justify-center grid">
            <OTPInput
              className="otp-input  text-xrlem  flex-wrap font-kanit font-thin"
              inputClassName="border border-gray-700"
              value={meterReading ? (meterReading == "NA" ? "" : meterReading) : ""}
              onChange={setMeterReading}
              autoFocus
              OTPLength={5}
              otpType="number"
              disabled={false}
            />
            {showError && meterReadingValidation && (
              <div className="text-color-red  justify-center font-sans text-md block text-left">
                {meterReadingValidation}
              </div>
            )}
          </div>

          <div className="flex justify-centr flex-wrap mt-2">
            <div className="w-full flex-1  justify-center">
              <button
                type="button"
                onClick={onClickSubmit}
                className={`${
                  showSelection && meterReading === currentResponse?.response && meterReading !== "NA"
                    ? "button-green-selected"
                    : "button-green"
                } font-kanit text-xrlem border-none`}
              >
                SUBMIT
              </button>
            </div>
            <div className="w-full flex-1  justify-center self-center">
              <button
                type="button"
                onClick={onClickNA}
                className={`${
                  showSelection && meterReading === currentResponse?.response && meterReading === "NA"
                    ? "button-gray-selected"
                    : "button-gray"
                } font-kanit text-xrlem border-none`}
              >
                NA
              </button>
            </div>
          </div>
          <div className="flex-1">
            <MultiplePhotoUpload
              deleteImageLoading={deleteImageLoading}
              uploadImageLoading={uploadImageLoading}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              currentResponse={currentResponse}
              setCurrentResponse={setCurrentResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
}
