export const userTypeManagement = {
  //For User Type List
  GET_USER_TYPE_LIST_LOADING: "GET_USER_TYPE_LIST_LOADING",
  GET_USER_TYPE_LIST_SUCCESS: "GET_USER_TYPE_LIST_SUCCESS",
  GET_USER_TYPE_LIST_ERROR: "GET_USER_TYPE_LIST_ERROR",

  GET_DROPDOWN_USERTYPE_LIST: "GET_DROPDOWN_USERTYPE_LIST",

  // For User type detail
  GET_USER_TYPE_DETAIL_LOADING: "GET_USER_TYPE_DETAIL_LOADING",
  GET_USER_TYPE_DETAIL_SUCCESS: "GET_USER_TYPE_DETAIL_SUCCESS",
  GET_USER_TYPE_DETAIL_ERROR: "GET_USER_TYPE_DETAIL_ERROR",

  // Add User Type
  ADD_USER_TYPE_LOADING: "ADD_USER_TYPE_LOADING",
  ADD_USER_TYPE_SUCCESS: "ADD_USER_TYPE_SUCCESS",
  ADD_USER_TYPE_ERROR: "ADD_USER_TYPE_ERROR",

  // Update user type
  UPDATE_USER_TYPE_LOADING: "UPDATE_USER_TYPE_LOADING",
  UPDATE_USER_TYPE_SUCCESS: "UPDATE_USER_TYPE_SUCCESS",
  UPDATE_USER_TYPE_ERROR: "UPDATE_USER_TYPE_ERROR",
  TOGGLE_USER_TYPE_STATUS_SUCCESS: "TOGGLE_USER_TYPE_STATUS_SUCCESS",
  TOGGLE_USER_TYPE_STATUS_ERROR: "TOGGLE_USER_TYPE_STATUS_ERROR",
  RESET_USER_TYPE_FLAGS: "RESET_USER_TYPE_FLAGS",

  GET_SUPER_ADMIN_USER_TYPES_SUCCESS: "GET_SUPER_ADMIN_USER_TYPES_SUCCESS",
  GET_SUPER_ADMIN_USER_TYPES_ERROR: "GET_SUPER_ADMIN_USER_TYPES_ERROR",

  // Deactivated Users by User Type Deactivation
  PRE_DEACTIVATED_USER_TYPE_LOADING: "PRE_DEACTIVATED_USER_TYPE_LOADING",
  PRE_DEACTIVATED_USER_TYPE_SUCCESS: "PRE_DEACTIVATED_USER_TYPE_SUCCESS",
  PRE_DEACTIVATED_USER_TYPE_ERROR: "PRE_DEACTIVATED_USER_TYPE_ERROR",

  // Deactivate the Users by confirm button in User Type Deactivation
  DEACTIVATE_USER_TYPE_LOADING: "DEACTIVATE_USER_TYPE_LOADING",
  DEACTIVATE_USER_TYPE_SUCCESS: "DEACTIVATE_USER_TYPE_SUCCESS",
  DEACTIVATE_USER_TYPE_ERROR: "DEACTIVATE_USER_TYPE_ERROR",
};
