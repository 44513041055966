import { useState, useEffect } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "../../../components/Button/Button.component";
import {
  setSelectedAccountId,
  setSelectedWorksiteId,
  setSelectedSieraProductId,
  setSelectedAssetTypeId,
  setSearchText,
  getDeviceInformation,
} from "../../../redux/deviceInformation/deviceInfo.action";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  setDropdownProductType,
  getDropdownAccountList,
  getDropdownWorksiteList,
} from "../../../redux/dropdownFilters/action";

import SearchBox from "../../../components/SearchBox/Searchbox.component";
import { SearchBoxDiv, SearchRefreshWrapper } from "./Filters.component.styles";
import { RefreshButtonDiv, RefreshButton } from "./DeviceInformation.component.styles";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";
import DropdownWrapper from "../../../components/DropdownComponent/DropdownWrapper";

const Filters = (props) => {
  const {
    isSuperAdmin,
    setSelectedAccountId,
    setSelectedWorksiteId,
    setSelectedSieraProductId,
    setSelectedAssetTypeId,

    selectedAccountId,
    selectedWorksiteId,
    selectedSieraProductId,
    selectedAssetTypeId,
    dropdownAccountList,
    dropdownAccountListLoading,
    dropdownFilterAccountId,
    dropdownWorksiteList,
    dropdownFilterProductList,
    dropdownAssetTypeList,
    getDropdownAccountList,
    getDropdownWorksiteList,
    setDropdownAccountId,
    setSearchText,
    searchText,
    getDeviceInformation,
    showWorksiteDropdown,
    loginDetails,
    showMoreFilter,
    setShowMoreFilter,
  } = props;

  //const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [searchTextLocal, setSearchTextLocal] = useState(null);
  const { t } = useTranslation();

  const accountId = isSuperAdmin ? selectedAccountId : loginDetails?.accountId;

  useEffect(() => {
    if (!dropdownAccountListLoading && !dropdownAccountList?.length) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    getDropdownWorksiteList();
  }, [dropdownFilterAccountId]);

  const searchChangeHandler = (e) => {
    if (e !== "") {
      setSearchText(e.target.value);
      getDeviceInformation(e.target.value);
      setSearchTextLocal(e.target.value);
    } else {
      setSearchText("");
      getDeviceInformation("");
      setSearchTextLocal("");
    }
  };

  const refreshData = () => {
    if (accountId) {
      getDeviceInformation(searchTextLocal);
    }
  };

  useEffect(() => {
    if (!showWorksiteDropdown) {
      setShowMoreFilter(true);
    }
  }, [showWorksiteDropdown]);
  return (
    <Row className="mt-2 mb-2">
      <Col lg={6}>
        <Row>
          {isSuperAdmin && (
            <Col lg={6}>
              <DropdownFilterWrapper
                options={dropdownAccountList?.map((data) => ({
                  _id: data._id,
                  label: data.companyName,
                }))}
                size="medium"
                label={t("Account")}
                isValidationError={false}
                value={accountId}
                onChange={(value) => {
                  setDropdownAccountId(value);
                  setSelectedAccountId(value);
                }}
                required={true}
              />
            </Col>
          )}
          {showWorksiteDropdown && accountId && (
            <Col lg={6}>
              <DropdownWrapper
                filter={{
                  type: "Worksite",
                  name: "worksiteName",
                  key: "name",
                  data: dropdownWorksiteList,
                }}
                size="medium"
                value={selectedWorksiteId}
                label={t("filter")}
                handleFilter={(value) => {
                  setSelectedWorksiteId(value);
                }}
              />
            </Col>
          )}
          {showMoreFilter && (
            <>
              <Col lg={6}>
                <DropdownWrapper
                  filter={{
                    type: "SIERA.AI Product",
                    name: "name",
                    key: "name",
                    data: dropdownFilterProductList,
                  }}
                  size="medium"
                  value={selectedSieraProductId}
                  label={t("filter")}
                  handleFilter={(value) => {
                    setSelectedSieraProductId(value);
                  }}
                />
              </Col>

              <Col lg={6}>
                <DropdownWrapper
                  filter={{
                    type: "Asset Type",
                    name: "_id",
                    key: "AssetTypeName",
                    data: dropdownAssetTypeList,
                  }}
                  size="medium"
                  value={selectedAssetTypeId}
                  label={t("filter")}
                  handleFilter={(value) => {
                    setSelectedAssetTypeId(value);
                  }}
                />
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Col lg={2}>
        {accountId && showWorksiteDropdown && (
          <div className="text-left" style={{ marginTop: "20px" }}>
            <Button
              iconClass="filter"
              title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
              label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
              onClick={() => setShowMoreFilter(!showMoreFilter)}
              showIcon
            />
          </div>
        )}
      </Col>
      <Col lg={4}>
        <SearchRefreshWrapper>
          <SearchBoxDiv>
            <SearchBox
              disabled={!accountId}
              placeholder={t("Search an Asset")}
              iconOnly={true}
              isIcon={true}
              width={"100%"}
              size="medium"
              onChange={searchChangeHandler}
              searchText={searchTextLocal || searchText}
              setSearchText={searchChangeHandler}
            />
          </SearchBoxDiv>
          <RefreshButtonDiv>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh</Tooltip>}>
              <RefreshButton className="icon icon-update" onClick={refreshData} isDisabled={!accountId} />
            </OverlayTrigger>
          </RefreshButtonDiv>
        </SearchRefreshWrapper>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    isSuperAdmin: state.user.isSuperAdmin,
    isSiteAdmin: state.user.isSiteAdmin,

    selectedAccountId: state.deviceInfo.selectedAccountId,
    selectedWorksiteId: state.deviceInfo.selectedWorksiteId,
    selectedSieraProductId: state.deviceInfo.selectedSieraProductId,
    selectedAssetTypeId: state.deviceInfo.selectedAssetTypeId,
    searchText: state.deviceInfo.searchText,

    dropdownAccountList: state.dropdownFilters.accountList,
    dropdownAccountListLoading: state.dropdownFilters.accountListLoading,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    dropdownFilterProductList: state.dropdownFilters.productList,
    dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
    showWorksiteDropdown: state.user.showWorksiteDropdown,
    loginDetails: state.user.loginDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedAccountId: (data) => dispatch(setSelectedAccountId(data)),
  setSelectedWorksiteId: (data) => dispatch(setSelectedWorksiteId(data)),
  setSelectedSieraProductId: (data) => dispatch(setSelectedSieraProductId(data)),
  setSelectedAssetTypeId: (data) => dispatch(setSelectedAssetTypeId(data)),
  setSearchText: (searchText) => dispatch(setSearchText(searchText)),
  getDeviceInformation: (searchText) => dispatch(getDeviceInformation(searchText)),

  setDropdownAccountId: (data) => dispatch(setDropdownAccountId(data)),
  setDropdownWorksiteId: (data) => dispatch(setDropdownWorksiteId(data)),
  setDropdownAssetTypeId: (data) => dispatch(setDropdownAssetTypeId(data)),
  setDropdownProductType: (data) => dispatch(setDropdownProductType(data)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
