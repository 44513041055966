export const allUserTypeAccessLevels = [
  { value: "ALL_ACCOUNTS", label: "All Accounts" },
  /* { value: "MULTIPLE_ACCOUNTS", label: "Multiple Accounts" }, */
  { value: "ALL_WORKSITES", label: "All Worksites" },
  { value: "MULTIPLE_WORKSITES", label: "Multiple Worksites" },
  { value: "SINGLE_WORKSITE", label: "Single Worksite" },
  { value: "INDIVIDUAL_EMPLOYEE", label: "Individual Employee" },
];

export const worksiteUserTypeAccessLevels = [
  { value: "ALL_WORKSITES", label: "All Worksites" },
  { value: "MULTIPLE_WORKSITES", label: "Multiple Worksites" },
  { value: "SINGLE_WORKSITE", label: "Single Worksite" },
  { value: "INDIVIDUAL_EMPLOYEE", label: "Individual Employee" },
];
