//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code

import React, { useEffect, useState } from "react";
import { Row, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";

import AssetManagement from "../../../Dashboard/Asset/AssetManagement.component";
import AddAssetStepper from "../../../Dashboard/Asset/AddAssetStepper.component";
import PlanAlert from "../../Asset/Modal/PlanAlertModal";
import { toGenerateCSV } from "../../../../redux/assets/action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { CSVLink } from "react-csv";
import { Trans, useTranslation } from "react-i18next";

import Button from "../../../../components/Button/Button.component";
import { setQrcodeFilterBy, getDropdownAssetTypeList } from "../../../../redux/dropdownFilters/action";
import { setAddAssetModal, setEditAlertModal, setModalWaitingToOpen } from "../../../../redux/modals/action";
import { setShowFilters } from "../../../../redux/mobileView/action";
import planConstant from "../../../../constant/planConstant.json";
import { PLAN_TO_ID } from "../../../../constant/billingPlans";
import AssetActionButtons from "../../Asset/AssetActionBar.component";
import AssetsCard from "../../Asset/AssetsCard.component";
// import SearchBox from "../../../../components/SearchBox.component";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import AssetFilters from "../../Asset/AssetFilters.component";
import AssetList from "../../Asset/AssetList.component";
import AssetOverview from "../../Asset/AssetOverview.component";
import AssetBulkUpload from "../../Asset/Modal/BulkUploadModal/AssetBulkUpload.component";
import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";
import ManagementPanelStyles from "./ManagementPanel.module.css";
import { ReactComponent as rightIcon } from "../../../../assets/rightIconwhite.svg";

import {
  AssetPanelStyled,
  FilterIconStyled,
  DesktopView,
  MobileView,
  ButtonsContainer,
  ActionBarWrapper,
  ActionBarButtons,
  SearchBoxWrapper,
  SearchFilter,
  FilterWrapper,
  FilterIconDiv,
  CloseIconDiv,
  FilterBackDiv,
  CloseBackDiv,
  FilterOnclickDes,
  FilterDesWrapper,
  AssetMobileListWrapper,
  AssetDetailHeading,
  RightIconImage,
} from "./assetPanel.component.styles";

const { inspectionPlan } = planConstant;

function AssetPanel(props) {
  const {
    history,
    toGenerateCSV,
    generateCSVData,
    loginDetails,
    inspectionPlanDetail,
    isSuperAdmin,
    setAddAssetModal,
    addAssetModal,
    setQrcodeFilterBy,
    updateActiveNavItem,
    setModalWaitingToOpen,
    setShowFilters,
    showFilters,
    assetName,
    editForm,
    editFormSecond,
    setEditAlertModal,
    getDropdownAssetTypeList,
    showActionBar,
  } = props;

  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("all");
  const [createdAsset, setCreatedAsset] = useState({});
  const [showExportData, setShowExportData] = useState(false);

  const { t } = useTranslation();

  //TODO-Optimize this code
  const isPlanValidToExport = () => {
    let noInspecDone = inspectionPlanDetail && inspectionPlanDetail.count;
    let noOfLimit =
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed;
    if (!noInspecDone) {
      noInspecDone = 0;
    }
    if (isSuperAdmin) {
      return true;
    }
    if (!noOfLimit) {
      return false;
    } else if (noOfLimit > noInspecDone) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getDropdownAssetTypeList();
    if (isPlanValidToExport) {
      toGenerateCSV();
    }
    updateActiveNavItem("assets");
  }, []);

  useEffect(() => {
    if (generateCSVData?.length > 0) {
      setShowExportData(true);
    } else {
      setShowExportData(false);
    }
    // updateActiveNavItem("users");
  }, [generateCSVData]);
  const headersData = [
    { label: t("Asset Name"), key: "assetName" },
    { label: t("Asset Type"), key: "assetType" },
    { label: t("Worksite"), key: "worksite" },
    { label: t("Worksite Manager"), key: "manager" },
    { label: t("Last Meter Reading"), key: "meterReading" },
    // { label: t("Last Inspection Result"), key: "status" },
    // { label: t("Last Inspected By"), key: "inspector" },
    // { label: t("Last Inspected On"), key: "date" },
    // { label: t("Last Impact On"), key: "lastImpactDate" },
    // { label: t("Last Impact Severity"), key: "lastimpactSeverity" },
    // { label: t("Last Impact By"), key: "lastImpactBy" },
    { label: t("Last Passed Inspection On"), key: "lastPassedInspectionAt" },
    { label: t("Last Failed Inspection On"), key: "lastFailedInspectionAt" },
    { label: t("Last Timedout Inspection On"), key: "lastTimedoutInspectionAt" },
    { label: t("Last Low Impact On"), key: "lastLowImpactAt" },
    { label: t("Last Medium Impact On"), key: "lastMediumImpactAt" },
    { label: t("Last High Impact On"), key: "lastHighImpactAt" },
  ];
  const handleDownloadCSV = (e) => {
    if (generateCSVData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  let basicPlan = loginDetails?.plan === PLAN_TO_ID.Basic;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AssetPanelStyled>
      <DesktopView>
        <AssetActionButtons
          editForm={editForm}
          editFormSecond={editFormSecond}
          setEditAlertModal={setEditAlertModal}
          setAddAssetModal={setAddAssetModal}
          setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
          isPlanValidToExport={isPlanValidToExport}
          generateCSVData={generateCSVData}
          headersData={headersData}
          showExportData={showExportData}
          handleDownloadCSV={handleDownloadCSV}
          setModalShow={setModalShow}
          setQrcodeFilterBy={setQrcodeFilterBy}
        />
        <AssetManagement />
      </DesktopView>

      <MobileView>
        <ActionBarWrapper>
          <ActionBarButtons>
            {!showActionBar ? (
              ""
            ) : (
              <>
                <>
                  <AssetActionButtons
                    editForm={editForm}
                    editFormSecond={editFormSecond}
                    setEditAlertModal={setEditAlertModal}
                    setAddAssetModal={setAddAssetModal}
                    setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
                    isPlanValidToExport={isPlanValidToExport}
                    generateCSVData={generateCSVData}
                    headersData={headersData}
                    showExportData={showExportData}
                    handleDownloadCSV={handleDownloadCSV}
                    setModalShow={setModalShow}
                    setQrcodeFilterBy={setQrcodeFilterBy}
                  />
                </>
              </>
            )}
          </ActionBarButtons>
        </ActionBarWrapper>
        {!showAssetDetail ? (
          <>
            <AssetsCard />
            <SearchFilter>
              <SearchBoxWrapper>
                <SearchBox
                  label={"Your Asset"}
                  placeholder={t("Search an asset")}
                  searchText={SearchText}
                  setSearchText={setSearchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </SearchBoxWrapper>

              <FilterWrapper onClick={handleToggle}>
                {isOpen ? (
                  <CloseBackDiv>
                    <CloseIconDiv className="icon icon-cancel" />
                  </CloseBackDiv>
                ) : (
                  <FilterBackDiv>
                    <FilterIconDiv className="icon icon-filter" />
                  </FilterBackDiv>
                )}
              </FilterWrapper>
            </SearchFilter>

            <FilterOnclickDes>
              {isOpen && (
                <FilterDesWrapper>
                  <AssetFilters />
                </FilterDesWrapper>
              )}
            </FilterOnclickDes>

            <AssetMobileListWrapper>
              <AssetList
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                searchText={SearchText}
                filterAccountId={filterAccountId}
                setShowAssetDetail={() => setShowAssetDetail(true)}
              />
            </AssetMobileListWrapper>
          </>
        ) : (
          <>
            <AssetDetailHeading>
              <span onClick={() => setShowAssetDetail(false)}>Your Asset</span>{" "}
              <RightIconImage className="icon icon-arrow-right"></RightIconImage>
              {` ${assetName || ""}`}
            </AssetDetailHeading>
            <AssetOverview />
          </>
        )}
      </MobileView>

      <AssetCentralModal
        show={addAssetModal}
        onHide={() => {
          setAddAssetModal(false);
          setModalWaitingToOpen(false);
        }}
        toogleModalClose={() => {
          setAddAssetModal(false);
          setModalWaitingToOpen(false);
        }}
        setIsSuccess={setIsSuccess}
        setIsError={setIsError}
        setCreatedAsset={setCreatedAsset}
      />
      {/* <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      /> */}
      {worksiteBulkUploadShow && (
        <AssetBulkUpload
          show={worksiteBulkUploadShow}
          onHide={() => setWorksiteBulkUploadShow(false)}
          toogleModalClose={() => setWorksiteBulkUploadShow(false)}
        />
      )}
      <PlanAlert history={history} modalShow={modalShow} setModalShow={setModalShow} />

      <CommonSuccessMessage />
    </AssetPanelStyled>
  );
}

function WoksiteBulkUploadModal(props) {
  return (
    <Modal
      backdrop="static"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ padding: "200px", height: "500px" }}
    >
      <Modal.Body>
        <Row className="pl-4 pr-4 justify-content-center">
          <h4 className="text-style">
            <Trans>This feature will be here soon, kindly contact</Trans>{" "}
            <a href="mailto:support@siera.ai">support@siera.ai</a> <Trans>for more information</Trans>.
          </h4>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export function AssetCentralModal(props) {
  const { t } = useTranslation();
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={t("Add Asset")}
      handleClose={props.toogleModalClose}
    >
      <AddAssetStepper
        {...props}
        toogleModalClose={props.toogleModalClose}
        setIsSuccess={props.setIsSuccess}
        setIsError={props.setIsError}
      />
    </ModalPopup>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  generateCSVData: state.assets.GeneratingCSVData,
  inspectionPlanDetail: state.config.inspectionPlan,
  isSuperAdmin: state.user.isSuperAdmin,
  addAssetModal: state.modals.addAssetModal,
  showFilters: state.mobileView.showFilters,
  assetName: state?.assets?.AssetOverview?.assetName,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  toGenerateCSV: () => dispatch(toGenerateCSV()),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setQrcodeFilterBy: (data) => dispatch(setQrcodeFilterBy(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetPanel));
