import styled from "styled-components";

export const AdjustHourModal = styled.div`
  width: 95%;
  margin: auto;
  text-align: left;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
  float: left;
  width: 100%;
  text-align: left;
`;

export const ContainerRow = styled.div`
  padding: 25px;
  margin: auto;
  overflow: hidden;
`;

export const TextStyle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: 500 !important;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
`;

export const ButtonStyled = styled.div``;
export const ConfirmStyledDiv = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: 500 !important;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
`;
export const ModalStyledDiv = styled.div`
  margin: 50px auto;
`;
