import React from "react";

// Custom Styles
import Styles from "./AddUserModalStyles.module.css";

// Redux
import { connect } from "react-redux";

// For multiple language support
import { Trans, useTranslation } from "react-i18next";

function UserDetailForm() {
  return (
    <>
      <div className={Styles.useroverviewContainer}>
        <div className={Styles.useroverviewHeading}>
          <h5>
            <Trans>User Details</Trans>
          </h5>
        </div>
        <div className={Styles.useroverviewFilters}>
          <h1>User deatils will go here</h1>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailForm);
