import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DropdownFilter from "../../../../../../components/DropdownFilter.component";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../../../redux/dropdownFilters/action";
import { ChartDivs, FilterDrop, StyledCol } from "./ChartsFilter.component.styles";
import DurationFilterComponent from "./daurationFilter/DurationFilter.component";
import Button from "../../../../../../components/Button/Button.component";
import { setDuration } from "../../../../../../redux/dropdownFilters/dropdownActions";

function ChartsFilters({
  setDropdownAccountId,
  setDropdownWorksiteId,
  dropdownWorksiteList,
  dropdownAccountList,
  showWorksiteDropdown,
  isSuperAdmin,
  setDuration,
}) {
  const { t } = useTranslation();
  const [accountId, setAccountId] = useState("");
  const [worksiteId, setWorksiteId] = useState("");
  const [timeDuration, setTimeDuration] = useState("");

  useEffect(() => {
    applyFilter();
  }, []);

  const applyFilter = () => {
    setDropdownAccountId(accountId);
    setDropdownWorksiteId(worksiteId);
    setDuration(timeDuration);
  };

  return (
    <ChartDivs>
      {isSuperAdmin && (
        <FilterDrop>
          <DropdownFilter
            filter={{
              type: "Account",
              key: "companyName",
              data: dropdownAccountList,
            }}
            value={accountId}
            label={t("filter")}
            handleFilter={(value) => {
              setAccountId(value);
            }}
          />
        </FilterDrop>
      )}

      {showWorksiteDropdown && (
        <FilterDrop>
          <DropdownFilter
            filter={{
              type: "Worksite",
              key: "name",
              data: dropdownWorksiteList,
            }}
            value={worksiteId}
            handleFilter={(value) => {
              setWorksiteId(value);
            }}
          />
        </FilterDrop>
      )}
      <DurationFilterComponent timeDuration={timeDuration} setTimeDuration={setTimeDuration} />

      <StyledCol>
        <span style={{ width: "15px", display: "inline-block" }}>&nbsp;</span>
        <Button
          label={t("Apply Filter")}
          size="medium"
          onClick={applyFilter}
          showIcon={false}
          buttonType="primary"
        />
      </StyledCol>
    </ChartDivs>
  );
}

const mapStateToProps = (state) => ({
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDuration: (duration) => dispatch(setDuration(duration)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsFilters));
