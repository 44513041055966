export const sectionsList = [
  "Reports",
  "Account Management",
  "Worksite Management",
  "Asset Type Management",
  "Asset Management",
  "User Type Management",
  "User Management",
  "Checklist Settings",
  "System Settings",
  "Device Information",
  "Error Logs",
  "Inspect Asset",
];

export const tabletPermissions = ["view"];

export const sectionPermissions = ["view", "edit", "add", "deactivate"];

export const dashboardSectionHeader = [
  { name: "Dashboard Sections", textleft: "left" },
  { name: "View", textleft: "center" },
  { name: "Edit", textleft: "center" },
  { name: "Add", textleft: "center" },
  { name: "Deactivate", textleft: "center" },
];

export const tabletSectionHeader = [
  { name: "Tablet Sections", textleft: "left" },
  { name: "Access", textleft: "center" },
  { name: "", textleft: "center" },
  { name: "", textleft: "center" },
  { name: "", textleft: "center" },
];

export const tabletSectionData = [];
