/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { login } from "../../../../redux/user/user.action";
import SettingsSection from "../../../../components/SettingsSection";
import {
  getNearMissAlertSettingsByAssetId,
  updateNearMissAlertSettingsByAssetId,
  resetNearMissAlertSettingsByAssetId,
  resetNotificationSettingsFlags,
} from "../../../../redux/notification/action";
import { isEqual } from "../../../../utils/validations/utilities";

import { SettingWrapper } from "./NotificationSetting.component.styles";
import { setDropdownAccountId } from "../../../../redux/dropdownFilters/action";
import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter, LoaderOverlay } from "../Settings.Styles";
import NoSettingsDataFound from "../common/NoAssetFound.component";
import { bulkSettingsUpdate } from "../../../../redux/config/action";
import { getSelectedOptions, handleRecipientsChangeUtil } from "./common";
import SnackBar from "../../SnackBar";
import Recipients from "./emailerRecipients/Recipients";
import validationConstant from "../../../../constant/messages/validation.json";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

const NotificationSettings = ({
  loginDetails,
  notificationSetting,
  isAccountAdmin,
  isAccountOwner,
  isSiteAdmin,
  dropdownAccountId,
  getNearMissAlertSettingsByAssetId,
  updateNearMissAlertSettingsByAssetId,
  resetNearMissAlertSettingsByAssetId,
  dropdownAssetId,
  bulkSettingsUpdate,
  loading,

  resetNotificationSettingsFlags,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
  nearMissAlertSettingsSuccess,
}) => {
  const [nearMissAlertSettings, setNearMissAlertSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownWorksiteId, setDropdownWorksiteId] = useState(null);
  const [worksiteId, setWorksiteId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getNearMissAlertSettingsByAssetId(selectedAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    if (selectedAssetId) {
      getNearMissAlertSettingsByAssetId(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    const acId =
      isAccountAdmin || isAccountOwner || isSiteAdmin
        ? loginDetails && loginDetails.accountId
        : dropdownAccountId;
    setCurrentAccountId(acId);
  }, [dropdownAccountId, loginDetails, isAccountAdmin, isAccountOwner, isSiteAdmin]);

  const { t } = useTranslation();

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setDropdownAccountId(loginDetails?.accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  const setDefaultNotificationSettingsWorksite = () => {
    setClearSelection(true);
    setNearMissAlertSettings(notificationSetting?.nearMissAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.nearMissAlertSettings,
    nearMissAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettingsWorksite();
    }
  }, [notificationSetting.nearMissAlertSettings]);

  const handleNearMissAlertChange = (field, value) => {
    setNearMissAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const displaySettings = {
    pedestrianProximity: {
      title: "pedestrianProximity",
      description: "pedestrianProximityDescription",
      tableHeader: ["Near-Miss to", "Alert"],
      onChange: handleNearMissAlertChange,
      tableData: [
        {
          label: "Pedestrian",
          value: !!nearMissAlertSettings?.pedestrian,
          field: "pedestrian",
        },
        {
          label: "Object",
          value: !!nearMissAlertSettings?.object,
          field: "object",
        },
      ],
    },
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    setDropdownWorksiteId(worksiteId);
  };

  const onUpdate = () => {
    if (Object.keys(applyToAllObj).length !== 0) {
      const selectedOptions = getSelectedOptions(applyToAllObj);

      const updatableSettings = {
        ...selectedOptions,
        settings: { notificationSettings: { nearMissAlertSettings } },
      };
      bulkSettingsUpdate(updatableSettings);
    }
    updateNearMissAlertSettingsByAssetId(
      selectedAssetId,
      {
        notificationSettings: {
          nearMissAlertSettings,
        },
      },
      setIsUpdateLoading
    );
  };

  const onReset = () => {
    resetNearMissAlertSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };
  const handleCloseSnackBar = () => {
    resetNotificationSettingsFlags();
    setIsUpdateLoading(false);
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(userType, isChecked, nearMissAlertSettings, setNearMissAlertSettings);
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...nearMissAlertSettings,
      customEmails: [...email],
    };
    setNearMissAlertSettings(temp);
  };

  const isLoaderVisible = loading || notificationUpdateLoading;

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("An alert will be sent when the selected near-miss result occurs on an asset")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />

      {dropdownAssetId && typeof dropdownAssetId === "boolean" && dropdownAssetId === true ? (
        <NoSettingsDataFound />
      ) : (
        <>
          <SettingsContainer>
            {isLoaderVisible ? (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            ) : (
              <SettingWrapper>
                <SettingsSection
                  {...displaySettings.pedestrianProximity}
                  isLoading={notificationUpdateLoading || loading}
                />
                {selectedAssetId && (
                  <Recipients
                    customEmails={nearMissAlertSettings?.customEmails}
                    handleCustomEmailsChange={handleCustomEmailsChange}
                    recipients={nearMissAlertSettings?.userTypes}
                    handleRecipientsChange={handleRecipientsChange}
                  />
                )}
              </SettingWrapper>
            )}
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              setDropdownWorksiteId={setDropdownWorksiteId}
              setWorksiteId={setWorksiteId}
              showAppyToAll={true}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleUpdate={onUpdate}
              handleCancel={setDefaultNotificationSettingsWorksite}
              isLoading={isLoading}
              worksiteId={worksiteId}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleReset={onReset}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
        </>
      )}
      <SnackBar
        isSuccess={notificationBulkUpdateSuccess || isUpdateLoading || nearMissAlertSettingsSuccess}
        label={t(validationConstant.notification_settings_updated)}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,

    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters.accountId,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    loading: state.notificationSetting.loading,

    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
    nearMissAlertSettingsSuccess: state.notificationSetting?.nearMissAlertSettingsSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setLogin: (loginDetail) => dispatch(login(loginDetail)),
  getNearMissAlertSettingsByAssetId: (assetId) => dispatch(getNearMissAlertSettingsByAssetId(assetId)),
  updateNearMissAlertSettingsByAssetId: (assetId, data, setIsUpdateLoading) =>
    dispatch(updateNearMissAlertSettingsByAssetId(assetId, data, setIsUpdateLoading)),
  resetNearMissAlertSettingsByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetNearMissAlertSettingsByAssetId(assetId, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
