import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import TemplateList from "./ChecklistTemplatesList.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import ChecklistTemplatesOverview from "./ChecklistTemplatesOverview.complete";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { useTranslation } from "react-i18next";

// Button Component
import Button from "../../../../components/Button/Button.component";

import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";
import styled from "styled-components";

const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ChecklistList({ updateActiveNavItem, checklistTemplatesLoading }) {
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("checklistTemplates");
  }, []);

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (checklistTemplatesLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!checklistTemplatesLoading) {
      setShowLoader(false);
    }
  }, [checklistTemplatesLoading, hasShownOnce]);

  return (
    <div>
      <BreadCrumb />

      <Row style={{ paddingTop: "20px" }} className="m-0 p-0">
        <div className="mr-2 pl-0 mt-2">
          <NavLink to="/app/checklistmanagement">
            <Button iconClass="icon-angle-left" label={t("Your Checklists")} showIcon buttonType="primary" />
          </NavLink>
        </div>
      </Row>

      <Row className="worksiteMainbox mt-2">
        {showLoader && (
          <LoaderOverlay>
            <SieraLoader OnlyLoaderIcon size="small" />
          </LoaderOverlay>
        )}
        <Col lg={3}>
          <TemplateList />
        </Col>
        <Col lg={9}>
          <ChecklistTemplatesOverview />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistTemplatesLoading: state.checklist.checklistTemplatesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistList);
