import styled from "styled-components";

export const DepthContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  font-family: "Open Sans", sans-serif;
  right: 561px !important;

  @media (min-width: 1440px) and (max-width: 1600px) {
    transform: scale(0.9);
  }
  @media (min-width: 1620px) and (max-width: 2200px) {
    transform: scale(1);
    right: 660px !important;
    top: -40px;
  }
  .dot-line-1,
  .dot-line-2 {
    width: 750px;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 200px;
  left: 360px;
  height: 100px;
  width: 490px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .addFurther {
    color: #323232;
    font-size: 16px !important;
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 12px;
`;

export const Col = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
`;

export const InputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  width: 112px;
  float: left;
  padding: 1px;
  display: block;
  clear: both;
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid ${(props) => (props.disabled ? " #CCCCCC" : " #323232")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  label {
    color: ${(props) => (props.disabled ? "#CCCCCC" : "#676767")};
    font-size: 12px !important;
  }
  input {
    color: ${(props) => (props.disabled ? "#CCCCCC" : "#323232")};
    font-size: 16px !important;
    width: 90%;
    margin: auto;
    line-height: 8px;
    margin-top: -5px;
    font-weight: bold;
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

export const SpanContainer = styled.span`
  color: ${(props) => (props.disabled ? "#CCCCCC" : "#323232")};
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  &:focus {
    & ~ ${InputContainer} {
      background-color: #ff0000; /* Background color when Input is active */
    }
  }
`;

export const Label = styled.label`
  margin-bottom: 0;
`;

export const DepthImage = styled.img`
  position: absolute;
  top: 200px;
  left: 194px;
  height: 300px;
`;

export const StyledButton = styled.div`
  button {
    margin-right: 10px;
    font-family: "Open Sans", sans-serif !important;
    border-color: ${({ isSelected, colorCode }) => (isSelected ? colorCode : "transparent")};
    background-color: ${({ isSelected, colorCode }) => (isSelected ? colorCode : "transparent")};
    border-color: ${({ isSelected, colorCode }) => (isSelected ? colorCode : colorCode)};
    color: ${({ isSelected, colorCode }) => (isSelected ? "#FFFFFF" : "#323232")};
    line-height: 32px;
    font-size: 16px !important;
    font-weight: bold;
    :hover {
      background-color: ${({ isSelected, colorCode }) => (isSelected ? colorCode : colorCode)};
      background: ${({ isSelected, colorCode }) => (isSelected ? colorCode : colorCode)};
    }
  }
  button[title="Zone 3"],
  button[title="Zone 2"] {
    color: ${({ isSelected, colorCode }) => (isSelected ? "#323232" : "#323232")};
  }
`;
