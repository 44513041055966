import styled from "styled-components";
export const ManagementLeftSidebarMenu = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  tr.tableTr td.active-list-item {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  tr.tableTr td button.burronInspect {
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    :hover{
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
      border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  }
  tr.tableTr td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    border-top: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  table.tableListSec tr th.listNameBG {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  table thead{
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .listNameSec {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    font-weight: 500 !important;;
    line-height: 1.2;
  }

  .tableHeight::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  .tableHeight::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  .tableHeight::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  button.add-iconList {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-size: 20px;
  }
`;

export const CheckboxDiv = styled.div`
  width: 100%;
  margin: 0 0px 8px;
  overflow: hidden;
  font-family: "Open Sans";
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  display: flex;
`;

export const Tab = styled.div`
  width: 33.333%;
  display: inline-block;
  padding: 5px;
  text-align: center;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL6 || "#F2F2F2"} !important;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.grayL4 || "#9A9A9A"};

  cursor: pointer;
  font-size: 14px !important;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  :hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.themePrimary || "#0D5FBE"};
  }
  :nth-child(2) {
    border-right: ${({ isActive, theme }) =>
      isActive
        ? `1px solid ${theme.colors.themePrimary || "#0D5FBE"}`
        : `1px solid ${theme.colors.grayL5 || "#CCCCCC"}`};

    border-left: ${({ isActive, theme }) =>
      isActive
        ? `1px solid ${theme.colors.themePrimary || "#0D5FBE"}`
        : `1px solid ${theme.colors.grayL5 || "#CCCCCC"}`};
  }
`;
