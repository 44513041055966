import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  toValidateChecklistName,
  toValidateAccountDropdown,
} from "../../../../utils/validations/checkValidation";

import DropdownWrapper from "../../../../components/DropdownComponent/DropdownWrapper";
import DropdownWrapperA from "../../../../components/DropdownComponent/Dropdown.component";
import InputField from "../../../../components/InputFieldComponent/InputField.component";
// Button Component
import Button from "../../../../components/Button/Button.component";
// import { ThemeContext } from "styled-components";
// custom css
// import checklistModuleCss from "./ChecklistModalStyle.module.css";
// import styled from "styled-components";
// const ChecklistOverviewCenter = styled.div`
//   div.tab-header-active {
//     background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
//     color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
//   }
//   .form-control:hover,
//   .input-group input:hover {
//     border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
//   }

//   input.form-control,
//   textarea.form-control {
//     border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
//   }
//   .form-control {
//     background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
//     color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
//   }
//   input.form-control:focus,
//   textarea.form-control:focus {
//     border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
//   }
//   .form-control:disabled {
//     background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
//     color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
//   }
//   input.form-control::placeholder,
//   textarea.form-control::placeholder {
//     color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
//   }

//   .card {
//     background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
//     border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
//   }
// `;

// const { CheklistCardHeader } = checklistModuleCss;

import {
  ChecklistOverviewCenter,
  HeadingContainer,
  CheckListDiv,
} from "./ChecklistOverview.component.styles";

export default function ChecklistOverview({
  checklistData,
  setChecklist,
  setFormSection,
  setFilterAccountId,
  filterAccountId,
  isSuperAdmin,
  dropdownAccountList,
  handleNextClick,
  isValidated,
  editChecklist,
}) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState([{ value: "eng", label: "English (Default)", isFixed: true }]);
  const [nextClicked, setNextClicked] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const options = [
    // { value: 'chinmd', label: 'Chinese (Mandarin)Chinese (Mandarin)' },
    // { value: 'span', label: 'Spanish' },
    // { value: 'chin', label: 'Chinese (Cantonese)' },
    // { value: 'afk', label: 'Afrikaans' },
    // { value: 'viet', label: 'Vietnamese' },
  ];

  const onChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = options.filter((v) => v.isFixed);
        break;
      default:
        break;
    }
    setLanguage([...value]);
  };

  const accountIDChangeHandler = (accId) => {
    setAccountError(null);
    setFilterAccountId(accId);
  };

  const validateAccount = () => {
    const accValidation = toValidateAccountDropdown(filterAccountId);

    if (isSuperAdmin && accValidation) {
      setAccountError(accValidation);
    } else {
      setAccountError(null);
    }
  };

  const handleLocalNextClick = (e) => {
    e.preventDefault();
    setNextClicked(true);
    validateAccount();
    if (toValidateChecklistName(checklistData?.checklistName) || (!editChecklist && accountError)) {
      return;
    }
    handleNextClick(e);
  };
  useEffect(() => {
    setNextClicked(false);
  }, [checklistData.checklistName]);

  return (
    <ChecklistOverviewCenter>
      <HeadingContainer>
        <Trans>Checklist Overview</Trans>
      </HeadingContainer>

      {/* Card */}
      <CheckListDiv>
        {/* Header */}

        {/* Header */}
        <Form
          noValidate
          validated={isValidated}
          onSubmit={(e) => handleLocalNextClick(e)}
          id="addChecklistForm"
        >
          {/* Form Body */}

          <Row className="p-4">
            {isSuperAdmin && !editChecklist && (
              <>
                <Col md={6} className="mb-2">
                  <DropdownWrapper
                    filter={{
                      type: "Account",
                      name: "companyName",
                      key: "companyName",
                      data: dropdownAccountList,
                      placeholder: "Select an Account",
                    }}
                    size="medium"
                    value={filterAccountId}
                    handleFilter={(value) => accountIDChangeHandler(value)}
                    isError={
                      accountError && (
                        <>
                          <Trans>{accountError}</Trans>
                        </>
                      )
                    }
                    errorMessage={t("This field is required")}
                  />
                </Col>
                <Col md={6} className="mb-2"></Col>
              </>
            )}
            <Col md={6} className="mb-2">
              <InputField
                label={t("Checklist Name")}
                value={checklistData.checklistName}
                minLength={1}
                size="medium"
                maxLength={64}
                placeholder={t("Please enter your Checklist Name")}
                required={true}
                onChange={(e) => setChecklist({ ...checklistData, checklistName: e.target.value })}
                autoFocus
                isError={nextClicked && toValidateChecklistName(checklistData?.checklistName)}
                errorMessage={t(nextClicked && toValidateChecklistName(checklistData?.checklistName))}
              />
              <br></br>
              <InputField
                label={t("Checklist Description")}
                value={checklistData.checklistDescription || ""}
                onChange={(e) => setChecklist({ ...checklistData, checklistDescription: e.target.value })}
                minLength={1}
                size="medium"
                isTextArea
                maxLength={128}
                placeholder={t("Please enter your Checklist Description")}
                rows={2}
              />
            </Col>

            <Col md={6} className="mb-2">
              <DropdownWrapperA
                label={t("Checklist Languages")}
                isClearable={language.some((v) => !v.isFixed)}
                isMulti
                size="medium"
                value={language}
                onChange={onChange}
                options={options}
              />
              {/* <label>
                <Trans>Checklist Languages</Trans>
              </label>
              <Select
                showLabel={true}
                label={t("Checklist Languages")}
                isClearable={language.some((v) => !v.isFixed)}
                isMulti
                value={language}
                onChange={onChange}
                options={options}
              /> */}
            </Col>
          </Row>

          <br />
          {/*  <p className="text-danger text-left"><Trans>Important You can make changes to the checklist in other languages selected here, in the next section</Trans>.</p>*/}

          <>
            <div className="float-right mr-3 mb-3">
              <Button
                label={t("Next")}
                iconClass="arrow-right"
                showIcon
                iconPosition="after"
                buttonType="primary"
              />
            </div>
          </>
          {/* Form Body */}
        </Form>
      </CheckListDiv>
      {/* Card */}
    </ChecklistOverviewCenter>
  );
}
