import React from "react";
import { Trans } from "react-i18next";
import {
  UserDeactivationList,
  TableContainerDiv,
  RowsContainer,
  Column,
  RowDiv,
  Important,
  NoUserLisr,
} from "./AdminList.styles";
import Button from "../../../components/Button/Button.component";

const AdminList = ({ adminList, companyName, handleSelectAccountId }) => {
  return (
    <UserDeactivationList>
      <TableContainerDiv>
        <RowDiv isHeader={true} gridTemplate={"100%"}>
          <Column isHeader={true}>Users in &nbsp;{companyName} &nbsp;- All Worksite Access Level only</Column>
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {adminList?.length === 0 ? (
            <>
              <NoUserLisr>
                <p>
                  There are no active<strong> All Worksites Access Level</strong> users in your account.{" "}
                  <br />
                  Please <b>add </b>an All Workiste Access level user or <b>upgrade</b> a user to All Worksite
                  Access Level.
                </p>
              </NoUserLisr>
            </>
          ) : (
            adminList?.map((admin, index) => (
              <>
                <RowDiv key={admin._id} isContentRow={true}>
                  <Column textleft={"left"}>
                    {admin.fullName ? <Trans>{admin.fullName}</Trans> : "No user found"}
                  </Column>
                  <Column textleft={"right"}>
                    <Button
                      iconClass="save"
                      label={"SELECT"}
                      onClick={() => {
                        handleSelectAccountId(admin._id, admin.fullName);
                      }}
                      showIcon={false}
                      buttonType="primary"
                    />
                  </Column>
                </RowDiv>
              </>
            ))
          )}
        </RowsContainer>
      </TableContainerDiv>
      <Important>
        IMPORTANT: Please note that only All Worksite Level Users can be upgraded to an Account Owner.
      </Important>
    </UserDeactivationList>
  );
};

export default AdminList;
