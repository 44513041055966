import i18next from 'i18next';
import "../../../../constant/i18n/i18n";

export const settingsOptions = [
  {
    label: i18next.t("All Worksites"),
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "worksites",
  },
  {
    label: i18next.t("Selected Worksites"),
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "name",
    radioGroup: "worksites",
    dropdown: {
      placeholder: "Select a Worksite",
    },
  },
  {
    label: i18next.t("All Asset Types"),
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assetTypes",
  },
  {
    label: i18next.t("Selected Asset Types"),
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "AssetTypeName",
    radioGroup: "assetTypes",
    dropdown: {
      placeholder: "Select an Asset Type",
    },
  },
  {
    label: i18next.t("All Assets"),
    isDropdown: false,
    id: "all",
    isCheckedKey: "all",
    radioGroup: "assets",
  },
  {
    label: i18next.t("Selected Assets"),
    id: "selected",
    isCheckedKey: "selected",
    isDropdown: true,
    labelKey: "assetName",
    radioGroup: "assets",
    dropdown: {
      placeholder: "Select an Asset",
    },
  },
];
