import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../../../components/Button/Button.component";
import { ReactComponent as IconSuccess } from "../../../../assets/AddIcon/IconRight.svg";
import { updateAsset } from "../../../../redux/assets/action";
import { apiCall } from "../../../../utils/apiCall";
import NumberInput from "../../../../components/HourMeterReadingInput/HourMeterInput.component";
import ModalComponent from "../../../../components/ModalPopupComponent/ModalPopup.component";
import {
  AdjustHourModal,
  HeadingContainer,
  ContainerRow,
  TextStyle,
  ButtonStyled,
  ConfirmStyledDiv,
  ModalStyledDiv,
} from "./AdjustHourMeterModal.styles";

function AdjustHourMeterReadingModal(props) {
  const { modalShow, setModalShowClose, currentHourMeterReading, assetOverview, updateAsset } = props;

  const [MeterReading, setMeterReading] = useState(String(assetOverview?.lastHourMeterReading));
  const [resetToZero, setResetToZero] = useState(false);
  const [updateMeterReading, setUpdateMeterReading] = useState(false);
  const [isMeterHourReadingUpdated, setIsMeterHourReadingUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const handleResetToZeroYes = () => {
    // Make an API request to update the asset meter reading

    const data = {
      accountId: assetOverview?.accountId,
      siteId: assetOverview.siteId?._id,
      assetId: assetOverview?._id,
      lastHourMeterReading: 0,
      isResetToZero: true,
    };

    // Make an API request to update the asset meter reading
    setIsLoading(true);
    apiCall(`/asset/adjustMeterReading/`, { method: "POST", data: data })
      .then((resp) => {
        setResetToZero(false);
        setUpdateMeterReading(false);
        setIsMeterHourReadingUpdated(true);
        setMeterReading(String(0));
        updateAsset(resp?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleResetToZeroNo = () => {
    setResetToZero(false);
    setUpdateMeterReading(false);
    setIsMeterHourReadingUpdated(false);
    setMeterReading(String(assetOverview?.lastHourMeterReading));
  };

  const handleUpdateMeterReadingYes = () => {
    let data = {
      accountId: assetOverview?.accountId,
      siteId: assetOverview.siteId?._id,
      assetId: assetOverview?._id,
      lastHourMeterReading: MeterReading,
      isResetToZero: false,
    };

    // Make an API request to update the asset meter reading
    setIsLoading(true);
    apiCall(`/asset/adjustMeterReading/`, { method: "POST", data: data })
      .then((resp) => {
        setResetToZero(false);
        setUpdateMeterReading(false);
        setIsMeterHourReadingUpdated(true);
        setIsLoading(false);
        updateAsset(resp?.data?.data);
        console.log(resp?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleUpdateMeterReadingNo = () => {
    setResetToZero(false);
    setUpdateMeterReading(false);
    setIsMeterHourReadingUpdated(false);
    setMeterReading(String(assetOverview?.lastHourMeterReading));
  };

  const handleUpdateReadingContinue = () => {
    setResetToZero(false);
    setUpdateMeterReading(false);
    setIsMeterHourReadingUpdated(false);
    setModalShowClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (assetOverview?.lastHourMeterReading == null) {
      setMeterReading("");
    } else {
      setMeterReading(String(assetOverview?.lastHourMeterReading));
    }
  }, [assetOverview?.lastHourMeterReading]);

  return (
    <ModalComponent
      show={modalShow}
      handleClose={() => setModalShowClose(false)}
      title={
        !resetToZero && !updateMeterReading && !isMeterHourReadingUpdated && <Trans>Adjust Hour Meter</Trans>
      }
      withHeader={!resetToZero && !updateMeterReading && !isMeterHourReadingUpdated}
    >
      {isMeterHourReadingUpdated ? (
        <>
          <ModalStyledDiv>
            <Row className="text-center">
              <Col>
                <h1>
                  <Trans>Hour Meter Updated</Trans>
                </h1>
                <p className="rightIcon">
                  <IconSuccess />
                </p>
                <p className="currentMeterR">
                  <Trans>Current Hour Meter Reading</Trans>
                </p>

                <NumberInput pointerEvents={true} value={MeterReading} handleInputChange={setMeterReading} />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button
                label={t("Continue")}
                onClick={handleUpdateReadingContinue}
                showIcon={false}
                buttonType="primary"
              />
            </Row>
          </ModalStyledDiv>
        </>
      ) : updateMeterReading ? (
        <>
          <ModalStyledDiv>
            <ConfirmStyledDiv>
              <Trans>Are you sure you want to change the hour meter reading</Trans>?
            </ConfirmStyledDiv>
            <Row className="justify-content-center">
              <Button
                label={t("Yes")}
                onClick={handleUpdateMeterReadingYes}
                showIcon={false}
                buttonType="primary"
              />
            </Row>
            <Row className="justify-content-center">
              <Button
                label={t("No")}
                onClick={handleUpdateMeterReadingNo}
                showIcon={false}
                buttonType="ghost"
              />
            </Row>
          </ModalStyledDiv>
        </>
      ) : resetToZero ? (
        <>
          <ModalStyledDiv>
            <ConfirmStyledDiv>
              <Trans>Are you sure you want to reset the hour meter to 'zero'</Trans>?
            </ConfirmStyledDiv>
            <Row className="justify-content-center">
              <Button label={t("Yes")} onClick={handleResetToZeroYes} showIcon={false} buttonType="primary" />
            </Row>
            <Row className="justify-content-center">
              <Button label={t("No")} onClick={handleResetToZeroNo} showIcon={false} buttonType="ghost" />
            </Row>
          </ModalStyledDiv>
        </>
      ) : (
        <>
          <AdjustHourModal>
            <HeadingContainer>
              <Trans>Current Reading</Trans>
            </HeadingContainer>

            <ContainerRow>
              <Row className="p4">
                <Col md={12} className="mb-2">
                  <TextStyle>
                    <Trans>Enter the adjusted reading for the hour meter</Trans>
                  </TextStyle>

                  <NumberInput
                    value={MeterReading}
                    pointerEvents={false}
                    handleInputChange={setMeterReading}
                  />
                </Col>
              </Row>
            </ContainerRow>
            <ButtonStyled>
              <Button
                className="float-left"
                label={t("Reset to Zero")}
                onClick={() => setResetToZero(true)}
                iconClass="asset-hour-reset"
                showIcon
                buttonType="primary"
              />

              <Button
                className="float-right"
                label={t("Update")}
                onClick={() => {
                  setUpdateMeterReading(true);
                }}
                showIcon={false}
                buttonType="primary"
                disabled={MeterReading == currentHourMeterReading}
              />
            </ButtonStyled>
          </AdjustHourModal>
        </>
      )}
    </ModalComponent>
  );
}

const mapStateToProps = (state) => ({
  assetOverview: state.assets.AssetOverview,
});

const mapDispatchToProps = (dispatch) => ({
  updateAsset: (data) => dispatch(updateAsset(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdjustHourMeterReadingModal));
