import { formActionTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case formActionTypes.SET_EDIT_FORM:
      return {
        ...state,
        editForm: action.data,
      };
    case formActionTypes.SET_EDIT_FORM_SECOND:
      return {
        ...state,
        editFormSecond: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
