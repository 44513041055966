import styled from "styled-components";
export const SteperStyledAddAsset = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const StepperMainDiv = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  flex-direction: row;
  margin: 0px auto 10px;
  @media (max-width: 1100px) {
    width: 100%;
    margin: auto;
  }
`;

export const StepperContainer = styled.div`
  display: grid;
  align-items: center;
  text-align: center;
  width: 50px;
  position: relative;
  justify-content: center;
  justify-items: center;
  @media (max-width: 1100px) {
    width: 30px;
    display: block;
    text-align: center;
  }
`;

export const StepperIcon = styled.div`
  display: inline-block;

  .stepperIconColor {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .stepIconActive {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    box-shadow: 0px 0px 6px #0000009a;
  }
  .stepIconInActive {
    box-shadow: 0px 0px 0px #0000009a;
  }
  .stepIconComplete {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    box-shadow: 0px 0px 0px #0000009a;
  }
  @media (max-width: 1100px) {
    position: relative;
  }
`;

export const StepperStepDiv = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700&display=swap");
  width: 100%;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  @media (max-width: 700px) {
    display: none;
  }

  .stepHeading {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    margin-top: 8px;
  }
  .stepHeadingActive {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-family: "Kanit", sans-serif;
    font-weight: 400;
  }
  .stepHeadingComplete {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-family: "Kanit", sans-serif;
    font-weight: 300;
  }
`;

export const StepperSmall = styled.div`
  font-size: 0.875em;
  margin-bottom: 5px;
  display: none;
`;

export const StepperName = styled.div`
  position: relative;
  width: max-content;
  line-height: 18px;
`;

export const StepperMainDivSE = styled.div`
  display: block;
`;

export const StepperIconStep = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  width: 50px;
  height: 50px;
  font-size: 22px !important;
  line-height: 18px;
  display: flex;
  z-index: 1;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  @media (max-width: 1100px) {
    width: 30px;
    height: 30px;
    i {
      display: none;
    }
  }
`;

export const StepSeparator = styled.div`
  flex: 1 1 auto;
  height: 3px;
  border-radius: 1px;
  display: block;
  position: relative;
  top: -12px;
  @media (max-width: 1100px) {
    top: -3px;
  }
  span {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    height: 3px;
    border-radius: 1px;
    display: block;
  }
  span.stepSeparatorComplete {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    height: 3px;
    border-radius: 1px;
    display: block;
  }
`;

export const StepperContainerN = styled.div``;
