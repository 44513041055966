import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";

import TimezoneSelect from "./TimeZoneSelect";
import DateRangePicker from "../../../../components/DatePickerComponent/DatePicker.component";
import { allDurationsArr, getTimeOptions } from "../constants";
import SimpleDropdownFilter from "../../../../components/SimpleDropdownFilter.component";
import { getGPSTrackingInfo } from "../../../../redux/deviceInformation/deviceInfo.action";

const LocationFilters = ({ setShowGPSTrackingMap, getGPSTrackingInfo, selectedAssetId }) => {
  const [duration, setDuration] = useState(null);
  const [timeRange, setTimeRange] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState({ label: "UTC", value: "UTC" });
  const [selectedDate, setSelectedDate] = useState(
    moment.utc(new Date()).utcOffset(selectedTimezone?.value).toDate()
  );

  const [currentDateTimeForTimeZone, setCurrentDateTimeForTimeZone] = useState(
    moment.utc(new Date()).utcOffset(selectedTimezone?.value).toDate()
  );
  const { t } = useTranslation();

  function getCurrentTimeInTimezone(PreviousTimeInMinutes) {
    return moment().tz(selectedTimezone.value).subtract(PreviousTimeInMinutes, "minutes").toISOString();
  }

  function getCurrentDateInTimezoneWithHour(hour) {
    return moment(selectedDate).tz(selectedTimezone.value).startOf("day").add(hour, "hours").toISOString();
  }

  const getUTCTimeBeforeXMinutes = (minutes) => {
    const xMinutesAgo = new Date(new Date(getCurrentTimeInTimezone(0)) - minutes * 60000);
    return xMinutesAgo.toISOString();
  };

  useEffect(() => {
    //1 is custom range
    if ((duration && duration !== 1) || (duration === 1 && selectedDate && timeRange)) {
      setShowGPSTrackingMap(true);
      if (duration && duration !== 1) {
        const startDateUTC = getUTCTimeBeforeXMinutes(duration);
        const endDateUTC = getUTCTimeBeforeXMinutes(0);
        getGPSTrackingInfo(selectedAssetId, startDateUTC, endDateUTC);
      } else {
        const startDateUTC = getCurrentDateInTimezoneWithHour(timeRange - 1);
        const endDateUTC = getCurrentDateInTimezoneWithHour(timeRange);
        getGPSTrackingInfo(selectedAssetId, startDateUTC, endDateUTC);
      }
    }
  }, [duration, timeRange, selectedDate, selectedTimezone]);

  useEffect(() => {
    const timeZone = selectedTimezone?.value?.split("__")[0];
    const currentDateTimeForTimezone = getCurrentTimeInTimezone(timeZone);
    setCurrentDateTimeForTimeZone(new Date(currentDateTimeForTimezone));
    //setSelectedDate(currentDateTimeForTimeZone);
  }, [selectedTimezone]);

  return (
    <Row className="locationFilter">
      <Col md={3}>
        <SimpleDropdownFilter
          options={allDurationsArr}
          label={t("Duration")}
          isValidationError={false}
          value={duration}
          handleFilter={(selectedObj) => setDuration(selectedObj.value)}
          sortData={false}
          size="medium"
        />
      </Col>
      {duration === 1 && (
        <>
          <Col md={3}>
            <DateRangePicker
              onlyDatePicker
              isClearable={false}
              name="issueDate"
              onChangeStartDate={(date) => {
                setSelectedDate(date);
              }}
              dateFormat="MM/dd/yyyy"
              label={t("Date")}
              placeholderText={t("Select a date")}
              selected={selectedDate}
              minDate={moment.utc(currentDateTimeForTimeZone).subtract(6, "days").toDate()}
              maxDate={currentDateTimeForTimeZone}
              size="medium"
            />
          </Col>
          <Col md={3}>
            <SimpleDropdownFilter
              options={getTimeOptions(selectedDate, currentDateTimeForTimeZone)}
              size="medium"
              label={t("Time Range")}
              isValidationError={false}
              value={timeRange}
              handleFilter={(selectedObj) => setTimeRange(selectedObj.value)}
              sortData={false}
              prioritizeSelected={true}
            />
          </Col>
          <Col md={3}>
            <TimezoneSelect selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} />
          </Col>
        </>
      )}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    devciceInformationList: state.deviceInfo.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGPSTrackingInfo: (assetId, startDateUTC, endDateUTC) =>
    dispatch(getGPSTrackingInfo(assetId, startDateUTC, endDateUTC)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilters);
