export const assetTypeManagementTypes = {
  // TYPE: ASSET TYPE LIST
  LIST_ASSET_TYPE: "LIST_ASSET_TYPE",

  // TYPE: DEFAULT ASSET_TYPE OVERVIEW
  DEFAULT_ASSET_TYPE_OVERVIEW: "DEFAULT_ASSET_TYPE_OVERVIEW",

  // TYPE: SELECTED ASSET_TYPE OVERVIEW
  ASSET_TYPE_OVERVIEW: "ASSET_TYPE_OVERVIEW",

  // TYPE: UPDATE ASSET_TYPE AFTER ASSET_TYPE UPDATE
  UPDATE_ASSET_TYPE: "UPDATE_ASSET_TYPE",

  GET_ASSET_TYPE_WITH_COUNT_BY_ACCOUNT_SUCCESS: "GET_ASSET_TYPE_WITH_COUNT_BY_ACCOUNT_SUCCESS",

  CREATE_ASSET_TYPE_SUCCESS: "CREATE_ASSET_TYPE_SUCCESS",
  CREATE_ASSET_TYPE_FAILURE: "CREATE_ASSET_TYPE_FAILURE",
  RESET_CREATE_ASSET_TYPE_FLAGS: "RESET_CREATE_ASSET_TYPE_FLAGS",
};
