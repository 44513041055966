import { useEffect } from "react";
import { Fragment, useState } from "react";
import MultiplePhotoUpload from "./FileUpload.component";

export default function MeterReading({
  data,
  currentIndex,
  setChecklistResponseForm,
  handleSubmitAnswer,
  showSelection,
  uploadImage,
  deleteImage,
  deleteImageLoading,
  uploadImageLoading,
}) {
  const [answerInput, setAnswerInput] = useState("");
  const [currentResponse, setCurrentResponse] = useState({});
  const [textValidation, setTextValidation] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCurrentResponse(data[currentIndex]);
    setAnswerInput(data[currentIndex]?.response);
    setShowError(false);
  }, [currentIndex]);

  const onChangeInput = (e) => {
    setAnswerInput(e.target.value);
  };

  const onClickSubmit = () => {
    setShowError(true);
    if (!answerInput) {
      setTextValidation("Text response is required");
      return;
    }
    if (!/^[-\w .()&$#]+$/.test(answerInput)) {
      setTextValidation("Please enter text only");
      return;
    }
    data[currentIndex] = {
      ...currentResponse,
      response: answerInput,
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  useEffect(() => {
    if (!answerInput) {
      setTextValidation("Text response is required");
      return;
    }
    if (!/^[-\w .()&$#]+$/.test(answerInput)) {
      setTextValidation("Please enter text only");
      return;
    }
    setTextValidation("");
  }, [answerInput]);

  const onClickNA = () => {
    data[currentIndex] = {
      ...currentResponse,
      response: "NA",
    };
    //setChecklistResponseForm(data);
    handleSubmitAnswer();
  };

  return (
    <>
      <div className="justify-center flex-wrap w-full  block md:block lg:flex ">
        <div className="flex-1 grid  border-[#F2F2F2] border">
          <div className="heightmax">
            <p className="w-full font-sans p-1  text-xl rounded text-black-text flex justify-left">
              <span className="text-md md:text-xl lg:text-xl "> {currentResponse?.question}</span>
            </p>
          </div>
        </div>
        <div className="flex-1 items-center justify-center relative">
          <div className="m-auto text-left w-full md:w-3/4 lg:w-3/4 ">
            <form noValidate>
              <label className="text-sm font-sans text-color-blackDark">Text Response</label>
              <textarea
                id="about"
                name="about"
                rows={4}
                onChange={onChangeInput}
                value={answerInput ? (answerInput == "NA" ? "" : answerInput) : ""}
                className="focus:border-blue-500 p-2 text-xrlem font-sans block w-full  text-xl border-b text-black-text border-gray-300"
                placeholder="Enter your response here"
              />
              {showError && textValidation && (
                <div className="text-color-red flex justify-left font-sans text-md">{textValidation}</div>
              )}
            </form>
          </div>
          <div className="flex justify-centr flex-wrap mt-4">
            <div className="w-full flex-1  justify-center">
              <button
                type="button"
                onClick={onClickSubmit}
                className={`${
                  showSelection && answerInput === currentResponse?.response && answerInput !== "NA"
                    ? "button-green-selected"
                    : "button-green"
                } font-kanit text-xrlem border-none`}
              >
                SUBMIT
              </button>
            </div>
            <div className="w-full flex-1  justify-center self-center">
              <button
                type="button"
                onClick={onClickNA}
                className={`${
                  showSelection && answerInput === currentResponse?.response && answerInput === "NA"
                    ? "button-gray-selected"
                    : "button-gray"
                } font-kanit text-xrlem border-none`}
              >
                NA
              </button>
            </div>
          </div>
          <div className="flex-1">
            <MultiplePhotoUpload
              deleteImageLoading={deleteImageLoading}
              uploadImageLoading={uploadImageLoading}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              currentResponse={currentResponse}
              setCurrentResponse={setCurrentResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
}
