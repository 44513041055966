import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "./Header.component";
import LoginRecordsDataTable from "./TableWrapper/LoginRecordsDataTable.component";
import { SettingsWrapper, SettingsContainer, SettingWrapper } from "./LoginRecords.component.styles";
import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
const LoginRecords = ({ updateActiveNavItem, selectedAccount }) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("activityLogs");
  }, [updateActiveNavItem]);

  const [loginRecords, setLoginRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);

  const handlePageLength = (e) => {
    setPageSize(parseInt(e.target.value));
    setPageNo(1);
  };

  const previousPage = () => {
    if (pageNo === 1) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (!loginRecords?.total || pageNo * pageSize >= loginRecords?.total) {
      return;
    }
    setPageNo(pageNo + 1);
  };

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);

    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
    if (loginRecords?.data?.length === newDownloadReportSet.size) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      loginRecords?.data?.forEach((data, i) => {
        newDownloadReportSet.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    addDownloadReportSet(new Set());
    setSelectAll(false);
  }, [pageNo, pageSize]);

  const [isLoading, setIsLoading] = useState(false);

  const [showMoreFilter, setShowMoreFilter] = useState(false);
  return (
    <SettingsWrapper>
      <Header
        title={t("LOGIN_RECORDS_HEADER")}
        setLoginRecords={setLoginRecords}
        pageSize={pageSize}
        pageNo={pageNo}
        downloadReportSet={downloadReportSet}
        recordSet={loginRecords?.data}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selectedAccount={selectedAccount}
        showMoreFilter={showMoreFilter}
        setShowMoreFilter={setShowMoreFilter}
      />

      <SettingsContainer showMoreFilter={showMoreFilter}>
        <SettingWrapper>
          <LoginRecordsDataTable
            data={loginRecords?.data || []}
            rowHeight={"370"}
            previousPage={previousPage}
            nextPage={nextPage}
            handlePageLength={handlePageLength}
            pageSize={pageSize}
            pageNo={pageNo}
            totalCount={loginRecords?.total || 0}
            downloadReportSet={downloadReportSet}
            isAllSelected={isAllSelected}
            handleCheckboxClick={handleCheckboxClick}
            handleSelectAll={handleSelectAll}
            isLoadingRecords={isLoading}
          />
        </SettingWrapper>
      </SettingsContainer>
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    dropdownAccountId: state.dropdownFilters.accountId,
    loading: state.notificationSetting.loading,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    accountListLoading: state.dropdownFilters.accountListLoading,
    isSuperAdmin: state.user.isSuperAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginRecords);
