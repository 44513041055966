import { assetStatusTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

const getAssetStatusLoading = () => ({
  type: assetStatusTypes.GET_ASSET_STATUS_LOADING,
});

const getAssetStatusSuccess = (data) => ({
  type: assetStatusTypes.GET_ASSET_STATUS_SUCCESS,
  data,
});

const getAssetStatusError = (err) => ({
  type: assetStatusTypes.GET_ASSET_STATUS_ERROR,
  data: err,
});

const setAssetStatusCount = (count) => ({
  type: assetStatusTypes.SET_ASSET_STATUS_COUNT,
  data: count,
});

export const getAssetStatus = (paginationData) => {
  return async (dispatch, getState) => {
    let state = getState();
    let dropdownFilters = state?.dropdownFilters;

    dispatch(getAssetStatusLoading());

    let accountID = state?.user?.loginDetails?.accountId || dropdownFilters?.accountId;
    let siteID = state?.user?.isSiteAdmin
      ? state?.user?.loginDetails?.userData?.authorizedSites?.[0]
      : dropdownFilters?.worksiteId;
    let assetTypeID = state?.dropdownFilters?.assetTypeId;
    let productType = state?.dropdownFilters?.productType || "all";
    let pageNo = paginationData?.pageNo;
    let pageSize = paginationData?.pageSize;
    let searchText;
    if (paginationData?.searchText) {
      searchText = paginationData?.searchText;
    } else {
      searchText = "all";
    }

    let action = `/asset/getassetfilter/${accountID}/${siteID}/${assetTypeID}/${pageNo}/${pageSize}/${
      searchText ? encodeURIComponent(searchText) : "all"
    }`;

    let data = {
      productType,
    };
    apiCall(action, { method: "POST", data })
      .then((response) => {
        dispatch(setAssetStatusCount(response.data.totalCount));
        dispatch(getAssetStatusSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAssetStatusError(err));
      });
  };
};
