import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import backendTimeZones from "../../../constant/backendTimezones";
import SnackBar from "../SnackBar/SnackBar";
import UpdateButtons from "../../../components/UpdateButtons.component";
import {
  worksiteDetailsUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
} from "../../../redux/worksites/action";
import { setEditForm } from "../../../redux/forms/action";
import ShiftDurationPanel from "./ShiftDurationPanel.component";
import { ManagementPanelWrapper, WorksiteOverviewDiv } from "./WorksiteOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";
import CustomInputField from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";

function WorksiteOverviewTab({
  worksiteOverview,
  OverviewDetails,
  statusButton,
  worksiteDetailsUpdate,
  updateLoading,
  updateSuccess,
  updateMessage,
  resetWorksitesState,
  WorksiteLoading,
  editForm,
  setEditForm,
  worksiteNameUpdate,
  handleWorksiteNameChange,
}) {
  const { manager, address, timeZone, accountId, _id } = OverviewDetails
    ? OverviewDetails
    : { manager: "", address: "", timeZone: backendTimeZones[0].value };
  const [value, setValue] = useState("overview");
  const [worksiteDetails, setWorksiteDetails] = useState({ manager, address, timeZone, _id });
  const [updateError, setUpdatetError] = useState("");
  const { t } = useTranslation();
  const [shiftSettings, setShiftSettings] = useState(null);
  const [WorkSiteName, setWorkSiteName] = useState("");
  const [IsSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setWorksiteDetails({
      ...worksiteDetails,
      manager: manager ? manager : null,
      address,
      timeZone,
      accountId,
      _id,
    });
    setUpdatetError("");
    setShiftSettings(OverviewDetails.shiftSettings);
  }, [OverviewDetails]);

  useEffect(() => {
    handleCancel();
  }, [editForm]);

  const handleChange = (newValue) => {
    setUpdatetError("");
    setValue(newValue);
  };

  const handleCancel = () => {
    setWorksiteDetails({ manager, address, timeZone, accountId, _id });
  };

  const handleUpdate = (event) => {
    // event.preventDefault();
    // worksiteDetailsUpdate(worksiteDetails._id, { ...worksiteDetails, shiftSettings }, setEditForm);
    // setIsSubmitted(true);
    // if (WorkSiteName) {
    //   worksiteNameUpdate(_id, WorkSiteName);
    // }
    event.preventDefault();
    worksiteDetailsUpdate(worksiteDetails._id, { ...worksiteDetails, shiftSettings }, setEditForm);
    setIsSubmitted(true);
    if (WorkSiteName) {
      worksiteNameUpdate(_id, WorkSiteName).then(() => {
        // Call the callback to notify WorksiteOverview that the name has been updated
        handleWorksiteNameChange(WorkSiteName); // Pass the new name up to WorksiteOverview
      });
    }
  };

  const handleCloseSnackBar = () => {
    resetWorksitesState();
  };

  const handleMessage = (updateSuccess, updateError) => {
    if (updateSuccess) {
      return t(updateMessage);
    } else if (updateError) {
      return updateMessage;
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setWorkSiteName(newName);
    handleWorksiteNameChange(newName); // Call the handler to update WorksiteOverview
  };

  useEffect(() => {
    if (OverviewDetails?.name) {
      setWorkSiteName(OverviewDetails.name);
    }
  }, [OverviewDetails]);
  // Determine if the cursor should be set to none
  const cursorStyle = !WorkSiteName ? { cursor: "not-allowed" } : {};

  return (
    <ManagementPanelWrapper>
      <Col>
        <SieraTabs
          title="tab"
          defaultActiveKey="overview"
          variant="horizontalNew"
          activeKey={value}
          onSelect={handleChange}
        >
          <SieraTab eventKey="overview" title={t("Worksite Overview")}>
            <div value={value} index="overview">
              {WorksiteLoading && (
                <div>
                  <Skeleton className="m-2" count={4} width={"90%"} />
                </div>
              )}
              {!WorksiteLoading && worksiteOverview && (
                <>
                  <WorksiteOverviewDiv>
                    <Row>
                      <>
                        <PermissionCheck
                          section="DASHBOARD"
                          permissionName="WORKSITE_MANAGEMENT"
                          actionName="edit"
                        >
                          {!editForm && (
                            <Col md={4}>
                              <CustomInputField
                                autofocus
                                label={t("Worksite Name")}
                                value={WorkSiteName}
                                onChange={handleNameChange}
                                disabled={editForm}
                                errorMessage={
                                  IsSubmitted && !WorkSiteName ? t("Worksite Name is required") : ""
                                }
                                fullWidth
                                isError={IsSubmitted && !WorkSiteName}
                                size="small"
                              />
                            </Col>
                          )}
                        </PermissionCheck>
                      </>

                      <Col md={4}>
                        <div>
                          <CustomInputField
                            disabled={editForm}
                            label={t("Address")}
                            value={worksiteDetails?.address}
                            onChange={(e) =>
                              setWorksiteDetails({ ...worksiteDetails, address: e.target.value })
                            }
                            type="text"
                            minLength={1}
                            maxLength={128}
                            placeholder={t("Enter Your Address")}
                            autofocus
                          />
                        </div>
                      </Col>
                      <Col md={4} className="work-label">
                        <DropdownFilterWrapper
                          disabled={editForm}
                          label={t("Time Zone")}
                          options={backendTimeZones?.map((option, i) => ({
                            _id: option._id,
                            label: option.label,
                          }))}
                          value={worksiteDetails?.timeZone}
                          onChange={(value) =>
                            setWorksiteDetails({
                              ...worksiteDetails,
                              timeZone: value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </WorksiteOverviewDiv>
                  <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="edit">
                    <Row style={cursorStyle}>
                      <Col md={4}></Col>
                      <Col md={4}></Col>
                      <UpdateButtons
                        editOverview={editForm}
                        setEditOverview={setEditForm}
                        statusButton={statusButton}
                        handleUpdate={handleUpdate}
                        IsLoading={updateLoading}
                        overviewDetail={worksiteOverview}
                        handleCancel={handleCancel}
                      />
                    </Row>
                  </PermissionCheck>
                </>
              )}
            </div>
          </SieraTab>

          <SieraTab eventKey="shifts" title={t("Worksite Shifts")}>
            <div value={value} index="shifts">
              {!WorksiteLoading && worksiteOverview && (
                <ShiftDurationPanel
                  savedShiftSettings={OverviewDetails.shiftSettings}
                  updateLoading={updateLoading}
                  handleUpdate={handleUpdate}
                  worksiteId={worksiteOverview ? worksiteOverview._id : ""}
                  shiftSettings={shiftSettings}
                  setShiftSettings={setShiftSettings}
                  statusButton={statusButton}
                />
              )}
            </div>
          </SieraTab>
        </SieraTabs>
        <SnackBar
          isSuccess={updateSuccess}
          isFailed={updateError}
          label={handleMessage(updateSuccess, updateError)}
          handleClose={handleCloseSnackBar}
        />
      </Col>
    </ManagementPanelWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  worksiteDetailsUpdate: (id, details, setEditForm) =>
    dispatch(worksiteDetailsUpdate(id, details, setEditForm)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  setEditForm: (data) => dispatch(setEditForm(data)),
  worksiteNameUpdate: (id, name) => dispatch(worksiteNameUpdate(id, name)),
});

const mapStateToProps = (state) => ({
  worksiteOverview: state.worksites.WorksiteOverview,
  updateLoading: state.worksites.UpdateLoading,
  updateSuccess: state.worksites.UpdateSuccess,
  updateMessage: state.worksites.UpdateMessage,
  updateError: state.worksites.UpdateError,
  WorksiteLoading: state.worksites.WorksiteLoading,
  editForm: state.forms.editForm,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteOverviewTab));
