import { userTypes } from "./user.types";

const INTIAL_STATE = {
  // Current User Details
  userDetails: null,
  loginSuccess: false,
  // Login Token Details
  loginDetails: null,
  refreshTokenLoading: false,
};

export const userReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_UP:
      return {
        ...state,
        userDetails: action.payload,
      };
    case userTypes.LOGIN:
      const accessLevel = action?.payload?.userData?.userType?.accessLevel;
      const DashboardPermissions = action?.payload?.userData?.permissions?.[0];
      const currentPermissionObj = DashboardPermissions?.permissions?.find(
        (permissionObj) => permissionObj.name === "SYSTEM_SETTINGS"
      );
      const systemSettingsEditPermission = currentPermissionObj?.action["edit"];

      const showWorksiteDropdown = [
        "ALL_ACCOUNTS",
        "MULTIPLE_ACCOUNTS",
        "SINGLE_ACCOUNT",
        "ALL_WORKSITES",
        "MULTIPLE_WORKSITES",
      ].includes(accessLevel);

      return {
        ...state,
        isSuperAdmin: accessLevel === "ALL_ACCOUNTS" || accessLevel === "MULTIPLE_ACCOUNTS",
        isSingleAccountMultiSiteUser:
          accessLevel === "ALL_WORKSITES" ||
          accessLevel === "MULTIPLE_WORKSITES" ||
          accessLevel === "SINGLE_ACCOUNT",
        isSiteAdmin: accessLevel === "SINGLE_WORKSITE",
        isAccountOwner: accessLevel === "SINGLE_ACCOUNT",
        isMultipleWorksiteUser: accessLevel === "MULTIPLE_WORKSITES",

        loginDetails: action.payload,
        loginSuccess: true,
        isSystemSettingsEditable: systemSettingsEditPermission,
        showWorksiteDropdown: showWorksiteDropdown,
        showUserTypeEnvUpgradeLogoutModal: !action?.payload?.userData?.userType,
      };

    case userTypes.RESET_LOGIN_LOGIN:
      return {
        ...state,
        loginSuccess: false,
      };

    //Case 3. Returning updated user details as payload
    case userTypes.UPDATE_ACCOUNT:
      let newDetail = { ...state?.loginDetails, ...action?.payload };
      if (action.payload.email) {
        newDetail = { ...newDetail, userData: { ...newDetail.userData, email: action.payload.email } };
      }
      return {
        ...state,
        loginDetails: { ...newDetail },
      };

    //Case 4. Restoring all state
    case userTypes.LOGOUT:
      return {
        success: false,
      };

    //Case 5. to update plan
    case userTypes.UPDATE_PLAN:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          plan: action.id,
          isTrial: false,
        },
      };

    case userTypes.UPDATE_STRIPE:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          stripeId: action.id,
        },
      };

    case userTypes.REFRESH_TOKEN:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          token: action.data,
        },
      };

    case userTypes.UPDATE_LOGIN_USER:
      let updateDetail = action.payload;
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          ...updateDetail,
          userData: { ...state.loginDetails.userData, ...updateDetail },
        },
      };

    case userTypes.SET_IS_ONBOARDED_FLAG:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          userData: { ...state.loginDetails.userData, isOnboarded: action.data },
        },
      };
    case userTypes.REFRESH_TOKEN_LOADING:
      return {
        ...state,
        refreshTokenLoading: action?.data,
      };

    case userTypes.UPDATE_ACCOUNT_OWNER_SUCCESS:
      return {
        ...state,
        updateAccountOwnerSuccess: true,
        updateAccountOwnerError: false,
        updateAccountOwnerLoading: false,
      };
    case userTypes.UPDATE_ACCOUNT_OWNER_ERROR:
      return {
        ...state,
        updateAccountOwnerSuccess: false,
        updateAccountOwnerError: true,
        updateAccountOwnerLoading: false,
      };
    case userTypes.UPDATE_ACCOUNT_OWNER_LOADING:
      return {
        ...state,
        updateAccountOwnerSuccess: false,
        updateAccountOwnerError: false,
        updateAccountOwnerLoading: true,
      };

    case userTypes.UPDATE_ACCOUNT_OWNER_RESET:
      return {
        ...state,
        updateAccountOwnerSuccess: false,
        updateAccountOwnerError: false,
        updateAccountOwnerLoading: false,
      };

    case userTypes.CURRENT_USER_DETAILS_UPDATED:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          userData: {
            ...state.loginDetails.userData,
            fullName: action.data.fullName,
            email: action.data.email,
            phone: action.data.phone,
          },
        },
      };

    default:
      return state;
  }
};
