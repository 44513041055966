import styled from "styled-components";
export const ChangePinChangePass = styled.div`
  width: 95%;
  margin: auto;
  text-align: left;
  /*  span.h4 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .card {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  p.passruleStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }

  input.form-control:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    border: 2px solid;
  }
  input.form-control[disabled]:hover {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }

  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }*/
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
  float: left;
  width: 100%;
  text-align: left;
`;

export const ContainerRow = styled.div`
  padding: 25px;
  margin: auto;
  overflow: hidden;
`;
export const ComponentDivSty = styled.div`
  color: ${({ isError }) => (isError ? "red" : "#323232")};
  background: #f2f2f2;
  padding: 0 10px 5px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
`;

export const InfoDivStyle = styled.div`
  div:first-child {
    width: 100%;
  }
  .icon-information-tooltip {
    width: auto !important;
  }
  button {
    pointer-events: none;
  }
`;

export const Icon = styled.div`
  margin-right: 5px;
  display: inline-flex;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
