import React from "react";
import { Col } from "react-bootstrap";

import Button from "../components/Button/Button.component";

import { useTranslation } from "react-i18next";


function UpdateButtons({
  editOverview,
  setEditOverview,
  statusButton,
  handleUpdate,
  overviewDetail,
  handleCancel,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Col className="text-right">
        {!editOverview && (
          <Button
            label={t("Cancel")}
            onClick={() => {
              setEditOverview(true);
              handleCancel && handleCancel();
            }}
            showIcon={false}
            buttonType="ghost"
          />
        )}

        {editOverview ? (
          <Button
            iconClass="edit"
            label={t("Edit")}
            onClick={() => {
              setEditOverview(!editOverview);
            }}
            disabled={!statusButton}
            showIcon
            buttonType="primary"
          />
        ) : (
          <Button
            iconClass="save"
            label={t("Save")}
            id={overviewDetail ? overviewDetail._id : ""}
            onClick={(event) => {
              handleUpdate(event);
            }}
            showIcon
            buttonType="save"
          />
        )}
      </Col>
    </>
  );
}

export default UpdateButtons;
