import React, { useState } from "react";
import Leaflet from "leaflet";
import { Trans, useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";

import { DetailsMainWrapper, DetailsHeading, BreakLine, TabWrapper } from "./DeviceDetailsInfo.styles";
import LocationTracking from "./LocationTracking";
import LastKnownLocation from "./LastKnownLocation";
import SiearTabs from "../../../../components/TabComponent/SieraTabs.Component";
import SiearTab from "../../../../components/TabComponent/SieraTab.Component";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const AssetLocation = (props) => {
  const { selectedAsset } = props;
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const position = selectedAsset?.lastDeviceInformation?.location?.coordinates;
  const { t } = useTranslation();

  const handleTabChange = (newValue) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setValue(newValue);
    }, 400);
  };

  return (
    <>
      <DetailsMainWrapper>
        <DetailsHeading>
          <Trans>Asset Location</Trans>
        </DetailsHeading>
        <BreakLine />
        <TabWrapper>
          <SiearTabs
            align="middle"
            defaultActiveKey="Last Known Location"
            variant="horizontal"
            onSelect={handleTabChange}
          >
            <SiearTab eventKey={t("Last Known Location")} title={t("Last Known Location")}>
              {loading ? (
                <div
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}
                >
                  <div className="spinner-border spinner-border-sm" role="status"></div>
                </div>
              ) : (
                <LastKnownLocation position={position} />
              )}
            </SiearTab>
            {/* SIER-7263 <SiearTab
              eventKey={t("Location Tracking")}
              title={t("Location Tracking")}
              disabled={!position || position.length === 0}
            >
              {loading ? (
                <div
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}
                >
                  <div className="spinner-border spinner-border-sm" role="status"></div>
                </div>
              ) : (
                <LocationTracking position={position} selectedAssetId={selectedAsset._id} />
              )}
            </SiearTab> */}
          </SiearTabs>
        </TabWrapper>
      </DetailsMainWrapper>
    </>
  );
};

export default AssetLocation;
