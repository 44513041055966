import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const LoaderCover = styled.div`
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Loader = () => {
  return (
    <LoaderCover>
      <Spinner animation="border" variant="primary" />
    </LoaderCover>
  );
};

export default Loader;
