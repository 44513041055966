const initialState = {
  Loading: false,
  Success: false,
  Error: false,
  AssetTypes: null,
  AssetLoading: false,
  AssetSuccess: true,
  AssetError: false,
  Assets: null,
  AssetCheckListLoading: false,
  AssetCheckListSuccess: false,
  AssetCheckListError: false,
  AssetCheckList: [],
  MediaLoading: false,
  MediaSuccess: false,
  MediaError: false,
  Media: null,
  AnswerLoading: false,
  AnswerSuccess: false,
  AnswerError: false,
  Result: null,
  SelectedAsset: null,
};

export default initialState;
