import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as AlertIcon } from "../../../../assets/AlertIcon.svg";
import Button from "../../../../components/Button/Button.component";
import { useTranslation } from "react-i18next";

const InspectionLimitError = ({ setShowInspection }) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setShowInspection(false);
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const { t } = useTranslation();
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl opacity-100">
                <AlertIcon className="w-14" />
                <div className="mt-4">
                  <p className="text-blue-600 font-normal text-2xl text-xrlem">
                    You've reached your inspection limit!
                  </p>
                  <br />
                  <p className="text-gray-600 font-sans text-xl text-xarlem">
                    Please upgrade your plan in order to perform unlimited inspections.
                  </p>
                  <br />
                  <p className="text-gray-600 font-sans text-xl text-xarlem">
                    Contact{" "}
                    <a className="text-blue-600 text-xl underline" href="support@siera.ai">
                      support@siera.ai
                    </a>{" "}
                    to upgrade your inspection plan.
                  </p>
                  <br />
                  {/*  <button
                    onClick={() => closeModal()}
                    className="mt-5 font-kanit text-white bg-secondary-background pl-8 pr-8 pt-3 pb-3 bg-[#0D5FBE] w-52 rounded font-normal"
                  >
                    CONTINUE
                  </button>*/}

                  <Button showIcon={false} label={t("CONTINUE")} onClick={() => closeModal()} size="large" />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InspectionLimitError;
