import Leaflet from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ReportWrapper } from "./Reports.styles";
import { useTranslation, Trans } from "react-i18next";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapView = ({ position }) => {
  return (
    <ReportWrapper>
      {position.length > 0 ? (
        <div>
          <MapContainer center={position} zoom={15} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                <Trans>DEVICE_LOCATION</Trans>
              </Popup>
              <Circle center={position} fillColor="blue" radius={50} />
            </Marker>
          </MapContainer>
        </div>
      ) : (
        <div>
          <Trans>DEVICE_LOCATION_UNAVAILABLE</Trans>
        </div>
      )}
    </ReportWrapper>
  );
};

export default MapView;
