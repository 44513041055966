export const initialState = {
  // For User Type Listing Status
  UserTypeListLoading: false,
  UserTypeListSuccess: false,
  UserTypeListError: false,
  UserTypeList: [],

  // User Type List Detail
  UserTypeDetailLoading: false,
  UserTypeDetailSuccess: false,
  UserTypeDetailError: false,
  UserTypeDetail: "",

  // Add User Type
  AddUserTypeLoading: false,
  addUserTypeSuccess: false,
  addUserTypeError: false,

  // Update User Type
  UserTypeUpdateLoading: false,
  UserTypeUpdateSuccess: false,
  UserTypeUpdateError: false,

  getSuperAdminUserTypesSuccess: false,
  getSuperAdminUserTypesError: false,

  // Deactivated Users by User Type Deactivation
  DeactivatedUsersLoading: false,
  DeactivatedUsersSuccess: false,
  DeactivatedUsersError: false,
  DeactivatedUsersList: [],

 // Deactivated Users by User Type Deactivation by confirm button
  DeactivateUserTypeLoading: false,
  DeactivateUserTypeSuccess: false,
  DeactivateUserTypeError: true,

};
