import styled from "styled-components";

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;

export const UserOverviewFormDiv = styled.div``;

export const ErrorField = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px !important" : "12px !important")};
  color: rgb(221, 0, 0);
  left: 3px;
  margin-top: 0px;
`;
