export const bulkUploadTypes = {
  // FOR USER BULK UPLOAD

  //FOR ASSET BULK UPLOAD
  GET_ASSET_BULK_UPLOAD_TEMPLATE_LOADING: "GET_ASSET_BULK_UPLOAD_TEMPLATE_LOADING",
  GET_ASSET_BULK_UPLOAD_TEMPLATE_SUCCESS: "GET_ASSET_BULK_UPLOAD_TEMPLATE_SUCCESS",
  GET_ASSET_BULK_UPLOAD_TEMPLATE_ERROR: "GET_ASSET_BULK_UPLOAD_TEMPLATE_ERROR",
  UPLOAD_ASSET_BULK_UPLOAD_FILE_LOADING: "UPLOAD_ASSET_BULK_UPLOAD_FILE_LOADING",
  UPLOAD_ASSET_BULK_UPLOAD_FILE_SUCCESS: "UPLOAD_ASSET_BULK_UPLOAD_FILE_SUCCESS",
  UPLOAD_ASSET_BULK_UPLOAD_FILE_ERROR: "UPLOAD_ASSET_BULK_UPLOAD_FILE_ERROR",
  RESET_UPLOAD_ASSET_BULK_UPLOAD_FILE_STATE: "RESET_UPLOAD_ASSET_BULK_UPLOAD_FILE_STATE",
};
