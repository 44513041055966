import styled from "styled-components";

export const UserDeactivationList = styled.div``;
export const ParaText = styled.p`
  text-align: center;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: 77%;
  margin: 10px auto;
`;

export const TableContainerDiv = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  width: 80%;
  margin: auto;
`;
export const NoDataDiv = styled.div``;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 10% 45% 45%;
  line-height: 32px;
  border-top-left-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  border-top-right-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  label {
    margin-bottom: 0px;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 6% 47% 47%;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
    height: auto;
  }
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  :nth-last-child {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
  color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.grayL1 || "#323232"};
  background: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ isHeader }) => (isHeader ? "18px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ textleft }) => (textleft ? textleft : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};

  @media (max-width: 768px) {
    font-size: ${({ isHeader }) => (isHeader ? "15px" : "13px")} !important;
  }

  :nth-child(1) {
    padding-left: 10px;
  }
`;

export const NameSite = styled.p`
  display: block;
  padding: 0px 5px;
  float: left;
  margin-bottom: 0px;
`;

export const RowsContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 592px);

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const CommaStyle = styled.div`
  margin: 0px;
  float: left;
  display: inline-block;
`;
