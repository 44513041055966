import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm, setEditFormSecond } from "../../../redux/forms/action";

// Button Component
import Button from "../../../components/Button/Button.component";

const EditAlertModal = ({ editAlertModal, setEditAlertModal, setEditForm, setEditFormSecond }) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="md"
      className="custom-modal discard-modal"
      show={editAlertModal}
      onHide={() => setEditAlertModal(false)}
      backdrop="static"
    >
      {/* modal body starts */}

      <Modal.Body className="text-center modalPopup">
        <Row className="mb-4 mt-4 ">
          <Col>
            <span className="mb-2 text-open-sans text-center modal-font-color">
              <Trans>Discard unsaved changes</Trans>
            </span>
          </Col>
        </Row>

        {/* Modal body ends */}
        <Row className="justify-content-center">
          <div className=" mr-2">
            <Button
              label={t("Cancel")}
              onClick={() => setEditAlertModal(false)}
              showIcon={false}
              buttonType="ghost"
            />
          </div>
          <div className="">
            <Button
              label={t("Discard")}
              onClick={() => {
                setEditForm(true);
                setEditFormSecond(true);
                setEditAlertModal(false);
              }}
              showIcon={false}
              buttonType="primary"
            />
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  editAlertModal: state.modals.editAlertModal,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setEditFormSecond: (data) => dispatch(setEditFormSecond(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAlertModal);
