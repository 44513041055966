import styled from "styled-components";

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 23% 63% auto;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  @media (max-width: 1100px) {
    grid-template-columns: ${({ isHeader }) => (isHeader ? "33% 12% auto" : "100%")};
    display: ${({ isHeader }) => (isHeader ? "none" : "block")};

    position: relative;
    border: ${({ isHeader }) => (isHeader ? "0px" : "1px solid #CCCCCC")};
    margin: ${({ isHeader }) => (isHeader ? "0px" : "10px 0px")};
    padding: 10px 0px;

    .editSwitchMobile {
      float: right;
      position: absolute;
      top: 5px;
      right: 0px;
    }
    .mobileFontSize {
      font-size: 16px !important;
    }
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  label {
    margin: 0 !important;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }

  // Responsive styling
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    float: none;
    margin-right: 0;
    padding: 7px;

    &:nth-child(odd) {
      text-align: right;
    }

    &:nth-child(even) {
      text-align: right;
    }
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 640px);
  padding-bottom: 10px;

  @media (max-width: 1100px) {
    height: auto;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;

export const MobileView = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    float: left;
  }
`;

export const AccessLevelsText = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  border-left: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  padding-left: 5px;
  ul {
    margin: 0;
    padding-left: 10px;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
    margin-right: 10px;
    display: flex;
  }
  ul.dashed > li:before {
    content: "---";
    text-indent: -5px;
    margin-right: 15px;
    letter-spacing: -2px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const AccessLevelHeading = styled.p`
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const AccessLevelDescription = styled.p`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
  margin-top: 15px;
  width: 100%;
  padding: 0px 15px;
`;

export const RowWrapper = styled.div`
  display: flex;
  padding: 0px 15px;
  margin-top: 15px;
  width: 100%;
`;

export const DownloadStyled = styled.div`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 18px !important;
`;

export const UserBulkUploadWrapper = styled.div`
  .nav-tabs {
    a {
      text-transform: uppercase;
    }
  }
`;
