import { HeaderWrapper, HeaderTitle } from "./GeneralSettingHeader.component.styles";

const GeneralSettingHeader = (props) => {
  const { title } = props;

  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderWrapper>
  );
};

export default GeneralSettingHeader;
