import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { isValidateDateObject } from "../../../utils/validations/checkValidation";
import { isNotCurrentYear } from "../../../utils/validations/utilities";
import { ThemeContext } from "styled-components";

const DashboardGraph = ({ title, series, colors, date, ...props }) => {
  const themeContext = useContext(ThemeContext);
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        height: 300,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: title,
        align: "left",
        style: {
          fontSize: "20px",
          fontFamily: "Kanit",
          color: themeContext?.colors?.grayL1 || "#323232",
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: colors,
      legend: {
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        offsetY: -10,
        fontFamily: "Open Sans",

        labels: {
          colors: themeContext?.colors?.grayL1 || "#323232",
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        style: {
          fontFamily: "Open Sans",
        },
      },
      grid: {
        row: {
          colors: ["transparent"],
          opacity: 0.5,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Open Sans",
            colors: themeContext?.colors?.grayL1 || "#323232",
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: true,
          hideOverlappingLabels: true,
          trim: false,
          floating: true,
          showDuplicates: false,
          datetimeUTC: false,
          style: {
            fontFamily: "Open Sans",
            colors: themeContext?.colors?.grayL1 || "#323232",
          },
        },
        tooltip: {
          enabled: true,
          style: {
            backgroundColor: themeContext?.colors?.themePrimary || "#0D5FBE",
          },
        },
      },
    },
  });

  useEffect(() => {
    let startDate, endDate;
    if (!isValidateDateObject(new Date(props.endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(props.endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(props.startDate))) {
      startDate = moment().startOf("month").add(1, "second").format("YYYY-MM-DD");
    } else {
      startDate = moment(props.startDate).format("YYYY-MM-DD");
    }
    const downloadName = `SIERA.AI_Graph_${startDate}-to-${endDate}`;
    setState((prevState) => ({
      ...prevState,
      series: series,
      options: {
        ...prevState?.options,
        chart: {
          ...prevState?.options?.chart,
          toolbar: {
            export: {
              csv: {
                filename: downloadName,
              },
              svg: {
                filename: downloadName,
              },
              png: {
                filename: downloadName,
              },
            },
          },
        },
        xaxis: {
          ...prevState.options.xaxis,
          categories: date || [],
          labels: {
            ...prevState.options.xaxis.labels,
            format: isNotCurrentYear(
              new Date(props.startDate).getFullYear(),
              new Date(props.endDate).getFullYear()
            )
              ? "MMM dd"
              : "MMM dd 'yy",
          },
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div id="chart">
      <ReactApexChart
        options={{
          ...state.options,
          xaxis: {
            ...state.options.xaxis,
          },
        }}
        series={state.series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default DashboardGraph;
