import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../../../components/Button/Button.component";
import { passwordFieldValidation } from "../../../utils";
import SnackBar from "../../Dashboard/SnackBar/SnackBar";
import validationConstant from "../../../constant/messages/validation.json";
import {
  PasswordRulesWrapper,
  RegistrationFormWrapper,
  FieldLabel,
  TittleDiv,
  ErrorMessageDiv,
  MainWrapperDiv,
  FirstLoginMessage,
  ErrorText,
} from "./ResetPasswordForm.styles";
import PasswordRecoveryBackground from "../../../assets/profile.jpg";
import config from "../../../config";
import { generatePasswordRegex } from "../../../utils/validations/utilities";
import PasswordErrorMessages from "../../../components/password/PasswordErrorMessages";

const baseUrl = config.baseUrl; //Base Url
const { password_not_match } = validationConstant;

// Forgot Password Form Component
const ResetPasswordForm = ({ history, match }) => {
  const isExpiredPassword = match.params.source === "expired-password";
  const isFirstLoginPasswordChange = match.params.source === "change-password";

  // Setting Forgot State
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsFailed, setIsFailed] = useState(false);
  const [IsValidated, setIsValidated] = useState(false);
  const [isPasswordUsed, setIsPasswordUsed] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [passwordRegex, setPasswordRegex] = useState("");
  const [backendErrorMessage, setBackendErrorMessage] = useState("");
  const [passwordPolicyError, setPasswordPolicyError] = useState(false);

  const [isException, setIsException] = useState(false);

  const handleShowPassword = () => {
    setShowNewPassword(!ShowNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!ShowConfirmPassword);
  };

  useEffect(() => {
    getPasswordPolicyForResetPW();
  }, []);

  const getPasswordPolicyForResetPW = () => {
    const token = match.params.token;
    let headers = {
      "Content-Type": "application/json",
      "auth-token": token,
    };

    axios
      .get(`${baseUrl}/common/getPasswordPolicy/`, { headers: headers })
      .then((response) => {
        const passPolicy = response?.data?.data;
        setPasswordPolicy(passPolicy);
        const passwordRegEx = generatePasswordRegex(passPolicy);
        setPasswordRegex(passwordRegEx);
      })
      .catch((error) => {
        console.log(error);
        setBackendErrorMessage(
          t("Error occurred while resetting password.") + " " + error?.response?.data?.message
        );
        setIsException(true);
        setIsLoading(false);
      });
  };

  // Submitting Form data to Forgot Password API
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!!NewPassword || !!ConfirmPassword) {
      setIsSubmitted(true);
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsValidated(true);
      return;
    }

    if (passwordFieldValidation(NewPassword, passwordRegex) && NewPassword === ConfirmPassword) {
      setIsLoading(true);
      let token = match.params.token;
      let data = {
        password: NewPassword,
        passwordVerification: ConfirmPassword,
      };
      let headers = {
        "Content-Type": "application/json",
        "auth-token": token,
      };

      axios
        .put(`${baseUrl}/user/update/forgotPassword`, data, { headers: headers })
        .then((response) => {
          if (response.data?.success) {
            setIsLoading(false);
            // Load the back to login page from here...
            history.push(
              "/backtologin" +
                (isExpiredPassword || isFirstLoginPasswordChange ? "/reset-password" : "/password-recovery")
            );
          } else {
            if (response.data?.data?.code === "PASSWORD_ALREADY_USED") {
              //showAlert("error", t(response.data?.message));
              setIsPasswordUsed(true);
            } else if (response.data?.error?.[0]) {
              setIsFailed(true);
              setBackendErrorMessage(
                t("Error occurred while resetting password.") + " " + response.data?.error?.[0]
              );
              setPasswordPolicyError(true);
            } else {
              setIsFailed(true);
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response?.data?.message === "Access Denied: You do not have the required permissions.") {
            setBackendErrorMessage(t("ACCESS_DENIED"));
          }
          console.log(error);
          setIsLoading(false);
          setIsFailed(true);
        });
    }
  };

  const handleCloseSnackBar = () => {
    setIsFailed(false);
    setBackendErrorMessage("");
    setIsPasswordUsed(false);
  };
  const { t } = useTranslation();

  const handleMessage = (IsFailed) => {
    if (IsFailed) {
      return t("Password Reset Link is Expired. Please Try Again");
    }
  };

  //const passwordPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-~()]).{8,64}$";

  const passwordPattern = passwordRegex.toString().replace(/\//g, "");

  return (
    <RegistrationFormWrapper>
      {/*Password Recovery Title*/}

      <MainWrapperDiv>
        <Row>
          <Col md={8}>
            <TittleDiv>
              {isExpiredPassword
                ? t("Reset New Password")
                : isFirstLoginPasswordChange
                  ? t("Password Change Required")
                  : t("Password Recovery")}
            </TittleDiv>

            <FirstLoginMessage>
              {isFirstLoginPasswordChange
                ? t("FIRST_LOGIN_PASSWORD_MESSAGE")
                : isExpiredPassword
                  ? t("RESET_NEW_PASSWORD")
                  : null}
            </FirstLoginMessage>
            <Row>
              <Col md={3}> </Col>
              <Col md={6}>
                {isException && (
                  <ErrorText>
                    {backendErrorMessage ||
                      t("Error occurred while retrieving data. Please try again later.")}
                  </ErrorText>
                )}
                <PasswordRulesWrapper
                  isError={
                    IsSubmitted &&
                    (NewPassword || ConfirmPassword) &&
                    !passwordFieldValidation(NewPassword, passwordRegex)
                  }
                >
                  <PasswordErrorMessages
                    parentPasswordPolicy={passwordPolicy}
                    parent="reset"
                    hideIcon="true"
                    isError={
                      isPasswordUsed ||
                      (IsSubmitted && !passwordFieldValidation(NewPassword, passwordRegex)) ||
                      passwordPolicyError
                    }
                  />
                </PasswordRulesWrapper>
              </Col>
              <Col md={3}> </Col>
            </Row>

            <Form onSubmit={handleSubmit} className="formStyle" noValidate validated={IsValidated}>
              <Row>
                <Col md={3}> </Col>
                <Col md={6}>
                  {/*Email Text Field*/}
                  <Form.Group controlId="formBasicEmail" className="label">
                    <FieldLabel show={!!NewPassword}>
                      <Form.Label>{t("New Password")}</Form.Label>
                    </FieldLabel>
                    <InputGroup>
                      <Form.Control
                        type={ShowNewPassword ? "text" : "password"}
                        className="registerInputText"
                        placeholder={t("New Password")}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required={true}
                        value={NewPassword}
                        pattern={passwordPattern}
                      />

                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend" onClick={handleShowPassword}>
                          {ShowNewPassword ? (
                            <i className="far fa-eye"></i>
                          ) : (
                            <i className="far fa-eye-slash"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {/* Password length must be between 8 to 20 characters*/}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}> </Col>
                <Col md={3}> </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicEmail" className="label">
                    <FieldLabel show={!!ConfirmPassword}>
                      <Form.Label>{t("Confirm Password")}</Form.Label>
                    </FieldLabel>
                    <InputGroup>
                      <Form.Control
                        type={ShowConfirmPassword ? "text" : "password"}
                        className="registerInputText"
                        placeholder={t("Confirm Password")}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required={true}
                        value={ConfirmPassword}
                        pattern={passwordPattern}
                      />

                      <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend" onClick={handleShowConfirmPassword}>
                          {ShowConfirmPassword ? (
                            <i className="far fa-eye"></i>
                          ) : (
                            <i className="far fa-eye-slash"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">{/*   Both the passwords must match" */}</Form.Text>
                  </Form.Group>
                  {/* {IsSubmitted && ConfirmPassword && NewPassword !== ConfirmPassword ? (
                    <ErrorMessageDiv
                      error={IsSubmitted && NewPassword !== ConfirmPassword}
                      style={{ textAlign: "center" }}
                    >
                      {t(password_not_match)}
                    </ErrorMessageDiv>
                  ) : null} */}

                  {ConfirmPassword && NewPassword !== ConfirmPassword ? (
                    <ErrorMessageDiv error={NewPassword !== ConfirmPassword}>
                      {t(password_not_match)}
                    </ErrorMessageDiv>
                  ) : null}
                </Col>
                <Col md={3}> </Col>
              </Row>

              <Col md={12}>
                {/*Submit Button*/}

                <Button
                  label={
                    IsLoading ? (
                      <div className="spinner-border spinner-border-sm" role="status"></div>
                    ) : (
                      t("Submit")
                    )
                  }
                  buttonType="save"
                  size="medium"
                  showIcon
                  iconClass="save"
                  disabled={!(!!NewPassword && !!ConfirmPassword) || isException}
                />
              </Col>

              <Col md={12} style={{ textAlign: "-webkit-center", marginTop: "5px" }}>
                {/*Cancel Link Redirecting to Enterprise Home Page*/}
                <Button
                  onClick={() => history.push("/")}
                  label={t("CANCEL")}
                  buttonType="ghost"
                  size="medium"
                  showIcon={false}
                />
              </Col>
            </Form>
            <SnackBar
              isWarning={isPasswordUsed}
              label={t("PASSWORD_HAS_BEEN_USED_BEFORE")}
              handleClose={handleCloseSnackBar}
            />

            <SnackBar isFailed={IsFailed} label={backendErrorMessage} handleClose={handleCloseSnackBar} />
            {/*  <SnackBar isFailed={isException} label={backendErrorMessage} handleClose={handleCloseSnackBar} /> */}
          </Col>
          <Col md={4}>
            <img src={PasswordRecoveryBackground} alt="submit" className="imageStyle" />
          </Col>
        </Row>
      </MainWrapperDiv>
    </RegistrationFormWrapper>
  );
};

export default withRouter(ResetPasswordForm);
