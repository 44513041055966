import React, { useState, useEffect } from "react";

// Custom Components
import CustomStepper from "../../../../../components/CustomStepper/CustomStepper.component";
import UserOverviewForm from "./UserOverviewForm.component";
import UserDetailForm from "./UserDetailForm.component";

// Redux
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Icons and Images
import { ReactComponent as UserOverviewIcon } from "../../../../../assets/AddIcon/Blue_User_Overview2.svg";
import { ReactComponent as UserDetailsIcon } from "../../../../../assets/AddIcon/Blue_User_Overview1.svg";
    
const allSteps = [
  {
    heading: "User Overview",
    icon: <UserOverviewIcon></UserOverviewIcon>,
  },
  {
    heading: "User Details",
    icon: <UserDetailsIcon></UserDetailsIcon>,
  },
];

const getStepComponent = (step, userModalData, setuserModalData) => {
  switch (step) {
    case 0:
      return <UserOverviewForm userModalData={userModalData} setuserModalData={setuserModalData} />;
    case 1:
      return <UserDetailForm userModalData={userModalData} setuserModalData={setuserModalData} />;
    default:
  }
};

function AddUserModalContent({ activeStep, setActiveStep }) {
  // const [activeStep, setActiveStep] = useState(0);

  const [userModalData, setuserModalData] = useState({});

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrev = () => {};

  useEffect(() => {
    console.log("AddUserModalData");
    console.log(userModalData);
  }, [userModalData]);

  return (
    <>
      <div>
        <CustomStepper allSteps={allSteps} activeStep={activeStep} />

        {getStepComponent(activeStep, userModalData, setuserModalData)}

        {activeStep === allSteps?.length - 1 ? (
          ""
        ) : (
          <div>
            {activeStep}
            <br></br>
            <span onClick={() => handleNext()} style={{ cursor: "pointer" }}>
              Next
            </span>
          </div>
        )}

        {activeStep != 0 && (
          <div>
            {activeStep}
            <br></br>
            <span onClick={() => setActiveStep(activeStep - 1)} style={{ cursor: "pointer" }}>
              Prev
            </span>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserModalContent));
