import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../../../../../components/Button/Button.component';
import PermissionCheck from '../../../../../../components/PermissionCheck/PermissionCheck';
import { updateActiveNavItem } from '../../../../../../redux/sideMenu/menu.action';
import { setAddUserTypeModal, setModalWaitingToOpen } from "../../../../../../redux/modals/action";

const ActionButtons = ({ updateActiveNavItem, setAddUserTypeModal }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const openAddUserTypeModal = () => {
    setAddUserTypeModal(true);
  };

  return (
    <>
      <PermissionCheck section={"DASHBOARD"} permissionName={"USER_MANAGEMENT"} actionName={"view"}>
        <Button
          label={t("Manage Users")}
          onClick={() => {
            updateActiveNavItem("users");
            history.push("/app/management");
          }}
          showIcon={false}
          buttonType="secondary"
        />
      </PermissionCheck>
      <PermissionCheck section={"DASHBOARD"} permissionName={"USER_TYPE_MANAGEMENT"} actionName={"add"}>
        <Button
          iconClass="add"
          label={t("Add User Type")}
          onClick={openAddUserTypeModal}
          showIcon={true}
          buttonType="secondary"
        />
      </PermissionCheck>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddUserTypeModal: (data) => dispatch(setAddUserTypeModal(data)),
});

export default connect(null, mapDispatchToProps)(ActionButtons);
