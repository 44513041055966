import styled from "styled-components";
import { InformationIcon } from "../../../assets";

export const ApplyToAllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 35px;
  float: right;
`;

export const StyledLabel = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
`;
export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const StyledCancelButton = styled.button`
  background-color: transparent;
  margin-right: 10px;
  border-radius: 0.2rem;
  border: none;
  height: 40px;
  width: 110px;
  box-shadow: none;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ColWrapper = styled.div`
  flex: 2;
`;

export const ColDiv = styled.div`
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    float: left;
    button[title="Cancel"] {
      float: left;
    }

    button[title="Update"] {
      float: right;
    }
    button[title="Save"] {
      float: right;
    }
  }
`;
export const ButtonReset = styled.div`
  float: left;
`;
