import styled from "styled-components";

export const SearchBoxDiv = styled.div`
  margin-top: 19px;
  width: 100%;
`;

export const SearchRefreshWrapper = styled.div`
  display: flex;
`;
