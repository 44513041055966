import React from "react";

const UserTypeAccessLevelView = ({ type }) => {
  switch (type) {
    case "ALL_ACCOUNTS":
      return <span>All Accounts</span>;

    case "MULTIPLE_ACCOUNTS":
      return <span>Multiple Accounts</span>;
    case "ALL_WORKSITES":
      return <span>All Worksites</span>;
    case "MULTIPLE_WORKSITES":
      return <span>Multiple Worksites</span>;

    case "SINGLE_WORKSITE":
      return <span>Single Worksite</span>;

    case "INDIVIDUAL_EMPLOYEE":
      return <span>Individual Employee</span>;

    default:
      return <></>;
  }
};

export default UserTypeAccessLevelView;
