import { accountManagementTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";

//To get all account types
const getAccountLoading = () => ({
  type: accountManagementTypes.GET_ALL_ACCOUNT_LOADING,
});

const getAccountSuccess = (data, isResetState, pageNo) => ({
  type: accountManagementTypes.GET_ALL_ACCOUNT_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getAccountError = (err) => ({
  type: accountManagementTypes.GET_ALL_ACCOUNT_ERROR,
  err,
});

export const getAccounts = (paginationData, isResetState) => {
  return async (dispatch, getState) => {
    dispatch(getAccountLoading());
    const { pageNo, pageSize, searchKey } = paginationData;
    const url = `/account/list/${pageNo}/${pageSize}/${searchKey ? encodeURI(searchKey) : "all"}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getAccountSuccess(cacheResult1, isResetState, pageNo));
        return;
      }
    }
    apiCall(url)
      .then((resp) => {
        //console.log(resp);
        if (cache) {
          if (resp?.data) {
            cache.set(url, resp.data);
          }
        }
        dispatch(getAccountSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getAccountError(err));
        console.log(err);
      });
  };
};

//To get acount detail
const getAccountDetailLoading = () => ({
  type: accountManagementTypes.GET_ACCOUNT_DETAIL_LOADING,
});

const getAccountDetailSuccess = (data) => ({
  type: accountManagementTypes.GET_ACCOUNT_DETAIL_SUCCESS,
  data,
});

const getAccountDetailError = (err) => ({
  type: accountManagementTypes.GET_ACCOUNT_DETAIL_ERROR,
  err,
});

export const getAccountDetailById = (accountId) => {
  return async (dispatch, getState) => {
    dispatch(getAccountDetailLoading());
    const url = `/account/${accountId}`;
    apiCall(url)
      .then((resp) => {
        console.log(resp);
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        dispatch(getAccountDetailSuccess(resp.data?.data));
      })
      .catch((err) => {
        dispatch(getAccountDetailError(err));
        console.log(err);
      });
  };
};

// To add account
const addAccountLoading = () => ({
  type: accountManagementTypes.ADD_ACCOUNT_LOADING,
});

const addAccountSuccess = (data) => ({
  type: accountManagementTypes.ADD_ACCOUNT_SUCCESS,
  data,
});

const addAccountError = (err) => ({
  type: accountManagementTypes.ADD_ACCOUNT_ERROR,
  err,
});

export const addAccounts = (accountId, data, setEditOverview) => {
  return async (dispatch, getState) => {
    dispatch(addAccountLoading());
    try {
      const resp = await apiCall(`/account/signup`, {
        method: "POST",
        data: data,
      });
      setEditOverview(true);
      let { _id, companyName, unitType, logo, enterpriseUrl } = resp.data.data;

      await CacheRefs.removeCacheForAccounts();

      dispatch(
        addAccountSuccess({
          id: _id,
          companyName,
          unitType,
          logo,
          enterpriseUrl,
        })
      );
    } catch (err) {
      dispatch(addAccountError(err));
      console.log(err);
    }
  };
};

// To update account
const updateAccountLoading = () => ({
  type: accountManagementTypes.UPDATE_ACCOUNT_LOADING,
});

export const updateAccountSuccess = (data) => ({
  type: accountManagementTypes.UPDATE_ACCOUNT_SUCCESS,
  data,
});

const updateAccountError = (err) => ({
  type: accountManagementTypes.UPDATE_ACCOUNT_ERROR,
  err,
});

export const resetAccountNameState = () => ({
  type: accountManagementTypes.RESET_ACCOUNT_NAME_STATE,
});

export const updateAccounts = (accountId, data, setEditOverview) => {
  return async (dispatch, getState) => {
    dispatch(updateAccountLoading());

    try {
      const resp = await apiCall(`/account/${accountId}`, {
        method: "PUT",
        data: data,
      });
      setEditOverview(true);
      let { _id, companyName, unitType, logo, enterpriseUrl, timeZone } = resp.data.data;
      await CacheRefs.removeCacheForAccounts();
      dispatch(getAccountDetailById(accountId));
      dispatch(
        updateAccountSuccess({
          id: _id,
          companyName,
          unitType,
          logo,
          enterpriseUrl,
          timeZone,
        })
      );
    } catch (err) {
      dispatch(updateAccountError(err));
      console.log(err);
    }
  };
};

export const selectAccount = (id) => ({
  type: accountManagementTypes.SELECT_ACCOUNT,
  id: id,
});

export const resetPasswordPolicy = () => ({
  type: accountManagementTypes.RESET_PASSWORD_POLICY,
});

export const getPasswordPolicy = (userAccountId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: accountManagementTypes.PASSWORD_POLICY_LOADING,
    });

    //state?.user?.loginDetails?.accountId
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const accountId = userAccountId
      ? userAccountId
      : isSuperAdmin
        ? state?.dropdownFilters?.accountId
        : state?.user?.loginDetails?.accountId;
    const url = `/account/passwordPolicy/${accountId}`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult = await cache.get(url);
      if (cacheResult) {
        dispatch(getAccountSuccess(cacheResult));
        return;
      }
    }

    apiCall(url)
      .then((response) => {
        dispatch({
          type: accountManagementTypes.PASSWORD_POLICY_SUCCESS,
          data: !response.data?.data
            ? { message: "Error occurred while retrieving password policy" }
            : response.data?.data,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: accountManagementTypes.PASSWORD_POLICY_ERROR,
          err,
        });
      });
  };
};

export const resetUpdatePasswordPolicyFlags = () => {
  return (dispatch) => {
    dispatch({
      type: accountManagementTypes.RESET_UPDATE_PASSWORD_POLICY_FLAGS,
    });
  };
};

export const updatePasswordPolicy = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: accountManagementTypes.UPDATE_PASSWORD_POLICY_LOADING,
    });
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const accountId = isSuperAdmin
      ? state?.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    const url = `/account/passwordPolicy/${accountId}`;

    apiCall(url, {
      method: "PUT",
      data: data,
    })
      .then((response) => {
        if (response.data?.success) {
          dispatch({
            type: accountManagementTypes.UPDATE_PASSWORD_POLICY_SUCCESS,
            data: response.data?.data,
          });
        } else {
          dispatch({
            type: accountManagementTypes.UPDATE_PASSWORD_POLICY_ERROR,
            err: response.data?.error,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: accountManagementTypes.UPDATE_PASSWORD_POLICY_ERROR,
          err,
        });
      });
  };
};
