import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { NoAssetWrapper } from "./NoAssetFound.styles";

const NoSettingsDataFound = ({ type, isSuperAdmin, dropdownAccountId, settingsDropdownAccountId }) => {
  const { t } = useTranslation();
  return (
    <>
      {(isSuperAdmin ? settingsDropdownAccountId : dropdownAccountId) ? (
        <NoAssetWrapper>
          <p>
            <Trans>No</Trans> {type || t("Asset")} <Trans>Found</Trans>
          </p>
          <p>
            <Trans>Please add</Trans> {type === t("User") ? t("a user") : t("an asset")}{" "}
            <Trans>to view this setting</Trans>
          </p>
        </NoAssetWrapper>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  settingsDropdownAccountId: state.dropdownFilters?.settings?.accountId,
  dropdownAccountId: state.dropdownFilters?.accountId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NoSettingsDataFound);
