const timeZone = [
  {
    value: "Africa/Abidjan",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Abidjan",
    utc: ["Africa/Abidjan"],
    _id: "+00:00 Abidjan",
    label: "Africa/Abidjan  (GMT)",
  },
  {
    value: "Africa/Accra",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Accra",
    utc: ["Africa/Accra"],
    _id: "+00:00 Accra",
    label: "Africa/Accra  (GMT)",
  },
  {
    value: "Africa/Addis_Ababa",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Addis Ababa",
    utc: ["Africa/Addis_Ababa"],
    _id: "+03:00 Addis Ababa",
    label: "Africa/Addis_Ababa  (EAT)",
  },
  {
    value: "Africa/Algiers",
    short: "CET",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Algiers",
    utc: ["Africa/Algiers"],
    _id: "+01:00 Algiers",
    label: "Africa/Algiers  (CET)",
  },
  {
    value: "Africa/Asmara",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Asmara",
    utc: ["Africa/Asmara"],
    _id: "+03:00 Asmara",
    label: "Africa/Asmara  (EAT)",
  },
  {
    value: "Africa/Asmera",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Asmera",
    utc: ["Africa/Asmera"],
    _id: "+03:00 Asmera",
    label: "Africa/Asmera  (EAT)",
  },
  {
    value: "Africa/Bamako",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Bamako",
    utc: ["Africa/Bamako"],
    _id: "+00:00 Bamako",
    label: "Africa/Bamako  (GMT)",
  },
  {
    value: "Africa/Bangui",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Bangui",
    utc: ["Africa/Bangui"],
    _id: "+01:00 Bangui",
    label: "Africa/Bangui  (WAT)",
  },
  {
    value: "Africa/Banjul",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Banjul",
    utc: ["Africa/Banjul"],
    _id: "+00:00 Banjul",
    label: "Africa/Banjul  (GMT)",
  },
  {
    value: "Africa/Bissau",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Bissau",
    utc: ["Africa/Bissau"],
    _id: "+00:00 Bissau",
    label: "Africa/Bissau  (GMT)",
  },
  {
    value: "Africa/Blantyre",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Blantyre",
    utc: ["Africa/Blantyre"],
    _id: "+02:00 Blantyre",
    label: "Africa/Blantyre  (CAT)",
  },
  {
    value: "Africa/Brazzaville",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Brazzaville",
    utc: ["Africa/Brazzaville"],
    _id: "+01:00 Brazzaville",
    label: "Africa/Brazzaville  (WAT)",
  },
  {
    value: "Africa/Bujumbura",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Bujumbura",
    utc: ["Africa/Bujumbura"],
    _id: "+02:00 Bujumbura",
    label: "Africa/Bujumbura  (CAT)",
  },
  {
    value: "Africa/Cairo",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Africa/Cairo",
    utc: ["Africa/Cairo"],
    _id: "+03:00 Cairo",
    label: "Africa/Cairo  (EEST)",
  },
  {
    value: "Africa/Casablanca",
    short: "UTC+01",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Casablanca",
    utc: ["Africa/Casablanca"],
    _id: "+01:00 Casablanca",
    label: "Africa/Casablanca  (UTC+01)",
  },
  {
    value: "Africa/Ceuta",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Africa/Ceuta",
    utc: ["Africa/Ceuta"],
    _id: "+02:00 Ceuta",
    label: "Africa/Ceuta  (CEST)",
  },
  {
    value: "Africa/Conakry",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Conakry",
    utc: ["Africa/Conakry"],
    _id: "+00:00 Conakry",
    label: "Africa/Conakry  (GMT)",
  },
  {
    value: "Africa/Dakar",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Dakar",
    utc: ["Africa/Dakar"],
    _id: "+00:00 Dakar",
    label: "Africa/Dakar  (GMT)",
  },
  {
    value: "Africa/Dar_es_Salaam",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Dar es_Salaam",
    utc: ["Africa/Dar_es_Salaam"],
    _id: "+03:00 Dar es_Salaam",
    label: "Africa/Dar_es_Salaam  (EAT)",
  },
  {
    value: "Africa/Djibouti",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Djibouti",
    utc: ["Africa/Djibouti"],
    _id: "+03:00 Djibouti",
    label: "Africa/Djibouti  (EAT)",
  },
  {
    value: "Africa/Douala",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Douala",
    utc: ["Africa/Douala"],
    _id: "+01:00 Douala",
    label: "Africa/Douala  (WAT)",
  },
  {
    value: "Africa/El_Aaiun",
    short: "UTC+01",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/El Aaiun",
    utc: ["Africa/El_Aaiun"],
    _id: "+01:00 El Aaiun",
    label: "Africa/El_Aaiun  (UTC+01)",
  },
  {
    value: "Africa/Freetown",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Freetown",
    utc: ["Africa/Freetown"],
    _id: "+00:00 Freetown",
    label: "Africa/Freetown  (GMT)",
  },
  {
    value: "Africa/Gaborone",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Gaborone",
    utc: ["Africa/Gaborone"],
    _id: "+02:00 Gaborone",
    label: "Africa/Gaborone  (CAT)",
  },
  {
    value: "Africa/Harare",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Harare",
    utc: ["Africa/Harare"],
    _id: "+02:00 Harare",
    label: "Africa/Harare  (CAT)",
  },
  {
    value: "Africa/Johannesburg",
    short: "SAST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Johannesburg",
    utc: ["Africa/Johannesburg"],
    _id: "+02:00 Johannesburg",
    label: "Africa/Johannesburg  (SAST)",
  },
  {
    value: "Africa/Juba",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Juba",
    utc: ["Africa/Juba"],
    _id: "+02:00 Juba",
    label: "Africa/Juba  (CAT)",
  },
  {
    value: "Africa/Kampala",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Kampala",
    utc: ["Africa/Kampala"],
    _id: "+03:00 Kampala",
    label: "Africa/Kampala  (EAT)",
  },
  {
    value: "Africa/Khartoum",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Khartoum",
    utc: ["Africa/Khartoum"],
    _id: "+02:00 Khartoum",
    label: "Africa/Khartoum  (CAT)",
  },
  {
    value: "Africa/Kigali",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Kigali",
    utc: ["Africa/Kigali"],
    _id: "+02:00 Kigali",
    label: "Africa/Kigali  (CAT)",
  },
  {
    value: "Africa/Kinshasa",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Kinshasa",
    utc: ["Africa/Kinshasa"],
    _id: "+01:00 Kinshasa",
    label: "Africa/Kinshasa  (WAT)",
  },
  {
    value: "Africa/Lagos",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Lagos",
    utc: ["Africa/Lagos"],
    _id: "+01:00 Lagos",
    label: "Africa/Lagos  (WAT)",
  },
  {
    value: "Africa/Libreville",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Libreville",
    utc: ["Africa/Libreville"],
    _id: "+01:00 Libreville",
    label: "Africa/Libreville  (WAT)",
  },
  {
    value: "Africa/Lome",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Lome",
    utc: ["Africa/Lome"],
    _id: "+00:00 Lome",
    label: "Africa/Lome  (GMT)",
  },
  {
    value: "Africa/Luanda",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Luanda",
    utc: ["Africa/Luanda"],
    _id: "+01:00 Luanda",
    label: "Africa/Luanda  (WAT)",
  },
  {
    value: "Africa/Lubumbashi",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Lubumbashi",
    utc: ["Africa/Lubumbashi"],
    _id: "+02:00 Lubumbashi",
    label: "Africa/Lubumbashi  (CAT)",
  },
  {
    value: "Africa/Lusaka",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Lusaka",
    utc: ["Africa/Lusaka"],
    _id: "+02:00 Lusaka",
    label: "Africa/Lusaka  (CAT)",
  },
  {
    value: "Africa/Malabo",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Malabo",
    utc: ["Africa/Malabo"],
    _id: "+01:00 Malabo",
    label: "Africa/Malabo  (WAT)",
  },
  {
    value: "Africa/Maputo",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Maputo",
    utc: ["Africa/Maputo"],
    _id: "+02:00 Maputo",
    label: "Africa/Maputo  (CAT)",
  },
  {
    value: "Africa/Maseru",
    short: "SAST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Maseru",
    utc: ["Africa/Maseru"],
    _id: "+02:00 Maseru",
    label: "Africa/Maseru  (SAST)",
  },
  {
    value: "Africa/Mbabane",
    short: "SAST",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Mbabane",
    utc: ["Africa/Mbabane"],
    _id: "+02:00 Mbabane",
    label: "Africa/Mbabane  (SAST)",
  },
  {
    value: "Africa/Mogadishu",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Mogadishu",
    utc: ["Africa/Mogadishu"],
    _id: "+03:00 Mogadishu",
    label: "Africa/Mogadishu  (EAT)",
  },
  {
    value: "Africa/Monrovia",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Monrovia",
    utc: ["Africa/Monrovia"],
    _id: "+00:00 Monrovia",
    label: "Africa/Monrovia  (GMT)",
  },
  {
    value: "Africa/Nairobi",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Africa/Nairobi",
    utc: ["Africa/Nairobi"],
    _id: "+03:00 Nairobi",
    label: "Africa/Nairobi  (EAT)",
  },
  {
    value: "Africa/Ndjamena",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Ndjamena",
    utc: ["Africa/Ndjamena"],
    _id: "+01:00 Ndjamena",
    label: "Africa/Ndjamena  (WAT)",
  },
  {
    value: "Africa/Niamey",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Niamey",
    utc: ["Africa/Niamey"],
    _id: "+01:00 Niamey",
    label: "Africa/Niamey  (WAT)",
  },
  {
    value: "Africa/Nouakchott",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Nouakchott",
    utc: ["Africa/Nouakchott"],
    _id: "+00:00 Nouakchott",
    label: "Africa/Nouakchott  (GMT)",
  },
  {
    value: "Africa/Ouagadougou",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Ouagadougou",
    utc: ["Africa/Ouagadougou"],
    _id: "+00:00 Ouagadougou",
    label: "Africa/Ouagadougou  (GMT)",
  },
  {
    value: "Africa/Porto-Novo",
    short: "WAT",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Porto-Novo",
    utc: ["Africa/Porto-Novo"],
    _id: "+01:00 Porto-Novo",
    label: "Africa/Porto-Novo  (WAT)",
  },
  {
    value: "Africa/Sao_Tome",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Sao Tome",
    utc: ["Africa/Sao_Tome"],
    _id: "+00:00 Sao Tome",
    label: "Africa/Sao_Tome  (GMT)",
  },
  {
    value: "Africa/Timbuktu",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Africa/Timbuktu",
    utc: ["Africa/Timbuktu"],
    _id: "+00:00 Timbuktu",
    label: "Africa/Timbuktu  (GMT)",
  },
  {
    value: "Africa/Tripoli",
    short: "EET",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Tripoli",
    utc: ["Africa/Tripoli"],
    _id: "+02:00 Tripoli",
    label: "Africa/Tripoli  (EET)",
  },
  {
    value: "Africa/Tunis",
    short: "CET",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Africa/Tunis",
    utc: ["Africa/Tunis"],
    _id: "+01:00 Tunis",
    label: "Africa/Tunis  (CET)",
  },
  {
    value: "Africa/Windhoek",
    short: "CAT",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Africa/Windhoek",
    utc: ["Africa/Windhoek"],
    _id: "+02:00 Windhoek",
    label: "Africa/Windhoek  (CAT)",
  },
  {
    value: "America/Adak",
    short: "HDT",
    offset: -9,
    isDST: true,
    text: "(UTC-09:00) America/Adak",
    utc: ["America/Adak"],
    _id: "-09:00 Adak",
    label: "America/Adak  (HDT)",
  },
  {
    value: "America/Anchorage",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Anchorage",
    utc: ["America/Anchorage"],
    _id: "-08:00 Anchorage",
    label: "America/Anchorage  (AKDT)",
  },
  {
    value: "America/Anguilla",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Anguilla",
    utc: ["America/Anguilla"],
    _id: "-04:00 Anguilla",
    label: "America/Anguilla  (AST)",
  },
  {
    value: "America/Antigua",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Antigua",
    utc: ["America/Antigua"],
    _id: "-04:00 Antigua",
    label: "America/Antigua  (AST)",
  },
  {
    value: "America/Araguaina",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Araguaina",
    utc: ["America/Araguaina"],
    _id: "-03:00 Araguaina",
    label: "America/Araguaina  (UTC-03)",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Buenos Aires",
    utc: ["America/Argentina/Buenos_Aires"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Buenos_Aires  (UTC-03)",
  },
  {
    value: "America/Argentina/Catamarca",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Catamarca",
    utc: ["America/Argentina/Catamarca"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Catamarca  (UTC-03)",
  },
  {
    value: "America/Argentina/ComodRivadavia",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/ComodRivadavia",
    utc: ["America/Argentina/ComodRivadavia"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/ComodRivadavia  (UTC-03)",
  },
  {
    value: "America/Argentina/Cordoba",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Cordoba",
    utc: ["America/Argentina/Cordoba"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Cordoba  (UTC-03)",
  },
  {
    value: "America/Argentina/Jujuy",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Jujuy",
    utc: ["America/Argentina/Jujuy"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Jujuy  (UTC-03)",
  },
  {
    value: "America/Argentina/La_Rioja",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/La Rioja",
    utc: ["America/Argentina/La_Rioja"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/La_Rioja  (UTC-03)",
  },
  {
    value: "America/Argentina/Mendoza",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Mendoza",
    utc: ["America/Argentina/Mendoza"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Mendoza  (UTC-03)",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Rio Gallegos",
    utc: ["America/Argentina/Rio_Gallegos"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Rio_Gallegos  (UTC-03)",
  },
  {
    value: "America/Argentina/Salta",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Salta",
    utc: ["America/Argentina/Salta"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Salta  (UTC-03)",
  },
  {
    value: "America/Argentina/San_Juan",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/San Juan",
    utc: ["America/Argentina/San_Juan"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/San_Juan  (UTC-03)",
  },
  {
    value: "America/Argentina/San_Luis",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/San Luis",
    utc: ["America/Argentina/San_Luis"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/San_Luis  (UTC-03)",
  },
  {
    value: "America/Argentina/Tucuman",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Tucuman",
    utc: ["America/Argentina/Tucuman"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Tucuman  (UTC-03)",
  },
  {
    value: "America/Argentina/Ushuaia",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Argentina/Ushuaia",
    utc: ["America/Argentina/Ushuaia"],
    _id: "-03:00 Argentina",
    label: "America/Argentina/Ushuaia  (UTC-03)",
  },
  {
    value: "America/Aruba",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Aruba",
    utc: ["America/Aruba"],
    _id: "-04:00 Aruba",
    label: "America/Aruba  (AST)",
  },
  {
    value: "America/Asuncion",
    short: "UTC-03",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Asuncion",
    utc: ["America/Asuncion"],
    _id: "-03:00 Asuncion",
    label: "America/Asuncion  (UTC-03)",
  },
  {
    value: "America/Atikokan",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Atikokan",
    utc: ["America/Atikokan"],
    _id: "-05:00 Atikokan",
    label: "America/Atikokan  (EST)",
  },
  {
    value: "America/Atka",
    short: "HDT",
    offset: -9,
    isDST: true,
    text: "(UTC-09:00) America/Atka",
    utc: ["America/Atka"],
    _id: "-09:00 Atka",
    label: "America/Atka  (HDT)",
  },
  {
    value: "America/Bahia",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Bahia",
    utc: ["America/Bahia"],
    _id: "-03:00 Bahia",
    label: "America/Bahia  (UTC-03)",
  },
  {
    value: "America/Bahia_Banderas",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Bahia Banderas",
    utc: ["America/Bahia_Banderas"],
    _id: "-06:00 Bahia Banderas",
    label: "America/Bahia_Banderas  (CST)",
  },
  {
    value: "America/Barbados",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Barbados",
    utc: ["America/Barbados"],
    _id: "-04:00 Barbados",
    label: "America/Barbados  (AST)",
  },
  {
    value: "America/Belem",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Belem",
    utc: ["America/Belem"],
    _id: "-03:00 Belem",
    label: "America/Belem  (UTC-03)",
  },
  {
    value: "America/Belize",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Belize",
    utc: ["America/Belize"],
    _id: "-06:00 Belize",
    label: "America/Belize  (CST)",
  },
  {
    value: "America/Blanc-Sablon",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Blanc-Sablon",
    utc: ["America/Blanc-Sablon"],
    _id: "-04:00 Blanc-Sablon",
    label: "America/Blanc-Sablon  (AST)",
  },
  {
    value: "America/Boa_Vista",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Boa Vista",
    utc: ["America/Boa_Vista"],
    _id: "-04:00 Boa Vista",
    label: "America/Boa_Vista  (UTC-04)",
  },
  {
    value: "America/Bogota",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Bogota",
    utc: ["America/Bogota"],
    _id: "-05:00 Bogota",
    label: "America/Bogota  (UTC-05)",
  },
  {
    value: "America/Boise",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Boise",
    utc: ["America/Boise"],
    _id: "-06:00 Boise",
    label: "America/Boise  (MDT)",
  },
  {
    value: "America/Buenos_Aires",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Buenos Aires",
    utc: ["America/Buenos_Aires"],
    _id: "-03:00 Buenos Aires",
    label: "America/Buenos_Aires  (UTC-03)",
  },
  {
    value: "America/Cambridge_Bay",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Cambridge Bay",
    utc: ["America/Cambridge_Bay"],
    _id: "-06:00 Cambridge Bay",
    label: "America/Cambridge_Bay  (MDT)",
  },
  {
    value: "America/Campo_Grande",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Campo Grande",
    utc: ["America/Campo_Grande"],
    _id: "-04:00 Campo Grande",
    label: "America/Campo_Grande  (UTC-04)",
  },
  {
    value: "America/Cancun",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Cancun",
    utc: ["America/Cancun"],
    _id: "-05:00 Cancun",
    label: "America/Cancun  (EST)",
  },
  {
    value: "America/Caracas",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Caracas",
    utc: ["America/Caracas"],
    _id: "-04:00 Caracas",
    label: "America/Caracas  (UTC-04)",
  },
  {
    value: "America/Catamarca",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Catamarca",
    utc: ["America/Catamarca"],
    _id: "-03:00 Catamarca",
    label: "America/Catamarca  (UTC-03)",
  },
  {
    value: "America/Cayenne",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Cayenne",
    utc: ["America/Cayenne"],
    _id: "-03:00 Cayenne",
    label: "America/Cayenne  (UTC-03)",
  },
  {
    value: "America/Cayman",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Cayman",
    utc: ["America/Cayman"],
    _id: "-05:00 Cayman",
    label: "America/Cayman  (EST)",
  },
  {
    value: "America/Chicago",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Chicago",
    utc: ["America/Chicago"],
    _id: "-05:00 Chicago",
    label: "America/Chicago  (CDT)",
  },
  {
    value: "America/Chihuahua",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Chihuahua",
    utc: ["America/Chihuahua"],
    _id: "-06:00 Chihuahua",
    label: "America/Chihuahua  (CST)",
  },
  {
    value: "America/Ciudad_Juarez",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Ciudad Juarez",
    utc: ["America/Ciudad_Juarez"],
    _id: "-06:00 Ciudad Juarez",
    label: "America/Ciudad_Juarez  (MDT)",
  },
  {
    value: "America/Coral_Harbour",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Coral Harbour",
    utc: ["America/Coral_Harbour"],
    _id: "-05:00 Coral Harbour",
    label: "America/Coral_Harbour  (EST)",
  },
  {
    value: "America/Cordoba",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Cordoba",
    utc: ["America/Cordoba"],
    _id: "-03:00 Cordoba",
    label: "America/Cordoba  (UTC-03)",
  },
  {
    value: "America/Costa_Rica",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Costa Rica",
    utc: ["America/Costa_Rica"],
    _id: "-06:00 Costa Rica",
    label: "America/Costa_Rica  (CST)",
  },
  {
    value: "America/Creston",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Creston",
    utc: ["America/Creston"],
    _id: "-07:00 Creston",
    label: "America/Creston  (MST)",
  },
  {
    value: "America/Cuiaba",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Cuiaba",
    utc: ["America/Cuiaba"],
    _id: "-04:00 Cuiaba",
    label: "America/Cuiaba  (UTC-04)",
  },
  {
    value: "America/Curacao",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Curacao",
    utc: ["America/Curacao"],
    _id: "-04:00 Curacao",
    label: "America/Curacao  (AST)",
  },
  {
    value: "America/Danmarkshavn",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) America/Danmarkshavn",
    utc: ["America/Danmarkshavn"],
    _id: "+00:00 Danmarkshavn",
    label: "America/Danmarkshavn  (GMT)",
  },
  {
    value: "America/Dawson",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Dawson",
    utc: ["America/Dawson"],
    _id: "-07:00 Dawson",
    label: "America/Dawson  (MST)",
  },
  {
    value: "America/Dawson_Creek",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Dawson Creek",
    utc: ["America/Dawson_Creek"],
    _id: "-07:00 Dawson Creek",
    label: "America/Dawson_Creek  (MST)",
  },
  {
    value: "America/Denver",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Denver",
    utc: ["America/Denver"],
    _id: "-06:00 Denver",
    label: "America/Denver  (MDT)",
  },
  {
    value: "America/Detroit",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Detroit",
    utc: ["America/Detroit"],
    _id: "-04:00 Detroit",
    label: "America/Detroit  (EDT)",
  },
  {
    value: "America/Dominica",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Dominica",
    utc: ["America/Dominica"],
    _id: "-04:00 Dominica",
    label: "America/Dominica  (AST)",
  },
  {
    value: "America/Edmonton",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Edmonton",
    utc: ["America/Edmonton"],
    _id: "-06:00 Edmonton",
    label: "America/Edmonton  (MDT)",
  },
  {
    value: "America/Eirunepe",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Eirunepe",
    utc: ["America/Eirunepe"],
    _id: "-05:00 Eirunepe",
    label: "America/Eirunepe  (UTC-05)",
  },
  {
    value: "America/El_Salvador",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/El Salvador",
    utc: ["America/El_Salvador"],
    _id: "-06:00 El Salvador",
    label: "America/El_Salvador  (CST)",
  },
  {
    value: "America/Ensenada",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) America/Ensenada",
    utc: ["America/Ensenada"],
    _id: "-07:00 Ensenada",
    label: "America/Ensenada  (PDT)",
  },
  {
    value: "America/Fort_Nelson",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Fort Nelson",
    utc: ["America/Fort_Nelson"],
    _id: "-07:00 Fort Nelson",
    label: "America/Fort_Nelson  (MST)",
  },
  {
    value: "America/Fort_Wayne",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Fort Wayne",
    utc: ["America/Fort_Wayne"],
    _id: "-04:00 Fort Wayne",
    label: "America/Fort_Wayne  (EDT)",
  },
  {
    value: "America/Fortaleza",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Fortaleza",
    utc: ["America/Fortaleza"],
    _id: "-03:00 Fortaleza",
    label: "America/Fortaleza  (UTC-03)",
  },
  {
    value: "America/Glace_Bay",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Glace Bay",
    utc: ["America/Glace_Bay"],
    _id: "-03:00 Glace Bay",
    label: "America/Glace_Bay  (ADT)",
  },
  {
    value: "America/Godthab",
    short: "UTC-01",
    offset: -1,
    isDST: true,
    text: "(UTC-01:00) America/Godthab",
    utc: ["America/Godthab"],
    _id: "-01:00 Godthab",
    label: "America/Godthab  (UTC-01)",
  },
  {
    value: "America/Goose_Bay",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Goose Bay",
    utc: ["America/Goose_Bay"],
    _id: "-03:00 Goose Bay",
    label: "America/Goose_Bay  (ADT)",
  },
  {
    value: "America/Grand_Turk",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Grand Turk",
    utc: ["America/Grand_Turk"],
    _id: "-04:00 Grand Turk",
    label: "America/Grand_Turk  (EDT)",
  },
  {
    value: "America/Grenada",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Grenada",
    utc: ["America/Grenada"],
    _id: "-04:00 Grenada",
    label: "America/Grenada  (AST)",
  },
  {
    value: "America/Guadeloupe",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Guadeloupe",
    utc: ["America/Guadeloupe"],
    _id: "-04:00 Guadeloupe",
    label: "America/Guadeloupe  (AST)",
  },
  {
    value: "America/Guatemala",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Guatemala",
    utc: ["America/Guatemala"],
    _id: "-06:00 Guatemala",
    label: "America/Guatemala  (CST)",
  },
  {
    value: "America/Guayaquil",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Guayaquil",
    utc: ["America/Guayaquil"],
    _id: "-05:00 Guayaquil",
    label: "America/Guayaquil  (UTC-05)",
  },
  {
    value: "America/Guyana",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Guyana",
    utc: ["America/Guyana"],
    _id: "-04:00 Guyana",
    label: "America/Guyana  (UTC-04)",
  },
  {
    value: "America/Halifax",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Halifax",
    utc: ["America/Halifax"],
    _id: "-03:00 Halifax",
    label: "America/Halifax  (ADT)",
  },
  {
    value: "America/Havana",
    short: "CDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Havana",
    utc: ["America/Havana"],
    _id: "-04:00 Havana",
    label: "America/Havana  (CDT)",
  },
  {
    value: "America/Hermosillo",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Hermosillo",
    utc: ["America/Hermosillo"],
    _id: "-07:00 Hermosillo",
    label: "America/Hermosillo  (MST)",
  },
  {
    value: "America/Indiana/Indianapolis",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Indianapolis",
    utc: ["America/Indiana/Indianapolis"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Indianapolis  (EDT)",
  },
  {
    value: "America/Indiana/Knox",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Indiana/Knox",
    utc: ["America/Indiana/Knox"],
    _id: "-05:00 Indiana",
    label: "America/Indiana/Knox  (CDT)",
  },
  {
    value: "America/Indiana/Marengo",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Marengo",
    utc: ["America/Indiana/Marengo"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Marengo  (EDT)",
  },
  {
    value: "America/Indiana/Petersburg",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Petersburg",
    utc: ["America/Indiana/Petersburg"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Petersburg  (EDT)",
  },
  {
    value: "America/Indiana/Tell_City",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Indiana/Tell City",
    utc: ["America/Indiana/Tell_City"],
    _id: "-05:00 Indiana",
    label: "America/Indiana/Tell_City  (CDT)",
  },
  {
    value: "America/Indiana/Vevay",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Vevay",
    utc: ["America/Indiana/Vevay"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Vevay  (EDT)",
  },
  {
    value: "America/Indiana/Vincennes",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Vincennes",
    utc: ["America/Indiana/Vincennes"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Vincennes  (EDT)",
  },
  {
    value: "America/Indiana/Winamac",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indiana/Winamac",
    utc: ["America/Indiana/Winamac"],
    _id: "-04:00 Indiana",
    label: "America/Indiana/Winamac  (EDT)",
  },
  {
    value: "America/Indianapolis",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Indianapolis",
    utc: ["America/Indianapolis"],
    _id: "-04:00 Indianapolis",
    label: "America/Indianapolis  (EDT)",
  },
  {
    value: "America/Inuvik",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Inuvik",
    utc: ["America/Inuvik"],
    _id: "-06:00 Inuvik",
    label: "America/Inuvik  (MDT)",
  },
  {
    value: "America/Iqaluit",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Iqaluit",
    utc: ["America/Iqaluit"],
    _id: "-04:00 Iqaluit",
    label: "America/Iqaluit  (EDT)",
  },
  {
    value: "America/Jamaica",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Jamaica",
    utc: ["America/Jamaica"],
    _id: "-05:00 Jamaica",
    label: "America/Jamaica  (EST)",
  },
  {
    value: "America/Jujuy",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Jujuy",
    utc: ["America/Jujuy"],
    _id: "-03:00 Jujuy",
    label: "America/Jujuy  (UTC-03)",
  },
  {
    value: "America/Juneau",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Juneau",
    utc: ["America/Juneau"],
    _id: "-08:00 Juneau",
    label: "America/Juneau  (AKDT)",
  },
  {
    value: "America/Kentucky/Louisville",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Kentucky/Louisville",
    utc: ["America/Kentucky/Louisville"],
    _id: "-04:00 Kentucky",
    label: "America/Kentucky/Louisville  (EDT)",
  },
  {
    value: "America/Kentucky/Monticello",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Kentucky/Monticello",
    utc: ["America/Kentucky/Monticello"],
    _id: "-04:00 Kentucky",
    label: "America/Kentucky/Monticello  (EDT)",
  },
  {
    value: "America/Knox_IN",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Knox IN",
    utc: ["America/Knox_IN"],
    _id: "-05:00 Knox IN",
    label: "America/Knox_IN  (CDT)",
  },
  {
    value: "America/Kralendijk",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Kralendijk",
    utc: ["America/Kralendijk"],
    _id: "-04:00 Kralendijk",
    label: "America/Kralendijk  (AST)",
  },
  {
    value: "America/La_Paz",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/La Paz",
    utc: ["America/La_Paz"],
    _id: "-04:00 La Paz",
    label: "America/La_Paz  (UTC-04)",
  },
  {
    value: "America/Lima",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Lima",
    utc: ["America/Lima"],
    _id: "-05:00 Lima",
    label: "America/Lima  (UTC-05)",
  },
  {
    value: "America/Los_Angeles",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) America/Los Angeles",
    utc: ["America/Los_Angeles"],
    _id: "-07:00 Los Angeles",
    label: "America/Los_Angeles  (PDT)",
  },
  {
    value: "America/Louisville",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Louisville",
    utc: ["America/Louisville"],
    _id: "-04:00 Louisville",
    label: "America/Louisville  (EDT)",
  },
  {
    value: "America/Lower_Princes",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Lower Princes",
    utc: ["America/Lower_Princes"],
    _id: "-04:00 Lower Princes",
    label: "America/Lower_Princes  (AST)",
  },
  {
    value: "America/Maceio",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Maceio",
    utc: ["America/Maceio"],
    _id: "-03:00 Maceio",
    label: "America/Maceio  (UTC-03)",
  },
  {
    value: "America/Managua",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Managua",
    utc: ["America/Managua"],
    _id: "-06:00 Managua",
    label: "America/Managua  (CST)",
  },
  {
    value: "America/Manaus",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Manaus",
    utc: ["America/Manaus"],
    _id: "-04:00 Manaus",
    label: "America/Manaus  (UTC-04)",
  },
  {
    value: "America/Marigot",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Marigot",
    utc: ["America/Marigot"],
    _id: "-04:00 Marigot",
    label: "America/Marigot  (AST)",
  },
  {
    value: "America/Martinique",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Martinique",
    utc: ["America/Martinique"],
    _id: "-04:00 Martinique",
    label: "America/Martinique  (AST)",
  },
  {
    value: "America/Matamoros",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Matamoros",
    utc: ["America/Matamoros"],
    _id: "-05:00 Matamoros",
    label: "America/Matamoros  (CDT)",
  },
  {
    value: "America/Mazatlan",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Mazatlan",
    utc: ["America/Mazatlan"],
    _id: "-07:00 Mazatlan",
    label: "America/Mazatlan  (MST)",
  },
  {
    value: "America/Mendoza",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Mendoza",
    utc: ["America/Mendoza"],
    _id: "-03:00 Mendoza",
    label: "America/Mendoza  (UTC-03)",
  },
  {
    value: "America/Menominee",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Menominee",
    utc: ["America/Menominee"],
    _id: "-05:00 Menominee",
    label: "America/Menominee  (CDT)",
  },
  {
    value: "America/Merida",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Merida",
    utc: ["America/Merida"],
    _id: "-06:00 Merida",
    label: "America/Merida  (CST)",
  },
  {
    value: "America/Metlakatla",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Metlakatla",
    utc: ["America/Metlakatla"],
    _id: "-08:00 Metlakatla",
    label: "America/Metlakatla  (AKDT)",
  },
  {
    value: "America/Mexico_City",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Mexico City",
    utc: ["America/Mexico_City"],
    _id: "-06:00 Mexico City",
    label: "America/Mexico_City  (CST)",
  },
  {
    value: "America/Miquelon",
    short: "UTC-02",
    offset: -2,
    isDST: true,
    text: "(UTC-02:00) America/Miquelon",
    utc: ["America/Miquelon"],
    _id: "-02:00 Miquelon",
    label: "America/Miquelon  (UTC-02)",
  },
  {
    value: "America/Moncton",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Moncton",
    utc: ["America/Moncton"],
    _id: "-03:00 Moncton",
    label: "America/Moncton  (ADT)",
  },
  {
    value: "America/Monterrey",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Monterrey",
    utc: ["America/Monterrey"],
    _id: "-06:00 Monterrey",
    label: "America/Monterrey  (CST)",
  },
  {
    value: "America/Montevideo",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Montevideo",
    utc: ["America/Montevideo"],
    _id: "-03:00 Montevideo",
    label: "America/Montevideo  (UTC-03)",
  },
  {
    value: "America/Montreal",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Montreal",
    utc: ["America/Montreal"],
    _id: "-04:00 Montreal",
    label: "America/Montreal  (EDT)",
  },
  {
    value: "America/Montserrat",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Montserrat",
    utc: ["America/Montserrat"],
    _id: "-04:00 Montserrat",
    label: "America/Montserrat  (AST)",
  },
  {
    value: "America/Nassau",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Nassau",
    utc: ["America/Nassau"],
    _id: "-04:00 Nassau",
    label: "America/Nassau  (EDT)",
  },
  {
    value: "America/New_York",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/New York",
    utc: ["America/New_York"],
    _id: "-04:00 New York",
    label: "America/New_York  (EDT)",
  },
  {
    value: "America/Nipigon",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Nipigon",
    utc: ["America/Nipigon"],
    _id: "-04:00 Nipigon",
    label: "America/Nipigon  (EDT)",
  },
  {
    value: "America/Nome",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Nome",
    utc: ["America/Nome"],
    _id: "-08:00 Nome",
    label: "America/Nome  (AKDT)",
  },
  {
    value: "America/Noronha",
    short: "UTC-02",
    offset: -2,
    isDST: false,
    text: "(UTC-02:00) America/Noronha",
    utc: ["America/Noronha"],
    _id: "-02:00 Noronha",
    label: "America/Noronha  (UTC-02)",
  },
  {
    value: "America/North_Dakota/Beulah",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/North Dakota/Beulah",
    utc: ["America/North_Dakota/Beulah"],
    _id: "-05:00 North Dakota",
    label: "America/North_Dakota/Beulah  (CDT)",
  },
  {
    value: "America/North_Dakota/Center",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/North Dakota/Center",
    utc: ["America/North_Dakota/Center"],
    _id: "-05:00 North Dakota",
    label: "America/North_Dakota/Center  (CDT)",
  },
  {
    value: "America/North_Dakota/New_Salem",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/North Dakota/New_Salem",
    utc: ["America/North_Dakota/New_Salem"],
    _id: "-05:00 North Dakota",
    label: "America/North_Dakota/New_Salem  (CDT)",
  },
  {
    value: "America/Nuuk",
    short: "UTC-01",
    offset: -1,
    isDST: true,
    text: "(UTC-01:00) America/Nuuk",
    utc: ["America/Nuuk"],
    _id: "-01:00 Nuuk",
    label: "America/Nuuk  (UTC-01)",
  },
  {
    value: "America/Ojinaga",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Ojinaga",
    utc: ["America/Ojinaga"],
    _id: "-05:00 Ojinaga",
    label: "America/Ojinaga  (CDT)",
  },
  {
    value: "America/Panama",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Panama",
    utc: ["America/Panama"],
    _id: "-05:00 Panama",
    label: "America/Panama  (EST)",
  },
  {
    value: "America/Pangnirtung",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Pangnirtung",
    utc: ["America/Pangnirtung"],
    _id: "-04:00 Pangnirtung",
    label: "America/Pangnirtung  (EDT)",
  },
  {
    value: "America/Paramaribo",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Paramaribo",
    utc: ["America/Paramaribo"],
    _id: "-03:00 Paramaribo",
    label: "America/Paramaribo  (UTC-03)",
  },
  {
    value: "America/Phoenix",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Phoenix",
    utc: ["America/Phoenix"],
    _id: "-07:00 Phoenix",
    label: "America/Phoenix  (MST)",
  },
  {
    value: "America/Port-au-Prince",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Port-au-Prince",
    utc: ["America/Port-au-Prince"],
    _id: "-04:00 Port-au-Prince",
    label: "America/Port-au-Prince  (EDT)",
  },
  {
    value: "America/Port_of_Spain",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Port of_Spain",
    utc: ["America/Port_of_Spain"],
    _id: "-04:00 Port of_Spain",
    label: "America/Port_of_Spain  (AST)",
  },
  {
    value: "America/Porto_Acre",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Porto Acre",
    utc: ["America/Porto_Acre"],
    _id: "-05:00 Porto Acre",
    label: "America/Porto_Acre  (UTC-05)",
  },
  {
    value: "America/Porto_Velho",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Porto Velho",
    utc: ["America/Porto_Velho"],
    _id: "-04:00 Porto Velho",
    label: "America/Porto_Velho  (UTC-04)",
  },
  {
    value: "America/Puerto_Rico",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Puerto Rico",
    utc: ["America/Puerto_Rico"],
    _id: "-04:00 Puerto Rico",
    label: "America/Puerto_Rico  (AST)",
  },
  {
    value: "America/Punta_Arenas",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Punta Arenas",
    utc: ["America/Punta_Arenas"],
    _id: "-03:00 Punta Arenas",
    label: "America/Punta_Arenas  (UTC-03)",
  },
  {
    value: "America/Rainy_River",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Rainy River",
    utc: ["America/Rainy_River"],
    _id: "-05:00 Rainy River",
    label: "America/Rainy_River  (CDT)",
  },
  {
    value: "America/Rankin_Inlet",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Rankin Inlet",
    utc: ["America/Rankin_Inlet"],
    _id: "-05:00 Rankin Inlet",
    label: "America/Rankin_Inlet  (CDT)",
  },
  {
    value: "America/Recife",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Recife",
    utc: ["America/Recife"],
    _id: "-03:00 Recife",
    label: "America/Recife  (UTC-03)",
  },
  {
    value: "America/Regina",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Regina",
    utc: ["America/Regina"],
    _id: "-06:00 Regina",
    label: "America/Regina  (CST)",
  },
  {
    value: "America/Resolute",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Resolute",
    utc: ["America/Resolute"],
    _id: "-05:00 Resolute",
    label: "America/Resolute  (CDT)",
  },
  {
    value: "America/Rio_Branco",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) America/Rio Branco",
    utc: ["America/Rio_Branco"],
    _id: "-05:00 Rio Branco",
    label: "America/Rio_Branco  (UTC-05)",
  },
  {
    value: "America/Rosario",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Rosario",
    utc: ["America/Rosario"],
    _id: "-03:00 Rosario",
    label: "America/Rosario  (UTC-03)",
  },
  {
    value: "America/Santa_Isabel",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) America/Santa Isabel",
    utc: ["America/Santa_Isabel"],
    _id: "-07:00 Santa Isabel",
    label: "America/Santa_Isabel  (PDT)",
  },
  {
    value: "America/Santarem",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Santarem",
    utc: ["America/Santarem"],
    _id: "-03:00 Santarem",
    label: "America/Santarem  (UTC-03)",
  },
  {
    value: "America/Santiago",
    short: "UTC-03",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Santiago",
    utc: ["America/Santiago"],
    _id: "-03:00 Santiago",
    label: "America/Santiago  (UTC-03)",
  },
  {
    value: "America/Santo_Domingo",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Santo Domingo",
    utc: ["America/Santo_Domingo"],
    _id: "-04:00 Santo Domingo",
    label: "America/Santo_Domingo  (AST)",
  },
  {
    value: "America/Sao_Paulo",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) America/Sao Paulo",
    utc: ["America/Sao_Paulo"],
    _id: "-03:00 Sao Paulo",
    label: "America/Sao_Paulo  (UTC-03)",
  },
  {
    value: "America/Scoresbysund",
    short: "UTC-01",
    offset: -1,
    isDST: false,
    text: "(UTC-01:00) America/Scoresbysund",
    utc: ["America/Scoresbysund"],
    _id: "-01:00 Scoresbysund",
    label: "America/Scoresbysund  (UTC-01)",
  },
  {
    value: "America/Shiprock",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Shiprock",
    utc: ["America/Shiprock"],
    _id: "-06:00 Shiprock",
    label: "America/Shiprock  (MDT)",
  },
  {
    value: "America/Sitka",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Sitka",
    utc: ["America/Sitka"],
    _id: "-08:00 Sitka",
    label: "America/Sitka  (AKDT)",
  },
  {
    value: "America/St_Barthelemy",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/St Barthelemy",
    utc: ["America/St_Barthelemy"],
    _id: "-04:00 St Barthelemy",
    label: "America/St_Barthelemy  (AST)",
  },
  {
    value: "America/St_Johns",
    short: "NDT",
    offset: -2.5,
    isDST: true,
    text: "(UTC-03:30) America/St Johns",
    utc: ["America/St_Johns"],
    _id: "-03:30 St Johns",
    label: "America/St_Johns  (NDT)",
  },
  {
    value: "America/St_Kitts",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/St Kitts",
    utc: ["America/St_Kitts"],
    _id: "-04:00 St Kitts",
    label: "America/St_Kitts  (AST)",
  },
  {
    value: "America/St_Lucia",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/St Lucia",
    utc: ["America/St_Lucia"],
    _id: "-04:00 St Lucia",
    label: "America/St_Lucia  (AST)",
  },
  {
    value: "America/St_Thomas",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/St Thomas",
    utc: ["America/St_Thomas"],
    _id: "-04:00 St Thomas",
    label: "America/St_Thomas  (AST)",
  },
  {
    value: "America/St_Vincent",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/St Vincent",
    utc: ["America/St_Vincent"],
    _id: "-04:00 St Vincent",
    label: "America/St_Vincent  (AST)",
  },
  {
    value: "America/Swift_Current",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Swift Current",
    utc: ["America/Swift_Current"],
    _id: "-06:00 Swift Current",
    label: "America/Swift_Current  (CST)",
  },
  {
    value: "America/Tegucigalpa",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) America/Tegucigalpa",
    utc: ["America/Tegucigalpa"],
    _id: "-06:00 Tegucigalpa",
    label: "America/Tegucigalpa  (CST)",
  },
  {
    value: "America/Thule",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) America/Thule",
    utc: ["America/Thule"],
    _id: "-03:00 Thule",
    label: "America/Thule  (ADT)",
  },
  {
    value: "America/Thunder_Bay",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Thunder Bay",
    utc: ["America/Thunder_Bay"],
    _id: "-04:00 Thunder Bay",
    label: "America/Thunder_Bay  (EDT)",
  },
  {
    value: "America/Tijuana",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) America/Tijuana",
    utc: ["America/Tijuana"],
    _id: "-07:00 Tijuana",
    label: "America/Tijuana  (PDT)",
  },
  {
    value: "America/Toronto",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) America/Toronto",
    utc: ["America/Toronto"],
    _id: "-04:00 Toronto",
    label: "America/Toronto  (EDT)",
  },
  {
    value: "America/Tortola",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Tortola",
    utc: ["America/Tortola"],
    _id: "-04:00 Tortola",
    label: "America/Tortola  (AST)",
  },
  {
    value: "America/Vancouver",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) America/Vancouver",
    utc: ["America/Vancouver"],
    _id: "-07:00 Vancouver",
    label: "America/Vancouver  (PDT)",
  },
  {
    value: "America/Virgin",
    short: "AST",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) America/Virgin",
    utc: ["America/Virgin"],
    _id: "-04:00 Virgin",
    label: "America/Virgin  (AST)",
  },
  {
    value: "America/Whitehorse",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) America/Whitehorse",
    utc: ["America/Whitehorse"],
    _id: "-07:00 Whitehorse",
    label: "America/Whitehorse  (MST)",
  },
  {
    value: "America/Winnipeg",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) America/Winnipeg",
    utc: ["America/Winnipeg"],
    _id: "-05:00 Winnipeg",
    label: "America/Winnipeg  (CDT)",
  },
  {
    value: "America/Yakutat",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) America/Yakutat",
    utc: ["America/Yakutat"],
    _id: "-08:00 Yakutat",
    label: "America/Yakutat  (AKDT)",
  },
  {
    value: "America/Yellowknife",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) America/Yellowknife",
    utc: ["America/Yellowknife"],
    _id: "-06:00 Yellowknife",
    label: "America/Yellowknife  (MDT)",
  },
  {
    value: "Antarctica/Casey",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Antarctica/Casey",
    utc: ["Antarctica/Casey"],
    _id: "+08:00 Casey",
    label: "Antarctica/Casey  (UTC+08)",
  },
  {
    value: "Antarctica/Davis",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Antarctica/Davis",
    utc: ["Antarctica/Davis"],
    _id: "+07:00 Davis",
    label: "Antarctica/Davis  (UTC+07)",
  },
  {
    value: "Antarctica/DumontDUrville",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Antarctica/DumontDUrville",
    utc: ["Antarctica/DumontDUrville"],
    _id: "+10:00 DumontDUrville",
    label: "Antarctica/DumontDUrville  (UTC+10)",
  },
  {
    value: "Antarctica/Macquarie",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Antarctica/Macquarie",
    utc: ["Antarctica/Macquarie"],
    _id: "+11:00 Macquarie",
    label: "Antarctica/Macquarie  (AEDT)",
  },
  {
    value: "Antarctica/Mawson",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Antarctica/Mawson",
    utc: ["Antarctica/Mawson"],
    _id: "+05:00 Mawson",
    label: "Antarctica/Mawson  (UTC+05)",
  },
  {
    value: "Antarctica/McMurdo",
    short: "NZDT",
    offset: 13,
    isDST: true,
    text: "(UTC+13:00) Antarctica/McMurdo",
    utc: ["Antarctica/McMurdo"],
    _id: "+13:00 McMurdo",
    label: "Antarctica/McMurdo  (NZDT)",
  },
  {
    value: "Antarctica/Palmer",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Antarctica/Palmer",
    utc: ["Antarctica/Palmer"],
    _id: "-03:00 Palmer",
    label: "Antarctica/Palmer  (UTC-03)",
  },
  {
    value: "Antarctica/Rothera",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Antarctica/Rothera",
    utc: ["Antarctica/Rothera"],
    _id: "-03:00 Rothera",
    label: "Antarctica/Rothera  (UTC-03)",
  },
  {
    value: "Antarctica/South_Pole",
    short: "NZDT",
    offset: 13,
    isDST: true,
    text: "(UTC+13:00) Antarctica/South Pole",
    utc: ["Antarctica/South_Pole"],
    _id: "+13:00 South Pole",
    label: "Antarctica/South_Pole  (NZDT)",
  },
  {
    value: "Antarctica/Syowa",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Antarctica/Syowa",
    utc: ["Antarctica/Syowa"],
    _id: "+03:00 Syowa",
    label: "Antarctica/Syowa  (UTC+03)",
  },
  {
    value: "Antarctica/Troll",
    short: "UTC+02",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Antarctica/Troll",
    utc: ["Antarctica/Troll"],
    _id: "+02:00 Troll",
    label: "Antarctica/Troll  (UTC+02)",
  },
  {
    value: "Antarctica/Vostok",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Antarctica/Vostok",
    utc: ["Antarctica/Vostok"],
    _id: "+05:00 Vostok",
    label: "Antarctica/Vostok  (UTC+05)",
  },
  {
    value: "Arctic/Longyearbyen",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Arctic/Longyearbyen",
    utc: ["Arctic/Longyearbyen"],
    _id: "+02:00 Longyearbyen",
    label: "Arctic/Longyearbyen  (CEST)",
  },
  {
    value: "Asia/Aden",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Aden",
    utc: ["Asia/Aden"],
    _id: "+03:00 Aden",
    label: "Asia/Aden  (UTC+03)",
  },
  {
    value: "Asia/Almaty",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Almaty",
    utc: ["Asia/Almaty"],
    _id: "+05:00 Almaty",
    label: "Asia/Almaty  (UTC+05)",
  },
  {
    value: "Asia/Amman",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Amman",
    utc: ["Asia/Amman"],
    _id: "+03:00 Amman",
    label: "Asia/Amman  (UTC+03)",
  },
  {
    value: "Asia/Anadyr",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Asia/Anadyr",
    utc: ["Asia/Anadyr"],
    _id: "+12:00 Anadyr",
    label: "Asia/Anadyr  (UTC+12)",
  },
  {
    value: "Asia/Aqtau",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Aqtau",
    utc: ["Asia/Aqtau"],
    _id: "+05:00 Aqtau",
    label: "Asia/Aqtau  (UTC+05)",
  },
  {
    value: "Asia/Aqtobe",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Aqtobe",
    utc: ["Asia/Aqtobe"],
    _id: "+05:00 Aqtobe",
    label: "Asia/Aqtobe  (UTC+05)",
  },
  {
    value: "Asia/Ashgabat",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Ashgabat",
    utc: ["Asia/Ashgabat"],
    _id: "+05:00 Ashgabat",
    label: "Asia/Ashgabat  (UTC+05)",
  },
  {
    value: "Asia/Ashkhabad",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Ashkhabad",
    utc: ["Asia/Ashkhabad"],
    _id: "+05:00 Ashkhabad",
    label: "Asia/Ashkhabad  (UTC+05)",
  },
  {
    value: "Asia/Atyrau",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Atyrau",
    utc: ["Asia/Atyrau"],
    _id: "+05:00 Atyrau",
    label: "Asia/Atyrau  (UTC+05)",
  },
  {
    value: "Asia/Baghdad",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Baghdad",
    utc: ["Asia/Baghdad"],
    _id: "+03:00 Baghdad",
    label: "Asia/Baghdad  (UTC+03)",
  },
  {
    value: "Asia/Bahrain",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Bahrain",
    utc: ["Asia/Bahrain"],
    _id: "+03:00 Bahrain",
    label: "Asia/Bahrain  (UTC+03)",
  },
  {
    value: "Asia/Baku",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Asia/Baku",
    utc: ["Asia/Baku"],
    _id: "+04:00 Baku",
    label: "Asia/Baku  (UTC+04)",
  },
  {
    value: "Asia/Bangkok",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Bangkok",
    utc: ["Asia/Bangkok"],
    _id: "+07:00 Bangkok",
    label: "Asia/Bangkok  (UTC+07)",
  },
  {
    value: "Asia/Barnaul",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Barnaul",
    utc: ["Asia/Barnaul"],
    _id: "+07:00 Barnaul",
    label: "Asia/Barnaul  (UTC+07)",
  },
  {
    value: "Asia/Beirut",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Beirut",
    utc: ["Asia/Beirut"],
    _id: "+03:00 Beirut",
    label: "Asia/Beirut  (EEST)",
  },
  {
    value: "Asia/Bishkek",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Bishkek",
    utc: ["Asia/Bishkek"],
    _id: "+06:00 Bishkek",
    label: "Asia/Bishkek  (UTC+06)",
  },
  {
    value: "Asia/Brunei",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Brunei",
    utc: ["Asia/Brunei"],
    _id: "+08:00 Brunei",
    label: "Asia/Brunei  (UTC+08)",
  },
  {
    value: "Asia/Calcutta",
    short: "IST",
    offset: 5.5,
    isDST: false,
    text: "(UTC+05:30) Asia/Calcutta",
    utc: ["Asia/Calcutta"],
    _id: "+05:30 Calcutta",
    label: "Asia/Calcutta  (IST)",
  },
  {
    value: "Asia/Chita",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Chita",
    utc: ["Asia/Chita"],
    _id: "+09:00 Chita",
    label: "Asia/Chita  (UTC+09)",
  },
  {
    value: "Asia/Choibalsan",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Choibalsan",
    utc: ["Asia/Choibalsan"],
    _id: "+08:00 Choibalsan",
    label: "Asia/Choibalsan  (UTC+08)",
  },
  {
    value: "Asia/Chongqing",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Chongqing",
    utc: ["Asia/Chongqing"],
    _id: "+08:00 Chongqing",
    label: "Asia/Chongqing  (CST)",
  },
  {
    value: "Asia/Chungking",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Chungking",
    utc: ["Asia/Chungking"],
    _id: "+08:00 Chungking",
    label: "Asia/Chungking  (CST)",
  },
  {
    value: "Asia/Colombo",
    short: "UTC+0530",
    offset: 5.5,
    isDST: false,
    text: "(UTC+05:30) Asia/Colombo",
    utc: ["Asia/Colombo"],
    _id: "+05:30 Colombo",
    label: "Asia/Colombo  (UTC+0530)",
  },
  {
    value: "Asia/Dacca",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Dacca",
    utc: ["Asia/Dacca"],
    _id: "+06:00 Dacca",
    label: "Asia/Dacca  (UTC+06)",
  },
  {
    value: "Asia/Damascus",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Damascus",
    utc: ["Asia/Damascus"],
    _id: "+03:00 Damascus",
    label: "Asia/Damascus  (UTC+03)",
  },
  {
    value: "Asia/Dhaka",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Dhaka",
    utc: ["Asia/Dhaka"],
    _id: "+06:00 Dhaka",
    label: "Asia/Dhaka  (UTC+06)",
  },
  {
    value: "Asia/Dili",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Dili",
    utc: ["Asia/Dili"],
    _id: "+09:00 Dili",
    label: "Asia/Dili  (UTC+09)",
  },
  {
    value: "Asia/Dubai",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Asia/Dubai",
    utc: ["Asia/Dubai"],
    _id: "+04:00 Dubai",
    label: "Asia/Dubai  (UTC+04)",
  },
  {
    value: "Asia/Dushanbe",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Dushanbe",
    utc: ["Asia/Dushanbe"],
    _id: "+05:00 Dushanbe",
    label: "Asia/Dushanbe  (UTC+05)",
  },
  {
    value: "Asia/Famagusta",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Famagusta",
    utc: ["Asia/Famagusta"],
    _id: "+03:00 Famagusta",
    label: "Asia/Famagusta  (EEST)",
  },
  {
    value: "Asia/Gaza",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Gaza",
    utc: ["Asia/Gaza"],
    _id: "+03:00 Gaza",
    label: "Asia/Gaza  (EEST)",
  },
  {
    value: "Asia/Harbin",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Harbin",
    utc: ["Asia/Harbin"],
    _id: "+08:00 Harbin",
    label: "Asia/Harbin  (CST)",
  },
  {
    value: "Asia/Hebron",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Hebron",
    utc: ["Asia/Hebron"],
    _id: "+03:00 Hebron",
    label: "Asia/Hebron  (EEST)",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Ho Chi_Minh",
    utc: ["Asia/Ho_Chi_Minh"],
    _id: "+07:00 Ho Chi_Minh",
    label: "Asia/Ho_Chi_Minh  (UTC+07)",
  },
  {
    value: "Asia/Hong_Kong",
    short: "HKT",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Hong Kong",
    utc: ["Asia/Hong_Kong"],
    _id: "+08:00 Hong Kong",
    label: "Asia/Hong_Kong  (HKT)",
  },
  {
    value: "Asia/Hovd",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Hovd",
    utc: ["Asia/Hovd"],
    _id: "+07:00 Hovd",
    label: "Asia/Hovd  (UTC+07)",
  },
  {
    value: "Asia/Irkutsk",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Irkutsk",
    utc: ["Asia/Irkutsk"],
    _id: "+08:00 Irkutsk",
    label: "Asia/Irkutsk  (UTC+08)",
  },
  {
    value: "Asia/Istanbul",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Istanbul",
    utc: ["Asia/Istanbul"],
    _id: "+03:00 Istanbul",
    label: "Asia/Istanbul  (UTC+03)",
  },
  {
    value: "Asia/Jakarta",
    short: "WIB",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Jakarta",
    utc: ["Asia/Jakarta"],
    _id: "+07:00 Jakarta",
    label: "Asia/Jakarta  (WIB)",
  },
  {
    value: "Asia/Jayapura",
    short: "WIT",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Jayapura",
    utc: ["Asia/Jayapura"],
    _id: "+09:00 Jayapura",
    label: "Asia/Jayapura  (WIT)",
  },
  {
    value: "Asia/Jerusalem",
    short: "IDT",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Jerusalem",
    utc: ["Asia/Jerusalem"],
    _id: "+03:00 Jerusalem",
    label: "Asia/Jerusalem  (IDT)",
  },
  {
    value: "Asia/Kabul",
    short: "UTC+0430",
    offset: 4.5,
    isDST: false,
    text: "(UTC+04:30) Asia/Kabul",
    utc: ["Asia/Kabul"],
    _id: "+04:30 Kabul",
    label: "Asia/Kabul  (UTC+0430)",
  },
  {
    value: "Asia/Kamchatka",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Asia/Kamchatka",
    utc: ["Asia/Kamchatka"],
    _id: "+12:00 Kamchatka",
    label: "Asia/Kamchatka  (UTC+12)",
  },
  {
    value: "Asia/Karachi",
    short: "PKT",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Karachi",
    utc: ["Asia/Karachi"],
    _id: "+05:00 Karachi",
    label: "Asia/Karachi  (PKT)",
  },
  {
    value: "Asia/Kashgar",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Kashgar",
    utc: ["Asia/Kashgar"],
    _id: "+06:00 Kashgar",
    label: "Asia/Kashgar  (UTC+06)",
  },
  {
    value: "Asia/Kathmandu",
    short: "UTC+0545",
    offset: 5.75,
    isDST: false,
    text: "(UTC+05:45) Asia/Kathmandu",
    utc: ["Asia/Kathmandu"],
    _id: "+05:45 Kathmandu",
    label: "Asia/Kathmandu  (UTC+0545)",
  },
  {
    value: "Asia/Katmandu",
    short: "UTC+0545",
    offset: 5.75,
    isDST: false,
    text: "(UTC+05:45) Asia/Katmandu",
    utc: ["Asia/Katmandu"],
    _id: "+05:45 Katmandu",
    label: "Asia/Katmandu  (UTC+0545)",
  },
  {
    value: "Asia/Khandyga",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Khandyga",
    utc: ["Asia/Khandyga"],
    _id: "+09:00 Khandyga",
    label: "Asia/Khandyga  (UTC+09)",
  },
  {
    value: "Asia/Kolkata",
    short: "IST",
    offset: 5.5,
    isDST: false,
    text: "(UTC+05:30) Asia/Kolkata",
    utc: ["Asia/Kolkata"],
    _id: "+05:30 Kolkata",
    label: "Asia/Kolkata  (IST)",
  },
  {
    value: "Asia/Krasnoyarsk",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"],
    _id: "+07:00 Krasnoyarsk",
    label: "Asia/Krasnoyarsk  (UTC+07)",
  },
  {
    value: "Asia/Kuala_Lumpur",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Kuala Lumpur",
    utc: ["Asia/Kuala_Lumpur"],
    _id: "+08:00 Kuala Lumpur",
    label: "Asia/Kuala_Lumpur  (UTC+08)",
  },
  {
    value: "Asia/Kuching",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Kuching",
    utc: ["Asia/Kuching"],
    _id: "+08:00 Kuching",
    label: "Asia/Kuching  (UTC+08)",
  },
  {
    value: "Asia/Kuwait",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Kuwait",
    utc: ["Asia/Kuwait"],
    _id: "+03:00 Kuwait",
    label: "Asia/Kuwait  (UTC+03)",
  },
  {
    value: "Asia/Macao",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Macao",
    utc: ["Asia/Macao"],
    _id: "+08:00 Macao",
    label: "Asia/Macao  (CST)",
  },
  {
    value: "Asia/Macau",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Macau",
    utc: ["Asia/Macau"],
    _id: "+08:00 Macau",
    label: "Asia/Macau  (CST)",
  },
  {
    value: "Asia/Magadan",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Asia/Magadan",
    utc: ["Asia/Magadan"],
    _id: "+11:00 Magadan",
    label: "Asia/Magadan  (UTC+11)",
  },
  {
    value: "Asia/Makassar",
    short: "WITA",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Makassar",
    utc: ["Asia/Makassar"],
    _id: "+08:00 Makassar",
    label: "Asia/Makassar  (WITA)",
  },
  {
    value: "Asia/Manila",
    short: "PST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Manila",
    utc: ["Asia/Manila"],
    _id: "+08:00 Manila",
    label: "Asia/Manila  (PST)",
  },
  {
    value: "Asia/Muscat",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Asia/Muscat",
    utc: ["Asia/Muscat"],
    _id: "+04:00 Muscat",
    label: "Asia/Muscat  (UTC+04)",
  },
  {
    value: "Asia/Nicosia",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Nicosia",
    utc: ["Asia/Nicosia"],
    _id: "+03:00 Nicosia",
    label: "Asia/Nicosia  (EEST)",
  },
  {
    value: "Asia/Novokuznetsk",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Novokuznetsk",
    utc: ["Asia/Novokuznetsk"],
    _id: "+07:00 Novokuznetsk",
    label: "Asia/Novokuznetsk  (UTC+07)",
  },
  {
    value: "Asia/Novosibirsk",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Novosibirsk",
    utc: ["Asia/Novosibirsk"],
    _id: "+07:00 Novosibirsk",
    label: "Asia/Novosibirsk  (UTC+07)",
  },
  {
    value: "Asia/Omsk",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Omsk",
    utc: ["Asia/Omsk"],
    _id: "+06:00 Omsk",
    label: "Asia/Omsk  (UTC+06)",
  },
  {
    value: "Asia/Oral",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Oral",
    utc: ["Asia/Oral"],
    _id: "+05:00 Oral",
    label: "Asia/Oral  (UTC+05)",
  },
  {
    value: "Asia/Phnom_Penh",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Phnom Penh",
    utc: ["Asia/Phnom_Penh"],
    _id: "+07:00 Phnom Penh",
    label: "Asia/Phnom_Penh  (UTC+07)",
  },
  {
    value: "Asia/Pontianak",
    short: "WIB",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Pontianak",
    utc: ["Asia/Pontianak"],
    _id: "+07:00 Pontianak",
    label: "Asia/Pontianak  (WIB)",
  },
  {
    value: "Asia/Pyongyang",
    short: "KST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Pyongyang",
    utc: ["Asia/Pyongyang"],
    _id: "+09:00 Pyongyang",
    label: "Asia/Pyongyang  (KST)",
  },
  {
    value: "Asia/Qatar",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Qatar",
    utc: ["Asia/Qatar"],
    _id: "+03:00 Qatar",
    label: "Asia/Qatar  (UTC+03)",
  },
  {
    value: "Asia/Qostanay",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Qostanay",
    utc: ["Asia/Qostanay"],
    _id: "+05:00 Qostanay",
    label: "Asia/Qostanay  (UTC+05)",
  },
  {
    value: "Asia/Qyzylorda",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Qyzylorda",
    utc: ["Asia/Qyzylorda"],
    _id: "+05:00 Qyzylorda",
    label: "Asia/Qyzylorda  (UTC+05)",
  },
  {
    value: "Asia/Rangoon",
    short: "UTC+0630",
    offset: 6.5,
    isDST: false,
    text: "(UTC+06:30) Asia/Rangoon",
    utc: ["Asia/Rangoon"],
    _id: "+06:30 Rangoon",
    label: "Asia/Rangoon  (UTC+0630)",
  },
  {
    value: "Asia/Riyadh",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Asia/Riyadh",
    utc: ["Asia/Riyadh"],
    _id: "+03:00 Riyadh",
    label: "Asia/Riyadh  (UTC+03)",
  },
  {
    value: "Asia/Saigon",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Saigon",
    utc: ["Asia/Saigon"],
    _id: "+07:00 Saigon",
    label: "Asia/Saigon  (UTC+07)",
  },
  {
    value: "Asia/Sakhalin",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Asia/Sakhalin",
    utc: ["Asia/Sakhalin"],
    _id: "+11:00 Sakhalin",
    label: "Asia/Sakhalin  (UTC+11)",
  },
  {
    value: "Asia/Samarkand",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Samarkand",
    utc: ["Asia/Samarkand"],
    _id: "+05:00 Samarkand",
    label: "Asia/Samarkand  (UTC+05)",
  },
  {
    value: "Asia/Seoul",
    short: "KST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Seoul",
    utc: ["Asia/Seoul"],
    _id: "+09:00 Seoul",
    label: "Asia/Seoul  (KST)",
  },
  {
    value: "Asia/Shanghai",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Shanghai",
    utc: ["Asia/Shanghai"],
    _id: "+08:00 Shanghai",
    label: "Asia/Shanghai  (CST)",
  },
  {
    value: "Asia/Singapore",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Singapore",
    utc: ["Asia/Singapore"],
    _id: "+08:00 Singapore",
    label: "Asia/Singapore  (UTC+08)",
  },
  {
    value: "Asia/Srednekolymsk",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Asia/Srednekolymsk",
    utc: ["Asia/Srednekolymsk"],
    _id: "+11:00 Srednekolymsk",
    label: "Asia/Srednekolymsk  (UTC+11)",
  },
  {
    value: "Asia/Taipei",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Taipei",
    utc: ["Asia/Taipei"],
    _id: "+08:00 Taipei",
    label: "Asia/Taipei  (CST)",
  },
  {
    value: "Asia/Tashkent",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Tashkent",
    utc: ["Asia/Tashkent"],
    _id: "+05:00 Tashkent",
    label: "Asia/Tashkent  (UTC+05)",
  },
  {
    value: "Asia/Tbilisi",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Asia/Tbilisi",
    utc: ["Asia/Tbilisi"],
    _id: "+04:00 Tbilisi",
    label: "Asia/Tbilisi  (UTC+04)",
  },
  {
    value: "Asia/Tehran",
    short: "UTC+0330",
    offset: 3.5,
    isDST: false,
    text: "(UTC+03:30) Asia/Tehran",
    utc: ["Asia/Tehran"],
    _id: "+03:30 Tehran",
    label: "Asia/Tehran  (UTC+0330)",
  },
  {
    value: "Asia/Tel_Aviv",
    short: "IDT",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Asia/Tel Aviv",
    utc: ["Asia/Tel_Aviv"],
    _id: "+03:00 Tel Aviv",
    label: "Asia/Tel_Aviv  (IDT)",
  },
  {
    value: "Asia/Thimbu",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Thimbu",
    utc: ["Asia/Thimbu"],
    _id: "+06:00 Thimbu",
    label: "Asia/Thimbu  (UTC+06)",
  },
  {
    value: "Asia/Thimphu",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Thimphu",
    utc: ["Asia/Thimphu"],
    _id: "+06:00 Thimphu",
    label: "Asia/Thimphu  (UTC+06)",
  },
  {
    value: "Asia/Tokyo",
    short: "JST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Tokyo",
    utc: ["Asia/Tokyo"],
    _id: "+09:00 Tokyo",
    label: "Asia/Tokyo  (JST)",
  },
  {
    value: "Asia/Tomsk",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Tomsk",
    utc: ["Asia/Tomsk"],
    _id: "+07:00 Tomsk",
    label: "Asia/Tomsk  (UTC+07)",
  },
  {
    value: "Asia/Ujung_Pandang",
    short: "WITA",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Ujung Pandang",
    utc: ["Asia/Ujung_Pandang"],
    _id: "+08:00 Ujung Pandang",
    label: "Asia/Ujung_Pandang  (WITA)",
  },
  {
    value: "Asia/Ulaanbaatar",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Ulaanbaatar",
    utc: ["Asia/Ulaanbaatar"],
    _id: "+08:00 Ulaanbaatar",
    label: "Asia/Ulaanbaatar  (UTC+08)",
  },
  {
    value: "Asia/Ulan_Bator",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Asia/Ulan Bator",
    utc: ["Asia/Ulan_Bator"],
    _id: "+08:00 Ulan Bator",
    label: "Asia/Ulan_Bator  (UTC+08)",
  },
  {
    value: "Asia/Urumqi",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Asia/Urumqi",
    utc: ["Asia/Urumqi"],
    _id: "+06:00 Urumqi",
    label: "Asia/Urumqi  (UTC+06)",
  },
  {
    value: "Asia/Ust-Nera",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Asia/Ust-Nera",
    utc: ["Asia/Ust-Nera"],
    _id: "+10:00 Ust-Nera",
    label: "Asia/Ust-Nera  (UTC+10)",
  },
  {
    value: "Asia/Vientiane",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Asia/Vientiane",
    utc: ["Asia/Vientiane"],
    _id: "+07:00 Vientiane",
    label: "Asia/Vientiane  (UTC+07)",
  },
  {
    value: "Asia/Vladivostok",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Asia/Vladivostok",
    utc: ["Asia/Vladivostok"],
    _id: "+10:00 Vladivostok",
    label: "Asia/Vladivostok  (UTC+10)",
  },
  {
    value: "Asia/Yakutsk",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Asia/Yakutsk",
    utc: ["Asia/Yakutsk"],
    _id: "+09:00 Yakutsk",
    label: "Asia/Yakutsk  (UTC+09)",
  },
  {
    value: "Asia/Yangon",
    short: "UTC+0630",
    offset: 6.5,
    isDST: false,
    text: "(UTC+06:30) Asia/Yangon",
    utc: ["Asia/Yangon"],
    _id: "+06:30 Yangon",
    label: "Asia/Yangon  (UTC+0630)",
  },
  {
    value: "Asia/Yekaterinburg",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Asia/Yekaterinburg",
    utc: ["Asia/Yekaterinburg"],
    _id: "+05:00 Yekaterinburg",
    label: "Asia/Yekaterinburg  (UTC+05)",
  },
  {
    value: "Asia/Yerevan",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Asia/Yerevan",
    utc: ["Asia/Yerevan"],
    _id: "+04:00 Yerevan",
    label: "Asia/Yerevan  (UTC+04)",
  },
  {
    value: "Atlantic/Azores",
    short: "UTC+00",
    offset: 0,
    isDST: true,
    text: "(UTC+00:00) Atlantic/Azores",
    utc: ["Atlantic/Azores"],
    _id: "+00:00 Azores",
    label: "Atlantic/Azores  (UTC+00)",
  },
  {
    value: "Atlantic/Bermuda",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) Atlantic/Bermuda",
    utc: ["Atlantic/Bermuda"],
    _id: "-03:00 Bermuda",
    label: "Atlantic/Bermuda  (ADT)",
  },
  {
    value: "Atlantic/Canary",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Atlantic/Canary",
    utc: ["Atlantic/Canary"],
    _id: "+01:00 Canary",
    label: "Atlantic/Canary  (WEST)",
  },
  {
    value: "Atlantic/Cape_Verde",
    short: "UTC-01",
    offset: -1,
    isDST: false,
    text: "(UTC-01:00) Atlantic/Cape Verde",
    utc: ["Atlantic/Cape_Verde"],
    _id: "-01:00 Cape Verde",
    label: "Atlantic/Cape_Verde  (UTC-01)",
  },
  {
    value: "Atlantic/Faeroe",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Atlantic/Faeroe",
    utc: ["Atlantic/Faeroe"],
    _id: "+01:00 Faeroe",
    label: "Atlantic/Faeroe  (WEST)",
  },
  {
    value: "Atlantic/Faroe",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Atlantic/Faroe",
    utc: ["Atlantic/Faroe"],
    _id: "+01:00 Faroe",
    label: "Atlantic/Faroe  (WEST)",
  },
  {
    value: "Atlantic/Jan_Mayen",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Atlantic/Jan Mayen",
    utc: ["Atlantic/Jan_Mayen"],
    _id: "+02:00 Jan Mayen",
    label: "Atlantic/Jan_Mayen  (CEST)",
  },
  {
    value: "Atlantic/Madeira",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Atlantic/Madeira",
    utc: ["Atlantic/Madeira"],
    _id: "+01:00 Madeira",
    label: "Atlantic/Madeira  (WEST)",
  },
  {
    value: "Atlantic/Reykjavik",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Atlantic/Reykjavik",
    utc: ["Atlantic/Reykjavik"],
    _id: "+00:00 Reykjavik",
    label: "Atlantic/Reykjavik  (GMT)",
  },
  {
    value: "Atlantic/South_Georgia",
    short: "UTC-02",
    offset: -2,
    isDST: false,
    text: "(UTC-02:00) Atlantic/South Georgia",
    utc: ["Atlantic/South_Georgia"],
    _id: "-02:00 South Georgia",
    label: "Atlantic/South_Georgia  (UTC-02)",
  },
  {
    value: "Atlantic/St_Helena",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Atlantic/St Helena",
    utc: ["Atlantic/St_Helena"],
    _id: "+00:00 St Helena",
    label: "Atlantic/St_Helena  (GMT)",
  },
  {
    value: "Atlantic/Stanley",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Atlantic/Stanley",
    utc: ["Atlantic/Stanley"],
    _id: "-03:00 Stanley",
    label: "Atlantic/Stanley  (UTC-03)",
  },
  {
    value: "Australia/ACT",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/ACT",
    utc: ["Australia/ACT"],
    _id: "+11:00 ACT",
    label: "Australia/ACT  (AEDT)",
  },
  {
    value: "Australia/Adelaide",
    short: "ACDT",
    offset: 10.5,
    isDST: true,
    text: "(UTC+10:30) Australia/Adelaide",
    utc: ["Australia/Adelaide"],
    _id: "+10:30 Adelaide",
    label: "Australia/Adelaide  (ACDT)",
  },
  {
    value: "Australia/Brisbane",
    short: "AEST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Australia/Brisbane",
    utc: ["Australia/Brisbane"],
    _id: "+10:00 Brisbane",
    label: "Australia/Brisbane  (AEST)",
  },
  {
    value: "Australia/Broken_Hill",
    short: "ACDT",
    offset: 10.5,
    isDST: true,
    text: "(UTC+10:30) Australia/Broken Hill",
    utc: ["Australia/Broken_Hill"],
    _id: "+10:30 Broken Hill",
    label: "Australia/Broken_Hill  (ACDT)",
  },
  {
    value: "Australia/Canberra",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Canberra",
    utc: ["Australia/Canberra"],
    _id: "+11:00 Canberra",
    label: "Australia/Canberra  (AEDT)",
  },
  {
    value: "Australia/Currie",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Currie",
    utc: ["Australia/Currie"],
    _id: "+11:00 Currie",
    label: "Australia/Currie  (AEDT)",
  },
  {
    value: "Australia/Darwin",
    short: "ACST",
    offset: 9.5,
    isDST: false,
    text: "(UTC+09:30) Australia/Darwin",
    utc: ["Australia/Darwin"],
    _id: "+09:30 Darwin",
    label: "Australia/Darwin  (ACST)",
  },
  {
    value: "Australia/Eucla",
    short: "UTC+0845",
    offset: 8.75,
    isDST: false,
    text: "(UTC+08:45) Australia/Eucla",
    utc: ["Australia/Eucla"],
    _id: "+08:45 Eucla",
    label: "Australia/Eucla  (UTC+0845)",
  },
  {
    value: "Australia/Hobart",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Hobart",
    utc: ["Australia/Hobart"],
    _id: "+11:00 Hobart",
    label: "Australia/Hobart  (AEDT)",
  },
  {
    value: "Australia/LHI",
    short: "UTC+11",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/LHI",
    utc: ["Australia/LHI"],
    _id: "+11:00 LHI",
    label: "Australia/LHI  (UTC+11)",
  },
  {
    value: "Australia/Lindeman",
    short: "AEST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Australia/Lindeman",
    utc: ["Australia/Lindeman"],
    _id: "+10:00 Lindeman",
    label: "Australia/Lindeman  (AEST)",
  },
  {
    value: "Australia/Lord_Howe",
    short: "UTC+11",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Lord Howe",
    utc: ["Australia/Lord_Howe"],
    _id: "+11:00 Lord Howe",
    label: "Australia/Lord_Howe  (UTC+11)",
  },
  {
    value: "Australia/Melbourne",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Melbourne",
    utc: ["Australia/Melbourne"],
    _id: "+11:00 Melbourne",
    label: "Australia/Melbourne  (AEDT)",
  },
  {
    value: "Australia/NSW",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/NSW",
    utc: ["Australia/NSW"],
    _id: "+11:00 NSW",
    label: "Australia/NSW  (AEDT)",
  },
  {
    value: "Australia/North",
    short: "ACST",
    offset: 9.5,
    isDST: false,
    text: "(UTC+09:30) Australia/North",
    utc: ["Australia/North"],
    _id: "+09:30 North",
    label: "Australia/North  (ACST)",
  },
  {
    value: "Australia/Perth",
    short: "AWST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Australia/Perth",
    utc: ["Australia/Perth"],
    _id: "+08:00 Perth",
    label: "Australia/Perth  (AWST)",
  },
  {
    value: "Australia/Queensland",
    short: "AEST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Australia/Queensland",
    utc: ["Australia/Queensland"],
    _id: "+10:00 Queensland",
    label: "Australia/Queensland  (AEST)",
  },
  {
    value: "Australia/South",
    short: "ACDT",
    offset: 10.5,
    isDST: true,
    text: "(UTC+10:30) Australia/South",
    utc: ["Australia/South"],
    _id: "+10:30 South",
    label: "Australia/South  (ACDT)",
  },
  {
    value: "Australia/Sydney",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Sydney",
    utc: ["Australia/Sydney"],
    _id: "+11:00 Sydney",
    label: "Australia/Sydney  (AEDT)",
  },
  {
    value: "Australia/Tasmania",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Tasmania",
    utc: ["Australia/Tasmania"],
    _id: "+11:00 Tasmania",
    label: "Australia/Tasmania  (AEDT)",
  },
  {
    value: "Australia/Victoria",
    short: "AEDT",
    offset: 11,
    isDST: true,
    text: "(UTC+11:00) Australia/Victoria",
    utc: ["Australia/Victoria"],
    _id: "+11:00 Victoria",
    label: "Australia/Victoria  (AEDT)",
  },
  {
    value: "Australia/West",
    short: "AWST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Australia/West",
    utc: ["Australia/West"],
    _id: "+08:00 West",
    label: "Australia/West  (AWST)",
  },
  {
    value: "Australia/Yancowinna",
    short: "ACDT",
    offset: 10.5,
    isDST: true,
    text: "(UTC+10:30) Australia/Yancowinna",
    utc: ["Australia/Yancowinna"],
    _id: "+10:30 Yancowinna",
    label: "Australia/Yancowinna  (ACDT)",
  },
  {
    value: "Brazil/Acre",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Brazil/Acre",
    utc: ["Brazil/Acre"],
    _id: "-05:00 Acre",
    label: "Brazil/Acre  (UTC-05)",
  },
  {
    value: "Brazil/DeNoronha",
    short: "UTC-02",
    offset: -2,
    isDST: false,
    text: "(UTC-02:00) Brazil/DeNoronha",
    utc: ["Brazil/DeNoronha"],
    _id: "-02:00 DeNoronha",
    label: "Brazil/DeNoronha  (UTC-02)",
  },
  {
    value: "Brazil/East",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Brazil/East",
    utc: ["Brazil/East"],
    _id: "-03:00 East",
    label: "Brazil/East  (UTC-03)",
  },
  {
    value: "Brazil/West",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Brazil/West",
    utc: ["Brazil/West"],
    _id: "-04:00 West",
    label: "Brazil/West  (UTC-04)",
  },
  {
    value: "CET",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) CET",
    utc: ["CET"],
    _id: "+02:00 CET",
    label: "CET  (CEST)",
  },
  {
    value: "CST6CDT",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) CST6CDT",
    utc: ["CST6CDT"],
    _id: "-05:00 CST6CDT",
    label: "CST6CDT  (CDT)",
  },
  {
    value: "Canada/Atlantic",
    short: "ADT",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) Canada/Atlantic",
    utc: ["Canada/Atlantic"],
    _id: "-03:00 Atlantic",
    label: "Canada/Atlantic  (ADT)",
  },
  {
    value: "Canada/Central",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) Canada/Central",
    utc: ["Canada/Central"],
    _id: "-05:00 Central",
    label: "Canada/Central  (CDT)",
  },
  {
    value: "Canada/Eastern",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) Canada/Eastern",
    utc: ["Canada/Eastern"],
    _id: "-04:00 Eastern",
    label: "Canada/Eastern  (EDT)",
  },
  {
    value: "Canada/Mountain",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) Canada/Mountain",
    utc: ["Canada/Mountain"],
    _id: "-06:00 Mountain",
    label: "Canada/Mountain  (MDT)",
  },
  {
    value: "Canada/Newfoundland",
    short: "NDT",
    offset: -2.5,
    isDST: true,
    text: "(UTC-03:30) Canada/Newfoundland",
    utc: ["Canada/Newfoundland"],
    _id: "-03:30 Newfoundland",
    label: "Canada/Newfoundland  (NDT)",
  },
  {
    value: "Canada/Pacific",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) Canada/Pacific",
    utc: ["Canada/Pacific"],
    _id: "-07:00 Pacific",
    label: "Canada/Pacific  (PDT)",
  },
  {
    value: "Canada/Saskatchewan",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Canada/Saskatchewan",
    utc: ["Canada/Saskatchewan"],
    _id: "-06:00 Saskatchewan",
    label: "Canada/Saskatchewan  (CST)",
  },
  {
    value: "Canada/Yukon",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) Canada/Yukon",
    utc: ["Canada/Yukon"],
    _id: "-07:00 Yukon",
    label: "Canada/Yukon  (MST)",
  },
  {
    value: "Chile/Continental",
    short: "UTC-03",
    offset: -3,
    isDST: true,
    text: "(UTC-03:00) Chile/Continental",
    utc: ["Chile/Continental"],
    _id: "-03:00 Continental",
    label: "Chile/Continental  (UTC-03)",
  },
  {
    value: "Chile/EasterIsland",
    short: "UTC-05",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) Chile/EasterIsland",
    utc: ["Chile/EasterIsland"],
    _id: "-05:00 EasterIsland",
    label: "Chile/EasterIsland  (UTC-05)",
  },
  {
    value: "Cuba",
    short: "CDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) Cuba",
    utc: ["Cuba"],
    _id: "-04:00 Cuba",
    label: "Cuba  (CDT)",
  },
  {
    value: "EET",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) EET",
    utc: ["EET"],
    _id: "+03:00 EET",
    label: "EET  (EEST)",
  },
  {
    value: "EST",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) EST",
    utc: ["EST"],
    _id: "-05:00 EST",
    label: "EST  (EST)",
  },
  {
    value: "EST5EDT",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) EST5EDT",
    utc: ["EST5EDT"],
    _id: "-04:00 EST5EDT",
    label: "EST5EDT  (EDT)",
  },
  {
    value: "Egypt",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Egypt",
    utc: ["Egypt"],
    _id: "+03:00 Egypt",
    label: "Egypt  (EEST)",
  },
  {
    value: "Eire",
    short: "IST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Eire",
    utc: ["Eire"],
    _id: "+01:00 Eire",
    label: "Eire  (IST)",
  },
  {
    value: "Etc/GMT",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/GMT",
    utc: ["Etc/GMT"],
    _id: "+00:00 GMT",
    label: "Etc/GMT  (GMT)",
  },
  {
    value: "Etc/GMT+0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/GMT+0",
    utc: ["Etc/GMT+0"],
    _id: "+00:00 GMT+0",
    label: "Etc/GMT+0  (GMT)",
  },
  {
    value: "Etc/GMT+1",
    short: "UTC-01",
    offset: -1,
    isDST: false,
    text: "(UTC-01:00) Etc/GMT+1",
    utc: ["Etc/GMT+1"],
    _id: "-01:00 GMT+1",
    label: "Etc/GMT+1  (UTC-01)",
  },
  {
    value: "Etc/GMT+10",
    short: "UTC-10",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Etc/GMT+10",
    utc: ["Etc/GMT+10"],
    _id: "-10:00 GMT+10",
    label: "Etc/GMT+10  (UTC-10)",
  },
  {
    value: "Etc/GMT+11",
    short: "UTC-11",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Etc/GMT+11",
    utc: ["Etc/GMT+11"],
    _id: "-11:00 GMT+11",
    label: "Etc/GMT+11  (UTC-11)",
  },
  {
    value: "Etc/GMT+12",
    short: "UTC-12",
    offset: -12,
    isDST: false,
    text: "(UTC-12:00) Etc/GMT+12",
    utc: ["Etc/GMT+12"],
    _id: "-12:00 GMT+12",
    label: "Etc/GMT+12  (UTC-12)",
  },
  {
    value: "Etc/GMT+2",
    short: "UTC-02",
    offset: -2,
    isDST: false,
    text: "(UTC-02:00) Etc/GMT+2",
    utc: ["Etc/GMT+2"],
    _id: "-02:00 GMT+2",
    label: "Etc/GMT+2  (UTC-02)",
  },
  {
    value: "Etc/GMT+3",
    short: "UTC-03",
    offset: -3,
    isDST: false,
    text: "(UTC-03:00) Etc/GMT+3",
    utc: ["Etc/GMT+3"],
    _id: "-03:00 GMT+3",
    label: "Etc/GMT+3  (UTC-03)",
  },
  {
    value: "Etc/GMT+4",
    short: "UTC-04",
    offset: -4,
    isDST: false,
    text: "(UTC-04:00) Etc/GMT+4",
    utc: ["Etc/GMT+4"],
    _id: "-04:00 GMT+4",
    label: "Etc/GMT+4  (UTC-04)",
  },
  {
    value: "Etc/GMT+5",
    short: "UTC-05",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Etc/GMT+5",
    utc: ["Etc/GMT+5"],
    _id: "-05:00 GMT+5",
    label: "Etc/GMT+5  (UTC-05)",
  },
  {
    value: "Etc/GMT+6",
    short: "UTC-06",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Etc/GMT+6",
    utc: ["Etc/GMT+6"],
    _id: "-06:00 GMT+6",
    label: "Etc/GMT+6  (UTC-06)",
  },
  {
    value: "Etc/GMT+7",
    short: "UTC-07",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) Etc/GMT+7",
    utc: ["Etc/GMT+7"],
    _id: "-07:00 GMT+7",
    label: "Etc/GMT+7  (UTC-07)",
  },
  {
    value: "Etc/GMT+8",
    short: "UTC-08",
    offset: -8,
    isDST: false,
    text: "(UTC-08:00) Etc/GMT+8",
    utc: ["Etc/GMT+8"],
    _id: "-08:00 GMT+8",
    label: "Etc/GMT+8  (UTC-08)",
  },
  {
    value: "Etc/GMT+9",
    short: "UTC-09",
    offset: -9,
    isDST: false,
    text: "(UTC-09:00) Etc/GMT+9",
    utc: ["Etc/GMT+9"],
    _id: "-09:00 GMT+9",
    label: "Etc/GMT+9  (UTC-09)",
  },
  {
    value: "Etc/GMT-0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/GMT-0",
    utc: ["Etc/GMT-0"],
    _id: "+00:00 GMT-0",
    label: "Etc/GMT-0  (GMT)",
  },
  {
    value: "Etc/GMT-1",
    short: "UTC+01",
    offset: 1,
    isDST: false,
    text: "(UTC+01:00) Etc/GMT-1",
    utc: ["Etc/GMT-1"],
    _id: "+01:00 GMT-1",
    label: "Etc/GMT-1  (UTC+01)",
  },
  {
    value: "Etc/GMT-10",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Etc/GMT-10",
    utc: ["Etc/GMT-10"],
    _id: "+10:00 GMT-10",
    label: "Etc/GMT-10  (UTC+10)",
  },
  {
    value: "Etc/GMT-11",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Etc/GMT-11",
    utc: ["Etc/GMT-11"],
    _id: "+11:00 GMT-11",
    label: "Etc/GMT-11  (UTC+11)",
  },
  {
    value: "Etc/GMT-12",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Etc/GMT-12",
    utc: ["Etc/GMT-12"],
    _id: "+12:00 GMT-12",
    label: "Etc/GMT-12  (UTC+12)",
  },
  {
    value: "Etc/GMT-13",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Etc/GMT-13",
    utc: ["Etc/GMT-13"],
    _id: "+13:00 GMT-13",
    label: "Etc/GMT-13  (UTC+13)",
  },
  {
    value: "Etc/GMT-14",
    short: "UTC+14",
    offset: 14,
    isDST: false,
    text: "(UTC+14:00) Etc/GMT-14",
    utc: ["Etc/GMT-14"],
    _id: "+14:00 GMT-14",
    label: "Etc/GMT-14  (UTC+14)",
  },
  {
    value: "Etc/GMT-2",
    short: "UTC+02",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Etc/GMT-2",
    utc: ["Etc/GMT-2"],
    _id: "+02:00 GMT-2",
    label: "Etc/GMT-2  (UTC+02)",
  },
  {
    value: "Etc/GMT-3",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Etc/GMT-3",
    utc: ["Etc/GMT-3"],
    _id: "+03:00 GMT-3",
    label: "Etc/GMT-3  (UTC+03)",
  },
  {
    value: "Etc/GMT-4",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Etc/GMT-4",
    utc: ["Etc/GMT-4"],
    _id: "+04:00 GMT-4",
    label: "Etc/GMT-4  (UTC+04)",
  },
  {
    value: "Etc/GMT-5",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Etc/GMT-5",
    utc: ["Etc/GMT-5"],
    _id: "+05:00 GMT-5",
    label: "Etc/GMT-5  (UTC+05)",
  },
  {
    value: "Etc/GMT-6",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Etc/GMT-6",
    utc: ["Etc/GMT-6"],
    _id: "+06:00 GMT-6",
    label: "Etc/GMT-6  (UTC+06)",
  },
  {
    value: "Etc/GMT-7",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Etc/GMT-7",
    utc: ["Etc/GMT-7"],
    _id: "+07:00 GMT-7",
    label: "Etc/GMT-7  (UTC+07)",
  },
  {
    value: "Etc/GMT-8",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Etc/GMT-8",
    utc: ["Etc/GMT-8"],
    _id: "+08:00 GMT-8",
    label: "Etc/GMT-8  (UTC+08)",
  },
  {
    value: "Etc/GMT-9",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Etc/GMT-9",
    utc: ["Etc/GMT-9"],
    _id: "+09:00 GMT-9",
    label: "Etc/GMT-9  (UTC+09)",
  },
  {
    value: "Etc/GMT0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/GMT0",
    utc: ["Etc/GMT0"],
    _id: "+00:00 GMT0",
    label: "Etc/GMT0  (GMT)",
  },
  {
    value: "Etc/Greenwich",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/Greenwich",
    utc: ["Etc/Greenwich"],
    _id: "+00:00 Greenwich",
    label: "Etc/Greenwich  (GMT)",
  },
  {
    value: "Etc/UCT",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/UCT",
    utc: ["Etc/UCT"],
    _id: "+00:00 UCT",
    label: "Etc/UCT  (UTC)",
  },
  {
    value: "Etc/UTC",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/UTC",
    utc: ["Etc/UTC"],
    _id: "+00:00 UTC",
    label: "Etc/UTC  (UTC)",
  },
  {
    value: "Etc/Universal",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/Universal",
    utc: ["Etc/Universal"],
    _id: "+00:00 Universal",
    label: "Etc/Universal  (UTC)",
  },
  {
    value: "Etc/Zulu",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Etc/Zulu",
    utc: ["Etc/Zulu"],
    _id: "+00:00 Zulu",
    label: "Etc/Zulu  (UTC)",
  },
  {
    value: "Europe/Amsterdam",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Amsterdam",
    utc: ["Europe/Amsterdam"],
    _id: "+02:00 Amsterdam",
    label: "Europe/Amsterdam  (CEST)",
  },
  {
    value: "Europe/Andorra",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Andorra",
    utc: ["Europe/Andorra"],
    _id: "+02:00 Andorra",
    label: "Europe/Andorra  (CEST)",
  },
  {
    value: "Europe/Astrakhan",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Europe/Astrakhan",
    utc: ["Europe/Astrakhan"],
    _id: "+04:00 Astrakhan",
    label: "Europe/Astrakhan  (UTC+04)",
  },
  {
    value: "Europe/Athens",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Athens",
    utc: ["Europe/Athens"],
    _id: "+03:00 Athens",
    label: "Europe/Athens  (EEST)",
  },
  {
    value: "Europe/Belfast",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Belfast",
    utc: ["Europe/Belfast"],
    _id: "+01:00 Belfast",
    label: "Europe/Belfast  (BST)",
  },
  {
    value: "Europe/Belgrade",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Belgrade",
    utc: ["Europe/Belgrade"],
    _id: "+02:00 Belgrade",
    label: "Europe/Belgrade  (CEST)",
  },
  {
    value: "Europe/Berlin",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Berlin",
    utc: ["Europe/Berlin"],
    _id: "+02:00 Berlin",
    label: "Europe/Berlin  (CEST)",
  },
  {
    value: "Europe/Bratislava",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Bratislava",
    utc: ["Europe/Bratislava"],
    _id: "+02:00 Bratislava",
    label: "Europe/Bratislava  (CEST)",
  },
  {
    value: "Europe/Brussels",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Brussels",
    utc: ["Europe/Brussels"],
    _id: "+02:00 Brussels",
    label: "Europe/Brussels  (CEST)",
  },
  {
    value: "Europe/Bucharest",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Bucharest",
    utc: ["Europe/Bucharest"],
    _id: "+03:00 Bucharest",
    label: "Europe/Bucharest  (EEST)",
  },
  {
    value: "Europe/Budapest",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Budapest",
    utc: ["Europe/Budapest"],
    _id: "+02:00 Budapest",
    label: "Europe/Budapest  (CEST)",
  },
  {
    value: "Europe/Busingen",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Busingen",
    utc: ["Europe/Busingen"],
    _id: "+02:00 Busingen",
    label: "Europe/Busingen  (CEST)",
  },
  {
    value: "Europe/Chisinau",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Chisinau",
    utc: ["Europe/Chisinau"],
    _id: "+03:00 Chisinau",
    label: "Europe/Chisinau  (EEST)",
  },
  {
    value: "Europe/Copenhagen",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Copenhagen",
    utc: ["Europe/Copenhagen"],
    _id: "+02:00 Copenhagen",
    label: "Europe/Copenhagen  (CEST)",
  },
  {
    value: "Europe/Dublin",
    short: "IST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Dublin",
    utc: ["Europe/Dublin"],
    _id: "+01:00 Dublin",
    label: "Europe/Dublin  (IST)",
  },
  {
    value: "Europe/Gibraltar",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Gibraltar",
    utc: ["Europe/Gibraltar"],
    _id: "+02:00 Gibraltar",
    label: "Europe/Gibraltar  (CEST)",
  },
  {
    value: "Europe/Guernsey",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Guernsey",
    utc: ["Europe/Guernsey"],
    _id: "+01:00 Guernsey",
    label: "Europe/Guernsey  (BST)",
  },
  {
    value: "Europe/Helsinki",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Helsinki",
    utc: ["Europe/Helsinki"],
    _id: "+03:00 Helsinki",
    label: "Europe/Helsinki  (EEST)",
  },
  {
    value: "Europe/Isle_of_Man",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Isle of_Man",
    utc: ["Europe/Isle_of_Man"],
    _id: "+01:00 Isle of_Man",
    label: "Europe/Isle_of_Man  (BST)",
  },
  {
    value: "Europe/Istanbul",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Istanbul",
    utc: ["Europe/Istanbul"],
    _id: "+03:00 Istanbul",
    label: "Europe/Istanbul  (UTC+03)",
  },
  {
    value: "Europe/Jersey",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Jersey",
    utc: ["Europe/Jersey"],
    _id: "+01:00 Jersey",
    label: "Europe/Jersey  (BST)",
  },
  {
    value: "Europe/Kaliningrad",
    short: "EET",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Europe/Kaliningrad",
    utc: ["Europe/Kaliningrad"],
    _id: "+02:00 Kaliningrad",
    label: "Europe/Kaliningrad  (EET)",
  },
  {
    value: "Europe/Kiev",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Kiev",
    utc: ["Europe/Kiev"],
    _id: "+03:00 Kiev",
    label: "Europe/Kiev  (EEST)",
  },
  {
    value: "Europe/Kirov",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Kirov",
    utc: ["Europe/Kirov"],
    _id: "+03:00 Kirov",
    label: "Europe/Kirov  (MSK)",
  },
  {
    value: "Europe/Kyiv",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Kyiv",
    utc: ["Europe/Kyiv"],
    _id: "+03:00 Kyiv",
    label: "Europe/Kyiv  (EEST)",
  },
  {
    value: "Europe/Lisbon",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/Lisbon",
    utc: ["Europe/Lisbon"],
    _id: "+01:00 Lisbon",
    label: "Europe/Lisbon  (WEST)",
  },
  {
    value: "Europe/Ljubljana",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Ljubljana",
    utc: ["Europe/Ljubljana"],
    _id: "+02:00 Ljubljana",
    label: "Europe/Ljubljana  (CEST)",
  },
  {
    value: "Europe/London",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Europe/London",
    utc: ["Europe/London"],
    _id: "+01:00 London",
    label: "Europe/London  (BST)",
  },
  {
    value: "Europe/Luxembourg",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Luxembourg",
    utc: ["Europe/Luxembourg"],
    _id: "+02:00 Luxembourg",
    label: "Europe/Luxembourg  (CEST)",
  },
  {
    value: "Europe/Madrid",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Madrid",
    utc: ["Europe/Madrid"],
    _id: "+02:00 Madrid",
    label: "Europe/Madrid  (CEST)",
  },
  {
    value: "Europe/Malta",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Malta",
    utc: ["Europe/Malta"],
    _id: "+02:00 Malta",
    label: "Europe/Malta  (CEST)",
  },
  {
    value: "Europe/Mariehamn",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Mariehamn",
    utc: ["Europe/Mariehamn"],
    _id: "+03:00 Mariehamn",
    label: "Europe/Mariehamn  (EEST)",
  },
  {
    value: "Europe/Minsk",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Minsk",
    utc: ["Europe/Minsk"],
    _id: "+03:00 Minsk",
    label: "Europe/Minsk  (UTC+03)",
  },
  {
    value: "Europe/Monaco",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Monaco",
    utc: ["Europe/Monaco"],
    _id: "+02:00 Monaco",
    label: "Europe/Monaco  (CEST)",
  },
  {
    value: "Europe/Moscow",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Moscow",
    utc: ["Europe/Moscow"],
    _id: "+03:00 Moscow",
    label: "Europe/Moscow  (MSK)",
  },
  {
    value: "Europe/Nicosia",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Nicosia",
    utc: ["Europe/Nicosia"],
    _id: "+03:00 Nicosia",
    label: "Europe/Nicosia  (EEST)",
  },
  {
    value: "Europe/Oslo",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Oslo",
    utc: ["Europe/Oslo"],
    _id: "+02:00 Oslo",
    label: "Europe/Oslo  (CEST)",
  },
  {
    value: "Europe/Paris",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Paris",
    utc: ["Europe/Paris"],
    _id: "+02:00 Paris",
    label: "Europe/Paris  (CEST)",
  },
  {
    value: "Europe/Podgorica",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Podgorica",
    utc: ["Europe/Podgorica"],
    _id: "+02:00 Podgorica",
    label: "Europe/Podgorica  (CEST)",
  },
  {
    value: "Europe/Prague",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Prague",
    utc: ["Europe/Prague"],
    _id: "+02:00 Prague",
    label: "Europe/Prague  (CEST)",
  },
  {
    value: "Europe/Riga",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Riga",
    utc: ["Europe/Riga"],
    _id: "+03:00 Riga",
    label: "Europe/Riga  (EEST)",
  },
  {
    value: "Europe/Rome",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Rome",
    utc: ["Europe/Rome"],
    _id: "+02:00 Rome",
    label: "Europe/Rome  (CEST)",
  },
  {
    value: "Europe/Samara",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Europe/Samara",
    utc: ["Europe/Samara"],
    _id: "+04:00 Samara",
    label: "Europe/Samara  (UTC+04)",
  },
  {
    value: "Europe/San_Marino",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/San Marino",
    utc: ["Europe/San_Marino"],
    _id: "+02:00 San Marino",
    label: "Europe/San_Marino  (CEST)",
  },
  {
    value: "Europe/Sarajevo",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Sarajevo",
    utc: ["Europe/Sarajevo"],
    _id: "+02:00 Sarajevo",
    label: "Europe/Sarajevo  (CEST)",
  },
  {
    value: "Europe/Saratov",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Europe/Saratov",
    utc: ["Europe/Saratov"],
    _id: "+04:00 Saratov",
    label: "Europe/Saratov  (UTC+04)",
  },
  {
    value: "Europe/Simferopol",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Simferopol",
    utc: ["Europe/Simferopol"],
    _id: "+03:00 Simferopol",
    label: "Europe/Simferopol  (MSK)",
  },
  {
    value: "Europe/Skopje",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Skopje",
    utc: ["Europe/Skopje"],
    _id: "+02:00 Skopje",
    label: "Europe/Skopje  (CEST)",
  },
  {
    value: "Europe/Sofia",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Sofia",
    utc: ["Europe/Sofia"],
    _id: "+03:00 Sofia",
    label: "Europe/Sofia  (EEST)",
  },
  {
    value: "Europe/Stockholm",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Stockholm",
    utc: ["Europe/Stockholm"],
    _id: "+02:00 Stockholm",
    label: "Europe/Stockholm  (CEST)",
  },
  {
    value: "Europe/Tallinn",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Tallinn",
    utc: ["Europe/Tallinn"],
    _id: "+03:00 Tallinn",
    label: "Europe/Tallinn  (EEST)",
  },
  {
    value: "Europe/Tirane",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Tirane",
    utc: ["Europe/Tirane"],
    _id: "+02:00 Tirane",
    label: "Europe/Tirane  (CEST)",
  },
  {
    value: "Europe/Tiraspol",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Tiraspol",
    utc: ["Europe/Tiraspol"],
    _id: "+03:00 Tiraspol",
    label: "Europe/Tiraspol  (EEST)",
  },
  {
    value: "Europe/Ulyanovsk",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Europe/Ulyanovsk",
    utc: ["Europe/Ulyanovsk"],
    _id: "+04:00 Ulyanovsk",
    label: "Europe/Ulyanovsk  (UTC+04)",
  },
  {
    value: "Europe/Uzhgorod",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Uzhgorod",
    utc: ["Europe/Uzhgorod"],
    _id: "+03:00 Uzhgorod",
    label: "Europe/Uzhgorod  (EEST)",
  },
  {
    value: "Europe/Vaduz",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Vaduz",
    utc: ["Europe/Vaduz"],
    _id: "+02:00 Vaduz",
    label: "Europe/Vaduz  (CEST)",
  },
  {
    value: "Europe/Vatican",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Vatican",
    utc: ["Europe/Vatican"],
    _id: "+02:00 Vatican",
    label: "Europe/Vatican  (CEST)",
  },
  {
    value: "Europe/Vienna",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Vienna",
    utc: ["Europe/Vienna"],
    _id: "+02:00 Vienna",
    label: "Europe/Vienna  (CEST)",
  },
  {
    value: "Europe/Vilnius",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Vilnius",
    utc: ["Europe/Vilnius"],
    _id: "+03:00 Vilnius",
    label: "Europe/Vilnius  (EEST)",
  },
  {
    value: "Europe/Volgograd",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Europe/Volgograd",
    utc: ["Europe/Volgograd"],
    _id: "+03:00 Volgograd",
    label: "Europe/Volgograd  (MSK)",
  },
  {
    value: "Europe/Warsaw",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Warsaw",
    utc: ["Europe/Warsaw"],
    _id: "+02:00 Warsaw",
    label: "Europe/Warsaw  (CEST)",
  },
  {
    value: "Europe/Zagreb",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Zagreb",
    utc: ["Europe/Zagreb"],
    _id: "+02:00 Zagreb",
    label: "Europe/Zagreb  (CEST)",
  },
  {
    value: "Europe/Zaporozhye",
    short: "EEST",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Europe/Zaporozhye",
    utc: ["Europe/Zaporozhye"],
    _id: "+03:00 Zaporozhye",
    label: "Europe/Zaporozhye  (EEST)",
  },
  {
    value: "Europe/Zurich",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Europe/Zurich",
    utc: ["Europe/Zurich"],
    _id: "+02:00 Zurich",
    label: "Europe/Zurich  (CEST)",
  },
  {
    value: "GB",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) GB",
    utc: ["GB"],
    _id: "+01:00 GB",
    label: "GB  (BST)",
  },
  {
    value: "GB-Eire",
    short: "BST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) GB-Eire",
    utc: ["GB-Eire"],
    _id: "+01:00 GB-Eire",
    label: "GB-Eire  (BST)",
  },
  {
    value: "GMT",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) GMT",
    utc: ["GMT"],
    _id: "+00:00 GMT",
    label: "GMT  (GMT)",
  },
  {
    value: "GMT+0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) GMT+0",
    utc: ["GMT+0"],
    _id: "+00:00 GMT+0",
    label: "GMT+0  (GMT)",
  },
  {
    value: "GMT-0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) GMT-0",
    utc: ["GMT-0"],
    _id: "+00:00 GMT-0",
    label: "GMT-0  (GMT)",
  },
  {
    value: "GMT0",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) GMT0",
    utc: ["GMT0"],
    _id: "+00:00 GMT0",
    label: "GMT0  (GMT)",
  },
  {
    value: "Greenwich",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Greenwich",
    utc: ["Greenwich"],
    _id: "+00:00 Greenwich",
    label: "Greenwich  (GMT)",
  },
  {
    value: "HST",
    short: "HST",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) HST",
    utc: ["HST"],
    _id: "-10:00 HST",
    label: "HST  (HST)",
  },
  {
    value: "Hongkong",
    short: "HKT",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Hongkong",
    utc: ["Hongkong"],
    _id: "+08:00 Hongkong",
    label: "Hongkong  (HKT)",
  },
  {
    value: "Iceland",
    short: "GMT",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Iceland",
    utc: ["Iceland"],
    _id: "+00:00 Iceland",
    label: "Iceland  (GMT)",
  },
  {
    value: "Indian/Antananarivo",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Indian/Antananarivo",
    utc: ["Indian/Antananarivo"],
    _id: "+03:00 Antananarivo",
    label: "Indian/Antananarivo  (EAT)",
  },
  {
    value: "Indian/Chagos",
    short: "UTC+06",
    offset: 6,
    isDST: false,
    text: "(UTC+06:00) Indian/Chagos",
    utc: ["Indian/Chagos"],
    _id: "+06:00 Chagos",
    label: "Indian/Chagos  (UTC+06)",
  },
  {
    value: "Indian/Christmas",
    short: "UTC+07",
    offset: 7,
    isDST: false,
    text: "(UTC+07:00) Indian/Christmas",
    utc: ["Indian/Christmas"],
    _id: "+07:00 Christmas",
    label: "Indian/Christmas  (UTC+07)",
  },
  {
    value: "Indian/Cocos",
    short: "UTC+0630",
    offset: 6.5,
    isDST: false,
    text: "(UTC+06:30) Indian/Cocos",
    utc: ["Indian/Cocos"],
    _id: "+06:30 Cocos",
    label: "Indian/Cocos  (UTC+0630)",
  },
  {
    value: "Indian/Comoro",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Indian/Comoro",
    utc: ["Indian/Comoro"],
    _id: "+03:00 Comoro",
    label: "Indian/Comoro  (EAT)",
  },
  {
    value: "Indian/Kerguelen",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Indian/Kerguelen",
    utc: ["Indian/Kerguelen"],
    _id: "+05:00 Kerguelen",
    label: "Indian/Kerguelen  (UTC+05)",
  },
  {
    value: "Indian/Mahe",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Indian/Mahe",
    utc: ["Indian/Mahe"],
    _id: "+04:00 Mahe",
    label: "Indian/Mahe  (UTC+04)",
  },
  {
    value: "Indian/Maldives",
    short: "UTC+05",
    offset: 5,
    isDST: false,
    text: "(UTC+05:00) Indian/Maldives",
    utc: ["Indian/Maldives"],
    _id: "+05:00 Maldives",
    label: "Indian/Maldives  (UTC+05)",
  },
  {
    value: "Indian/Mauritius",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Indian/Mauritius",
    utc: ["Indian/Mauritius"],
    _id: "+04:00 Mauritius",
    label: "Indian/Mauritius  (UTC+04)",
  },
  {
    value: "Indian/Mayotte",
    short: "EAT",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Indian/Mayotte",
    utc: ["Indian/Mayotte"],
    _id: "+03:00 Mayotte",
    label: "Indian/Mayotte  (EAT)",
  },
  {
    value: "Indian/Reunion",
    short: "UTC+04",
    offset: 4,
    isDST: false,
    text: "(UTC+04:00) Indian/Reunion",
    utc: ["Indian/Reunion"],
    _id: "+04:00 Reunion",
    label: "Indian/Reunion  (UTC+04)",
  },
  {
    value: "Iran",
    short: "UTC+0330",
    offset: 3.5,
    isDST: false,
    text: "(UTC+03:30) Iran",
    utc: ["Iran"],
    _id: "+03:30 Iran",
    label: "Iran  (UTC+0330)",
  },
  {
    value: "Israel",
    short: "IDT",
    offset: 3,
    isDST: true,
    text: "(UTC+03:00) Israel",
    utc: ["Israel"],
    _id: "+03:00 Israel",
    label: "Israel  (IDT)",
  },
  {
    value: "Jamaica",
    short: "EST",
    offset: -5,
    isDST: false,
    text: "(UTC-05:00) Jamaica",
    utc: ["Jamaica"],
    _id: "-05:00 Jamaica",
    label: "Jamaica  (EST)",
  },
  {
    value: "Japan",
    short: "JST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Japan",
    utc: ["Japan"],
    _id: "+09:00 Japan",
    label: "Japan  (JST)",
  },
  {
    value: "Kwajalein",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Kwajalein",
    utc: ["Kwajalein"],
    _id: "+12:00 Kwajalein",
    label: "Kwajalein  (UTC+12)",
  },
  {
    value: "Libya",
    short: "EET",
    offset: 2,
    isDST: false,
    text: "(UTC+02:00) Libya",
    utc: ["Libya"],
    _id: "+02:00 Libya",
    label: "Libya  (EET)",
  },
  {
    value: "MET",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) MET",
    utc: ["MET"],
    _id: "+02:00 MET",
    label: "MET  (CEST)",
  },
  {
    value: "MST",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) MST",
    utc: ["MST"],
    _id: "-07:00 MST",
    label: "MST  (MST)",
  },
  {
    value: "MST7MDT",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) MST7MDT",
    utc: ["MST7MDT"],
    _id: "-06:00 MST7MDT",
    label: "MST7MDT  (MDT)",
  },
  {
    value: "Mexico/BajaNorte",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) Mexico/BajaNorte",
    utc: ["Mexico/BajaNorte"],
    _id: "-07:00 BajaNorte",
    label: "Mexico/BajaNorte  (PDT)",
  },
  {
    value: "Mexico/BajaSur",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) Mexico/BajaSur",
    utc: ["Mexico/BajaSur"],
    _id: "-07:00 BajaSur",
    label: "Mexico/BajaSur  (MST)",
  },
  {
    value: "Mexico/General",
    short: "CST",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Mexico/General",
    utc: ["Mexico/General"],
    _id: "-06:00 General",
    label: "Mexico/General  (CST)",
  },
  {
    value: "NZ",
    short: "NZDT",
    offset: 13,
    isDST: true,
    text: "(UTC+13:00) NZ",
    utc: ["NZ"],
    _id: "+13:00 NZ",
    label: "NZ  (NZDT)",
  },
  {
    value: "NZ-CHAT",
    short: "UTC+1345",
    offset: 13.75,
    isDST: true,
    text: "(UTC+13:45) NZ-CHAT",
    utc: ["NZ-CHAT"],
    _id: "+13:45 NZ-CHAT",
    label: "NZ-CHAT  (UTC+1345)",
  },
  {
    value: "Navajo",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) Navajo",
    utc: ["Navajo"],
    _id: "-06:00 Navajo",
    label: "Navajo  (MDT)",
  },
  {
    value: "PRC",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) PRC",
    utc: ["PRC"],
    _id: "+08:00 PRC",
    label: "PRC  (CST)",
  },
  {
    value: "PST8PDT",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) PST8PDT",
    utc: ["PST8PDT"],
    _id: "-07:00 PST8PDT",
    label: "PST8PDT  (PDT)",
  },
  {
    value: "Pacific/Apia",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Pacific/Apia",
    utc: ["Pacific/Apia"],
    _id: "+13:00 Apia",
    label: "Pacific/Apia  (UTC+13)",
  },
  {
    value: "Pacific/Auckland",
    short: "NZDT",
    offset: 13,
    isDST: true,
    text: "(UTC+13:00) Pacific/Auckland",
    utc: ["Pacific/Auckland"],
    _id: "+13:00 Auckland",
    label: "Pacific/Auckland  (NZDT)",
  },
  {
    value: "Pacific/Bougainville",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Bougainville",
    utc: ["Pacific/Bougainville"],
    _id: "+11:00 Bougainville",
    label: "Pacific/Bougainville  (UTC+11)",
  },
  {
    value: "Pacific/Chatham",
    short: "UTC+1345",
    offset: 13.75,
    isDST: true,
    text: "(UTC+13:45) Pacific/Chatham",
    utc: ["Pacific/Chatham"],
    _id: "+13:45 Chatham",
    label: "Pacific/Chatham  (UTC+1345)",
  },
  {
    value: "Pacific/Chuuk",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Chuuk",
    utc: ["Pacific/Chuuk"],
    _id: "+10:00 Chuuk",
    label: "Pacific/Chuuk  (UTC+10)",
  },
  {
    value: "Pacific/Easter",
    short: "UTC-05",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) Pacific/Easter",
    utc: ["Pacific/Easter"],
    _id: "-05:00 Easter",
    label: "Pacific/Easter  (UTC-05)",
  },
  {
    value: "Pacific/Efate",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Efate",
    utc: ["Pacific/Efate"],
    _id: "+11:00 Efate",
    label: "Pacific/Efate  (UTC+11)",
  },
  {
    value: "Pacific/Enderbury",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Pacific/Enderbury",
    utc: ["Pacific/Enderbury"],
    _id: "+13:00 Enderbury",
    label: "Pacific/Enderbury  (UTC+13)",
  },
  {
    value: "Pacific/Fakaofo",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Pacific/Fakaofo",
    utc: ["Pacific/Fakaofo"],
    _id: "+13:00 Fakaofo",
    label: "Pacific/Fakaofo  (UTC+13)",
  },
  {
    value: "Pacific/Fiji",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Fiji",
    utc: ["Pacific/Fiji"],
    _id: "+12:00 Fiji",
    label: "Pacific/Fiji  (UTC+12)",
  },
  {
    value: "Pacific/Funafuti",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Funafuti",
    utc: ["Pacific/Funafuti"],
    _id: "+12:00 Funafuti",
    label: "Pacific/Funafuti  (UTC+12)",
  },
  {
    value: "Pacific/Galapagos",
    short: "UTC-06",
    offset: -6,
    isDST: false,
    text: "(UTC-06:00) Pacific/Galapagos",
    utc: ["Pacific/Galapagos"],
    _id: "-06:00 Galapagos",
    label: "Pacific/Galapagos  (UTC-06)",
  },
  {
    value: "Pacific/Gambier",
    short: "UTC-09",
    offset: -9,
    isDST: false,
    text: "(UTC-09:00) Pacific/Gambier",
    utc: ["Pacific/Gambier"],
    _id: "-09:00 Gambier",
    label: "Pacific/Gambier  (UTC-09)",
  },
  {
    value: "Pacific/Guadalcanal",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Guadalcanal",
    utc: ["Pacific/Guadalcanal"],
    _id: "+11:00 Guadalcanal",
    label: "Pacific/Guadalcanal  (UTC+11)",
  },
  {
    value: "Pacific/Guam",
    short: "ChST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Guam",
    utc: ["Pacific/Guam"],
    _id: "+10:00 Guam",
    label: "Pacific/Guam  (ChST)",
  },
  {
    value: "Pacific/Honolulu",
    short: "HST",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Pacific/Honolulu",
    utc: ["Pacific/Honolulu"],
    _id: "-10:00 Honolulu",
    label: "Pacific/Honolulu  (HST)",
  },
  {
    value: "Pacific/Johnston",
    short: "HST",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Pacific/Johnston",
    utc: ["Pacific/Johnston"],
    _id: "-10:00 Johnston",
    label: "Pacific/Johnston  (HST)",
  },
  {
    value: "Pacific/Kanton",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Pacific/Kanton",
    utc: ["Pacific/Kanton"],
    _id: "+13:00 Kanton",
    label: "Pacific/Kanton  (UTC+13)",
  },
  {
    value: "Pacific/Kiritimati",
    short: "UTC+14",
    offset: 14,
    isDST: false,
    text: "(UTC+14:00) Pacific/Kiritimati",
    utc: ["Pacific/Kiritimati"],
    _id: "+14:00 Kiritimati",
    label: "Pacific/Kiritimati  (UTC+14)",
  },
  {
    value: "Pacific/Kosrae",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Kosrae",
    utc: ["Pacific/Kosrae"],
    _id: "+11:00 Kosrae",
    label: "Pacific/Kosrae  (UTC+11)",
  },
  {
    value: "Pacific/Kwajalein",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Kwajalein",
    utc: ["Pacific/Kwajalein"],
    _id: "+12:00 Kwajalein",
    label: "Pacific/Kwajalein  (UTC+12)",
  },
  {
    value: "Pacific/Majuro",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Majuro",
    utc: ["Pacific/Majuro"],
    _id: "+12:00 Majuro",
    label: "Pacific/Majuro  (UTC+12)",
  },
  {
    value: "Pacific/Marquesas",
    short: "UTC-0930",
    offset: -9.5,
    isDST: false,
    text: "(UTC-10:30) Pacific/Marquesas",
    utc: ["Pacific/Marquesas"],
    _id: "-10:30 Marquesas",
    label: "Pacific/Marquesas  (UTC-0930)",
  },
  {
    value: "Pacific/Midway",
    short: "SST",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Pacific/Midway",
    utc: ["Pacific/Midway"],
    _id: "-11:00 Midway",
    label: "Pacific/Midway  (SST)",
  },
  {
    value: "Pacific/Nauru",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Nauru",
    utc: ["Pacific/Nauru"],
    _id: "+12:00 Nauru",
    label: "Pacific/Nauru  (UTC+12)",
  },
  {
    value: "Pacific/Niue",
    short: "UTC-11",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Pacific/Niue",
    utc: ["Pacific/Niue"],
    _id: "-11:00 Niue",
    label: "Pacific/Niue  (UTC-11)",
  },
  {
    value: "Pacific/Norfolk",
    short: "UTC+12",
    offset: 12,
    isDST: true,
    text: "(UTC+12:00) Pacific/Norfolk",
    utc: ["Pacific/Norfolk"],
    _id: "+12:00 Norfolk",
    label: "Pacific/Norfolk  (UTC+12)",
  },
  {
    value: "Pacific/Noumea",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Noumea",
    utc: ["Pacific/Noumea"],
    _id: "+11:00 Noumea",
    label: "Pacific/Noumea  (UTC+11)",
  },
  {
    value: "Pacific/Pago_Pago",
    short: "SST",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Pacific/Pago Pago",
    utc: ["Pacific/Pago_Pago"],
    _id: "-11:00 Pago Pago",
    label: "Pacific/Pago_Pago  (SST)",
  },
  {
    value: "Pacific/Palau",
    short: "UTC+09",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) Pacific/Palau",
    utc: ["Pacific/Palau"],
    _id: "+09:00 Palau",
    label: "Pacific/Palau  (UTC+09)",
  },
  {
    value: "Pacific/Pitcairn",
    short: "UTC-08",
    offset: -8,
    isDST: false,
    text: "(UTC-08:00) Pacific/Pitcairn",
    utc: ["Pacific/Pitcairn"],
    _id: "-08:00 Pitcairn",
    label: "Pacific/Pitcairn  (UTC-08)",
  },
  {
    value: "Pacific/Pohnpei",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Pohnpei",
    utc: ["Pacific/Pohnpei"],
    _id: "+11:00 Pohnpei",
    label: "Pacific/Pohnpei  (UTC+11)",
  },
  {
    value: "Pacific/Ponape",
    short: "UTC+11",
    offset: 11,
    isDST: false,
    text: "(UTC+11:00) Pacific/Ponape",
    utc: ["Pacific/Ponape"],
    _id: "+11:00 Ponape",
    label: "Pacific/Ponape  (UTC+11)",
  },
  {
    value: "Pacific/Port_Moresby",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Port Moresby",
    utc: ["Pacific/Port_Moresby"],
    _id: "+10:00 Port Moresby",
    label: "Pacific/Port_Moresby  (UTC+10)",
  },
  {
    value: "Pacific/Rarotonga",
    short: "UTC-10",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Pacific/Rarotonga",
    utc: ["Pacific/Rarotonga"],
    _id: "-10:00 Rarotonga",
    label: "Pacific/Rarotonga  (UTC-10)",
  },
  {
    value: "Pacific/Saipan",
    short: "ChST",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Saipan",
    utc: ["Pacific/Saipan"],
    _id: "+10:00 Saipan",
    label: "Pacific/Saipan  (ChST)",
  },
  {
    value: "Pacific/Samoa",
    short: "SST",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) Pacific/Samoa",
    utc: ["Pacific/Samoa"],
    _id: "-11:00 Samoa",
    label: "Pacific/Samoa  (SST)",
  },
  {
    value: "Pacific/Tahiti",
    short: "UTC-10",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) Pacific/Tahiti",
    utc: ["Pacific/Tahiti"],
    _id: "-10:00 Tahiti",
    label: "Pacific/Tahiti  (UTC-10)",
  },
  {
    value: "Pacific/Tarawa",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Tarawa",
    utc: ["Pacific/Tarawa"],
    _id: "+12:00 Tarawa",
    label: "Pacific/Tarawa  (UTC+12)",
  },
  {
    value: "Pacific/Tongatapu",
    short: "UTC+13",
    offset: 13,
    isDST: false,
    text: "(UTC+13:00) Pacific/Tongatapu",
    utc: ["Pacific/Tongatapu"],
    _id: "+13:00 Tongatapu",
    label: "Pacific/Tongatapu  (UTC+13)",
  },
  {
    value: "Pacific/Truk",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Truk",
    utc: ["Pacific/Truk"],
    _id: "+10:00 Truk",
    label: "Pacific/Truk  (UTC+10)",
  },
  {
    value: "Pacific/Wake",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Wake",
    utc: ["Pacific/Wake"],
    _id: "+12:00 Wake",
    label: "Pacific/Wake  (UTC+12)",
  },
  {
    value: "Pacific/Wallis",
    short: "UTC+12",
    offset: 12,
    isDST: false,
    text: "(UTC+12:00) Pacific/Wallis",
    utc: ["Pacific/Wallis"],
    _id: "+12:00 Wallis",
    label: "Pacific/Wallis  (UTC+12)",
  },
  {
    value: "Pacific/Yap",
    short: "UTC+10",
    offset: 10,
    isDST: false,
    text: "(UTC+10:00) Pacific/Yap",
    utc: ["Pacific/Yap"],
    _id: "+10:00 Yap",
    label: "Pacific/Yap  (UTC+10)",
  },
  {
    value: "Poland",
    short: "CEST",
    offset: 2,
    isDST: true,
    text: "(UTC+02:00) Poland",
    utc: ["Poland"],
    _id: "+02:00 Poland",
    label: "Poland  (CEST)",
  },
  {
    value: "Portugal",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) Portugal",
    utc: ["Portugal"],
    _id: "+01:00 Portugal",
    label: "Portugal  (WEST)",
  },
  {
    value: "ROC",
    short: "CST",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) ROC",
    utc: ["ROC"],
    _id: "+08:00 ROC",
    label: "ROC  (CST)",
  },
  {
    value: "ROK",
    short: "KST",
    offset: 9,
    isDST: false,
    text: "(UTC+09:00) ROK",
    utc: ["ROK"],
    _id: "+09:00 ROK",
    label: "ROK  (KST)",
  },
  {
    value: "Singapore",
    short: "UTC+08",
    offset: 8,
    isDST: false,
    text: "(UTC+08:00) Singapore",
    utc: ["Singapore"],
    _id: "+08:00 Singapore",
    label: "Singapore  (UTC+08)",
  },
  {
    value: "Turkey",
    short: "UTC+03",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) Turkey",
    utc: ["Turkey"],
    _id: "+03:00 Turkey",
    label: "Turkey  (UTC+03)",
  },
  {
    value: "UCT",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) UCT",
    utc: ["UCT"],
    _id: "+00:00 UCT",
    label: "UCT  (UTC)",
  },
  {
    value: "US/Alaska",
    short: "AKDT",
    offset: -8,
    isDST: true,
    text: "(UTC-08:00) US/Alaska",
    utc: ["US/Alaska"],
    _id: "-08:00 Alaska",
    label: "US/Alaska  (AKDT)",
  },
  {
    value: "US/Aleutian",
    short: "HDT",
    offset: -9,
    isDST: true,
    text: "(UTC-09:00) US/Aleutian",
    utc: ["US/Aleutian"],
    _id: "-09:00 Aleutian",
    label: "US/Aleutian  (HDT)",
  },
  {
    value: "US/Arizona",
    short: "MST",
    offset: -7,
    isDST: false,
    text: "(UTC-07:00) US/Arizona",
    utc: ["US/Arizona"],
    _id: "-07:00 Arizona",
    label: "US/Arizona  (MST)",
  },
  {
    value: "US/Central",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) US/Central",
    utc: ["US/Central"],
    _id: "-05:00 Central",
    label: "US/Central  (CDT)",
  },
  {
    value: "US/East-Indiana",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) US/East-Indiana",
    utc: ["US/East-Indiana"],
    _id: "-04:00 East-Indiana",
    label: "US/East-Indiana  (EDT)",
  },
  {
    value: "US/Eastern",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) US/Eastern",
    utc: ["US/Eastern"],
    _id: "-04:00 Eastern",
    label: "US/Eastern  (EDT)",
  },
  {
    value: "US/Hawaii",
    short: "HST",
    offset: -10,
    isDST: false,
    text: "(UTC-10:00) US/Hawaii",
    utc: ["US/Hawaii"],
    _id: "-10:00 Hawaii",
    label: "US/Hawaii  (HST)",
  },
  {
    value: "US/Indiana-Starke",
    short: "CDT",
    offset: -5,
    isDST: true,
    text: "(UTC-05:00) US/Indiana-Starke",
    utc: ["US/Indiana-Starke"],
    _id: "-05:00 Indiana-Starke",
    label: "US/Indiana-Starke  (CDT)",
  },
  {
    value: "US/Michigan",
    short: "EDT",
    offset: -4,
    isDST: true,
    text: "(UTC-04:00) US/Michigan",
    utc: ["US/Michigan"],
    _id: "-04:00 Michigan",
    label: "US/Michigan  (EDT)",
  },
  {
    value: "US/Mountain",
    short: "MDT",
    offset: -6,
    isDST: true,
    text: "(UTC-06:00) US/Mountain",
    utc: ["US/Mountain"],
    _id: "-06:00 Mountain",
    label: "US/Mountain  (MDT)",
  },
  {
    value: "US/Pacific",
    short: "PDT",
    offset: -7,
    isDST: true,
    text: "(UTC-07:00) US/Pacific",
    utc: ["US/Pacific"],
    _id: "-07:00 Pacific",
    label: "US/Pacific  (PDT)",
  },
  {
    value: "US/Samoa",
    short: "SST",
    offset: -11,
    isDST: false,
    text: "(UTC-11:00) US/Samoa",
    utc: ["US/Samoa"],
    _id: "-11:00 Samoa",
    label: "US/Samoa  (SST)",
  },
  {
    value: "UTC",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) UTC",
    utc: ["UTC"],
    _id: "+00:00 UTC",
    label: "UTC  (UTC)",
  },
  {
    value: "Universal",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Universal",
    utc: ["Universal"],
    _id: "+00:00 Universal",
    label: "Universal  (UTC)",
  },
  {
    value: "W-SU",
    short: "MSK",
    offset: 3,
    isDST: false,
    text: "(UTC+03:00) W-SU",
    utc: ["W-SU"],
    _id: "+03:00 W-SU",
    label: "W-SU  (MSK)",
  },
  {
    value: "WET",
    short: "WEST",
    offset: 1,
    isDST: true,
    text: "(UTC+01:00) WET",
    utc: ["WET"],
    _id: "+01:00 WET",
    label: "WET  (WEST)",
  },
  {
    value: "Zulu",
    short: "UTC",
    offset: 0,
    isDST: false,
    text: "(UTC+00:00) Zulu",
    utc: ["Zulu"],
    _id: "+00:00 Zulu",
    label: "Zulu  (UTC)",
  },
];
export default timeZone;
