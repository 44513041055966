import styled from "styled-components";

export const S3SettingUpdateModal = styled.div`
  .modal-body {
    padding: 0px;
  }
`;
export const HeadingDivStyle = styled.div`
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  padding: 10px 20px;
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const ContainerDivStyle = styled.div`
  padding: 10px 20px;
`;

export const ContainerHead = styled.div`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  margin-bottom: 6px;
`;

export const S3SettingTable = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 4px;
`;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  line-height: 14px;
  border-bottom: 1px solid
    ${({ isHeader, theme }) =>
      isHeader ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.grayL6 || "#F2F2F2"};
  :last-child {
    border-bottom: none;
  }
  /*margin: ${({ isHeader }) => (isHeader ? "auto auto -12px auto" : "10px auto")};*/
  background: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  @media (max-width: 1100px) {
    height: auto;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "8px 10px" : "8px 13px")};
  color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL1 || "#323232" : theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "15px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  height: calc(100vh - 597px);

  ::-webkit-scrollbar-track {
    background-color: rgba(236, 236, 236, 0);
    border-radius: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888e94;
    border-width: 10px 3px 10px 3px;
    border-radius: 12px;
    border: 3px solid rgb(255, 255, 255);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #636161;
  }
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const ButtonContainer = styled.div`
  padding: 10px 20px;
  text-align: center;
`;
