export const initialState = {
  accountList: [],
  worksiteList: [],
  assetList: [],
  assetTypeList: [],
  userList: [],
  productList: [],
  userAccessLevelForAccount: [
    { name: "Account Admin", _id: "account_admin" },
    { name: "Account Viewer", _id: "account_viewer" },
    { name: "Worksite Admin", _id: "site_admin" },
    { name: "Operator", _id: "operator" },
  ],
  userAccessLevelForSite: [
    { name: "Worksite Admin", _id: "site_admin" },
    { name: "Operator", _id: "operator" },
  ],
  accountId: "all",
  worksiteId: "all",
  assetId: "all",
  assetTypeId: "all",
  dropdownUserAccessLevel: "all",
  qrCodeFilterBy: "assets",
  productType: "all",
  duration: { _id: 1, label: "Today" },
};
