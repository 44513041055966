import React, { useState } from "react";
import InputField from "./InputField.component";

const InputPasswordPin = ({ value, onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return <InputField value={inputValue} onChange={handleInputChange} {...rest} />;
};

export default InputPasswordPin;
