import { analyticsTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case analyticsTypes.GET_USER_CHARTS_LIST_LOADING:
      return {
        ...state,
        getUserChartsListLoading: action.data,
      };

    case analyticsTypes.GET_USER_CHARTS_LIST_SUCCESS:
      return {
        ...state,
        getUserChartsListLoading: false,
        getUserChartsList: {
          loaded: true,
          success: action.data,
          error: null,
        },
      };
    case analyticsTypes.GET_USER_CHARTS_LIST_ERROR:
      return {
        ...state,
        getUserChartsListLoading: false,
        getUserChartsList: {
          success: null,
          error: action.data,
        },
      };
    case analyticsTypes.GET_ALL_CHARTS_LIST_LOADING:
      return {
        ...state,
        getAllChartsListLoading: action.data,
      };

    case analyticsTypes.GET_ALL_CHARTS_LIST_SUCCESS:
      return {
        ...state,
        getAllChartsListLoading: false,
        getAllChartsList: {
          success: action.data,
          error: null,
        },
      };
    case analyticsTypes.GET_ALL_CHARTS_LIST_ERROR:
      return {
        ...state,
        getAllChartsListLoading: false,
        getAllChartsList: {
          success: null,
          error: action.data,
        },
      };
    case analyticsTypes.SAVE_USER_CHARTS_LIST_LOADING:
      return {
        ...state,
        saveUserChartsListLoading: action.data,
      };

    case analyticsTypes.SAVE_USER_CHARTS_LIST_SUCCESS:
      return {
        ...state,
        saveUserChartsListLoading: false,
        saveUserChartsList: {
          payload: [],
          success: action.data,
          error: null,
        },
      };
    case analyticsTypes.SAVE_USER_CHARTS_LIST_ERROR:
      return {
        ...state,
        saveUserChartsListLoading: false,
        saveUserChartsList: {
          payload: [],
          success: null,
          error: action.data,
        },
      };
    case analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_LOADING:
      return {
        ...state,
        getJwtTokenLoading: action.data,
      };

    case analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_SUCCESS:
      return {
        ...state,
        getJwtTokenLoading: false,
        getJwtToken: {
          success: action.data,
          error: null,
        },
      };
    case analyticsTypes.GET_JWT_TOKEN_FOR_CHARTS_ERROR:
      return {
        ...state,
        getJwtTokenLoading: false,
        getJwtToken: {
          success: null,
          error: action.data,
        },
      };
    case analyticsTypes.SELECT_CHART:
      return {
        ...state,
        selectedChart: action.data,
      };
    case analyticsTypes.SHOW_LOADER:
      return {
        ...state,
        showLoader: action.data,
      };
    case analyticsTypes.SHOW_ERROR:
      return {
        ...state,
        showError: action.data,
      };
    // Returning default state
    default:
      return state;
  }
};
