import React from "react";
import { connect } from "react-redux";

const CustomFieldHOC = (props) => {
  const { selectedUserType, fieldType, children } = props;
  const customField = selectedUserType.customFields.find((field) => field.type === fieldType);

  return <>{customField ? children : null}</>;
};

const mapStateToProps = (state) => ({
  permissions: state?.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

CustomFieldHOC.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldHOC);
