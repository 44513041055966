import React from "react";
import AccountCard from "./AccountCard.component";
import AccountOverview from "./AccountOverview.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setEditAlertModal } from "../../../redux/modals/action";
import { AccountWrapper, ManagementRightBottomWrapper } from "./Account.component.styles";

const Account = ({ imageLoaded, setImageLoaded, editForm, setEditAlertModal }) => (
  <AccountWrapper>
    <AccountCard />

    <ManagementRightBottomWrapper>
      {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
      <AccountOverview imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
    </ManagementRightBottomWrapper>
  </AccountWrapper>
);

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  editForm: state.forms.editForm,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
