// DropdownSection.js
import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DropdownFilter from "../../../components/DropdownComponent/DropdownWrapper";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
} from "../../../redux/dropdownFilters/action";
import { useTranslation } from "react-i18next";

const AssetFilters = ({
  isSuperAdmin,
  dropdownAccountList,
  dropdownWorksiteList,
  dropdownAssetTypeList,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAssetTypeId,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  setEditAlertModal,
  showWorksiteDropdown,
  editForm,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isSuperAdmin && (
        <Col>
          <DropdownFilter
            filter={{
              type: "Account",
              name: "companyName",
              key: "companyName",
              data: dropdownAccountList,
            }}
            value={dropdownAccountId}
            label={t("filter")}
            handleFilter={(value) => (editForm ? setDropdownAccountId(value) : setEditAlertModal(true))}
          />
        </Col>
      )}

      {showWorksiteDropdown && (
        <Col>
          <DropdownFilter
            filter={{
              type: "Worksite",
              key: "name",
              data: dropdownWorksiteList,
            }}
            value={dropdownWorksiteId}
            label={t("filter")}
            handleFilter={(value) => (editForm ? setDropdownWorksiteId(value) : setEditAlertModal(true))}
          />
        </Col>
      )}

      <Col>
        <DropdownFilter
          filter={{
            type: "Asset Type",
            key: "AssetTypeName",
            data: dropdownAssetTypeList,
          }}
          value={dropdownAssetTypeId}
          label={t("filter")}
          handleFilter={(value) => (editForm ? setDropdownAssetTypeId(value) : setEditAlertModal(true))}
          disabled={isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all")}
        />
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters.assetTypeId,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAccountList: state.dropdownFilters.accountList,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  isSuperAdmin: state.user.isSuperAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  dropdownFilterAssetTypeId: state.dropdownFilters.assetTypeId,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetFilters));
