export const worksiteTypes = {
  //For Worksites List
  GET_ALL_WORKSITES_LOADING: "GET_ALL_WORKSITES_LOADING",
  GET_ALL_WORKSITES_SUCCESS: "GET_ALL_WORKSITES_SUCCESS",
  GET_ALL_WORKSITES_ERROR: "GET_ALL_WORKSITES_ERROR",

  // Get worksite by id
  GET_WORKSITE_DETAIL_LOADING: "GET_WORKSITE_DETAIL_LOADING",
  GET_WORKSITE_DETAIL_SUCCESS: "GET_WORKSITE_DETAIL_SUCCESS",
  GET_WORKSITE_DETAIL_ERROR: "GET_WORKSITE_DETAIL_ERROR",

  //For adding new worksite
  POST_NEW_WORKSITE_LOADING: "POST_NEW_WORKSITE_LOADING",
  POST_NEW_WORKSITE_SUCCESS: "POST_NEW_WORKSITE_SUCCESS",
  RESET_WORKSITES_SUCCESS_FLAG: "RESET_WORKSITES_SUCCESS_FLAG",
  POST_NEW_WORKSITE_ERROR: "POST_NEW_WORKSITE_ERROR",

  //For updating worksite status
  WORKSITE_STATUS_UPDATE_LOADING: "WORKSITE_STATUS_UPDATE_LOADING",
  WORKSITE_STATUS_UPDATE_SUCCESS: "WORKSITE_STATUS_UPDATE_SUCCESS",
  WORKSITE_STATUS_UPDATE_ERROR: "WORKSITE_STATUS_UPDATE_ERROR",

  //For updating worksite details
  WORKSITE_DETAILS_UPDATE_LOADING: "WORKSITE_DETAILS_UPDATE_LOADING",
  WORKSITE_DETAILS_UPDATE_SUCCESS: "WORKSITE_DETAILS_UPDATE_SUCCESS",
  WORKSITE_DETAILS_UPDATE_ERROR: "WORKSITE_DETAILS_UPDATE_ERROR",

  //For updating worksite name
  WORKSITE_NAME_UPDATE_LOADING: "WORKSITE_NAME_UPDATE_LOADING",
  WORKSITE_NAME_UPDATE_SUCCESS: "WORKSITE_NAME_UPDATE_SUCCESS",
  WORKSITE_NAME_UPDATE_ERROR: "WORKSITE_NAME_UPDATE_ERROR",

  //For selected worksite
  SELECTED_WORKSITE: "SELECTED_WORKSITE",

  //For Resetting Worksite state
  RESET_WORKSITES_STATE: "RESET_WORKSITES_STATE",



     // Deactivated Users by User Type Deactivation
     PRE_DEACTIVATED_WORKSITE_LOADING: "PRE_DEACTIVATED_WORKSITE_LOADING",
     PRE_DEACTIVATED_WORKSITE_SUCCESS: "PRE_DEACTIVATED_WORKSITE_SUCCESS",
     PRE_DEACTIVATED_WORKSITE_ERROR: "PRE_DEACTIVATED_WORKSITE_ERROR",
   
     // Deactivate the Users by confirm button in User Type Deactivation
     DEACTIVATE_WORKSITE_LOADING: "DEACTIVATE_WORKSITE_LOADING",
     DEACTIVATE_WORKSITE_SUCCESS: "DEACTIVATE_WORKSITE_SUCCESS",
     DEACTIVATE_WORKSITE_ERROR: "DEACTIVATE_WORKSITE_ERROR",
};
