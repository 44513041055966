import { inspectionTypes } from "./types";
import { initialState } from "./initialState";

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case inspectionTypes.UPLOAD_IMAGE_LOADING:
      return {
        ...state,
        uploadImageLoading: true,
        uploadImageError: false,
        uploadImageSuccess: false,
      };

    case inspectionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageLoading: false,
        uploadImageError: false,
        uploadImageSuccess: true,
      };
    case inspectionTypes.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadImageLoading: false,
        uploadImageError: true,
        uploadImageSuccess: false,
      };

    case inspectionTypes.DELETE_IMAGE_LOADING:
      return {
        ...state,
        deleteImageLoading: true,
        deleteImageError: false,
        deleteImageSuccess: false,
      };

    case inspectionTypes.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        deleteImageLoading: false,
        deleteImageError: false,
        deleteImageSuccess: true,
      };

    case inspectionTypes.DELETE_IMAGE_ERROR:
      return {
        ...state,
        deleteImageLoading: false,
        deleteImageError: true,
        deleteImageSuccess: false,
      };

    case inspectionTypes.GET_CHECKLIST_BY_ID_LOADING:
      return {
        ...state,
        getChecklistLoading: true,
        getChecklistError: false,
        getChecklistSuccess: false,
      };

    case inspectionTypes.GET_CHECKLIST_BY_ID_SUCCESS:
      return {
        ...state,
        getChecklistLoading: false,
        getChecklistError: false,
        getChecklistSuccess: true,
        checklist: action.data,
      };

    case inspectionTypes.GET_CHECKLIST_BY_ID_ERROR:
      return {
        ...state,
        getChecklistLoading: false,
        getChecklistError: true,
        getChecklistSuccess: false,
      };

    case inspectionTypes.POST_INSPECTION_RESPONSE_LOADING:
      return {
        ...state,
        postInspectionRepsonseLoading: true,
        postInspectionRepsonseError: false,
        postInspectionRepsonseSuccess: false,
      };

    case inspectionTypes.POST_INSPECTION_RESPONSE_SUCCESS:
      return {
        ...state,
        postInspectionRepsonseLoading: false,
        postInspectionRepsonseError: false,
        postInspectionRepsonseSuccess: true,
      };

    case inspectionTypes.POST_INSPECTION_RESPONSE_ERROR:
      return {
        ...state,
        postInspectionRepsonseLoading: false,
        postInspectionRepsonseError: true,
        postInspectionRepsonseSuccess: false,
      };

    case inspectionTypes.TO_STORE_SELECTED_ASSET:
      return {
        ...state,
        selectedAsset: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
