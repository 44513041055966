import styled from "styled-components";

// Styled component for the row
export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  margin: 0;
`;

// Styled component for the button wrapper
export const StyledButtonWrapper = styled.div`
  margin-right: 8px;
  padding-left: 0;
`;

export const DivListTableStyle = styled.div`
  margin-top: 20px;
  width: 100%;
`;
