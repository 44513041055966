export const initialState = {
  //For Account List
  AccountLoading: false,
  AccountSuccess: false,
  AccountError: false,
  Accounts: [],

  // For accout detail fetch
  accountDetailSuccess: false,
  accountDetailLoading: false,
  accountDetailError: false,

  //For Account Update
  UpdateAccountLoading: false,
  UpdateAccountSuccess: false,
  UpdateAccountError: false,
  passwordPolicy: {
    passwordExpiration: {
      status: false,
      duration: 365,
    },
    passwordReuse: {
      status: false,
      count: 3,
    },
    passwordLockoutDashboard: {
      status: true,
      count: 5,
    },
    passwordLockoutAndroid: {
      status: true,
      count: 5,
    },
    minLength: 8,
    upperCaseRequired: true,
    lowerCaseRequired: true,
    numberRequired: true,
    specialCharacterRequired: true,
    changeOwnPasswords: true,
    changeOthersPasswords: true,
  },

  passwordPolicyUpdateSuccess: false,
  passwordPolicyUpdateLoading: false,
  passwordPolicyUpdateError: false,
  changePasswordAfterFirstLogin: false,

  // //For Asset Type Overview
  // AssetTypeOverview: null,

  // //For Asset Overview
  // AssetOverview: null,

  // //For Inspection Asset
  // InpectionAsset: null,

  // //For Adding New Asset
  // AddAssetLoading: false,
  // AddAssetSuccess: false,
  // AddAssetError: false,

  // //For Asset Status
  // AssetStatusLoading: false,
  // AssetStatusSuccess: false,
  // AssetStatusError: false,
  // AssetStatus: false,
  // AssetStatusMessage: "",

  // //For Asset Image Update
  // AssetImageLoading: false,
  // AssetImageSuccess: false,
  // AssetImageError: false,
  // AssetImage: "",

  // //For Generate CSV
  // GenerateCSVLoading: false,
  // GenerateCSVSuccess: false,
  // GenerateCSVError: false,
  // GeneratingCSVData: [],

  // //For Asset Name Edit
  // EditAssetNameLoading: false,
  // EditAssetNameSuccess: false,
  // EditAssetNameError: false,

  // //For Asset Checklist
  // AssetCheckListLoading: false,
  // AssetCheckListSuccess: false,
  // AssetCheckListError: false,
  // AssetCheckList: [],

  // //For Inspection Upload Media
  // InspectionMediaLoading: false,
  // InspectionMediaSuccess: false,
  // InspectionMediaError: false,
  // InspectionMedia: null,

  // //For Answer Submit
  // AnswerLoading: false,
  // AnswerSuccess: false,
  // AnswerError: false,
  // Result: null,
};
