import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle, Polyline, FeatureGroup } from "react-leaflet";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";

import { NoDataMessage, GPSTrackingWrapper, Disclaimer } from "./GPSTracking.styles";
import LastKnownLocation from "./LastKnownLocation";
import blueIcon from "../../../../assets/pin-blue.png";
import redIcon from "../../../../assets/pin-red.png";

const ZOOM = 15;

const GPSTracking = ({ position, locationData, isGPSLoading, startDateUTC, endDateUTC }) => {
  let prevPosition = {
    lat: locationData[0]?.lat,
    lon: locationData[0]?.lon,
  };

  const compareDates = (a, b) => {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else {
      return 0;
    }
  };

  const coordinates = locationData
    // ?.sort(compareDates)
    // ?.filter((location) => {
    //   if (location.date >= endDateUTC || location.date <= startDateUTC) {
    //     return false;
    //   }
    //   if (location?.lon != prevPosition?.lon || location?.lat != prevPosition?.lat) {
    //     prevPosition.lon = location?.lon;
    //     prevPosition.lat = location?.lat;
    //     return true;
    //   }
    //   return false;
    // })
    .map((location) => [location.lat, location.lon]);

  const startIcon = new Leaflet.Icon({
    iconUrl: redIcon,
    iconSize: [30, 35],
    iconAnchor: [15, 35],
    popupAnchor: [0, -14],
    shadowSize: [41, 41],
  });

  const endIcon = new Leaflet.Icon({
    iconUrl: blueIcon,
    iconSize: [30, 35],
    iconAnchor: [15, 35],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const mapRef = useRef(null);

  useEffect(() => {
    console.log("Called", coordinates[0], "coordinates--length", coordinates.length);
    mapRef?.current?.setView(coordinates[0], mapRef.current.getZoom());
  }, [coordinates]);

  return (
    <GPSTrackingWrapper>
      {locationData.length === 0 || coordinates.length === 0 || isGPSLoading ? (
        <>
          <NoDataMessage>
            {isGPSLoading ? (
              <div className="spinner-border spinner-border-sm" role="status"></div>
            ) : (
              <>
                <Trans>GPS_NOT_AVAILABLE</Trans>
                <br></br>
                <Trans>PLEASE_CHECK_INTERNET</Trans>
              </>
            )}
          </NoDataMessage>
          <LastKnownLocation position={position} />
        </>
      ) : (
        <MapContainer
          center={coordinates?.[0]?.length === 2 ? coordinates[0] : position}
          zoom={ZOOM}
          scrollWheelZoom={true}
          ref={mapRef}
        >
          <FeatureGroup>
            <Polyline positions={coordinates} color="blue" />
          </FeatureGroup>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* <Polyline pathOptions={limeOptions} positions={coordinates} /> */}
          <Marker position={[...(coordinates[0] || [])]} icon={startIcon}>
            <Popup>
              <Trans>DEVICE_LOCATION_START</Trans>
            </Popup>
            <Circle center={[...(coordinates[0] || [])]} fillColor="#FF0000" radius={10} color="#FF0000" />
          </Marker>

          <Marker position={[...(coordinates[coordinates.length - 1] || [])]} icon={endIcon}>
            <Popup>
              <Trans>DEVICE_LOCATION_END</Trans>
            </Popup>
            <Circle center={[...(coordinates[coordinates.length - 1] || [])]} fillColor="blue" radius={10} />
          </Marker>
        </MapContainer>
      )}
      <Disclaimer>The accuracy of the data depends on multiple factors. </Disclaimer>
    </GPSTrackingWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    locationData: state.deviceInfo?.locationData || [],
    isGPSLoading: state.deviceInfo?.isGPSLoading || false,
    startDateUTC: state.deviceInfo?.startDateUTC,
    endDateUTC: state.deviceInfo?.endDateUTC,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GPSTracking);
