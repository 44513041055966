//To validate email field
export const toValidateEmail = (email) => {
  let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email) {
    return "Email is required";
  } else if (!pattern.test(email)) {
    return "Please enter a valid email";
  }
  //     if (pattern.test(email)) {
  //       return { success: true, msg: "" };
  //     } else {
  //       return { error: true, msg: "Please enter a valid email" };
  //     }
  //   } else {
  //     return { error: true, msg: "Email is required" };
  //   }
};

//To validate password
export const passwordFieldValidation = (value, passwordRegEx) => {
  let validation = passwordRegEx
    ? passwordRegEx
    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\d)(?=.*[~!@#$%^&(),\-_=+*)])(?=.{8,})/;
  return validation.test(value);
};

// export const passwordFieldValidation = (value) => {
//   if (!value) {
//     return "Password is required";
//   } else if (!passwordValidation(value)) {
//     return "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter and 1 number.";
//   }
// };

//To get plan expiring days

export const toGetExpiryDate = (date) => {
  let endDate = new Date(date);
  var numberOfDaysToAdd = 30;
  endDate.setDate(endDate.getDate() + numberOfDaysToAdd);
  var dd = endDate.getDate();
  var mm = endDate.getMonth() + 1;
  var y = endDate.getFullYear();
  var expiringDate = mm + "/" + dd + "/" + y;
  const date1 = new Date();
  const date2 = new Date(expiringDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  console.log(diffTime + " milliseconds");
  console.log(diffDays + " days");
  return diffDays;
};

export const getRandomPassword = (passwordLength) => {
  let length = passwordLength || 10;
  let string = "abcdefghijklmnopqrstuvwxyz";
  let stringUpper = "PDLGKASRWTIZUVXNJHBCOMEFQY";
  let numeric = "0123456789";
  let punctuation = "!@#$%&";
  let password = "";
  let character = "";
  while (password.length < length) {
    let entity1 = Math.ceil(string.length * Math.random() * Math.random());
    let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    /*  let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold; */
    character += string.charAt(entity1);
    character += stringUpper.charAt(entity1);
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
  return password.substr(0, length);
};

export const convertValue = (value, unit) => {
  if (unit === "Metric") {
    return value;
  } else {
    return (Number(value) * 0.00328084).toFixed(2);
  }
};

export const convertToMM = (value, unit) => {
  if (unit === "Metric") {
    return value;
  } else {
    return Number((Number(value) * 304.8).toFixed(0));
  }
};

/**
 * Determine whether the given `input` is iterable.
 *
 * @returns {Boolean}
 */
export function isIterable(input) {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === "function";
}
