import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Trans, useTranslation } from "react-i18next";

import "react-pro-sidebar/dist/css/styles.css";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import { DesktopMenuAccordion, ResponsiveMenuDropdown } from "./ActivityLogs.component.styles";

const MenuBar = ({ history, permissions, isAccountOwner, isSuperAdmin }) => {
  const { t } = useTranslation();
  const menuItems = [
    {
      id: "applicationActivityRecords",
      label: t("Application Activity Logs"),
      route: "activity-logs",
      subMenuRoutes: ["application-login-records", "lock-unlock-logs"],
      subMenu: [
        {
          id: "loginRecords",
          label: t("Login Records"),
          route: "application-login-records",
        },

        {
          id: "lockUnlockLogs",
          label: t("Lock / Unlock Logs"),
          route: "lock-unlock-logs",
        },
      ],
    },
  ];

  const [selectedRoute, setSelectedRoute] = useState("application-login-records");
  const [expandedPanel, setExpandedPanel] = useState("");

  const userManagementObj = permissions[0].permissions.find(
    (permission) => permission.name === "USER_MANAGEMENT"
  );

  useEffect(() => {
    const route = history?.location?.pathname?.replace("/app/activity-logs/", "");
    setSelectedRoute(route);

    menuItems.forEach((menuItem) => {
      if (menuItem.subMenuRoutes?.includes(route)) {
        setExpandedPanel(menuItem.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.pathname]);

  const clickHandler = (route) => {
    history.push(route);
    setSelectedRoute(route);
  };
  const onOpenChangeHandler = (id) => {
    setExpandedPanel(id);
  };
  const options = menuItems.map((menuItem, index) => {
    return menuItem.subMenu
      ? menuItem.subMenu.map((subMenu, subMenuIndex) => {
          return { value: subMenu.route, label: subMenu.label };
        })
      : { value: menuItem.route, label: menuItem.label };
  });

  const getMenuItem = (subMenu) => {
    return (
      <>
        {subMenu.id === "dashboardActivityLogs" && !isSuperAdmin ? null : (
          <MenuItem
            onClick={() => clickHandler(subMenu.route)}
            active={subMenu.route === selectedRoute}
            title={subMenu.label}
          >
            <Trans>{subMenu.label}</Trans>
          </MenuItem>
        )}
      </>
    );
  };

  return (
    <ProSidebar>
      <ResponsiveMenuDropdown>
        <DropdownComponent
          label={"Menu"}
          options={options.flat()}
          value={options?.label}
          handleFilter={(selectedData) => clickHandler(selectedData.value)}
        />
      </ResponsiveMenuDropdown>
      <DesktopMenuAccordion>
        <Menu>
          {menuItems.map((menuItem, index) => {
            return (
              <>
                {menuItem.subMenu ? (
                  <SubMenu
                    title={menuItem.label}
                    onOpenChange={() => onOpenChangeHandler(menuItem.id)}
                    open={expandedPanel === menuItem.id}
                  >
                    {menuItem.subMenu.map((subMenu, subMenuIndex) => {
                      return (
                        <Fragment key={subMenuIndex}>
                          {subMenu.id === "application-login-records" || subMenu.id === "lock-unlock-logs"
                            ? userManagementObj?.action?.view
                              ? getMenuItem(subMenu)
                              : null
                            : getMenuItem(subMenu)}
                        </Fragment>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <MenuItem
                    onClick={() => clickHandler(menuItem.route)}
                    active={menuItem.route === selectedRoute}
                    title={menuItem.label}
                  >
                    <Trans>{menuItem.label}</Trans>
                  </MenuItem>
                )}
              </>
            );
          })}
        </Menu>
      </DesktopMenuAccordion>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
