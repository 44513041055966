export const initialState = {
  //For Users List
  UsersLoading: false,
  UsersSuccess: false,
  UsersError: false,
  Users: [],

  userDetailSuccess: false,
  userDetailLoading: false,
  userDetailError: false,

  //For Worksite Overview
  UserOverview: null,

  //For Adding Worksite
  AddUserLoading: false,
  AddUserSuccess: false,
  AddUserError: false,
  UserMessage: "",

  //For Status Update
  StatusLoading: true,
  StatusSuccess: false,
  StatusError: false,
  StatusFailed: false,
  StatusMessage: "",
  StatusData:{},

  //For Worksite Details Update
  UpdateLoading: false,
  UpdateSuccess: false,
  UpdateError: false,
  UpdateMessage: "",

  //For worksite name update
  UpdateNameLoading: false,
  UpdateNameSuccess: false,
  UpdateNameError: false,
  UpdateNameMessage: "",

  //For user bulk upload template list
  UserBulkUploadLoading: false,
  UserBulkUploadSuccess: false,
  UserBulkUploadError: false,
  UserBulkUploadTemplates: [],

  updateUserSuccess: false,

  //For Generate CSV
  GenerateCSVLoading: false,
  GenerateCSVSuccess: false,
  GenerateCSVError: false,
  GeneratingCSVData: [],
};
