import styled from "styled-components";

export const StepperStyleDiv = styled.div`
  .stepperStyle .MuiStepLabel-label.MuiStepLabel-completed {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    font-weight: 300;
  }
`;
export const StepperWrapperStyle = styled.div`
  @media (max-width: 1100px) {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
    button[title="Back"] {
      float: left;
    }
  }
`;
export const WorksiteOverviewIcon = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: 25px !important;
  margin-top: 5px;
`;
export const WorksiteShiftIcon = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: 25px !important;
  margin-top: 5px;
`;
