export const assetManagementTypes = {
  //For Asset List
  GET_ALL_ASSETS_LOADING: "GET_ALL_ASSETS_LOADING",
  GET_ALL_ASSETS_SUCCESS: "GET_ALL_ASSETS_SUCCESS",
  GET_ALL_ASSETS_ERROR: "GET_ALL_ASSETS_ERROR",
  UPDATE_ASSET_ITEM: "UPDATE_ASSET_ITEM",

  // For Getting Single asset By id
  GET_ASSET_DETAIL_LOADING: "GET_ASSET_DETAIL_LOADING",
  GET_ASSET_DETAIL_SUCCESS: "GET_ASSET_DETAIL_SUCCESS",
  GET_ASSET_DETAIL_ERROR: "GET_ASSET_DETAIL_ERROR",

  //For Asset Types
  GET_ASSET_TYPE_LOADING: "GET_ASSET_TYPE_LOADING",
  GET_ASSET_TYPE_SUCCESS: "GET_ASSET_TYPE_SUCCESS",
  GET_ASSET_TYPE_ERROR: "GET_ASSET_TYPE_ERROR",

  // For Getting Single asset type By id
  GET_ASSET_TYPE_DETAIL_LOADING: "GET_ASSET_TYPE_DETAIL_LOADING",
  GET_ASSET_TYPE_DETAIL_SUCCESS: "GET_ASSET_TYPE_DETAIL_SUCCESS",
  GET_ASSET_TYPE_DETAIL_ERROR: "GET_ASSET_TYPE_DETAIL_ERROR",

  //For Adding New Asset
  ADD_NEW_ASSET_LOADING: "ADD_NEW_ASSET_LOADING",
  ADD_NEW_ASSET_SUCCESS: "ADD_NEW_ASSET_SUCCESS",
  ADD_NEW_ASSET_ERROR: "ADD_NEW_ASSET_ERROR",
  RESET_ASSETS_SUCCESS_FLAG: "RESET_ASSETS_SUCCESS_FLAG",
  //For Asset Status
  UPDATE_ASSET_STATUS_LOADING: "UPDATE_ASSET_STATUS_LOADING",
  UPDATE_ASSET_STATUS_SUCCESS: "UPDATE_ASSET_STATUS_SUCCESS",
  UPDATE_ASSET_STATUS_ERROR: "UPDATE_ASSET_STATUS_ERROR",
  UPDATE_ASSET_STATUS_FAILED: "UPDATE_ASSET_STATUS_FAILED",

  //For Asset Image Update
  UPDATE_ASSET_IMAGE_LOADING: "UPDATE_ASSET_IMAGE_LOADING",
  UPDATE_ASSET_IMAGE_SUCCESS: "UPDATE_ASSET_IMAGE_SUCCESS",
  UPDATE_ASSET_IMAGE_ERROR: "UPDATE_ASSET_IMAGE_ERROR",

  //For Generate CSV
  GENERATE_CSV_LOADING: "GENERATE_CSV_LOADING",
  GENERATE_CSV_SUCCESS: "GENERATE_CSV_SUCCESS",
  GENERATE_CSV_ERROR: "GENERATE_CSV_ERROR",

  //For Asset Name Edit
  EDIT_ASSET_NAME_LOADING: "EDIT_ASSET_NAME_LOADING",
  EDIT_ASSET_NAME_SUCCESS: "EDIT_ASSET_NAME_SUCCESS",
  EDIT_ASSET_NAME_ERROR: "EDIT_ASSET_NAME_ERROR",

  //For Asset Checklist
  GET_ASSET_CHECKLISTS_LOADING: "GET_ASSET_CHECKLISTS_LOADING",
  GET_ASSET_CHECKLISTS_SUCCESS: "GET_ASSET_CHECKLISTS_SUCCESS",
  GET_ASSET_CHECKLISTS_ERROR: "GET_ASSET_CHECKLISTS_ERROR",

  //For Inspection Upload Media
  POST_INSPECTION_MEDIA_LOADING: "POST_INSPECTION_MEDIA_LOADING",
  POST_INSPECTION_MEDIA_SUCCESS: "POST_INSPECTION_MEDIA_SUCCESS",
  POST_INSPECTION_MEDIA_ERROR: "POST_INSPECTION_MEDIA_ERROR",

  //For Answer Submit
  POST_ANSWER_LOADING: "POST_ANSWER_LOADING",
  POST_ANSWER_SUCCESS: "POST_ANSWER_SUCCESS",
  POST_ANSWER_ERROR: "POST_ANSWER_ERROR",

  //For Storing Selected Asset
  STORE_SELECTED_ASSET: "STORE_SELECTED_ASSET",
  DEFAULT_ASSET_SELECTED: "DEFAULT_ASSET_SELECTED",
  TO_STORE_INSPECTION_ASSET: "TO_STORE_INSPECTION_ASSET",

  //For Storing Selected Asset Type
  STORE_SELECTED_ASSET_TYPE: "STORE_SELECTED_ASSET_TYPE",

  //For resetting all state
  RESET_ALL_ASSET_STATUS: "RESET_ALL_ASSET_STATUS",
  ASSET_ADDED: "ASSET_ADDED",

  // Update Asset
  UPDATE_ASSET: "UPDATE_ASSET",

  ADD_ASSET_IMAGE_LOADING: "ADD_ASSET_IMAGE_LOADING",
  ADD_ASSET_IMAGE_SUCCESS: "ADD_ASSET_IMAGE_SUCCESS",
  ADD_ASSET_IMAGE_ERROR: "ADD_ASSET_IMAGE_ERROR",

  // Update asset overview
  UPDATE_ASSET_OVERVIEW: "UPDATE_ASSET_OVERVIEW",

  // Asset Type Checklist Mapping
  UPDATE_ASSET_TYPE_CHECKLIST_LOADING: "UPDATE_ASSET_TYPE_CHECKLIST_LOADING",
  UPDATE_ASSET_TYPE_CHECKLIST_SUCCESS: "UPDATE_ASSET_TYPE_CHECKLIST_SUCCESS",
  UPDATE_ASSET_TYPE_CHECKLIST_ERROR: "UPDATE_ASSET_TYPE_CHECKLIST_ERROR",
  RESET_UPDATE_ASSET_TYPE_CHECKLIST_STATE: "RESET_UPDATE_ASSET_TYPE_CHECKLIST_STATE",

  SET_ASSET_TYPE_SUCCESS: "SET_ASSET_TYPE_SUCCESS",
  RESET_ASSET_TYPE_SUCCESS: "RESET_ASSET_TYPE_SUCCESS",

  GET_ALL_ASSETS_LIST_SUCCESS: "GET_ALL_ASSETS_LIST_SUCCESS",
};
