import React, { useState, useEffect } from "react";
import moment from "moment";
import { Trans } from "react-i18next";

import ScoutLogo from "../../../../assets/logo/Scout_Logo.png";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";
import { ReactComponent as AlertSettings } from "../../../../assets/AlertSettings.svg";
import "./report.css";
import {
  ReportDivWrapp,
  ReportPageWrapper,
  ReportTopSection,
  PageNumber,
  SieraLogo,
  EmailHelpText,
  Anchor,
  ReportName,
  ReportDataSection,
  ReprtHeaderTop,
  ReportStatus,
  PassReport,
  FailReport,
  ReportCenterData,
  ReportRightSec,
  ReportPageFooter,
  TimedoutReport,
  ReportDataHeaderTop,
  SectionData,
  SectionHeader,
  SectionBody,
  SectionName,
  SectionTotalTime,
  QuestionData,
  WarningSign,
  QuestionBody,
  QuestionResponse,
  QuestionResponseTime,
  NotesStyledDiv,
  QuestionDetails,
} from "../../../../components/ReportViewComponent/ReportViewComponent.Style";
import config from "../../../../config";
const responseMediaPath = config.s3ResponseMedia;

function InspectionReport({ assetOverview }) {
  const [reportData, setReportData] = useState(null);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("inspectionReportObject"));
    setReportData(data);
    setPosition(data?.location?.coordinates || null);
    document.title = data?.inspectorId && data?.inspectorId?.fullName + " | Inspection Summary Report";
  }, []);

  const secondsToMinutesAndSeconds = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Number(seconds % 60).toFixed(1);

    return `${minutes > 0 ? `${minutes} ${minutes > 1 ? "m" : "m"} ` : ""}${remainingSeconds} ${
      remainingSeconds > 1 ? "s" : "s"
    } `;
  };

  const timeZone = localStorage.getItem("currentTimezone") || reportData?.siteId?.timeZone;
  return (
    <ReportDivWrapp>
      {reportData ? (
        <>
          <ReportPageWrapper>
            <ReportTopSection>
              <PageNumber>Page 1</PageNumber>
              <SieraLogo src={ScoutLogo} alt="" />
              <EmailHelpText>
                For support email us at
                <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
              </EmailHelpText>
              <EmailHelpText>
                or visit our Helpdesk&nbsp;
                <Anchor href="https://support.siera.ai" target="_blank">
                  https://support.siera.ai
                </Anchor>
              </EmailHelpText>
            </ReportTopSection>

            <ReportName>
              <Trans>Inspection Summary</Trans>
            </ReportName>

            <ReportDataSection>
              <ReprtHeaderTop>
                <Trans>Status</Trans>
                <ReportStatus>
                  {reportData.status === "passed" ? (
                    <PassReport>PASS</PassReport>
                  ) : reportData.status === "failed" ? (
                    <FailReport>FAIL</FailReport>
                  ) : (
                    <TimedoutReport>
                      <Trans>Timed Out</Trans>
                    </TimedoutReport>
                  )}
                </ReportStatus>
              </ReprtHeaderTop>
              <ReportCenterData>
                <Trans>Checklist Name</Trans>
                <ReportRightSec>{reportData.checklistId.checklistName}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Worksite</Trans>
                <ReportRightSec>{reportData.siteId.name}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Asset Name</Trans>
                <ReportRightSec>{reportData.assetId.assetName}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Inspected At</Trans>
                <ReportRightSec>
                  {moment(reportData?.inspectionAt)
                    .utcOffset(timeZone?.split?.(" ")?.[0])
                    .format("dddd, MMMM Do YYYY, hh:mm:ss A")}
                  ({getTimezoneShortCode(timeZone)})
                </ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Inspected By</Trans>
                <ReportRightSec>{reportData.inspectorId && reportData.inspectorId.fullName}</ReportRightSec>
              </ReportCenterData>
            </ReportDataSection>
            <br />
          </ReportPageWrapper>

          <ReportPageWrapper>
            {/* Inspection Common Header Section */}
            <ReportTopSection>
              <PageNumber>Page 2</PageNumber>
              <SieraLogo src={ScoutLogo} alt="" />
              <EmailHelpText>
                For support email us at
                <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
              </EmailHelpText>
              <EmailHelpText>
                or visit our Helpdesk&nbsp;
                <Anchor href="https://support.siera.ai" target="_blank">
                  https://support.siera.ai
                </Anchor>
              </EmailHelpText>
            </ReportTopSection>

            {/* Inspection Details Header Title */}
            <ReportName>
              <Trans>Inspection Details</Trans>
            </ReportName>

            <ReportDataHeaderTop>
              <Trans>Status</Trans>
              <ReportStatus>
                {reportData.status === "passed" ? (
                  <PassReport>PASS</PassReport>
                ) : reportData.status === "failed" ? (
                  <FailReport>FAIL</FailReport>
                ) : (
                  <TimedoutReport>Timed Out</TimedoutReport>
                )}
              </ReportStatus>
            </ReportDataHeaderTop>

            <ReportCenterData>
              <Trans>Total Checklist Time</Trans>
              <ReportRightSec>
                {(() => {
                  const checkListTime = reportData?.response?.reduce(
                    (checklistTotalTime, section) =>
                      checklistTotalTime +
                        section?.questions?.reduce(
                          (sectionTotalTime, question) =>
                            sectionTotalTime +
                              (question?.answerResponseTime >= 0 ? question?.answerResponseTime : 0) || 0,
                          0
                        ) || 0,
                    0
                  );
                  if (checkListTime === 0 || !reportData?.isResponseTimeRecorded) {
                    return "NA";
                  } else {
                    return secondsToMinutesAndSeconds(checkListTime);
                  }
                })()}
              </ReportRightSec>
            </ReportCenterData>

            <br />

            <ReportDataSection>
              {reportData &&
                reportData?.response?.map((response) => (
                  <>
                    <SectionData>
                      <SectionHeader>
                        <SectionName>{response.sectionName}</SectionName>
                        <SectionTotalTime>
                          {(() => {
                            let secTime = response?.questions?.reduce(
                              (sectionTotalTime, question) =>
                                sectionTotalTime +
                                  (question?.answerResponseTime >= 0 ? question?.answerResponseTime : 0) || 0,
                              0
                            );
                            if (secTime === 0 || !reportData?.isResponseTimeRecorded) {
                              return "NA";
                            } else {
                              return secondsToMinutesAndSeconds(secTime);
                            }
                          })()}
                        </SectionTotalTime>
                      </SectionHeader>
                      <SectionBody>
                        {response &&
                          response.questions.map((question) => (
                            <>
                              <QuestionDetails>
                                <QuestionData>
                                  {question?.isfailedResponse &&
                                    question?.failedResponse?.toLowerCase?.().trim() ==
                                      question?.response?.toLowerCase?.().trim() && (
                                      <WarningSign>
                                        <AlertSettings
                                          style={{ cursor: "pointer", width: "60%", margin: "auto" }}
                                        />
                                      </WarningSign>
                                    )}
                                  <QuestionBody>
                                    {question.question}
                                    {question.responseMedia && question.responseMedia[0]
                                      ? question.responseMedia.map((data) => {
                                          return (
                                            <div className="imageView">
                                              <img
                                                style={{
                                                  width: "5rem",
                                                  objectFit: "contain",
                                                  marginRight: 5,
                                                }}
                                                src={responseMediaPath + data}
                                                alt=""
                                              />
                                            </div>
                                          );
                                        })
                                      : ""}
                                  </QuestionBody>
                                  <QuestionResponse>
                                    {[
                                      "OK".toLowerCase?.(),
                                      "NOT OK".toLowerCase?.(),
                                      "NA".toLowerCase?.(),
                                    ].includes(question?.response?.toLowerCase?.()) ? (
                                      <span
                                        style={{
                                          color: "rgb(103, 103, 103)",
                                        }}
                                      >
                                        {question.response}
                                      </span>
                                    ) : ["Timedout".toLowerCase?.()].includes(
                                        question?.response?.toLowerCase?.()
                                      ) ? (
                                      <span
                                        style={{
                                          color: "rgb(103, 103, 103)",
                                        }}
                                      >
                                        Timed out
                                      </span>
                                    ) : (
                                      <span>{question.response}</span>
                                    )}
                                  </QuestionResponse>
                                  <QuestionResponseTime>
                                    {question?.answerResponseTime < 0 || !reportData?.isResponseTimeRecorded
                                      ? "NA"
                                      : secondsToMinutesAndSeconds(question?.answerResponseTime)}
                                  </QuestionResponseTime>
                                </QuestionData>
                                {question.note && <NotesStyledDiv>Notes: {question.note}</NotesStyledDiv>}
                              </QuestionDetails>
                            </>
                          ))}
                      </SectionBody>
                    </SectionData>
                    <br />
                  </>
                ))}
              <p>
                *Please note that there may be slight inconsistencies in the total figures due to rounding.
                <br />
                This is a normal occurrence and does not affect the overall accuracy of the data.
              </p>

              <ReportPageFooter>
                <Trans>Proudly Built in Austin, TX. Copyright </Trans>
                {new Date().getFullYear()}.&nbsp;
                <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
              </ReportPageFooter>
            </ReportDataSection>
          </ReportPageWrapper>
        </>
      ) : (
        ""
      )}
    </ReportDivWrapp>
  );
}

export default InspectionReport;
