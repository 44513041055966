import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Route, Switch, Redirect } from "react-router-dom";

import { selectLoginDetails } from "../../redux/user/user.selectors";

// Landing Pages
import ManagementTabs from "../../view/Dashboard/Management/ManagementTabs.component";
import ManagementTabsNew from "../../view/Dashboard/Management/ManagementTabsNew.component";
import ViewAssetTypesManagement from "../../view/Dashboard/Asset/ViewAssetTypesManagement.component";
import ChecklistManagement from "../../view/Dashboard/checklist/ChecklistManagement.component";
import InspectAsset from "../../view/Dashboard/InspectAsset/InspectAsset";
import AnalyticsPage from "../../view/Dashboard/Analytics/Analytics/AnalyticsPage.component";
import AssetStatus from "../../view/Dashboard/AssetStatus/AssetStatus.component";
import Report from "../../view/Dashboard/Report/Report.component";
import Dashboard from "../../view/Dashboard/Dashboard/Dashboard.component";
import Settings from "../../view/Dashboard/Settings/Settings";
import SystemSetting from "../../view/Dashboard/Profile/SystemSetting/SystemSetting.component";
import GeneralSetting from "../../view/Dashboard/Profile/generalSetting/GeneralSetting.component";
import ChecklistTemplate from "../../view/Dashboard/checklist/Templates/ChecklistTemplates.component";
import ErrorLogs from "../../view/Dashboard/Analytics/ErrorLogs/ErrorLogs.component";
import IncidentReportView from "../../view/Dashboard/Report/ReportView/IncidentReportView.component";
import ProximityReportView from "../../view/Dashboard/Report/ReportView/ProximityReportView.component";
import InspectionReport from "../../view/Dashboard/Asset/Report/Report.component";
import UnauthorizedAccessReportView from "../../view/Dashboard/Report/ReportView/UnauthorizedAccessReportView.component";
import DeviceInformation from "../../view/Dashboard/deviceInformation/DeviceInformation.component";
import UserTypesManagement from "../../view/Dashboard/Management/userTypes/UserTypesManagement.component";
import LoginRecords from "../../view/Dashboard/ActivityLogs/applicationActivityLogs/loginRecords/LoginRecords.component";
import QrcodeComponent from "../../view/Dashboard/Asset/Qrcode/Qrcode.component";
import ActivityLogs from "../../view/Dashboard/ActivityLogs/ActivityLogs.component";
import TroubleShootingLogs from "../../view/Dashboard/ActivityLogs/dashboardActivityLogs/troubleshooting/TroubleShooting.component";
// Layouts
import DashboardLayout from "../../layouts/DashboardLayout";

const LandingRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

const ReportLandingRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={(props) => <Layout></Layout>}></Route>
);

const DashboardLandingRoutes = ({ loginDetails, ...rest }) => {
  if (!window.location.href.includes("reset-password") && (!loginDetails || !loginDetails.success)) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Switch>
        {/* 1. Dashboard Page route with Enterprise URL */}
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/:enterprise_url/dashboard"
          component={ManagementTabs}
        />
        {/* 3. View Asset Type Page route*/}
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/management/assettype"
          component={ViewAssetTypesManagement}
        />
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/management/userType"
          component={UserTypesManagement}
        />
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/management/qrcode"
          component={QrcodeComponent}
        />
        {/* 4. Checklist Management Page route*/}
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/checklistmanagement"
          component={ChecklistManagement}
        />
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/checklistmanagement/templates"
          component={ChecklistTemplate}
        />
        {/* 6. Dashboard without enterprise URL*/}
        {/* <LandingRoute layout={DashboardLayout} exact path='/app/management/abc/1' component={ManagementTabs1} /> */}
        <LandingRoute layout={DashboardLayout} exact path="/app/management" component={ManagementTabsNew} />
        {/* USER PROFILE EDIT SECTION */}
        <LandingRoute layout={DashboardLayout} path="/app/settings" component={Settings} />
        <LandingRoute layout={DashboardLayout} exact path="/app/inspectAsset" component={InspectAsset} />
        <LandingRoute layout={DashboardLayout} exact path="/app/analytics" component={AnalyticsPage} />
        {/* <LandingRoute layout={DashboardLayout} exact path="/app/analyticsPage2" component={AnalyticsPage2} /> */}
        <LandingRoute layout={DashboardLayout} exact path="/app/assetstatus" component={AssetStatus} />
        <LandingRoute
          layout={DashboardLayout}
          exact
          path="/app/device-information"
          component={DeviceInformation}
        />
        <LandingRoute layout={DashboardLayout} path="/app/activity-logs" component={ActivityLogs} />
        <LandingRoute
          layout={DashboardLayout}
          path="/app/troubleshooting-logs"
          component={TroubleShootingLogs}
        />
        <LandingRoute layout={DashboardLayout} exact path="/app/report" component={Report} />
        <LandingRoute layout={DashboardLayout} exact path="/app/dashboard" component={Dashboard} />
        <LandingRoute layout={DashboardLayout} path="/app/system-setting" component={SystemSetting} />
        <LandingRoute layout={DashboardLayout} path="/app/general-setting" component={GeneralSetting} />
        <LandingRoute layout={DashboardLayout} path="/app/error-logs" component={ErrorLogs} />
        <LandingRoute layout={DashboardLayout} path="/app/login-records" component={LoginRecords} />
        <LandingRoute
          layout={ReportLandingRoute}
          exact
          path="/app/impact-report"
          component={IncidentReportView}
        />
        <LandingRoute
          layout={ReportLandingRoute}
          exact
          path="/app/near-miss-report"
          component={ProximityReportView}
        />
        <LandingRoute
          layout={ReportLandingRoute}
          exact
          path="/app/inspection-report"
          component={InspectionReport}
        />
        <LandingRoute
          layout={ReportLandingRoute}
          exact
          path="/app/unauthorized-access-report"
          component={UnauthorizedAccessReportView}
        />
        {/*   <LandingRoute layout={DashboardLayout} component={Dashboard} /> */}
      </Switch>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
});

export default connect(mapStateToProps, null)(DashboardLandingRoutes);
