import React from "react";
import SkeletonLoader from "../SkeletonLoaderComponent/SkeletionLoader.component";
import PropTypes from "prop-types";
import {
  DesktopContainer,
  Cell,
  Header,
  Row,
  AccordionContent,
  PaginationContainer,
} from "./DataTable.component.styles";

const SkeletonLoaderComponent = ({ columns = [], rowsPerPage, accordion, pagination }) => {
  return (
    <DesktopContainer>
      <Header>
        {columns?.map?.((column, index) => (
          <Cell key={index} width={column.width}>
            <SkeletonLoader width="100%" height="18px" />
          </Cell>
        ))}
        {accordion && <Cell width="50">&nbsp;</Cell>}
      </Header>

      {[...Array(rowsPerPage)].map((_, rowIndex) => (
        <div key={rowIndex} className="fontFamilyData">
          <Row>
            {columns?.map?.((column, colIndex) => (
              <Cell key={colIndex} width={column.width} style={{ padding: "12px auto" }}>
                <SkeletonLoader width="70%" height="16px" />
              </Cell>
            ))}
            {accordion && (
              <Cell width="50">
                <SkeletonLoader width="70%" height="16px" />
              </Cell>
            )}
          </Row>
          {accordion && (
            <AccordionContent>
              <div className="accod-container">
                {[...Array(5)].map((_, index) => (
                  <div className="accord-div" key={index}>
                    <SkeletonLoader width="30%" height="1em" />
                    <SkeletonLoader width="70%" height="1em" />
                  </div>
                ))}
              </div>
            </AccordionContent>
          )}
        </div>
      ))}

      {/* Pagination skeleton */}
      {pagination && (
        <PaginationContainer>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div className="left-right-icons">
            <SkeletonLoader width="1em" height="1em" />
            <SkeletonLoader width="1em" height="1em" />
          </div>
        </PaginationContainer>
      )}
    </DesktopContainer>
  );
};

SkeletonLoaderComponent.propTypes = {
  columns: PropTypes.array,
  rowsPerPage: PropTypes.number,
  // rowHeight: PropTypes.number,
  accordion: PropTypes.bool,
  pagination: PropTypes.bool,
};

export default SkeletonLoaderComponent;
