/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Enterprise Landing Page Forgot Password Form Styling
 */
import styled from "styled-components";

// Forgot Password Title Text Styling
export const RegisterTitle = styled.div`
  font-size: 40px !important;
  color: #323232;
  padding-top: 50px;
  padding-bottom: 12px;
  font-family: "Kanit";
`;
// Forgot Password Sub Title Text Styling
export const RegisterSubTitle = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: #919191;
  padding-top: 3px;
  font-family: "Open Sans";
`;
// Forgot Password Sub Title Text2 Styling
export const RegisterSubTitle2 = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: #919191;
  padding-top: 3px;
  padding-bottom: 40px;
  font-family: "Open Sans";
`;

// Forgot Password Cancel Link Text Styling
export const Cancel = styled.div`
  font-size: 17px;
  font-weight: normal;
  color: #919191;
  padding-bottom: 10px;
  font-family: "Kanit";
  height: 40px;
  width: 110px;
  background: transparent;
  line-height: 40px;
  border-radius: 0.2rem;
  border: none;
  background-color: transparent;
  text-align: center;
  &:hover {
    background-color: #f2f2f2;
  }
`;
export const ForgotPasswordContainer = styled.div`
  height: 86vh;
 /* background: url(https://i.ibb.co/5vxGXDZ/top-view-forklift-blue-background-23-2149853123-removebg-preview.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: aliceblue;*/
`;

export const ButtonWrapper = styled.div`
  button.cancel {
    width: 200px;
    margin-right: 15px;
  }

  button.save {
    width: 200px;
    margin-right: 15px;
  }
`;
