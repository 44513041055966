import styled from "styled-components";
export const AssetTypeOverviewWrapper = styled.div`
  width: 95%;
  margin: auto;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;

export const ContainerRow = styled.div`
  padding: 25px;
  margin: auto;
`;
