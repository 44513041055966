import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { setManagementActionBar } from "../../../redux/mobileView/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setEditAlertModal } from "../../../redux/modals/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import managementStyles from "./management.module.css";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import {
  ManagementTabHeader,
  DotIcon,
  CancelIcon,
  MobileTabBar,
  TabViewDiv,
  IconActionBar,
  IconDiv,
  CanIconDiv,
  DotIconBack,
} from "./ManagementTabBar.component.style";
import SiearTabs from "../../../components/TabComponent/SieraTabs.Component";

function ManagementTabBar({
  isSuperAdmin,
  isAccountOwner,
  isAccountAdmin,
  activeTab,
  showActionBar,
  setManagementActionBar,
  updateActiveNavItem,
  menuList,
  editForm,
  editFormSecond,
  setEditAlertModal,
}) {
  const { t } = useTranslation();

  const handleSelectDropdown = (selectedOption) => {
    if (activeTab === selectedOption.value) {
      return;
    }
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(selectedOption.value);
  };

  const handleChangeTab = (e) => {
    if (e.target.id == "main_tab") {
      return;
    }
    if (activeTab == e.target.id) {
      return;
    }
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(e.target.id);
  };

  return (
    <>
      <ManagementTabHeader>
        <div className={`${managementStyles.tabbar}`} onClick={handleChangeTab} id="main_tab">
          <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="view">
            <p
              id="accounts"
              className={`${managementStyles.tabbaritem} ${
                activeTab === "accounts" ? "tabbaritemactive" : ""
              }`}
            >
              <Trans>Accounts</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"WORKSITE_MANAGEMENT"} actionName="view">
            <p
              id="worksites"
              className={`${managementStyles.tabbaritem} ${
                activeTab === "worksites" ? "tabbaritemactive" : ""
              }`}
            >
              <Trans>Worksites</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"ASSET_MANAGEMENT"} actionName="view">
            <p
              id="assets"
              className={`${managementStyles.tabbaritem} ${activeTab === "assets" ? "tabbaritemactive" : ""}`}
            >
              <Trans>Assets</Trans>
            </p>
          </PermissionCheck>
          <PermissionCheck section="DASHBOARD" permissionName={"USER_MANAGEMENT"} actionName="view">
            <p
              id="users"
              className={`${managementStyles.tabbaritem} ${activeTab === "users" ? "tabbaritemactive" : ""}`}
            >
              <Trans>Users</Trans>
            </p>
          </PermissionCheck>
        </div>

        <MobileTabBar>
          <TabViewDiv>
            <DropdownComponent
              value={{
                value: activeTab,
                label: t(
                  activeTab
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .charAt(0)
                    .toUpperCase() + activeTab.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)
                ),
              }}
              handleFilter={handleSelectDropdown}
              options={[
                ...(isSuperAdmin ? [{ value: "accounts", label: t("Accounts") }] : []),
                ...(isSuperAdmin || isAccountOwner || isAccountAdmin
                  ? [{ value: "worksites", label: t("Worksites") }]
                  : []),
                { value: "assets", label: t("Assets") },
                { value: "users", label: t("Users") },
                { value: "assetType", label: t("Asset Type") },
                { value: "userType", label: t("User Type") },
              ]}
              size="medium"
            />
          </TabViewDiv>
          <IconActionBar>
            <IconDiv onClick={() => setManagementActionBar(!showActionBar)}>
              {showActionBar ? (
                <CanIconDiv>
                  <CancelIcon className="icon icon-cancel"></CancelIcon>
                </CanIconDiv>
              ) : (
                <DotIconBack>
                  <DotIcon className="icon icon-ellipsis-v"></DotIcon>
                </DotIconBack>
              )}
            </IconDiv>
          </IconActionBar>
        </MobileTabBar>
      </ManagementTabHeader>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
  activeTab: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isAccountAdmin: state.user.isAccountAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  menuList: state.sideMenu.menuState,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setManagementActionBar: (data) => dispatch(setManagementActionBar(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementTabBar));
