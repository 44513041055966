/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  DASHBOARD LAYOUT WITH HEADER AND FOOTER
 */

import React from "react";

import Header from "../view/Dashboard/Header/Header.component";
import Footer from "../view/Dashboard/Footer/Footer.component";

class DashboardLayout extends React.Component {
  render() {
    return (
      <>
        <Header mainContent={this.props.children} />

        <Footer />
      </>
    );
  }
}

export default DashboardLayout;
