//TODO: Optimize this file. Remove unused code

import React, { useState, useEffect, useRef } from "react";

import { Container, Row, Col, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import {
  toValidateEmail,
  toValidatePassword,
  toValidateConfirmPassword,
  toValidatePin,
  toValidatePhone,
} from "../../../utils/validations/checkValidation";
import Input from "../../../components/Input";
import { userList } from "../../../redux/userManagement/userManagement.action";
import { ReactComponent as FileUploadIcon } from "../../../assets/Profile_Photo.svg";
import { resetAddUserState } from "../../../redux/users/action";
import { getAllassetTypesWithCountByAccountId } from "../../../redux/assetType/assetType.action";
import { DashboardLogin, Email, PIN, Phone } from "./customFields/";
import CustomFieldHOC from "./customFields/CustomFieldHOC.component";
import {
  UserDetailsFormDiv,
  HeadingContainer,
  ImagePreview,
  HeaderLabel,
  ProfilePicUpload,
  InputUploader,
  HoverText,
  ImageWrapper,
  ModalBodyHeight,
} from "./UserDetailsForm.styles";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import Button from "../../../components/Button/Button.component";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { generatePasswordRegex } from "../../../utils/validations/utilities";
import { getPasswordPolicy, resetPasswordPolicy } from "../../../redux/accounts/action";

const AvatarImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledProfileImage = styled(FileUploadIcon)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.icon-svg {
    width: 100%;
    height: 100%;
  }
`;

function UserDetailsForm({
  userErrMessage,
  addUserError,
  setFormData,
  formData,
  setPreviewImage,
  handleNext,
  resetAddUserState,
  getAllassetTypesWithCountByAccountId,
  accountId,
  setIsPinValidating,
  setLastValidatedPin,
  lastValidatedPin,
  isUpdate,
  selectedUserType,
  userTypeDetailList,
  isPinValidating,
  userOverviewDetail,
  loginDetails,
  passwordPolicy,
  getPasswordPolicy,
}) {
  const [image, setImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation();

  const [isPinAvailable, setIsPinAvailable] = useState(true);

  const [pinErrorMessage, setPinErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const { pin, email, accessLevel } = formData;

  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const imageRef = useRef(null);

  const { changeOthersPasswords, changeOwnPasswords, _id: loggedInUserId } = loginDetails?.userData;
  const isSelf = loggedInUserId === userOverviewDetail?._id;
  const [passwordRegex, setPasswordRegex] = useState({});

  useEffect(() => {
    if (passwordPolicy) {
      const passwordRegex = generatePasswordRegex(passwordPolicy);
      setPasswordRegex(passwordRegex);
    }
  }, [passwordPolicy]);

  useEffect(() => {
    getPasswordPolicy(formData.accountId);
  }, [formData.accountId]);

  const getPasswordChangeMessage = () => {
    if (changeOwnPasswords && changeOthersPasswords) {
      return "COWP_ON_COTHP_ON";
    } else if (changeOwnPasswords && !changeOthersPasswords) {
      if (isSelf) {
        return "COWP_ON_COTHP_OFF_SELF";
      } else {
        return "COWP_ON_COTHP_OFF_OTHERS";
      }
    } else if (!changeOwnPasswords && changeOthersPasswords) {
      if (isSelf) {
        return "COWP_OFF_COTHP_ON_SELF";
      } else {
        return "COWP_OFF_COTHP_ON_OTHERS";
      }
    } else {
      return "COWP_OFF_COTHP_OFF";
    }
  };

  const handleCropComplete = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob((blob) => {
      setCroppedImage(blob);
      setModalOpen(false);
    }, "image/jpeg");
  };

  useEffect(() => {
    if (userErrMessage) {
      resetAddUserState();
    }
  }, [pin, email]);

  useEffect(() => {
    if (accessLevel === "INDIVIDUAL_EMPLOYEE") {
      getAllassetTypesWithCountByAccountId(formData.accountId);
    }
  }, [accessLevel]);

  const getValidationsFieldsArr = () => {
    const validationArray = [];
    selectedUserType?.customFields?.forEach((field) => {
      if (field.type === "EMAILANDPASSWORD") {
        validationArray.push("EMAIL");
        validationArray.push("PASSWORD");
      } else {
        validationArray.push(field.type);
      }
    });
    return validationArray;
  };

  const getUserTypeField = (fieldName) =>
    selectedUserType?.customFields?.find((field) => field.type === fieldName);

  /*   const fileSelectHandler = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setPreviewImage({ logo: event.target.files[0] });
    setFormData({ ...formData });
  }; */

  const handleImageSelect = (event) => {
    const selectedImage = event.target.files[0];
    setImage(URL.createObjectURL(selectedImage));
    setModalOpen(true);
    setPreviewImage({ logo: selectedImage });
    setFormData({ ...formData });
  };

  const handleSubmit = (event) => {
    if (isPinValidating) {
      return;
    }
    const validationsArray = getValidationsFieldsArr();
    const phoneErrorForm = validationsArray.includes("PHONE")
      ? toValidatePhone(formData.phone, getUserTypeField("PHONE")?.isRequired)
      : "";
    const pinErrorForm = validationsArray.includes("PIN") ? toValidatePin(formData.pin) : "";
    const emailErrorForm = validationsArray.includes("EMAIL") ? toValidateEmail(formData?.email) : "";
    const passwordErrorForm = validationsArray.includes("PASSWORD")
      ? toValidatePassword(formData.password, passwordRegex)
      : "";
    const confirmPasswordForm = validationsArray.includes("PASSWORD")
      ? toValidateConfirmPassword(formData.password, formData.passwordVerification)
      : "";

    if (!pinErrorMessage || (typeof pinErrorMessage === "string" && !pinErrorMessage?.includes("PIN"))) {
      setPinErrorMessage(pinErrorForm);
    }
    setPhoneErrorMessage(phoneErrorForm);

    let isFormValid =
      !phoneErrorForm &&
      !pinErrorForm &&
      !emailErrorForm &&
      !passwordErrorForm &&
      !confirmPasswordForm &&
      isPinAvailable;

    if (
      isUpdate &&
      getUserTypeObj(formData.newUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE" &&
      getUserTypeObj(formData.oldUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE"
    ) {
      isFormValid = !pinErrorForm && !emailErrorForm && isPinAvailable;
    }

    if (isUpdate) {
      if (!isFormValid) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }
    } else if (!isFormValid) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    if (croppedImage) {
      setPreviewImage({ logo: croppedImage });
    }

    setValidated(false);
    handleNext(event);
    event.preventDefault();
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];

    const allowedTypes = [".jpg", ".jpeg", ".png", ".bmp"];
    const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();

    if (!allowedTypes.includes(fileType)) {
      alert("Please upload a JPEG, JPG, PNG, or BMP file only.");
      return;
    }

    setImage(URL.createObjectURL(file));
    setModalOpen(true);
    setPreviewImage({ logo: file });
    setFormData({ ...formData });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>{getPasswordChangeMessage()}</Trans>
    </Tooltip>
  );

  const getUserTypeObj = (id) => userTypeDetailList?.find?.((userType) => id === userType._id);

  return (
    <UserDetailsFormDiv>
      <Container>
        <HeadingContainer>
          <Trans>User Details</Trans>
        </HeadingContainer>
        <Form noValidate id="userAddForm1" onSubmit={handleSubmit} style={{ margin: "20px" }}>
          <Row className="justify-content-center">
            <Col md={6}>
              {/* ProfilePic*/}

              <HeaderLabel>{t("Profile Picture")}</HeaderLabel>
              <ProfilePicUpload>
                <ImageWrapper
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  isDragging={isDragging}
                >
                  <HoverText>
                    {isDragging ? (
                      <i className="icon icon-plus"></i>
                    ) : croppedImage ? (
                      "Change Photo"
                    ) : (
                      "Add Photo"
                    )}
                  </HoverText>

                  <InputUploader type="file" accept=".jpg, .jpeg, .png, .bmp" onChange={handleImageSelect} />

                  <ModalComponent
                    style={{ textAlign: "center" }}
                    show={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    title="Crop Image"
                    size="lg"
                    withHeader={true}
                  >
                    <ModalBodyHeight>
                      <ReactCrop
                        src={image}
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onImageLoaded={(image) => (imageRef.current = image)}
                      />
                    </ModalBodyHeight>
                    <Row className="text-right">
                      <Col md={12}>
                        <Button label="Cancel" onClick={() => setModalOpen(false)} buttonType="ghost" />
                        <Button label="Done" onClick={handleCropComplete} buttonType="primary" />
                      </Col>
                    </Row>
                  </ModalComponent>

                  {/* <ImagePreview
                    src={croppedImage ? URL.createObjectURL(croppedImage) : FileUploadIcon}
                    alt={"profile"}
                  /> */}

                  {croppedImage ? (
                    <AvatarImage src={URL.createObjectURL(croppedImage)} alt="Profile" />
                  ) : (
                    <StyledProfileImage className="icon-svg" />
                  )}

                  {/* <HoverText>{croppedImage ? "Change Photo" : "Add Photo"}</HoverText> */}
                </ImageWrapper>
              </ProfilePicUpload>
            </Col>

            <Col md={6}>
              <CustomFieldHOC selectedUserType={selectedUserType} fieldType={"EMAILANDPASSWORD"}>
                <Email
                  validated={validated}
                  formData={formData}
                  setFormData={setFormData}
                  selectedUserType={selectedUserType}
                />
              </CustomFieldHOC>
              <CustomFieldHOC selectedUserType={selectedUserType} fieldType={"PHONE"}>
                <Phone
                  formData={formData}
                  setFormData={setFormData}
                  selectedUserType={selectedUserType}
                  phoneErrorMessage={phoneErrorMessage}
                />
              </CustomFieldHOC>
              <CustomFieldHOC selectedUserType={selectedUserType} fieldType={"PIN"}>
                <PIN
                  validated={validated}
                  formData={formData}
                  isPinAvailable={isPinAvailable}
                  setIsPinAvailable={setIsPinAvailable}
                  setFormData={setFormData}
                  selectedUserType={selectedUserType}
                  lastValidatedPin={lastValidatedPin}
                  setLastValidatedPin={setLastValidatedPin}
                  accountId={accountId}
                  setIsPinValidating={setIsPinValidating}
                  pinErrorMessage={pinErrorMessage}
                  setPinErrorMessage={setPinErrorMessage}
                />
              </CustomFieldHOC>
              {isUpdate &&
                getUserTypeObj(formData.newUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE" &&
                getUserTypeObj(formData.oldUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE" && (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                    <Col className="asterisk_form">
                      <Input disabled label={t("Password")} value="********" />
                    </Col>
                  </OverlayTrigger>
                )}
              {(isUpdate
                ? getUserTypeObj(formData.oldUserTypeId)?.accessLevel === "INDIVIDUAL_EMPLOYEE" &&
                  getUserTypeObj(formData.newUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE"
                : getUserTypeObj(formData.newUserTypeId)?.accessLevel !== "INDIVIDUAL_EMPLOYEE") && (
                <CustomFieldHOC selectedUserType={selectedUserType} fieldType={"EMAILANDPASSWORD"}>
                  <DashboardLogin
                    validated={validated}
                    formData={formData}
                    setFormData={setFormData}
                    userAccountId={formData.accountId}
                  />
                </CustomFieldHOC>
              )}
            </Col>
          </Row>

          <p className="mt-2 text-danger text-center">
            <Trans>{addUserError ? userErrMessage : ""}</Trans>
          </p>
        </Form>
      </Container>
    </UserDetailsFormDiv>
  );
}

const mapStateToProps = (state) => ({
  addUserError: state.users.AddUserError,
  userErrMessage: state.users.UserMessage,
  accountId: state.user.loginDetails.accountId,
  dropdownAccountId: state.dropdownFilters.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
  isUpdate: state.modals.isUpdate,
  userTypeDetailList: state.userType.userTypeDetailList,
  userOverviewDetail: state.users.UserOverview,
  loginDetails: state.user.loginDetails,
  passwordPolicy: state?.accounts?.passwordPolicy,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (id) => dispatch(setDropdownAccountId(id)),
  userList: (users) => dispatch(userList(users)),
  resetAddUserState: () => dispatch(resetAddUserState()),
  getAllassetTypesWithCountByAccountId: (accountId) =>
    dispatch(getAllassetTypesWithCountByAccountId(accountId, "newAccount")),
  getPasswordPolicy: (userAccountId) => dispatch(getPasswordPolicy(userAccountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm);
