import styled from "styled-components";

export const QuickAccesManageDiv = styled.div`
  width: 100%;
  margin: auto;
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const QuickAccessMain = styled.div`
  width: 100%;
  margin: 10px auto;
  display: flex;
`;

export const QuickAccessDivName = styled.div`
  width: 100%;
  display: block;
  font-size: 9px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  margin-top: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  opacity: 1;
`;

export const QuickAccessDivIcon = styled.div`
  background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px #00000029;
  border-radius: 50px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  line-height: 45px;
`;
export const QuickAccessIconName = styled.div``;

export const QucickAccessIconDiv = styled.div`
  cursor: pointer;
  flex: 1;
  text-align: center;
  :hover {
    ${QuickAccessDivIcon} {
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0% 0% no-repeat padding-box;
    }
    ${QuickAccessDivName} {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      font-weight: 600;
    }
  }
`;
