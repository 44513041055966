import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { withRouter } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import axios from "axios";
import { FormControl } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import accessLevelList from "../../../constant/accessLevel";
import config from "../../../config";
const baseUrl = config.baseUrl; //Base Url

// Make Styles from material UI
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  registerInputText: {
    width: "40ch",
    backgroundColor: "white",
  },
  registerButton: {
    width: "27ch",
    height: "5ch",
    backgroundColor: "#0D5FBE",
    fontFamily: "Kanit",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#5387DC",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// Request Access Form Component
function RequestAccess({ history, accountId }) {
  const { t } = useTranslation();
  // Assigning useStyles hook to classes
  const classes = useStyles();

  // Setting User Access with Email and Password
  const [userAccess, setUserAccess] = useState({ accountId: accountId });

  // Setting Recaptcha with Email and Password
  const recaptchaLoaded = () => {
    console.log("capcha loaded successfully");
  };

  /*   const handleSubscribe = () => {
    if (this.state.isVerified) {
      alert("You have Successfully Subscribed");
    } else {
      alert("Please verify that you are a human!");
    }
  }; */

  // Recaptcha Verify Call back upon clicking captcha
  const verifyCallback = (response) => {
    if (response) {
      this.setState({ isVerified: true });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${baseUrl}/RequestAccess/`, userAccess)
      .then((response) => {
        if (response.data.success) {
          history.push("/requestaccesssuccess");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*   const handleChange = (event) => {
    //setUserRole(event.target.value);
    setUserAccess({ ...userAccess, RolePermission: event.target.value });
  }; */

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      {/*First and Last Name Text Field*/}
      <FormControl>
        <TextField
          size="small"
          onChange={(e) => setUserAccess({ ...userAccess, fullName: e.target.value })}
          className={classes.registerInputText}
          id="outlined-basic"
          label={t("First and Last Name")}
          variant="outlined"
          name="FullName"
        />
      </FormControl>

      {/*Email ID Text Field*/}
      <FormControl style={{ backgroundColor: "white" }}>
        <TextField
          size="small"
          onChange={(e) => setUserAccess({ ...userAccess, email: e.target.value })}
          className={classes.registerInputText}
          id="outlined-basic"
          label={t("Email ID")}
          type="email"
          name="email"
          variant="outlined"
        />
      </FormControl>

      {/* Request Access */}
      <InputLabel>
        <Trans>Select Access Level</Trans>
      </InputLabel>
      <Select
        style={{ paddingTop: "15px" }}
        value={userAccess.permissions}
        onChange={(e) => setUserAccess({ ...userAccess, permissions: e.target.value })}
        className={classes.registerInputText}
      >
        {accessLevelList.map((data) => (
          <MenuItem value={data.value}>{data.name}</MenuItem>
        ))}
      </Select>

      {/*Request Description Text Field*/}
      <FormControl>
        <TextField
          size="small"
          onChange={(e) => setUserAccess({ ...userAccess, description: e.target.value })}
          className={classes.registerInputText}
          id="outlined-basic"
          label={t("Request Description")}
          type="text"
          name="requestdescription"
          variant="filled"
          multiline
          rows={4}
        />
      </FormControl>

      {/* Google Captcha */}
      <Row className="d-flex justify-content-center">
        <Recaptcha
          sitekey="6LcL5t8ZAAAAAIyPUk75YUihXaMIzbXRl5BS2Gwn"
          render="explicit"
          onloadCallback={recaptchaLoaded}
          verifyCallback={verifyCallback}
        />
      </Row>

      {/*Request Access Button */}
      <Button type="submit" className={classes.registerButton} variant="contained" color="primary">
        <Trans>Request Access</Trans>
      </Button>
    </form>
  );
}

export default withRouter(RequestAccess);
