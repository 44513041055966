import { countStateTypes } from "./count.types";

const INTIAL_STATE = {
  //1. count state
  countState: {},
};

export const countReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    // Case 1: Selecting the count state
    case countStateTypes.UPDATE_COUNT:
      return {
        ...state,
        countState: action.payload[0],
      };

    // Returning default state
    default:
      return state;
  }
};
