import React from "react";
import styled from "styled-components";

const dataTableSizeStyle = {
  small: {
    customCellFont: "12px",
    theadFont: "18px",
    tbodyFont: "14px",
  },
  medium: {
    customCellFont: "14px",
    theadFont: "20px",
    tbodyFont: "16px",
  },
  large: {
    customCellFont: "16px",
    theadFont: "22px",
    tbodyFont: "18px",
  },
};

export const MobileContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  .container-div {
    margin-bottom: 10px;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    border-radius: 4px;
    overflow: hidden;
    text-align: left;
  }
  .topDataDiv {
    font-family: "Open Sans", sans-serif;
  }
  .mob-container {
    padding: 15px;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }

  .first-row {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    .mob-div-left {
      float: left;
      width: 50%;
    }
    .mob-div-right {
      float: right;
      text-align: right;
      width: 50%;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      div {
        font-weight: 300;
        padding: 0px;
      }
    }
  }

  .center-mob-div {
    float: left;
    width: 100%;
    display: block;
    font-family: "Open Sans", sans-serif;
  }

  .mob-div {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .accName {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .bottomDiv {
    width: 100%;
    overflow: hidden;
    .arrowsSec {
      float: left;
      width: 50%;
      div {
        cursor: pointer;
        position: relative;
        top: 10px;
        i {
          font-size: 24px;
          color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
        }
      }
    }
    .mob-div {
      float: right;
      text-align: right;
      width: 50%;
      .action-div {
        width: 100%;
        float: right;
        display: flex;
        i {
          flex: 1;
          font-size: 24px;
          color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
          cursor: pointer;
        }
      }
    }
  }

  .accod-container {
    display: block;
    width: 100%;
    padding: 15px;
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .accord-div {
    width: 100%;
    margin-bottom: 10px;
  }

  .labelStyle {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  .inputDivStyle {
    border-radius: 5px;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFFF"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    padding: 4px 8px;
    margin: 2px 6px 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DesktopContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  width: 100%;
  border: 0px solid #ccc;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};

  @media (max-width: 1000px) {
    display: none;
  }
  .fontFamilyHeader {
    font-family: "Kanit", sans-serif;
  }
  .fontFamilyData {
    font-family: "Open Sans", sans-serif;
    &:focus {
      border: 1px solid;
      border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const Cell = styled.div`
  flex: ${(props) => (props.width ? `0 0 ${props.width}px` : "1")};
  padding: 8px;
  border-right: 0px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span.sortable {
    display: ${(props) => (props.centered ? "block !important" : "flex")};
    text-align: ${(props) => (props.centered ? "center" : "")};
  }

  .button-div {
    text-align: center;
  }

  #button-container {
    position: relative;
    width: 100px;
    height: 24px;
    border: 1px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
  }

  #nameUserStyle {
    position: absolute;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    left: -100%;
    transition: 0.5s;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #statusStyle {
    position: absolute;
    left: 0;
    transition: 0.5s;
    cursor: pointer;
    display: inline-block;
    width: 100%;
  }

  #button-container:hover #nameUserStyle {
    left: 0;
  }

  #button-container:hover #statusStyle {
    left: 100%;
  }

  .action-div {
    display: flex;
    text-align: center;
    i {
      flex: 1;
      font-size: 20px;
      cursor: pointer;
      line-height: 15px;
      position: relative;
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const NoReportsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  overflow-y: hidden !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
`;

export const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 400;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 16px;
  @media (max-width: 1000px) {
    display: none;
  }
  .sortable {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  height: ${(props) => props.rowHeight}px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: left;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .small-font {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
`;

export const SortIcon = styled.div`
  margin-left: 4px;
  display: inline-block;
`;

export const AccordionIcon = styled.div`
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  padding: 8px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  .accod-container {
    float: left;
    display: block;
    width: 100%;
  }
  .accord-div {
    float: left;
    width: 25%;
    margin-bottom: 10px;
    text-align: left;
  }

  .labelStyle {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }

  .inputDivStyle {
    border-radius: 5px;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    padding: 4px 8px;
    margin: 2px 6px 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-top: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  .left-right-icons {
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
`;

export const FormGroupReport = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  & label {
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    font-size: 14px !important;
    margin-bottom: initial;
  }
  & select {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    cursor: pointer;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 50px;
    :focus,
    :active {
      border: none;
    }
  }
`;

export const ShowingDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  font-size: 14px !important;
`;

export const ArrowIconDiv = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  font-size: 14px !important;
  i {
    position: relative;
    top: 2px;
  }
`;
