import moment from "moment";

export const getDateTimeInFormatExportData = (timezone, dateTime) => {
  if (!dateTime) {
    return "";
  }
  return timezone
    ? moment(dateTime).utcOffset(timezone?.split(" ")?.[0]).format("dddd, MMMM Do YYYY, h:mm a")
    : dateTime;
};
