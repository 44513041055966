export const initialState = {
  addSiteModal: false,
  addAssetModal: false,
  addUserModal: false,
  addAssetTypeModal: false,
  addUserTypeModal: false,
  modalWaitingForOpen: false,
  addAssetModalRedirect: false,
  editAlertModal: false,
  addChecklistModal: false,
};
