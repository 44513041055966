import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { ReactComponent as RightCircledIcon } from "../../../assets/rightCircledIcon/Group 3.svg";
import Button from "../../../components/Button/Button.component";
import { RegisterSubTitle, RegisterSubTitle2, RegisterTitle } from "./RequestAccessSuccess.styles";

// Request Access Success Component
const RequestAccessSuccess = ({ history }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Request Access Title */}
      <RegisterTitle>
        <Trans>Request Enterprise Access</Trans>
      </RegisterTitle>

      {/* Request Access Sub Title */}
      <RegisterSubTitle>
        <Trans>Your request has been sent to the Wolte Group Account Admin</Trans>.
      </RegisterSubTitle>
      {/* Request Access Sub Title 2*/}
      <RegisterSubTitle>
        <Trans>You will receive your access credentials, once your request is approved</Trans>.
      </RegisterSubTitle>
      {/* Request Access Contact Admin Sub Title */}
      <RegisterSubTitle2>
        <Trans>Contact your Account Admin for details</Trans>
      </RegisterSubTitle2>
      <Container>
        <form>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              {/* Success Tick Icon */}
              <RightCircledIcon style={{ width: "60px", height: "60px" }} />
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              {/* Back To Login Button redirecting to Home Page*/}

              <Button
                onClick={() => history.push("/")}
                label={t("Back To Login")}
                buttonType="primary"
                size="large"
                showIcon={false}
              />
            </Col>
            <Col md={3}></Col>
          </Row>
        </form>
      </Container>
    </>
  );
};

export default withRouter(RequestAccessSuccess);
