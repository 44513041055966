//This is a copy from the email share module. Rewrite this when time permits

import React, { useState } from "react";
import "./emailsStyles.css";
import { Form, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import validationConstant from "../../constant/messages/validation.json";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import {
  EmailsContainer,
  ClearAll,
  EmailList,
  BlockContainer,
  TabSpan,
  EmailText,
  CloseIcon,
  StyledButton,
} from "./SettingsEmailSelector.styles";

const SettingsEmailSelector = ({ customEmails, onChange, isLoading, isDisabled }) => {
  const [email, setEmail] = useState("");
  const [IsValidated, setIsValidated] = useState(false);

  const { t } = useTranslation();

  const onEmailAdd = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setIsValidated(true);
      return;
    }
    setIsValidated(false);
    let currentEmailList = new Set([...customEmails]);
    currentEmailList.add(email);
    onChange("customEmails", [...currentEmailList]);
    setEmail("");
  };

  const removeEmail = (e) => {
    const id = e.currentTarget.id;
    const currentEmailList = new Set([...customEmails]);
    currentEmailList.delete(id);
    onChange("customEmails", [...currentEmailList]);
  };

  const clearAll = (e) => {
    e.preventDefault();
    onChange("customEmails", []);
  };

  return (
    <div className="report-modal-container">
      <Form noValidate validated={IsValidated} onSubmit={onEmailAdd}>
        <Form.Group controlId="formBasicemail">
          <div style={{ border: "1px solid #E2E2E2" }} className="d-flex p-1">
            <Form.Control
              disabled={isDisabled}
              size="sm"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form-control email-input border-0"
              type="email"
              name="email"
              placeholder={t("EMAIL_FIELD_PLACEHOLDER_TEXT")}
              // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
              pattern='(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            />
            <StyledButton disabled={isDisabled} size="sm" type="submit">
              <AddIcon fill="white" height={14} />
            </StyledButton>
          </div>
          <Form.Control.Feedback type="invalid" style={{ textAlign: "left" }}>
            {t(validationConstant.invalid_email)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>

      <EmailsContainer>
        {isLoading ? null : (
          <>
            {customEmails?.length > 0 && !isDisabled && (
              <ClearAll href="#" onClick={clearAll}>
                <Trans>CLEAR_ALL</Trans>
              </ClearAll>
            )}
            <EmailList>
              <BlockContainer>
                {customEmails?.map((email) => (
                  <TabSpan key={email}>
                    <EmailText> {email}</EmailText>
                    {!isDisabled && (
                      <CloseIcon id={email} onClick={!isDisabled && removeEmail}>
                        <i class="fas fa-times"></i>
                      </CloseIcon>
                    )}
                  </TabSpan>
                ))}
              </BlockContainer>
            </EmailList>
          </>
        )}
      </EmailsContainer>
    </div>
  );
};

export default SettingsEmailSelector;
