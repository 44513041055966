import { MapWrapper, MapPositionDetail, LocationNotAvail } from "./DeviceDetailsInfo.styles";
import { Trans } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { Disclaimer } from "./GPSTracking.styles";

const ZOOM = 15;

const LastKnownLocation = (props) => {
  const { position } = props;
  return (
    <>
      {position && position.length >= 2 && (position[0] !== 0 || position[1] !== 0) ? (
        <>
          <MapPositionDetail>
            {position[0]} , {position[1]}
          </MapPositionDetail>

          <MapWrapper>
            <MapContainer center={position} zoom={ZOOM} scrollWheelZoom={true}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}>
                <Popup>
                  <Trans>DEVICE_LOCATION</Trans>
                </Popup>
                <Circle center={position} fillColor="blue" radius={50} />
              </Marker>
            </MapContainer>
            <Disclaimer>The accuracy of the data depends on multiple factors. </Disclaimer>
          </MapWrapper>
        </>
      ) : (
        <LocationNotAvail>
          <Trans>DEVICE_LOCATION_UNAVAILABLE</Trans>
        </LocationNotAvail>
      )}
    </>
  );
};

export default LastKnownLocation;
