import styled from "styled-components";

export const MFAWrapper = styled.div`
  align-items: center;
  margin-top: 15px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  float: left;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  position: relative;
  top: 6px;
`;

export const SignUpModalDiv = styled.div``;
export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: 20px !important;
`;
export const CompanyLogoText = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
  font-size: 12px !important;
  float: left;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  display: contents;
`;
export const CompanyLogoDiv = styled.div`
  margin-top: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 4px;
`;
export const CompanyImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const ModalBodyHeight = styled.div`
  height: calc(100vh - 260px);
  align-content: center;
  text-align: center;
  .ReactCrop {
    align-items: center;
    justify-content: center;
  }
  img.ReactCrop__image {
    /*  height: calc(100vh - 260px); */
    height: 100%;
    max-height: calc(100vh - 280px);
  }
`;

export const Break = styled.div`
  display: block;
  width: 100%;
  height: 10px;
`;
