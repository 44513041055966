import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./styles.css";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

import SwitchComponent from "../Switchbox/SwitchComponent";
import {
  SettingNotification,
  SettingTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  ToggleWrapper,
  OnOffText,
  SettingDotIconLow,
  SettingDotIconMed,
  SettingDotIconHigh,
  SettingContent,
  CustomeUserDiv,
  SettingDotIcon,
  InformationDivIcon,
  StyledInformationIcon,
} from "./indexStyled.styles";
import SettingsEmailSelector from "./SettingsEmailSelector.component";

const renderTooltipMessage = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans>{props.message}</Trans>
  </Tooltip>
);

const SettingsSection = ({
  tableHeader,
  tableData,
  onChange,
  hasCustomEmailField,
  customEmails,
  isLoading,
  isSystemSettingsEditable,
}) => {
  return (
    <SettingNotification>
      <SettingTableDescription>
        {tableHeader && (
          <RowDiv isHeader={true}>
            {tableHeader?.map?.((data, index) => (
              <Column even={index % 2 === 0} isHeader={true}>
                <Trans>{data}</Trans>
              </Column>
            ))}
          </RowDiv>
        )}
        <RowsContainer>
          {tableData?.map?.((data, index) => (
            <RowDiv>
              <Column
                align={"left"}
                textleft={"left"}
                isHeader={data.likeHeader}
                likeHeader={data.likeHeader}
                even={true}
              >
                {data.field == "high" && (
                  <SettingContent>
                    <SettingDotIconHigh className="icon icon-dot"></SettingDotIconHigh>
                  </SettingContent>
                )}
                {data.field == "medium" && (
                  <SettingContent>
                    <SettingDotIconMed className="icon icon-dot"></SettingDotIconMed>
                  </SettingContent>
                )}
                {data.field == "low" && (
                  <SettingContent>
                    <SettingDotIconLow className="icon icon-dot"></SettingDotIconLow>
                  </SettingContent>
                )}
                {data.icon && (
                  <SettingContent>
                    <SettingDotIcon color={data.color} className="icon icon-dot"></SettingDotIcon>
                  </SettingContent>
                )}
                <Trans>{data.label}</Trans>
                {data.showOverlay && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipMessage({ message: data.overlayMessage })}
                  >
                    <InformationDivIcon>
                      <StyledInformationIcon className="icon icon-information-tooltip" />
                    </InformationDivIcon>
                  </OverlayTrigger>
                )}
              </Column>
              <Column align={"right"} textleft={"right"} isHeaderToggle={data.likeHeader}>
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status"></div>
                ) : (
                  <ToggleWrapper>
                    <OnOffText>{data.value ? <Trans>On</Trans> : <Trans>Off</Trans>}</OnOffText>
                    <SwitchComponent
                      disabled={!isSystemSettingsEditable}
                      onChange={() => onChange(data.field, !data.value)}
                      checked={data.value}
                    />
                  </ToggleWrapper>
                )}
              </Column>
            </RowDiv>
          ))}
        </RowsContainer>
      </SettingTableDescription>

      {hasCustomEmailField && (
        <>
          <CustomeUserDiv>
            <Trans>Custom Users</Trans>
          </CustomeUserDiv>
          <SettingsEmailSelector
            /*emailList={emailList}
              setEmailList={setEmailList} */
            customEmails={customEmails}
            onChange={onChange}
            isLoading={isLoading}
            isDisabled={!isSystemSettingsEditable}
          />
        </>
      )}
    </SettingNotification>
  );
};

const mapStateToProps = (state) => ({
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSection);

//export default SettingsSection;
