import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";
import ViewAssetTypeOverviewTable from "./ViewAssetTypeOverviewTable.component";
import AssetTypeChecklistsTab from "../AssetType/Tabs/AssetTypeChecklistsTab.component";
import "../AssetStatus/AssetStatus.css";

export default function ViewAssetTypeOverviewTab(props) {
  const [value, setValue] = useState("Asset Type Overview");
  const { t } = useTranslation();

  const handleTabSelect = (eventKey) => {
    setValue(eventKey);
  };

  return (
    <div>
      <SieraTabs
        variant="horizontalNew"
        defaultActiveKey="Asset Type Overview"
        activeKey={value}
        onSelect={handleTabSelect}
      >
        <SieraTab eventKey="Asset Type Overview" title={t("Asset Type Overview")}>
          <ViewAssetTypeOverviewTable {...props} />
        </SieraTab>
        <SieraTab eventKey="Asset Type Checklists" title={t("Asset Type Checklists")}>
          <AssetTypeChecklistsTab {...props} />
        </SieraTab>
      </SieraTabs>
    </div>
  );
}
