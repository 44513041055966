import { notificationSettingsTypes } from "./types";

const initialState = {
  loading: false,
  notificationUpdateSuccess: false,
  notificationUpdateError: false,
  notificationUpdateLoading: false,
  notificationBulkUpdateSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationSettingsTypes.RESET_NOTIFICATION_SETTINGS_FLAGS:
      return {
        ...state,
        loading: false,
        notificationBulkUpdateSuccess: false,
        notificationUpdateLoading: false,
        operatorCertificateExpiryAlertSettingsSuccess: false,
        nearMissAlertSettingsSuccess: false,
        bulkUpdateS3CameraSettingSuccess: false,
      };
    case notificationSettingsTypes.GET_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };

    case notificationSettingsTypes.GET_ACCOUNT_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        accountSummaryAlertSettings: action.data,
        loading: false,
      };

    case notificationSettingsTypes.NOTIFICATION_LOADING:
      return {
        ...state,
        loading: action.data,
      };

    case notificationSettingsTypes.GET_ALERT_SETTINGS_BY_ASSET_ID_SUCCESS:
      const successFlag = action.data.alertType + "Success";
      return {
        ...state,
        [action.data.alertType]: action.data.data,
        ...(action.data.setSuccessFlag ? { [successFlag]: true } : {}),
        loading: false,
      };

    case notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    case notificationSettingsTypes.BULK_UPDATE_SUCCESS:
      return {
        ...state,
        notificationBulkUpdateSuccess: true,
        notificationUpdateLoading: false,
      };
    case notificationSettingsTypes.BULK_UPDATE_LOADING:
      return {
        ...state,
        notificationUpdateLoading: true,
      };

    case notificationSettingsTypes.GET_SYSTEM_SETTINGS_USER_TYPE_SUCCESS:
      return {
        ...state,
        notificationUserTypes: action.data.data,
      };
    default:
      return state;
  }
};

export default reducer;
