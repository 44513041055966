import React from "react";
import * as htmlToImage from "html-to-image";
import { Button } from "react-bootstrap";
import { ReactComponent as Download } from "../../../assets/Download.svg";
import { ReactComponent as PrintQR } from "../../../assets/Blue_Print.svg";
import ReactDOM from "react-dom";
import { Trans } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import config from "../../../config";

const baseUrlOperator = config.operatorBaseUrl; //Base Url
const redirectAssetQrScan = config.redirectAssetQrScan; // Operator asset qr scan

function UserQRCode({ qrFor, qrDetail, bindUrl }) {
  const downloadQrCode = (e) => {
    let newDiv = document.createElement("div");
    htmlToImage
      .toSvg(
        ReactDOM.render(
          <div style={{ display: "inline-block" }}>
            <div
              className="text-center"
              style={{
                display: "inline-block",
              }}
            >
              <QRCodeSVG
                size={100}
                value={
                  qrDetail
                    ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrFor.qrContent]
                    : ""
                }
              />

              <p title={qrDetail ? qrDetail[qrFor.qrLabel] : ""}>
                {" "}
                {qrDetail ? qrDetail[qrFor.qrLabel] : ""}
              </p>
            </div>
          </div>,
          newDiv
        ),
        { quality: 0.1 }
      )
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${qrDetail ? qrDetail[qrFor.qrLabel] : "qrCode"}.svg`;
        link.href = dataUrl;
        link.click();
      });
  };

  const printQr = () => {
    let newDiv = document.createElement("div");
    htmlToImage
      .toSvg(
        ReactDOM.render(
          <div style={{ display: "inline-block" }}>
            <div
              className="text-center"
              style={{
                display: "inline-block",
              }}
            >
              <QRCodeSVG
                size={96}
                value={
                  qrDetail
                    ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrFor.qrContent]
                    : ""
                }
              />
              <p style={{ margin: "5px" }} title={qrDetail ? qrDetail[qrFor.qrLabel] : ""}>
                {" "}
                {qrDetail ? qrDetail[qrFor.qrLabel] : ""}
              </p>
            </div>
          </div>,
          newDiv
        ),
        { quality: 0.1 }
      )
      .then((dataUrl) => {
        let printDiv = document.createElement("div");
        let printQrCode = document.createElement("img");
        printQrCode.src = dataUrl;
        printQrCode.style.display = "inline-block";
        printQrCode.style.width = "100%";
        printQrCode.style.height = "100%";
        printQrCode.style.padding = "10px";

        printDiv.appendChild(printQrCode);
        let printWindow = window.open();
        const content = `<html><head><style> p{margin-top: "5px" !important;margin-bottom: "5px" !important; } </style></head><body>
                ${printDiv.innerHTML}                 
                <script>setTimeout(() => {
                    try {
                        // Print for Safari browser
                        document.execCommand('print', false, null)
                      } catch {
                        window.print()
                      }
                }, 100) </script></body></html>`;
        try {
          printWindow.document.write(content);
        } catch {
          alert("Please check pop-up permission and try again");
        }
      });
  };

  return (
    <>
      <div id="assetQrCode" className="bg-white QRcodeBulk">
        <QRCodeSVG
          id="userQrCode"
          size={100}
          value={
            qrDetail ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrFor.qrContent] : ""
          }
        />

        <div
          className="text-center"
          style={{
            fontFamily: "Open Sans",
            fontSize: "12px",
            padding: "0px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span title={qrDetail ? qrDetail[qrFor.qrLabel] : ""}>
            {" "}
            {qrDetail ? qrDetail[qrFor.qrLabel] : ""}
          </span>
        </div>
      </div>
      <div>
        <p className="downloadQRText">
          <Trans>Please print and paste this QR code on the user's badge or uniform</Trans>
        </p>
      </div>
      <div>
        <Button
          className="buttonAdd button-svg-hover"
          onClick={downloadQrCode}
          variant="outline-primary"
          style={{ marginRight: "20px" }}
        >
          <Download style={{ width: 20, marginRight: 10, fill: "#0d5fbe" }} fill="#0d5fbe" />{" "}
          <Trans>DOWNLOAD</Trans>
        </Button>
        <Button className="buttonAdd button-svg-hover" onClick={printQr} variant="outline-primary">
          <PrintQR style={{ width: 20, marginRight: 10 }} /> <Trans>PRINT</Trans>
        </Button>
      </div>
    </>
  );
}

export default UserQRCode;
