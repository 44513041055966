let units = [
  {
    label: "Metric",
    _id: "Metric",
  },
  {
    label: "Imperial",
    _id: "Imperial",
  },
];

export default units;
