import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { createStructuredSelector } from "reselect";

import { selectLoginDetails } from "../../../redux/user/user.selectors";
import { selectUserOverview } from "../../../redux/userManagement/userManagement.selector";
import { connect } from "react-redux";
import accessLevelList from "../../../constant/accessLevel";
import { Trans, useTranslation } from "react-i18next";
import { apiCall } from "../../../utils/apiCall";

const styles = {
  titleName: {
    fontFamily: "Kanit",
    fontSize: "26px",
    color: "black",
    textAlign: "left",
    paddingTop: "2px",
  },
  accessLevelTitle: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "#323232",
    textAlign: "left",
    paddingTop: "2px",
  },
  date: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#919191",
    textAlign: "left",
    paddingTop: "8px",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#323232",
    textAlign: "left",
    paddingTop: "8px",
  },
  safetyEnabled: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    color: "#0D5FBE",
    textAlign: "left",
    fontWeight: "bold",
    paddingTop: "7px",
  },
};
const UserRequestOverview = ({ selectUserOverview, loginDetails }) => {
  const [userAccess, setUserAccess] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    console.log(selectUserOverview);
    setUserAccess({ ...selectUserOverview, requestId: selectUserOverview ? selectUserOverview._id : "" });
  }, [selectUserOverview, loginDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    userAccess.isgranted = e.target.value;
    await apiCall(`/requestAccess/allowUsers`, { method: "POST", data: userAccess })
      .then(function (response) {
        if (true) {
          //handle success
          //updateUser(response.data.data)
          //toogleModalClose()
          alert(`Access ${userAccess.isgranted ? "Approved" : "Declined"}`);
          console.log(response.data.data);
        }
        // else if (response.data.errors) {
        //     setUserAddError(response.data.errors.errors[0].msg + ' ' + response.data.errors.errors[0].param)
        // }
        // else {
        //     setUserAddError(response.data.message)
        // }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  return (
    <>
      <Card className="pending_height">
        {selectUserOverview ? (
          <Card.Body>
            {/* Overview Of user access request */}
            <Row>
              <Col md={6} className="ml-2 mt-0 mb-2">
                <h2 style={styles.titleName}>{selectUserOverview ? selectUserOverview.fullName : ""}</h2>
                <h4 style={styles.date}>{selectUserOverview ? selectUserOverview.email : ""}</h4>
                <div style={styles.subTitle}>{selectUserOverview ? selectUserOverview.description : ""}</div>
                {/* <div style={styles.date}>Requested On: September 22, 2018</div> */}
              </Col>
              <Col md={{ offset: 4 }}></Col>
            </Row>

            {/* User access level dropdown and passowrd */}
            <Row className="m-0 ml-2 mb-4">
              <Col>
                <Form.Group className="selectBoxDiv hide-detail">
                  <Form.Label>
                    <Trans>Access Level</Trans>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="selectBox"
                    onChange={(e) => setUserAccess({ ...userAccess, permissions: e.target.value })}
                    value={userAccess.permissions}
                    defaultValue={userAccess.permissions}
                  >
                    {accessLevelList.map((data) => (
                      <option value={data.value}>{data.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="inputBoxDiv">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    className="inputBox"
                    onChange={(e) => setUserAccess({ ...userAccess, password: e.target.value })}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    name="password"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="inputBoxDiv">
                  <Form.Label>Password verification</Form.Label>
                  <Form.Control
                    className="inputBox"
                    onChange={(e) => setUserAccess({ ...userAccess, passwordVerification: e.target.value })}
                    id="outlined-basic"
                    label="Password verification"
                    type="password"
                    name="passwordVerification"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Reject Approve Buttons */}
            <Row className="float-right">
              <button onClick={handleSubmit} value={false} className="float-right buttonCancel mb-3 mr-0">
                <Trans>REJECT</Trans>
              </button>
              <button onClick={handleSubmit} value={true} className="buttonSubmit ml-4 mr-5">
                <Trans>APPROVE</Trans>
              </button>
            </Row>
          </Card.Body>
        ) : (
          <Card.Body className="mt-5">
            <h2>
              <Trans>No Requests</Trans>
            </h2>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loginDetails: selectLoginDetails,
  selectUserOverview: selectUserOverview,
});

export default connect(mapStateToProps, null)(UserRequestOverview);
