import React, { useRef } from "react";
import {
  LableWrapper,
  BorderBottom,
  SearchBoxWrapper,
  SearchSubmit,
  SearchIconSVG,
} from "./Searchbox.component.styles";
import PropTypes from "prop-types";
const SearchBox = (props) => {
  const {
    label,
    searchText,
    onChange,
    name,
    placeholder,
    disabled,
    type,
    setSearchText,
    isDebounceDisabled,
    delay,
    isIcon,
    size,
    value,
    ...rest
  } = props;

  const inputElement = useRef();

  const onClear = () => {
    if (!!searchText) {
      setSearchText("");
      inputElement.current.value = "";
    }
  };

  const debounceFunction = () => {
    let timer = null;
    return (e) => {
      if (timer) {
        clearTimeout(timer);
      }
      e.persist();
      timer = setTimeout(
        (e) => {
          onChange(e);
        },
        delay,
        e
      );
    };
  };

  const onChangeHandler = isDebounceDisabled ? onChange : debounceFunction();

  return (
    <SearchBoxWrapper isSearchText={!!searchText} isIcon={isIcon} disabled={disabled} size={size}>
      <LableWrapper>{label}</LableWrapper>
      <input
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={onChangeHandler}
        disabled={disabled}
        ref={inputElement}
        {...rest}
      />
      <BorderBottom isSearchText={!!searchText} />
      <SearchSubmit isIcon={isIcon}>
        <SearchIconSVG isSearchText={!!searchText} onClick={onClear} disabled={disabled} />
      </SearchSubmit>
    </SearchBoxWrapper>
  );
};

SearchBox.propTypes = {
  /**
   * Specify whether the checkbox should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Set input type
   */
  type: PropTypes.string,
  /**
   * By default the call back is debounced. It can be disabled by passing true
   */
  isDebounceDisabled: PropTypes.bool,
  /**
   * checkbox label text
   */
  label: PropTypes.string.isRequired,
  /**
   * Search box placeholder text
   */
  placeholder: PropTypes.string,
  /**
   *  Provide a function to be called when something is typed on the search box
   */
  onChange: PropTypes.func.isRequired,
  /**
   *  Initial search text if any
   */
  searchText: PropTypes.string,
  /**
   * name attribute for the search field input box
   */
  name: PropTypes.string,
  /**
   * If you want to apply custom debounce delay, pass the value using this
   */
  delay: PropTypes.number,
  /**
   * If you want to apply custom debounce delay, pass the value using this
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

SearchBox.defaultProps = {
  type: "text",
  delay: 500,
  disabled: false,
  isDebounceDisabled: false,
  size: "small",
};

export default SearchBox;
