import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import UserTypeList from "../userTypes/userTypeList/UserTypeList.component";
import ViewUserTypes from "./view/ViewUserTypes.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import { setAddUserTypeModal, setModalWaitingToOpen } from "../../../../redux/modals/action";
import AddUserTypeModal from "./add/AddUserTypeModal.component";
import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import {
  getUserTypeList,
  getUserTypeBySearchText,
  getSuperAdminUserTypes,
} from "../../../../redux/userType/action";
import { setDropdownAccountId } from "../../../../redux/dropdownFilters/action";
import {
  UserTypeManagementDiv,
  ButtonsContainer,
  UserTypeMainContainer,
  UserTypeListing,
  UserTypeDataList,
  SearchContainer,
  DesktopView,
  MobileView,
  SearchBoxWrapper,
  SearchFilter,
  FilterWrapper,
  FilterIconDiv,
  CloseIconDiv,
  FilterBackDiv,
  CloseBackDiv,
  FilterOnclickDes,
  FilterDesWrapper,
  UserMobileListWrapper,
  UserTypeDetailHeading,
  RightIconImage,
  LoaderOverlay,
} from "./UserTypesManagement.styles";

import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

import ActionButtons from "../userTypes/view/UserTypeSection/ActionButtons.component";
import UserTypeSelection from "../userTypes/view/UserTypeSection/UserTypeSelection.component";
import SearchBoxComponent from "../userTypes/view/UserTypeSection/SearchBox.component";
import UserTypeActionBar from "./UserTypeActionBar.component";
import ManagementPanelStyles from "../panels/ManagementPanel.module.css";
import { ReactComponent as rightIcon } from "../../../../assets/rightIconwhite.svg";

const ViewUserTypesManagement = ({
  history,
  isSuperAdmin,
  setAddUserTypeModal,
  addUserTypeModal,
  dropdownAccountList,
  setModalWaitingToOpen,
  getUserTypeList,
  setDropdownAccountId,
  getUserTypeBySearchText,
  dropdownAccountId,
  getSuperAdminUserTypes,
  userTypeOverview,
  updateActiveNavItem,
  UserTypeListLoading,
}) => {
  const [filterAccountId, setFilterAccountId] = useState(dropdownAccountId);
  const [searchText, setSearchText] = useState("");
  const [selectedAccessLevel, setSelectedAccessLevel] = useState("SINGLE_ACCOUNT");
  const { t } = useTranslation();
  const [showUserTypeDetail, setShowUserTypeDetail] = useState(false);

  useEffect(() => {
    getUserTypeList();
  }, [dropdownAccountId]);

  const handleAccessLevelChange = (accessLevel) => {
    setSelectedAccessLevel(accessLevel);
    if (accessLevel === "ALL_ACCOUNTS") {
      getSuperAdminUserTypes(accessLevel);
      setDropdownAccountId("all");
    }
  };

  const handleAccountChange = (accountId) => {
    setFilterAccountId(accountId);
    setDropdownAccountId(accountId);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    updateActiveNavItem("userType");

    return () => {
      window.removeEventListener("userType", handleResize);
    };
  }, []);

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (UserTypeListLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!UserTypeListLoading) {
      setShowLoader(false);
    }
  }, [UserTypeListLoading, hasShownOnce]);

  return (
    <UserTypeManagementDiv>
      {isMobile ? (
        <MobileView>
          <UserTypeActionBar setAddUserTypeModal={setAddUserTypeModal} />

          {!showUserTypeDetail ? (
            <>
              <SearchFilter>
                <SearchBoxWrapper>
                  <SearchBoxComponent
                    searchText={searchText}
                    setSearchText={setSearchText}
                    getUserTypeBySearchText={getUserTypeBySearchText}
                  />
                </SearchBoxWrapper>
                {isSuperAdmin && (
                  <FilterWrapper onClick={handleToggle}>
                    {isOpen ? (
                      <CloseBackDiv>
                        <CloseIconDiv className="icon icon-filter" />
                      </CloseBackDiv>
                    ) : (
                      <FilterBackDiv>
                        <FilterIconDiv className="icon icon-filter" />
                      </FilterBackDiv>
                    )}
                  </FilterWrapper>
                )}
              </SearchFilter>
              {isSuperAdmin && (
                <FilterOnclickDes>
                  {isOpen && (
                    <FilterDesWrapper>
                      <UserTypeSelection
                        isSuperAdmin={isSuperAdmin}
                        selectedAccessLevel={selectedAccessLevel}
                        handleAccessLevelChange={handleAccessLevelChange}
                        dropdownAccountList={dropdownAccountList}
                        dropdownAccountId={dropdownAccountId}
                        handleAccountChange={handleAccountChange}
                      />
                    </FilterDesWrapper>
                  )}
                </FilterOnclickDes>
              )}

              <UserMobileListWrapper>
                <UserTypeList
                  searchText={searchText}
                  setSearchText={setSearchText}
                  filterAccountId={filterAccountId}
                  accessLevel={selectedAccessLevel}
                  style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                  setShowUserTypeDetail={setShowUserTypeDetail}
                />
              </UserMobileListWrapper>
            </>
          ) : (
            <>
              <UserTypeDetailHeading className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
                <span onClick={() => setShowUserTypeDetail(false)}>Your User Type</span>{" "}
                <RightIconImage className="icon icon-arrow-right"></RightIconImage>
                <Trans> {userTypeOverview ? userTypeOverview.name : ""}</Trans>
              </UserTypeDetailHeading>
              {/* <WorksiteOverview filterAccountId={filterAccountId} /> */}
              <div>
                <UserTypeDataList>
                  {isSuperAdmin && (
                    <UserTypeSelection
                      isSuperAdmin={isSuperAdmin}
                      selectedAccessLevel={selectedAccessLevel}
                      handleAccessLevelChange={handleAccessLevelChange}
                      dropdownAccountList={dropdownAccountList}
                      dropdownAccountId={dropdownAccountId}
                      handleAccountChange={handleAccountChange}
                    />
                  )}
                  <ViewUserTypes
                    setFilterAccountId={setFilterAccountId}
                    selectedAccessLevel={selectedAccessLevel}
                  />
                </UserTypeDataList>
              </div>
            </>
          )}
        </MobileView>
      ) : (
        <DesktopView>
          {/* For breadcrumb */}
          <BreadCrumb />

          {/* For action buttons */}
          <ButtonsContainer>
            <ActionButtons setAddUserTypeModal={setAddUserTypeModal} />
          </ButtonsContainer>

          <Row>
            {/* For user type listing */}

            {showLoader && (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            )}

            <Col md={3}>
              <SearchContainer>
                <SearchBoxComponent
                  searchText={searchText}
                  setSearchText={setSearchText}
                  getUserTypeBySearchText={getUserTypeBySearchText}
                />
              </SearchContainer>
              <UserTypeList
                filterAccountId={filterAccountId}
                accessLevel={selectedAccessLevel}
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                setShowUserTypeDetail={setShowUserTypeDetail}
              />
            </Col>

            {/* For user type overview */}
            <Col md={9}>
              {isSuperAdmin && (
                <UserTypeSelection
                  isSuperAdmin={isSuperAdmin}
                  selectedAccessLevel={selectedAccessLevel}
                  handleAccessLevelChange={handleAccessLevelChange}
                  dropdownAccountList={dropdownAccountList}
                  dropdownAccountId={dropdownAccountId}
                  handleAccountChange={handleAccountChange}
                />
              )}
              <ViewUserTypes
                setFilterAccountId={setFilterAccountId}
                selectedAccessLevel={selectedAccessLevel}
              />
            </Col>
          </Row>
        </DesktopView>
      )}

      <AddUserTypeModal
        show={addUserTypeModal}
        toggleModalClose={() => {
          setAddUserTypeModal(false);
          setModalWaitingToOpen(false);
        }}
      />
      <CommonSuccessMessage />
    </UserTypeManagementDiv>
  );
};

const mapStateToProps = (state) => ({
  userTypeOverview: state.userType.UserTypeDetail,
  isSuperAdmin: state.user.isSuperAdmin,
  addUserTypeModal: state.modals.addUserTypeModal,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
  UserTypeListLoading: state.userType.UserTypeListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setAddUserTypeModal: (data) => dispatch(setAddUserTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  getUserTypeList: () => dispatch(getUserTypeList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  getUserTypeBySearchText: (searchText) => dispatch(getUserTypeBySearchText(searchText)),
  getSuperAdminUserTypes: (superAdminUserTypeId) => dispatch(getSuperAdminUserTypes(superAdminUserTypeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewUserTypesManagement));
