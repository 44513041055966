import React, { Fragment, useEffect, useState } from "react";
//import { useParams } from "react-router-dom";
import InspectionModal from "./Modal.component";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ReactComponent as SelectIcon } from "../../../../assets/ArrowRight.svg";

import "../Inspection/inspection.css";

// redux
import { connect } from "react-redux";
//import { Trans, useTranslation } from "react-i18next";
import {
  uploadImage,
  deleteImage,
  getChecklistById,
  postInspectionResponse,
} from "../../../../redux/inspection/action";

const inspectionCover = {
  height: "calc(100vh - 31vh)",
  border: "1px solid #CCC",
  borderRadius: 4,
  "@media (max-width: 600px)": {
    paddingBottom: "20px",
  },
};

function Inspection({
  accountId,
  userFullName,
  inspectorId,
  postInspectionRepsonseLoading,
  uploadImage,
  deleteImage,
  postInspectionResponse,
  userData,
  assetChecklists,
  deleteImageLoading,
  uploadImageLoading,
  getChecklistLoading,
  getChecklistById,
  currentChecklist,
  logoutUser,
  selectedAsset,
  inspectionPlanDetail,
  setShowInspection,
}) {
  //let { assetId } = useParams();
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [currentLocalChecklist, setCurrentLocalChecklist] = useState([]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!assetChecklists?.[0]) {
      return;
    }
    const defaultChecklist = assetChecklists?.find((checklist) => checklist?.isDefaultChecklist);
    setSelectedChecklist(defaultChecklist || assetChecklists[0]);
  }, [assetChecklists]);

  useEffect(() => {
    if (!selectedChecklist) {
      return;
    }
    getChecklistById(selectedChecklist?.checklistId?._id);
  }, [selectedChecklist]);

  useEffect(() => {
    let newChecklistResponse = [];
    currentChecklist?.sections?.map((section) => {
      section?.questions?.map((question) => {
        newChecklistResponse.push({
          message: section?.notificationMessage,
          notification: section?.sectionStartNotification,
          question: question?.questionText,
          answers: question?.responseTypeId?.controlType,
          isFailedResponse: question?.isQuestionMarkAsChecklistFail,
          failedResponse: question?.failedResponse,
          id: question?._id,
          sectionId: section?._id,
          sectionName: section?.sectionName,
          isMeterReading: question?.responseTypeId?.responseText === "Meter Reading",
          responseTypeId: question?.responseTypeId?._id,
        });
      });
    });
    setCurrentLocalChecklist([...newChecklistResponse]);
  }, [currentChecklist]);

  return (
    <div style={inspectionCover} className="mb-11 md:mb-0 lg:mb-0">
      <div className="mx-auto  mt-5 text-center font-sans w-11/12 md:w-3/5 lg:w-3/5">
        <div className="inspection-name">
          <p className="text-md mb-2 font-sans">
            You are inspecting <span className="font-bold">{selectedAsset.assetName}</span>
          </p>
        </div>
        <div>
          <>
            <Listbox value={selectedChecklist} onChange={setSelectedChecklist}>
              <div className="relative mt-5 operator-name-button">
                <Listbox.Button className="font-sans relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-secondary-background focus-visible:ring-offset-2 focus-visible:border-indigo-500 text-lg border border-[#C6C6C6]">
                  <span className="truncate text-sm text-[#323232] d-block">
                    {selectedChecklist?.checklistId?.checklistName}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectIcon className="w-5 h-5 text-gray-400 rotate-icon" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="inspection-select-list absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg">
                    {assetChecklists?.map((checklist, checklistIndex) => (
                      <Listbox.Option
                        key={checklist?._id}
                        className={({ active }) =>
                          `${active ? "text-black bg-secondary-background/[.1]" : "text-black"}
                          cursor-pointer select-none relative py-2 pl-10 pr-4 text-left`
                        }
                        value={checklist}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? "font-medium" : "font-normal"
                              } text-sm text-[#323232] truncate`}
                            >
                              {checklist?.checklistId?.checklistName}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active ? "text-black" : "text-black"
                                } absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {currentLocalChecklist.length > 0 && (
              <InspectionModal
                uploadImage={uploadImage}
                deleteImage={deleteImage}
                setCurrentLocalChecklist={setCurrentLocalChecklist}
                currentLocalChecklist={currentLocalChecklist}
                setShowInspection={setShowInspection}
                postInspectionResponse={postInspectionResponse}
                postInspectionRepsonseLoading={postInspectionRepsonseLoading}
                uploadImageLoading={uploadImageLoading}
                deleteImageLoading={deleteImageLoading}
                getChecklistLoading={getChecklistLoading}
                logoutUser={logoutUser}
                inspectData={{
                  inspectionPlanDetail: inspectionPlanDetail,
                  userFullName: userFullName,
                  accountId: accountId,
                  inspectorId: inspectorId,
                  assetId: selectedAsset?._id,
                  currentChecklist: currentLocalChecklist,
                  checklistId: currentChecklist?._id,
                }}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  postInspectionRepsonseLoading: state.inspect.postInspectionRepsonseLoading,
  currentChecklist: state.inspect.checklist,
  getChecklistLoading: state.inspect.getChecklistLoading,
  deleteImageLoading: state.inspect.deleteImageLoading,
  uploadImageLoading: state.inspect.uploadImageLoading,
  accountId: state.user.loginDetails?.accountId,
  userFullName: state.user.loginDetails?.userData?.fullName,
  userData: state.user.loginDetails.userData,
  loginDetails: state.user.loginDetails,
  inspectorId: state.user.loginDetails?.userData?._id,
  assetChecklists: state.inspectionAssets.AssetCheckList,
  selectedAsset: state.inspect.selectedAsset,
  inspectionPlanDetail: state.config.inspectionPlan,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (data, callbackAfterUpload) => dispatch(uploadImage(data, callbackAfterUpload)),
  deleteImage: (data, callbackAfterDelete) => dispatch(deleteImage(data, callbackAfterDelete)),
  postInspectionResponse: (data, callbackAfterInspect) =>
    dispatch(postInspectionResponse(data, callbackAfterInspect)),
  getChecklistById: (checklistId) => dispatch(getChecklistById(checklistId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
