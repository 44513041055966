import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as DownloadIcon } from "../../../assets/Download_In_Progress.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/Back_Button.svg";
import { ReactComponent as EmailIcon } from "../../../assets/Email.svg";

const DownloadAllReportModal = ({ showDownloadAllReportModal, setShowDownloadAllReportModal }) => {
  return (
    <Modal
      size="md"
      className="custom-modal"
      show={showDownloadAllReportModal}
      onHide={() => setShowDownloadAllReportModal(false)}
      backdrop="static"
    >
      {/* modal body starts */}

      <Modal.Body className="text-center modalPopup">
        <Row style={{ justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
          <DownloadIcon style={{ width: 60, marginRight: 10 }} fill="#0d5fbe" />
          <ArrowIcon style={{ width: 25, marginRight: 10, transform: "rotate(180deg)" }} fill="#0d5fbe" />
          <EmailIcon style={{ width: 60, marginRight: 10 }} fill="#0d5fbe" />
        </Row>
        <Row className="mb-4 mt-4 ">
          <Col>
            <span className="mb-2 text-open-sans text-center modal-font-color" style={{ fontSize: "1rem" }}>
              <Trans>
                The reports are getting compiled. You will receive the link shortly on your email to download
                the CSV file.
              </Trans>
            </span>
          </Col>
        </Row>

        {/* Modal body ends */}
        <Row className="justify-content-center">
          <div className="s3modalbuttonyes">
            <button
              onClick={() => {
                setShowDownloadAllReportModal(false);
              }}
              className="btn btn-primary mb-2 buttons ripple"
            >
              <Trans> CONTINUE </Trans>
            </button>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAllReportModal);
