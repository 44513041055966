//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { addUser } from "../../../redux/userManagement/userManagement.action";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { toValidateUserName } from "../../../utils/validations/checkValidation";
import { postUser } from "../../../redux/users/action";

import { Trans, useTranslation } from "react-i18next";
import Input from "../../../components/Input";
import { userList } from "../../../redux/userManagement/userManagement.action";
import { UserOverviewFormDiv, ErrorField, HeadingContainer } from "./UserOverviewForm.component.styles";
import MultiSelector from "../../../components/multiSelector/MultiSelector.component";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import { getUserTypeDetailList } from "../../../redux/userType/action";

import CustomInputField from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";

function UserOverviewForm({
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  setDropdownAccountId,
  dropdownWorksiteList,
  dropdownAccountList,
  handleNext,
  setFormData,
  formData,
  isSubmitClicked,
  userTypeDetailList,
  selectedUserType,
  setSelectedUserType,
  getUserTypeDetailList,
  dropdownAccountId,
  isUpdate,
}) {
  const [validated, setValidated] = useState(false);
  const [isValidationError, setValidationError] = useState(false);
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isWorksiteError, setIsWorksiteError] = useState(false);
  const [isUserTypeError, setIsUserTypeError] = useState(false);
  const [isAccountError, setIsAccountError] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      handleUserTypeChange(formData.newUserTypeId);
    }
  }, [formData?.newUserTypeId]);

  const handleAccessLevelChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      permissions: value,
    }));
  };

  const handleUserTypeChange = (value) => {
    userTypeDetailList?.forEach((userType) => {
      if (userType._id == value) {
        // setSelectedUserType(userType);
        setFormData((prevState) => ({
          ...prevState,
          userTypeId: value,
          accessLevel: userType.accessLevel,
          newUserTypeId: value,
          pin: null,
          email: null,
          password: null,
          passwordVerification: null,
        }));

        setSelectedUserType({
          ...userType,
          multiWorksitesOptions: [],
        });
        setSelectedOptions([]);
      }
    });
  };

  const handleUsernameChange = (e) => {
    setFormData({
      ...formData,
      fullName: e.target?.value,
    });
  };

  useEffect(() => {
    if (selectedUserType?.multiWorksitesOptions?.length > 0) {
      setSelectedOptions(selectedUserType?.multiWorksitesOptions);
    }
  }, [selectedUserType?.multiWorksitesOptions]);

  const handleWorksiteChange = (selectedOptions) => {
    const valueArr = selectedOptions.map((item) => {
      return item.value;
    });

    if (selectedUserType?.accessLevel === "MULTIPLE_WORKSITES") {
      setSelectedUserType({
        ...selectedUserType,
        multiWorksitesOptions: selectedOptions,
      });
    }

    setIsWorksiteError(false);
    setFormData({
      ...formData,
      authorizedSites: valueArr,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let form = event.target;
    let errorFound = false;
    if (toValidateUserName(formData?.fullName)) {
      setValidated(true);
    }

    if (isSuperAdmin && !formData.accountId) {
      setIsAccountError(true);
      errorFound = true;
    }

    if (!selectedUserType) {
      setIsUserTypeError(true);
      errorFound = true;
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      errorFound = true;
    }

    if (
      selectedUserType?.accessLevel === "MULTIPLE_WORKSITES" &&
      (!formData?.authorizedSites || formData?.authorizedSites?.length <= 0)
    ) {
      setIsWorksiteError(true);
      event.stopPropagation();
      setValidated(true);
      errorFound = true;
    }

    if (
      (selectedUserType?.accessLevel === "SINGLE_WORKSITE" ||
        selectedUserType?.accessLevel === "INDIVIDUAL_EMPLOYEE") &&
      (!formData?.authorizedSites || formData?.authorizedSites?.length <= 0)
    ) {
      setIsWorksiteError(true);
      event.stopPropagation();
      setValidated(true);
      errorFound = true;
    }

    if (errorFound) {
      return;
    }

    if (isValidationError) {
      //console.log("PEEP PEEP");
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    handleNext(event);
    event.preventDefault();
  };

  //remove this function and test it when time permits
  const validateUserData = (formData) => {
    /*  let accessLevel = formData.permissions;
    let accountId = formData.accountId;
    let siteId = formData.authorizedSites; */
    let errors = [];
    // if (!accessLevel) {
    //   errors.push("Access Level");
    // }
    // if (!accountId && isSuperAdmin) {
    //   errors.push("Account");
    // }
    // if (
    //   !siteId &&
    //   [permissions.SITE_ADMIN, permissions.OPERATOR].includes(formData.permissions) &&
    //   !isSiteAdmin
    // ) {
    //   errors.push("Worksite");
    // }
    return errors;
  };

  useEffect(() => {
    if (!isSubmitClicked) {
      return;
    }
    let isError = validateUserData(formData);
    if (isError[0]) {
      setValidationError(isError);
    } else {
      setValidationError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isSubmitClicked]);

  useEffect(() => {
    if (formData?.accessLevel === "ALL_WORKSITES") {
      setFormData({
        ...formData,
        authorizedSites: dropdownWorksiteList?.map((worksite) => {
          return worksite._id;
        }),
      });
    } /* else {
      setFormData({
        ...formData,
        authorizedSites: [],
      });
    } */
  }, [formData?.accessLevel]);

  //get userType detai list when accountId changes
  useEffect(() => {
    if (formData.accountId) {
      getUserTypeDetailList();
    }
  }, [dropdownAccountId]);

  useEffect(() => {
    if (formData?.accountId) {
      setDropdownAccountId(formData?.accountId || "");
    }
  }, [formData?.accountId]);

  const [userTypeOptions, setUsertypeOptions] = useState([]);
  useEffect(() => {
    if (!(isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all"))) {
      const usertypeOptionsTemp = userTypeDetailList
        ?.filter((data) => {
          return data.isActive;
        })
        .map((data) => {
          return {
            _id: data._id,
            value: data._id,
            label: data.name,
          };
        });
      setUsertypeOptions(usertypeOptionsTemp);
    }
  }, [userTypeDetailList]);

  return (
    <UserOverviewFormDiv>
      <Container>
        <HeadingContainer>
          <Trans>User Overview</Trans>
        </HeadingContainer>

        <Form noValidate validated={validated} id="userAddForm1" onSubmit={handleSubmit}>
          <Row className="p-4">
            {/* Super Admin Dropdown */}
            {isSuperAdmin && (
              <Col md={6} className="mb-2">
                <DropdownFilterWrapper
                  options={dropdownAccountList?.map((data) => ({
                    _id: data._id,
                    value: data._id,
                    label: data.companyName,
                  }))}
                  label={t("Account")}
                  isValidationError={isValidationError}
                  isError={isAccountError}
                  errorMessage={t("This field is required")}
                  value={formData?.accountId || ""}
                  onChange={(value) => {
                    setDropdownAccountId(value);
                    setFormData({
                      ...formData,
                      accountId: value,
                    });
                  }}
                  disabled={formData?.accountId && formData?.accountId !== "all" && isUpdate}
                />
              </Col>
            )}

            {/* First Name and Last Name Input */}
            <Col md={6} className="mb-2">
              <CustomInputField
                label={t("First and Last Name")}
                placeholder={t("First and Last Name")}
                name="fullName"
                value={formData.fullName || ""}
                onChange={handleUsernameChange}
                required
                isError={validated && t(toValidateUserName(formData?.fullName))}
                errorMessage={t("This field is required")}
              />
            </Col>

            {/* User Type */}
            <Col md={6} className="mb-2">
              <DropdownFilterWrapper
                label={t("User Type")}
                options={userTypeOptions}
                required={true}
                isValidationError={isValidationError}
                isError={isUserTypeError}
                value={formData?.userTypeId}
                onChange={(value) => {
                  setIsUserTypeError(false);
                  handleUserTypeChange(value);
                }}
                errorMessage={t("This field is required")}
              />
            </Col>

            {/* Worksite Selection */}
            {selectedUserType?.accessLevel === "MULTIPLE_WORKSITES" && (
              <Col md={6} className="mb-2">
                <MultiSelector
                  options={dropdownWorksiteList.map((option) => ({
                    label: option.name,
                    value: option._id,
                  }))}
                  placeholder={t("Select worksites")}
                  isDisabled={false}
                  label={t("Worksites")}
                  changeHandler={handleWorksiteChange}
                  returnValueArray={false}
                  selectedOptions={selectedOptions}
                  clearAllText={t("Clear All")}
                />

                {isWorksiteError && <ErrorField fontSize="14">{t("This field is required")}</ErrorField>}
              </Col>
            )}

            {(selectedUserType?.accessLevel === "SINGLE_WORKSITE" ||
              selectedUserType?.accessLevel === "INDIVIDUAL_EMPLOYEE") && (
              <>
                <Col md={6} className="mb-2">
                  <DropdownComponent
                    label={t("Worksite")}
                    placeholder={t("Select a worksite")}
                    options={dropdownWorksiteList.map((option) => ({
                      label: option.name,
                      value: option._id,
                    }))}
                    value={dropdownWorksiteList?.map((worksite) => {
                      if (
                        worksite._id === formData?.authorizedSites?.[0] ||
                        worksite._id === formData?.authorizedSites
                      ) {
                        return {
                          label: worksite.name,
                          value: worksite._id,
                        };
                      }
                    })}
                    handleFilter={(selectedWorksite) => {
                      setIsWorksiteError(false);
                      setFormData({
                        ...formData,
                        authorizedSites: [selectedWorksite.value],
                      });
                    }}
                    errorMessage={t("This field is required")}
                    isError={isWorksiteError}
                  />
                </Col>
              </>
            )}
          </Row>

          {/* TODO : Changing the tooltip as the core logic is changed */}
          {/* Tooltip */}
          <Row className="mt-3 pl-2">
            <Col md={8} className="instruction-bottom" style={{ padding: "0px" }}></Col>
          </Row>
        </Form>
      </Container>
    </UserOverviewFormDiv>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  userDetails: state.userManagement.users,
  worksiteList: state.worksites.Worksites,
  addUserLoading: state.users.AddUserLoading,
  addUserSuccess: state.users.AddUserSuccess,
  addUserError: state.users.AddUserError,
  userErrMessage: state.users.UserMessage,
  accountList: state.accounts.Accounts,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  userTypeDetailList: state.userType.userTypeDetailList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  addUser: (userDetails) => dispatch(addUser(userDetails)),
  postUser: (data, cb) => dispatch(postUser(data, cb)),
  setDropdownAccountId: (id) => dispatch(setDropdownAccountId(id)),
  userList: (users) => dispatch(userList(users)),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOverviewForm);
