import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import AssetList from "./AssetList.component";
import Assets from "./Assets.component";
import { connect } from "react-redux";
import { setEditAlertModal } from "../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";
import SearchBox from "../../../components/SearchBox/Searchbox.component";
import styled from "styled-components";

const ManagementRow = styled.div`
  margin-top: 10px;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AssetManagement = ({ assetTypes, setEditAlertModal, editForm, editFormSecond, AssetLoading }) => {
  const [SearchText, setSearchText] = useState("");
  const [SiteFilter, setSiteFilter] = useState("all");
  const [AssetType, setAssetType] = useState("all");
  const [filterAccountId, setFilterAccountId] = useState("all");

  //To handle asset type
  const handleChange = (e) => {
    setAssetType(e.target.value);
  };

  const handleSiteFilter = (e) => {
    setSiteFilter(e.target.value);
  };

  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (AssetLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!AssetLoading) {
      setShowLoader(false);
    }
  }, [AssetLoading, hasShownOnce]);

  return (
    <ManagementRow>
      {showLoader && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}

      <Row>
        <Col lg={3}>
          <SearchBox
            label={t("Your Assets")}
            placeholder={t("Search an Asset")}
            searchText={SearchText}
            onChange={(e) => setSearchText(e.target.value)}
            setSearchText={(data) =>
              editForm && editFormSecond ? setSearchText(data) : setEditAlertModal(true)
            }
          />
          <AssetList
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            searchText={SearchText}
            assetTypes={assetTypes}
            siteFilter={SiteFilter}
            assetType={AssetType}
            handleChange={handleChange}
            filterAccountId={filterAccountId}
            setShowAssetDetail={() => {}}
          />
        </Col>
        <Col lg={9}>
          <Assets
            searchText={SearchText}
            setSearchText={setSearchText}
            handleFilter={handleSiteFilter}
            userFilter={SiteFilter}
            assetType={AssetType}
            handleChange={handleChange}
            assetTypes={assetTypes}
            filterAccountId={filterAccountId}
            setFilterAccountId={setFilterAccountId}
          />
        </Col>
      </Row>
    </ManagementRow>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.assets.AssetTypes,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  AssetLoading: state.assets.AssetLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetManagement);
