import React, { useState } from "react";

import { Container, Row, Col, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  RegisterTitle,
  RegisterSubTitle,
  RegisterSubTitle2,
  ForgotPasswordContainer,
  ButtonWrapper,
} from "./ForgotPasswordForm.styles";
import { toValidateEmail } from "../../../utils/validations/checkValidation";
import SnackBar from "../../Dashboard/SnackBar/SnackBar";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";
import Button from "../../../components/Button/Button.component";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";

const ForgotPasswordForm = ({ history }) => {
  const [forgot, setForgot] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validationMessage = toValidateEmail(forgot);
    if (validationMessage) {
      setIsValidated(true);
      setIsInvalidEmail(true);
      return;
    }

    setIsInvalidEmail(false);
    setIsValidated(false);
    if (!toValidateEmail(forgot)) {
      setIsLoading(true);
      apiCall(`/user/password/forgot/${forgot}`)
        .then((response) => {
          history.push("/enterprisepasswordrecoverysuccess");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setIsFailed(true);
        });
    }
  };

  const handleCloseSnackBar = () => {
    setIsFailed(false);
  };
  const { t } = useTranslation();
  const handleMessage = (isFailed) => {
    if (isFailed) {
      return t(validationConstant.user_not_found);
    }
  };
  return (
    <ForgotPasswordContainer className="forgot-pass forgot-pass-height">
      <RegisterTitle>{t("Password Recovery")}</RegisterTitle>
      <RegisterSubTitle>{t("Don’t worry! Resetting your password is easy")}.</RegisterSubTitle>
      <RegisterSubTitle2>{t("Just enter your registered enterprise email address")}</RegisterSubTitle2>
      <Container>
        <Form onSubmit={handleSubmit} noValidate validated={isValidated}>
          <Row>
            <Col md={3}></Col>
            <Col md={6} className="login-email">
              <InputFieldWrapper
                autoFocus={true}
                placeholder={t("Email")}
                type="email"
                name="emailId"
                size="medium"
                onChange={(e) => setForgot(e.target.value)}
                isError={isInvalidEmail}
                value={forgot}
                errorMessage={t(validationConstant.invalid_email)}
              />
            </Col>
            <Col md={3}></Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col md={12} className="text-center">
              <ButtonWrapper>
                <Button
                  className="cancel"
                  onClick={() => history.push("/")}
                  label={t("CANCEL")}
                  buttonType="ghost"
                  size="large"
                  showIcon={false}
                />

                <Button
                  className="save"
                  label={
                    isLoading ? (
                      <div className="spinner-border spinner-border-sm" role="status"></div>
                    ) : (
                      t("Send")
                    )
                  }
                  buttonType="primary"
                  size="large"
                  showIcon
                  iconClass="send"
                />
              </ButtonWrapper>
            </Col>
          </Row>
        </Form>
        <SnackBar isFailed={isFailed} label={handleMessage(isFailed)} handleClose={handleCloseSnackBar} />
      </Container>
    </ForgotPasswordContainer>
  );
};

export default withRouter(ForgotPasswordForm);
