import styled from "styled-components";

export const AssetPermissionWrapper = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    margin-top: 10px;
    .mobileHide {
      font-size: 0px !important;
    }
    .mobileHideSpan {
      display: none;
    }
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 33% 33% auto;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  @media (max-width: 1100px) {
    grid-template-columns: ${({ isHeader }) => (isHeader ? "33% 12% auto" : "100%")};
    display: ${({ isHeader }) => (isHeader ? "grid" : "block")};

    position: relative;
    border: ${({ isHeader }) => (isHeader ? "0px" : "1px solid #CCCCCC")};
    margin: ${({ isHeader }) => (isHeader ? "0px" : "10px 0px")};
    padding: 10px 0px;

    .editSwitchMobile {
      float: right;
      position: absolute;
      top: 5px;
      right: 0px;
    }
    .mobileFontSize {
      font-size: 16px !important;
    }
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "14px" : "14px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  label {
    margin: 0 !important;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  @media (max-width: 1100px) {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
    line-height: 10px;
    justify-content: left !important;
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 652px)" : "calc(100vh - 640px)")};
  padding-bottom: 10px;

  @media (max-width: 1100px) {
    height: auto;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;

export const AllowDisallowWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const AllowDisallow = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? "#bcbcbc" : "#1070e0")};
  padding: 0 5px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  user-select: none;
`;

export const MobileView = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const DesktopView = styled.div`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;


