import React from "react";
import styled from "styled-components";

const BreadCrumbStyle = {
  small: {
    fontSize: "14px",
    buttonDotFont: "30px",
    backButton: "14px",
    backButtonLineH: "25px",
    backButtonHeight: "32px",
    backButtonWidth: "32px",
  },
  medium: {
    fontSize: "16px",
    buttonDotFont: "33px",
    backButton: "16px",
    backButtonLineH: "27px",
    backButtonHeight: "34px",
    backButtonWidth: "34px",
  },
  large: {
    fontSize: "18px",
    buttonDotFont: "34px",
    backButton: "18px",
    backButtonLineH: "29px",
    backButtonHeight: "38px",
    backButtonWidth: "38px",
  },
};

export const BreadcrumbStyled = styled.div`
  .pointerCursor {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
  .breadcrumb {
    font-family: "Open Sans", sans-serif;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0rem;
    list-style: none;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-radius: 0;
    align-items: center;
  }
  li.breadcrumb-item {
    align-items: center;
    &:nth-child(2) {
      &:before {
        display: none !important;
      }
    }
  }
  li.breadcrumb-item a {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    text-decoration: none;
    font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
    font-weight: 300;
    text-align: center;
    padding: 2px 2px;
    width: inherit;
    border-radius: 100%;
    &:hover {
      background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      border-radius: 4px;
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    &::before {
      content: "=";
      display: block;
      font-family: "siera-font-icons";
      font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
      color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    }
  }

  .dropdown-toggle {
    background: none;
    border: 0px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .show > .btn-secondary.dropdown-toggle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(255, 255, 255, 1) !important;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: black !important;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    box-shadow: 0 0 0 0rem rgba(255, 255, 255, 1) !important;
  }
  li.active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    text-decoration: none;
    font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
    font-weight: 700;
    align-items: center;
    text-transform: capitalize;
    a {
      &:hover {
        background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      }
    }
    b {
      font-weight: 700;
      padding: 4px 8px;
      &:hover {
        background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
        border-radius: 4px;
      }
    }
  }
  .dropdownStyle {
    display: inline-flex;
    margin-left: 5px;
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    .dropdown-menu {
      padding: 0rem 0;
      background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3) !important;
      border: 0;
      max-height: 180px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: green !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
      }

      a.dropdown-item {
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
        font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
        &:hover {
          color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
          text-decoration: none;
          background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
        }
        &:focus {
          color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
          text-decoration: none;
          background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
        }
      }
    }
  }
  .dropdownStyle {
    &::before {
      content: "=";
      display: block;
      font-family: "siera-font-icons";
      align-self: center;
      font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
    }
    &::after {
      content: "=";
      display: block;
      font-family: "siera-font-icons";
      align-self: center;
      font-size: ${(props) => BreadCrumbStyle[props.size].fontSize} !important;
    }
  }

  .dropdownStyle button {
    width: 30px;
    margin: 0;
    padding: 0;
    font-size: ${(props) => BreadCrumbStyle[props.size].buttonDotFont} !important;
    margin-top: -6px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    &::after {
      display: none;
    }
  }
  .backButtonStyle {
    transform: rotate(180deg);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border-radius: 100%;
    line-height: ${(props) => BreadCrumbStyle[props.size].backButtonLineH} !important;
    height: ${(props) => BreadCrumbStyle[props.size].backButtonHeight} !important;
    width: ${(props) => BreadCrumbStyle[props.size].backButtonWidth} !important;
    align-items: center;
    text-align: center;
    cursor: pointer;
    &:hover {
       background-color: ${({ theme }) => theme.colors.grayL3 || "#676767"}; 
      a {
        background-color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
        .icon {
          color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        }
      }
    }
    a {
      &:hover {
        background: none !important;
      }
    }
  }
`;

export const BackButton = styled.div`
  font-size: ${(props) => BreadCrumbStyle[props.size].backButton} !important;
  display: inline-block;
  margin-top: 3px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  &:hover {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
`;
