import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { MarketingCarousel } from "./MarketingCarousel/MarketingCarousel.component";

import Register from "./RegistrationForm/RegistrationForm.component";
import { Trans } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import TysonSVG  from "../../assets/regis-logos/1200px-Tyson_Foods_logo.svg.png";
import GeodisSVG from "../../assets/regis-logos/Geodis-Logo-PNG.png";
import GringSVG from "../../assets/regis-logos/Grainger.png";
import KenchoSVG from "../../assets/regis-logos/Kenco_Logo.png";
import KensSVG from "../../assets/regis-logos/Kens-Logo.png";
import OwncoSVG from "../../assets/regis-logos/Owen-Cornings_Logo_Horizontal.jpg";

// Material UI
// Tabs
const responsive_c = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RegistrationLanding = (props) => {
  // Setting Tab Value
  const [value, setValue] = React.useState(0);

  // Setting Header Text State
  const [header, setHeader] = useState({ status: "login" });

  // Tab Value Change Handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Row noGutters>
        <Col xs={12} md={12} lg={8} xlg={8}>
          <MarketingCarousel handleChange={handleChange} setHeader={setHeader} />
        </Col>
        <Col xs={12} md={12} lg={4} xlg={4} className="height_Overflow">
          <>
            <div className="regist-top-header">
              <h2>
                <Trans>Create Your Free Account Today!</Trans>
              </h2>
              <h4>
                <Trans>Get 30-days FREE Pro trial. No Credit card required. Cancel Anytime.</Trans>
              </h4>
            </div>
          </>
          <Register />
          <div className="carousel-wrapper">
            <div className="carousel-heading">
              <Trans>Trusted by</Trans>
            </div>
            <Carousel
              responsive={responsive_c}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              className="borderCar-Reg"
            >
              <div>
                <img alt="" src={TysonSVG} />
              </div>
              {/*<div>
                      
                        <img alt="sss" src={DbschSVG} />
                      
                       </div> */}
              <div>
                <img alt="" src={GeodisSVG} />
              </div>
              <div>
                <img alt="" src={GringSVG} />
              </div>
              <div>
                <img alt="" src={KenchoSVG} />
              </div>
              <div>
                <img alt="" src={KensSVG} />
              </div>
              <div>
                <img alt="" src={OwncoSVG} />
              </div>
            </Carousel>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RegistrationLanding;
