/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA DASHBOARD MENU TYPES
 */

export const menuStateTypes = {
  // 1. TYPE: MENU LIST
  UPDATE_MENU: "UPDATE_MENU",
  UPDATE_REPORT_TAB: "UPDATE_REPORT_TAB",
  UPDATE_ACTIVE_ITEM: "UPDATE_ACTIVE_ITEM",
};
