import React, { useEffect, useRef, useState } from "react";
import "./flipcard.css";

export default function FlipCard({ front, back, currentFace = "front" }) {
  const [frontHeight, setFrontHeight] = useState(0);
  const [backHeight, setBackHeight] = useState(0);

  const frontRef = useRef();
  const backRef = useRef();

  useEffect(() => {
    let frontFaceChild = frontRef.current.children;
    let backFaceChild = backRef.current.children;
    if (frontFaceChild[0]) {
      setFrontHeight(getChildHeight(frontFaceChild));
    }
    if (backFaceChild[0]) {
      setBackHeight(getChildHeight(backFaceChild));
    }
  }, []);

  const getChildHeight = (node) => node[0].offsetHeight;

  return (
    <div
      class={`flip-card ${currentFace !== "front" ? "second" : ""}`}
      style={{
        height: currentFace === "front" ? frontHeight + 120 : backHeight + 120,
      }}
    >
      <div className="content">
        <div className="front" ref={frontRef}>
          {front?.()}
        </div>
        <div className="back" ref={backRef}>
          {back?.()}
        </div>
      </div>
    </div>
  );
}
