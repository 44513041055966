const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export const getMonth = (i) => monthsArray[i - 1 ? i - 1 : 0];

export const isNotCurrentYear = (startYear, endYear) => {
  let currentYear = new Date().getFullYear();
  if (currentYear !== startYear || currentYear !== endYear) {
    return false;
  }
  return true;
};

export const isEqual = function (value, other) {
  if (!value && !other) {
    return true;
  }
  var type = Object.prototype.toString.call(value);

  if (type !== Object.prototype.toString.call(other)) return false;

  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;
  var valueLen = type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen = type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  var compare = function (item1, item2) {
    var itemType = Object.prototype.toString.call(item1);

    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }
  return true;
};

//Following utility function is used to generate password regex based on the configuration
export const generatePasswordRegex = (config) => {
  const parts = [];

  if (config.minLength) {
    parts.push(`(?=.{${config.minLength},})`);
  }
  if (config.upperCaseRequired) {
    parts.push(`(?=.*[A-Z])`);
  }
  if (config.lowerCaseRequired) {
    parts.push(`(?=.*[a-z])`);
  }
  if (config.numberRequired) {
    parts.push(`(?=.*\\d)`);
  }
  if (config.specialCharacterRequired) {
    parts.push(`(?=.*[~!@#$%^&*()\\-_=+])`);
  }

  const regexString = parts.join("");
  return new RegExp(`^${regexString}.*$`);
};
