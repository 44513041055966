//TODO - Clean up, Refactor,  Optimize this file when time permits and remove this comment afterwards

import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import { userList, defaultUserOverview } from "../../../redux/userManagement/userManagement.action";
import { setQrcodeFilterBy } from "../../../redux/dropdownFilters/action";
import { setAddUserModal } from "../../../redux/modals/action";
import { getUsers, resetUsersState } from "../../../redux/users/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setModalWaitingToOpen } from "../../../redux/modals/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import planConstant from "../../../constant/planConstant.json";
import { PLAN_TO_ID } from "../../../constant/billingPlans";
import permission from "../../../constant/permission";

import AddNewUserModal from "./modals/AddUserModal/AddUserModal.component";
import { UserCentralModal } from "../Management/panels/userPanel.component";
import Button from "../../../components/Button/Button.component";
import { getUserExportData } from "../../../redux/users/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import ManagementPanelStyles from "../../../view/Dashboard/Management/panels/ManagementPanel.module.css";

const { inspectionPlan } = planConstant;

function UserActionBar({
  pendingList,
  setPendingList,
  setWorksiteBulkUploadShow,
  history,
  setAddUserModal,
  showActionBar,
  addUserModal,
  setModalWaitingToOpen,
  editForm,
  setEditAlertModal,
  generateCSVData,
  generateCSVLoading,
  inspectionPlanDetail,
  isSuperAdmin,
  loginDetails,
  editFormSecond,
  getUserExportData,
  props,
  setQrcodeFilterBy,
}) {
  //const [modalShow, setModalShow] = useState(false);
  const [showExportData, setShowExportData] = useState(false);
  const { t } = useTranslation();
  const isPlanValidToExport = () => {
    let noInspecDone = inspectionPlanDetail && inspectionPlanDetail.count;
    let noOfLimit =
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed;
    if (!noInspecDone) {
      noInspecDone = 0;
    }
    if (isSuperAdmin) {
      return true;
    }
    if (!noOfLimit) {
      return false;
    } else if (noOfLimit > noInspecDone) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isPlanValidToExport) {
      getUserExportData();
    }
    // updateActiveNavItem("users");
  }, []);

  useEffect(() => {
    if (generateCSVData?.length > 0) {
      setShowExportData(true);
    } else {
      setShowExportData(false);
    }
    // updateActiveNavItem("users");
  }, [generateCSVData]);

  let access = loginDetails?.permission?.permissionName;

  //let basicPlan = loginDetails?.plan === PLAN_TO_ID.Basic;
  const headersData = [
    { label: t("First Name and Last Name"), key: "fullName" },
    { label: t("User Type"), key: "usertype" },
    { label: t("Worksite"), key: "worksite" },
    { label: t("Email ID"), key: "email" },
    { label: t("Phone"), key: "phone" },
    // { label: t("Last Meter Reading"), key: "meterReading" },
    // { label: t("Last Inspection Result"), key: "status" },
    // { label: t("Last Inspected By"), key: "inspector" },
    // { label: t("Last Inspection On"), key: "date" },
    // { label: t("Last Impact On"), key: "lastImpactDate" },
    // { label: t("Last Impact Severity"), key: "lastimpactSeverity" },
    // { label: t("Last Impact By"), key: "lastImpactBy" },
  ];

  const handleDownloadCSV = (e) => {
    if (generateCSVData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <PermissionCheck section="DASHBOARD" permissionName="USER_TYPE_MANAGEMENT" actionName="view">
        <Button
          label={t("Manage User Types")}
          onClick={() => {
            updateActiveNavItem("userType");
            history.push("/app/management/userType");
          }}
          buttonType="secondary"
        />
      </PermissionCheck>
      {/* ADD USER */}
      <PermissionCheck section="DASHBOARD" permissionName="USER_MANAGEMENT" actionName="add">
        <Button
          iconClass="add"
          label={t("Add User")}
          onClick={() => (editForm ? setAddUserModal(true) : setEditAlertModal(true))}
          showIcon
          buttonType="secondary"
        />
      </PermissionCheck>

      {/* BULK UPLOAD */}
      <PermissionCheck section="DASHBOARD" permissionName="USER_MANAGEMENT" actionName="add">
        <Button
          iconClass="bulk-upload"
          label={t("Bulk Upload")}
          onClick={() => (editForm ? setWorksiteBulkUploadShow(true) : setEditAlertModal(true))}
          showIcon
          buttonType="secondary"
        />
      </PermissionCheck>
      {/* PRINT QR CODES */}
      <Button
        iconClass="print"
        label={t("Print Qr Codes")}
        onClick={() => {
          if (editForm) {
            setQrcodeFilterBy("users");
            history.push("/app/management/qrcode");
          } else {
            setEditAlertModal(true);
          }
        }}
        showIcon
        buttonType="secondary"
      />
      <CSVLink
        data={generateCSVData ? generateCSVData : []}
        headers={headersData}
        filename={"user-ledger.csv"}
      >
        <Button
          disabled={!showExportData}
          iconClass="download"
          label={t("Export Data")}
          showIcon
          buttonType="secondary"
          onClick={editForm && editFormSecond ? handleDownloadCSV : setEditAlertModal}
        />
      </CSVLink>

      <UserCentralModal
        show={addUserModal}
        onHide={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        toogleModalClose={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        setIsSuccess={() => {}}
        setIsError={() => {}}
        updateUserDetails={props.updateUserDetails}
        isUpdate={props.isUpdate}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  users: state.users.Users,
  addUserSuccess: state.users.AddUserSuccess,
  userMessage: state.users.UserMessage,
  usersLoading: state.users.UsersLoading,
  addUserModal: state.modals.addUserModal,
  showActionBar: state.mobileView.showManagementActionBar,
  editForm: state.forms.editForm,
  generateCSVData: state.users.GeneratingCSVData,
  generateCSVLoading: state.users.GenerateCSVLoading,
  inspectionPlanDetail: state.config.inspectionPlan,
  isSuperAdmin: state.user.isSuperAdmin,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  userList: (users) => dispatch(userList(users)),
  defaultUserOverview: () => dispatch(defaultUserOverview()),
  getUsers: (isPendingUserList) => dispatch(getUsers(isPendingUserList)),
  resetUsersState: () => dispatch(resetUsersState()),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setQrcodeFilterBy: (data) => dispatch(setQrcodeFilterBy(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  getUserExportData: (siteId) => dispatch(getUserExportData(siteId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserActionBar));
