import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(30deg, #0d5fbe 0%, #0d5fbe 100%);
  min-height: 38px;
  height: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px !important;
  text-align: left;
  @media (max-width: 768px) {
    height: auto;
    font-size: 15px !important;
    padding: 10px;
  }
`;

export const FilterWrapper = styled.div`
  padding: 16px 24px 24px;
  position: relative;
  z-index: 2;
`;
