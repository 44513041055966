import styled from "styled-components";

export const UploadMessage = styled.div`
  text-align: center;
  padding: 0px 30px 30px 30px;
`;

export const UploadTitle = styled.div`
  font-family: "Kanit";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 28px !important;
  margin-bottom: 20px;
`;

export const IconDiv = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;

  i {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-block;
    font-size: 30px;
    line-height: 55px;
  }

  i.icon-cancel {
    border: 1px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }

  i.icon-select {
    border: 1px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }

  i.icon-exclamation-mark {
    border: 1px solid ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
    color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  }
`;

export const TextUploadMessage = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-bottom: 10px;
`;

export const TextUserAddedUpload = styled.div`
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  margin: 10px;
  font-size: 18px !important;

  span.redFailed {
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    font-weight: 600;
  }
  span.greenSuccess {
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    font-weight: 600;
  }
`;
export const S3InstructionModal = styled.div`
  float: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  display: inline-block;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  i {
    font-size: 16px;
    line-height: 13px;
  }
  span {
    float: left;
    text-align: left;
    padding-left: 7px;
    margin-top: -1px;
  }
`;

export const DownloadFileMessage = styled.div`
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  margin: 0;
  font-size: 22px !important;
  a.clickHereA {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    text-decoration: none;
    margin: 5px;
  }
  a.clickHereA:hover {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    text-decoration: none;
  }
`;
