import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { getPasswordPolicy, resetPasswordPolicy } from "../../redux/accounts/action";
import {
  PasswordRulesWrapper,
  PassNotMatch,
  IconDanger,
  RulesText,
  LineDIV,
} from "./PasswordErrorMessages.styles";

function PasswordErrorMessages({
  passwordPolicy,
  getPasswordPolicy,
  hideIcon,
  isError,
  isSuperAdmin,
  parentPasswordPolicy,
  passwordPolicyLoading,
  accountId,
  selectedAccountID,
  resetPasswordPolicy,
  userAccountId,
  useDefaultPasswordPolicy,
}) {
  const { t } = useTranslation();
  const componentPasswordPolicy = parentPasswordPolicy || passwordPolicy;
  const {
    upperCaseRequired,
    lowerCaseRequired,
    numberRequired,
    specialCharacterRequired,
    minLength,
    passwordReuse,
    message,
  } = componentPasswordPolicy || {};
  const i18count = passwordReuse?.count;

  useEffect(() => {
    if ((isSuperAdmin || !minLength) && !parentPasswordPolicy && !useDefaultPasswordPolicy) {
      getPasswordPolicy(userAccountId);
    }
    return () => {
      resetPasswordPolicy();
    };
  }, [selectedAccountID, accountId, userAccountId]);

  const userReactivationErrorMessage = t("MIN_X_CHARS", { number: minLength });

  return (
    <>
      {passwordPolicyLoading ? (
        <Skeleton count={6} />
      ) : (
        <>
          {minLength ? (
            <PasswordRulesWrapper>
              <PassNotMatch className="alignTop" isError={isError}>
                {!hideIcon && <IconDanger className="icon icon-alert-icon" isError={isError}></IconDanger>}
                <RulesText>
                  <LineDIV isError={isError}>
                    <Trans>PASS_SATISFY_REQ</Trans>
                  </LineDIV>
                  {minLength && <LineDIV isError={isError}>{userReactivationErrorMessage}</LineDIV>}
                  {upperCaseRequired && (
                    <LineDIV isError={isError}>
                      <Trans>ONE_UPPER_CASE</Trans>
                    </LineDIV>
                  )}
                  {lowerCaseRequired && (
                    <LineDIV isError={isError}>
                      <Trans>ONE_LOWER_CASE</Trans>
                    </LineDIV>
                  )}
                  {numberRequired && (
                    <LineDIV isError={isError}>
                      <Trans>ONE_NUM_CHAR</Trans>
                    </LineDIV>
                  )}
                  {specialCharacterRequired && (
                    <LineDIV isError={isError}>
                      <Trans>ONE_SPECIAL_CHAR</Trans>
                    </LineDIV>
                  )}

                  {passwordReuse?.status && (
                    <LineDIV isError={isError}>
                      <Trans i18nKey="DIFF_PASS_PRE_3">{{ i18count }}</Trans>
                    </LineDIV>
                  )}
                </RulesText>
              </PassNotMatch>
            </PasswordRulesWrapper>
          ) : (
            <>{message}</>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    passwordPolicy: state?.accounts?.passwordPolicy,
    isSuperAdmin: state?.user?.isSuperAdmin,
    passwordPolicyLoading: state?.accounts?.passwordPolicyLoading,
    accountId: state?.user?.loginDetails?.accountId,
    selectedAccountID: state?.dropdownFilters?.accountId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPasswordPolicy: (userAccountId) => dispatch(getPasswordPolicy(userAccountId)),
  resetPasswordPolicy: () => dispatch(resetPasswordPolicy()),
});

PasswordErrorMessages.defaultProps = {
  passwordPolicy: {
    passwordExpiration: {
      status: false,
      duration: 365,
    },
    passwordReuse: {
      status: false,
      count: 3,
    },
    passwordLockoutDashboard: {
      status: true,
      count: 5,
    },
    passwordLockoutAndroid: {
      status: true,
      count: 5,
    },
    minLength: 8,
    upperCaseRequired: true,
    lowerCaseRequired: true,
    numberRequired: true,
    specialCharacterRequired: true,
    changeOwnPasswords: true,
    changeOthersPasswords: true,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordErrorMessages);
