import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { WrapperDiv, UserIconconDiv, MainWraper, SpanCount, SpanHead } from "./UsersCard.component.styles";

const UsersCard = ({ userCount }) => {
  const { t } = useTranslation();
  return (
    <WrapperDiv>
      <MainWraper>
        <SpanHead> {t("Users")}</SpanHead>
        <UserIconconDiv className="icon icon-users">
          <SpanCount>{userCount || 0}</SpanCount>
        </UserIconconDiv>
      </MainWraper>
    </WrapperDiv>
  );
};

const mapStateToProps = (state) => ({
  userCount: state.users.totalCount,
});

export default connect(mapStateToProps, null)(UsersCard);
