import { useState, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import WorksiteOverviewForm from "../../../Worksite/WorksiteOverviewForm.component";
import { ThemeContext } from "styled-components";
import styled from "styled-components";
import CustomStepper from "../../../../../components/StepperComponent/Stepper.Component";

const AddWorsiteStepper = (props) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    { iconClass: "add-worksite", heading: t("Worksite Overview") },
    { iconClass: "worksites", heading: t("Worksite Shifts") },
  ];
  const themeContext = useContext(ThemeContext);
  const [shiftDuration, setShiftDuration] = useState({
    isEnabled: false,
    duration: 8,
    timeUnit: "hours",
  });

  const deleteShiftDuration = () => {
    setShiftDuration({ ...shiftDuration, isEnabled: false });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step, props, validated) => {
    const { deleteShiftDuration } = props;
    return (
      <WorksiteOverviewForm
        {...props}
        step={step}
        toogleModalClose={props.toogleModalClose}
        setIsSuccess={props.setIsSuccess}
        setIsError={props.setIsError}
        deleteShiftDuration={deleteShiftDuration}
        shiftDuration={shiftDuration}
        setShiftDuration={setShiftDuration}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    );
  };

  return (
    <>
      <CustomStepper
        allSteps={steps}
        activeStep={activeStep}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      {getStepContent(activeStep, { deleteShiftDuration }, true)}
    </>
  );
};

export default AddWorsiteStepper;
