import styled from "styled-components";
export const EditProfileContainer = styled.div``;

export const ImageAvatar = styled.div`
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center;
  background-size: 100%;
  border: 0.8px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background-repeat: no-repeat;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const ChangeImage = styled.div`
  font-style: 0.6em !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  position: relative;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  svg {
    width: 0.8em;
    height: 0.8em;
  }
  span {
    font-weight: 400;
    font-size: 14px !important;
    display: block;
  }
  input[type="file"] {
    visibility: hidden;
  }
`;

export const TopImageAccountDiv = styled.div`
  display: block;
  overflow: hidden;
`;
export const NameContainerDiv = styled.div`
  display: block;
  overflow: hidden;
  float: left;
  margin: 25px 10px;
`;

export const AccountProfileDetail = styled.div`
  width: 100%;
  font-size: 20px !important;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  position: relative;
  top: -10px;
  text-transform: capitalize;
`;

export const UserNameDiv = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 40px !important;
  text-align: left;

  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  position: relative;

  .form-group {
    float: left;
  }
  label.form-label {
    display: none;
  }
  .input-group {
    input {
      border: none;
      padding: 0;
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
      font-weight: 600;
      font-size: 32px !important;
      text-align: left;
      background-color: transparent !important;
    }
  }
`;

export const InputProfileDiv = styled.div`
  clear: both;
  margin-top: 10px;
  float: left;
  margin-bottom: 15px;
  width: 100%;
`;

export const InputProfileDivCont = styled.div`
  clear: both;
  margin: 5px auto;
  overflow: hidden;
`;

export const PhoneErrorDiv = styled.div`
  clear: both;
  margin: 5px auto;
  overflow: hidden;
`;

export const ButtonContainerStyle = styled.div`
  display: flex;
  padding: 15px;
`;
export const ButtonContainerDiv = styled.div`
  flex: 1;
`;

export const MFAWrapper = styled.div`
  align-items: left;
  padding: 10px 10px 0 0;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const CameraIcon = styled.div`
  font-size: 18px !important;
`;
