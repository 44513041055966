import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { ReactComponent as AddIconSVG } from "../../../../assets/add-icon-blue.svg";
import { ReactComponent as DeleteIconSVG } from "../../../../assets/delete-icon-blue.svg";
import { toValidateUserName } from "../../../../utils/validations/checkValidation";
import {
  WrapperOperatorForklift,
  Header,
  BodyWrapperOperator,
  DeleteCertificateButton,
  AddCertificateButton,
  ButtonWrapperOpCert,
  NoValidMessage,
} from "./OperatorVerificationForm.styles";
import "./OperatorVerificationStepper.css";
import { Trans } from "react-i18next";
import { useEffect } from "react";
import { vehicleClassesByValue } from "./constants";
import CertificateFormFields from "./CertificateFormFields.component";

const OperatorVerificationForm = (props) => {
  const { formData = { certificates: { certifiedVehicleClasses: [] } }, setFormData, handleNext } = props;
  const { issueDate, expiryDate, certifiedVehicleClasses, certificateNumber, certifyingOrganization } =
    formData?.certificates || {};

  const [isCertificate, setIsCertificate] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (issueDate && expiryDate && certifiedVehicleClasses && certifiedVehicleClasses.length > 0) {
      setIsCertificate(true);
    }
  }, [issueDate, expiryDate, certifiedVehicleClasses]);

  const addClickHandler = () => {
    setIsCertificate(true);
  };

  const deleteClickHandler = () => {
    setIsCertificate(false);
    setFormData({
      ...formData,
      certificates: {},
    });
  };

  const stringCertifiedVehicleClasses = JSON.stringify(certifiedVehicleClasses);
  useEffect(() => {
    const selectedOpt = [];
    if (certifiedVehicleClasses?.length > 0) {
      certifiedVehicleClasses.forEach((vClass) => {
        selectedOpt.push(vehicleClassesByValue[vClass]);
      });
    }
    setSelectedOptions(selectedOpt);
  }, [stringCertifiedVehicleClasses]);

  const vehicleClassesChangeHandler = (valueArr) => {
    setFormData({
      ...formData,
      certificates: {
        ...formData.certificates,
        certifiedVehicleClasses: valueArr,
      },
    });
  };

  const changeHandler = (field, e) => {
    if (field === "issueDate" || field === "expiryDate") {
      setFormData({
        ...formData,
        certificates: {
          ...formData.certificates,
          [field]: e,
        },
      });
    } else {
      e.preventDefault();
      setFormData({
        ...formData,
        certificates: {
          ...formData.certificates,
          [field]: e.target.value,
        },
      });
    }
    setErrors({ [field]: false });
  };

  const validateForm = (field, value) => {
    const { issueDate, expiryDate, certifiedVehicleClasses, certificateNumber, certifyingOrganization } =
      formData?.certificates || {};
    const errors = {};
    if (!issueDate && field !== "issueDate") {
      errors.issueDate = true;
    }
    if (!expiryDate && field !== "expiryDate") {
      errors.expiryDate = true;
    }

    if (certificateNumber) {
      if (toValidateUserName(certificateNumber)) errors.certificateNumber = true;
    }

    if (certifyingOrganization) {
      if (toValidateUserName(certifyingOrganization)) errors.certifyingOrganization = true;
    }

    if (!certifiedVehicleClasses || certifiedVehicleClasses.length === 0) {
      errors.certifiedVehicleClasses = true;
    }
    setErrors(errors);
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isCertificate) {
      const errors = validateForm();
      if (Object.keys(errors).length !== 0) {
        return;
      }
    }
    handleNext(event);
  };

  return (
    <>
      <WrapperOperatorForklift>
        <Header>Operator Verification</Header>
        <Form id="userAddForm1" onSubmit={handleSubmit}>
          <>
            {isCertificate ? (
              <CertificateFormFields
                issueDate={issueDate}
                changeHandler={changeHandler}
                errors={errors}
                expiryDate={expiryDate}
                certificateNumber={certificateNumber}
                vehicleClassesChangeHandler={vehicleClassesChangeHandler}
                certifyingOrganization={certifyingOrganization}
                selectedOptions={selectedOptions}
              />
            ) : (
              <>
                <NoValidMessage>
                  <Trans>No valid certificate message</Trans>
                </NoValidMessage>
              </>
            )}
          </>
        </Form>
      </WrapperOperatorForklift>
      <ButtonWrapperOpCert>
        <AddCertificateButton onClick={addClickHandler} disabled={isCertificate}>
          <AddIconSVG />
          <Trans>Add Certificate</Trans>
        </AddCertificateButton>
        {isCertificate && (
          <DeleteCertificateButton onClick={deleteClickHandler}>
            <DeleteIconSVG />
            <Trans>Delete Certificate</Trans>
          </DeleteCertificateButton>
        )}
      </ButtonWrapperOpCert>
    </>
  );
};

export default OperatorVerificationForm;
