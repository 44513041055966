import { assetTypeManagementTypes } from "./assetType.types";
import { apiCall } from "../../utils/apiCall";
import { CacheRefs } from "../../utils/cache/CacheRefs";
// Selecting all assetType
export const assetTypeList = (assetType) => ({
  type: assetTypeManagementTypes.LIST_ASSET_TYPE,
  payload: assetType,
});

// Selected asset type overview
export const assetTypeOverview = (assetTypeId) => ({
  type: assetTypeManagementTypes.ASSET_TYPE_OVERVIEW,
  payload: assetTypeId,
});

// Selected default asset type overview from the asset type list
export const defaultAssetTypeOverview = () => ({
  type: assetTypeManagementTypes.DEFAULT_ASSET_TYPE_OVERVIEW,
});

export const getAllassetTypesWithCountByAccountIdSuccess = (data) => ({
  type: assetTypeManagementTypes.GET_ASSET_TYPE_WITH_COUNT_BY_ACCOUNT_SUCCESS,
  payload: data,
});

export const getAllassetTypesWithCountByAccountId = (userAccountId, accountType) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = userAccountId
      ? userAccountId
      : state.user.isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!accountId) {
      return;
    }
    const url = `/assettype/getallassettypeswithcount/${accountId}`;

    apiCall(url)
      .then((resp) =>
        dispatch(
          getAllassetTypesWithCountByAccountIdSuccess({
            data: resp?.data?.data,
            isNewAccount: accountType === "newAccount",
          })
        )
      )
      .catch((err) => {
        console.log(err);
      });
  };
};

const createAssetTypeSuccess = (data) => ({
  type: assetTypeManagementTypes.CREATE_ASSET_TYPE_SUCCESS,
  payload: data,
});

const createAssetTypeFailure = () => ({
  type: assetTypeManagementTypes.CREATE_ASSET_TYPE_FAILURE,
});

export const resetCreateAssetTypeFlags = () => ({
  type: assetTypeManagementTypes.RESET_CREATE_ASSET_TYPE_FLAGS,
});

export const createAssetType = (accountId, data) => {
  return async (dispatch, getState) => {
    try {
      const response = await apiCall(`/assettype/create/${accountId}`, {
        method: "POST",
        data,
      });
      if (response.data.success === true) {
        await CacheRefs.removeCacheForAssetTypes();
        dispatch(createAssetTypeSuccess(response.data));
      } else {
        dispatch(createAssetTypeFailure());
      }
    } catch (err) {
      console.log(err);
    }
  };
};
