import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { setErrorCode } from "../../redux/errors/action";
import ErrorPage from "../../assets/Error_500_Image.png";
import "./ErrorStyle.css";

function Error500({ errorCode, setErrorCode }) {
  const history = useHistory();

  const handleGoBackButton = () => {
    setErrorCode("");
  };

  useEffect(() => {
    if (Number(errorCode) !== 500) {
      history.push("/app/dashboard");
    }
  }, []);

  return (
    <Row>
      <Col md={4}>
        <div className="error-page">
          <div className="error-style">
            <h3>
              <Trans>Error 500</Trans>
            </h3>
            <h5>
              <Trans>SORRY, UNEXPECTED ERROR</Trans>
            </h5>
            <p>
              <Trans>We are working on fixing the problem.</Trans>
            </p>
            <p>
              <Trans>Be back soon.</Trans>
            </p>
            <br />
            <NavLink onClick={() => handleGoBackButton()} to="/app/dashboard">
              <button className="button-ErrorStyle ripple">
                <Trans>TRY AGAIN</Trans>
              </button>
            </NavLink>
          </div>
        </div>
      </Col>
      <Col md={8}>
        <div className="error-style">
          <img src={ErrorPage} alt="Unexpected Error" />
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  errorCode: state.errors.errorCode,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorCode: (data) => dispatch(setErrorCode(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error500));
