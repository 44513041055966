import { CacheManager } from "./CacheManager";
import { CacheTypes } from "./CacheTypes";

export class CacheRefs {
  static autoPurgeKey() {
    return "AUTO_PURGE";
  }
  static accountsDDList() {
    return `/dropdown/accounts`;
  }
  static accountById() {
    return `/dropdown/accounts/id`;
  }
  static getTtlShort() {
    return 1000 * 60 * 2;
  }
  static getTtlLong() {
    return 1000 * 60 * 5;
  }
  static getTtlTenMins() {
    return 1000 * 60 * 10;
  }

  static getAllKeyPartsForAcc() {
    return ["/accounts", "/account"];
  }
  static getAllKeyPartsForSites() {
    return ["/sites", "/site"];
  }
  static getAllKeyPartsForAssets() {
    return ["/assets", "/asset"];
  }

  static getAllKeyPartsForAssetTypes() {
    return ["/assetTypes", "/assetTypes"];
  }
  static getAllKeyPartsForCheckLists() {
    return ["/checklist", "/checklists", "/inspection"];
  }
  static getAllKeyPartsForUsers() {
    return ["/users", "/user"];
  }

  static getAllKeyPartsForImpcats() {
    return ["/impact", "/impacts"];
  }

  static getAllKeyPartsForUnauthorized() {
    return ["/unauthorized", "/unauthorized"];
  }

  static getAllKeyPartsForUserCharts() {
    return ["/userCharts", "/daChart"];
  }

  static async purgeCache(cacheTypeArg = null) {
    try {
      const cache = CacheManager.getInstance();
      if (cache) {
        await cache.purge(cacheTypeArg);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async removeCacheFor(arrOfkeyPartsToRemove) {
    try {
      const cache = CacheManager.getInstance();
      if (cache) {
        await cache.removeWhereKeyIncludes(arrOfkeyPartsToRemove);

        await cache.removeWhereKeyIncludes(arrOfkeyPartsToRemove, CacheTypes.FBC);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async removeCacheForAccounts() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForAcc());
  }

  static async removeCacheForSites() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForSites());
  }

  static async removeCacheForAssets() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForAssets());
  }

  static async removeCacheForAssetTypes() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForAssetTypes());
  }

  static async removeCacheForChecklists() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForCheckLists());
  }

  static async removeCacheForUsers() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForUsers());
  }

  static async removeCacheForImpacts() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForImpcats());
  }

  static async removeCacheForUnauthorized() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForUnauthorized());
  }

  static async removeCacheForUserCharts() {
    await CacheRefs.removeCacheFor(CacheRefs.getAllKeyPartsForUserCharts());
  }
}
