import React from "react";
import { Row, Col } from "react-bootstrap";
import Input from "../../../../../../components/Input";
import { Trans, useTranslation } from "react-i18next";
import DropdownComponent from "../../../../../../components/DropdownComponent/DropdownFilterWrapper";

import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
`;

const StyledWrapper = styled.div`
  margin-right: 8px;
  padding-left: 0;
  width: 30%;
`;

const UserTypeSelection = ({
  isSuperAdmin,
  selectedAccessLevel,
  handleAccessLevelChange,
  dropdownAccountList,
  dropdownAccountId,
  handleAccountChange,
}) => {
  const { t } = useTranslation();
  return (
    <StyledRow>
      <StyledWrapper>
        <DropdownComponent
          options={[
            {
              _id: "ALL_ACCOUNTS",
              label: "All Accounts",
            },
            {
              _id: "SINGLE_ACCOUNT",
              label: "Single Account",
            },
          ]}
          isValidationError={false}
          label={t("User Type Access Level")}
          value={selectedAccessLevel}
          onChange={(value) => handleAccessLevelChange(value)}
        />
      </StyledWrapper>

      <StyledWrapper>
        <DropdownComponent
          options={dropdownAccountList?.map((data) => ({
            _id: data._id,
            label: data.companyName,
          }))}
          isValidationError={false}
          label={t("Account Name")}
          value={dropdownAccountId}
          onChange={(accId) => handleAccountChange(accId)}
          disabled={isSuperAdmin && selectedAccessLevel !== "SINGLE_ACCOUNT"}
        />
      </StyledWrapper>
    </StyledRow>
  );
};

export default UserTypeSelection;
