import React, { useEffect } from "react";
import List from "../../../components/List";
import { connect } from "react-redux";
import { getUsers, getUserDetailById } from "../../../redux/users/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import { getInspectionCount } from "../../../redux/counts/action";
import styled from "styled-components";
const ListingPage = styled.div`
  .tableHeight {
    height: calc(100vh - 339px);
  }
`;

const listType = require("../../../constant/listType");

function UserList({
  userDetails,
  userOverview,
  pendingList,
  searchText,
  userTypeFilter,
  filterAccountId,
  getUsers,
  totalCount,
  getInspectionCount,
  setImageLoaded,
  UsersLoading,
  getUserDetailById,
  editForm,
  setEditAlertModal,
  setShowUserDetail,
}) {
  const { t } = useTranslation();
  const listDetail = {
    label: pendingList ? t("Pending Users Requests") : t("Your Users"),
    emptyListText: t("NO_USERS_FOUND"),
    listType: listType.USERS,
  };

  useEffect(() => {
    getInspectionCount({ inspectorId: userOverview?._id });
  }, [userOverview]);

  return (
    <ListingPage className="assetPageList assetPageData assettypename">
      <List
        hideHeader={true}
        listDetail={listDetail}
        listData={userDetails}
        selectItem={(id) => {
          if (editForm) {
            getUserDetailById(id);
            setImageLoaded(false);
          } else {
            setEditAlertModal(true);
          }
          setShowUserDetail();
        }}
        selectedId={userOverview && userOverview._id}
        isFilterChange={filterAccountId + userTypeFilter + searchText}
        loadData={getUsers}
        totalCount={totalCount}
        searchKey={searchText.length > 3 ? searchText : ""}
        dataLoading={UsersLoading}
        showActiveInactiveTabs={true}
      />
    </ListingPage>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  userDetails: state.users.Users,
  userOverview: state.users.UserOverview,
  UsersSuccess: state.users.UsersSuccess,
  totalCount: state.users.totalCount,
  UsersLoading: state.users.UsersLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetailById: (id) => dispatch(getUserDetailById(id)),
  getUsers: (paginationData, isResetState) => dispatch(getUsers(paginationData, isResetState, true)),
  getInspectionCount: (data) => dispatch(getInspectionCount(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
