//TODO definitely cleanup this file when time permits. Remove unused/commented code and optimize rest

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as IconSuccess } from "../../../assets/AddIcon/IconRight.svg";
import UserOverviewForm from "./UserOverviewForm.component";
import UserDetailsForm from "./UserDetailsForm.component";
import AssetPermissionForm from "./assetPermission/AssetPermissionForm.component";
import OperatorVerificationForm from "./operatorVerification/OperatorVerificationForm.component";

import alertConstant from "../../../constant/messages/toaster.json";
import { assetAdded } from "../../../redux/assets/action";
import Button from "../../../components/Button/Button.component";

import { addAsset } from "../../../redux/asset/asset.action";

import { Trans, useTranslation } from "react-i18next";
import useAlert from "../../../utils/useAlert";
import {
  getDropdownAccountList,
  getDropdownAssetTypeList,
  getDropdownWorksiteList,
} from "../../../redux/dropdownFilters/action";
import { postUser, resetAddUserState, updateUser, getUserDetailById } from "../../../redux/users/action";
import { setAddUserModal, setModalWaitingToOpen } from "../../../redux/modals/action";
import UserQRCode from "./UserQRCode.component";
import SnackBar from "../SnackBar";
import { StepperWrapperStyle } from "./AddUserStepper.component.styles";
import CustomStepper from "../../../components/StepperComponent/Stepper.Component";

const qrType = require("../../../constant/qrType");

function getStepContent(
  step,
  props,
  validated,
  setIsPinValidating,
  isPinValidating,
  setLastValidatedPin,
  lastValidatedPin,
  selectedUserType,
  setSelectedUserType,
  isUpdate
) {
  const { formData, setFormData, handleNext, PreviewImage, setPreviewImage, isSubmitClicked } = props;

  switch (step) {
    case 0:
      return (
        <UserOverviewForm
          isSubmitClicked={isSubmitClicked}
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
          selectedUserType={selectedUserType}
          setSelectedUserType={setSelectedUserType}
          isUpdate={isUpdate}
        />
      );
    case 1:
      return (
        <UserDetailsForm
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
          PreviewImage={PreviewImage}
          setPreviewImage={setPreviewImage}
          setIsPinValidating={setIsPinValidating}
          isPinValidating={isPinValidating}
          setLastValidatedPin={setLastValidatedPin}
          lastValidatedPin={lastValidatedPin}
          selectedUserType={selectedUserType}
          isUpdate={isUpdate}
        />
      );
    case 2:
      return (
        <OperatorVerificationForm
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
          PreviewImage={PreviewImage}
          setPreviewImage={setPreviewImage}
          isSubmitClicked={isSubmitClicked}
        />
      );

    case 3:
      return (
        <AssetPermissionForm
          validated={validated}
          setFormData={setFormData}
          formData={formData}
          handleNext={handleNext}
          PreviewImage={PreviewImage}
          setPreviewImage={setPreviewImage}
          isSubmitClicked={isSubmitClicked}
        />
      );
    default:
      return;
  }
}

function AddUserStepper(props) {
  const {
    loginDetails,
    toogleModalClose,
    isSuperAdmin,
    getDropdownAccountList,
    getDropdownAssetTypeList,
    getDropdownWorksiteList,
    resetAddUserState,
    postUser,
    updateUser,
    addUserError,
    isSiteAdmin,
    setModalWaitingToOpen,
    setAddUserModal,
    updateUserDetails,
    getUserDetailById,
    isUpdate,
    userErrorMessage,
  } = props;

  const [activeStep, setActiveStep] = React.useState(0);

  const OPERATOR_STEPS = ["User Overview", "User Details", "Operator Verification", "Asset Permissions"];
  const NON_OPERATOR_STEPS = ["User Overview", "User Details", "Operator Verification"];

  const [steps, setSteps] = useState(NON_OPERATOR_STEPS);

  const OPERATOR_ICONS = {
    "User Overview": "user-overview",
    "User Details": "user-details",
    "Operator Verification": "forklift-certification",
    "Asset Permissions": "asset-permission",
  };

  const NON_OPERATOR_ICONS = {
    "User Overview": "user-overview",
    "User Details": "user-details",
    "Operator Verification": "forklift-certification",
  };

  const [icons, setIcons] = useState(NON_OPERATOR_ICONS);

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [PreviewImage, setPreviewImage] = useState(null);
  const [userOverviewDetail, setUserOverviewDetail] = useState();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isPinValidating, setIsPinValidating] = useState(false);
  const [lastValidatedPin, setLastValidatedPin] = useState("");

  const [selectedUserType, setSelectedUserType] = useState();

  useEffect(() => {
    if (formData.accessLevel === "INDIVIDUAL_EMPLOYEE") {
      setSteps(OPERATOR_STEPS);
      setIcons(OPERATOR_ICONS);
    } else {
      setSteps(NON_OPERATOR_STEPS);
      setIcons(NON_OPERATOR_ICONS);
    }
  }, [formData.accessLevel]);

  useEffect(() => {
    if (updateUserDetails) {
      setFormData(updateUserDetails);
    }
  }, [updateUserDetails]);

  const { showAlert } = useAlert();

  const handleNext = (event) => {
    resetAddUserState();
    if (activeStep === steps.length - 1) {
      handleSubmit(event);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const { t } = useTranslation();

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setModalWaitingToOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setModalWaitingToOpen(false);
    //let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/;
    setValidated(false);
    createUserAPICalling();
  };

  const createUserAPICalling = async () => {
    if (isLoading) {
      return;
    }
    if (isSiteAdmin) {
      formData.authorizedSites = loginDetails?.userData?.authorizedSites[0];
      formData.siteId = loginDetails?.userData?.authorizedSites[0];
    }
    if (!formData.siteId) {
      formData.siteId = formData?.authorizedSites;
    }
    formData.accountNo = loginDetails.accountNo;
    if (!isSuperAdmin) {
      formData.accountId = loginDetails.accountId;
    }
    if (updateUserDetails) {
      formData.userId = updateUserDetails._id;
    }

    setIsLoading(true);
    let bodyFormData = new FormData();
    PreviewImage?.logo && bodyFormData.append("profileImg", PreviewImage?.logo);
    const {
      accountId,
      authorizedSites,
      email,
      fullName,
      phone,
      pin,
      siteId,
      userId,
      password,
      _id,
      userTypeId,
      unauthorizedAssetTypes,
    } = formData;
    if (updateUserDetails) {
      let data = {
        accountId,
        authorizedSites,
        email,
        fullName,
        phone,
        pin,
        siteId,
        userId,
        _id,
        password,
        userTypeId,
        unauthorizedAssetTypes,
      };
      if (formData?.permissions === "operator") {
        data.password = password;
      }
      bodyFormData.append("userData", JSON.stringify(data));
    } else {
      bodyFormData.append("userData", JSON.stringify(formData));
    }
    if (updateUserDetails) {
      updateUser(bodyFormData, formData, () => {
        getUserDetailById(formData._id);
        setAddUserModal(false);
        setModalWaitingToOpen(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        showAlert("success", alertConstant.user_updated_successful, 1500);
      });
    } else {
      postUser(bodyFormData, (resp) => {
        setAddUserModal(false);
        setModalWaitingToOpen(false);
        setUserOverviewDetail(resp);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    }
  };

  useEffect(() => {
    getDropdownAccountList();
    getDropdownAssetTypeList();
    getDropdownWorksiteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addUserError) {
      setIsLoading(false);
      setToasterStatus(true);
    }
  }, [addUserError]);
  const [toasterStatus, setToasterStatus] = useState(false);

  const handleCloseSnackBar = () => {
    resetAddUserState();
    setToasterStatus(false);
  };

  return (
    <>
      <CustomStepper
        allSteps={steps.map((label, index) => ({
          iconClass: icons[label],
          heading: t(label),
        }))}
        activeStep={activeStep}
        onNext={handleNext}
        onPrevious={handleBack}
      />

      <div className="stepperStyle">
        {activeStep === steps.length ? (
          <div className="text-center">
            <h3 className="text-center">
              <Trans>User Added</Trans>
            </h3>
            <p className="rightIcon">
              <IconSuccess />
            </p>
            <UserQRCode qrFor={qrType.USERS} qrDetail={userOverviewDetail} />
            <br></br>

            <Button
              label={t("Skip")}
              onClick={() => toogleModalClose()}
              showIcon={false}
              buttonType="ghost"
            />
          </div>
        ) : (
          <StepperWrapperStyle>
            {/* <Stepper alternativeLabel={true} activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label, i) => (
                <Step key={label}>
                  <StepLabel className="muistepper" StepIconComponent={ColorlibStepIcon}>
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper> */}
            <div className="button-dissabled-asset">
              <>
                {getStepContent(
                  activeStep,
                  {
                    formData,
                    setFormData,
                    handleNext,
                    PreviewImage,
                    setPreviewImage,
                    isSubmitClicked,
                  },
                  validated,
                  setIsPinValidating,
                  isPinValidating,
                  setLastValidatedPin,
                  lastValidatedPin,
                  selectedUserType,
                  setSelectedUserType,
                  isUpdate
                )}
              </>
              <div className="container text-right">
                {activeStep !== 0 && (
                  <Button
                    label={t("Back")}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    showIcon
                    iconClass="arrow-left"
                    buttonType="ghost"
                  />
                )}
                <Button
                  className="float-right mb-4"
                  label={
                    <>
                      {activeStep === steps.length - 1 ? (
                        <>
                          {isLoading || isPinValidating ? (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          ) : (
                            t("Submit")
                          )}
                        </>
                      ) : (
                        <>
                          {isPinValidating ? (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                          ) : (
                            t("Next")
                          )}
                        </>
                      )}
                    </>
                  }
                  onClick={() => {
                    if (!isPinValidating && !isLoading) {
                      setIsSubmitClicked(true);
                    }
                  }}
                  iconClass={activeStep === steps.length - 1 ? "save" : "arrow-right"}
                  showIcon
                  iconPosition={activeStep === steps.length - 1 ? "before" : "after"}
                  buttonType={activeStep === steps.length - 1 ? "save" : "primary"}
                  form="userAddForm1"
                  value="submit"
                />
              </div>
            </div>
          </StepperWrapperStyle>
        )}
      </div>

      <SnackBar
        isFailed={toasterStatus}
        label={userErrorMessage || "Error occurred while processing. Please try later."}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetTypeList: state.assets.AssetTypes,
  assetImageLoading: state.asset.AssetImageLoading,
  assetImage: state.assets.AssetImage,
  workSiteList: state.worksites.Worksites,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  addUserError: state.users.AddUserError,
  isUpdate: state.modals.isUpdate,
  userErrorMessage: state.users.UserMessage,
});

const mapDispatchToProps = (dispatch) => ({
  addAsset: (assetDetail) => dispatch(addAsset(assetDetail)),
  assetAdded: (data) => dispatch(assetAdded(data)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  postUser: (data, cb) => dispatch(postUser(data, cb)),
  updateUser: (data, formData, cb) => dispatch(updateUser(data, formData, cb)),
  resetAddUserState: () => dispatch(resetAddUserState()),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  getUserDetailById: (id) => dispatch(getUserDetailById(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserStepper));
