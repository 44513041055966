import { useState } from "react";
import {
  NoDataWrapper,
  NoDataHeader,
  Details,
  StepWrapper,
  Step,
  ActionButton,
} from "./NoDataComponent.styles";
import { Trans, useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

const NoDataComponent = ({ noDataObj, noS3SettingDataAction, actionButtonTransKey, onLoadShowModal }) => {
  const { header, summary, steps } = noDataObj;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(onLoadShowModal);
  const handleClose = () => {
    setShowModal(false);
  };
  const NoDataBody = () => (
    <>
      <Details>
        <Trans>{summary}</Trans>
      </Details>

      <StepWrapper>
        {steps.map((step) =>
          step.isHtml ? (
            <Step>
              <div dangerouslySetInnerHTML={{ __html: t(step.text) }} />
            </Step>
          ) : (
            <Step>
              <Trans>{step.text}</Trans>
            </Step>
          )
        )}
      </StepWrapper>
    </>
  );

  return (
    <>
      <NoDataWrapper>
        <NoDataHeader>
          <Trans>{header}</Trans>
        </NoDataHeader>
        <NoDataBody />
        {noS3SettingDataAction && (
          <ActionButton onClick={noS3SettingDataAction}>
            <Trans>{actionButtonTransKey}</Trans>
          </ActionButton>
        )}
      </NoDataWrapper>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>{header}</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NoDataBody />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NoDataComponent;
