export const billingTypes = {
  //For Billing Plans
  GET_ALL_PLANS_LOADING: "GET_ALL_PLANS_LOADING",
  GET_ALL_PLANS_SUCCESS: "GET_ALL_PLANS_SUCCESS",
  GET_ALL_PLANS_ERROR: "GET_ALL_PLANS_ERROR",

  //For Create customer
  POST_CREATE_CUSTOMER_LOADING: "POST_CREATE_CUSTOMER_LOADING",
  POST_CREATE_CUSTOMER_SUCCESS: "POST_CREATE_CUSTOMER_SUCCESS",
  POST_CREATE_CUSTOMER_ERROR: "POST_CREATE_CUSTOMER_ERROR",

  //For getting invoices
  GET_INVOICES_LOADING: "GET_INVOICES_LOADING",
  GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
  GET_INVOICES_ERROR: "GET_INVOICES_ERROR",

  //To update to basic plan
  UPDATE_BASIC_PLAN_LOADING: "UPDATE_BASIC_PLAN_LOADING",
  UPDATE_BASIC_PLAN_ERROR: "UPDATE_BASIC_PLAN_ERROR",
  UPDATE_BASIC_PLAN_SUCCESS: "UPDATE_BASIC_PLAN_SUCCESS",

  //For storing selected plan
  STORE_SELECTED_PLAN: "STORE_SELECTED_PLAN",

  // For billing info
  GET_BILLING_INFO: "GET_BILLING_INFO",

  //For Reseting state
  RESET_STATE: "RESET_STATE",
};
