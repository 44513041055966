import React from "react";
import PropTypes from "prop-types";
import {
  SteperStyledAddAsset,
  StepperContainer,
  StepperMainDiv,
  StepperIcon,
  StepperIconStep,
  StepperStepDiv,
  StepperSmall,
  StepperName,
  StepSeparator,
} from "./Stepper.Component.styles";

const CustomStepper = ({ allSteps, activeStep, onNext, onPrevious, ...rest }) => {
  return (
    <SteperStyledAddAsset>
      <StepperMainDiv>
        {allSteps?.map?.((currentStep, index) => (
          <React.Fragment key={index}>
            <StepperContainer>
              <StepperIcon>
                <StepperIconStep
                  className={`stepperIconColor ${
                    index === activeStep
                      ? "stepIconActive"
                      : index < activeStep
                      ? "stepIconComplete"
                      : "stepIconInactive"
                  }`}
                >
                  {/* Replace with your own icon component or image */}
                  <i className={`icon icon-${currentStep?.iconClass}`} />
                </StepperIconStep>
              </StepperIcon>
              <StepperStepDiv>
                <StepperSmall
                  className={`stepHeading ${
                    index === activeStep
                      ? "stepHeadingActive"
                      : index < activeStep
                      ? "stepHeadingComplete"
                      : "stepHeadingInactive"
                  }`}
                >
                  Step {index + 1}
                </StepperSmall>

                <StepperName
                  className={`stepHeading ${
                    index === activeStep
                      ? "stepHeadingActive"
                      : index < activeStep
                      ? "stepHeadingComplete"
                      : "stepHeadingInactive"
                  }`}
                >
                  {currentStep?.heading}
                </StepperName>
              </StepperStepDiv>
            </StepperContainer>
            {index !== allSteps?.length - 1 && (
              <StepSeparator>
                <span
                  className={index < activeStep ? "stepSeparatorComplete" : "stepSeparatorNotComplete"}
                ></span>
              </StepSeparator>
            )}
          </React.Fragment>
        ))}
      </StepperMainDiv>
    </SteperStyledAddAsset>
  );
};

CustomStepper.propTypes = {
  allSteps: PropTypes.arrayOf(
    PropTypes.shape({
      iconClass: PropTypes.string,
      heading: PropTypes.string,
    })
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

CustomStepper.defaultProps = {};

export default CustomStepper;
