import styled from "styled-components";

export const UserTypeEditDiv = styled.div`
  margin: 10px 20px auto;
  @media (max-width: 1100px) {
    margin: 10px 0px auto;
  }
`;

export const ModalEditWrapper = styled.div`
  .modal-header {
    padding: 10px 30px !important;
  }
`;

export const ButtonWrapper = styled.div`
  margin: auto 10px;
  width: 96%;
  text-align: end;
  .buttonWrapper {
    display: flex;
    float: right;
    @media (max-width: 1100px) {
      width: 100%;
      display: block;
      button[title="Back"] {
        float: left;
      }
      button[title="Next"] {
        float: right;
      }
    }
  }
`;
