import { createSelector } from "reselect";

// Selecting the AssetType State from AssetType Management Reducer
const selectAssetType = (state) => state.assetType;

// 1. Select Asset Type List from Enterprise Account
export const selectAssetTypeList = createSelector([selectAssetType], (assetType) => assetType.assetType);

// 1. Selected Asset Type Overview from Enterprise Account
export const selectAssetTypeOverview = createSelector(
  [selectAssetType],
  (assetType) => assetType.assetTypeOverview
);
