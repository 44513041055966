import styled from "styled-components";

export const UserTypeDataDetails = styled.div``;

export const UserTypeDropdownBox = styled.div`
  display: block;
  float: left;
  width: 40%;
  text-align: left;
`;
