import deviceInfoTypes from "./deviceInfo.types";

const INTIAL_STATE = {
  selectedAccountId: null,
  selectedWorksiteId: "all",
  selectedSieraProductId: "all",
  selectedAssetTypeId: "all",
  pageSize: 10,
  pageNo: 1,
  isLoading: false,
};

const deviceInfo = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case deviceInfoTypes.SET_SELECTED_ACCOUNT_ID:
      return {
        ...state,
        selectedAccountId: action.data,
      };

    case deviceInfoTypes.SET_SELECTED_WORKSITE_ID:
      return {
        ...state,
        selectedWorksiteId: action.data,
      };

    case deviceInfoTypes.SET_SELECTED_SIERA_PRODUCT_ID:
      return {
        ...state,
        selectedSieraProductId: action.data,
      };

    case deviceInfoTypes.SET_SELECTED_ASSET_TYPE_ID:
      return {
        ...state,
        selectedAssetTypeId: action.data,
      };

    case deviceInfoTypes.SET_PAGE_NO:
      return {
        ...state,
        pageNo: action.data,
      };

    case deviceInfoTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.data,
      };

    case deviceInfoTypes.SET_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case deviceInfoTypes.RESET_DATA_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case deviceInfoTypes.RESET_DEVICE_DATA:
      return {
        ...state,
        pageNo: 1,
      };
    case deviceInfoTypes.RESET_DEVICE_DATA_TO_INITIAL_STATE:
      return {
        ...INTIAL_STATE,
        pageNo: 1,
        data: [],
        totalCount: 0,
        searchText: null,
      };

    case deviceInfoTypes.GET_DEVICE_INFORMATION_SUCCESS:
      return {
        ...state,
        data: action.data.deviceList,
        totalCount: action.data.totalCount,
      };
    case deviceInfoTypes.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.data,
      };

    case deviceInfoTypes.GET_GPS_DATA_SUCCESS:
      return {
        ...state,
        locationData: action.data,
        isGPSLoading: false,
      };
    case deviceInfoTypes.SET_GPS_DATA_LOADING: {
      return {
        ...state,
        isGPSLoading: true,
      };
    }
    case deviceInfoTypes.RESET_GPS_DATA_LOADING: {
      return {
        ...state,
        isGPSLoading: false,
      };
    }
    case deviceInfoTypes.SET_GPS_LOCATION_DATES: {
      return {
        ...state,
        startDateUTC: action.data.startDateUTC,
        endDateUTC: action.data.endDateUTC,
      };
    }
    default:
      return state;
  }
};

export default deviceInfo;
