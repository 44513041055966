import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { apiCall } from "../../utils/apiCall";
import "./modal.css";
import { ReactComponent as LockIcon } from "../../assets/icons/Locked.svg";
import { ReactComponent as Unlocked } from "../../assets/icons/Unlocked.svg";

// Button Component
import Button from "../../components/Button/Button.component";
import styled from "styled-components";

const ButtonUnLocked = styled.div`
  & button {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    border: 1.3px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    &:hover {
      background: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    }
  }
`;
export default function UnlockModal({ modalShow, handleModalClose, token, data, updateItem }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(data?.isLocked);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsLocked(data?.isLocked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setIsSuccess(false);
  }, [modalShow]);

  const callApi = (bool) => {
    const dat = {
      accountId: "na",
      isLocked: bool,
      siteId: data?.siteId?._id || data?.siteId,
      assetId: data?._id || data?.assetId,
      lockingDate: new Date(),
      lockedBy: data?.lockedBy,
    };
    setIsLoading(true);
    apiCall("/asset/lockAsset", {
      method: "POST",
      data: dat,
    })
      .then(() => {
        setIsLocked(bool);
        setIsSuccess(true);
        setIsLoading(false);
        updateItem({
          ...data,
          isLocked: bool,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onSubmit = () => callApi(!isLocked);
  const onUnlock = () => setIsSuccess(false);

  return (
    <Modal size="md" className="custom-modal " show={modalShow} onHide={handleModalClose}>
      <Modal.Body className="text-center modalPopup">
        {isSuccess ? (
          <>
            <Row className="mb-4 mt-4 ">
              <Col>
                <span className="h5 mb-2 text-open-sans text-center modal-font-size">
                  <strong>
                    <Trans>{t(isLocked ? "assetLocked" : "assetUnLocked")}</Trans>
                  </strong>
                </span>
              </Col>
            </Row>
            <Row className="mb-4 mt-4 ">
              <Col>
                {isLocked ? <LockIcon className="danger-icon" /> : <Unlocked className="success-icon" />}
              </Col>
            </Row>
            <Row className="mb-4 mt-4 ">
              <Col>
                <span className="mb-2 text-open-sans text-center modal-font-color">
                  <Trans>
                    {isLocked ? <strong>{data?.assetName} </strong> : <strong></strong>}
                    {t(isLocked ? "isTurnedOff" : "isTurnedOn")}
                  </Trans>
                </span>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-4 mt-4 ">
            <Col>
              <span className="mb-2 text-open-sans text-center modal-font-color">
                <Trans>{isLocked ? "unlockModalQuestion" : "lockModalQuestion"}</Trans>
              </span>
            </Col>
          </Row>
        )}
        <Row className="justify-content-center mb-2">
          {isSuccess ? (
            <Button
              style={{ width: "166px" }}
              label={t("Continue")}
              onClick={handleModalClose}
              showIcon={false}
              buttonType="primary"
            />
          ) : (
            <Button
              style={{ width: "160px" }}
              label={t("Yes")}
              onClick={onSubmit}
              disabled={isLoading}
              showIcon={false}
              buttonType="primary"
            />
          )}
        </Row>
        <Row className="justify-content-center">
          {isSuccess ? (
            isLocked && (
              <ButtonUnLocked>
                <Button
                  iconClass="unlock"
                  label={t("Unlock Asset")}
                  onClick={onUnlock}
                  disabled={isLoading}
                  showIcon
                  buttonType="secondary"
                />
              </ButtonUnLocked>
            )
          ) : (
            <Button
              style={{ width: "160px" }}
              label={t("Cancel")}
              onClick={handleModalClose}
              showIcon={false}
              buttonType="ghost"
            />
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
