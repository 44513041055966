import React from "react";
import { connect } from "react-redux";
import SuccessMessageModal from "../../../components/successMessage/SuccessMessageModal.component";
import { resetWorksitesSuccessFlag } from "../../../redux/worksites/action";
import { resetAssetSuccessFlag } from "../../../redux/assets/action";
import { resetUsersSuccessFlag } from "../../../redux/users/action";
import {
  setAddAssetModal,
  setAddSiteModal,
  setAddUserModal,
  setAddAssetTypeModal,
  setAddUserTypeModal,
} from "../../../redux/modals/action";
import { resetCreateAssetTypeFlags } from "../../../redux/assetType/assetType.action";
import { resetUserTypeFlags } from "../../../redux/userType/action";

function CommonSuccessMessage(props) {
  const {
    setAddAssetModal,
    setAddAssetTypeModal,
    setAddSiteModal,
    addWorksiteSuccessFlag,
    resetWorksitesSuccessFlag,
    addAssetTypeSuccessFlag,
    addassetSuccessFlag,
    newlyAddedAsset,
    resetAssetSuccessFlag,
    addUserSuccessFlag,
    resetUsersSuccessFlag,
    newlyAddedUser,
    setAddUserModal,
    resetCreateAssetTypeFlags,
    addUserTypeSuccess,
    resetUserTypeFlags,
    setAddUserTypeModal,
  } = props;

  const successMessageAttributes = () => {
    if (addWorksiteSuccessFlag) {
      return {
        show: addWorksiteSuccessFlag,
        propertyName: "Worksite",
        handleCloseModal: resetWorksitesSuccessFlag,
        handleAddAnother: () => {
          resetWorksitesSuccessFlag();
          setAddSiteModal(true);
        },
      };
    } else if (addAssetTypeSuccessFlag) {
      return {
        show: addAssetTypeSuccessFlag,
        propertyName: "Asset Type",
        handleCloseModal: resetCreateAssetTypeFlags,
        handleAddAnother: () => {
          resetCreateAssetTypeFlags();
          setAddAssetTypeModal(true);
        },
      };
    } else if (addassetSuccessFlag) {
      return {
        show: addassetSuccessFlag,
        propertyName: "Asset",
        handleCloseModal: resetAssetSuccessFlag,
        handleAddAnother: () => {
          resetAssetSuccessFlag();
          setAddAssetModal(true);
        },
        showQRCode: true,
        qrCodeDetails: {
          label: newlyAddedAsset && newlyAddedAsset.assetName,
          id: newlyAddedAsset && newlyAddedAsset._id,
        },
      };
    } else if (addUserSuccessFlag) {
      return {
        show: addUserSuccessFlag,
        propertyName: "User",
        handleCloseModal: resetUsersSuccessFlag,
        handleAddAnother: () => {
          resetUsersSuccessFlag();
          setAddUserModal(true);
        },
        showQRCode: true,
        qrCodeDetails: {
          label: newlyAddedUser && newlyAddedUser.fullName,
          id: newlyAddedUser && newlyAddedUser._id,
        },
      };
    } else if (addUserTypeSuccess) {
      return {
        show: addUserTypeSuccess,
        propertyName: "User Type",
        handleCloseModal: resetUserTypeFlags,
        handleAddAnother: () => {
          resetUserTypeFlags();
          setAddUserTypeModal(true);
        },
      };
    }
  };

  return <SuccessMessageModal {...successMessageAttributes()} />;
}

const mapStateToProps = (state) => ({
  addWorksiteSuccessFlag: state.worksites.addWorksiteSuccessFlag,
  addassetSuccessFlag: state.assets.addassetSuccessFlag,
  newlyAddedAsset: state.assets.newlyAddedAsset,
  addUserSuccessFlag: state.users.addUserSuccessFlag,
  newlyAddedUser: state.users.newlyAddedUser,
  addAssetTypeSuccessFlag: state.assetType.addAssetTypeSuccessFlag,
  addUserTypeSuccess: state.userType.addUserTypeSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
  setAddUserTypeModal: (data) => dispatch(setAddUserTypeModal(data)),
  resetWorksitesSuccessFlag: () => dispatch(resetWorksitesSuccessFlag()),
  resetAssetSuccessFlag: () => dispatch(resetAssetSuccessFlag()),
  resetUsersSuccessFlag: () => dispatch(resetUsersSuccessFlag()),
  resetCreateAssetTypeFlags: () => dispatch(resetCreateAssetTypeFlags()),
  resetUserTypeFlags: () => dispatch(resetUserTypeFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonSuccessMessage);
