import { useEffect, useState } from "react";
import { HeaderWrapper, HeaderTitle, FilterWrapper, StyledCol } from "./Header.component.styles";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import DateRangePicker from "../../../../../components/DatePicker.component";
import Button from "../../../../../components/Button/Button.component";
import { getDropdownAccountList } from "../../../../../redux/dropdownFilters/action";
import {
  getDropdownWorksiteList,
  getDropdownAssetList,
  getUsers,
} from "../../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../../utils/apiCall";
import ExportToCSV from "../ExportToCSV.component";
import { setSettingsDropdownAccountId } from "../../../../../redux/dropdownFilters/dropdownActions";

const SettingsHeader = (props) => {
  const {
    title,
    getDropdownAssetList,
    getDropdownWorksiteList,
    isSuperAdmin,
    assetList,
    dropdownAccountList,
    worksiteList,
    getUsers,
    userList,
    setLoginRecords,
    pageSize,
    pageNo,
    downloadReportSet,
    recordSet,
    getWorksiteListLoading,
    getUserListLoading,
    getAssetListLoading,
    getDropdownAccountList,
    currentTimeZone,
    loginDetails,
    selectedAccountId,
    showWorksiteDropdown,
    setIsLoading,
    isAccountOwner,
    isSiteAdmin,
    setSettingsDropdownAccountId,
    isAccountAdmin,
    selectedAccount,
    showMoreFilter,
    setShowMoreFilter,
  } = props;
  const { t } = useTranslation();

  const [filterBy, setFilterBy] = useState({ value: "assets", label: "Assets" });
  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);
  const [selectedWorksite, setSelectedWorksite] = useState({ value: "all", label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: "all", label: "All" });
  const [selectedUser, setSelectedUser] = useState({ value: "all", label: "All" });
  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [userListWithAll, setUserListWithAll] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const getLoginRecords = async () => {
    setIsLoading(true);
    try {
      const resp = await apiCall("/loginRecords/getLoginRecords", {
        method: "POST",
        data: {
          accountId: isSuperAdmin ? selectedAccount?.value : loginDetails?.accountId,
          siteId: selectedWorksite?.value,
          assetTypeId: "all",
          assetId: selectedAsset?.value,
          userId: selectedUser?.value,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          pageNo: pageNo || 1,
          pageSize: pageSize || 10,
        },
      });

      if (resp.data.success) {
        setLoginRecords(resp.data);
      }
    } catch (error) {
      console.error("Error fetching login records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount?.value, selectedWorksite?.value, null, 1, "all", "");
    }
  }, [selectedAccount, getDropdownWorksiteList, getUsers, selectedWorksite?.value]);

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  useEffect(() => {
    getLoginRecords();
  }, [pageNo, pageSize]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  useEffect(() => {
    if (selectedAccount) {
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount?.value, selectedWorksite?.value, null, 1, "all", "");
    }
  }, [selectedAccount, getDropdownWorksiteList, getUsers, selectedWorksite?.value]);

  useEffect(() => {
    if (selectedWorksite?.value) {
      getDropdownAssetList(selectedAccount?.value, selectedWorksite?.value, null);
    }
  }, [selectedWorksite, getDropdownAssetList, selectedAccount?.value]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: "all", label: "All" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: "all", label: "All" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (assetList?.length > 0 && assetList?.[0]?._id !== -1) {
      const optionListTemp = assetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "all", label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: "all", label: "All" }]);
    }
  }, [assetList]);

  useEffect(() => {
    if (userList?.length > 0 && userList?.[0]?._id !== -1) {
      const optionListTemp = userList.map((option) => ({
        label: option.fullName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "all", label: "All" });
      setUserListWithAll(optionListTemp);
    } else {
      setUserListWithAll([{ value: "all", label: "All" }]);
    }
  }, [userList]);

  const applyFilter = () => {
    getLoginRecords();
  };

  const accountId = isSuperAdmin ? selectedAccountId : loginDetails?.accountId;

  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>

      <FilterWrapper>
        <Row>
          {/* Super Admin Dropdown */}
          {/* {isSuperAdmin && (
              <Col lg={2}>
                <DropdownComponent
                  label={t("Account")}
                  placeholder={t("Select an Account")}
                  options={accountListWithAll}
                  value={selectedAccount}
                  handleFilter={setSelectedAccount}
                  size="medium"
                  isLoading={accountListLoading}
                />
              </Col>
            )} */}

          {/* Worksite Dropdown */}

          <Col md={3}>
            <DropdownComponent
              label={t("Worksite")}
              placeholder={t("Select a worksite")}
              options={worksitesListWithAll}
              value={selectedWorksite}
              handleFilter={setSelectedWorksite}
              errorMessage={t("This field is required")}
              size="medium"
              isLoading={getWorksiteListLoading}
            />
          </Col>

          {/* Date Range Picker */}
          <Col lg={6}>
            <DateRangePicker
              setDate={onDateChange}
              startDate={startDate}
              endDate={endDate}
              minDate={moment().subtract(730, "days").toDate()}
            />
          </Col>

          {/* Filter & Export Buttons */}
          <StyledCol>
            <Button
              iconClass="filter"
              title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
              label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
              onClick={() => setShowMoreFilter(!showMoreFilter)}
              iconOnly
              buttonType={showMoreFilter ? "primary" : "secondary"}
            />
            <span style={{ width: "10px", display: "inline-block" }}>&nbsp;</span>
            <Button
              label={t("Apply Filter")}
              size="medium"
              onClick={applyFilter}
              showIcon={false}
              buttonType="primary"
            />
          </StyledCol>

          {/* Export to CSV */}
          <Col>
            <ExportToCSV
              downloadReportSet={downloadReportSet}
              reportList={recordSet}
              currentTimeZone={currentTimeZone}
            />
          </Col>
        </Row>

        {/* Additional Filters */}
        {showMoreFilter && (
          <Row>
            {/* Filter By Dropdown */}
            <Col lg={3}>
              <DropdownComponent
                label={t("Filter By")}
                placeholder={t("Select a Type")}
                options={[
                  { value: "assets", label: t("Assets") },
                  { value: "users", label: t("Users") },
                ]}
                value={filterBy}
                handleFilter={setFilterBy}
                size="medium"
              />
            </Col>

            {/* Conditional Dropdowns Based on Filter Selection */}
            {filterBy?.value === "users" && (
              <Col lg={3}>
                <DropdownComponent
                  label={t("Users")}
                  placeholder={t("Select a User")}
                  options={userListWithAll}
                  value={selectedUser}
                  handleFilter={(user) => {
                    setSelectedUser(user);
                    setSelectedAsset({ value: "all", label: t("All") });
                  }}
                  size="medium"
                  isLoading={getUserListLoading}
                />
              </Col>
            )}

            {filterBy?.value === "assets" && (
              <Col lg={3}>
                <DropdownComponent
                  label={t("Asset")}
                  placeholder={t("Select an Asset")}
                  options={assetListWithAll}
                  value={selectedAsset}
                  handleFilter={(asset) => {
                    setSelectedAsset(asset);
                    setSelectedUser({ value: "all", label: t("All") });
                  }}
                  size="medium"
                  isLoading={getAssetListLoading}
                />
              </Col>
            )}
          </Row>
        )}
      </FilterWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
  userList: state.modalDropdownFilters.userList,
  assetList: state.modalDropdownFilters.assetList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  getUserListLoading: state.modalDropdownFilters.getUserListLoading,
  getAssetListLoading: state.modalDropdownFilters.getAssetListLoading,
  accountListLoading: state.dropdownFilters.accountListLoading,
  currentTimeZone: state.settings.currentTimeZone,
  isSuperAdmin: state.user.isSuperAdmin,
  loginDetails: state.user.loginDetails,
  selectedAccountId: state.deviceInfo.selectedAccountId,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,

  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey) =>
    dispatch(getUsers(accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey)),
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAssetList: (accountId, worksiteId, assetTypeId) =>
    dispatch(getDropdownAssetList(accountId, worksiteId, assetTypeId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
});

SettingsHeader.defaultProps = {
  showFilters: true,
  handleAssetIdFilter: () => {},
  handleAssetTypeIdFilter: () => {},
  handleWorksiteFilter: () => {},
  showWorksiteFilter: true,
  showAssetTypeFilter: true,
  showAssetFilter: true,
  showUserTypeFilter: false,
  showUserFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsHeader);
