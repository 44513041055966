import styled from "styled-components";

export const SettingNotification = styled.div``;

export const SettingTableDescription = styled.div``;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  height: 32px;
  border-bottom: 1px solid
    ${({ isHeader, theme }) =>
      isHeader ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.grayL5 || "#CCCCCC"};
  :last-child {
    border-bottom: none;
  }
  margin: ${({ isHeader }) => (isHeader ? "auto auto -12px auto" : "10px auto")};
  @media (max-width: 1100px) {
    height: auto;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "8px 0px" : "0px")};
  color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader }) => (isHeader ? "18px" : "16px")} !important;
  font-family: ${({ isHeader }) => (isHeader ? "Kanit" : "Open Sans")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: ${({ even }) => (even ? "left" : "right")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const OnOffText = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const SettingDotIcon = styled.i`
  font-size: 17px;
  color: ${(props) => props.color};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
`;

export const SettingDotIconLow = styled.i`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
`;

export const SettingDotIconMed = styled.i`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
`;

export const SettingDotIconHigh = styled.i`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
`;

export const SettingContent = styled.div`
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CustomEmailFieldDiv = styled.div`
  display: blockl;
  width: 100%;
  clear: both;
`;

export const CustomeUserDiv = styled.div`
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-weight: 400;
`;

export const CustomeEmailDiv = styled.div`
  margin-top: 10px;
`;
export const CloseButtonDiv = styled.div`
  line-height: 20px;
  padding-left: 10px;
`;

export const EmailContainerDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  padding: 4px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin: 5px;
  display: flex;
  float: left;
  &:hover ${CloseButtonDiv} {
   /* border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    margin: 3px;
    padding: 0px 6px;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-size: 14px;
    line-height: 20px;*/
  }
`;

export const EmailSpanDiv = styled.div`
  margin-right: 5px;
  float: left;
  flex: 1;
`;

export const InformationDivIcon = styled.div`
  position: relative;
  top: 3px;
`;

export const StyledInformationIcon = styled.div`
  width: 17px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 1px;
`;
