import {
  HeaderInfoWrapper,
  BacktoSearch,
  AssetNameHeading,
  HeaderAssetDetails,
  SpanDetails,
  LeftFontAwsm,
} from "./DeviceDetailsInfo.styles";
import { Trans } from "react-i18next";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RefreshButtonDiv, RefreshButton } from "../DeviceInformation.component.styles";

const Header = (props) => {
  const { accountName = "NA", setShowDeviceDetails, selectedAsset, getDeviceInformation } = props;

  const { lastDeviceInformation, assetTypeId, siteId, assetName, productType } = selectedAsset;

  const { imeiNumber, assetTag } = lastDeviceInformation || { imeiNumber: "NA", assetTag: "NA" };

  const refreshData = () => {
    getDeviceInformation();
  };

  return (
    <>
      <HeaderInfoWrapper>
        <BacktoSearch onClick={() => setShowDeviceDetails(false)}>
          <LeftFontAwsm>
            <i className="icon-angle-left"></i>
          </LeftFontAwsm>
          <Trans>Back to Search</Trans>
        </BacktoSearch>
        <AssetNameHeading>
          <span title={assetName}>{assetName}</span>

          <RefreshButtonDiv>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh</Tooltip>}>
              <RefreshButton className="icon icon-update" onClick={refreshData} />
            </OverlayTrigger>
          </RefreshButtonDiv>
        </AssetNameHeading>
        <HeaderAssetDetails>
          <Row>
            <Col md={4}>
              <SpanDetails>
                {" "}
                Account:{" "}
                <strong>
                  <span title={accountName}>{accountName}</span>
                </strong>
              </SpanDetails>
            </Col>
            <Col md={8}>
              <SpanDetails>
                {" "}
                SIERA.AI Product:{" "}
                <strong>
                  <span title={productType}>{productType}</span>
                </strong>
              </SpanDetails>
            </Col>

            <Col md={4}>
              <SpanDetails>
                {" "}
                Worksite:{" "}
                <strong>
                  <span title={siteId?.name}>{siteId?.name}</span>
                </strong>
              </SpanDetails>
            </Col>
            <Col md={8}>
              <SpanDetails>
                {" "}
                IMEI Number:{" "}
                <strong>
                  <span title={imeiNumber}>{imeiNumber}</span>
                </strong>
              </SpanDetails>
            </Col>

            <Col md={4}>
              <SpanDetails>
                {" "}
                Asset Type:{" "}
                <strong>
                  <span title={assetTypeId?.AssetTypeName}>{assetTypeId?.AssetTypeName}</span>
                </strong>
              </SpanDetails>
            </Col>
            <Col md={8}>
              <SpanDetails>
                {" "}
                Asset Tag:{" "}
                <strong>
                  <span title={assetTag || "NA"}>{assetTag || "NA"}</span>
                </strong>
              </SpanDetails>
            </Col>
          </Row>
        </HeaderAssetDetails>
      </HeaderInfoWrapper>
    </>
  );
};

export default Header;
