import styled from "styled-components";
export const ManagementTabHeader = styled.div`
  p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .tabbaritemactive {
    border-bottom: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-weight: 500;
  }
  /* div {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .tabbaritemactive {
    border-bottom: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-weight: 500;
  }
  .selectStyleCss:before {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    position: relative;
    z-index: 1;
  }
  .selectStyleCss select {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  @media screen and (max-width: 1100px) {
    div {
      border-bottom: 0px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    }
  }*/
`;

export const DotIcon = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CancelIcon = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const MobileTabBar = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: flex;
    margin: 10px auto;
    .select__single-value {
      font-weight: bold !important;
    }
    svg path {
      filter: invert(1);
    }
  }
`;
export const TabViewDiv = styled.div`
  flex: 1;
`;
export const IconActionBar = styled.div``;
export const IconDiv = styled.div``;
export const CanIconDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const DotIconBack = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
