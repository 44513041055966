import React, { useState, useEffect } from "react";
import Slider from "../CameraSettingView/slider";
import { ReactComponent as ForkLiftTopView } from "../../../../../assets/CameraSettings/Forklift_Sideview.svg";
import { ReactComponent as ForkliftBack } from "../../../../../assets/CameraSettings/Forklift_Back.svg";
import { ReactComponent as ForkliftFront } from "../../../../../assets/CameraSettings/Forklift_Front.svg";
import { ReactComponent as ForkliftSideviewReverse } from "../../../../../assets/CameraSettings/Forklift_Sideview_Reverse.svg";
import cameraHeightViewCss from "./CameraHeightView.module.css";

const {
  dot_line_1,
  dot_line_2,
  dot_line_3,
  dot_line_4,
  dot_line_5,
  dot_line_6,
  dot_line_7,
  dot_line_8,
  forklift_height,
  container,
  square_red,
  parent,
  canvas_position,
  forklift_height_back_rear,
  forklift_height_reverse,
} = cameraHeightViewCss;
const CameraHeightView = (props) => {
  const {
    cameraPosition,
    obstacle,
    inputValue,
    setInputValue,
    value,
    setValue,
    s3CameraForm,
    selectedCamera,
    setS3CameraForm,
    getCameraRawObject,
    forkliftClass,
    setForkliftClass,
    setTabKey,
    unitType,
    isFeet,
    s3SettingsUntouchedObject,
  } = props;
  const currentInputValue = { ...inputValue[obstacle] };
  const [heightDataConst, setHeightDataConstant] = useState([
    { type: "pos1", min: 500, max: 10000 },
    { type: "pos2", min: 500, max: 3000 },
    { type: "pos3", min: 500, max: 3000 },
    { type: "pos4", min: parseInt(currentInputValue.pos6) + 500, max: 4000 },
    { type: "pos5", min: 500, max: parseInt(currentInputValue.pos6) - 500 },
    { type: "pos6", min: 1500, max: 3000 },
  ]);

  const [heightDataConstFeet, setHeightDataConstantFeet] = useState([
    { type: "pos1", min: 1.64, max: 32.8 },
    { type: "pos2", min: 1.64, max: 9.84 },
    { type: "pos3", min: 1.64, max: 9.84 },
    { type: "pos4", min: parseFloat(currentInputValue.pos6) + 1.64, max: 13.12 },
    { type: "pos5", min: 1.64, max: parseFloat(currentInputValue.pos6) - 1.64 },
    { type: "pos6", min: 4.92, max: 9.84 },
  ]);

  useEffect(() => {
    let currentInputValue = { ...inputValue[obstacle] };
    setHeightDataConstant([
      { type: "pos1", min: 500, max: 10000 },
      { type: "pos2", min: 500, max: 3000 },
      { type: "pos3", min: 500, max: 3000 },
      { type: "pos4", min: parseInt(currentInputValue.pos6) + 500, max: 4000 },
      { type: "pos5", min: 500, max: parseInt(currentInputValue.pos6) - 500 },
      { type: "pos6", min: 1500, max: 3000 },
    ]);
    setHeightDataConstantFeet([
      { type: "pos1", min: 1.64, max: 32.8 },
      { type: "pos2", min: 1.64, max: 9.84 },
      { type: "pos3", min: 1.64, max: 9.84 },
      { type: "pos4", min: parseFloat(currentInputValue.pos6) + 1.64, max: 13.12 },
      { type: "pos5", min: 1.64, max: parseFloat(currentInputValue.pos6) - 1.64 },
      { type: "pos6", min: 4.92, max: 9.84 },
    ]);
  }, [inputValue]);

  let canvas = document.getElementById("myCanvas");
  useEffect(() => {
    if (canvas) {
      return;
    }
    canvas = document.getElementById("myCanvas");
  }, [value]);

  useEffect(() => {
    let tempPositionPedestrian = {};
    let tempPositionObject = {};
    let newS3CameraForm = { ...s3CameraForm };
    newS3CameraForm[selectedCamera] = getCameraRawObject(
      { number: selectedCamera, name: forkliftClass },
      inputValue
    );
    //setS3CameraForm({ ...newS3CameraForm });
    let currentHeightConst = isFeet ? heightDataConstFeet : heightDataConst;
    currentHeightConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["pedestrian"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
        // inputValue[type] = max;
      }
      if (type === "pos5") {
        newValue = Math.abs(newValue);
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionPedestrian[type] = newValue;
      //setInputValue([ ...inputValue, { [type]: inputValue } ])
    });
    currentHeightConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["object"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
        // inputValue[type] = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionObject[type] = newValue;
    });
    let newValue = { ...value };
    let pos4Object = tempPositionObject?.pos4;
    let pos4Pedestrian = tempPositionPedestrian?.pos4;
    if (isNaN(pos4Object) || isNaN(pos4Pedestrian)) {
      return;
    }
    newValue["pedestrian"] = { ...tempPositionPedestrian };
    newValue["object"] = { ...tempPositionObject };
    setValue({ ...newValue });
  }, [inputValue]);

  var rangeSlider1 = document.getElementById("rs-range-line-4");
  var rangeBullet1 = document.getElementById("rs-bullet-4");
  var rangeInput1 = document.getElementById("rs-input-4");
  var rangeSlider2 = document.getElementById("rs-range-line-5");
  var rangeBullet2 = document.getElementById("rs-bullet-5");
  var rangeInput2 = document.getElementById("rs-input-5");
  var rangeSlider3 = document.getElementById("rs-range-line-7");
  var rangeBullet3 = document.getElementById("rs-bullet-7");
  var rangeInput3 = document.getElementById("rs-input-7");

  useEffect(() => {
    let { pos1, pos4, pos5, pos6 } = value[obstacle];

    if (isNaN(pos4)) {
      return;
    }
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 244);
    var bulletPosition1 = pos4 / rangeSlider1?.max;
    var bulletPosition2 = pos5 / rangeSlider2?.max;
    var bulletPosition3 = pos1 / rangeSlider3?.max;

    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 90 + "px";
      ctx.lineTo(pos4 * 1.72 + 12, 235 - pos4 * 1.8);
      // ctx.lineTo(98, 235 - (pos4 * 1.8));
      // ctx.lineTo(98, 330 - (pos5 * 1.4));
      if (pos1 >= pos4 - 4) {
        ctx.lineTo(pos1 * 1.6 + 20, 235 - pos4 * 1.8);
      } else {
        ctx.lineTo(pos1 * 1.6 + 20, 225 - pos1 * 1.6);
      }

      if (true) {
        //pos1 <= (pos5-parseInt(0.2*pos5))
        ctx.lineTo(pos1 * 1.6 + 20, 325 - pos5 * 1.45);
      } else {
        ctx.lineTo(pos1 * 1.6 + 20, 300 - pos1 * 1.6);
      }
      ctx.lineTo(80 - pos5 * 1.34, 325 - pos5 * 1.4);

      ctx.lineTo(0, 244);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0.7)";
      ctx.strokeStyle = "#7a7a51";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 70 + "px";
    }
    if (rangeBullet3) {
      rangeBullet3.style.left = bulletPosition3 * 80 + "px";
    }
    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 90 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 70 + "px";
    }
    if (rangeInput3) {
      rangeInput3.style.left = bulletPosition3 * 80 + "px";
    }
  }, [value, inputValue, obstacle, selectedCamera]);

  const unTouchedNearMissDetectionObj = s3SettingsUntouchedObject?.[0]?.nearMissDetection?.[obstacle];

  return (
    <div style={{ postion: "relative" }} className="camera_height_style">
      <div className="width-text" style={{ top: "310px" }}>
        <p>Move the slider up or down to adjust the values</p>
      </div>
      <canvas id="myCanvas" className={canvas_position} width="310" height="285"></canvas>
      {forkliftClass?.split("-")[0] === "rear" && (
        <ForkLiftTopView style={{ width: "280px", height: "100px" }} className={forklift_height} />
      )}
      {forkliftClass?.split("-")[0] === "front" && (
        <ForkliftSideviewReverse
          style={{ width: "280px", height: "100px" }}
          className={forklift_height_reverse}
        />
      )}
      {forkliftClass === "right" && (
        <ForkliftBack style={{ width: "280px", height: "100px" }} className={forklift_height_back_rear} />
      )}
      {forkliftClass === "left" && (
        <ForkliftFront style={{ width: "280px", height: "100px" }} className={forklift_height_back_rear} />
      )}
      <div className={square_red} />
      <div className={dot_line_1} />
      <div className={dot_line_2} />
      <div className={dot_line_3} />
      <div className={dot_line_4} />
      <div className={dot_line_5} />
      <div className={dot_line_6} />
      <div className={dot_line_7} />
      {/* <div className={dot_line_8} /> */}
      <Slider
        unitType={unitType}
        isDepth={false}
        obstacle={obstacle}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setValue={setValue}
        value={value}
        type="pos6"
        min={isFeet ? 4.92 : 1500}
        max={isFeet ? 9.84 : 3000}
        untouchedValueinMM={unTouchedNearMissDetectionObj?.middle}
      />
      <div className={container + " " + parent}>
        <Slider
          unitType={unitType}
          isDepth={false}
          obstacle={obstacle}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos7"
          min={isFeet ? 1.64 : 500}
          max={isFeet ? 32.8 : 10000}
          setTabKey={setTabKey}
          /* fallback */
          untouchedValueinMM={500}
        />
        <Slider
          unitType={unitType}
          isDepth={false}
          obstacle={obstacle}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos4"
          min={isFeet ? heightDataConstFeet[4 - 1].min : heightDataConst[4 - 1].min}
          max={isFeet ? 13.12 : 4000}
          setTabKey={setTabKey}
          untouchedValueinMM={unTouchedNearMissDetectionObj?.top}
        />
        <Slider
          unitType={unitType}
          isDepth={false}
          obstacle={obstacle}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos5"
          min={isFeet ? 1.64 : 500}
          max={isFeet ? heightDataConstFeet[5 - 1].max : heightDataConst[5 - 1].max}
          setTabKey={setTabKey}
          untouchedValueinMM={unTouchedNearMissDetectionObj?.bottom}
        />
      </div>
      <div className="triangle_down"></div>
    </div>
  );
};

export default CameraHeightView;
