import React from "react";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
`;

const Skeleton = styled.div`
  background-color: #f0f0f0;
  animation: ${pulse} 1.5s infinite ease-in-out;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "1em"};
  border-radius: ${(props) => props.borderRadius || "4px"};
`;

const SkeletonLoader = ({ width, height, borderRadius }) => {
  return <Skeleton width={width} height={height} borderRadius={borderRadius} />;
};

export default SkeletonLoader;
