import { bulkUploadTypes } from "./types";
import { initialState } from "./initialState";

export const bulkUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case bulkUploadTypes.RESET_UPLOAD_ASSET_BULK_UPLOAD_FILE_STATE:
      return {
        ...state,
        assetBulkUploadFileUploadLoading: false,
        assetBulkUploadFileUploadSuccess: false,
        assetBulkUploadFileUploadError: false,
        assetBulkUploadFileUploadStatus: "",
      };

    case bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_LOADING:
      return {
        ...state,
        assetBulkUploadLoading: true,
        assetBulkUploadSuccess: false,
        assetBulkUploadError: false,
      };

    case bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        assetBulkUploadLoading: false,
        assetBulkUploadSuccess: false,
        assetBulkUploadError: true,
      };

    case bulkUploadTypes.GET_ASSET_BULK_UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        assetBulkUploadLoading: false,
        assetBulkUploadSuccess: true,
        assetBulkUploadError: false,
        assetTypeTemplateList: action.data,
      };

    case bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_LOADING:
      return {
        ...state,
        assetBulkUploadFileUploadLoading: true,
        assetBulkUploadFileUploadSuccess: false,
        assetBulkUploadFileUploadError: false,
        assetBulkUploadFileUploadStatus: "",
      };

    case bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        assetBulkUploadFileUploadLoading: false,
        assetBulkUploadFileUploadSuccess: true,
        assetBulkUploadFileUploadError: false,
        assetBulkUploadFileUploadStatus: action.data,
      };

    case bulkUploadTypes.UPLOAD_ASSET_BULK_UPLOAD_FILE_LOADING:
      return {
        ...state,
        assetBulkUploadFileUploadLoading: true,
        assetBulkUploadFileUploadSuccess: false,
        assetBulkUploadFileUploadError: true,
        assetBulkUploadFileUploadStatus: "",
      };

    default:
      return state;
  }
};
