export const modalDropdownTypes = {
  GET_MODAL_DROPDOWN_WORKSITE_LIST_LOADING: "GET_MODAL_DROPDOWN_WORKSITE_LIST_LOADING",
  GET_MODAL_DROPDOWN_WORKSITE_LIST_FAILED: "GET_MODAL_DROPDOWN_WORKSITE_LIST_FAILED",
  GET_MODAL_DROPDOWN_WORKSITE_LIST_SUCCESS: "GET_MODAL_DROPDOWN_WORKSITE_LIST_SUCCESS",

  GET_MODAL_DROPDOWN_ASSET_LIST_LOADING: "GET_MODAL_DROPDOWN_ASSET_LIST_LOADING",
  GET_MODAL_DROPDOWN_ASSET_LIST_FAILED: "GET_MODAL_DROPDOWN_ASSET_LIST_FAILED",
  GET_MODAL_DROPDOWN_ASSET_LIST_SUCCESS: "GET_MODAL_DROPDOWN_ASSET_LIST_SUCCESS",

  GET_MODAL_DROPDOWN_USER_LIST_SUCCESS: "GET_MODAL_DROPDOWN_USER_LIST_SUCCESS",
  GET_MODAL_DROPDOWN_USER_LIST_LOADING: "GET_MODAL_DROPDOWN_USER_LIST_LOADING",
  GET_MODAL_DROPDOWN_USER_LIST_FAILED: "GET_MODAL_DROPDOWN_USER_LIST_FAILED",

  GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_SUCCESS: "GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_SUCCESS",
  GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_LOADING: "GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_LOADING",
  GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_FAILED: "GET_MODAL_DROPDOWN_SUPER_ADMIN_LIST_FAILED",
};
