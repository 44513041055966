export const tableHeader = [
  { name: "Account", space: 0, feildName: "accountName" },
  { name: "Worksite", space: 0, feildName: "worksite" },
  { name: "Asset Type", space: 0, feildName: "assetType" },
  { name: "Asset Name", space: 0, feildName: "assetName" },
  { name: "Inspected By", space: 0, feildName: "inspectedBy" },
  { name: "Date, Time", space: 0, feildName: "createdAt" },
  { name: "Report Status", space: 1, feildName: "status" },
  { name: "Actions", space: 1, feildName: "inspectionCountMonthly" },
];

export const mobileRowHeader = [
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 1,
    isNestedVar3: true,
    feildName1: "assetId",
    feildName2: "assetTypeId",
    feildName3: "AssetTypeName",
  },
  {
    name: "Asset Name",
    keyName: "assetName",
    space: 1,
    isNestedVar2: true,
    feildName1: "assetId",
    feildName2: "assetName",
  },
  {
    name: "Inspected By",
    sapce: 1,
    isNestedVar2: true,
    feildName1: "inspectorId",
    feildName2: "fullName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    feildName: "createdAt",
  },
];

export const tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    isNestedVar2: true,
    feildName1: "accountId",
    feildName2: "companyName",
  },
  {
    name: "Worksite",
    keyName: "worksite",
    space: 0,
    isNestedVar2: true,
    feildName1: "siteId",
    feildName2: "name",
  },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    isNestedVar3: true,
    feildName1: "assetId",
    feildName2: "assetTypeId",
    feildName3: "AssetTypeName",
  },
  {
    name: "Asset Name",
    keyName: "assetName",
    space: 0,
    isNestedVar2: true,
    feildName1: "assetId",
    feildName2: "assetName",
  },
  {
    name: "Inspected By",
    space: 0,
    isNestedVar2: true,
    feildName1: "inspectorId",
    feildName2: "fullName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    feildName: "inspectionAt",
    fieldName2: "sideId",
    fieldName3: "timeZone",
  },
  {
    name: "Report Status",
    keyName: "status",
    isStatus: true,
    space: 1,
    feildName: "status",
    isNestedVar2: true,
    isNestedVar3: true,
  },
];

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
  { name: "User", _id: "inspectedBy" },
  { name: "Inspection Result", _id: "inspectionResult" },
  { name: "Checklist", _id: "checklist" },
];

export const paginationOptions = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectedBy: "User",
  inspectionResult: "Inspection Result",
  checklist: "Checklist",
};
