import React, { useState } from "react";
import DataTableInspection from "../../../../../components/DataTableComponent/DataTable.component";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toDownloadFiles } from "../../../../../redux/config/action";
import { getTimezoneShortCode } from "../../../../../utils/timezoneConvert";
import PopoverCustom from "../../../../../components/PopoverCustom.component";
import ReportDownload from "../../../Report/ReportShare/ReportShareModal.component";
import moment from "moment";
import awsConstant from "../../../../../constant/awsFolder.json";
import { NoReportsDiv } from "../../../../../components/DataTableComponent/DataTable.component.styles";

const InspectionTable = ({
  data,
  loginDetails,
  isSiteAdmin,
  toDownloadFiles,
  fileDownloadLoading,
  isSingleAccountMultiSiteUser,
  setIsSuccess,
  setIsError,
  currentTimeZone,
  loading,
}) => {
  const { t } = useTranslation();
  const tableHeader = [
    { name: "Account", space: 2, feildName: "accountName" },
    { name: "Worksite", space: 1, feildName: "worksite" },
    { name: "Asset Type", space: 1, feildName: "assetType" },
    { name: "Asset Name", space: 1, feildName: "assetName" },
    { name: "Inspected By", sapce: 1, feildName: "inspectedBy" },
    { name: "Date, Time", space: 1, feildName: "createdAt" },
    { name: "Actions", space: 2, feildName: "inspectionCountMonthly" },
  ];

  const [loadingReportId, setLoadingReportId] = useState("");
  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.inspectionReport, `${id}.pdf`);
  };
  const viewReportClickHandler = (e, data) => {
    localStorage.setItem("inspectionReportObject", JSON.stringify(data));
    window.open("/#/app/inspection-report", "_blank");
  };
  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };
  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>
            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 1,
                        reportId: row?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };
  const columns = tableHeader
    .filter(
      (data) =>
        !(
          (isSingleAccountMultiSiteUser && data.name === "Account") ||
          (isSiteAdmin && (data.name === "Worksite" || data.name === "Account"))
        )
    )

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row.siteId?.name || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetTypeId?.AssetTypeName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Inspected By") {
        return {
          name: t(data.name),
          selector: (row) => row?.inspectorId?.fullName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const timeZone = currentTimeZone || row?.siteId?.timeZone;
            const formattedDate = !row?.createdAt
              ? "NA"
              : `${moment(row?.createdAt)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm A")} ${getTimezoneShortCode(timeZone)}`;

            return <span title={formattedDate}> {formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }
      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.feildName],
        sortable: true,
      };
    });

  const mobileCheckboxDateTime = [
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Asset Type", className: "assetTyleStyle" },
  ];

  const mobileCheckboxAccordion = ["Asset Name", "Inspected By"];

  if (!Array.isArray(data)) {
    return (
      <NoReportsDiv>
        <Trans>No Inspection Reports Available</Trans>
      </NoReportsDiv>
    );
  }

  return (
    <>
      <DataTableInspection
        title=""
        columns={columns}
        data={data}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordion={mobileCheckboxAccordion}
        responsive
        dense
        loading={loading}
        rowHeight={"565"}
        scrollable={true}
        fixedHeader
        mobileCheckboxOnly
        mobileDateTimeOnly
        mobileActionItemOnly
        noDataMessage={t("No Inspection Reports Available")}
        rowsPerPageOptions={[5]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  fileDownloadLoading: state.config.fileDownloadLoading,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionTable));
