import React from "react";
import { RadioButtonWrapper, RadioInput, RadioLabel, RadioCircle } from "./Radio.component.styles";
import PropTypes from "prop-types";

const RadioButton = (props) => {
  const { label, size, name, checked, id, disabled, onChange, onClick, ...rest } = props;

  return (
    <RadioButtonWrapper>
      <RadioInput
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        size={size}
        {...rest}
      />
      {checked && <RadioCircle disabled={disabled} size={size} label={label}></RadioCircle>}
      <RadioLabel size={size} htmlFor={id} title={label}>
        {label}
      </RadioLabel>
    </RadioButtonWrapper>
  );
};

RadioButton.propTypes = {
  /**
   * Specify whether the Radio Button should be disabled, or not
   */
  disabled: PropTypes.bool,
  /**
   * Radio Button label
   */
  label: PropTypes.string,
  /**
   *  Provide an function to be called when the Radio Button element is clicked
   */
  onChange: PropTypes.func,
  /**
   * How large should the Radio Button be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

RadioButton.defaultProps = {
  size: "large",
  id: "id",
};

export default RadioButton;
