import React from "react";
import { useTranslation } from "react-i18next";
import { ParaText } from "./AssetReactivation.styles";

const AssetReactivation = ({ assetStatusData }) => {
  const { t } = useTranslation();
  const type = !assetStatusData.isAccountActive
    ? "Account"
    : !assetStatusData.isWorksiteActive
    ? "Worksite"
    : "";
  const assetReactivationErrorMessage = t("ASSET_REACTIVATION_ERROR_MESSAGE", { type });

  return <ParaText>{assetReactivationErrorMessage}</ParaText>;
};

export default AssetReactivation;
