import styled from "styled-components";
export const EmailsContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-right: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
`;

export const ClearAll = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  cursor: pointer;
  padding: 5px 10px;
  font-weight: 600;
  margin: 0;
  font-family: "Open Sans", sans-serif;
`;

export const EmailList = styled.div`
  max-height: 76px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BlockContainer = styled.div`
  display: block;
  padding: 0px 0px 0px 10px;
`;

export const TabSpan = styled.div`
  display: flex;
  min-width: 0px;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  float: left;
  font-family: "Open Sans", sans-serif;
`;

export const EmailText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  box-sizing: border-box;
`;

export const CloseIcon = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: rgb(255, 189, 173);
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    i {
      color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    }
  }
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 0.875rem;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
