import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../assets/Delete.svg";
import { ReactComponent as AddUploadIcon } from "../../../../assets/add.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/cancel.svg";

export default function MyModal({
  isOpen,
  setIsOpen,
  uploadedFile,
  fileUpload,
  deleteFile,
  responseMedia,
  deleteImageLoading,
  uploadImageLoading,
}) {
  const [deleteId, setDeleteId] = useState("");
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-[1300] overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="max-w-[72%] inline-block border border-gray-500 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl rounded-md">
                <div className="flex flex-wrap justify-end" onClick={() => setIsOpen(false)}>
                  {/*  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    */}
                  <CloseIcon className="h-5 icon-color-black" />
                </div>
                <div className="flex flex-wrap items-space-evenly justify-center">
                  <div className="border rounded-md border-[#d9d9d9] w-28 h-28 mt-0 mr-2 mb-2 ml-0 inline-block align-top">
                    <label
                      className="flex flex-col align-middle h-full items-center justify-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        onChange={fileUpload}
                        type="file"
                        className="hidden block"
                        name="uploadFile"
                        multiple
                        accept="image/*,audio/*,video/*,application/pdf,.csv"
                      />
                      <AddUploadIcon className="h-5 upload-icon-color" />
                      UPLOAD
                    </label>
                  </div>

                  {uploadedFile?.map((file, i) => {
                    let url = URL.createObjectURL(uploadedFile[i]);
                    return (
                      <div className="w-28 h-28 mt-0 mr-2 mb-2 ml-0 inline-block align-top">
                        <div className="h-full m-0 relative p-2  border  rounded-md border-[#d9d9d9]">
                          <div className="p-0 relative h-full overflow-hidden ">
                            {uploadImageLoading && !responseMedia?.[i] && (
                              <svg
                                role="status"
                                className="inline ml-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 absolute top-[30%] left-[30%]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="#1C64F2"
                                />
                              </svg>
                            )}
                            <span>
                              <img
                                className={`w-28 h-28 object-cover ${
                                  uploadImageLoading && !responseMedia?.[i] && "opacity-40"
                                }`}
                                key={i}
                                id="blah"
                                src={url}
                                alt="your image"
                              />
                            </span>
                          </div>

                          {!(uploadImageLoading && !responseMedia?.[i]) && (
                            <span className="z-[10] whitespace-nowrap delete-icon text-center">
                              {/* <p  className='text-center text-blue-500 underline'>Delete</p>*/}
                              {deleteImageLoading && deleteId === responseMedia?.[i] ? (
                                <svg
                                  role="status"
                                  className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#1C64F2"
                                  />
                                </svg>
                              ) : (
                                <DeleteIcon
                                  className="h-5 icon-color-black"
                                  style={{
                                    cursor: "pointer",
                                    width: "-webkit-fill-available",
                                  }}
                                  id={responseMedia?.[i]}
                                  onClick={() => {
                                    setDeleteId(responseMedia?.[i]);
                                    deleteFile(responseMedia?.[i]);
                                  }}
                                />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
