import React from "react";

export let initialState = {
  alert: null,
};

const useController = () => {
  const [state, dispatch] = React.useReducer((state, value) => {
    let newState = {
      ...state,
      ...value,
    };
    return newState;
  }, initialState);

  return { state, dispatch };
};

export default useController;
