import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

import DownloadStatusTable from "./TableWrapper/TableWrapper.component";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import DateRangePicker from "../../../../components/DatePicker.component";
import MoreFilterMobileToggle from "../../../../components/More Filter Button/MoreFilterButton.component";
import { getUsers, getAllSuperAdmins } from "../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../utils/apiCall";
import {
  RefreshButtonDiv,
  RefreshButton,
  FiltersWrapper,
  FiltersWrapperTop,
  RefreshButtonD,
  DownloadStatusTableDiv,
} from "./DownloadStatus.styles";
import { getDropdownAccountList } from "../../../../redux/dropdownFilters/action";

function DownloadStatus({
  dropdownAccountList,
  getUsers,
  userList,
  superAdminList,
  getAllSuperAdmins,
  accountListLoading,
  getDropdownAccountList,
  getUserListLoading,
  getSuperAdminListLoading,
}) {
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState({ value: null, label: "All" });
  const [selectedUser, setSelectedUser] = useState({ value: null, label: "All" });
  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [statusData, setStatusData] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [requestedByOptions, setRequestedByOptions] = useState([{ value: null, label: "All" }]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [reportType, setReportType] = useState("s3logscsv");

  const [accountListWithAll, setAccountListWithAll] = useState([]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  const handleReportType = (requestType) => {
    setReportType(requestType);
  };

  useEffect(() => {
    if (selectedAccountType?.value === 1) {
      getAllSuperAdmins();
    } else if (selectedAccount?.value) {
      getUsers(selectedAccount?.value);
    } else {
      setRequestedByOptions([{ value: null, label: "All" }]);
    }
  }, [getUsers, selectedAccountType?.value, selectedAccount?.value]);

  useEffect(() => {
    setSelectedUser({ value: null, label: "All" });
  }, [selectedAccountType?.value]);

  useEffect(() => {
    if (selectedAccount?.value || selectedAccountType?.value === 1) {
      getDownloadStatusData();
    }
  }, [selectedAccountType?.value, selectedAccount, startDate, endDate, selectedUser, pageNo, pageSize]);

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList?.map((data) => ({
        value: data._id,
        label: data.companyName,
      }));
      optionListTemp.unshift({ value: null, label: "All" });
      setAccountListWithAll(optionListTemp);
    }
  }, [dropdownAccountList]);

  useEffect(() => {
    const superAdminsOptions =
      superAdminList?.map((data) => ({
        value: data._id,
        label: data.fullName,
      })) || [];

    const userOptions =
      userList?.map?.((data) => ({
        value: data._id,
        label: data.fullName,
        emailTo: data.email,
      })) || [];
    const optionListTemp =
      selectedAccountType?.value === 1 ? superAdminsOptions : selectedAccount?.value ? userOptions : [];

    optionListTemp.unshift({ value: null, label: "All" });

    setRequestedByOptions(optionListTemp);
  }, [selectedAccountType?.value, userList, superAdminList]);

  const [statusDataLoading, setStatusDataLoading] = useState(false);

  const getDownloadStatusData = async () => {
    setStatusDataLoading(true);
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    let url = `/asyncreq/?isSuperAdmin=${selectedAccountType?.value === 1}&accountId=${
      selectedAccount?.value
    }&reqStartDate=${formattedStartDate}&reqType=${reportType}&reqEndDate=${formattedEndDate}&createdBy=${
      selectedUser?.value
    }&pageNo=${pageNo}&size=${pageSize}`;

    try {
      const resp = await apiCall(url);

      if (resp.data.success) {
        setStatusData(resp.data.data?.data || []);
        setTotalCount(resp.data.data?.totalCount || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setStatusDataLoading(false);
    }
  };

  const refreshData = () => {
    getDownloadStatusData();
  };

  const handlePageLength = (e) => {
    setPageSize(e.target.value);
  };

  const previousPage = () => {
    if (pageNo === 1) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (pageNo * pageSize >= totalCount) {
      return;
    }
    setPageNo(pageNo + 1);
  };

  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const onRefresh = () => {
    setIsRotating(true);

    setTimeout(() => {
      setIsRotating(false);
      setRotation(rotation + 360);
    }, 2000);
  };

  return (
    <>
      <MoreFilterMobileToggle>
        <FiltersWrapperTop>
          <FiltersWrapper>
            <Row>
              <Col lg={2} className="mb-2">
                <DropdownComponent
                  options={[
                    {
                      value: 1,
                      label: t("All Accounts"),
                    },
                    {
                      value: 2,
                      label: t("Single Account"),
                    },
                  ]}
                  label={t("User Type Access Level")}
                  value={selectedAccountType}
                  onChange={(value) => {
                    setSelectedAccountType(value);
                  }}
                  size="medium"
                />
              </Col>
              {selectedAccountType && (
                <>
                  <Col lg={2}>
                    <DropdownComponent
                      label={t("Account")}
                      placeholder={t("Select an Account")}
                      options={accountListWithAll}
                      value={selectedAccount}
                      handleFilter={(selectedData) => {
                        setSelectedAccount(selectedData);
                      }}
                      size="medium"
                      isLoading={accountListLoading}
                    />
                  </Col>
                  <Col lg={4}>
                    <DateRangePicker
                      setDate={(date) => onDateChange(date)}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={moment().subtract(30, "days").toDate()}
                    />
                  </Col>
                </>
              )}
            </Row>
            {selectedAccountType && (
              <Row>
                <Col lg={2}>
                  <DropdownComponent
                    label={t("Requested By")}
                    placeholder={
                      selectedAccountType?.value === 1 ? t("Select a Super admin") : t("Select a User")
                    }
                    options={requestedByOptions}
                    value={selectedUser}
                    handleFilter={(selectedUser) => {
                      setSelectedUser(selectedUser);
                    }}
                    size="medium"
                    isLoading={getUserListLoading || getSuperAdminListLoading}
                  />
                </Col>

                <Col lg={2}>
                  <DropdownComponent
                    label={t("Report Type")}
                    placeholder={t("Select a report type")}
                    options={[
                      {
                        value: "s3logscsv",
                        label: "S3 Error Logs",
                      },
                    ]}
                    value={{
                      value: "s3logscsv",
                      label: "S3 Error Logs",
                    }}
                    handleFilter={(selectedData) => {
                      handleReportType(selectedData.value);
                    }}
                    size="medium"
                  />
                </Col>
              </Row>
            )}
          </FiltersWrapper>
          {selectedAccountType && (
            <RefreshButtonDiv>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <Trans>Refresh</Trans>
                  </Tooltip>
                }
              >
                <RefreshButton onClick={refreshData}>
                  <RefreshButtonD
                    rotating={isRotating}
                    onClick={onRefresh}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    className="icon icon-update"
                  ></RefreshButtonD>
                </RefreshButton>
              </OverlayTrigger>
            </RefreshButtonDiv>
          )}
        </FiltersWrapperTop>
      </MoreFilterMobileToggle>
      <br />
      <DownloadStatusTableDiv>
        <DownloadStatusTable
          data={statusData}
          rowHeight={430}
          previousPage={previousPage}
          nextPage={nextPage}
          handlePageLength={handlePageLength}
          pageSize={pageSize}
          pageNo={pageNo}
          totalCount={totalCount}
          loading={statusDataLoading}
        />
      </DownloadStatusTableDiv>
    </>
  );
}

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
  userList: state.modalDropdownFilters.userList,
  superAdminList: state.modalDropdownFilters.superAdminList,
  accountListLoading: state.dropdownFilters.accountListLoading,
  getUserListLoading: state.modalDropdownFilters.getUserListLoading,
  getSuperAdminListLoading: state.modalDropdownFilters.getSuperAdminListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey) =>
    dispatch(getUsers(accountId, siteId, dropdownUserAccessLevel, pageNo, pageSize, searchKey)),
  getAllSuperAdmins: () => dispatch(getAllSuperAdmins()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadStatus));
