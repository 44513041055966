import styled from "styled-components";
export const ErrorLogsStyledDiv = styled.div`
  .form-control:hover,
  .input-group input:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  input.form-control,
  textarea.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  input.form-control:focus,
  textarea.form-control:focus {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  input.form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
    &:hover {
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
    }
  }
  input.form-control::placeholder,
  textarea.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }

  .card {
    /* background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;*/
  }
  .tableStyledDiv {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .tableStyledDiv thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    font-size: 28px;
    border: 0px solid;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  }
  .tableStyledDiv thead tr th .error-card-style .card .row .font-semibold {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  tbody.billing-height .accordion .card,
  .collapse .card-body {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .error-descr-div {
    padding-top: 8px;
    padding-bottom: 7px;
  }
  .inputDivStyle {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    border-radius: 5px;
    text-align: left;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .pageRow .selectBoxDiv label,
  .pageRow_Num p,
  .pageRow .selectBoxDiv .selectBox {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .pageRow_Arrow {
    display: flex;
  }
`;
export const ExpandMoreIcon = styled.div`
  color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  font-size: 18px !important;
`;
export const ExpandLessIcon = styled.div`
  color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  font-size: 18px !important;
`;

export const ArrowLeftStyled = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  font-size: 18px !important;
  cursor: pointer;
`;
export const ArrowRightStyled = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  font-size: 18px !important;
  cursor: pointer;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 300px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;