import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SiearTab from "../../../components/TabComponent/SieraTab.Component";

import {
  ChecklistTabBarMenu,
  ChecklistTabSection,
  SectionnameChecklist,
  SectionChecklistTableWrapper,
  RowDiv,
  Column,
  RowsContainer,
} from "./ChecklistOverviewTab.styles";

function ChecklistOverviewTab({ checklistOverview }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0);
  }, [checklistOverview]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <ChecklistTabBarMenu>
      {checklistOverview ? (
        <ChecklistTabSection>
          <SieraTabs activeKey={value} onSelect={handleChange} variant="horizontalNew" defaultActiveKey={0}>
            {checklistOverview.sections &&
              checklistOverview.sections.map((data, i) => (
                <SiearTab eventKey={i} title={data.sectionName} key={i}>
                  <SectionnameChecklist>
                    <span className="headerColor" title={data.sectionName}>
                      {data.sectionName}
                    </span>
                  </SectionnameChecklist>
                  <SectionChecklistTableWrapper>
                    <RowDiv isHeader={true}>
                      <Column isHeader={true} align={"left"}>
                        <Trans>Question Details</Trans>
                      </Column>
                      <Column isHeader={true} align={"left"}>
                        <Trans>Response Type</Trans>
                      </Column>
                      <Column isHeader={true} align={"center"}>
                        <Trans>Enable Ignition</Trans>
                      </Column>
                      <Column isHeader={true} align={"center"}>
                        <Trans>Critical Question</Trans>
                      </Column>
                      <Column isHeader={true} align={"center"}>
                        <Trans>Fail Response</Trans>
                      </Column>
                      <Column isHeader={true} align={"center"}>
                        <Trans>Response Time</Trans>
                      </Column>
                    </RowDiv>

                    <RowsContainer>
                      {data.questions && data.questions[0]
                        ? data.questions.map((question, j) => (
                            <RowDiv key={j}>
                              <Column
                                align={"left"}
                                textleft={"left"}
                                widthResp={"75%"}
                                floatResp={"left"}
                                RmarginResp={"5%"}
                              >
                                <span title={question.questionText}>{question.questionText}</span>
                              </Column>
                              <Column
                                align={"left"}
                                textleft={"left"}
                                widthResp={"75%"}
                                floatResp={"left"}
                                RmarginResp={"5%"}
                              >
                                <span title={question.responseTypeId && question.responseTypeId.responseText}>
                                  {question.responseTypeId && question.responseTypeId.responseText}
                                </span>
                              </Column>
                              <Column
                                align={"center"}
                                textleft={"center"}
                                widthResp={"20%"}
                                floatResp={"none"}
                              >
                                <SwitchComponent checked={data.enableIgnition} disabled={true} />
                                {data.enableIgnition}
                              </Column>
                              <Column
                                align={"center"}
                                textleft={"center"}
                                widthResp={"20%"}
                                floatResp={"none"}
                              >
                                <SwitchComponent
                                  checked={question?.isQuestionMarkAsChecklistFail}
                                  disabled={true}
                                />
                                {question?.isQuestionMarkAsChecklistFail}
                              </Column>
                              <Column
                                align={"center"}
                                textleft={"center"}
                                widthResp={"20%"}
                                floatResp={"none"}
                              >
                                {question?.isQuestionMarkAsChecklistFail && question?.failedResponse}
                              </Column>
                              <Column
                                align={"center"}
                                textleft={"center"}
                                widthResp={"20%"}
                                floatResp={"none"}
                              >
                                {data.sectionQuestionResponseTimeStatus &&
                                  question.questionResponseTime + " seconds"}
                              </Column>
                            </RowDiv>
                          ))
                        : ""}
                    </RowsContainer>
                  </SectionChecklistTableWrapper>
                </SiearTab>
              ))}
          </SieraTabs>
        </ChecklistTabSection>
      ) : (
        "Loading..."
      )}
    </ChecklistTabBarMenu>
  );
}

export default withRouter(ChecklistOverviewTab);
