import styled from "styled-components";

export const ChartDivs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
`;

export const FilterDrop = styled.div`
  width: 30%;
  margin-right: 2%;
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;
