export const defaultInputValues = {
  pedestrian: { pos1: 0, pos2: 0, pos3: 0, pos4: 0, pos5: 0, pos6: 0 },
  object: { pos1: 0, pos2: 0, pos3: 0, pos4: 0, pos5: 0, pos6: 0 },
  sczPedestrian: { pos1: 0, pos2: 0, pos3: 0, pos4: 0, pos5: 0, pos6: 0 },
  sczObject: { pos1: 0, pos2: 0, pos3: 0, pos4: 0, pos5: 0, pos6: 0 },
};

export const noDataObj = {
  header: "S3 No Data Header",
  summary: "S3 No Data Summary",
  steps: [
    {
      text: "S3 No Data Step1",
    },
    {
      text: "S3 No Data Step2",
    },
    {
      text: "S3 No Data Step3",
      isHtml: true,
    },
  ],
};

export const cameraPositionObj = {
  front: { position: "front", isDisabled: true, top: 60, left: 0 },
  right: { position: "right", isDisabled: true, top: 0, left: 105 },
  left: { position: "left", isDisabled: true, top: 123, left: 105 },
  rear: { position: "rear", isDisabled: true, top: 60, left: 198 },
};

export const widthDataConst = [
  {
    type: "pos1",
    min: 500,
    max: 10000,
  },
  {
    type: "pos2",
    min: 500,
    max: 3000,
  },
  {
    type: "pos3",
    min: 500,
    max: 3000,
  },
  {
    type: "pos4",
    min: 500,
    max: 3000,
  },
  {
    type: "pos5",
    min: 500,
    max: 3000,
  },
  {
    type: "pos6",
    min: 500,
    max: 3000,
  },
];

export const widthDataConstFeet = [
  {
    type: "pos1",
    min: 0.49,
    max: 32.8,
  },
  {
    type: "pos2",
    min: 1.64,
    max: 9.84,
  },
  {
    type: "pos3",
    min: 1.64,
    max: 9.84,
  },
  {
    type: "pos4",
    min: 1.64,
    max: 9.84,
  },
  {
    type: "pos5",
    min: 1.64,
    max: 9.84,
  },
  {
    type: "pos6",
    min: 1.64,
    max: 9.84,
  },
];

export const camPositionToCamNumber = {
  rear: 1,
  front: 2,
  left: 3,
  right: 4,
};

export const unselectedCams = [
  {
    isDisabled: false,
    top: 60,
    left: 198,
  },
  {
    isDisabled: false,
    top: 60,
    left: 0,
  },
  {
    isDisabled: false,
    top: 123,
    left: 105,
  },
  {
    isDisabled: false,
    top: 0,
    left: 105,
  },
];
