import styled from "styled-components";

export const HeaderWrapper = styled.div``;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(30deg, #0d5fbe 0%, #0d5fbe 100%);
  min-height: 38px;
  height: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px !important;
  text-align: left;
  @media (max-width: 768px) {
    height: auto;
    font-size: 15px !important;
    padding: 10px;
  }
`;

export const FilterWrapper = styled.div`
  padding: 16px 15px 10px;
  position: relative;
  z-index: 2;
`;

export const LoginRecordsStyle = styled.div`
  margin: 15px auto;
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex: 0 17%;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  button[title="Apply Filter"] {
    padding: initial;
    width: 100%;
  }
`;
