import styled from "styled-components";

export const TabWrapper = styled.div`
  max-width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin: 0;
  }
  .pro-sidebar {
    width: 270px;
    box-shadow: none;
    min-width: 100%;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    z-index: 99;
    /*height: calc(100vh - 160px);*/
    flex: 1;
  }
  .pro-sidebar > .pro-sidebar-inner {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    @media (max-width: 768px) {
      border: 0px;
      margin-bottom: 15px;
    }
  }
  .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
  }
  .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: transparent;
  }
  .pro-sidebar .pro-menu {
    font-family: "Open Sans", sans-serif;
    padding-top: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 16px;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 14px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
    border-bottom: 0px;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
    border-bottom: 0px;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item:active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: none;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item:focus {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: none;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 45px;
  }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item {
    font-size: 15px;
    border-bottom: 0px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(224deg);
    width: 10px;
    height: 10px;
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.active {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 15px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  height: 70px;
  z-index: 100;
  text-align: left;
`;

export const DesktopMenuAccordian = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResponsiveMenuDropdown = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ButtonWrapper = styled.button`
  background: transparent;
  border: 0px;
  height: 44px;
  i {
    font-size: 22px;
    margin: 5px;
    color: white;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;