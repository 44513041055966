import styled from "styled-components";

export const CarouselWrapperDiv = styled.div``;

export const AssetIconconDiv = styled.div`
  display: initial;
  font-size: 20px !important;
  position: relative;
  right: 10px;
  top: 1px;
  color: #676767;
`;

export const StyledCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 12px -7px #323232;
  border-radius: 4px;
`;

export const AssetDiv = styled.div`
  color: #0d5fbe;
  font-size: 24px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`;

export const StyledRow = styled.div``;

export const StyledCol = styled.div`
  margin-top: 5px;
  color: #676767;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
`;
