import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HeaderWrapper, HeaderTitle, FilterWrapper } from "./Header.component.styles";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import { getDropdownAccountList } from "../../../../../redux/dropdownFilters/action";
import { getDropdownWorksiteList } from "../../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../../utils/apiCall";
import {
  setSettingsDropdownAccountId,
  getDropdownAssetList,
  setSettingsDropdownAssetId,
} from "../../../../../redux/dropdownFilters/dropdownActions";
import { setDropdownWorksiteId } from "../../../../../redux/dropdownFilters/action";

const SettingsHeader = (props) => {
  const {
    title,
    getDropdownWorksiteList,
    isSuperAdmin,
    assetList,
    dropdownAccountList,
    worksiteList,
    setLockUnlockRecords,
    pageSize,
    pageNo,
    getWorksiteListLoading,
    getAssetListLoading,
    getDropdownAccountList,
    loginDetails,
    selectedAccountId,
    setIsLoading,
    isAccountOwner,
    isSiteAdmin,
    setSettingsDropdownAccountId,
    isAccountAdmin,
    selectedAccount,
    getDropdownAssetList,
    setSettingsDropdownAssetId,
    setDropdownWorksiteId,
    setPageNo,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);
  const [selectedWorksite, setSelectedWorksite] = useState({ value: "", label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: "", label: "All" });
  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [assetStatus, setAssetStatus] = useState({ value: "", label: "All" });

  /*   useEffect(() => {
    setSettingsDropdownAssetId(selectedAsset?.value);
  }, [selectedAsset]);

  useEffect(() => {
    setDropdownWorksiteId(selectedWorksite?.value);
  }, [selectedWorksite]); */

  const accountId = isSuperAdmin ? selectedAccount?.value : loginDetails?.accountId;

  const getLockUnlockRecords = async (localPageNo) => {
    setIsLoading(true);
    const params = `${accountId && accountId !== "all" ? "&accountId=" + accountId : ""}${selectedWorksite.value ? "&siteId=" + selectedWorksite.value : ""}${selectedAsset.value ? "&assetId=" + selectedAsset.value : ""}${assetStatus.value ? "&event=" + assetStatus.value : ""}`;

    try {
      const resp = await apiCall(
        `/events/lockunlock?pageSize=${pageSize}&pageNo=${localPageNo || pageNo}${params}`,
        {
          method: "GET",
        }
      );
      if (resp.data.success) {
        setLockUnlockRecords(resp?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching login records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPageNo(1);
    if (!isLoading) {
      setTimeout(() => {
        getLockUnlockRecords(1);
      });
    }
  }, [selectedAccount, selectedWorksite, selectedAsset, assetStatus, pageSize]);

  useEffect(() => {
    if (!isLoading) {
      getLockUnlockRecords();
    }
  }, [pageNo]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  useEffect(() => {
    if (selectedAccount) {
      getDropdownWorksiteList(selectedAccount.value);
    }
  }, [selectedAccount, getDropdownWorksiteList, selectedWorksite?.value]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: "", label: "All" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: "", label: "All" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (assetList?.length > 0 && assetList?.[0]?._id !== -1) {
      const optionListTemp = assetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "", label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: "", label: "All" }]);
    }
  }, [assetList]);

  useEffect(() => {
    getDropdownAssetList(selectedAccountId, selectedWorksite?.value, null);
  }, [selectedWorksite, getDropdownAssetList, selectedAccountId]);

  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>

      <FilterWrapper>
        <Row>
          <Col md={4}>
            <DropdownComponent
              label={t("Worksite")}
              placeholder={t("Select a worksite")}
              options={worksitesListWithAll}
              value={selectedWorksite}
              handleFilter={(option) => {
                setSelectedWorksite(option);
              }}
              errorMessage={t("This field is required")}
              size="medium"
              isLoading={getWorksiteListLoading}
            />
          </Col>

          {/* Date Range Picker */}
          <Col lg={4}>
            <DropdownComponent
              label={t("Assets")}
              placeholder={t("Select an Asset")}
              options={assetListWithAll}
              value={selectedAsset}
              handleFilter={(asset) => {
                setSelectedAsset(asset);
              }}
              size="medium"
              isLoading={getAssetListLoading}
            />
          </Col>
          <Col lg={4}>
            <DropdownComponent
              label={t("Asset Status")}
              placeholder={t("Select a status")}
              options={[
                { value: "", label: t("All") },
                { value: "lock", label: t("Locked") },
                { value: "unlock", label: t("Unlocked") },
              ]}
              value={assetStatus}
              handleFilter={setAssetStatus}
              size="medium"
            />
          </Col>
        </Row>
      </FilterWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
  assetList: state.dropdownFilters.assetList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  getAssetListLoading: state.modalDropdownFilters.getAssetListLoading,
  accountListLoading: state.dropdownFilters.accountListLoading,
  currentTimeZone: state.settings.currentTimeZone,
  isSuperAdmin: state.user.isSuperAdmin,
  loginDetails: state.user.loginDetails,
  selectedAccountId: state.deviceInfo.selectedAccountId,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  dropdownAssetList: state.dropdownFilters?.settings?.assetList,
  dropdownAssetId: state.dropdownFilters.settings?.assetId,
  assetListLoading: state.dropdownFilters?.settings?.assetListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAssetList: (selectedAccountId, selectedWorksite) =>
    dispatch(getDropdownAssetList(selectedAccountId, selectedWorksite)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setSettingsDropdownAssetId: (assetId) => dispatch(setSettingsDropdownAssetId(assetId)),
});

SettingsHeader.defaultProps = {
  showFilters: true,
  handleAssetIdFilter: () => {},
  handleAssetTypeIdFilter: () => {},
  handleWorksiteFilter: () => {},
  showWorksiteFilter: true,
  showAssetTypeFilter: true,
  showAssetFilter: true,
  showUserTypeFilter: false,
  showUserFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsHeader);
