import React from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { CarouselWrapperDiv } from "./WorksiteCard.component.styles";
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const carouselData = [
  {
    name: "Worksites",
    iconClass: "worksites",
    feildName: "sites",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
  {
    name: "Assets",
    iconClass: "assets",
    feildName: "assets",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
  {
    name: "Users",
    iconClass: "users",
    feildName: "users",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
];

const WorksiteCard = ({ count }) => {
  return (
    <>
      <CarouselWrapperDiv>
        <CarouselComponent
          responsive={responsive}
          countData={count}
          carouselData={carouselData}
          size="small"
          pagination={false}
        />
      </CarouselWrapperDiv>
    </>
  );
};

const mapStateToProps = (state) => ({
  count: state.counts.allCount, // Make sure count is correctly mapped from state
});

export default connect(mapStateToProps, null)(WorksiteCard);
