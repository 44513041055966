import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import MarketingSlider from "../../components/MarketingCarouselSlider/MarketingSlider.component";
import image1 from "../../assets/MarketingCarousel/Carousel_1.jpg";
import image2 from "../../assets/MarketingCarousel/Carousel_2.jpg";
import image3 from "../../assets/MarketingCarousel/Carousel_3.jpg";

import LoginRequestAccessTabs from "./LoginRequestAccessTabs/LoginRequestAccessTabs.component";

// Material UI
// Tabs

const Home = (props) => {
  // Setting Tab Value
  const [value, setValue] = React.useState(0);

  // Setting Header Text State
  const [header, setHeader] = useState({ status: "login" });

  // Tab Value Change Handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Row noGutters>
        <Col xs={12} md={12} lg={8} xlg={8}>
          <MarketingSlider
            handleChange={handleChange}
            setHeader={setHeader}
            items={[
              {
                buttonType: "secondary",
                label: "Request a Demo",
                onClick: (e) => {
                  e.preventDefault();
                  window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
                },
                src: image1,
              },
              {
                buttonType: "primary",
                label: "Start Inspecting for FREE",
                onClick: (e) => {
                  e.preventDefault();
                  window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
                },
                src: image2,
              },
              {
                buttonType: "primary",
                label: "Start Inspecting for FREE",
                onClick: (e) => {
                  e.preventDefault();
                  window.open("https://share.hsforms.com/1-5bHObFcRS22mO1IvA253g2iwjd", "_blank");
                },
                src: image3,
              },
            ]}
          />
        </Col>
        <Col xs={12} md={12} lg={4} xlg={4} className="height_Overflow">
          <LoginRequestAccessTabs
            props={props}
            handleChange={handleChange}
            header={header}
            value={value}
            setHeader={setHeader}
          />
        </Col>
      </Row>
    </>
  );
};

export default Home;
