import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { toValidatePassword, toValidateConfirmPassword } from "../../../../utils/validations/checkValidation";
import { getRandomPassword } from "../../../../utils";
import CustomInputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";
import { generatePasswordRegex } from "../../../../utils/validations/utilities";
import PasswordErrorMessages from "../../../../components/password/PasswordErrorMessages";

const DashboardLogin = (props) => {
  const { validated, formData, setFormData, passwordPolicy, userAccountId } = props;
  const { t } = useTranslation();
  const [passwordRegex, setPasswordRegex] = useState({});

  const handlePasswordChange = (e) => {
    setFormData({
      ...formData,
      password: e?.target?.value?.replace(/\s+/g, ""),
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setFormData({
      ...formData,
      passwordVerification: e.target.value?.replace(/\s+/g, ""),
    });
  };

  const handleAutoGeneratePassword = (e) => {
    let newPassword = getRandomPassword(passwordPolicy?.minLength || 8);
    let { autoGeneratePassword } = formData;
    setFormData({
      ...formData,
      autoGeneratePassword: !autoGeneratePassword,
      password: !autoGeneratePassword ? newPassword : "",
      passwordVerification: !autoGeneratePassword ? newPassword : "",
    });
  };

  useEffect(() => {
    if (passwordPolicy) {
      const passwordRegex = generatePasswordRegex(passwordPolicy);
      setPasswordRegex(passwordRegex);
    }
  }, [passwordPolicy]);

  return (
    <>
      <Col>
        <input
          checked={formData.autoGeneratePassword}
          onChange={(e) => handleAutoGeneratePassword(e)}
          name="autogeneratePassword"
          type="checkbox"
          style={{ marginRight: "5px" }}
        />
        <label for="autogeneratePassword">
          <Trans>Auto-generate Password</Trans>?
        </label>
      </Col>
      <Col className="asterisk_form">
        <CustomInputField
          label="Password"
          required
          id="password"
          placeholder="Password"
          variant="standard"
          type="password"
          name="password"
          autocomplete="one-time-code"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,64}$"
          onChange={(e) => handlePasswordChange(e)}
          isInvalid={validated && toValidatePassword(formData.password, passwordRegex)}
          feedback={validated && t(toValidatePassword(formData.password, passwordRegex))}
          value={formData.password}
          isError={validated && toValidatePassword(formData.password, passwordRegex)}
          //errorMessage={validated && toValidatePassword(formData.password, passwordRegex)}
        />
        {validated && toValidatePassword(formData.password, passwordRegex) && (
          <PasswordErrorMessages
            hideIcon={true}
            isError={!!toValidatePassword(formData.password, passwordRegex)}
            userAccountId={userAccountId}
          />
        )}
      </Col>

      <Col className="asterisk_form">
        <CustomInputField
          label={t("Confirm Password")}
          id="passwordVerification"
          placeholder={t("Confirm Password")}
          variant="standard"
          type="password"
          name="passwordVerification"
          autocomplete="one-time-code"
          onChange={(e) => handleConfirmPasswordChange(e)}
          isInvalid={validated && toValidateConfirmPassword(formData.password, formData.passwordVerification)}
          feedback={
            validated && t(toValidateConfirmPassword(formData.password, formData.passwordVerification))
          }
          value={formData.passwordVerification}
          isError={validated && toValidateConfirmPassword(formData.password, formData.passwordVerification)}
          errorMessage={
            validated && t(toValidateConfirmPassword(formData.password, formData.passwordVerification))
          }
        />
      </Col>
    </>
  );
};
const mapStateToProps = (state) => ({
  passwordPolicy: state?.accounts?.passwordPolicy,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLogin);
