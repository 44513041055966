import { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import {
  SelectedItemsWrapper,
  SelectedItem,
  RemoveItem,
  SelectedItemText,
  SelectItemWrapper,
  ClearAllbuttom,
  ClearAllButtonWrapper,
} from "./ApplyToAllModal.styles";
import SimpleDropdownFilter from "../../../../components/SimpleDropdownFilter.component";
import { getDropdownWorksiteList } from "../../../../redux/dropdownFilters/action";
import {
  getUsersByWorksites,
  getAssetTypesByWorksites,
  getAssetsByAssetType,
} from "../../../../redux/users/action";

const Dropdown = memo((props) => {
  const {
    option,
    checkedOptions,
    setCheckedOptions,
    worksiteList,
    assetTypeList,
    assetList,
    selectedItems,
    setSelectedItems,
    users,
    getDropdownWorksiteList,
    getAssetTypesByWorksites,
    setReducedDropdownItem,
    getAssetsByAssetType,
    getUsersByWorksitesAndUserTypes,
    assetTypesBySitesLoading,
    assetsByAssetTypesLoading,
    usersBySitesLoading,
    pageType,
    setAccountLevelPermissionSelected,
    isAssetPermission,
    userTypeDetailList,
  } = props;

  const [optionList, setOptionList] = useState([]);
  const [dropdownList, setDropdownList] = useState([]);
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    setUserTypes(
      userTypeDetailList?.map((type) => ({
        label: type.name,
        _id: type._id,
        accessLevel: type.accessLevel,
      }))
    );
  }, [userTypeDetailList]);

  useEffect(() => {
    if (checkedOptions?.[option.radioGroup]?.all) {
      setSelectedItems({
        ...selectedItems,
        [option.radioGroup]: {
          selectedItems: [],
        },
      });
    }
  }, [checkedOptions]);

  const isAccountPermissionSelected = (allSelectedItemsObj) => {
    const selectedItemsObj =
      allSelectedItemsObj?.userTypes?.selectedItems || selectedItems?.userTypes?.selectedItems;

    return !!selectedItemsObj?.find((item) => item?.accessLevel === "ALL_WORKSITES");
  };

  const isWorksitePermissionSelected = (allSelectedItemsObj) => {
    const selectedItemsObj =
      allSelectedItemsObj?.userTypes?.selectedItems || selectedItems?.userTypes?.selectedItems;
    return !!selectedItemsObj?.find(
      (item) => item?.accessLevel === "SINGLE_WORKSITE" || item?.value === "INDIVIDUAL_EMPLOYEE"
    );
  };

  const setOrderedOptionList = (filteredOptions, allSelectedItemsObj) => {
    if (option.radioGroup !== "userTypes") {
      filteredOptions.sort((a, b) => a.label?.localeCompare(b?.label));
      setOptionList(filteredOptions);
    } else {
      const isAccountPermission = isAccountPermissionSelected(allSelectedItemsObj);
      setAccountLevelPermissionSelected(isAccountPermission);
      //Clear worksites and users
      if (isAccountPermission || selectedItems?.userTypes?.selectedItems?.length === 0) {
        if (!checkedOptions?.userTypes?.all && allSelectedItemsObj) {
          const updatedSelectedItems = {
            ...allSelectedItemsObj,
            worksites: {
              selectedItems: [],
            },
            users: {
              selectedItems: [],
            },
          };
          if (JSON.stringify(selectedItems) !== JSON.stringify(updatedSelectedItems)) {
            setSelectedItems(updatedSelectedItems);
          }
        }
      }
      const updatedOptions = filteredOptions.map((option) => {
        if (
          isAccountPermissionSelected(allSelectedItemsObj) &&
          (option?.accessLevel === "SINGLE_WORKSITE" || option?.accessLevel === "INDIVIDUAL_EMPLOYEE")
        ) {
          return { ...option, isDisabled: true };
        } else if (
          isWorksitePermissionSelected(allSelectedItemsObj) &&
          option?.accessLevel === "ALL_WORKSITES"
        ) {
          return { ...option, isDisabled: true };
        }
        return option;
      });
      setOptionList(updatedOptions);
    }
  };

  useEffect(() => {
    if (!worksiteList || worksiteList.length === 0 || isAssetPermission)
      getDropdownWorksiteList(isAssetPermission);
  }, []);

  useEffect(() => {
    if (dropdownList?.length > 0) {
      const dropdonwOptions = getDropdownOptions();
      const unselectedItems = dropdonwOptions.filter(
        (dOption) =>
          !selectedItems?.[option.radioGroup]?.selectedItems?.find((item) => dOption.value === item.value)
      );
      setOrderedOptionList(unselectedItems);
    } else {
      setOptionList([]);
    }
  }, [selectedItems, dropdownList]);

  const getIndividualEmployees = () => {
    return userTypes.filter((userType) => userType.accessLevel === "INDIVIDUAL_EMPLOYEE");
  };

  useEffect(() => {
    if (option?.isCheckedKey && option?.isCheckedKey === "selected" && option.radioGroup === "worksites") {
      setDropdownList(worksiteList);
    } else if (
      option?.isCheckedKey &&
      option?.isCheckedKey === "selected" &&
      option.radioGroup === "assetTypes"
    ) {
      setDropdownList(assetTypeList || []);
    } else if (
      option?.isCheckedKey &&
      option?.isCheckedKey === "selected" &&
      option.radioGroup === "assets"
    ) {
      setDropdownList(assetList || []);
    } else if (option?.isCheckedKey && option?.isCheckedKey === "selected" && option.radioGroup === "users") {
      setDropdownList(users || []);
    } else if (
      option?.isCheckedKey &&
      option?.isCheckedKey === "selected" &&
      option.radioGroup === "userTypes"
    ) {
      if (pageType === "assetPermissions") {
        setDropdownList(getIndividualEmployees());
      } else {
        setDropdownList(userTypes || []);
      }
    }
  }, [worksiteList, assetTypeList, users, option, assetList]);

  const getDropdownOptions = () => {
    return dropdownList?.map((item) => ({
      label: item[option.labelKey],
      value: item._id,
      isDisabled: !!item.isDisabled,
      accessLevel: item?.accessLevel,
    }));
  };

  const getDataBasedOnSelection = (items) => {
    if (option.radioGroup === "worksites") {
      getUpdatedDataForWorksiteIds(items);
      const selectedPermissionIds = selectedItems?.userTypes?.selectedItems?.map(
        (selecedItems) => selecedItems.value
      );
      const worksiteIds = items?.map((item) => item.value);
      getUsersByWorksitesAndUserTypes(worksiteIds, selectedPermissionIds, isAssetPermission);

      if (
        checkedOptions?.assetTypes?.all ||
        (checkedOptions?.assetTypes?.selected && selectedItems?.assetTypes?.selectedItems?.length > 0)
      ) {
        const selectedIds = selectedItems?.assetTypes?.selectedItems?.map((item) => item.value);
        getAssetsByAssetType({ siteIds: worksiteIds || "all", assetTypeIds: selectedIds }, isAssetPermission);
      }

      if (
        checkedOptions?.userTypes?.all ||
        (checkedOptions?.userTypes?.selected && selectedItems?.userTypes?.selectedItems?.length > 0)
      ) {
        const selectedIds = selectedItems?.userTypes?.selectedItems?.map((item) => item.value);
        getUsersByWorksitesAndUserTypes(worksiteIds || "all", selectedIds, isAssetPermission);
      }
    } else if (option.radioGroup === "assetTypes") {
      const selectedIds = items?.map((item) => item.value);
      const selectedSites = selectedItems?.worksites?.selectedItems?.map(
        (selecedItems) => selecedItems.value
      );
      getAssetsByAssetType({ siteIds: selectedSites || "all", assetTypeIds: selectedIds }, isAssetPermission);
    } else if (option.radioGroup === "userTypes") {
      const selectedIds = items?.map((item) => item.value);
      const worksiteIds = selectedItems?.worksites?.selectedItems?.map((item) => item.value);
      getUsersByWorksitesAndUserTypes(worksiteIds, selectedIds, isAssetPermission);
    }
  };

  const handleFilter = (data) => {
    const selected = selectedItems?.[option.radioGroup]?.selectedItems || [];
    const selectedItemsTemp = [...selected, data];

    selectedItemsTemp?.sort((a, b) => a?.label?.localeCompare(b.label));

    const allSelectedItemsObj = {
      ...selectedItems,
      [option.radioGroup]: { selectedItems: selectedItemsTemp },
    };
    setSelectedItems(allSelectedItemsObj);

    const filteredOptions = optionList.filter((option) => {
      return option.value !== data.value;
    });
    setOrderedOptionList(filteredOptions, allSelectedItemsObj);

    getDataBasedOnSelection(selectedItemsTemp);
  };

  const removeHandler = (item, radioGroup) => {
    const selectedItemsTemp = selectedItems?.[radioGroup]?.selectedItems?.filter(
      (selectedItem) => selectedItem.value !== item.value
    );

    let allSelectedItemsObj = selectedItems;
    if (radioGroup === "userTypes") {
      setCheckedOptions({
        ...checkedOptions,
        worksites: { all: false, selected: false },
        users: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        worksites: {
          selectedItems: [],
        },
        users: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "worksites") {
      setCheckedOptions({
        ...checkedOptions,
        assetTypes: { all: false, selected: false },
        assets: { all: false, selected: false },
        users: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        assetTypes: {
          selectedItems: [],
        },
        assets: {
          selectedItems: [],
        },
        users: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "assetTypes") {
      setCheckedOptions({
        ...checkedOptions,
        assets: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        assets: {
          selectedItems: [],
        },
      };
    }
    setOrderedSelectedItemsList(allSelectedItemsObj, selectedItemsTemp, radioGroup);
    setOrderedOptionList([...optionList, item]);
    /*  if (radioGroup === "worksites") {
      getUpdatedDataForWorksiteIds(selectedItems[radioGroup]?.selectedItems);
    } */
    setReducedDropdownItem(radioGroup);
    getDataBasedOnSelection(selectedItemsTemp);
  };

  const getUpdatedDataForWorksiteIds = (selectedItems) => {
    const siteIDs = [];
    //selectedItems?.worksites?.selectedItems?.forEach((item) => siteIDs.push(item.value));
    selectedItems?.forEach((item) => siteIDs.push(item.value));

    //Make backend call to retrive Users
    //getUsersByWorksites(siteIDs);
    //Make backend call to retrive Asset Types
    getAssetTypesByWorksites(siteIDs);
  };

  const clearAllHandler = () => {
    const radioGroup = option.radioGroup;
    let allSelectedItemsObj = {};
    if (radioGroup === "userTypes") {
      setCheckedOptions({
        ...checkedOptions,
        worksites: { all: false, selected: false },
        users: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        userTypes: {
          selectedItems: [],
        },
        worksites: {
          selectedItems: [],
        },
        users: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "worksites") {
      setCheckedOptions({
        ...checkedOptions,
        assetTypes: { all: false, selected: false },
        assets: { all: false, selected: false },
        users: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        worksites: {
          selectedItems: [],
        },
        assetTypes: {
          selectedItems: [],
        },
        assets: {
          selectedItems: [],
        },
        users: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "assetTypes") {
      setCheckedOptions({
        ...checkedOptions,
        assets: { all: false, selected: false },
      });

      allSelectedItemsObj = {
        ...selectedItems,
        assetTypes: {
          selectedItems: [],
        },
        assets: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "assets") {
      allSelectedItemsObj = {
        ...selectedItems,
        assets: {
          selectedItems: [],
        },
      };
    } else if (radioGroup === "users") {
      allSelectedItemsObj = {
        ...selectedItems,
        users: {
          selectedItems: [],
        },
      };
    }

    setSelectedItems({
      ...selectedItems,
      ...allSelectedItemsObj,
    });
    setOrderedOptionList(getDropdownOptions());
    setReducedDropdownItem(option.radioGroup);
  };

  const setOrderedSelectedItemsList = (allSelectedItemsObj, filteredOptions, radioGroup) => {
    filteredOptions?.sort((a, b) => a?.label?.localeCompare(b.label));
    setSelectedItems({
      ...allSelectedItemsObj,
      [radioGroup]: {
        selectedItems: filteredOptions,
      },
    });
  };

  return (
    <SelectItemWrapper>
      <SimpleDropdownFilter
        useAsDropdown={true}
        options={optionList}
        placeholder={option?.dropdown?.placeholder}
        handleFilter={handleFilter}
        disabled={!checkedOptions?.[option.radioGroup]?.[option.isCheckedKey]}
        isLoading={
          (option.radioGroup === "assetTypes" && assetTypesBySitesLoading) ||
          (option.radioGroup === "assets" && assetsByAssetTypesLoading) ||
          (option.radioGroup === "users" && usersBySitesLoading)
        }
      />
      {checkedOptions?.[option.radioGroup]?.[option.isCheckedKey] &&
        selectedItems?.[option.radioGroup]?.selectedItems?.length > 0 && (
          <SelectedItemsWrapper>
            <ClearAllButtonWrapper>
              <ClearAllbuttom onClick={clearAllHandler}>Clear All</ClearAllbuttom>
            </ClearAllButtonWrapper>
            {selectedItems?.[option.radioGroup]?.selectedItems.map((item, index) => {
              return (
                <SelectedItem key={index}>
                  <SelectedItemText>{item.label}</SelectedItemText>
                  <RemoveItem onClick={() => removeHandler(item, option.radioGroup)}>X</RemoveItem>
                </SelectedItem>
              );
            })}
          </SelectedItemsWrapper>
        )}
    </SelectItemWrapper>
  );
});

const mapStateToProps = (state) => ({
  worksiteList: state?.dropdownFilters?.settings?.worksiteList,
  users: state.users.usersBySites,
  assetTypeList: state.users.assetTypesBySites,
  assetList: state.users.assetsByAssetTypes,
  assetTypesBySitesLoading: state.users.assetTypesBySitesLoading,
  assetsByAssetTypesLoading: state.users.assetsByAssetTypesLoading,
  usersBySitesLoading: state.users.usersBySitesLoading,
  userTypeDetailList: state.userType.userTypeDetailList,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (isAssetPermission) => dispatch(getDropdownWorksiteList(isAssetPermission)),
  getUsersByWorksites: (sites, permissions, isAssetPermission) =>
    dispatch(getUsersByWorksites(sites, permissions, isAssetPermission)),
  getAssetTypesByWorksites: (siteIDs) => dispatch(getAssetTypesByWorksites(siteIDs)),
  getUsersByWorksitesAndUserTypes: (sites, permissions, isAssetPermission) =>
    dispatch(getUsersByWorksites(sites, permissions, isAssetPermission)),
  getAssetsByAssetType: (data, isAssetPermission) => dispatch(getAssetsByAssetType(data, isAssetPermission)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
