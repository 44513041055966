import { configTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

// To update worksite auto logout setting loading
export const updateWorksiteAutoLogoutSettingLoading = () => ({
  type: configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_LOADING,
});

// To update worksite auto logout setting success
export const updateWorksiteAutoLogoutSettingSuccess = (data) => ({
  type: configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS,
  data: data,
});

// To update worksite auto logout setting error
export const updateWorksiteAutoLogoutSettingError = () => ({
  type: configTypes.UPDATE_WORKSITE_AUTO_LOGOUT_SETTING_ERROR,
});

export const resetWorksiteAutoLogoutSettingState = () => ({
  type: configTypes.RESET_WORKSITE_AUTO_LOGOUT_SETTING,
});

//To get worksite Auto Logout setting loading
export const getWorksiteAutoLogoutSettingLoading = () => ({
  type: configTypes.GET_WORKSITE_AUTO_LOGOUT_SETTING_LOADING,
});

//To get worksite Auto Logout setting success
export const getWorksiteAutoLogoutSettingSuccess = (data) => ({
  type: configTypes.GET_WORKSITE_AUTO_LOGOUT_SETTING_SUCCESS,
  data: data,
});

//To get worksite Auto Logout setting error
export const getWorksiteAutoLogoutSettingError = () => ({
  type: configTypes.GET_WORKSITE_AUTO_LOGOUT_SETTING_ERROR,
});

/* // To get worksite Auto Logout settings
export const getWorksiteAutoLogoutSetting = (worksiteId) => {
  return async(dispatch, getState) => {
    dispatch(getWorksiteAutoLogoutSettingLoading());
    apiCall(`/site/autoLogoutSettings/${worksiteId}`)
      .then((response) => {
        dispatch(getWorksiteAutoLogoutSettingSuccess(response.data.data));
      })
      .catch((err) => {
        console.log("getWorksiteAutoLogoutSetting -- Failed", err);
        dispatch(getWorksiteAutoLogoutSettingError());
      });
  };
}; */

export const getAssetAutoLogoutSettings = (assetId) => {
  return async (dispatch, getState) => {
    dispatch(getWorksiteAutoLogoutSettingLoading());
    apiCall(`/systemSettings/autoLogoutSettings/${assetId}`)
      .then((response) => {
        dispatch(getWorksiteAutoLogoutSettingSuccess(response.data.data.autoLogoutSettings));
      })
      .catch((err) => {
        console.log("getAssetAutoLogoutSettings -- Failed", err);
        dispatch(getWorksiteAutoLogoutSettingError());
      });
  };
};

export const updateAssetAutoLogoutSettingsByAssetId = (assetId, data, callbackAfterUpdate) => {
  return async (dispatch) => {
    dispatch(updateWorksiteAutoLogoutSettingLoading());
    apiCall(`/systemSettings/autoLogoutSettings/${assetId}`, {
      method: "PUT",
      data: { autoLogoutSettings: data },
    })
      .then((response) => {
        callbackAfterUpdate(true);
        dispatch(getWorksiteAutoLogoutSettingSuccess(response.data.data.autoLogoutSettings));
      })
      .catch((err) => {
        console.log("updateAssetAutoLogoutSettingsByAssetId Failed", err);
        dispatch(getWorksiteAutoLogoutSettingError());
      });
  };
};

export const resetAssetAutoLogoutSettingsByAssetId = (assetId, callbackAfterUpdate) => {
  callbackAfterUpdate(true);
  return async (dispatch) => {
    apiCall(`/systemSettings/autoLogoutSettings/${assetId}`, {
      method: "PATCH",
    })
      .then((response) => {
        callbackAfterUpdate(false);
        dispatch(getWorksiteAutoLogoutSettingSuccess(response.data.data));
      })
      .catch((err) => {
        callbackAfterUpdate(false);
        console.log("resetAssetAutoLogoutSettingsByAssetId Failed", err);
        dispatch(getWorksiteAutoLogoutSettingError());
      });
  };
};

/** End of Auto logout actions */
