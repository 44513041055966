// WrapperInputField.js
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DropdownComponent from "../../../../../components/DropdownComponent/DropdownFilterWrapper";

const GeneralDropdownContainer = styled.div`
  display: block;
  margin: 10px 0px;
  overflow: hidden;
  @media (max-width: 1100px) {
    padding: 0px 10px;
  }
`;

const LeftLabel = styled.div`
  float: left;
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const RightInput = styled.div`
  float: right;
  width: 40%;
  .select__control {
    width: 99%;
    margin-left: 2px;
  }
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const SpanTop = styled.div`
  text-align: left;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
const SpanBottom = styled.div`
  text-align: left;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

const GeneralDropdownField = ({ leftLabel, lineDivider, rightLabel, ...dropdownProps }) => {
  return (
    <>
      <GeneralDropdownContainer>
        <LeftLabel>
          <SpanTop>{leftLabel}</SpanTop>
          <SpanBottom>{rightLabel && <span>{rightLabel}</span>}</SpanBottom>
        </LeftLabel>

        <RightInput>
          <DropdownComponent classname="inputWidth" size="medium" {...dropdownProps} />
        </RightInput>
      </GeneralDropdownContainer>

      {lineDivider && <Divider></Divider>}
    </>
  );
};

GeneralDropdownField.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  lineDivider: PropTypes.bool,
};

export default GeneralDropdownField;
