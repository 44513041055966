// Library Imports
import React, { useState, useEffect } from "react";

// Custom Component Imports
import BreadCrumb from "../../../components/BreadCrumb.component";
import ManagementTabBar from "./ManagementTabBar.component";
import AccountPanel from "./panels/accountPanel.component";
import WorksitePanel from "./panels/worksite/worksitePanel.component";
import AssetPanel from "./panels/assetPanel.component";
import AssetType from "../Asset/ViewAssetTypesManagement.component";
import UserPanel from "./panels/userPanel.component";
import EditAlertModal from "../Modals/EditAlertModal";

// Redux Imports
import { setManagementActionBar, setShowFilters } from "../../../redux/mobileView/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setAddAssetModal, setAddUserModal, setModalWaitingToOpen } from "../../../redux/modals/action";
import { updateMenu } from "../../../redux/sideMenu/menu.action";
import UserTypesManagement from "./userTypes/UserTypesManagement.component";
import ViewAssetTypesManagement from ".././Asset/ViewAssetTypesManagement.component";

function ManagementTabsNew({
  setManagementActionBar,
  setShowFilters,
  activeTab,
  updateActiveNavItem,
  isSuperAdmin,
  isAccountOwner,
  isAccountAdmin,
  isSiteAdmin,
  setModalWaitingToOpen,
  modalWaitingToOpen,
  worksiteLoading,
  updateMenu,
  setAddAssetModal,
  setAddUserModal,
}) {
  const [activeTabLocal, setActiveTabLocal] = useState(activeTab);

  useEffect(() => {
    if (worksiteLoading) {
      return;
    }
    if (modalWaitingToOpen && modalWaitingToOpen === "Asset") {
      setActiveTabLocal("assets");
      updateActiveNavItem("assets");
      setAddAssetModal(true);
    } else if (modalWaitingToOpen && modalWaitingToOpen === "User") {
      setActiveTabLocal("users");
      updateActiveNavItem("users");
      setAddUserModal(true);
    }
    setModalWaitingToOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksiteLoading]);

  useEffect(() => {
    setManagementActionBar(false);
    setShowFilters(false);
    setActiveTabLocal(activeTab);
    if (
      activeTab === "accounts" ||
      activeTab === "worksites" ||
      activeTab === "assets" ||
      activeTab === "assetType" ||
      activeTab === "users" ||
      activeTab === "userType"
    ) {
    } else {
      if (isSuperAdmin) {
        setActiveTabLocal("accounts");
      }
      if (isAccountAdmin || isAccountOwner) {
        setActiveTabLocal("worksites");
      }
      if (isSiteAdmin) {
        setActiveTabLocal("assets");
      }
    }
  }, [activeTab]);

  return (
    <>
      {/* Breadcrum */}
      <div>
        <BreadCrumb />
      </div>

      {/* Management Tabs */}
      <div>
        <ManagementTabBar />
      </div>

      {/* Edit Alert Modal */}
      <div>
        <EditAlertModal />
      </div>

      {/* Management Sections */}
      <div>
        {activeTabLocal === "accounts" && <AccountPanel />}
        {activeTabLocal === "worksites" && <WorksitePanel />}
        {activeTabLocal === "assets" && <AssetPanel />}
        {activeTabLocal === "assetType" && <AssetType />}
        {activeTabLocal === "users" && <UserPanel />}
        {activeTabLocal === "userType" && <UserTypesManagement />}
        {/* {activeTabLocal === "assetType" && <ViewAssetTypesManagement />} */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
  activeTab: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isAccountAdmin: state.user.isAccountAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  worksiteLoading: state.worksites.AddWorksiteLoading,
  modalWaitingToOpen: state.modals.modalWaitingForOpen,
});

const mapDispatchToProps = (dispatch) => ({
  setManagementActionBar: (data) => dispatch(setManagementActionBar(data)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementTabsNew));
