import { worksiteTypes } from "./types";
import { initialState } from "./initialState";

export const worksitesReducer = (state = initialState, action) => {
  switch (action.type) {
    // For Worksites List
    case worksiteTypes.GET_ALL_WORKSITES_LOADING:
      return {
        ...state,
        WorksiteLoading: true,
        WorksiteSuccess: false,
        WorksiteError: false,
      };

    case worksiteTypes.GET_ALL_WORKSITES_SUCCESS:
      if (state?.Worksites?.length == action.data.count && !action.isResetState) {
        return {
          ...state,
          WorksiteLoading: false,
          WorksiteSuccess: true,
          WorksiteError: false,
        };
      }
      let newWorksites =
        action.isResetState || action.pageNo == 1
          ? action.data.data
          : [...state.Worksites, ...action.data.data];
      return {
        ...state,
        WorksiteLoading: false,
        WorksiteSuccess: true,
        WorksiteError: false,
        totalCount: action.data.count,
        Worksites: newWorksites,
      };

    case worksiteTypes.GET_ALL_WORKSITES_ERROR:
      return {
        ...state,
        WorksiteLoading: false,
        WorksiteSuccess: false,
        WorksiteError: true,
      };

    // Getting worksite detail
    case worksiteTypes.GET_WORKSITE_DETAIL_LOADING:
      return {
        ...state,
        worksiteDetailSuccess: false,
        worksiteDetailLoading: true,
        worksiteDetailError: false,
      };

    case worksiteTypes.GET_WORKSITE_DETAIL_SUCCESS:
      return {
        ...state,
        worksiteDetailSuccess: true,
        worksiteDetailLoading: false,
        worksiteDetailError: false,
        WorksiteOverview: action.data,
      };

    case worksiteTypes.GET_WORKSITE_DETAIL_ERROR:
      return {
        worksiteDetailSuccess: false,
        worksiteDetailLoading: false,
        worksiteDetailError: true,
      };

    // For Adding New Worksite
    case worksiteTypes.POST_NEW_WORKSITE_LOADING:
      return {
        ...state,
        AddWorksiteLoading: true,
        AddWorksiteSuccess: false,
        AddWorksiteError: false,
      };

    case worksiteTypes.POST_NEW_WORKSITE_SUCCESS:
      return {
        ...state,
        AddWorksiteLoading: false,
        AddWorksiteSuccess: true,
        AddWorksiteError: false,
        totalCount: (state.totalCount ? state.totalCount : state.Worksites) + 1,
        Worksites: [...state.Worksites, action.data.data],
        WorksiteMessage: action.data && action.data.message,
        addWorksiteSuccessFlag: true,
      };

    case worksiteTypes.RESET_WORKSITES_SUCCESS_FLAG:
      return {
        ...state,
        addWorksiteSuccessFlag: false,
      };

    case worksiteTypes.POST_NEW_WORKSITE_ERROR:
      return {
        ...state,
        AddWorksiteLoading: false,
        AddWorksiteSuccess: false,
        AddWorksiteError: true,
        WorksiteMessage: action.data && action.data.message,
      };

    // For Worksite Status
    case worksiteTypes.WORKSITE_STATUS_UPDATE_LOADING:
      return {
        ...state,
        StatusLoading: true,
        StatusSuccess: false,
        StatusError: false,
      };

    case worksiteTypes.WORKSITE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: true,
        StatusError: false,
        StatusMessage: action.data && action.data.message,
        Worksites: state.Worksites.map((worksite) => {
          if (worksite._id === action.data.id) {
            return {
              ...worksite,
              status: action.data.status,
            };
          }
          return worksite;
        }),
      };

    case worksiteTypes.WORKSITE_STATUS_UPDATE_ERROR:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: false,
        StatusError: true,
      };

    // For Worksite Details Update
    case worksiteTypes.WORKSITE_DETAILS_UPDATE_LOADING:
      return {
        ...state,
        UpdateLoading: true,
        UpdateSuccess: false,
        UpdateError: false,
      };

    case worksiteTypes.WORKSITE_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: true,
        UpdateError: false,
        UpdateMessage: action.data && action.data.message,
        Worksites: state.Worksites.map((worksite) => {
          if (worksite._id === action.data.id) {
            return {
              ...worksite,
              manager: action.data.details.manager,
              address: action.data.details.address,
              timeZone: action.data.details.timeZone,
              accountId: action.data.details.accountId,
              shiftSettings: action.data.details.shiftSettings,
            };
          }
          return worksite;
        }),
      };

    case worksiteTypes.WORKSITE_DETAILS_UPDATE_ERROR:
      return {
        ...state,
        UpdateLoading: false,
        UpdateSuccess: false,
        UpdateError: true,
        UpdateMessage: action.data && action.data.message,
      };

    // For Worksite name Update
    case worksiteTypes.WORKSITE_NAME_UPDATE_LOADING:
      return {
        ...state,
        UpdateNameLoading: true,
        UpdateNameSuccess: false,
        UpdateNameError: false,
      };

    case worksiteTypes.WORKSITE_NAME_UPDATE_SUCCESS:
      //console.log(action.data);
      return {
        ...state,
        UpdateNameLoading: false,
        UpdateNameSuccess: true,
        UpdateNameError: false,
        UpdateNameMessage: action.data && action.data.message,
        Worksites: state.Worksites.map((worksite) => {
          if (worksite._id === action.data.id) {
            return {
              ...worksite,
              name: action.data.name,
            };
          }
          return worksite;
        }),
      };

    case worksiteTypes.WORKSITE_NAME_UPDATE_ERROR:
      return {
        ...state,
        UpdateNameLoading: false,
        UpdateNameSuccess: false,
        UpdateNameError: true,
        UpdateNameMessage: action.data && action.data.message,
      };

    //For selected worksite
    case worksiteTypes.SELECTED_WORKSITE:
      return {
        ...state,
        WorksiteOverview: state.Worksites.find((worksite) => worksite._id === action.data),
      };

    //For reseting the state
    case worksiteTypes.RESET_WORKSITES_STATE:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: false,
        StatusError: false,
        StatusMessage: "",
        AddWorksiteLoading: false,
        AddWorksiteSuccess: false,
        AddWorksiteError: false,
        WorksiteMessage: "",
        UpdateLoading: false,
        UpdateSuccess: false,
        UpdateError: false,
        UpdateMessage: "",
        UpdateNameLoading: false,
        UpdateNameSuccess: false,
        UpdateNameError: false,
        UpdateNameMessage: "",
        deactivateWorksiteLoading: false,
        deactivateWorksiteSuccess: false,
        deactivateWorksiteError: false,
        preDeactivatedWorksiteList: {},
      };

    case worksiteTypes.PRE_DEACTIVATED_WORKSITE_LOADING:
      return {
        ...state,
        preDeactivatedWorksiteLoading: true,
        preDeactivatedWorksiteSuccess: false,
        preDeactivatedWorksiteError: false,
        preDeactivatedWorksiteList: {},
      };

    case worksiteTypes.PRE_DEACTIVATED_WORKSITE_SUCCESS:
      return {
        ...state,
        preDeactivatedWorksiteLoading: false,
        preDeactivatedWorksiteSuccess: true,
        preDeactivatedWorksiteError: false,
        preDeactivatedWorksiteList: action.data,
      };

    case worksiteTypes.PRE_DEACTIVATED_WORKSITE_ERROR:
      return {
        ...state,
        preDeactivatedWorksiteLoading: false,
        preDeactivatedWorksiteSuccess: false,
        preDeactivatedWorksiteError: true,
        preDeactivatedWorksiteList: {},
      };

    case worksiteTypes.DEACTIVATE_WORKSITE_LOADING:
      return {
        ...state,
        deactivateWorksiteLoading: true,
        deactivateWorksiteSuccess: false,
        deactivateWorksiteError: false,
      };

    case worksiteTypes.DEACTIVATE_WORKSITE_SUCCESS:
      return {
        ...state,
        deactivateWorksiteLoading: false,
        deactivateWorksiteSuccess: true,
        deactivateWorksiteError: false,
        Worksites: state.Worksites.map((worksite) => {
          if (worksite._id === action.data.id) {
            return {
              ...worksite,
              status: false,
            };
          }
          return worksite;
        }),
      };

    case worksiteTypes.DEACTIVATE_WORKSITE_ERROR:
      return {
        ...state,
        deactivateWorksiteLoading: false,
        deactivateWorksiteSuccess: false,
        deactivateWorksiteError: true,
      };

    // Returning default state
    default:
      return state;
  }
};
