import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { setErrorCode } from "../../redux/errors/action";

import { Trans } from "react-i18next";
import ErrorPage from "../../assets/Error_404_Image.png";
import "./ErrorStyle.css";

function Error404({ errorCode, setErrorCode }) {
  const history = useHistory();

  const handleGoBackButton = () => {
    setErrorCode("");
  };

  useEffect(() => {
    if (Number(errorCode) !== 404) {
      history.push("/app/dashboard");
    }
  }, []);

  return (
    <>
      <Row>
        <Col md={4}>
          <div className="error-page">
            <div className="error-style">
              <h3>
                <Trans>Error 404</Trans>
              </h3>
              <h5>
                <Trans>Hmmm... That doesn't seem right</Trans>
              </h5>
              <p>
                <Trans>There's been some miscommunication.</Trans>
              </p>
              <p>
                <Trans>The page you requested for is unavailable.</Trans>
              </p>
              <br />
              <NavLink onClick={() => handleGoBackButton()} to="/app/dashboard">
                <button className="button-ErrorStyle ripple">
                  <Trans>Go Back</Trans>
                </button>
              </NavLink>
            </div>
          </div>
        </Col>
        <Col md={8}>
          <div className="error-style">
            <img src={ErrorPage} alt="Error occurred" />
          </div>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  errorCode: state.errors.errorCode,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorCode: (data) => dispatch(setErrorCode(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error404));
