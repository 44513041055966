import styled from "styled-components";

export const WorksiteTopHeader = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    padding: 5px 10px;
    border-radius: 4px;
  }
`;

export const DetailsWrapper = styled.div`
  flex: 1 1 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const WorksiteDataDescp = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL2 || "#434343"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
  }
`;

export const WorksiteHeadDetail = styled.div`
  font-family: Kanit;
  font-size: 26px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ManagementRightBottomWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  padding: 15px;
  min-height: calc(100vh - 322px);
`;
export const TextEditIcon = styled.i`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`;

export const TooltipStyled = styled.i`
  /* font-size: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 8px;
  margin-left: 10px;*/
  cursor: pointer;
`;

export const StatusMobileView = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;
export const StatusDesktopView = styled.div`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;


export const NoSectionStyle = styled.div`
  display: block;
  height: calc(100vh - 280px);
  align-content: center;
  clear: both;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  p {
    margin-bottom: 0px;
  }
  .clickDesktop {
    display: block;
    @media (max-width: 1100px) {
      display: none;
    }
  }
  .tapMobile {
    display: none;
    @media (max-width: 1100px) {
      display: block;
    }
  }
`;
