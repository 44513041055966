import { ProSidebar, Menu, MenuItem, SidebarHeader } from "react-pro-sidebar";
import NavListItem from "../../view/Dashboard/NavListItem/NavListItem.component";
import SidebarProfile from "./SidebarProfile";
import { SidbarMenuMainCont } from "./SidebarProfile.styles";

import styled from "styled-components";
const LeftArrowSVG = styled.span`
  font-size: 20px !important;
  cursor: pointer;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;
const LeftArrowIconDiv = styled.div`
  div {
    text-align: right;
    height: 20px;
    float: right;
    position: relative;
    top: 33px;
    right: 0px;
    margin: 6px;
    padding: 10px;
    border-radius: 50%;
    height: 40px;
    svg {
      filter: opacity(0.5);
      height: 20px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

const RightArrowIconDiv = styled.div`
  div {
    text-align: right;
    height: 20px;
    float: right;
    position: relative;
    right: 0px;
    margin: 6px;
    padding: 10px;
    border-radius: 50%;
    height: 40px;
    svg {
      filter: opacity(0.5);
      height: 20px;
      transform: rotate(180deg);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
const CollapsTopSection = styled.div`
  .NavBarTopM .company-image,
  .NavBarTopM span.user-details-name,
  .NavBarTopM span.user-details-companyname,
  .NavBarTopM span.user-details-accountname {
    display: none;
  }
  .NavBarTopM .user-imageIcon {
    margin-top: 65px;
    width: 48px;
    height: 48px;
    margin-left: 8px;
  }
`;

const Sidebar = ({ image, collapsed, toggled, handleToggleSidebar, handleCollapsedChange, userDetails }) => {
  return (
    <SidbarMenuMainCont>
      <ProSidebar collapsed={collapsed} toggled={toggled} onToggle={handleToggleSidebar} breakPoint="md">
        {/* Header */}

        <SidebarHeader>
          <Menu iconShape="circle">
            {collapsed ? (
              <MenuItem>
                <RightArrowIconDiv onClick={handleCollapsedChange}>
                  <div>
                    <LeftArrowSVG className="icon icon-arrow-right" />
                  </div>
                </RightArrowIconDiv>
                <CollapsTopSection>
                  <SidebarProfile userDetails={userDetails} />
                </CollapsTopSection>
              </MenuItem>
            ) : (
              <MenuItem>
                <LeftArrowIconDiv onClick={handleCollapsedChange}>
                  <div>
                    <LeftArrowSVG className="icon icon-arrow-left" />
                  </div>
                </LeftArrowIconDiv>

                <div>
                  <SidebarProfile userDetails={userDetails} />
                </div>
              </MenuItem>
            )}
          </Menu>
        </SidebarHeader>
        <NavListItem />
      </ProSidebar>
    </SidbarMenuMainCont>
  );
};

export default Sidebar;
