import styled from "styled-components";

export const QRCodeLabel = styled.div`
  font-family: "Open Sans";
  font-size: 12px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const QRCodeWrapper = styled.div`
  width: 110px;
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  padding: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
`;
