export const defaultSpeedControlValue = {
  sczPedestrian: {
    pos1: 0,
    pos2: 0,
    pos3: 0,
    pos4: 0,
    pos5: 0,
    zones: {
      one: 1000,
      two: 2000,
      three: 3000,
      four: 4000,
    },
  },
  sczObject: {
    pos1: 0,
    pos2: 0,
    pos3: 0,
    pos4: 0,
    pos5: 0,
    zones: {
      one: 1000,
      two: 2000,
      three: 3000,
      four: 4000,
    },
  },
};

export const styles = {
  textStyle: {
    fontFamily: "Open Sans",
    fontSize: "0.7rem",
    color: "#707070",
    textAlign: "left",
  },
};

export const zoneMap = [
  { zone: 1, coords: "14.5,147.5,65.5,95.5, 65.5, 202.5" },
  { zone: 2, coords: "69.5, 94.5, 96.5, 68.5, 97.5, 230.5, 70.5, 204.5" },
  { zone: 3, coords: "100.5, 65.5, 127.5, 40.5, 127.5, 256.5, 101.5, 234.5" },
  { zone: 4, coords: "132.5, 34.5, 158.5, 7.5, 157.5, 290.5, 132.5, 263.5" },
];

export const zoneMaxValues = { one: 9998, two: 9998, three: 9999, four: 10000 };

export const zoneDefaultValues = { one: 1000, two: 2000, three: 3000, four: 4000 };

export const currentToPreviousZoneMap = {
  two: "one",
  three: "two",
  four: "three",
};

export const currentToNextZoneMap = {
  one: "two",
  two: "three",
  three: "four",
};
